import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { glasConfig } from '../constants/Config'
import request from 'axios'

export default class Password extends Component {
	constructor(props) {
		super(props)
		this.state = {
			validate: false,
			email: '',
			regist: '',
		}
	}

	validateEmail(email) {
		const mailformat =
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
		if (email.match(mailformat)) {
			return true
		}
		return false
	}

	handleChange(e) {
		this.setState({
			validate: this.validateEmail(e.target.value),
			email: e.target.value,
			regist: '',
		})
	}

	/**
	 * パスワード再発行
	 */
	handlerRegist() {
		if (!(this.state.email && this.state.validate)) {
			return
		}

		const params = {
			email: this.state.email,
			applicationName: glasConfig.application,
		}

		const url = glasConfig.url_base + glasConfig.path_password
		const options = {
			method: 'POST',
			url: url,
			data: params,
			json: true,
		}

		request(options)
			.then((response) => {
				this.setState({
					regist: response.data.code,
				})
			})
			.catch((error) => {
				this.setState({
					regist: 1,
				})
			})
	}

	render() {
		let errormsg = ''
		if (this.state.regist === '') {
			errormsg =
				this.state.email && !this.state.validate
					? 'メールアドレスが不正です'
					: ''
		} else if (this.state.regist === 6) {
			errormsg = 'メールアドレスが登録されていません'
		} else {
			errormsg = 'システムエラー'
		}

		const CustomStyleInput = {
			width: '100%',
			height: '3.2em',
		}

		return (
			<div className="content-auth">
				<div className="auth-box">
					<div className="sitelogo">
						<img
							className="sitelogo__image"
							src="/img/stella_logo_color.png"
							alt="ステラロゴ"
						/>
					</div>
					<div className="auth-box__inner">
						<h1 className="auth-box__title boldText">
							パスワード再発行
						</h1>
						{this.state.regist === 0 ? (
							<p className="auth-box__text">
								ご登録メールアドレス宛に、
								<br />
								パスワード再発行のメールをお送りしましたので
								<br />
								ご確認くださいませ。
							</p>
						) : (
							<div>
								<div className="textfield">
									<div className="mdl-textfield mdl-js-textfield textfield">
										<input
											style={CustomStyleInput}
											placeholder="登録メールアドレス入力"
											className="mdl-textfield__input textfield__input"
											type="text"
											id="auth_mail"
											value={this.state.email}
											onChange={(e) =>
												this.handleChange(e)
											}
										/>
									</div>
									{errormsg}
								</div>
								<div className="btn-wrap">
									<button
										className="btn-raised mdl-js-ripple-effect color_default spread_width"
										disabled={!this.state.validate}
										onClick={() => this.handlerRegist()}
									>
										送信
									</button>
								</div>
							</div>
						)}
						<Link to="/login">ログインに戻る</Link>
					</div>
				</div>
			</div>
		)
	}
}
