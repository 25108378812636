import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

export default class DialogReplyConfirm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			funcAccept: null,
		}
	}

	acceptReply = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				onRequestClose={this.closeDialog}
				className="dialogReplyConfirm"
			>
				<span style={{ fontSize: 18, fontWeight: 'bold' }}>
					一度回答すると、削除・変更はできません。
				</span>
				<br></br>
				<span style={{ fontSize: 18 }}>
					こちらの回答でよろしいですか？
				</span>
				<div className="buttonsReply">
					<button className="cancelReply" onClick={this.closeDialog}>
						キャンセル
					</button>
					<button className="submitReply" onClick={this.acceptReply}>
						送信
					</button>
				</div>
			</Dialog>
		)
	}
}
