import {
	TIMELINE_GET,
	TIMELINE_POST,
	TIMELINE_DELETE,
	TIMELINE_LIKE_GET,
	TIMELINE_COMMENT_GET,
	TIMELINE_COMMENT_POST,
	TIMELINE_COMMENT_DELETE,
} from '../constants/ActionTypes'

const initialState = {
	data: [],
	postPage: null,
	autoLoad: true,
	postTimeline: false,
	likes: {},
	comments: {},
	postId: null,
	timelineDelSuccess: false,
}

export const Timeline = (state = initialState, action) => {
	switch (action.type) {
		case TIMELINE_GET:
			let timeline = {}
			let autoload = true
			if (action.data.prevPage < action.data.postPage) {
				timeline = state.data.concat(action.data.data)
				if (action.data.data.length === 0) {
					autoload = false
				}
			} else {
				timeline = action.data.data
				if (action.data.data.length === 0) {
					autoload = false
				}
			}
			return Object.assign({}, state, action.data, {
				data: timeline,
				autoLoad: autoload,
			})
		case TIMELINE_POST:
			return Object.assign({}, state, action.data)
		case TIMELINE_DELETE:
			return Object.assign({}, state, action.data)
		case TIMELINE_LIKE_GET:
			return Object.assign({}, state, action.data)
		case TIMELINE_COMMENT_GET:
			return Object.assign({}, state, action.data)
		case TIMELINE_COMMENT_POST:
			return Object.assign({}, state, action.data)
		case TIMELINE_COMMENT_DELETE:
			return Object.assign({}, state, action.data)
		default:
			return state
	}
}
