/**
 * チャット画面（/chat/:userid で表示）
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'
import ChatContent from './ChatContent'
import ChatUserInfo from './ChatUserInfo'
import $ from 'jquery'
import Bell from './ChatContent/Frames/Bell'
import * as MyProfileActions from '../actions/MyProfileActions'
class Chat extends Component {
	constructor(props) {
		super(props)
		this.state = {
			UserData: {},
			numberFeasibleWord: 0,
			sendFilePrice: 0,
			sendTextPrice: 0,
		}
		this.countTimeRefreshToken = 1
	}

	componentDidMount() {
		$('body').addClass('refreshing')
		const { params } = this.props.match
		Promise.all([
			this.loadUserData(params.userid),
			this.loadNumberWord(params.userid),
		])
			.then((results) => { })
			.catch((e) => {
				console.log(e)
			})
	}

	componentWillUnmount() {
		$('body').removeClass('refreshing')
	}
	componentWillReceiveProps(nextProps) {

		const { params } = this.props.match
		const nextuid = nextProps.match.params.userid
		if (nextuid !== params.userid) {
			Promise.all([
				this.loadUserData(nextuid),
				this.loadNumberWord(nextuid),
			])
				.then((results) => { })
				.catch((e) => {
					console.log(e)
				})
		} else {
			this.countTimeRefreshToken += 1
			if (this.countTimeRefreshToken % 60 === 0) {
				MyProfileActions.refreshNewToken()
				this.countTimeRefreshToken = 0
			}
			Promise.all([this.loadNumberWord(params.userid)])
				.then((results) => { })
				.catch((e) => {
					console.log(e)
				})
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextProps.match.params.userid === nextState.UserData.userId
	}

	/**
	 *
	 * @param {string} userId
	 * @return {void}
	 */
	loadUserData(userId) {
		const url = glasConfig.url_base + glasConfig.path_user_info + userId
		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		return new Promise((resolve, reject) => {
			request(options)
				.then((response) => {
					if (response.data.code === 0 && response.data.data.userId) {
						this.setState({
							UserData: response.data.data,
						})
						resolve(true)
					}
					if (response.data.code === 28) {
						this.props.history.push('/home')
					}
				})
				.catch((error) => {
					//throw error
				})
		})
	}

	loadNumberWord(userId) {
		const url = glasConfig.url_base + glasConfig.path_point_feasibleword
		const params = {
			fortuneTellerId: Fetch.tellerId(),
			userId: userId,
		}
		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		return new Promise((resolve, reject) => {
			request(options)
				.then((response) => {
					if (response.data.code === 0) {
						this.setState({
							numberFeasibleWord:
								response.data.data.numberFeasibleWord,
							sendTextPrice: response.data.data.sendTextPrice,
							sendFilePrice: response.data.data.sendFilePrice,
						})
						resolve(true)
					}
				})
				.catch((error) => {
					//throw error
				})
		})
	}

	render() {
		const { socket } = this.props
		const pointUser = this.state.UserData.point
		return (
			<div className="box-chat">
				<Bell />
				<ChatContent
					socket={socket}
					UserInfo={this.state.UserData}
					reload={(userId) => this.loadUserData(userId)}
					numberFeasibleWord={this.state.numberFeasibleWord}
					sendTextPrice={this.state.sendTextPrice}
					sendFilePrice={this.state.sendFilePrice}
					pointUser={pointUser}
					userId={this.props.location.pathname.split('/')[3]}
				/>
				<ChatUserInfo
					socket={socket}
					UserInfo={this.state.UserData}
					reload={(userId) => this.loadUserData(userId)}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(Chat)
