import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

class RequireSubmitChanges extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			open: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ open: nextProps.show })
	}

	handleClose = () => {
		this.setState({ open: false })
	}

	onClickCancel = () => {
		this.setState({ open: false })
		this.props.onClickCancel()
	}

	onClickOk = () => {
		this.props.onClickOk()
	}

	render() {
		const actions = [
			<FlatButton
				label="Cancel"
				primary={true}
				onClick={() => this.onClickCancel()}
			/>,
			<FlatButton
				label="OK"
				primary={true}
				keyboardFocused={true}
				onClick={() => this.onClickOk()}
			/>,
		]

		return (
			<div>
				<Dialog
					actions={actions}
					modal={false}
					open={this.state.open}
					onRequestClose={this.onClickCancel}
				>
					変更が保存されていません。ページ遷移しますか？
				</Dialog>
			</div>
		)
	}
}

export default RequireSubmitChanges
