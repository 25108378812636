/**
 * チャットリクエスト関連
 */
import {
	REQUEST_OFFLINE,
	REQUEST_OFFLINE_MERGE,
	REQUEST_OFFLINE_DELETE,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//オフライン時のチャットリクエスト一覧を取得
export const fetch = (params = {}) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		let url = `?page=0&size=100`
		if (params.orderBy !== undefined) {
			url = url + `&orderBy=${params.orderBy}`
		}
		if (params.sortBy !== undefined) {
			url = url + `&sortBy=${params.sortBy}`
		}
		const options = {
			method: 'GET',
			url: glasConfig.url_base + glasConfig.path_offline_request_v2 + url,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					let data = {}
					if (response.data.data) {
						for (var i in response.data.data) {
							data[response.data.data[i].fromId] =
								response.data.data[i]
						}
					}
					dispatch(resultSuccess(REQUEST_OFFLINE, data))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + REQUEST_OFFLINE, error)
				throw error
			})
	}
}

/**
 * stateに保存されているデータを一部更新する
 * 通知から左カラムの情報を更新したい場合など。
 */
export const merge = (req) => {
	return (dispatch) => dispatch(resultSuccess(REQUEST_OFFLINE_MERGE, req))
}

/**
 * stateに保存されているデータを一部削除する
 * 通知から左カラムの情報を更新したい場合など。
 */
export const del = (userId) => {
	return (dispatch) => dispatch(resultSuccess(REQUEST_OFFLINE_DELETE, userId))
}
