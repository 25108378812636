import { MYINFO_ACCOUNT_PASSWORD_PUT } from '../constants/ActionTypes'

const initialState = {
	data: {},
}

export const MyAccountPassword = (state = initialState, action) => {
	switch (action.type) {
		case MYINFO_ACCOUNT_PASSWORD_PUT:
			return Object.assign({}, state, action.data)
		default:
			return state
	}
}
