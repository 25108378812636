// ファイル操作
import {
	FILE_UPLOAD_POST,
	FILE_UPLOAD_GET,
	FILE_UPLOAD_PUT,
	FILE_UPLOAD_DELETE,
	FILE_CONVERT_CANVAS_TO_IMAGE_GET,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

// ファイルアップロード
// data = new FormData()
// data.append('name', 'data')
export const postFile = (formData) => {
	return (dispatch) => {
		return request
			.post(
				glasConfig.url_base + glasConfig.path_file,
				formData.fileData,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(FILE_UPLOAD_POST, response.data.data)
					)
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(FILE_UPLOAD_POST, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + FILE_UPLOAD_POST, error)
				throw error
			})
	}
}

// ファイル取得
export const getFile = (fileId) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.path_file + fileId, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(FILE_UPLOAD_GET, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(FILE_UPLOAD_GET, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + FILE_UPLOAD_GET, error)
				throw error
			})
	}
}

// ファイル更新
export const putFile = (fileId) => {
	return (dispatch) => {
		return request
			.put(glasConfig.url_base + glasConfig.path_file + fileId, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(FILE_UPLOAD_PUT, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(FILE_UPLOAD_PUT, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + FILE_UPLOAD_PUT, error)
				throw error
			})
	}
}

// ファイル削除
export const deleteFile = (fileId) => {
	return (dispatch) => {
		return request
			.delete(glasConfig.url_base + glasConfig.path_file + fileId, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(FILE_UPLOAD_DELETE, response.data.data)
					)
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(FILE_UPLOAD_DELETE, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + FILE_UPLOAD_DELETE, error)
				throw error
			})
	}
}

// canvasから画像を出力
export const canvasToImage = (profileImageSrc) => {
	return (dispatch) => {
		dispatch(
			resultSuccess(FILE_CONVERT_CANVAS_TO_IMAGE_GET, { profileImageSrc })
		)
	}
}
