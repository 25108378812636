import React from 'react'

export default (props) => {
	if (props.subcommentNumber === 0) {
		return ''
	}
	let subComments = Array.prototype.map.call(
		props.subcomments,
		(current, index) => {
			const isTellerComment = props.currentTellerId == current.userId
			const label = isTellerComment
				? current.userName
				: `${props.group} 代 ${props.checkGender(current.gender)}`
			return (
				<li
					className={
						current.approved
							? 'list__item'
							: 'list__item not_approved'
					}
					key={current.subcommentId}
					id={`commentId${current.subcommentId}`}
				>
					<div className="timeline-comment comment_host">
						{current.approved && isTellerComment && (
							<button
								className="btn timeline-comment__btn-clear"
								onClick={(e) =>
									props.onClick(e, current.subcommentId)
								}
							>
								<i className="material-icons btn__icons">
									clear
								</i>
							</button>
						)}
						<p className="timeline-comment__name">{label}</p>
						<p className="timeline-comment__time">
							{props.checkDiffDate(current.createdTime)}
						</p>
						<div className="timeline-comment__main">
							{current.subcommentValue}
						</div>
					</div>
				</li>
			)
		}
	)
	return subComments
}
