import {
    ADD_ANSWER_DRAFT,
    REMOVE_ANSWER_DRAFT,
} from '../constants/ActionTypes'
import moment from 'moment'

const initialState = {
    data: [],
}

export const AnswerDraft = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ANSWER_DRAFT: {
            localStorage.setItem("draft-answer", JSON.stringify(action.data))
            return {
                data: action.data,
            }
        }

        case REMOVE_ANSWER_DRAFT: {
            let getAnswerDraft = []
            if (localStorage.hasOwnProperty("draft-answer")) {
                let dataAnswerDraft = JSON.parse(localStorage.getItem("draft-answer"))
                if (action.data) {
                    dataAnswerDraft = dataAnswerDraft.filter(i => i.questionId !== action.data)
                }
                const currentTime = moment().format('x')
                getAnswerDraft = dataAnswerDraft.filter(i => i.timeExpired > currentTime)
                localStorage.setItem("draft-answer", JSON.stringify(getAnswerDraft))
            }

            return {
                data: getAnswerDraft,
            }
        }
        default: {
            return state
        }
    }
}
