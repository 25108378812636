/* eslint-disable no-console */
import { QuestionCategory } from '../constants/Config'

export const isPWA = () => {
	let pwa = false
	const userAgent = navigator.userAgent
	if(userAgent.includes('iPad') || (userAgent.includes('iPhone') && userAgent.includes('Mac OS')) || userAgent.includes('Mobile Safari')) {
		pwa = true
	}
	return pwa
}

export const isIpad = () => /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1

export const getPermissionForMicrophone = () => {
	const permissions = navigator.mediaDevices.getUserMedia({audio: true, video: false})
	permissions
		.then((stream) => {
			console.log('%c [STELLA] stream: ', 'color: red' , stream)
		})
		.catch((err) => {
			console.log('%c [STELLA] err: ', 'color: red' , err)
		})
}

export function validateEmail(email) {
	let re =
		/^(([^<>()\\,;:\s@"]+(\.[^<>().,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export const getDeviceType = () => {
	let device = ''
	const screenWidth = window.outerWidth
	if (screenWidth < 768) {
		device = 'mobile'
	} else if (screenWidth < 1200) {
		device = 'tablet'
	} else {
		device = 'pc'
	}
	return device
}

export function categoryAnswer(category_id) {
	let textCategory = ''
	switch (category_id) {
	case QuestionCategory.MARRIAGE_OR_DIVORCE: {
		textCategory = '結婚/離婚'
		break
	}
	case QuestionCategory.MARRIAGE: {
		textCategory = '縁結び'
		break
	}
	case QuestionCategory.DIVORCE: {
		textCategory = '縁切り'
		break
	}
	case QuestionCategory.REPUDIATION: {
		textCategory = '復縁'
		break
	}
	case QuestionCategory.INFIDELITY: {
		textCategory = '不倫'
		break
	}
	case QuestionCategory.LOVE: {
		textCategory = '恋愛 '
		break
	}
	case QuestionCategory.WORK: {
		textCategory = ' 仕事'
		break
	}
	case QuestionCategory.INTERPERSONAL: {
		textCategory = '対人関係'
		break
	}
	case QuestionCategory.FORTUNE: {
		textCategory = '運勢'
		break
	}
	case QuestionCategory.LUCKY_MONEY: {
		textCategory = '金運'
		break
	}
	case QuestionCategory.METAL: {
		textCategory = 'メンタル'
		break
	}
	case QuestionCategory.OTHER: {
		textCategory = 'その他'
		break
	}
	}

	return textCategory
}

export function convertUrlQueryParam(params) {
	return Object.keys(params).map((key) => {
		return encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
	}).join("&");
}
