import styled from 'styled-components'
import { POINT_REPORT } from '../../../../constants/PointReportConstants'
import { colors, fonts, DEVICE } from '../../../../util/StellaConstant'

/*
	@media ${DEVICE.mobileS} {

	}
	@media ${DEVICE.laptop} {

	}
	@media ${DEVICE.laptopL} {

	}
*/

export const CalendarBlock = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: CalendarBlock;
		display: grid;
		padding-bottom: 110px;
		grid-template-areas:
			'TargetPoint'
			'ActualPoint'
			'PointBonus'
			'PointCalendar'
			'RedirectDailyReward';
	}
	@media ${DEVICE.laptop} {
		background-color: ${colors.white};
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
		border-radius: 4px;
		padding: 26px 11px 21px 20px;
		margin-bottom: 11px;
		grid-column-gap: 14px;
		// grid-row-gap: 21px;
		grid-template-areas:
			'TargetPoint ActualPoint PointBonus'
			'PointCalendar PointCalendar'
			'RedirectDailyReward RedirectDailyReward';
		margin-top: 68px;
	}
	@media ${DEVICE.laptopL} {
		background-color: ${colors.white};
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
		border-radius: 4px;
		padding: 26px 11px 21px 20px;
		margin-bottom: 11px;
		grid-column-gap: 14px;
		// grid-row-gap: 21px;
		grid-template-areas:
			'TargetPoint ActualPoint PointBonus'
			'PointCalendar PointCalendar PointCalendar';
		margin-top: 0;
	}
`

export const TargetPoint = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: TargetPoint;
		background-color: ${colors.snow};
		padding: 14px 13px 14px 18px;
		margin-bottom: 6px;
		margin-top: 43px;
		margin-left: 8px;
		margin-right: 8px;
	}
	@media ${DEVICE.laptop} {
		padding: 19px 17px 16px 15px;
		margin-bottom: 34px;
		margin-top: 0;
	}
	@media ${DEVICE.laptopL} {
		padding: 19px 38px 16px 18px;
		margin-bottom: 19px;
		min-width: 370px;
		margin-top: 40px;
	}
`

export const ActualPoint = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: ActualPoint;
		background-color: ${colors.azureMist};
		padding: 14px 10px 14px 18px;
		margin-bottom: 6px;
		margin-left: 8px;
		margin-right: 8px;
	}
	@media ${DEVICE.laptop} {
		padding: 19px 22px 15px 18px;
		margin-bottom: 34px;
		margin-top: 0;
	}
	@media ${DEVICE.laptopL} {
		padding: 19px 37px 15px 18px;
		margin-bottom: 19px;
		min-width: 350px;
		margin-top: 40px;
	}
`
export const PointBonus = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: PointBonus;
		background-color: ${colors.azureMist};
		padding: 14px 10px 14px 18px;
		margin-bottom: 6px;
		margin-left: 8px;
		margin-right: 8px;
	}
	@media ${DEVICE.laptop} {
		padding: 19px 22px 15px 18px;
		margin-bottom: 34px;
		margin-top: 0;
	}
	@media ${DEVICE.laptopL} {
		padding: 19px 37px 15px 18px;
		margin-bottom: 19px;
		width: 350px;
		margin-top: 40px;
	}
`

export const Title = styled.p`
	@media ${DEVICE.mobileS} {
		display: block;
		color: ${props => POINT_REPORT.TITLES[props.type].color};
		font-family: ${props => POINT_REPORT.TITLES[props.type].fontFamily.mobile};
		font-size: ${props => POINT_REPORT.TITLES[props.type].fontSize.mobile};
		background-color: ${props => POINT_REPORT.TITLES[props.type].backgroundColor};
		padding: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '7px 17px' : '0'};
		width: fit-content;
		text-align: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'center' : 'left'};
		border-radius: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '13px' : '0'};
		margin: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'auto' : '0'};
		margin-bottom: 8px;
	}
	@media ${DEVICE.laptop} {
		display: block;
		color: ${props => POINT_REPORT.TITLES[props.type].color};
		font-family: ${props => POINT_REPORT.TITLES[props.type].fontFamily.laptop};
		font-size: ${props => POINT_REPORT.TITLES[props.type].fontSize.laptop};
		background-color: ${props => POINT_REPORT.TITLES[props.type].backgroundColor};
		padding: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '7px 17px' : '0'};
		width: fit-content;
		text-align: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'center' : 'left'};
		border-radius: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '13px' : '0'};
		margin: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'auto' : '0'};
		margin-bottom: 8px;
	}
	@media ${DEVICE.laptopL} {
		font-family: ${props => POINT_REPORT.TITLES[props.type].fontFamily.laptopL};
		font-size: ${props => POINT_REPORT.TITLES[props.type].fontSize.laptopL};
		margin-top: 0;
	}
`

export const Row = styled.div`
	@media ${DEVICE.mobileS} {
		display: flex;
		justify-content: ${props => props.align};
		align-items: baseline;
	}
	@media ${DEVICE.laptop} {

	}
	@media ${DEVICE.laptopL} {

	}
`

export const TotalActualPoint = styled.span`
	@media ${DEVICE.mobileS} {
		display: none;
	}
	@media ${DEVICE.laptop} {
		display: none;
	}
	@media ${DEVICE.laptopL} {
		display: initial;
		font-size: 17px;
		font-family: ${fonts.HiraKakuProW3};
		margin-right: 10px;
	}
`

export const LastDayActualPoint = styled.span`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 13px;
	}
	@media ${DEVICE.laptop} {
		font-size: 14px;
	}
	@media ${DEVICE.laptopL} {
		font-size: 16px;

	}
`