import React, { Component } from 'react'
import { connect } from 'react-redux'
import IntroductionMenu from './IntroductionMenu'
import './styleIntroduction.css'
import { glasConfig } from '../../constants/Config'
import request from 'axios'
import DialogGetPoint from '../homeTellerIntroductionTeller/DialogGetPoint'

const MISSION_TITLE = {
	COMPLETE_PROFILE: {
		content: 'プロフィール情報を登録する',
		url: '/home/setting',
	},
	AVATAR_APPROVED: {
		content: '適切なプロフィール画像を登録し、加工完了まで進む',
		url: '/home/setting',
	},
	AGE_VERIFIED: {
		content: '年齢認証を完了させる',
		url: '/home/setting/account',
	},
	EARNING_POINT: {
		content: '稼働して精算可能額を稼ぐ（3540ポイント～）',
		url: '/home',
	},
	REGISTER_BANKING: {
		content: '銀行情報を登録する',
		url: '/home/setting/bank/top',
	},
	REQUEST_PAYOFF: {
		content: '精算申請する',
		url: '/home/reward',
	},
}

Number.prototype.formatCurrency = function (n, x) {
	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
	return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
}

const StepIntroduction = ({ content, active, onClick = () => {} }) => (
	<div className="item-step-rwi">
		<div style={{ flex: 1 }}>
			<p className={`step-content-rwi`}>{content}</p>
		</div>
		<div className="btn-step-rwi">
			<button
				style={{ fontSize: 15 }}
				onClick={active ? onClick : () => {}}
				class={`btn-raised ${active && 'color_default'}`}
			>
				{active ? '挑戦する' : '達成済'}
			</button>
		</div>
	</div>
)

class Introduction extends Component {
	constructor(props) {
		super(props)
		this.state = {
			numberPoint: 0,
			listMission: [],
			achievementTellerIsIntroduced: 0,
		}
	}

	componentDidMount() {
		this.getPointSetting()
		this.getMissionIntroduction()
		this.getAchievementTellerIsIntroduced()
	}

	getPointSetting = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base + glasConfig.get_point_setting_introduction,
				{
					headers: { Authorization: token },
					params: { isIntroduced: true },
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					const { point } = response.data.data
					this.setState({ numberPoint: point })
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getAchievementTellerIsIntroduced = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
					glasConfig.get_achievement_teller_is_introduced,
				{
					headers: { Authorization: token },
				}
			)
			.then((response) => {
				if (response.data.code === 0 && response.data.data) {
					const { rewardId } = response.data.data
					this.setState({ achievementTellerIsIntroduced: rewardId })
				} else {
					this.setState({ achievementTellerIsIntroduced: 0 })
				}
			})
			.catch((error) => {
				this.setState({ achievementTellerIsIntroduced: 0 })
			})
	}

	getMissionIntroduction = () => {
		const token = localStorage.getItem('token')
		return request
			.get(glasConfig.url_base + glasConfig.get_mission_introduction, {
				headers: { Authorization: token },
			})
			.then((response) => {
				if (response.data.code === 0) {
					const data = response.data.data
					this.setState({ listMission: data })
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}
	checkMissionIntroduction = (missionId, url, type) => () => {
		// if (type == "REQUEST_PAYOFF" || type == "REGISTER_BANKING") {
		//     this.props.history.push(url)
		//     return
		// }
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
					glasConfig.check_mission_introduction +
					missionId,
				{
					headers: { Authorization: token },
				}
			)
			.then((response) => {
				if (
					response.data.code === 0 &&
					response.data.data &&
					response.data.data.complete
				) {
					this.getMissionIntroduction()
				} else this.props.history.push(url)
			})
			.catch((error) => {
				this.props.history.push(url)
			})
	}

	getPoint = (rewardId) => () => {
		const { numberPoint } = this.state
		this.refs.dialogGetPoint.show({ rewardId, numberPoint })
	}

	render() {
		const { numberPoint, achievementTellerIsIntroduced, listMission } =
			this.state
		const { isIntroduced } = this.props
		return (
			<div className="content">
				<DialogGetPoint
					ref="dialogGetPoint"
					onClose={() => {
						this.getAchievementTellerIsIntroduced()
					}}
				/>
				<h1 className="content__title hidden-sp">紹介</h1>
				<IntroductionMenu isIntroduced={isIntroduced} />
				<div className="primary">
					<div className="primary-body-in frame-table">
						<h4
							style={{
								paddingBottom: 20,
								fontWeight: 'normal',
								fontSize: 20,
							}}
						>
							紹介での登録
						</h4>
						<p style={{ paddingBottom: 15, fontSize: 15 }}>
							<span>
								条件を達成すれば、あなたと紹介者の両方に
							</span>
							<span
								style={{
									color: 'rgba(236, 64, 122, 1)',
									fontSize: 15,
								}}
							>
								{numberPoint}ptsが貰えます
							</span>
							<span>。</span>
							<br />
							<span>
								このページのミッションを達成してGETしましょう！
							</span>
						</p>
						{!!achievementTellerIsIntroduced && (
							<button
								onClick={this.getPoint(
									achievementTellerIsIntroduced
								)}
								class="btn-raised color_default btnGetPointIntro"
							>
								紹介ボーナスを受け取る
							</button>
						)}
					</div>
				</div>
				<h4 className="title-rwi">
					<span>条件：登録日より</span>
					<span style={{ color: 'rgba(236, 64, 122, 1)' }}>
						30日以内
					</span>
					<span>に精算申請を行う</span>
				</h4>
				<div className="primary primary-rwi">
					{/* <StepIntroduction content="プロフィール情報を登録する" active={false} url="/home/setting" />
                    <StepIntroduction content="適切なプロフィール画像を登録し、加工完了まで進む" active={false} url="/home/setting" />
                    <StepIntroduction content="年齢認証を完了させる" active={false} url="/home/setting/account" />
                    <StepIntroduction content="稼働して精算可能額を稼ぐ（3540ポイント～）" active={true} url="/home" />
                    <StepIntroduction content="銀行情報を登録する" active={true} url="/home/setting/bank/top" />
                    <StepIntroduction content="精算申請する" active={true} url="/home/reward" /> */}
					{listMission.map((v, k) => (
						<StepIntroduction
							key={k}
							content={MISSION_TITLE[v.type].content}
							active={!v.complete}
							onClick={this.checkMissionIntroduction(
								v.missionId,
								MISSION_TITLE[v.type].url,
								v.type
							)}
						/>
					))}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		CustomerManagement: store.CustomerManagement,
	}
}

export default connect(mapStateToProps)(Introduction)
