import {
	DO_SUSPEND,
	DO_SHOW_DIALOG_CHAT,
	DO_REMOVE_REQUEST,
} from '../constants/ActionTypes'

const initialState = {
	isShowBell: false,
	message: [],
}

const checkIfRequestIsInList = (listRequest, requestId) => {
	let isInList = true
	if (listRequest.length === 0) {
		isInList = false
	} else {
		const findRequest = listRequest.find((item) => item.msgId === requestId)
		if (findRequest === undefined) {
			isInList = false
		}
	}
	return isInList
}

export const SuspendRequestChat = (state = initialState, action) => {
	switch (action.type) {
		case DO_SUSPEND:
			const newListRequest = [...state.message]
			const isInList = checkIfRequestIsInList(
				newListRequest,
				action.data.msgId
			)
			if (isInList) {
				const listExceptClickedRequest = newListRequest.filter(
					(item) => item.msgId !== action.data.msgId
				)
				localStorage.setItem(
					'requestSuspend',
					JSON.stringify([...listExceptClickedRequest, action.data])
				)
				return {
					...state,
					isShowBell: true,
					message: [...listExceptClickedRequest, action.data],
				}
			} else {
				localStorage.setItem(
					'requestSuspend',
					JSON.stringify([...state.message, action.data])
				)
				return {
					...state,
					isShowBell: true,
					message: [...state.message, action.data],
				}
			}
		case DO_SHOW_DIALOG_CHAT:
			return { ...state, isShowBell: false }
		case DO_REMOVE_REQUEST:
			const requestId = action.data
			const listExceptClickedRequest = state.message.filter(
				(item) => item.msgId !== requestId
			)
			localStorage.setItem(
				'requestSuspend',
				JSON.stringify(listExceptClickedRequest)
			)
			return { ...state, message: listExceptClickedRequest }
		default:
			return state
	}
}
