import { FOLLOWERS_GET } from '../constants/ActionTypes'

const initialState = {
	loaded: false,
	data: {},
}

export const Follower = (state = initialState, action) => {
	switch (action.type) {
		case FOLLOWERS_GET: {
			return Object.assign({}, state, {
				loaded: true,
				data: action.data,
			})
		}
		default: {
			return state
		}
	}
}
