/**
 * チャット画面　右カラムのメモ
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as UserInfoActions from '../actions/UserInfoActions'
import Dialog from 'material-ui/Dialog'
import $ from 'jquery'
import { Link } from 'react-router-dom'

class ChatUserMemo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			memo: '',
			showNotice: false,
			showExpansion: false,
			focusing: false,
			showNoticeErr: false,
		}
	}

	componentWillMount() {
		const { UserInfo } = this.props
		this.setState({
			memo: !UserInfo.memo ? '' : UserInfo.memo,
		})
	}

	componentDidUpdate(prevProps) {
		if (prevProps.UserInfo.userId !== this.props.UserInfo.userId) {
			this.setState({ memo: this.props.UserInfo.memo })
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isResetMemo) {
			const { UserInfo } = this.props
			this.setState({
				memo: !UserInfo.memo ? '' : UserInfo.memo,
			})
			this.props.updateResetMemo()
		}
	}

	/**
	 * 入力イベント
	 *
	 * @param {*} e イベント
	 * @return {void}
	 */
	handleChange(e) {
		const memo = e.target.value
		this.setState({
			memo: memo,
		})
	}

	/**
	 * フォーカスが外れたときのイベント（入力を保存）
	 *
	 * @return {void}
	 */
	async handleSaveMemo() {
		//フォーカスが外れた時にメモを保存する
		const { dispatch, UserInfo } = this.props
		const orgmemo = UserInfo.memo ? UserInfo.memo : ''
		if (this.state.memo === null || orgmemo === this.state.memo) {
			return
		}
		const data = { ...UserInfo }
		data['memo'] = this.state.memo
		const response = await dispatch(UserInfoActions.post('memo', data))
		if (response.data.code === 0) {
			UserInfo['memo'] = this.state.memo
			this.setState({
				showNotice: true,
			})
			setTimeout(() => {
				this.setState({
					showNotice: false,
				})
			}, 2000)
		} else {
			this.setState({
				showNoticeErr: true,
				memo: UserInfo.memo,
			})
			setTimeout(() => {
				this.setState({
					showNoticeErr: false,
				})
			}, 2000)
		}
	}

	handleExpansion() {
		this.setState({
			showExpansion: !this.state.showExpansion,
		})
	}

	handleCloseDialog(event) {
		const { UserInfo } = this.props
		event.stopPropagation()
		this.setState({
			showExpansion: false,
			memo: !UserInfo.memo ? '' : UserInfo.memo,
		})
	}

	handleFullScreen(event) {
		event.stopPropagation()
		this.setState({ showExpansion: !this.state.showExpansion })
	}

	render() {
		const { show, UserInfo, joinState } = this.props
		if (!(UserInfo.userId && show)) {
			return null
		}

		return (
			<div className="chat-memo-wrap">
				<div className="chat-memo">
					<Dialog
						modal={false}
						open={this.state.showExpansion}
						onRequestClose={() =>
							this.setState({ showExpansion: false })
						}
					>
						<div style={titleDialog}>メモ</div>
						<div
							className="chat-memo-dialog"
							onClick={this.stopChatMemory}
							style={styleDialogMemo}
						>
							<TextArea
								joinState = {joinState}
								show={true}
								memo={this.state.memo}
								changeFunc={(e) => this.handleChange(e)}
							/>
							<div
								className="chat-memo__scale-down"
								style={{ cursor: 'pointer' }}
								onClick={(event) =>
									this.handleCloseDialog(event)
								}
							>
								<i className="material-icons">remove</i>
							</div>
						</div>
						<div style={stylePopupMemo}>
							{this.state.showNotice && (
								<p style={styleTextSave}>
									<i className="material-icons chat-memo__icon">
										check
									</i>
									保存しました
								</p>
							)}
							{this.state.showNoticeErr &&
								!this.state.showExpansion && (
									<p style={styleTextErr}>
										もう一度保存してください
									</p>
								)}
							<button
								className="btn-raised color_default"
								type="button"
								onClick={() => this.handleSaveMemo()}
							>
								保存
							</button>
						</div>
					</Dialog>
					<TextArea
						joinState = {joinState}
						show={!this.state.showExpansion}
						memo={this.state.memo}
						changeFunc={(e) => this.handleChange(e)}
					/>
					{this.state.showExpansion ? (
						<div className="chat-memo-hide-text">
							メモ欄拡大表示中
						</div>
					) : (
						''
					)}

					<label
						className="chat-memo__label"
						htmlFor="secondary_comment"
					></label>
					<div
						className="chat-memo__fullscreen"
						style={{ cursor: 'pointer' }}
						onClick={(e) => this.handleFullScreen(e)}
					>
						<i className="material-icons">fullscreen</i>
					</div>
				</div>
				<div style={styleBtnMemo}>
					{this.state.showNotice && !this.state.showExpansion && (
						<p style={styleTextSave}>
							<i className="material-icons chat-memo__icon">
								check
							</i>
							保存しました
						</p>
					)}
					{this.state.showNoticeErr && !this.state.showExpansion && (
						<p style={styleTextErr}>もう一度保存してください</p>
					)}
					<button
						className="btn-raised color_default"
						type="button"
						onClick={() => this.handleSaveMemo()}
					>
						保存
					</button>
				</div>
			</div>
		)
	}
}

const TextArea = ({ show, memo, changeFunc,joinState }) => {
	if (!show) {
		return null
	}
	return (
		<div style={{ height: '100%', width: '100%' }}>
			<textarea
				style={joinState===true? { width: '100%' } :  { width: '100%', height:'350px' }}
				placeholder="メモはアナタ以外に見られることはありません"
				className="chat-memo__input"
				value={memo ? memo : ''}
				onChange={(e) => changeFunc(e)}
			/>
			<textarea
				style={{ width: '96%' }}
				placeholder="メモを記入してください"
				className="chat-memo__input-sp"
				value={memo ? memo : ''}
				onChange={(e) => changeFunc(e)}
			/>
		</div>
	)
}

const styleBtnMemo = {
	display: 'flex',
	position: 'absolute',
	bottom: '30px',
	right: '26px',
	alignItems: 'center',
}

const stylePopupMemo = {
	float: 'right',
	marginTop: '10px',
	display: 'flex',
	alignItems: 'center',
}

const styleTextSave = {
	fontSize: '13px',
	color: 'rgba(0, 0, 0, 0.38)',
	marginRight: '5px',
}

const styleTextErr = {
	fontSize: '13px',
	color: 'red',
	marginRight: '5px',
	marginLeft: '10px',
}

const styleDialogMemo = {
	border: '2px solid rgba(0, 0, 0, 0.12)',
	borderRadius: '3px',
	marginTop: '10px',
}

const titleDialog = {
	fontSize: '15px',
	color: 'rgba(0, 0, 0, 0.87)',
	position: 'absolute',
	top: '7px',
}

const mapStateToProps = (state) => {
	return {
		ChatRequest: state.ChatRequest,
	}
}

export default connect(mapStateToProps)(ChatUserMemo)
