import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import BaseController from './app/components/BaseController'
import { PrivateRoute } from './app/components/PrivateRoute'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import LoadingBar from './app/components/LoadingBar'
import Layout from './app/components/Layout'
import Login from './app/components/Login'
import Password from './app/components/Password'
import Contract from './app/components/Contract'
import PasswordReset from './app/components/PasswordReset'
import PasswordAnnounce from './app/components/PasswordAnnounce'
import DialogNotifi from '././app/components/Dialog'
import Maintenance from './app/components/Maintenance'
import axios from 'axios'
import IdleContainer from './app/components/IdleContainer'
import { FireMsg } from './app/util/Firebase'

// import AsyncController from './app/components/AsyncController';

// const Layout = AsyncController(() => import('./app/components/Layout'));
// const Login = AsyncController(() => import('./app/components/Login'));
// const Password = AsyncController(() => import('./app/components/Password'));
// const Contract = AsyncController(() => import('./app/components/Contract'));
// const PasswordReset = AsyncController(() => import('./app/components/PasswordReset'));
// const PasswordAnnounce = AsyncController(() => import('./app/components/PasswordAnnounce'));

let appRef = null
let count = 0

// handle error http 500 show screen maintainer
axios.interceptors.response.use(
	(res) => {
		if(res.data.code===500){
			appRef.setState({ maintenance: true })
		}
		count = 0
		return res
	},

	(error) => {
		if (error.response && error.response.status === 500 && window.location.pathname!=='/login') {
			window.location.replace('/login')
			// if(!error.response || error.response.status === 500 || !error.response.data || error.response.data.status === 500){
			// window.location.replace('/maintenance')
			// count += 1
			// try {
			// 	if (appRef) {
			// 		if (count >= 3) {
			// 			appRef.setState({ maintenance: true })
			// 		}
			// 	}
			// } catch (error) {}
			// appRef.setState({ maintenance: true })
		}
		return Promise.reject(error)
	}
)
class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			maintenance: false,
		}
		appRef = this
	}
	render() {
		if (this.state.maintenance) return <Maintenance />
		return (
			<MuiThemeProvider muiTheme={getMuiTheme()}>
				<IdleContainer>
					<LoadingBar />
					<BaseController />
					<Switch>
						<PrivateRoute path="/home" component={Layout} />
						<Route exact path="/login" component={Login} />
						<Route exact path="/password" component={Password} />
						<Route
							exact
							path="/password_reset/:auth_code"
							component={PasswordReset}
						/>
						<Route
							exact
							path="/announce"
							component={PasswordAnnounce}
						/>
						<Route exact path="/contract" component={Contract} />
						<Route
							exact
							path="/maintenance"
							component={Maintenance}
						/>
						<Route
							exact
							path="/"
							render={(props) => (
								<PrivateRoute component={Layout} />
							)}
						/>
						<Route component={Login} />
					</Switch>
					<DialogNotifi
						ref={(dialog) => DialogNotifi.setDialog(dialog)}
					/>
				</IdleContainer>
			</MuiThemeProvider>
		)
	}
}

export default App
