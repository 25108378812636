import React, { Component } from 'react'
import { connect } from 'react-redux'

import AnswerMenu from './AnswerMenu'
import AnswerItem from './AnswerItem'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import { ANSWER_TELLER, glasConfig, MAX_SIZE_ANSWER_SHOW } from './../constants/Config'
import Checkbox from 'material-ui/Checkbox'
import { isPWA } from '../helper/helpFunction'
import { width } from '@mui/system'
import AnswerItemLogWeb from './Answer/AnswerItemLogWeb'

class AnswerLogs extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageRecords: [],
			currentPage: 0,
			dataloaded: false,
			totalRecords: 0,
			showBestLog: false,
		}
	}

	componentWillMount() {
		this.getAnswerLogs()
	}

	createUrlQuery = (page) => {
		let postPage = page || 0
		let params = ['tab=all', `page=${postPage}`]
		return `?${params.join('&')}`
	}

	createUrlQueryPC = (page) => {
		let postPage = page || 0
		let params = [
			'tab=history',
			`pickedBestAnswer=${this.state.showBestLog}`,
			`page=${postPage}`,
			`size=${MAX_SIZE_ANSWER_SHOW}`
		]
		return `?${params.join('&')}`
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.AnswerManagement.answerLogs) {
			let dataloaded = true
			this.setState({
				pageRecords: nextProps.AnswerManagement.answerLogs.data.list,
				currentPage: nextProps.AnswerManagement.answerLogs.page,
				totalRecords:
					nextProps.AnswerManagement.answerLogs.data.totalRecord,
				dataloaded,
			})
		}
	}

	getAnswerLogs = (page) => {
		const { dispatch } = this.props
		if (isPWA()) {
			dispatch(
				AnswerManagementActions.getAnswerLogs(
					this.createUrlQuery(page),
					page
				)
			)
		} else {
			dispatch(
				AnswerManagementActions.getAnswerLogsV2(
					this.createUrlQueryPC(page),
					page
				)
			)
		}
		
	}

	showAnswerLogs = () => {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				if (isPWA())
				return (
					<AnswerItem
						item={current}
						key={index}
						flagAnswerLog={true}
						answerLog={true}
					/>
				)

				return (
					<AnswerItemLogWeb 
						item={current}
						key={index}
						flagAnswerLog={true}
						answerLog={true} 
					/>
				)
			}
		)
	}

	handlePage(event, page) {
		event.preventDefault()
		this.getAnswerLogs(page)
		window.scrollTo(0, 0)
	}

	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}

		let currentPage = parseInt(this.state.currentPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.answermanagement.answer
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0
		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1
		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = currentPage - showHalfPage
		let loopEnd = currentPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (currentPage !== 0) {
			firstPage = false
		}
		if (currentPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			lastPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = currentPage > 0 ? currentPage - 1 : 0
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = currentPage !== endPage ? currentPage + 1 : endPage
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === currentPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item" key={i}>
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className="primary__inner">
				<div className="pager">
					<ul className="list">
						{prevNav}
						{pageNav}
						{nextNav}
					</ul>
				</div>
			</div>
		)
	}

	openWelcomeAnswer = () => {
		this.props.dispatch(AnswerManagementActions.openDialogWelComeAnswer())
	}

	handleClickCheckBox = (e) => {
		this.setState({ showBestLog : e.target.checked}, () => {
			this.getAnswerLogs()
		})
		
	}

	render() {
		return (
			<div className="content">
				<h1 className="content__title hidden-sp">
					教えて！先生
					<i
						class="material-icons content__icon-welcome-answer"
						onClick={this.openWelcomeAnswer}
					>
						help_outline
					</i>
				</h1>
				<AnswerMenu />
				{!isPWA() &&
				<div className="background-container_answerlog">
					<label class="container-answer_checkbox">
						{ANSWER_TELLER.LABEL_CHECKBOX_ANSWER_BEST_ANSWER}
						<input type="checkbox" onChange={(e) => 
							this.handleClickCheckBox(e)
						}/>
						<span class="checkmark"></span>
					</label>
				</div>
				}
				{this.showAnswerLogs()}
				{this.setPager()}
				<div
					className="content__text-welcome-answer hidden-pc"
					onClick={this.openWelcomeAnswer}
				>
					教えて！先生とは？
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
	}
}

export default connect(mapStateToProps)(AnswerLogs)
