import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
	glasConfig,
	katakanaList,
} from '../constants/Config'
// import SettingMenu from './SettingMenu'
import RewardMenu from './RewardMenu'
import * as MyBankActions from '../actions/MyBankActions'
import $ from 'jquery'

/**
 * 銀行支店頭文字選択
 */

class SettingBankBranches extends Component {
	constructor(props) {
		super(props)
		this.state = {
			code: this.props.match.params.code,
			branches: [],
			katakana: null,
			back: false,
		}
	}

	componentWillMount() {
		const { dispatch } = this.props
		dispatch(MyBankActions.getBankCode(this.state.code))
		dispatch(MyBankActions.getBranch(this.state.code))
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.Bank.branchInitial) {
			let initials = []
			let branches = nextProps.Bank.branchInitial
			let re = /([^A-Za-z]).*/
			branches.forEach((current, index) => {
				let matches = current.siten_furi.match(re)
				if (matches) {
					let zenkaku = this.toZenkakuKatakana(matches[1])
					if (initials.indexOf(zenkaku) === -1) {
						initials.push(zenkaku)
					}
				}
			})

			initials.sort((a, b) => {
				a = this.toKatakanaHiragana(a.toString())
				b = this.toKatakanaHiragana(b.toString())
				if (a < b) {
					return -1
				} else if (a > b) {
					return 1
				}
				return 0
			})

			this.getKatakanaInitial(initials)
		}
	}

	toZenkakuKatakana(str) {
		let ret = []

		let m = {
			0xff61: 0x300c,
			0xff62: 0x300d,
			0xff63: 0x3002,
			0xff64: 0x3001,
			0xff65: 0x30fb, // 。「」、・
			0xff67: 0x30a1,
			0xff68: 0x30a3,
			0xff69: 0x30a5,
			0xff6a: 0x30a7,
			0xff6b: 0x30a9, // ァ
			0xff6c: 0x30e3,
			0xff6d: 0x30e5,
			0xff6e: 0x30e7,
			0xff6f: 0x30c3,
			0xff70: 0x30fc, // ャュョッー
			0xff71: 0x30a2,
			0xff72: 0x30a4,
			0xff73: 0x30a6,
			0xff74: 0x30a8,
			0xff75: 0x30aa, // ア
			0xff76: 0x30ab,
			0xff77: 0x30ad,
			0xff78: 0x30af,
			0xff79: 0x30b1,
			0xff7a: 0x30b3, // カ
			0xff7b: 0x30b5,
			0xff7c: 0x30b7,
			0xff7d: 0x30b9,
			0xff7e: 0x30bb,
			0xff7f: 0x30bd, // サ
			0xff80: 0x30bf,
			0xff81: 0x30c1,
			0xff82: 0x30c4,
			0xff83: 0x30c6,
			0xff84: 0x30c8, // タ
			0xff85: 0x30ca,
			0xff86: 0x30cb,
			0xff87: 0x30cc,
			0xff88: 0x30cd,
			0xff89: 0x30ce, // ナ
			0xff8a: 0x30cf,
			0xff8b: 0x30d2,
			0xff8c: 0x30d5,
			0xff8d: 0x30d8,
			0xff8e: 0x30db, // ハ
			0xff8f: 0x30de,
			0xff90: 0x30df,
			0xff91: 0x30e0,
			0xff92: 0x30e1,
			0xff93: 0x30e2, // マ
			0xff94: 0x30e4,
			0xff95: 0x30e6,
			0xff96: 0x30e8, // ヤ
			0xff97: 0x30e9,
			0xff98: 0x30ea,
			0xff99: 0x30eb,
			0xff9a: 0x30ec,
			0xff9b: 0x30ed, // ラ
			0xff9c: 0x30ef,
			0xff66: 0x30f2,
			0xff9d: 0x30f3, // ワヲン
			0xff9e: 0x309b,
			0xff9f: 0x309c, // 濁点、半濁点
		}

		let c = str.charCodeAt(0)
		ret.push(m[c] || c)
		return String.fromCharCode.apply(null, ret)
	}

	toKatakanaHiragana(src) {
		return src.replace(/[\u30a1-\u30f6]/g, (match) => {
			let chr = match.charCodeAt(0) - 0x60
			return String.fromCharCode(chr)
		})
	}

	getKatakanaInitial(initials) {
		let parentPath = this.getParentName(this.props.match.path)

		let katakana = Array.prototype.map.call(
			katakanaList,
			(current, index) => {
				if (initials.indexOf(current.katakana) !== -1) {
					return (
						<li className="list__item">
							<Link
								to={{
									pathname: `${parentPath}/initials/${this.state.code}/${current.initials}`,
								}}
								className="bank-link width_short"
								data-initials={current.initials}
							>
								{current.katakana}
							</Link>
						</li>
					)
				} else {
					return (
						<li className="list__item">
							<span className="bank-link width_short">
								{current.katakana}
							</span>
						</li>
					)
				}
			}
		)

		this.setState({
			katakana: katakana,
		})
	}

	getInitialBranches(initials) {
		for (let branch in this.state.branches) {
			if (this.state.branches[branch].kana.indexOf(initials) === 0) {
				return true
			}
		}
		return false
	}

	getParentName(path) {
		return path.split('/').slice(0, -1).join('/')
	}

	handleClick(e) {
		this.setState({
			back: true,
		})
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('銀行口座情報編集')
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
		$('.menu-header').removeClass('hiddenClass')
	}

	render() {
		if (this.state.back) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -2)
						.join('/')}/top`}
				/>
			)
		}

		let katakana = null
		if (this.state.katakana) {
			katakana = this.state.katakana
		}

		let content = ''
		if (this.state.code === glasConfig.bank.yucho) {
			content = (
				<div>
					<p>下記より該当する銀行名の先頭文字を選択してください。</p>
					<p>支店名は漢数字の読みになります。</p>
					<p>例)支店名「三一八」は表の「サ」を選択。</p>
					<p>
						<a
							href="http://www.jp-bank.japanpost.jp/kojin/sokin/furikomi/kouza/kj_sk_fm_kz_1.html"
							target="_blank"
						>
							ゆうちょ記号番号から振込用の支店名・口座番号を調べる
						</a>
					</p>
				</div>
			)
		} else {
			content = (
				<p>下記より該当する銀行名の先頭文字を選択してください。</p>
			)
		}

		let bank_name = ''
		if (this.props.Bank.bank.length) {
			bank_name = this.props.Bank.bank[0].bank_name
		}

		return (
			<div className="content bank-detail bank-branches-setting">
				<h1 className="content__title">設定</h1>
				{/* <SettingMenu /> */}
				<RewardMenu />
				<div className="primary">
					<div className="hidden-mobile">
						<div className="primary-title">
							<h1 className="primary-title__main">
								銀行口座情報
							</h1>
						</div>
						<div className="primary__inner">
							<div className="bank-box">
								<table className="table-th-left">
									<tbody>
										<tr>
											<th className="table-align-left">
												銀行名
											</th>
											<td className="table-align-left">
												{bank_name}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div className="primary-title">
							<h1 className="primary-title__main">支店名選択</h1>
						</div>
						<div className="primary__inner">
							<div className="primary-title lv_2">{content}</div>
							<ul className="list japanese_syllabary margin_bottom">
								{katakana}
							</ul>
							<input
								type="button"
								className="btn-raised margin_right"
								value="戻る"
								onClick={(e) => this.handleClick(e)}
							/>
						</div>
					</div>
					<div className="display-mobile">
						<div className="primary__inner">
							<div className="primary-content">
								<div className="primary-content__header">
									<div className="primary-content__header__left">
										銀行名
									</div>
									<div className="primary-content__header__right">
										{bank_name}
									</div>
								</div>
								<div className="primary-content__body">
									<div className="primary-title lv_2">
										<h2 className="primary-title__main">
											支店名を選択
										</h2>
									</div>
									<ul className="list japanese_syllabary margin_bottom">
										{katakana}
									</ul>
									<input
										type="button"
										className="btn-raised margin_right"
										value="戻る"
										onClick={(e) => this.handleClick(e)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { Bank: state.Bank }
}

export default connect(mapStateToProps)(SettingBankBranches)
