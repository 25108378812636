import React, { Component } from 'react'

import Dialog from 'material-ui/Dialog'
import request from 'axios'
import { glasConfig } from './../constants/Config'
import moment from 'moment'
import * as Fetch from './../util/Fetch'

export default class DialogOtherAnswers extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			pageRecords: [],
			totalRecords: 0,
			currentPage: 0,
			dataloaded: false,
		}
	}

	componentWillMount() {
		this.getAnswersOfOtherTeller()
	}

	createUrlQuery = (page) => {
		const questionId = this.props.questionId
		let postPage = page || 0
		let params = [
			`questionId=${questionId}`,
			`page=${postPage}`,
			`size=${10}`,
		]
		return `?${params.join('&')}`
	}

	handlePage(event, page) {
		event.preventDefault()
		this.getAnswersOfOtherTeller(page)
		this.setState({ currentPage: page })
		window.scrollTo(0, 0)
	}

	getAnswersOfOtherTeller = (page) => {
		const token = localStorage.getItem('token')
		const params = this.createUrlQuery(page)

		request
			.get(
				glasConfig.url_base +
					glasConfig.path_answer +
					'answer/' +
					params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let answersOfOtherTellers = []
					const tellerId = Fetch.tellerId()
					answersOfOtherTellers = response.data.data.list
					let totalRecords = response.data.data.totalRecord
					answersOfOtherTellers.forEach((element, index) => {
						if (element.tellerId == tellerId) {
							answersOfOtherTellers.splice(index, 1)
							totalRecords = totalRecords - 1
						}
					})
					this.setState({
						pageRecords: answersOfOtherTellers,
						totalRecords,
						dataloaded: true,
					})
				}
			})
			.catch((error) => console.log(error))
	}

	acceptReply = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = () => {
		this.setState({ show: true })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	showAnswers = () => {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<OtherAnswerItem item={current} index={index} key={index} />
				)
			}
		)
	}

	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}

		let currentPage = parseInt(this.state.currentPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.answermanagement.otherAnswer
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0
		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1
		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = currentPage - showHalfPage
		let loopEnd = currentPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (currentPage !== 0) {
			firstPage = false
		}
		if (currentPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			lastPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = currentPage > 0 ? currentPage - 1 : 0
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = currentPage !== endPage ? currentPage + 1 : endPage
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === currentPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item" key={i}>
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className="primary__inner">
				<div className="pager">
					<ul className="list">
						{prevNav}
						{pageNav}
						{nextNav}
					</ul>
				</div>
			</div>
		)
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				onRequestClose={this.closeDialog}
				className="dialogOtherAnswers"
			>
				<i
					class="material-icons tab__icons closeOtherAnswerSP hiddenPC"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<div className="otherAnwersTop">
					<span className="otherAnwersTitle">
						他の先生の回答 {this.props.totalAnswer}件
					</span>
					<span
						className="otherAnwersClose"
						onClick={this.closeDialog}
					>
						閉じる
					</span>
				</div>
				<div>{this.showAnswers()}</div>
				<div>{this.setPager()}</div>
			</Dialog>
		)
	}
}

const OtherAnswerItem = (props) => {
	const answerTime = moment(props.item.answerTime).format('YYYY/MM/DD')
	return (
		<div
			className={
				props.index === 0
					? 'otherAnswerItem otherAnswerItemFirst'
					: 'otherAnswerItem'
			}
		>
			<div className="otherAnswerTop">
				<div className="otherAnswerTime">{answerTime}</div>
				<div className="answerPostedLikeOtherDialog">
					<i
						className={
							props.item.totalLike === 0
								? 'fas fa-heart fa-heart0'
								: 'fas fa-heart'
						}
					/>
					<span>{props.item.totalLike}</span>
				</div>
			</div>
			<hr className="hidden-sp" style={{ color: '#eae8e8' }} />
			<div className="otherAnswerContent">
				{props.item.isBestAnswer && (
					<img src="/img/icBestAnswer.png" alt={''} />
				)}
				<p>{props.item.answerContent}</p>
			</div>
		</div>
	)
}
