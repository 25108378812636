import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomerManagementMenu from './CustomerManagementMenu'
import * as CustomerManagementActions from '../actions/CustomerManagementActions'
import * as moment from 'moment'
import request from 'axios'
import { glasConfig } from '../constants/Config'
import DialogWarningDeleteTimeLine from './DialogWarningDeleteTimeLine'
import Snackbar from 'material-ui/Snackbar'
import * as ChatRequestActions from '../actions/ChatRequestActions'

class CustomerManagementBlock extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageRecords: [],
			totalRecords: 0,
			nowPage: 0,
			dataloaded: false,
			removedBlock: false,
		}
	}

	componentDidMount() {
		const { dispatch } = this.props
		dispatch(CustomerManagementActions.blockGet(this.createUrlQuery()))
	}

	componentWillReceiveProps(nextProps) {
		let dataloaded = true
		this.setState({
			pageRecords: nextProps.CustomerManagement.data.list,
			totalRecords: nextProps.CustomerManagement.data.totalRecord,
			nowPage: nextProps.CustomerManagement.page,
			dataloaded,
		})
	}

	createUrlQuery(page) {
		let postPage = page || 0
		let params = ['size=10', `page=${postPage}`]
		return `?${params.join('&')}`
	}

	handlePage(event, page) {
		const { dispatch } = this.props
		event.preventDefault()
		dispatch(
			CustomerManagementActions.blockGet(this.createUrlQuery(page), page)
		)
	}

	onUnBlock(userId) {
		const { dispatch } = this.props
		request
			.delete(
				glasConfig.url_base +
				glasConfig.path_block +
				`?friendId=${userId}`,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ removedBlock: true })
					setTimeout(
						() => this.setState({ removedBlock: false }),
						1500
					)
					dispatch(
						CustomerManagementActions.blockGet(
							this.createUrlQuery(
								this.state.totalRecords % 10 === 1 &&
									this.state.nowPage > 0
									? this.state.nowPage - 1
									: this.state.nowPage
							),
							this.state.nowPage
						)
					)
					this.enableChatDraft(userId)
				}
			})
			.catch((error) => {
				throw error
			})
	}

	enableChatDraft(userId) {
		const { dispatch } = this.props
		let data = {
			'userId': userId,
			'serverTime': moment().utc().format("YYYYMMDDHHmmss")
		}
		dispatch(ChatRequestActions.changeStatusChatDraft(data))

	}


	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}

		let nowPage = parseInt(this.state.nowPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.usermanagement.block
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0
		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1

		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = nowPage - showHalfPage
		let loopEnd = nowPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (nowPage !== 0) {
			firstPage = false
		}
		if (nowPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			lastPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = nowPage > 0 ? nowPage - 1 : 0
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = nowPage !== endPage ? nowPage + 1 : endPage
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === nowPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item">
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className={pageNav.length > 0 ? 'primary__inner' : ''}>
				<div className={pageNav.length > 0 ? 'pager' : ''}>
					<ul className="list">
						{prevNav}
						{pageNav}
						{nextNav}
					</ul>
				</div>
			</div>
		)
	}

	getNotificationHistory() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<tr key={current.notificationSettingId}>
						<td>{current.userName}</td>
						<td>{current.requestChat}</td>
						<td>{current.requestCall}</td>
						<td>
							{
								current.lastChatTime ?
									<>
										{moment(current.lastChatTime, 'YYYYMDHm')
											.add(9, 'hours')
											.format('YYYY/MM/DD H:mm')}
									</>
									: '-'
							}

						</td>
						<td>
							{moment(current.blockTime, 'YYYYMDHm')
								.add(9, 'hours')
								.format('YYYY/MM/DD H:mm')}
						</td>
						<td>
							<button
								className="editNoti"
								onClick={() => this.onUnBlock(current.userId)}
							>
								解除
							</button>
						</td>
					</tr>
				)
			}
		)
	}

	getNotificationHistorySP() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<div className="warpper-item-noti" key={current.userId}>
						<div className="content-item">
							<ul className="list-info block-sp">
								<li className="block-user-name">
									{current.userName}
								</li>
								<li className="block-chat">
									<span className="blockSpan1">
										チャット相談
									</span>
									<span>: {current.requestChat}回</span>
								</li>
								<li className="block-call">
									<span className="blockSpan1">電話相談</span>
									<span>: {current.requestCall}回</span>{' '}
								</li>
								<li className="info-user">
									<span className="blockSpan1">最終対応</span>
									:{' '}
									{moment(current.lastChatTime, 'YYYYMDHm')
										.add(9, 'hours')
										.format('YYYY/MM/DD H:mm')}
								</li>
								<li className="last-row-block">
									<button
										onClick={() =>
											this.onUnBlock(current.userId)
										}
										className="unBlockSp"
									>
										解除
									</button>
								</li>
							</ul>
						</div>
					</div>
				)
			}
		)
	}

	render() {
		return (
			<div className="content">
				<h1 className="content__title hidden-sp">顧客管理</h1>
				<CustomerManagementMenu />
				<div className="primary primary-block  primaryNotification">
					<div className="primary-title hidden-sp">
						<h1 className="primary-title__main">ブロックリスト</h1>
						<div className="frame-table-noti hidden-sp">
							<table className="table-data table-data-block">
								<thead>
									<tr>
										<th className="table-align-center">
											名前
										</th>
										<th className="table-align-center">
											チャット相談回数
										</th>
										<th className="table-align-center">
											電話相談回数
										</th>
										<th className="table-align-center">
											最終対応日時
										</th>
										<th className="table-align-center">
											ブロック日時
										</th>
										<th className="table-align-center"></th>
									</tr>
								</thead>
								<tbody>{this.getNotificationHistory()}</tbody>
							</table>
						</div>
					</div>
					<div className="customer-list-sp display-mobile">
						{this.getNotificationHistorySP()}
					</div>
					{this.setPager()}
				</div>
				<DialogWarningDeleteTimeLine
					ref={(ref) => (this.confirmDelNotificationDialog = ref)}
					dispatch={this.props.dispatch}
				/>
				<Snackbar
					open={this.state.removedBlock}
					message={'ブロックを解除しました。'}
					autoHideDuration={1500}
				/>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		CustomerManagement: store.CustomerManagement,
	}
}

export default connect(mapStateToProps)(CustomerManagementBlock)
