import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as MyAccountActions from '../actions/MyAccountActions'
import { TYPE_OPTION_BELL_DEVEICE, glasConfig } from '../constants/Config'
import { denyStatuses } from '../constants/VerifyStatus'
import SettingMenu from './SettingMenu'
import TextField from 'material-ui/TextField'
import Snackbar from 'material-ui/Snackbar'
import Checkbox from 'material-ui/Checkbox'
import {
	receiveNotiList,
	receiveNotiListSP, ringStoneListSP
} from '../constants/ReceiveNotiList'
import '../../App.css'
import RequireSubmitChanges from '../components/RequireSubmitChanges'
import { isMobile } from 'react-device-detect';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
import { playASound, pauseSound } from '../util/Sound'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
class SettingAccount extends Component {
	constructor(props) {
		super(props)

		this.state = {
			email: '',
			listCheckbox: {
				receiveNotiByEmail: false,
				receiveReviewNotiByEmail: false,
				receiveFavoriteNotiByEmail: false,
				receiveReloginNotiByEmail: false,
			},
			error: false,
			message: '',
			loading: false,
			checkSubmit: true,
			show: false,
			targetPathName: '',
			cancel: false,
			ringStone: props.MyProfile.data.ringtoneNumber,
			playRingStone: '',
			isDisableVibration: props.MyProfile.data.isDisableVibration || false,
			isDisableSoundRequestChat: props.MyProfile.data.isDisableSoundRequestChat || false,
			isDisableSoundMessage: props.MyProfile.data.isDisableSoundMessage || false
		}
	}

	componentWillMount() {
		const { dispatch, Account } = this.props
		dispatch(MyAccountActions.fetch())
	}

	componentWillUnmount() {
		pauseSound()
		this.setState({ playRingStone: '' })
	}



	componentDidUpdate(prevProps, prevState) {
		if (prevProps.MyAccount.data !== this.props.MyAccount.data) {
			this.setState({
				email: this.props.MyAccount.data.email,
				listCheckbox: {
					...this.state.listCheckbox,
					receiveNotiByEmail:
						this.props.MyAccount.data.receiveNotiByEmail,
					receiveReviewNotiByEmail:
						this.props.MyAccount.data.receiveReviewNotiByEmail,
					receiveFavoriteNotiByEmail:
						this.props.MyAccount.data.receiveFavoriteNotiByEmail,
					receiveReloginNotiByEmail:
						this.props.MyAccount.data.receiveReloginNotiByEmail,
				},
			})
		}

	}

	componentWillReceiveProps(preProps, nextProps) {
		if (
			(this.state.loading && !nextProps.MyAccount.loading) ||
			(this.state.loading && nextProps.MyAccount.loading)
		) {
			this.setState({ loading: false })
		}
		if (
			!this.props.MyAccount.loadedGetAccount &&
			nextProps.MyAccount.loadedGetAccount
		) {
			this.setState({
				email: nextProps.MyAccount.data.email,
				listCheckbox: {
					...this.state.listCheckbox,
					receiveNotiByEmail:
						nextProps.MyAccount.data.receiveNotiByEmail,
					receiveReviewNotiByEmail:
						nextProps.MyAccount.data.receiveReviewNotiByEmail,
					receiveFavoriteNotiByEmail:
						nextProps.MyAccount.data.receiveFavoriteNotiByEmail,
					receiveReloginNotiByEmail:
						nextProps.MyAccount.data.receiveReloginNotiByEmail,
				},
			})
		}
	}

	componentDidMount() {
		this.props.history.block((location, action) => {
			// const currentPathName = window.location.pathname;
			if (!this.state.checkSubmit && !this.state.cancel) {
				this.setState({ show: true, targetPathName: location.pathname })
				return false
			}
		})
	}

	onClickCancel = () => {
		this.setState({ show: false })
	}

	onClickOk = () => {
		this.setState({ checkSubmit: true, show: false }, () => {
			this.props.history.push(this.state.targetPathName)
		})
	}

	handleSubmit(e) {
		// 更新
		if (e.target.name === 'settingAccount') {
			this.setState({ checkSubmit: true })
			const { dispatch } = this.props

			e.preventDefault()

			let email = document.settingAccount.email.value
			let listCheckbox = this.state.listCheckbox
			this.setState({ loading: true })
			// let data = {
			//   email: email,
			//   listCheckbox: listCheckbox
			// }

			dispatch(MyAccountActions.put(email, listCheckbox))
		}
	}

	handleChange(e) {
		let email = ''

		if (e.target.id === 'mail_address') {
			email = e.target.value
		}

		this.setState({
			email,
			checkSubmit: false,
		})
	}

	handleClick(event, isInputChecked, receiveState) {
		this.setState({
			checkSubmit: false,
			listCheckbox: {
				...this.state.listCheckbox,
				[receiveState]: isInputChecked,
			},
		})
	}

	handleSubmitRingStone(e) {
		this.setState({ checkSubmit: true })
		e.preventDefault()
		pauseSound()
		let paramsUrl = {
			ringtoneNumber: this.state.ringStone,
			isDisableVibration: this.state.isDisableVibration,
			isDisableSoundRequestChat: this.state.isDisableSoundRequestChat,
			isDisableSoundMessage: this.state.isDisableSoundMessage
		}
		this.props.dispatch(MyAccountActions.updateRingTone(this.props.MyProfile, paramsUrl, () => {
			this.setState({
				error: true,
				message: `正常に保存しました。`,
			})
		}))
		let dataRingStone = ringStoneListSP.find(s => s.id === this.state.ringStone) || {}
		localStorage.setItem('ringStone', JSON.stringify(dataRingStone))
	}

	handleChooseRingStone(ringStone) {
		this.setState({ ringStone, checkSubmit: false, })
	}

	handleChooseRungDevice(value) {
		this.setState({isDisableVibration: value == TYPE_OPTION_BELL_DEVEICE.OPTION_ON ? false : true})
	}

	handleSettingMelodyRequestChat(value) {
		this.setState({isDisableSoundRequestChat: value == TYPE_OPTION_BELL_DEVEICE.OPTION_ON ? false : true})
	}
	handleSettingMelodyNewChat(value) {
		this.setState({isDisableSoundMessage: value == TYPE_OPTION_BELL_DEVEICE.OPTION_ON ? false : true})
	}

	playRingStone(e, ringStone, file) {
		e.preventDefault()
		if (ringStone !== this.state.playRingStone) {
			playASound(file, () => {
				this.setState({ playRingStone: '' })
			})
			this.setState({ playRingStone: ringStone })
		} else {
			pauseSound()
			this.setState({ playRingStone: '' })
		}
	}
	componentWillReceiveProps() {
		pauseSound()
		this.setState({ playRingStone: '' })
	}

	handleKeyUp(e) {
		let email = ''

		if (e.target.id === 'mail_address') {
			email = e.target.value
		}

		this.setState({
			email,
		})
	}

	checkAgeVerify() {
		const { MyAccount } = this.props

		if (
			MyAccount.data.verifyAge === null ||
			parseInt(MyAccount.data.verifyAge, 10) === -2
		) {
			return ''
		}
		let text = ''
		denyStatuses.forEach((status) => {
			if (MyAccount.data.verifyAge === status.name) {
				text = status.text
			}
		})

		let verifyState = ''
		if (
			MyAccount.data.verifyAge === 'Reviewing' ||
			MyAccount.data.verifyAge === 'WaitingBoss' ||
			MyAccount.data.verifyAge === 'WaitingEmployee' ||
			MyAccount.data.verifyAge === 'Reviewed' ||
			parseInt(MyAccount.data.verifyAge, 10) === 0
		) {
			verifyState = <strong>審査中</strong>
		} else if (
			MyAccount.data.verifyAge === 'Verified' ||
			parseInt(MyAccount.data.verifyAge, 10) === 1
		) {
			verifyState = <strong>認証済み。お仕事を開始できます。</strong>
		} else if (text != '') {
			verifyState = <strong>審査NG</strong>
		}

		return (
			<div>
				<dl className="definition title_colon">
					<dt className="definition__title">年齢認証状況</dt>
					<dd className="definition__data">
						<div className="text-accent">{verifyState}</div>
					</dd>
				</dl>
				<p>
					<span dangerouslySetInnerHTML={{ __html: text }} />
				</p>
			</div>
		)
	}

	handleErrorClose() {
		this.setState({
			error: false,
			message: '',
		})
	}

	render() {
		const { MyAccount, MyProfile } = this.props

		const listCheckbox = receiveNotiList.map((checkbox, index) => {
			return (
				<div className="list-check-box" key={index}>
					<Checkbox
						style={{ marginBottom: 10 }}
						iconStyle={{ margin: 0 }}
						label={checkbox.label}
						labelStyle={{
							paddingTop: 3,
							fontFamily: 'Times New Roman',
							fontSize: 13,
						}}
						id={checkbox.id}
						checked={this.state.listCheckbox[checkbox.receiveState]}
						onCheck={(e, i, receiveState) =>
							this.handleClick(e, i, checkbox.receiveState)
						}
					/>
				</div>
			)
		})

		const listCheckboxRingStone = ringStoneListSP.map((checkbox, index) => {
			return <div className="list-check-box" style={{ marginBottom: 15, display: 'flex', flexDirection: 'row', maxWidth: isMobile ? '100%' : '30%' }} key={index}>
				<Checkbox
					style={{ marginBottom: 25, paddingTop: 7 }}
					iconStyle={{ marginRight: 15 }}
					label={checkbox.label}
					labelStyle={{ fontFamily: "HiraKakuPro-W3", fontSize: 15 }}
					id={checkbox.id}
					checked={this.state.ringStone === checkbox.id}
					onCheck={() => this.handleChooseRingStone(checkbox.id)}
				/>

				{
					this.state.playRingStone === checkbox.checkboxId ?
						<button style={{ backgroundColor: '#ec407a', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 150, }} className="btn-raised"
							onClick={(e) => this.playRingStone(e, checkbox.checkboxId, checkbox.file)}
						>
							<Pause />
							<span>再生中</span>
						</button>
						:
						<button style={{ backgroundColor: '#f9f9f9', color: '#939393', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 150 }} className="btn-raised"
							onClick={(e) => this.playRingStone(e, checkbox.checkboxId, checkbox.file)}
						>
							<PlayArrow />
							<span>再生する</span>
						</button>
				}
			</div>
		})

		const listCheckboxSP = receiveNotiListSP.map((checkbox, index) => {
			return (
				<div className="list-check-box-sp" key={index}>
					<Checkbox
						style={{ marginBottom: 10 }}
						iconStyle={{ margin: 0 }}
						label={checkbox.label}
						labelStyle={{
							paddingTop: 3,
							fontFamily: 'Times New Roman',
							fontSize: 12,
						}}
						id={checkbox.id}
						checked={this.state.listCheckbox[checkbox.receiveState]}
						onCheck={(e, i, receiveState) =>
							this.handleClick(e, i, checkbox.receiveState)
						}
					/>
				</div>
			)
		})

		return (
			<div className="content">
				<h1 className="content__title">設定</h1>
				<SettingMenu />
				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main">アカウント</h1>
						<p className="primary-title__subtitle title-setting-account">
							お客様側には公開されません。
						</p>
					</div>
					<div className="primary__inner">
						<form
							name="settingAccount"
							onSubmit={(e) => this.handleSubmit(e)}
						>
							<dl className="definition">
								<dt className="definition__title write_down">
									本名
								</dt>
								<dt className="definition__title write_down_sp">
									本名
								</dt>
								<dd className="definition__data margin_bottom">
									<p>
										{MyAccount.data.realName
											? MyAccount.data.realName
											: MyProfile.data.realName}
									</p>
								</dd>
								<dt className="definition__title write_down">
									メールアドレス
								</dt>
								<dt className="definition__title write_down_sp">
									メールアドレス
								</dt>
								<dd className="definition__data">
									<TextField
										className="textfield__input"
										type="text"
										id="mail_address"
										name="email"
										value={this.state.email}
										hintText="メールアドレス"
										onKeyUp={(e) => this.handleKeyUp(e)}
										onChange={(e) => this.handleChange(e)}
									/>
									<span
										style={{
											color: 'red',
											marginLeft: '8px',
											fontSize: 17,
										}}
									>
										{' '}
										{MyAccount.data.type ===
											'MYINFO_ACCOUNT_PUT_FALSE'
											? '* メールアドレスは既に存在しています、または、正しい形式ではありません。'
											: ''}
									</span>
								</dd>
								{listCheckbox}
								<dt className="title-list-check-sp">通知</dt>
								{listCheckboxSP}
							</dl>
							<div className="btn-wrap">
								<input
									type="submit"
									className="btn-raised color_default"
									value="登録"
								/>
							</div>
						</form>
					</div>
				</div>

				{/*  */}

				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main" >チャット着信メロディ</h1>
						<p className="primary-title__subtitle title-setting-account">お好きなメロディを選択してください </p>
					</div>
					<div className="primary__inner">
						<form name="settingRingStone" onSubmit={e => this.handleSubmitRingStone(e)}>
							<dl className="definition">
								{listCheckboxRingStone}
								<dt className="title-list-check-sp" >通知</dt>
							</dl>
							<div className="primary__setting_rung">
								<h1 class="primary-title__main">チャット着信時の振動</h1>
								<p class="primary-title__subtitle title-setting-account">&nbsp;依頼があったときのスマホのバイブ有効無効を選べます</p>
								<div>
									<FormControl>
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label"
											defaultValue={this.state.isDisableVibration ? TYPE_OPTION_BELL_DEVEICE.OPTION_OFF : TYPE_OPTION_BELL_DEVEICE.OPTION_ON}
											name="radio-buttons-group"
											className="list_gender"
											onChange={(e, value) => this.handleChooseRungDevice(value)}
										>
											<FormControlLabel value={TYPE_OPTION_BELL_DEVEICE.OPTION_ON} control={
													<Radio className={!this.state.isDisableVibration ? "radio-checked" : "radio-unchecked"} />
												}
												label={TYPE_OPTION_BELL_DEVEICE.OPTION_ON} />
											<FormControlLabel value={TYPE_OPTION_BELL_DEVEICE.OPTION_OFF} control={
													<Radio className={!this.state.isDisableVibration ? "radio-unchecked" : "radio-checked"} />
												} label={TYPE_OPTION_BELL_DEVEICE.OPTION_OFF} />
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							<div className="primary__setting_rung">
							<h1 class="primary-title__main">チャット鑑定中のチャット着信メロディ</h1>
								<p class="primary-title__subtitle title-setting-account">&nbsp;鑑定中、新たに依頼がきたときのメロディのオンオフを選べます</p>
								<div>
									<FormControl>
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label"
											defaultValue={this.state.isDisableSoundRequestChat ? TYPE_OPTION_BELL_DEVEICE.OPTION_OFF : TYPE_OPTION_BELL_DEVEICE.OPTION_ON}
											name="radio-buttons-group"
											className="list_gender"
											onChange={(e, value) => this.handleSettingMelodyRequestChat(value)}
										>
											<FormControlLabel value={TYPE_OPTION_BELL_DEVEICE.OPTION_ON} control={
													<Radio className={!this.state.isDisableSoundRequestChat ? "radio-checked" : "radio-unchecked"} />
												}
												label={TYPE_OPTION_BELL_DEVEICE.OPTION_ON} />
											<FormControlLabel value={TYPE_OPTION_BELL_DEVEICE.OPTION_OFF} control={
													<Radio className={!this.state.isDisableSoundRequestChat ? "radio-unchecked" : "radio-checked"} />
												} label={TYPE_OPTION_BELL_DEVEICE.OPTION_OFF} />
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							<div className="primary__setting_rung">
							<h1 class="primary-title__main">チャット鑑定中のメッセージ通知音</h1>
								<p class="primary-title__subtitle title-setting-account">&nbsp;鑑定中、チャットが届いたときに鳴る通知音のオンオフを選べます</p>
								<div>
									<FormControl>
										<RadioGroup
											aria-labelledby="demo-radio-buttons-group-label"
											defaultValue={this.state.isDisableSoundMessage ? TYPE_OPTION_BELL_DEVEICE.OPTION_OFF : TYPE_OPTION_BELL_DEVEICE.OPTION_ON}
											name="radio-buttons-group"
											className="list_gender"
											onChange={(e, value) => this.handleSettingMelodyNewChat(value)}
										>
											<FormControlLabel value={TYPE_OPTION_BELL_DEVEICE.OPTION_ON} control={
													<Radio className={!this.state.isDisableSoundMessage ? "radio-checked" : "radio-unchecked"} />
												}
												label={TYPE_OPTION_BELL_DEVEICE.OPTION_ON} />
											<FormControlLabel value={TYPE_OPTION_BELL_DEVEICE.OPTION_OFF} control={
													<Radio className={!this.state.isDisableSoundMessage ? "radio-unchecked" : "radio-checked"} />
												} label={TYPE_OPTION_BELL_DEVEICE.OPTION_OFF} />
										</RadioGroup>
									</FormControl>
								</div>
							</div>
							<div className="btn-wrap">
								<input type="submit" className="btn-raised color_default" value="設定" />
							</div>
						</form>
					</div>
				</div>
				{/*  */}

				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main">年齢認証</h1>
					</div>
					<div className="primary__inner">
						<p className="text-disabled margin_bottom">
							ご本人様確認と18歳以上であることの確認のため身分証の提出が必要です。{' '}
							<br />
							事務局での確認が済みますと、お仕事を開始できます。
						</p>
						{MyAccount.data.verifyAge !== 'Verified' && (
							<div className="btn-wrap margin_bottom">
								<Link
									to="/home/setting/ageauth"
									className="btn-raised color_accent"
								>
									年齢認証を行う
								</Link>
							</div>
						)}
						{this.checkAgeVerify()}
					</div>
				</div>
				<div className="box-shadow">
					<div className="primary__inner">
						<Link
							className="link-setting-pass"
							to="/home/setting/password"
						>
							パスワード変更はこちら
						</Link>
					</div>
				</div>
				<Snackbar
					open={this.state.error}
					message={this.state.message}
					autoHideDuration={2000}
					onRequestClose={(e) => this.handleErrorClose(e)}
				/>
				<RequireSubmitChanges
					{...this.props}
					onClickCancel={() => this.onClickCancel()}
					onClickOk={() => this.onClickOk()}
					show={this.state.show}
					targetPathName={this.state.targetPathName}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyAccount: state.MyAccount,
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(SettingAccount)
