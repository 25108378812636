import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
	glasConfig,
	katakanaList,
} from '../constants/Config'
import SettingMenu from './SettingMenu'
import * as MyBankActions from '../actions/MyBankActions'
import TextField from 'material-ui/TextField'
import Snackbar from 'material-ui/Snackbar'
import $ from 'jquery'
import RewardMenu from './RewardMenu'

/**
 * 口座情報入力
 */

class SettingBankAccount extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bankCode: this.props.match.params.bankCode,
			branchCode: this.props.match.params.branchCode,
			back: false,
			disabled: 'disabled',
			noValid: false,
			nameValid: false,
			accountNo: null,
			accountName: null,
			inputError: '',
			snackOpen: false,
		}
	}

	componentWillMount() {
		const {
			dispatch,
			Bank: {
				data: { accountName, accountNo },
			},
		} = this.props
		const bankCode = sessionStorage.getItem('bankCode')
		if (accountName && accountNo) {
			this.setState(() => ({ disabled: '', accountName, accountNo }))
		}
		dispatch(
			MyBankActions.getBranchCode(
				this.state.bankCode,
				this.state.branchCode
			)
		)
		dispatch(MyBankActions.getBankCode(bankCode))
	}

	handleSubmit(event) {
		event.preventDefault()

		this.addLeadZero()

		const { dispatch } = this.props

		let params = {
			bankCode: this.state.bankCode,
			branchCode: this.state.branchCode,
			accountNo: this.state.accountNo,
			accountName: this.state.accountName,
		}

		dispatch(MyBankActions.accountConfirm(params))
		this.props.history.push('/home/setting/bank/account/confirm', params)
	}

	handleBlur(event) {
		if (event.target.name === 'accountNo') {
			this.addLeadZero()
		}
	}

	addLeadZero() {
		let tmpValue = document.branchForm.accountNo.value
		if (
			tmpValue.length > 0 &&
			tmpValue.length < glasConfig.bank.accountNo
		) {
			let start = glasConfig.bank.accountNo - tmpValue.length
			let zero = ''
			for (start; 0 < start; start--) {
				zero += '0'
			}
			this.setState({
				accountNo: zero + tmpValue,
			})
		}
	}

	handleClick(event) {
		this.setState({
			back: true,
		})
	}

	handleRequestClose(event) {
		this.setState({
			snackOpen: false,
		})
	}

	handleChange(event) {
		let no = false
		let name = false

		let noValid = false
		let nameValid = false

		let inputError = ''
		let snackOpen = false

		let accountNo = ''
		let accountName = ''
		if (document.branchForm.accountNo.value.length === 0) {
			no = false
		} else {
			no = true
			if (/[^0-9]/g.test(document.branchForm.accountNo.value)) {
				noValid = true
				snackOpen = true
				inputError = '口座番号は数字で入力してください'
			}
			accountNo = document.branchForm.accountNo.value.replace(
				/[^0-9]*/g,
				''
			)
		}

		if (document.branchForm.accountName.value.length === 0) {
			name = false
		} else {
			name = true
			if (/[^ァ-ヶ-ー「）」]/g.test(document.branchForm.accountName.value)) {
				nameValid = true
				snackOpen = true
				inputError = '口座名義はカタカナで入力してください'
			}
			accountName = document.branchForm.accountName.value
		}

		if (no && name && !noValid && !nameValid) {
			this.setState({
				disabled: '',
				noValid: noValid,
				accountNo: accountNo,
				nameValid: nameValid,
				accountName: accountName,
				inputError: inputError,
				snackOpen: snackOpen,
			})
		} else {
			this.setState({
				disabled: 'disabled',
				noValid: noValid,
				accountNo: accountNo,
				nameValid: nameValid,
				accountName: accountName,
				inputError: inputError,
				snackOpen: snackOpen,
			})
		}
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('銀行口座情報編集')
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
		$('.menu-header').removeClass('hiddenClass')
	}

	render() {
		if (this.state.back) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -3)
						.join('/')}/branches/${this.state.bankCode}`}
				/>
			)
		}

		let bank_name = ''
		let branch_name = ''

		if (this.props.Bank.bank.length) {
			bank_name = this.props.Bank.bank[0].bank_name
		}

		if (this.props.Bank.branch.length) {
			branch_name = this.props.Bank.branch[0].siten_name
		}

		return (
			<div className="content bank-detail bank-account-setting">
				<h1 className="content__title">設定</h1>
				{/* <SettingMenu /> */}
				<RewardMenu />
				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main">銀行口座情報</h1>
					</div>
					<div className="primary__inner bank-account-info">
						<div className="primary-content">
							<div className="primary-content__body">
								<div className="bank-box">
									<table className="table-th-left">
										<tbody>
											<tr>
												<th className="table-align-left">
													銀行名
												</th>
												<td className="table-align-left">
													{bank_name}
												</td>
											</tr>
											<tr>
												<th className="table-align-left">
													支店名
												</th>
												<td className="table-align-left">
													{branch_name}(支店コード：
													{this.state.branchCode})
												</td>
											</tr>
											<tr>
												<th className="table-align-left">
													口座種別
												</th>
												<td className="table-align-left">
													普通預金
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="primary-title">
						<h1 className="primary-title__main">銀行口座情報</h1>
					</div>
					<div className="primary__inner">
						<div className="primary-content">
							<div className="primary-content__body">
								<div className="primary-title lv_2 display-mobile">
									<h2 className="primary-title__main">
										口座番号・口座名義を入力
									</h2>
								</div>
								<form
									name="branchForm"
									onSubmit={(e) => this.handleSubmit(e)}
								>
									<dl className="definition margin_bottom">
										<dd className="definition__data">
											<div className="textfield">
												<TextField
													hintText="口座番号を入力"
													maxLength={
														glasConfig.bank
															.accountNo
													}
													className="textfield__input"
													type="text"
													name="accountNo"
													id="bank_account_number"
													defaultValue={
														this.props.Bank.data
															.accountNo
													}
													onBlur={(e) =>
														this.handleBlur(e)
													}
													onChange={(e) =>
														this.handleChange(e)
													}
												/>
											</div>
											<p className="textfield-note hidden-mobile">
												※7桁未満の場合は自動で先頭に0が追加されます
											</p>
										</dd>
										<dd className="definition__data">
											<div className="textfield">
												<TextField
													hintText="口座名義を入力"
													className="textfield__input"
													type="text"
													id="bank_account"
													name="accountName"
													defaultValue={
														this.props.Bank.data
															.accountName
													}
													onChange={(e) =>
														this.handleChange(e)
													}
												/>
											</div>
										</dd>
									</dl>
									<input
										type="button"
										name="returnButton"
										className="btn-raised margin_right"
										onClick={(e) => this.handleClick(e)}
										value="戻る"
									/>
									<input
										type="submit"
										name="nextButton"
										className="btn-raised color_default"
										value="次へ"
										disabled={this.state.disabled}
									/>
								</form>
							</div>
						</div>
					</div>
				</div>
				<Snackbar
					open={this.state.snackOpen}
					message={this.state.inputError}
					autoHideDuration={4000}
					onRequestClose={(e) => this.handleRequestClose(e)}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { Bank: state.Bank }
}

export default connect(mapStateToProps)(SettingBankAccount)
