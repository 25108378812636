const FirebaseInitConfig = () => {
	if (process.env.REACT_APP_ENV === 'prod') {
		return {
			apiKey: 'AIzaSyApYXE3zEkMoM_GH-vx9BY6xxxnh1QlpG4',
			authDomain: 'stella-app-50889.firebaseapp.com',
			databaseURL: 'https://stella-app-50889.firebaseio.com',
			projectId: 'stella-app-50889',
			storageBucket: 'stella-app-50889.appspot.com',
			messagingSenderId: '319979049056',
			appId: '1:319979049056:android:0442c4a117c4b1a1',
		}
	} else {
		return {
			apiKey: 'AIzaSyCZLNouTiT0VdrfuhZRNQBpPXpR-UB_7to',
			authDomain: 'fir-test-8e1ce.firebaseapp.com',
			databaseURL: 'https://fir-test-8e1ce.firebaseio.com',
			projectId: 'fir-test-8e1ce',
			storageBucket: 'fir-test-8e1ce.appspot.com',
			messagingSenderId: '831487422014',
			appId: '1:831487422014:android:0442c4a117c4b1a1',
		}
	}
}

export const Config = FirebaseInitConfig()
