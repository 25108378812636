/**
 * チャット画面 メッセージ履歴と入力フォーム
 */
import React, { Component } from 'react'
import ChatContentMessageDetails from './ChatContentMessageDetails'
import moment from 'moment'
import $ from 'jquery'
import { isMobile } from 'react-device-detect'

export default class ChatContentMessage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			lastmsg: {},
			length: 0,
			readlength: 0,
			userId: '',
			idMessageOfflineOpened: '',
		}

		if (!this.props.hasSessionStorage('curScrollTop')) {
			this.props.setSessionStorage('curScrollTop', '')
		}

		this.curHeight = 0
		this.getOnBottom = this.getOnBottom.bind(this)
		this.setOnBottom = this.setOnBottom.bind(this)
	}

	componentDidMount() {
		this.setScroll()
	}

	setScroll(length) {
		const messageList = $(this.refs.messageList)
		const actualList = messageList.find('ul')
		if (isMobile) {
			setTimeout(() => {
				if (
					length ||
					(this.state.length <= 15 && this.state.length !== 0)
				) {
					window.scrollTo(0, actualList.height())
				} else {
					window.scrollTo(0, window.innerHeight)
				}
			}, 200)
			if (this.state.length >= 15) {
				setTimeout(() => {
					window.addEventListener('scroll', this.handleScrollMobile)
				}, 500)
			}
		}
	}

	handleScrollMobile = (event) => {
		const { loadFunc } = this.props
		// let scrollTop = event.srcElement.body.scrollTop
		// if (isAndroid) {
		if (window.pageYOffset === 0) {
			loadFunc()
		}
		// } else {
		//   if (scrollTop > 100 && scrollTop < 200) {
		//     loadFunc()
		//   }
		// }
	}

	componentWillUnmount() {
		if (this.state.length >= 15) {
			setTimeout(() => {
				window.removeEventListener('scroll', this.handleScrollMobile)
			}, 500)
		}
		
	}

	componentWillReceiveProps(nextProps) {
		const lastLength = this.state.length
		this.setState(
			{
				lastmsg: nextProps.ChatLog[nextProps.ChatLog.length - 1],
				length: nextProps.ChatLog.length,
				readlength: Object.keys(nextProps.ReadLog).length,
			},
			() => {
				if (
					lastLength !== this.state.length &&
					nextProps.UserInfo.userId === this.props.UserInfo.userId
				) {
					let numberMes =
						parseInt(nextProps.ChatLog.length) -
						parseInt(lastLength)
					if (numberMes === 1) {
						this.setScroll(numberMes)
					} else {
						this.setScroll()
					}
				}
			}
		)
		if (nextProps.UserInfo.userId !== this.props.UserInfo.userId) {
			this.props.setSessionStorage('curScrollTop', '')
			this.setState({ userId: nextProps.UserInfo.userId })
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.UserInfo.userId !== this.props.UserInfo.userId) {
			window.scrollTo(0, document.body.scrollHeight)
			return true
		} else {
			if (
				nextProps.idMessageOfflineOpened !==
				this.props.idMessageOfflineOpened
			) {
				this.setState({
					idMessageOfflineOpened: nextProps.idMessageOfflineOpened,
				})
				return true
			}
			if (!this.state.lastmsg) {
				return true
			}
			if (nextProps.ChatLog.length !== this.state.length) {
				return true
			}

			if (
				Object.keys(nextProps.ReadLog).length !== this.state.readlength
			) {
				return true
			}

			const nowMsgId =
				this.state.lastmsg.msgId === undefined
					? ''
					: this.state.lastmsg.msgId
			const newMsgId =
				nextState.lastmsg === undefined ? '' : nextState.lastmsg.msgId

			if (nowMsgId === newMsgId) {
				return false
			}
			return false
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.idMessageOfflineOpened !==
			prevProps.idMessageOfflineOpened
		) {
			return
		}
		const messageList = $(this.refs.messageList)
		const actualList = messageList.find('ul')
		this.curHeight = actualList.height()

		if (this.state.readlength !== prevState.readlength) {
			if (this.props.getSessionStorage('curScrollTop') === '-1') {
				let scrollTop =
					actualList.height() -
					this.props.getSessionStorage('curHeight')
				messageList.prop('scrollTop', scrollTop)
				this.props.setSessionStorage('curScrollTop', scrollTop)
			} else {
				messageList.prop('scrollTop', 9999)
			}

			return
		}

		const nowMsgId =
			typeof this.state.lastmsg === 'undefined'
				? ''
				: this.state.lastmsg.msgId
		const oldMsgId =
			typeof prevState.lastmsg === 'undefined'
				? ''
				: prevState.lastmsg.msgId
		let scrollTop = 1
		if (messageList.length) {
			if (
				!oldMsgId &&
				this.props.getSessionStorage('curScrollTop') === '-1'
			) {
				scrollTop =
					actualList.height() -
					this.props.getSessionStorage('curHeight')
				this.setOnBottom(0)
			} else if (oldMsgId !== nowMsgId) {
				scrollTop = actualList.height() + 16 - messageList.height()
				this.setOnBottom(1)
			}
			messageList.prop('scrollTop', scrollTop)
			this.props.setSessionStorage('curScrollTop', scrollTop)
		}
	}

	getOnBottom() {
		return this.props.getSessionStorage('onBottom')
	}

	setOnBottom(onBottom) {
		this.props.setSessionStorage('onBottom', onBottom)
	}

	render() {
		const { ChatLog, ReadLog, UserInfo, MyProfile, loadFunc } = this.props
		//チャットログを表示
		if (!ChatLog.length) {
			return ''
		}

		moment.locale('ja')
		this.beforDate = ''
		const ChatList = Object.values(ChatLog).map((res) => {
			//const msgTime = moment(res.serverTime,"YYYYMMDDHHmmss").add(9,'hours').format('HH:mm')

			//'YYYY/MM/DD'は非推奨フォーマット
			let msgDate = res.serverTime
				? moment(res.serverTime, 'YYYYMMDDHHmmss')
						.add(9, 'hours')
						.format('YYYYMMDD')
				: moment.unix(res.senddate).format('YYYYMMDD')
			const tmpDate = msgDate
			if (this.beforDate) {
				if (moment(msgDate).isSame(this.beforDate)) {
					msgDate = ''
				}
			}
			this.beforDate = tmpDate

			if (msgDate) {
				if (moment(msgDate).year() !== moment().year()) {
					msgDate = moment(msgDate).format('YYYY/MM/DD')
				} else {
					const diff = moment().diff(msgDate, 'days')
					if (!diff) {
						msgDate = '今日'
					} else if (diff === 1) {
						msgDate = '昨日'
					} else if (diff > 1) {
						msgDate = msgDate = moment(msgDate).format('MM/DD')
					}
				}
			}

			//通知により既読になったもの
			if (res.readTime === undefined && ReadLog[res.msgId]) {
				res.readTime = ReadLog[res.msgId]
			}

			return (
				<li key={res.msgId + res.serverTime} className="list__item">
					{msgDate ? (
						<div className="chat-list-delimiter" key={msgDate}>
							<p className="chat-list-delimiter__text">
								{msgDate}
							</p>
						</div>
					) : (
						''
					)}

					<ChatContentMessageDetails
						key={res.msgId}
						getOnBottom={this.getOnBottom}
						data={{
							message: res,
							official: res.official,
							UserName: UserInfo.userName,
							UserGender: UserInfo.gender,
							MyName: MyProfile.data.fortuneTellerName,
							MyId: MyProfile.data.fortuneTellerId,
							MyAvatartPath: MyProfile.data.avatarPath,
							idMessageOfflineOpened:
								this.state.idMessageOfflineOpened,
						}}
					/>
				</li>
			)
		})

		const handleScroll = () => {
			const messageList = $(this.refs.messageList)
			this.setOnBottom(
				messageList.scrollTop() + messageList.height() ==
					messageList.find('ul').height() + 16
					? 1
					: 0
			)
			if (messageList.prop('scrollTop') === 0) {
				this.props.setSessionStorage('curScrollTop', '-1')
				this.props.setSessionStorage('curHeight', this.curHeight)
				loadFunc()
			}
		}

		return (
			<div
				ref="messageList"
				className="chat-list-area"
				onScroll={() => handleScroll()}
			>
				<ul className="list column_1">{ChatList}</ul>
			</div>
		)
	}
}
