import {
	DETAIL_ANSWER_CHAT_LOG,
	DETAIL_ANSWER_CHAT_LOG_DEFAULT,
} from '../constants/ActionTypes'

const initialState = {
	data: null,
}

export const DetailAnswerChatLog = (state = initialState, action) => {
	switch (action.type) {
		case DETAIL_ANSWER_CHAT_LOG_DEFAULT:
			return action.data
		case DETAIL_ANSWER_CHAT_LOG:
			return action.data
		default:
			return state
	}
}
