/**
 * Helpページ
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { glasConfig } from '../constants/Config'
import '../assets/scss/faqStype.scss'
import $ from 'jquery'

const LIST_ADDITIONAL = [
	{ id: 1, content: 'お問い合わせ窓口', link: '/home/contact' },
	{ id: 2, content: 'お問い合わせ履歴/回答', link: '/home/contact/history' },
]

class Help extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: [],
			searchBody: '',
			detailFaq: {},
			screenFaq: 'Help',
		}
	}

	componentDidMount = async () => {
		const url = glasConfig.url_outside + glasConfig.helpFaq + '?type=2'
		await axios.get(url, {}).then((response) => {
			if (response.data !== undefined && response.data.code === 0) {
				this.setState({
					data: response.data.data,
				})
			}
		})
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.screenFaq === 'Detail') {
			this.setState({
				detailFaq: {},
				screenFaq: 'Help',
			})
		}
	}

	chagneValueSearch = async (value) => {
		this.setState({
			searchBody: value,
		})
	}

	searchTextValueHelp = async (value) => {
		const url =
			glasConfig.url_outside +
			glasConfig.helpFaq +
			'?type=2&search=' +
			value
		await axios.get(url, {}).then((response) => {
			if (response.data !== undefined && response.data.code === 0) {
				this.setState({
					data: response.data.data,
				})
			}
		})
	}

	changeDetailQuestion = (category_id, faq_id) => {
		this.state.data.map((item, key) => {
			if (item.category_id === category_id) {
				item.listFaq.map((itemFaq, keyFaq) => {
					if (itemFaq.faq_id === faq_id) {
						this.setState({
							detailFaq: itemFaq,
							screenFaq: 'Detail',
						})
					}
				})
			}
		})
	}

	renderQuestionRow = (listData, searchValue) => {
		return (
			//list category
			listData.length > 0 ? (
				listData.map((item, key) => {
					return (
						<div key={key}>
							{item.listFaq !== undefined ? (
								<>
									<div className="primary-title">
										<h1 className="primary-title__main">
											{item.name}
										</h1>
									</div>
									{
										//list faq
										item.listFaq.length > 0
											? item.listFaq.map(
												(itemFaq, keyFaq) => {
													return (
														<div
															key={keyFaq}
															className="primary__inner-box"
														>
															<ul className="list column_1 has_dotto">
																<li className="list__item_question">
																	<Link
																		onClick={() =>
																			this.changeDetailQuestion(
																				item.category_id,
																				itemFaq.faq_id
																			)
																		}
																		to={
																			'#'
																		}
																	>
																		<span
																			dangerouslySetInnerHTML={{
																				__html: itemFaq.quest,
																			}}
																		/>
																		{/*<span dangerouslySetInnerHTML={{__html: itemFaq.quest}}  />*/}
																	</Link>
																</li>
															</ul>
														</div>
													)
												}
											)
											: null
									}
								</>
							) : null}
						</div>
					)
				})
			) : (
				<div>
					<div className={'key-search-value'}>
						{searchValue}{' '}
						<span className={'box-key-word-search'}>
							キーワードの検索結果
						</span>
					</div>
					<div className={'box-title-null-data'}>
						見つかりませんでした
					</div>
				</div>
			)
		)
	}

	defaultDetailFaq = () => {
		this.setState({
			detailFaq: {},
			screenFaq: 'Help',
		})
	}

	renderDetailHelp = (item) => {
		let answerValue = item.answer
		let questionValue = item.quest
		$('.secondary-header').addClass('hiddenClass')
		$('#lead-nav').addClass('hiddenClass')
		$('.material-icons.icon-back').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('ヘルプ')

		return (
			<div>
				<div className={'menu-top-back-btn'}>
					<Link
						to="/home/help"
						className="back-to-home-mobile"
						onClick={() => this.defaultDetailFaq()}
					>
						一覧に戻る
					</Link>
				</div>
				<div className={'back-link-header-help'}>
					<Link to={'#'} onClick={() => this.defaultDetailFaq()}>
						ヘルプ
					</Link>{' '}
					<span> > 検索結果 </span>
				</div>
				<div className={'primary help-content'}>
					<div className="box-title-header-quest">
						<div className="title-quest-data render-quest">
							<span
								dangerouslySetInnerHTML={{
									__html: questionValue,
								}}
							/>
						</div>
					</div>
					<div className="primary__inner render-answer">
						<div
							dangerouslySetInnerHTML={{ __html: answerValue }}
						/>
					</div>
				</div>
			</div>
		)
	}

	render() {
		let { MyProfile } = this.props
		let specialCmCode = MyProfile.data.specialCmCode
		$('.secondary-header').removeClass('hiddenClass')
		$('#lead-nav').removeClass('hiddenClass')
		$('.material-icons.icon-back').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')

		return (
			<div className="content">
				<div className="content__inner layout_help">
					<h1 className="content__title">ヘルプ</h1>
					{/* search box======= */}
					{Object.keys(this.state.detailFaq).length === 0 ? (
						<div className="help-form-wrap">
							<div className="help-form-main">
								<i className="material-icons">search</i>
								<div className="textfield">
									<input
										className="textfield__input"
										type="text"
										value={this.state.searchBody}
										onChange={(e) =>
											this.chagneValueSearch(
												e.target.value
											)
										}
										id="handle_name"
										placeholder="検索キーワードを入力"
									/>
								</div>
							</div>
							<div className="help-form-actions">
								<div className="btn-wrap help-form-actions__submit">
									<button
										onClick={(e) =>
											this.searchTextValueHelp(
												this.state.searchBody
											)
										}
										className="btn-raised color_default spread_width"
									>
										検索
									</button>
								</div>
							</div>
						</div>
					) : null}
					{/* end saerch box =========*/}

					{Object.keys(this.state.detailFaq).length === 0 ? (
						<div className={'primary help-content'} style={{ minHeight: 200 }}>
							{this.renderQuestionRow(
								this.state.data,
								this.state.searchBody
							)}
						</div>
					) : (
						this.renderDetailHelp(this.state.detailFaq)
					)}
					<div className="help-additional--title">
						ここにない質問についてはお問い合わせください。
					</div>
					<div className="box-shadow help-content help-additional__content">
						<ul>
							{LIST_ADDITIONAL.map((item) => (
								<li className="list__item_question">
									<Link
										to={item.link}
										className="help-additional__content-item"
									>
										{item.content}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
				{/* {specialCmCode ? <div style={{marginBottom: 20}}>
                        ここにない質問については、ご担当者様にお尋ねくださいませ。
                    </div>
                    : <div style={{marginBottom: 20}}>
                        ここにない質問については<Link to="/home/contact">お問い合わせください。</Link>
                    </div>
                } */}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(Help)
