import {
	REFRESH_HISTORY_READ_UN_PIN,
	REQUEST_DELETE_MESSAGE_READ,
	REQUEST_HISTORY_DEFAULT,
	REQUEST_HISTORY_READ,
	REQUEST_HISTORY_READ_DEFAULT,
	REQUEST_HISTORY_READ_REFRESH,
	RESET_REQUEST_HISTORY,
} from '../constants/ActionTypes'
import * as Fetch from '../util/Fetch'
import { getUserId } from '../assets/help/help'

const initialState = {
	loaded: false,
	data: {},
}

export const RequestHistoryRead = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_HISTORY_READ: {
			let listNew = {}
			if (action.data) {
				if (action.data.length > 0) {
					action.data.map((item, key) => {
						Object.assign(listNew, { [getUserId(item)]: item })
					})
				}
			}
			return {
				...state,
				loaded: true,
				data: { ...state.data, ...listNew },
			}
		}
		case REQUEST_HISTORY_READ_REFRESH: {
			const sortable2 = Object.entries({ ...action.data, ...state.data })
			.sort(([, a], [, b]) => b.serverTime - a.serverTime)
			.reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
			return {
				loaded: true,
				data: sortable2
			}
		}
		case REQUEST_HISTORY_READ_DEFAULT: {
			return {
				loaded: false,
				data: {},
			}
		}
		case RESET_REQUEST_HISTORY: {
			return { ...state, loaded: false, data: {} }
		}
		case REQUEST_DELETE_MESSAGE_READ: {
			delete state.data[getUserId(action.data)]
			return Object.assign({}, state, {
				loaded: true,
			})
		}
		case REFRESH_HISTORY_READ_UN_PIN: {
			let listNew = state.data
			if (action.data) {
				if (action.data.length > 0) {
					action.data.map((item) => {
						Object.assign(listNew, { [getUserId(item)]: item })
					})
				}
			}
			const sortable2 = Object.entries(listNew)
				.sort(([, a], [, b]) => b.serverTime - a.serverTime)
				.reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
			return {
				...state,
				loaded: true,
				data: sortable2,
			}
		}
		default: {
			return state
		}
	}
}
