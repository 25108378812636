import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { isMobile, isAndroid } from 'react-device-detect'

class ContactMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		let pathName = window.location.pathname
		let contactCreate =
			'btn-tab' + (pathName === '/home/contact' ? ' is-active' : '')
		let contactList =
			'btn-tab' +
			(pathName.indexOf('/home/contact/history') !== -1 ||
			pathName.indexOf('/home/contact/detail') !== -1
				? ' is-active'
				: '')

		return (
			<div>
				<nav className="lead-nav contact-lead-nav">
					<ul className="list lead_nav">
						<li className="list__item">
							<Link
								className={contactCreate}
								to={`/home/contact`}
							>
								お問い合わせ窓口
							</Link>
						</li>
						<li className="list__item">
							<Link
								className={contactList}
								to={`/home/contact/history`}
							>
								お問い合わせ履歴{' '}
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		)
	}
}

export default ContactMenu
