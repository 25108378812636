import {
	REQUEST_OFFLINE,
	REQUEST_OFFLINE_MERGE,
	REQUEST_OFFLINE_DELETE,
} from '../constants/ActionTypes'

const initialState = {
	loaded: false,
	data: {},
}

export const RequestOffline = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_OFFLINE: {
			return Object.assign({}, state, {
				loaded: true,
				data: action.data,
			})
		}
		case REQUEST_OFFLINE_MERGE: {
			if (action.data.userId) {
				let orgData = {}
				if (!state.data[action.data.userId]) {
					orgData = action.data.data
				} else {
					orgData = state.data[action.data.userId]
					//一旦結合させる
					Object.assign(orgData, action.data.data)
					//先頭にもってくるために、一旦state.dataから削除する
					delete state.data[action.data.userId]
				}

				//先頭に追加
				state.data = Object.assign(
					{
						[action.data.userId]: orgData,
					},
					state.data
				)
			}
			return Object.assign({}, state, {
				loaded: true,
			})
		}
		case REQUEST_OFFLINE_DELETE: {
			const userId = action.data
			if (state.data[userId]) {
				delete state.data[userId]
			}
			return Object.assign({}, state, {
				loaded: true,
			})
		}
		default: {
			return state
		}
	}
}
