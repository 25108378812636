import React from 'react'
import { POINT_REPORT } from '../../../../constants/PointReportConstants'
import { DayInMonthLabel } from '../../StyledPointReport/Calendar/StyledCustomCalendar'
import moment from 'moment'

const DayInMonth = ({ date, drilldownView, isOffRange, label, onDrillDown }) => {

	const getDay = () => {
		const day = moment(date).day()
		let result = 'NORMAL_DAY'
		switch (day) {
		case POINT_REPORT.DATE_INDEX.MOMENT_JS.SATURDAY:
			result = 'SATURDAY'
			break
		case POINT_REPORT.DATE_INDEX.MOMENT_JS.SUNDAY:
			result = 'SUNDAY'
			break
		default:
			break
		}
		return result
	}

	const checkIsToday = () => moment(date).isSame(new Date(), 'day')

	return (
		<DayInMonthLabel
			
			day={getDay()}
			isOffRange={isOffRange}
			isToday={checkIsToday()}
		>
			{label}
		</DayInMonthLabel>
	)
}

export default DayInMonth