import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import CustomerManagementMenu from './CustomerManagementMenu'
import * as CustomerManagementActions from '../actions/CustomerManagementActions'
import * as moment from 'moment'
import request from 'axios'
import { glasConfig } from '../constants/Config'
import DialogWarningDeleteTimeLine from '../components/DialogWarningDeleteTimeLine'

class CustomerManagementNotificationHistory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageRecords: [],
			totalRecords: 0,
			nowPage: 0,
			dataloaded: false,
		}
	}

	componentDidMount() {
		const { dispatch } = this.props
		dispatch(
			CustomerManagementActions.notificationGet(this.createUrlQuery())
		)
	}

	componentWillReceiveProps(nextProps) {
		let dataloaded = true
		this.setState({
			pageRecords: nextProps.CustomerManagement.data.list,
			totalRecords: nextProps.CustomerManagement.data.totalRecord,
			nowPage: nextProps.CustomerManagement.page,
			dataloaded,
		})
	}

	createUrlQuery(page) {
		let postPage = page || 0
		let params = ['size=10', `page=${postPage}`]
		return `?${params.join('&')}`
	}

	handlePage(event, page) {
		const { dispatch } = this.props
		event.preventDefault()
		dispatch(
			CustomerManagementActions.notificationGet(
				this.createUrlQuery(page),
				page
			)
		)
	}

	acceptDeleteNotification = (notificationId) => {
		this.deleteNotification(notificationId)
	}

	handleNotificationDelete(notificationId) {
		this.confirmDelNotificationDialog.openDialog(() =>
			this.acceptDeleteNotification(notificationId)
		)
	}

	deleteNotification(notificationId) {
		const { dispatch } = this.props
		const token = localStorage.getItem('token')
		request
			.delete(
				glasConfig.url_base +
					glasConfig.path_setting_noti +
					`?notificationSettingId=${notificationId}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						CustomerManagementActions.notificationGet(
							this.createUrlQuery(this.state.nowPage)
						)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}

		let nowPage = parseInt(this.state.nowPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.usermanagement.management
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0
		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1

		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = nowPage - showHalfPage
		let loopEnd = nowPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (nowPage !== 0) {
			firstPage = false
		}
		if (nowPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			lastPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = nowPage > 0 ? nowPage - 1 : 0
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = nowPage !== endPage ? nowPage + 1 : endPage
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === nowPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item">
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className="primary__inner">
				<div className="pager">
					<ul className="list">
						{prevNav}
						{pageNav}
						{nextNav}
					</ul>
				</div>
			</div>
		)
	}

	getNotificationHistory() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<tr key={current.notificationSettingId}>
						<td>
							{current.timeToPush
								? moment(
										new Date(current.timeToPush),
										'YYYYMDHm'
								  ).format('YYYY/MM/DD H:mm')
								: '-'}
						</td>
						<td>{current.content}</td>
						<td>{current.receiverNumber}人</td>
						<td>{current.numberClick}回</td>
						<td>{current.ratioClick}%</td>
						<td>
							{current.pushed ? (
								'-'
							) : (
								<a
									href={`/home/customermanagement/notification/edit/${current.notificationSettingId}`}
								>
									<button className="editNoti">編集</button>
								</a>
							)}
						</td>
						<td>
							<button
								onClick={() =>
									this.handleNotificationDelete(
										current.notificationSettingId
									)
								}
								className="deleteNoti"
							>
								削除
							</button>
						</td>
					</tr>
				)
			}
		)
	}

	getNotificationHistorySP() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<div className="warpper-item-noti" key={current.userId}>
						<div className="top-item">
							<span className="userName">
								{current.timeToPush
									? moment(
											new Date(current.timeToPush),
											'YYYYMDHm'
									  ).format('YYYY/MM/DD H:mm')
									: '-'}
							</span>
						</div>
						<div className="content-item">
							<ul className="list-info">
								<li className="content-noti">
									{current.content}
								</li>
								<li className="info-user">
									送信人数 : {current.receiverNumber}人
								</li>
								<li className="info-user">
									クリック数 : {current.numberClick}回
								</li>
								<li className="last-row-noti">
									<div className="notiRatio">
										<span>
											クリック率 : {current.ratioClick}%
										</span>
									</div>
									<div className="noti-btns">
										{current.pushed ? (
											<button className="editNotiPushed">
												-
											</button>
										) : (
											<a
												href={`/home/customermanagement/notification/edit/${current.notificationSettingId}`}
											>
												<button className="editNoti">
													編集
												</button>
											</a>
										)}
										<button
											onClick={() =>
												this.handleNotificationDelete(
													current.notificationSettingId
												)
											}
											className="deleteNoti"
										>
											削除
										</button>
									</div>
								</li>
							</ul>
						</div>
					</div>
				)
			}
		)
	}

	render() {
		return (
			<div className="content">
				<h1 className="content__title hidden-sp">顧客管理</h1>
				<CustomerManagementMenu />
				<div className="primary primaryNotification">
					<div className="primary-title hidden-sp">
						<h1 className="primary-title__main">
							プッシュ通知一覧
						</h1>
						<div>
							<Link
								to={`/home/customermanagement/notification/create`}
							>
								<button className="createNoti">
									プッシュ通知作成
								</button>
							</Link>
						</div>
						<div className="frame-table-noti hidden-sp">
							<table className="table-data">
								<thead>
									<tr>
										<th className="table-align-center">
											送信時間
										</th>
										<th className="table-align-center">
											内容
										</th>
										<th className="table-align-center">
											送信人数
										</th>
										<th className="table-align-center">
											クリック数
										</th>
										<th className="table-align-center">
											クリック率
										</th>
										<th className="table-align-center">
											編集
										</th>
										<th className="table-align-center">
											削除
										</th>
									</tr>
								</thead>
								<tbody>{this.getNotificationHistory()}</tbody>
							</table>
						</div>
					</div>
					<Link
						to={`/home/customermanagement/notification/create`}
						className="display-mobile"
					>
						<button className="createNoti">プッシュ通知作成</button>
					</Link>
					<div className="customer-list-sp display-mobile">
						{this.getNotificationHistorySP()}
					</div>
					{this.setPager()}
				</div>
				<DialogWarningDeleteTimeLine
					ref={(ref) => (this.confirmDelNotificationDialog = ref)}
					dispatch={this.props.dispatch}
				/>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		CustomerManagement: store.CustomerManagement,
	}
}

export default connect(mapStateToProps)(CustomerManagementNotificationHistory)
