import React, { Component } from 'react'

class CheckBoxCM extends Component {
	constructor(props) {
		super(props)
		this.state = {
			checked: false,
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.defaultValue !== this.state.checked) {
			this.setState({
				checked: nextProps.defaultValue,
			})
		}
		return true
	}

	changeValue = (value) => {
		this.setState({
			checked: !value,
		})
		this.props.onData(!value)
	}

	render() {
		return (
			<label className="label-text-92797">
				<input
					type="checkbox"
					checked={this.state.checked}
					value={this.state.checked}
					className="input-line-botoom-92797"
					onClick={(event) => this.changeValue(this.state.checked)}
				/>
				{this.props.locationText !== undefined
					? this.props.titleText
					: null}
			</label>
		)
	}
}

export default CheckBoxCM
