import {
	TIMELINE_GET,
	TIMELINE_POST,
	TIMELINE_DELETE,
	TIMELINE_LIKE_GET,
	TIMELINE_COMMENT_GET,
	TIMELINE_COMMENT_POST,
	TIMELINE_COMMENT_DELETE,
} from '../constants/ActionTypes'
import { glasConfig, TimeLineContentType } from '../constants/Config'
import request from 'axios'
import * as Fetch from '../util/Fetch'
import * as moment from 'moment'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//タイムライン取得
export const timelineGet = (params, prevPage, postPage) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		return request
			.get(glasConfig.url_base + glasConfig.path_timeline + params, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					let data = {
						data: response.data.data,
						prevPage: prevPage,
						postPage: postPage,
						autoLoad: true,
						postTimeline: false,
					}
					if (
						response.data.data === null ||
						response.data.data.length === 0
					) {
						data.autoLoad = false
					}
					dispatch(resultSuccess(TIMELINE_GET, data))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + TIMELINE_GET, error)
				throw error
			})
	}
}

//いいね取得
export const timelineLikeGet = (params) => {
	return (dispatch, getState) => {
		const token = localStorage.getItem('token')

		return request
			.get(
				glasConfig.url_base +
				glasConfig.path_timeline_like +
				'?postId=' +
				params.postId,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let props = getState()
					let timeline = props.Timeline
					timeline.likes[params.postId] = response.data.data

					dispatch(resultSuccess(TIMELINE_LIKE_GET, timeline))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + TIMELINE_LIKE_GET, error)
				throw error
			})
	}
}

//コメント取得
export const timelineCommentGet = (params) => {
	return (dispatch, getState) => {
		const token = localStorage.getItem('token')

		return request
			.get(
				glasConfig.url_base +
				glasConfig.path_timeline_comment +
				'?postId=' +
				params.postId,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let props = getState()
					let timeline = props.Timeline
					timeline.comments[params.postId] = response.data.data

					dispatch(resultSuccess(TIMELINE_COMMENT_GET, timeline))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + TIMELINE_COMMENT_GET, error)
				throw error
			})
	}
}

//タイムライン登録
export const timelinePost = (params, state, callback, callbackErr) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		let postValues = {
			postValues: [
				{
					type: TimeLineContentType.CONTENT,
					value: params.timelineData,
				},
			],
		}

		return request
			.post(glasConfig.url_base + glasConfig.path_timeline, postValues, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					let data = {
						autoLoad: true,
						postPage: state.postPage,
						postTimeline: true,
					}
					dispatch(resultSuccess(TIMELINE_POST, data))
					callback()
				} else {
					callbackErr()
				}
			})
			.catch((error) => {
				console.log('error on call ' + TIMELINE_POST, error)
				throw error
			})
	}
}

// タイムラインファイル同時登録
export const timelineFilePost = (params, callback, callbackErr) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		Promise.resolve()
			.then(() => {
				return new Promise((resolve, reject) => {
					request
						.post(
							glasConfig.url_base + glasConfig.path_file,
							params.fileData,
							{
								headers: {
									Authorization: token,
								},
							}
						)
						.then((response) => {
							if (response.data.code === 0) {
								resolve(response.data.data.filePath)
							} else {
								throw 'システムエラー'
							}
						})
						.catch((error) => {
							console.log('error on call ' + TIMELINE_POST, error)
							throw error
						})
				})
			})
			.then((filePath) => {
				return new Promise((resolve, reject) => {
					let postValues = {
						postValues: [
							{
								type: TimeLineContentType.CONTENT,
								value: params.timelineData,
							},
							{
								type: TimeLineContentType.IMAGE,
								value: filePath,
							},
						],
					}

					request
						.post(
							glasConfig.url_base + glasConfig.path_timeline,
							postValues,
							{
								headers: {
									Authorization: token,
								},
							}
						)
						.then((response) => {
							if (response.data.code === 0) {
								let data = {
									autoLoad: true,
									postPage: 1,
									postTimeline: true,
								}
								dispatch(resultSuccess(TIMELINE_POST, data))
								callback()

							} else {
								callbackErr()
							}
						})
						.catch((error) => {
							console.log('error on call ' + TIMELINE_POST, error)
							throw error
						})
				})
			})
	}
}

//タイムライン削除
export const timelineDelete = (params) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		dispatch(resultSuccess(TIMELINE_DELETE, { timelineDelSuccess: false }))
		return request
			.delete(glasConfig.url_base + glasConfig.path_timeline + params, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(TIMELINE_DELETE, {
							timelineDelSuccess: true,
						})
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + TIMELINE_DELETE, error)
				throw error
			})
	}
}

//コメント登録
export const timelineCommentPost = (params, callback, callbackErr) => {
	return (dispatch, getState) => {
		const token = localStorage.getItem('token')

		return request
			.post(
				glasConfig.url_base + glasConfig.path_timeline_subcomment,
				params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let myprofile = getState().MyProfile.data
					let timeline = getState().Timeline
					let comments = timeline.comments[params.postId]

					comments.forEach((comCur, comIndex, commentsAry) => {
						if (
							comCur.commentId === params.commentId &&
							comCur.subcommentNumber > 0
						) {
							// commentsAry[comIndex].subcomments.forEach((subComCur, subComIndex)=>{
							commentsAry[comIndex].subcomments.push({
								approved: false,
								birthday: null,
								commentId: comCur.commentId,
								createdTime: moment().utc().format('YYYYMMDDhhmmss'),
								gender: null,
								postId: params.postId,
								subcommentId: response.data.data.subcommentId,
								subcommentValue: params.subcommentValue,
								userAvatarPath: myprofile.avatarPath,
								userId: myprofile.fortuneTellerId,
								userName: myprofile.fortuneTellerName,
								userType: 2,
							})
							// })
						} else if (comCur.commentId === params.commentId) {
							commentsAry[comIndex].subcomments.push({
								approved: false,
								birthday: null,
								commentId: comCur.commentId,
								createdTime: moment().utc().format('YYYYMMDDhhmmss'),
								gender: null,
								postId: params.postId,
								subcommentId: response.data.data.subcommentId,
								subcommentValue: params.subcommentValue,
								userAvatarPath: myprofile.avatarPath,
								userId: myprofile.fortuneTellerId,
								userName: myprofile.fortuneTellerName,
								userType: 2,
							})
						}
					})

					let data = {
						comments: {},
					}
					data.comments[params.postId] = comments
					dispatch(
						resultSuccess(
							TIMELINE_COMMENT_POST,
							Object.assign({}, data, timeline)
						)
					)
					callback()
				} else {
					callbackErr()
				}
			})
			.catch((error) => {
				console.log('error on call ' + TIMELINE_COMMENT_POST, error)
				throw error
			})
	}
}

//タイムラインコメント削除
export const timelineCommentDelete = (params) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		return request
			.delete(
				glasConfig.url_base +
				glasConfig.path_timeline_subcomment +
				params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let data = {
						autoLoad: true,
						postPage: 1,
						postTimeline: true,
					}
					dispatch(resultSuccess(TIMELINE_COMMENT_DELETE, data))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + TIMELINE_COMMENT_DELETE, error)
				throw error
			})
	}
}
