import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig, MissionLevelId } from '../constants/Config'
import MissionMenu from './MissionMenu'
import * as MissionActions from '../actions/MissionActions'
import MissionItem from './MissionItem'

class MissionAdvanced extends Component {
	componentWillMount() {
		const { dispatch, Mission } = this.props
		dispatch(MissionActions.getMissions())
	}

	render() {
		const { Mission, dispatch } = this.props

		const missions = Mission.missions.map((mission) => {
			if (mission.catalog == MissionLevelId['ADVANCED']) {
				return <MissionItem mission={mission} dispatch={dispatch} />
			}
		})

		return (
			<div className="content setting-mission">
				<h1 className="content__title">ミッション</h1>
				<MissionMenu />
				<div className="primary">
					<ul>{missions}</ul>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		Mission: state.Mission,
	}
}

export default connect(mapStateToProps)(MissionAdvanced)
