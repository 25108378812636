import React, { Component } from 'react'
import Dialog from '@mui/material/Dialog'
import moment from 'moment'
import { TYPE_LOCALSTORAGE } from '../../constants/Config'

export default class DialogNewbie extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
        }
    }

    gotoBlogDetailScreen = () => {
        this.setState({ show: false })
        window.open('/home/staffblog/detail/10623', "_blank")
    }


    openDialog = () => {
        this.setState({ show: true })
        localStorage.setItem(TYPE_LOCALSTORAGE.SHOW_DIALOG_NEWBIE, moment().format('x'))
    }

    closeDialog = () => {
        this.setState({ show: false })
        localStorage.setItem(TYPE_LOCALSTORAGE.SHOW_DIALOG_NEWBIE, moment().format('x'))
    }


    render() {
        const registerTime = moment(this.props.registerTime, "YYYYMMDDHHmm")
        return (
            <Dialog
                open={this.state.show}
                bodyClassName="dialog-newbie-content"
                style={{ alignItems: 'center' }}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                    },
                }}
            >
                <div className="dialog-newbie">
                    <img src="/img/img_debut.png" className="" />
                    <div className="dialog-newbie-title">
                        <img src="/img/icon_firsttime.png" className="imgIconFirstTime" style={{ marginInline:'0 10px' }} />
                        現在デビュー期間中
                        <img src="/img/icon_firsttime.png" className="imgIconFirstTime" style={{ marginLeft: 10 }} />
                    </div>
                    <div className="dialog-time-end-newbie">
                        期間終了は {registerTime.add(30, 'days').format('MM月DD日')}
                    </div>
                    <div className="dialog-newbie-text-note">
                        お客様の目を惹くこの期間をお見逃しなく！
                    </div>
                    <div className="dialog-first-login-area-button">
                        <button
                            className="submitUnBlock dialog-first-login-button"
                            style={{ width: 220 }}
                            onClick={this.gotoBlogDetailScreen}
                        >
                            詳細はこちら！
                        </button>
                    </div>
                    <button
                        className="dialog-event-btn-close"
                        onClick={() => this.closeDialog()}
                        style={{ fontSize: 14, color: '#4d4b51', backgroundColor: 'white', marginBottom: 0, padding: 0 }}
                    >
                        閉じる
                    </button>
                </div>
            </Dialog>
        )
    }
}
