import React from 'react'
import { POINT_REPORT, isInt } from '../../../../constants/PointReportConstants'
import { StatisticData, StatisticUnit } from '../../StyledPointReport'

const Statistic = (props) => {
	const { type, data } = props

	
	const formatData = () => {
		let result = ''
		if(data === null || data == 0 || data === 'NaN') {
			result = POINT_REPORT.STATISTICS[type].DATA.defaultValue
		} else {
			result = isInt(+data) ? parseInt(data).toLocaleString() : (+data).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			})
		}
		return result
	}

	const fomatedData = formatData()
	return (
		<span>
			<StatisticData type={type}>
				{fomatedData}
			</StatisticData>
			<StatisticUnit type={type}>
				{
					POINT_REPORT.STATISTICS[type].UNIT.value
				}
			</StatisticUnit>
		</span>
	)
}

export default Statistic