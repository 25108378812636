import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import * as AuthActions from '../actions/AuthActions'
import * as MyProfileActions from '../actions/MyProfileActions'
import * as Fetch from '../util/Fetch'
import { FireMsg } from '../util/Firebase'
import TextField from 'material-ui/TextField'
import LoadingBar from './LoadingBar'
import { isIOS, isSafari } from 'react-device-detect'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { DEVICE_TYPE_FOR_CALLING_SIP_SERVER } from '../constants/Config'
import { isPWA } from '../helper/helpFunction'


const debounce = require('lodash.debounce')

class Login extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loginid: '',
			password: '',
			message: {
				loginid: '',
				password: '',
			},
			error: false,
			firebaseToken: null,
			to: '/home',
			disabled: false,
		}
	}

	componentWillMount() {
		if (
			this.props.location.state !== undefined &&
			this.props.location.state.from.pathname
		) {
			this.setState({
				to: this.props.location.state.from.pathname,
			})
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			!Object.keys(this.props.Auth.data).length &&
			!Object.keys(nextProps.Auth.data).length
		) {
			this.setState({ disabled: false })
		}
	}

	handleChange(e) {
		const name = e.target.name
		const val = e.target.value
		this.setState({
			[name]: val,
		})
	}

	async handleSubmit() {
		const { dispatch } = this.props
		const loginid = this.state.loginid
		const password = this.state.password
		const deviceType = isPWA() ? DEVICE_TYPE_FOR_CALLING_SIP_SERVER : Fetch.fetchUserAgent()

		this.setState({ disabled: true }, () => {
			let message = {}
			if (loginid.length > 0 && password.length > 0) {
				dispatch(
					AuthActions.fetchLogin({
						loginid: loginid,
						password: password,
						deviceType,
					})
				)
			} else {
				if (!loginid) {
					message.loginid =
						'メールアドレスを入力してください'
				}

				if (!password) {
					message.password =
						'パスワードを入力してください'
				}

				this.setState({
					error: true,
					message: message,
				})
			}
		})
	}

	handleEnter(e) {
		if (e.keyCode === 13 && !this.state.disabled) {
			this.handleSubmit()
		}
	}

	fetchProfileDebounce = debounce(() => {
		const { dispatch } = this.props
		dispatch(MyProfileActions.fetch())
	}, 1000)

	render() {
		const { dispatch, Auth, MyProfile } = this.props
		const expire = Fetch.chkExpireToken()
		if (expire) {
			if (MyProfile.loaded) {
				//初回ログイン時は規約ページへ
				if (!Auth.data.lastTimeLogin) {
					return <Redirect to="/contract" />
				}
				return <Redirect to={this.state.to} />
			}
		}

		const tellerId = Fetch.tellerId()
		if (tellerId && tellerId === Auth.tellerId) {
			this.fetchProfileDebounce()
		}

		const errmsgId = this.state.message.loginid ? (
			<div className="auth-box__error">{this.state.message.loginid}</div>
		) : (
			''
		)
		const errmsgPass = this.state.message.password ? (
			<div className="auth-box__error">{this.state.message.password}</div>
		) : (
			''
		)
		const errmsgSystem = Auth.errormsg ? (
			<div className="auth-box__error">{Auth.errormsg}</div>
		) : (
			''
		)

		return (
			<div>
				<div
					className="content-auth"
					onKeyDown={(e) => this.handleEnter(e)}
				>
					<LoadingBar />
					<div className="auth-box">
						<Link className="sitelogo" to="/login">
							<img
								className="sitelogo__image"
								src="/img/stella_logo_color.png"
								alt="ステラロゴ"
							/>
						</Link>
						<div className="auth-box__inner">
							{errmsgSystem}
							<div className="textfield">
								<TextField
									hintText="メールアドレス"
									id="auth_mail"
									className="textfield__input"
									name="loginid"
									onChange={(e) => this.handleChange(e)}
									fullWidth={true}
								/>
								{errmsgId}
							</div>
							<div className="textfield">
								<TextField
									hintText="パスワード"
									id="auth_password"
									className="textfield__input"
									name="password"
									onChange={(e) => this.handleChange(e)}
									fullWidth={true}
									type="password"
								/>
								{errmsgPass}
							</div>
							<div className="btn-wrap">
								<button
									disabled={this.state.disabled}
									className="btn-raised color_default spread_width"
									onClick={() => this.handleSubmit()}
								>
									ログイン
								</button>
							</div>
							<Link to="/password" className="password">
								パスワードを忘れた方
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		Auth: state.Auth,
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(Login)
