import React from 'react'

const NotFound = () => {
	return (
		<div className="content">
			<h1 className="content__title">404 page not found</h1>
			<p>お探しのページは見つかりませんでした。</p>
		</div>
	)
}

export default NotFound
