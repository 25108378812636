import { axiosGET } from '../../helper/TypeAxios'
import { glasConfig } from './../../constants/Config'
import * as Fetch from '../../util/Fetch'

if (process.env.REACT_APP_TYPE_SERVER === 'dev') {
} else if (process.env.REACT_APP_TYPE_SERVER === 'production') {
}

export const RewardExplanationAPI = async (params = {}) => {
	const token = await localStorage.getItem('token')
	let tellerId = Fetch.tellerId()
	let urlGet = glasConfig.url_base + glasConfig.get_reward_explain + tellerId
	return axiosGET(urlGet, { Authorization: token })
}

// stellaanswer_withmyanswer
export const StellaAnswerWithMyAnswer = async (params = {}) => {
	const token = await localStorage.getItem('token')
	let paramsJoin = '?'
	if (params.page !== undefined) {
		paramsJoin = paramsJoin + 'page=' + (params.page - 1) + '&'
	}

	if (params.size !== undefined) {
		paramsJoin = paramsJoin + 'size=' + params.size + '&'
	}

	if (params.userId !== undefined) {
		paramsJoin = paramsJoin + 'userId=' + params.userId
	}
	let urlGet =
		glasConfig.url_base +
		glasConfig.get_stellaanswer_withmyanswer +
		paramsJoin

	return axiosGET(urlGet, { Authorization: token })
}

// stellaanswer_withmyanswer
export const DetailAnswerInChat = async (params = {}) => {
	const token = await localStorage.getItem('token')
	let paramsJoin = '?'

	if (params.questionId !== undefined) {
		paramsJoin = paramsJoin + 'questionId=' + params.questionId
	}
	let urlGet =
		glasConfig.url_base + glasConfig.get_detail_answer_in_chat + paramsJoin

	return axiosGET(urlGet, { Authorization: token })
}
