import React, { useState, useEffect } from 'react'
import moment from 'moment'

const Timer = (props) => {
	const { expiryTimestamp, functions } = props
	let interval
	const [time, setTime] = useState(expiryTimestamp)

	useEffect(() => {
		const expiredTime = moment(expiryTimestamp)
		const currentTime = moment()

		const diff = currentTime.diff(expiredTime, 'seconds')
		setTime(Math.abs(diff))
	}, [])

	useEffect(() => {
		interval = setInterval(() => {
			setTime((counter) => counter - 1)
		}, 1000)

		return () => {
			clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (time < 0) {
			clearInterval(interval)
			functions.closeDialog()
			functions.sendDecline()
		}
		return
	}, [time])

	return <span>{time}</span>
}

export default Timer
