import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import * as FileActions from '../actions/FileActions'
import AvatarEditor from 'react-avatar-editor'
import Snackbar from 'material-ui/Snackbar'

class SettingProfileImage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			rotate: 0,
			file: {},
			validImage: true,
		}
	}

	componentWillMount() {}

	componentDidMount() {
		// fileフォームからファイルオブジェクトを取得
		this.setState({ file: document.updateTeller.tellerAvatar.files[0] })
		let file = document.updateTeller.tellerAvatar.files[0]
		this.setState({ validImage: this.props.validateAvaPath(file) })
		let reader = new FileReader()
		reader.onload = this.drawImage
		reader.readAsDataURL(file)

		this.defaultImage = null
		this.defaultImageFile = null

		let dialog = document.getElementsByClassName('prof-dialog-wrap')[0]
	}

	// ダイアログを閉じる
	handleDialogClose(event) {
		this.props.onClose('closed')
	}

	setEditorRef = (editor) => (this.editor = editor)

	// プロフィール画像アップロード
	// canvasの左上の座標
	handleSaveImage(event) {
		if (!this.state.validImage) {
			return false
		}
		const { dispatch } = this.props

		const canvas = this.editor.getImage()
		let dataUrl = canvas.toDataURL('image/jpeg')

		this.props.onClose('upload')

		dispatch(FileActions.canvasToImage(dataUrl))
	}

	rotateCanvas() {
		if (this.state.rotate === 360) {
			this.setState({ rotate: 0 })
		}
		this.setState({ rotate: this.state.rotate + 90 })
	}

	render() {
		let style = {
			margin: 'auto',
			height: 300,
			width: 300,
			cursor: 'all-scroll',
		}
		return (
			<div className="prof-dialog">
				<div className="prof-dialog__text hidden-sp">
					<p>写真を切り取る範囲を指定してください。</p>
					<p>下記円形のエリアをマウスでずらして調整できます。</p>
				</div>
				<div>
					<div style={style}>
						<AvatarEditor
							ref={this.setEditorRef}
							image={this.state.file}
							width={250}
							height={250}
							borderRadius={250 / 2}
							color={[0, 0, 0]} // RGBA
							scale={1.2}
							rotate={this.state.rotate}
						/>
					</div>
				</div>
				<div className="prof-dialog__button">
					<div className="btn-wrap margin_bottom btn-setting-1">
						<button
							className="btn border_white"
							onClick={(e) => this.rotateCanvas(e)}
						>
							<i className="material-icons btn__icons">loop</i>
							回転
						</button>
					</div>
					<div className="btn-wrap margin_bottom btn-setting-2">
						<button
							className="btn-raised color_default"
							onClick={(e) => this.handleSaveImage(e)}
						>
							この位置で保存
						</button>
					</div>
					<div className="btn-wrap margin_bottom btn-setting-3">
						<button
							className="btn color_white"
							onClick={(e) => this.handleDialogClose(e)}
						>
							キャンセル
						</button>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return store
}

export default connect(mapStateToProps)(SettingProfileImage)
