import {
	GO_TO_POINT_REPORT_SCREEN_AT_FIRST_TIME,
	SET_TARGET_POINT,
	GET_CHART_DATA,
	GET_CALENDAR_DATA,
	GET_COMMON_DATA_FOR_POINT_REPORT,
	GET_DEFAULT_TARGET_POINT
} from '../constants/ActionTypes'

const initialState = {
	isVisited: false,
	targetPoint: { value: {}, isLoaded: false },
	commonData: { value: {}, isLoaded: false },
	chartData: { value: [], isLoaded: false },
	calendarData: { currentMonth: '', value: [], isLoaded: false },
}

export const PointReport = (state = initialState, action) => {
	switch (action.type) {

	case GO_TO_POINT_REPORT_SCREEN_AT_FIRST_TIME: {
		return {...state, isVisited: true}
	}
	case SET_TARGET_POINT: {
		return { ...state, targetPoint: { value: action.data, isLoaded: true }}
	}
	case GET_DEFAULT_TARGET_POINT: {
		return { ...state, targetPoint: { value: action.data, isLoaded: true }}
	}
	case GET_CHART_DATA: {
		return { ...state, chartData: { value: action.data, isLoaded: true} }
	}
	case GET_CALENDAR_DATA: {
		return { ...state, calendarData: { currentMonth: action.data.currentMonth, value: action.data.data, isLoaded: true} }
	}
	case GET_COMMON_DATA_FOR_POINT_REPORT: {
		return { ...state, commonData: { value: action.data, isLoaded: true} }
	}
	default: {
		return state
	}
	}
}
