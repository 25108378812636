import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { glasConfig } from '../constants/Config'
import * as MyPasswordActions from '../actions/MyPasswordActions'
import SettingMenu from './SettingMenu'
import TextField from 'material-ui/TextField'
import Snackbar from 'material-ui/Snackbar'
import $ from 'jquery'

class SettingPassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			message: '',
			error: false,
		}
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('#lead-nav').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('パスワード変更')
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.menu-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
	}

	handleChange(event) {
		let form = document.passwordForm

		let nowPassword = form.nowPassword.value.length
		let newPassword = form.newPassword.value.length
		let renewPassword = form.renewPassword.value.length

		if (nowPassword || newPassword || renewPassword) {
			form.submitBtn.disabled = false
		} else {
			form.submitBtn.disabled = true
		}
	}

	handleSubmit(event) {
		if (event.target.name === 'passwordForm') {
			event.preventDefault()

			const { dispatch } = this.props

			let nowPassword = event.target.nowPassword.value
			let newPassword = event.target.newPassword.value
			let renewPassword = event.target.renewPassword.value

			let error = false
			let message = ''
			if (this.validPasswordValue(newPassword, renewPassword)) {
				error = true
				message = 'パスワードが一致しません'
			} else if (this.validPasswordLength(newPassword.length)) {
				error = true
				message = `パスワードは${glasConfig.account.password}文字以上入力してください`
			} else if (
				!(
					this.validPasswordValue(newPassword, renewPassword) ||
					this.validPasswordLength(newPassword.length)
				)
			) {
				event.target.nowPassword.value = ''
				event.target.newPassword.value = ''
				event.target.renewPassword.value = ''

				dispatch(MyPasswordActions.put(nowPassword, newPassword))
			}

			this.setState({
				error: error,
				message: message,
			})
		}
	}

	validPasswordValue(newPassword, renewPassword) {
		return newPassword === renewPassword ? false : true
	}

	validPasswordLength(newPassword) {
		return newPassword >= glasConfig.account.password ? false : true
	}

	handleErrorClose() {
		this.setState({
			error: false,
		})
	}

	render() {
		// let errorMessage = (this.state.message)?(<p>{this.state.message}</p>):''
		return (
			<div className="content">
				<h1 className="content__title hidden-sp">設定</h1>
				<ol className="breadcrumb hidden-sp">
					<li className="breadcrumb__item">
						<Link to="/home/setting/account">アカウント編集</Link>
					</li>
					<li className="breadcrumb__item">
						<p>パスワード変更</p>
					</li>
				</ol>
				<SettingMenu />
				<div className="primary">
					<div className="primary-title hidden-sp">
						<h1 className="primary-title__main hidden-sp">
							パスワード変更
						</h1>
					</div>
					<div className="primary__inner">
						<form
							name="passwordForm"
							onSubmit={(e) => this.handleSubmit(e)}
						>
							<dl className="definition">
								<dt className="definition__title write_down password_title">
									現在のパスワード
								</dt>
								<dd className="definition__data margin_bottom password_input">
									<TextField
										type="password"
										name="nowPassword"
										onChange={(e) => this.handleChange(e)}
									/>
								</dd>
								<dt className="definition__title write_down password_title">
									新しいパスワード
								</dt>
								<dd className="definition__data margin_bottom password_input">
									<TextField
										type="password"
										name="newPassword"
										onChange={(e) => this.handleChange(e)}
									/>
								</dd>
								<dt className="definition__title write_down password_title">
									新しいパスワード(再入力)
								</dt>
								<dd className="definition__data margin_bottom password_input">
									<TextField
										type="password"
										name="renewPassword"
										onChange={(e) => this.handleChange(e)}
									/>
								</dd>
							</dl>
							<div className="btn-wrap">
								<input
									type="submit"
									name="submitBtn"
									className="btn-raised color_default"
									value="変更"
									disabled
								/>
							</div>
						</form>
					</div>
				</div>
				<Snackbar
					open={this.state.error}
					message={this.state.message}
					autoHideDuration={4000}
					onRequestClose={(e) => this.handleErrorClose(e)}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyPassword: state.MyPassword,
	}
}

export default connect(mapStateToProps)(SettingPassword)
