import React, { Component } from 'react'
import { PrivateRoute } from './PrivateRoute'
import MissionElementary from './MissionElementary'
import MissionIntermediate from './MissionIntermediate'
import MissionAdvanced from './MissionAdvanced'
import MissionSpecial from './MissionSpecial'
import MissionArchived from './MissionArchived'
import { isMobile } from 'react-device-detect'

class RouteMission extends Component {
	render() {
		return (
			<span>
				<PrivateRoute
					path={isMobile ? `${this.props.match.path}/elementary` : `${this.props.match.path}/daily`}
					component={MissionElementary}
				/>
				<PrivateRoute
					path={isMobile ? `${this.props.match.path}/intermediate` : `${this.props.match.path}/start-dash`}
					component={MissionIntermediate}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/advanced`}
					component={MissionAdvanced}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/special`}
					component={MissionSpecial}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/archived`}
					component={MissionArchived}
				/>
			</span>
		)
	}
}

export default RouteMission
