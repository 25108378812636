import styled from 'styled-components'
import { POINT_REPORT } from '../../../constants/PointReportConstants'
import { colors, fonts, DEVICE } from '../../../util/StellaConstant'

/*
    @media ${DEVICE.mobileS} {

    }
    @media ${DEVICE.laptop} {

    }
    @media ${DEVICE.laptopL} {

    }
*/

export const StyledContainerMainContent = styled.div`
	@media ${DEVICE.mobileS} {
		position: absolute;
		top: 50px;
		width: 100%;
	}
	@media ${DEVICE.laptop} {
		// position: absolute;
		// top: 70px;
		// left: 260px;
		// width: calc(100% - 260px);
		padding: 88px 10px 48px 288px;
		position: unset;
		top: unset;
		left: unset;
		width: unset;
	}
	}
	@media ${DEVICE.laptopL} {
		padding: 88px 10px 48px 288px;
		position: unset;
		top: unset;
		left: unset;
		width: unset;
	}
`

export const ContentLayout = styled.div`
	@media ${DEVICE.mobileS} {
		display: grid;
		grid-template-areas: 
			'TabTitle'
			'ChartBlock'
			'StatisticBlock';
		padding: 0 10px 60px;
	}
	@media ${DEVICE.laptop} {
		display: grid;
	}
	@media ${DEVICE.laptopL} {
		display: grid;
		grid-template-areas:
			'ChartBlock'
			'StatisticBlock';
		padding: 0 0 60px;
		margin-top: 24px;
	}
`

export const ScreenName = styled.div`
	@media ${DEVICE.mobileS} {
		display: none;
	}
	@media ${DEVICE.laptop} {
		display: none;
	}
	@media ${DEVICE.laptopL} {
		display: initial;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 24px;
		margin-bottom: 16px;
	}
`

export const TabTitle = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: TabTitle;
		font-family: ${fonts.HiraKakuProW3}
		font-size: 13px;
		color: ${colors.mediumBlue};
		margin: 17px 0 15px 0;
	}
	@media ${DEVICE.laptop} {
		font-size: 14px;
		margin: 32.5px auto 28px auto;
	}
	@media ${DEVICE.laptopL} {
		display: none;
	}
`
export const Title = styled.p`
	@media ${DEVICE.mobileS} {
		display: block;
		color: ${props => POINT_REPORT.TITLES[props.type].color};
		font-family: ${props => POINT_REPORT.TITLES[props.type].fontFamily.mobile};
		font-size: ${props => POINT_REPORT.TITLES[props.type].fontSize.mobile};
		background-color: ${props => POINT_REPORT.TITLES[props.type].backgroundColor};
		padding: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '7px 17px' : '0'};
		width: fit-content;
		text-align: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'center' : 'left' };
		border-radius: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '13px' : '0'};
		margin: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'auto' : '0'};
		margin-bottom: 8px;
	}
	@media ${DEVICE.laptop} {
		display: block;
		color: ${props => POINT_REPORT.TITLES[props.type].color};
		font-family: ${props => POINT_REPORT.TITLES[props.type].fontFamily.laptop};
		font-size: ${props => POINT_REPORT.TITLES[props.type].fontSize.laptop};
		background-color: ${props => POINT_REPORT.TITLES[props.type].backgroundColor};
		padding: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '7px 17px' : '0'};
		width: fit-content;
		text-align: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'center' : 'left' };
		border-radius: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? '13px' : '0'};
		margin: ${props => POINT_REPORT.TITLES[props.type].isHaveBackground ? 'auto' : '0'};
		margin-bottom: 8px;
	}
	@media ${DEVICE.laptopL} {
		font-family: ${props => POINT_REPORT.TITLES[props.type].fontFamily.laptopL};
		font-size: ${props => POINT_REPORT.TITLES[props.type].fontSize.laptopL};
		margin-top: 0;
	}
`

export const ChartBlock = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: ChartBlock;
		display: grid;
		grid-template-areas:
			'TargetPoint'
			'ActualPoint'
			'PointBonus'
			'ChartContainer'
			'RedirectDailyReward';
	}
	@media ${DEVICE.laptop} {
		background-color: ${colors.white};
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
		border-radius: 4px;
		padding: 26px 11px 21px 20px;
		margin-bottom: 11px;
		grid-column-gap: 14px;
		// grid-row-gap: 21px;
		grid-template-areas:
			'TargetPoint ActualPoint PointBonus'
			'ChartContainer ChartContainer'
			'RedirectDailyReward RedirectDailyReward';
	}
	@media ${DEVICE.laptopL} {
		background-color: ${colors.white};
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
		border-radius: 4px;
		padding: 26px 11px 21px 20px;
		margin-bottom: 11px;
		grid-column-gap: 14px;
		// grid-row-gap: 21px;
		grid-template-areas:
			'TargetPoint ActualPoint PointBonus'
			'ChartContainer ChartContainer ChartContainer';
	}
`

export const StatisticBlock = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: StatisticBlock;
		display: grid;
		grid-column-gap: 10px;
		grid-row-gap: 13px;
		grid-template-areas:
			'WorkingDayInAMonth'
			'RateOfPaidMessages'
			'RateOfReplyUserQuestions';
	}
	@media ${DEVICE.laptop} {
		grid-template-areas:
			'WorkingDayInAMonth RateOfReplyUserQuestions'
			'RateOfPaidMessages RateOfPaidMessages';
	}
	@media ${DEVICE.laptopL} {
		grid-template-areas: 'WorkingDayInAMonth RateOfPaidMessages RateOfReplyUserQuestions';
	}
`

export const TargetPoint = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: TargetPoint;
		background-color: ${colors.snow};
		padding: 14px 13px 14px 18px;
		margin-bottom: 6px;
		
	}
	@media ${DEVICE.laptop} {
		padding: 19px 17px 16px 15px;
		margin-bottom: 0;
	}
	@media ${DEVICE.laptopL} {
		padding: 19px 38px 16px 18px;
		margin-bottom: 0;
		min-width: 370px;
	}
`

export const ActualPoint = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: ActualPoint;
		background-color: ${colors.azureMist};
		padding: 14px 10px 14px 18px;
		margin-bottom: 6px;

	}
	@media ${DEVICE.laptop} {
		padding: 19px 22px 15px 18px;
		margin-bottom: 0;
	}
	@media ${DEVICE.laptopL} {
		padding: 19px 37px 15px 18px;
		margin-bottom: 0;
		min-width: 433px;
	}
`
export const PointBonus = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: PointBonus;
		background-color: ${colors.azureMist};
		padding: 14px 10px 19px 18px;
		margin-bottom: 6px;

	}
	@media ${DEVICE.laptop} {
		padding: 19px 22px 15px 18px;
		margin-bottom: 0;
	}
	@media ${DEVICE.laptopL} {
		padding: 19px 37px 15px 18px;
		margin-bottom: 0;
		width: 370px;
	}
`

export const ChartContainer = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: ChartContainer;
		background-color: ${colors.white};
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
	}
	@media ${DEVICE.laptop} {
		box-shadow: none;
		position: relative;
		padding-bottom: 80px;
	}
	@media ${DEVICE.laptopL} {
		box-shadow: none;
		position: relative;
		padding-bottom: 80px;
	}
`

export const RedirectDailyRewardForMobile = styled.button`
	@media ${DEVICE.mobileS} {
		grid-area: RedirectDailyReward;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 14px;
		color: ${colors.mediumBlue};
		text-align: center;
		padding: 11px 25.5px;
		border: none;
		outline: none;
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 ${colors.oxfordBlue},0 0 2px 0 ${colors.onyx};
		background-color: ${colors.ghostWhite};
		margin: 11px auto 0;
	}
	@media ${DEVICE.laptop} {
		display: none;
	}
	@media ${DEVICE.laptopL} {
		display: none;
	}
`

export const RedirectDailyReward = styled.button`
	@media ${DEVICE.mobileS} {
		display: none;
	}
	@media ${DEVICE.laptop} {
		display: initial;
		grid-area: RedirectDailyReward;
		color: ${colors.mediumBlue};
		text-align: center;
		border: none;
		outline: none;
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 ${colors.oxfordBlue},0 0 2px 0 ${colors.onyx};
		background-color: ${colors.ghostWhite};
		// margin: 11px auto 0;
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 16px;
		position: absolute;
		bottom: 33px;
		right: 14px;
	}
	@media ${DEVICE.laptopL} {
		display: initial;
		font-size: 15px;
		font-family: ${fonts.HiraKakuProW3};
		position: absolute;
		bottom: 33px;
		right: 14px;
	}
`
export const WorkingDayInAMonth = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: WorkingDayInAMonth;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
		background-color: ${colors.white};
		justify-content: center;
		align-items: center;
		margin-top: 11px;
		padding: 19px 16px 7px;
	}
	@media ${DEVICE.laptop} {
		margin-top: 0;
	}
	@media ${DEVICE.laptopL} {
		justify-content: start;
	}
`
export const RateOfPaidMessages = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: RateOfPaidMessages;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
		background-color: ${colors.white};
		justify-content: center;
		align-items: center;
		margin-top: 11px;
		padding: 19px 16px 7px;
	}
	@media ${DEVICE.laptop} {
		margin-top: 0;
	}
	@media ${DEVICE.laptopL} {
		justify-content: start;
	}
`

export const RateOfPaidMessagesNotice1 = styled.p`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 14px;
		color: ${colors.accent};
		text-align: center;
		margin-bottom: 9px;
		line-height: 1;
		white-space: pre-line;
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 16px;
		line-height: 1.1;
	}
	@media ${DEVICE.laptopL} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 16px;
		line-height: 1.1;
	}
`
export const RateOfPaidMessagesNotice2 = styled.p`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 10px;
		color: ${colors.dimGray};
		text-align: center;
		line-height: 1;
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 12px;
	}
	@media ${DEVICE.laptopL} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 12px;
	}
`

export const RateOfReplyUserQuestions = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: RateOfReplyUserQuestions;
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		box-shadow: 0 1px 3px 0 ${colors.oxfordBlue};
		background-color: ${colors.white};
		justify-content: center;
		align-items: center;
		margin-top: 11px;
		padding: 19px 16px 7px;
	}
	@media ${DEVICE.laptop} {
		margin-top: 0;
	}
	@media ${DEVICE.laptopL} {
		justify-content: start;
	}
`

export const StatisticData = styled.span`
	@media ${DEVICE.mobileS} {
		color: ${props => POINT_REPORT.STATISTICS[props.type].DATA.color};
		font-size: ${props => POINT_REPORT.STATISTICS[props.type].DATA.fontSize.mobile};
		font-family: ${props => POINT_REPORT.STATISTICS[props.type].DATA.fontFamily.mobile};
	}
	@media ${DEVICE.laptop} {
		color: ${props => POINT_REPORT.STATISTICS[props.type].DATA.color};
		font-size: ${props => POINT_REPORT.STATISTICS[props.type].DATA.fontSize.laptop};
		font-family: ${props => POINT_REPORT.STATISTICS[props.type].DATA.fontFamily.laptop};
	}
	@media ${DEVICE.laptopL} {
		color: ${props => POINT_REPORT.STATISTICS[props.type].DATA.color};
		font-size: ${props => POINT_REPORT.STATISTICS[props.type].DATA.fontSize.laptopL};
		font-family: ${props => POINT_REPORT.STATISTICS[props.type].DATA.fontFamily.laptopL};
	}
`

export const ButtonSetTargetPoint = styled.button`
	@media ${DEVICE.mobileS} {
		border: 1px solid ${colors.accent};
		border-radius: 3px;
		background-color: ${colors.white};
		padding: 6px 30px;
		color: ${colors.accent};
		font-family: ${fonts.HiraKakuProW3};
		font-size: 14px;
		box-shadow: 0 2px 2px 0 ${colors.pinkPearl};
		align-self: flex-start;
		margin-top: -7px;
		margin-left: 20px;
	}
	@media ${DEVICE.laptop} {
		padding: 11px 33px 11px 34px;
		margin-left: 20px;
	}
	@media ${DEVICE.laptopL} {
		padding: 11px 33px 11px 34px;
		margin-left: 20px;
	}
`

export const StatisticUnit = styled.span`
	@media ${DEVICE.mobileS} {
		color: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.color};
		font-size: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.fontSize.mobile};
		font-family: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.fontFamily.mobile};
	}
	@media ${DEVICE.laptop} {
		color: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.color};
		font-size: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.fontSize.laptop};
		font-family: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.fontFamily.laptop};
	}
	@media ${DEVICE.laptopL} {
		color: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.color};
		font-size: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.fontSize.laptopL};
		font-family: ${props => POINT_REPORT.STATISTICS[props.type].UNIT.fontFamily.laptopL};
	}
`

export const Row = styled.div`
	@media ${DEVICE.mobileS} {
		display: flex;
		justify-content: ${props => props.align};
		align-items: baseline;
	}
	@media ${DEVICE.laptop} {

	}
	@media ${DEVICE.laptopL} {

	}
`

export const Ruler = styled.div`
	@media ${DEVICE.mobileS} {
		width: 100%;
		height: 1px;
		background-color: ${colors.gainsboro};
		margin: 13px 0 9px;
	}
	@media ${DEVICE.laptop} {
		width: 180px;
		margin: 13px 0 17px;
	}
	@media ${DEVICE.laptopL} {

	}
`

export const TotalActualPoint = styled.span`
	@media ${DEVICE.mobileS} {
		display: none;
	}
	@media ${DEVICE.laptop} {
		display: none;
	}
	@media ${DEVICE.laptopL} {
		display: initial;
		font-size: 17px;
		font-family: ${fonts.HiraKakuProW3};
		margin-right: 10px;
	}
`

export const LastDayActualPoint = styled.span`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 13px;
	}
	@media ${DEVICE.laptop} {
		font-size: 14px;
	}
	@media ${DEVICE.laptopL} {
		font-size: 16px;

	}
`