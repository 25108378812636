import React, { Component } from 'react'
import { glasConfig, Methods } from '../constants/Config'
import Checkbox from 'material-ui/Checkbox'

export default class SettingMethods extends Component {
	handleClick(event, isInputChecked) {
		this.props.handleClick(
			event,
			isInputChecked,
			'methods',
			'得意占術を選択してください'
		)
	}

	render() {
		let list = []
		let methods = Methods

		for (let i in methods) {
			let disabled = false
			let checked = false
			let defaultChecked = false
			if (this.props.checked.indexOf(methods[i]) !== -1) {
				checked = true
			}
			if (this.props.disabled.indexOf(methods[i]) !== -1) {
				disabled = true
			}
			if (
				this.props.defaultChecked !== undefined &&
				this.props.defaultChecked.indexOf(methods[i]) !== -1
			) {
				defaultChecked = true
			} else if (
				this.props.defaultChecked !== undefined &&
				this.props.defaultChecked.length === glasConfig.setting.methods
			) {
				disabled = true
			}

			list.push(
				<li className="list__item" key={methods[i]}>
					<Checkbox
						label={methods[i]}
						onCheck={(e, i) => this.handleClick(e, i)}
						checked={checked}
						defaultChecked={defaultChecked}
						disabled={disabled}
						id={'methods-' + i}
						name="methods[]"
						value={methods[i]}
					/>
				</li>
			)
		}

		return (
			<dl className="definition wide_bottom">
				<dt className="definition__title">
					得意占術
					<span className="definition__description">1～6つ選択</span>
				</dt>
				<dd className="definition__data data_methods">
					<ul className="list flex_start">{list}</ul>
				</dd>
			</dl>
		)
	}
}
