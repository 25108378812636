/**
 * 通話・チャットリクエスト（左カラムの対応中(inConversation:true)、相談依頼申請一覧(inConversation:false)）
 */
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
require('moment/locale/ja')

export default class LeftOfflineList extends PureComponent {
	constructor(props) {
		super(props)
		const orgtime = this.makeOrgTime(this.props.data.serverTime)
		this.state = {
			org: orgtime,
			view: moment(orgtime).fromNow(),
		}
	}

	makeOrgTime(time) {
		moment.locale('ja')
		return moment(time, 'YYYYMMDDHHmmss')
			.add(9, 'hours')
			.format('YYYY-MM-DD HH:mm:ss')
	}

	countUp() {
		this.setState({
			view: moment(this.state.org).fromNow(),
		})
	}

	componentDidMount() {
		this.interval = setInterval(() => this.countUp(), 1000)
	}

	componentWillReceiveProps(nextProps) {
		const nexttime = this.makeOrgTime(nextProps.data.serverTime)
		if (nexttime !== this.state.orgtime) {
			this.setState({
				org: nexttime,
				view: moment(nexttime).fromNow(),
			})
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	render() {
		const { data, chatUserId, userNameOfflineRequest, closeNavLeft } =
			this.props
		if (!data.fromId) {
			return null
		}

		const viewtime = this.state.view === '数秒前' ? '今' : this.state.view

		const userName =
			data.fromName || data.userName || userNameOfflineRequest
		const label =
			data.type === 2 ? (
				<span className="btn-customer__call">通話</span>
			) : (
				''
			)
		let chooseClass = 'btn-customer customer_default'
		if (chatUserId === data.fromId) {
			chooseClass = 'btn-customer customer_request-choose'
		} else {
			if (moment().diff(moment(this.state.org), 'seconds') <= 10) {
				chooseClass = 'btn-customer customer_default'
			}
		}

		return (
			<Link
				to={`/home/chat/${data.fromId}`}
				className="list__item"
				onClick={closeNavLeft}
			>
				<div
					className={chooseClass}
					key={
						data.msgId
							? data.msgId
							: 'offline_' + data.serverTime + data.fromId
					}
				>
					<p className="btn-customer__item is_name">
						{userName}
						{label}
					</p>
					<p className="btn-customer__item is_time">{viewtime}</p>
				</div>
			</Link>
		)
	}
}
