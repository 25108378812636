/**
 * チャット申し込みダイアログ
 *
 */
import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import './dialogGetPoint.css'
import imgMission from '../../assets/mission_getreward.png'
import { glasConfig } from '../../constants/Config'
import request from 'axios'
import * as Fetch from '../../util/Fetch'

class DialogGetPoint extends Component {
	constructor(props) {
		super(props)
		/**
		 * show: ダイアログ true:表示,false:非表示
		 */
		this.state = {
			show: false,
			data: {
				rewardId: '',
				numberPoint: 0,
				isIntroduceCustomer: false
			},
		}
	}
	show = (data) => {
		data = data || this.state.data
		this.setState({ show: true, data })
	}
	_onClose = () => {
		this.setState({ show: false })
	}
	_onGetPoint = () => {
		const token = localStorage.getItem('token')
		const { rewardId, isIntroduceCustomer } = this.state.data
		const endpoint = isIntroduceCustomer ? glasConfig.get_reward_customer : glasConfig.reward_point_introduction
		return request
			.put(
				glasConfig.url_base + endpoint,
				{},
				{
					headers: { Authorization: token },
					params: { rewardId },
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
			.finally(() => {
				this._onClose()
				this.props.onClose && this.props.onClose()
			})
	}

	render() {
		const { data } = this.state
		return (
			<div className="dl-get-point">
				<Dialog
					modal={true}
					open={this.state.show}
					bodyClassName="dialog__content"
					contentClassName="dialog-content"
				>
					<h3 className="dl-get-point-title">
						{' '}
						ボーナスポイントを獲得しました！{' '}
					</h3>
					<div className="dl-get-point-content">
						<img src={imgMission} />
						<p className="label-point">
							{data.numberPoint}
							{'PT'}
						</p>
					</div>
					<div className="logout-dialog-btn-wrap">
						<div className="btn-wrap margin_bottom btn-dialog">
							<button
								onClick={this._onGetPoint}
								className="btn-raised color_accent"
							>
								報酬を受け取る！
							</button>
						</div>
						<div className="btn-wrap margin_bottom btn-bottom-dialog">
							<a
								onClick={this._onClose}
								href="javascript:;"
								style={{ color: '#c6c6c6' }}
							>
								キャンセル
							</a>
						</div>
					</div>
					{/* <Snackbar
          open={this.state.submitSuccess}
          message="正常に保存しました。審査完了をお待ちください。"
          autoHideDuration={5000}
          onRequestClose={e=>this.handleSubmitClose(e)}
          className="message-dialog"
        /> */}
				</Dialog>
			</div>
		)
	}
}

export default DialogGetPoint
