import React, { Component } from 'react'
import { connect } from 'react-redux'

import Dialog from 'material-ui/Dialog'
import Checkbox from 'material-ui/Checkbox'
import moment from 'moment'
import * as Fetch from '../util/Fetch'

const DO_NOT_SHOW_TODAY = '今日はこのお知らせを表示しない'

class DialogConfirmSendTemplate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			checked: false,
		}
	}

	componentDidMount() {
		const { show } = this.props
		this.setState({ show: show })
	}

	doNotShowToday = async () => {
		if (this.state.checked) {
			let flagConfirmSendTemplate = await localStorage.getItem(
				'flagConfirmSendTemplate'
			)

			flagConfirmSendTemplate =
				!flagConfirmSendTemplate ||
				flagConfirmSendTemplate === null ||
				flagConfirmSendTemplate === 'null'
					? {}
					: JSON.parse(flagConfirmSendTemplate)
			flagConfirmSendTemplate[Fetch.tellerId()] = moment()
				.format('YYYYMMDD')
				.toString()

			localStorage.setItem(
				'flagConfirmSendTemplate',
				JSON.stringify(flagConfirmSendTemplate),
				() => {
					this.setState({ checked: false })
				}
			)
		}
	}

	handleApprove = () => {
		const { data } = this.props
		const templateContent = data.templateContent

		this.handleReject()
		this.doNotShowToday()
		this.props.sendMessage('', templateContent, '', true)
	}

	handleReject = () => {
		this.setState({ show: false })
		this.doNotShowToday()
		this.props.close()
	}

	handleCheckDoNotShowToday = (e) => {
		this.setState({ checked: !this.state.checked })
	}

	render() {
		const show = this.state.show
		const { data } = this.props

		return (
			<Dialog
				titleStyle={{ color: '#11ad9d' }}
				modal={false}
				open={show}
				className="dialogConfirmSendTemplate"
			>
				<i
					className="material-icons closeMessageOffline"
					onClick={this.handleReject}
				>
					clear
				</i>
				{/* Title */}
				<div className="dialogConfirmSendTemplateTitle">
					<span>「{data.templateTitle}」</span>を送信しますか？ <br />
					※無料チャットで送信されます
				</div>

				{/* Content Template */}
				<div className="dialogConfirmSendTemplateContent">
					<p className="dialogMainContent">{data.templateContent}</p>
				</div>

				{/* Button Approve/Reject */}
				<div className="containerButton">
					<button
						className="approveSendTemplate"
						onClick={this.handleApprove}
					>
						はい
					</button>
					<button
						className="rejectSendTemplate"
						onClick={this.handleReject}
					>
						いいえ
					</button>
				</div>

				{/* Checkbox Dont Show */}
				<div className="checkboxConfirmSendTemplate hidden-mobile">
					<Checkbox
						label={DO_NOT_SHOW_TODAY}
						onCheck={(check) =>
							this.handleCheckDoNotShowToday(check)
						}
						checked={this.state.checked}
						iconStyle={styles.iconStyle}
						labelStyle={styles.labelStylePC}
					/>
				</div>
				<div className="checkboxConfirmSendTemplate hidden-pc">
					<Checkbox
						label={DO_NOT_SHOW_TODAY}
						onCheck={(check) =>
							this.handleCheckDoNotShowToday(check)
						}
						checked={this.state.checked}
						iconStyle={styles.iconStyle}
						labelStyle={styles.labelStyleMB}
					/>
				</div>
			</Dialog>
		)
	}
}

const styles = {
	iconStyle: {
		color: 'rgba(0, 0, 0, 0.25)',
	},
	labelStyleMB: {
		color: 'rgba(0, 0, 0, 0.64)',
		fontSize: 14,
		width: '100%',
	},
	labelStylePC: {
		color: 'rgba(0, 0, 0, 0.64)',
	},
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(DialogConfirmSendTemplate)
