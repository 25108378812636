import axios from 'axios'
import { GET_LIST_CATEGORY_BLOG } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data
	}
}

export const getCategoryBlog = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		axios
			.get(glasConfig.url_base + glasConfig.blog_categories, {
				headers: {
					Authorization: token,
					'Content-Type': 'multipart/form-data',
				}
			})
			.then(response => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(GET_LIST_CATEGORY_BLOG, response.data.data))
				}
			})
			.catch(error => console.log(error))
	}
}