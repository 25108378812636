export const denyStatuses = [
	{
		name: 'InvalidId',
		text: '受け付けない身分証です。</br> 確認に適したものを再送してください。',
	},
	{
		name: 'PrivateId',
		text: '身分証と本人の顔が必要です。</br>自分の顔のそばに身分証を構えたものを撮影して再送してください。</br>※その時身分証の内容がぼやけたりして見えなくならないよう注意してください',
	},
	{ name: 'Student', text: '高校在学年齢の方はご利用いただけません。' },
	{
		name: 'InvalidName',
		text: '身分証の名義と、ご登録者の名義が一致していません。</br>ご登録者の方の身分証を登録してください。',
	},
	{
		name: 'UnclearId',
		text: '身分証がぼやけている、加工しすぎているなどで内容が読み取れません。</br>内容が確認できるように撮影しなおして再送してください。',
	},
	{
		name: 'NoId',
		text: '身分証の顔写真が確認できません。</br>隠さずに再送してください。',
	},
	{
		name: 'NotCofirmId',
		text: '身分証と本人の顔が必要です。</br>自分の顔の傍に身分証を構えたものを撮影して再送してください。',
	},
	{
		name: 'LackInfo',
		text: '認証に必要な情報が不足しています。</br>確認しなおして再送してください。',
	},
	{
		name: 'NeedPOA',
		text: '日本国籍か、永住権等の所持証明が必要です。</br>確認できる証明書を再送してください。',
	},
	{
		name: 'NotJapan',
		text: 'ステラでは日本国籍の方以外は現在働いていただくことができません。',
	},
	{
		name: 'DoNothing',
		text: '受け付けられない身分証です。事務局窓口までお問い合わせください',
	},
]
