import { GET_LIST_CATEGORY_BLOG } from '../constants/ActionTypes'

const initialState = {
	data: [],
}
export const CategoryBlog = (state = initialState, action) => {
	switch (action.type) {
		case GET_LIST_CATEGORY_BLOG:
			return Object.assign({ data: action.data })

		default:
			return state
	}
}