import {
	AUTH_LOGIN,
	AUTH_TELLERID,
	AUTH_LOGOUT,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import { setLocalStorage, removeLocalStorage } from '../util/Fetch'
import request from 'axios'
import SparkMD5 from 'spark-md5'
import jwtdecode from 'jwt-decode'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

const resultSuccess = (type, error, data) => {
	return {
		type: type,
		errormsg: error,
		data,
	}
}

//ログイン処理
export const fetchLogin = (res) => {
	return (dispatch) => {
		dispatch(showLoading())
		return request
			.post(glasConfig.url_base + glasConfig.path_teller_login, {
				email: res.loginid,
				password: SparkMD5.hash(res.password),
				autoLogin: false,
				deviceType: res.deviceType,
				applicationName: glasConfig.application,
				pushNotificationToken: res.token,
			})
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					const tellerId = localStorage.getItem('tellerId')
					if (tellerId !== response.data.data.fortuneTellerId) {
						localStorage.setItem('statusAnswer', 1)
						localStorage.setItem('categoryAnswer', 0)
						localStorage.setItem('sortByAnswer', 1)
						localStorage.setItem('showPopUpBestAnswer', 'false')
						localStorage.setItem(
							'tellerId',
							response.data.data.fortuneTellerId
						)
						localStorage.setItem('lastTellerId', tellerId)
					}
					const token = response.data.data.token
					localStorage.setItem('token', token)
					const decoded = jwtdecode(token)
					const date = new Date()
					const expiredRemainTime =
						decoded.expiredRemainTime + date.getTime()
					localStorage.setItem('expiredRemainTime', expiredRemainTime)
					dispatch(resultSuccess(AUTH_LOGIN, '', response.data.data))
				} else {
					dispatch(
						resultSuccess(
							AUTH_LOGIN,
							'ログインIDまたはパスワードが間違っています',
							{}
						)
					)
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + AUTH_LOGIN, error)
				dispatch(resultSuccess(AUTH_LOGIN, 'システムエラー', {}))
			})
	}
}

//占いIDを取得
export const fetchTellerId = () => {
	return (dispatch) => {
		const tellerId = Fetch.tellerId()
		dispatch(resultSuccess(AUTH_TELLERID, '', tellerId))
	}
}

//ログアウト
export const logout = (nextlogin) => {
	const token = localStorage.getItem('token')
	let options = {
		method: 'PUT',
		url: glasConfig.url_base + glasConfig.path_teller_login + token,
		headers: {
			Authorization: token,
			'Content-Type': 'application/json',
		},
		json: true,
	}

	let params
	if (nextlogin) {
		params = nextlogin
	}

	if (params) {
		options['data'] = params
	}

	return (dispatch) => {
		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					setLocalStorage()
					dispatch(resultSuccess(AUTH_LOGOUT))
				} else {
					dispatch(resultSuccess(AUTH_LOGOUT, 'システムエラー', {}))
				}
				removeLocalStorage()
			})
			.catch((error) => {
				console.log('error on call ' + AUTH_LOGIN, error)
				dispatch(resultSuccess(AUTH_LOGIN, 'システムエラー', {}))
			})
	}
}
