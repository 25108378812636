import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import request from 'axios'
import { glasConfig } from '../constants/Config'

export default class DialogOutChat extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			funcAccept: null,
		}
	}

	componentDidMount() {
		this.checkCloseChat()
	}

	checkUserCloseChat(message) {
		return (
			message.msgType === 'CLOSE_CHAT' &&
			this.props.userData.userId === message.fromId
		)
	}

	checkCloseChat() {
		const { socket } = this.props
		socket.on('userRequest', (res) => {
			const message = JSON.parse(res)
			if (
				this.checkUserCloseChat(message) ||
				message.msgType === 'RESPONSE_REQUEST_CHAT'
			) {
				this.closeDialog()
			}
		})
	}

	callApiOutChat(requestId) {
		request.delete(
			glasConfig.url_base +
				glasConfig.path_chat_request +
				requestId +
				'/teller',
			{
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			}
		)
	}

	handleOutChat = () => {
		const userId = this.props.userData.userId
		const token = localStorage.getItem('token')
		this.closeDialog()

		request
			.get(
				glasConfig.url_base +
					glasConfig.path_chat_request +
					`checkinconversation/forteller?userId=${userId}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (
					response.data.code === 0 &&
					response.data.data.inconversation
				) {
					this.callApiOutChat(response.data.data.requestId)
				}
			})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				className="dialog-out-chat"
				bodyClassName="out-chat__body"
				contentClassName="out-chat__content"
			>
				<i
					className="material-icons tab__icons closeBlock"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<p className="dialog-out-chat__content1">
					占い師から退席を行うのは以下の場合のみ行ってください。
				</p>
				<p className="dialog-out-chat__content2">
					<span>・お客様が長時間反応しない場合 </span>
					<br />
					<span>
						・お客様が暴言や嫌がらせなど許容できない行為を行って
						いる場合
					</span>
					<br />
					<span>・その他システム上のエラーなど発生した場合</span>
				</p>
				<p className="dialog-out-chat__content3">
					※上記の場合であっても, 必ず
					<span>無料メッセージで一言断ってから</span>
					退席するようお願い致します。
					<br />
					不適切な退席はお客様の満足度の低下に繋がりますので,
					そのような行為が目立つ方は警告やアカウント停止など対処させていただく場合がございます。
				</p>
				<div className="wrap-btn-out-chat">
					<button
						className="btn-raised color_default btn-accept-out-chat"
						onClick={this.handleOutChat}
					>
						内容に同意して退席する
					</button>
					<button
						className="btn-raised btn-cancel-out-chat"
						onClick={this.closeDialog}
					>
						退席しない
					</button>
				</div>
			</Dialog>
		)
	}
}
