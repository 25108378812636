import React from 'react';

const Pager = (props) => {

    const { handlePage } = props

    if (
        !props.totalRecords
    ) {
        return null
    }

    let nowPage = parseInt(props.nowPage, 10)
    let totalRecords = parseInt(props.totalRecords, 10)
    let item = 20
    let endPage =
        totalRecords > item ? parseInt(totalRecords / item, 10) : 0
    endPage =
        endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1

    let showPage = endPage < 5 ? endPage : 5
    let showHalfPage = Math.floor(showPage / 2)

    let loopStart = nowPage - showHalfPage
    let loopEnd = nowPage + showHalfPage

    if (loopStart <= -1) {
        loopStart = 0
        loopEnd = showPage - 1
    }
    if (loopEnd > endPage) {
        loopStart = endPage - showPage
        loopEnd = endPage
    }

    let firstPage = true
    let prevPage = true
    let lastPage = true
    let nextPage = true

    if (nowPage !== 0) {
        firstPage = false
    }
    if (nowPage !== 0 && endPage !== 0) {
        prevPage = false
    }
    if (nowPage >= 0 && endPage !== nowPage) {
        lastPage = false
    }
    if (nowPage >= 0 && endPage !== nowPage) {
        nextPage = false
    }

    let prevNav = []
    if (endPage > 0) {
        prevNav.push(
            <li className="list__item" key={'first_page_ 1'}>
                <button
                    className="btn"
                    disabled={firstPage}
                    onClick={(e) => handlePage(e, 0)}
                >
                    <i className="material-icons">first_page</i>
                </button>
            </li>
        )
        let prev = nowPage > 0 ? nowPage - 1 : 0
        prevNav.push(
            <li className="list__item" key={'prev' + prev}>
                <button
                    className="btn"
                    disabled={prevPage}
                    onClick={(e) => handlePage(e, prev)}
                >
                    <i className="material-icons">navigate_before</i>
                </button>
            </li>
        )
    }

    let nextNav = []
    if (endPage > 0) {
        let next = nowPage !== endPage ? nowPage + 1 : endPage
        nextNav.push(
            <li className="list__item" key={'navigate_next' + next}>
                <button
                    className="btn"
                    disabled={nextPage}
                    onClick={(e) => handlePage(e, next)}
                >
                    <i className="material-icons">navigate_next</i>
                </button>
            </li>
        )
        nextNav.push(
            <li className="list__item" key={'endPage' + endPage}>
                <button
                    className="btn"
                    disabled={lastPage}
                    onClick={(e) => handlePage(e, endPage)}
                >
                    {' '}
                    <i className="material-icons">last_page</i>
                </button>
            </li>
        )
    }
    let pageNav = []
    for (let i = loopStart; i <= loopEnd; i++) {
        let pageClass = ['btn']
        if (i === nowPage) {
            pageClass.push('is-active')
        }

        pageNav.push(
            <li className="list__item" key={'page' + i}>
                <button
                    className={pageClass.join(' ')}
                    onClick={(e) => handlePage(e, i)}
                >
                    {i + 1}
                </button>
            </li>
        )
    }

    return (
        <div className={'primary'}>
            {prevNav.length > 0 ||
                pageNav.length > 0 ||
                nextNav.length > 0 ? (
                <div className="primary__inner">
                    <div className="pager">
                        <ul className="list">
                            {prevNav}
                            {pageNav}
                            {nextNav}
                        </ul>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Pager