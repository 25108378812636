import React, { Component } from 'react'

export default class MissionDefault extends Component {
	render() {
		return (
            <div className="mission-empty">
					未達成のミッションはありません。
					<br />
					次のミッションが追加されるまでしばらくお待ちください。
				</div>
		)
	}
}