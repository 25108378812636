/**
 * アラート系のダイアログ
 */
import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

class DialogWarningFullChat extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			numberLimitChat: 5,
		}
	}

	openDialog = (numberLimitChat) => {
		this.setState({ show: true, numberLimitChat })
	}

	closeDialog = () => {
		this.setState({ show: false }, () => {
			this.props.closeWarningFullChatDialog()
		})
	}

	render() {
		const actions = [
			<FlatButton
				label="OK"
				primary={true}
				keyboardFocused={true}
				onClick={(e) => this.closeDialog()}
			/>,
		]

		return (
			<div>
				<Dialog
					modal={false}
					actions={actions}
					open={this.state.show}
					onRequestClose={(e) => this.closeDialog()}
				>
					<div>
						同時にチャットできる人数は{this.state.numberLimitChat}
						人までです。
					</div>
				</Dialog>
			</div>
		)
	}
}

export default DialogWarningFullChat
