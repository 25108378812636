import {
	MISSION_GET,
	MISSION_GET_ARCHIVED,
	MISSION_START,
	MISSION_GET_POINT,
	MISSION_GET_START_DASH,
	MISSION_GET_DAILY,
	MISSION_GET_SPECIAL,
	MISSION_GET_ARCHIVED_V2,
	MISSION_GET_V2,
	MISSION_RESET
} from '../constants/ActionTypes'
import { CATEGORY_MISSTION, glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

export const getMissions = () => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.path_missions, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(MISSION_GET, response.data.data))
				} else {
					dispatch(resultSuccess(MISSION_GET, []))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET, error)
				throw error
			})
	}
}
export const getMissions_v2 = () => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.mission_v2, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(MISSION_GET_V2, response.data.data))
				} else {
					dispatch(resultSuccess(MISSION_GET_V2, []))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET_V2, error)
				throw error
			})
	}
}
export const getMissionsStartDash = (type, page) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.mission_v2 + '?category=' + type + '&page=' + page, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(MISSION_GET_START_DASH, response.data.data))
				} else {
					dispatch(resultSuccess(MISSION_GET_START_DASH, []))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET_START_DASH, error)
				throw error
			})
	}
}
export const getMissionsDaily = (type, page) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.mission_v2 + '?category=' + type + '&page=' + page, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(MISSION_GET_DAILY, response.data.data))
				} else {
					dispatch(resultSuccess(MISSION_GET_DAILY, []))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET_DAILY, error)
				throw error
			})
	}
}
export const getMissionsSpecial = (type, page) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.mission_v2 + '?category=' + type + '&page=' + page, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(MISSION_GET_SPECIAL, response.data.data))
				} else {
					dispatch(resultSuccess(MISSION_GET_SPECIAL, []))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET_SPECIAL, error)
				throw error
			})
	}
}

export const getMissionsAchivedV2 = (type, page) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.mission_v2 + '?category=' + type + '&page=' + page, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					console.log(page)
					dispatch(resultSuccess(MISSION_GET_ARCHIVED_V2, response.data.data))
				} else {
					dispatch(resultSuccess(MISSION_GET_ARCHIVED_V2, []))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET_ARCHIVED_V2, error)
				throw error
			})
	}
}

export const getArchivedMissions = () => {
	return (dispatch) => {
		return request
			.get(
				glasConfig.url_base +
				glasConfig.path_missions +
				'/archievedpoint',
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(MISSION_GET_ARCHIVED, response.data.data)
					)
				} else {
					dispatch(resultSuccess(MISSION_GET_ARCHIVED, []))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET_ARCHIVED, error)
				throw error
			})
	}
}

export const startMission = (id) => {
	return (dispatch) => {
		// dispatch(resultSuccess(MISSION_START, {id, completeUnit: 20}))
		return request
			.put(
				glasConfig.url_base + glasConfig.path_missions + '/' + id,
				{},
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(MISSION_START, {
							id,
							completeUnit: response.data.data.completeUnit,

						})
					)
				} else {
					dispatch(resultSuccess(MISSION_START, null))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_START, error)
				throw error
			})
	}
}

export const getPoint = (id, catelog, isHome) => {
	return (dispatch) => {
		// dispatch(resultSuccess(MISSION_GET_POINT, id))

		return request
			.put(
				glasConfig.url_base +
				glasConfig.path_missions +
				'/' +
				id +
				'/archievedmissionpoint',
				{},
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(MISSION_GET_POINT, { id, catelog, isHome }))
				} else {
					dispatch(resultSuccess(MISSION_GET_POINT, null))
				}
			})
			.catch((error) => {
				console.log('error on call ' + MISSION_GET_POINT, error)
				throw error
			})
	}
}

export const resetMission = (type) => {
	return (dispatch) => {
		let actionType = ""
		switch (type) {
			case CATEGORY_MISSTION.DAILY:
				actionType = MISSION_GET_DAILY
				break
			case CATEGORY_MISSTION.SPECIAL:
				actionType = MISSION_GET_SPECIAL
				break
			case CATEGORY_MISSTION.START_DASH:
				actionType = MISSION_GET_START_DASH
				break
			case CATEGORY_MISSTION.ACHIEVED:
				actionType = MISSION_GET_ARCHIVED_V2
				break
			default :
				break
		}
		dispatch(resultSuccess(MISSION_RESET, actionType))
	}
}

