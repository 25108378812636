import React, { Component } from 'react'
import { connect } from 'react-redux'

import Dialog from 'material-ui/Dialog'
import Checkbox from 'material-ui/Checkbox'
import moment from 'moment'
import * as Fetch from '../util/Fetch'

class DialogMessageOffline extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			checked: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ show: nextProps.show })
	}

	handleSubmit = async () => {
		this.closeDialog()
		if (this.state.checked) {
			let flagMessageOffline = await localStorage.getItem(
				'flagMessageOffline'
			)
			flagMessageOffline =
				!flagMessageOffline ||
				flagMessageOffline === null ||
				flagMessageOffline === 'null'
					? {}
					: JSON.parse(flagMessageOffline)
			flagMessageOffline[Fetch.tellerId()] = moment()
				.format('YYYYMMDD')
				.toString()
			localStorage.setItem(
				'flagMessageOffline',
				JSON.stringify(flagMessageOffline),
				() => {
					this.setState({ checked: false })
				}
			)
		}
		this.props.submitted()
	}

	closeDialog = () => {
		this.setState({ show: false })
		this.props.close()
	}

	handleClick = (e) => {
		this.setState({ checked: !this.state.checked })
	}

	render() {
		return (
			<Dialog
				titleStyle={{ color: '#11ad9d' }}
				modal={false}
				open={this.state.show}
				className="dialogMessageOffline"
			>
				<i
					className="material-icons closeMessageOffline"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<div className="dialogMessageOfflineTitle">
					このメッセージはオフライン有料メッセージで送信されます。
					<br></br> ユーザーが開封した時にポイントが入ります。
				</div>
				<button
					className="submitMessageOffline"
					onClick={this.handleSubmit}
				>
					OK
				</button>
				<div>
					<Checkbox
						label="今日はこのお知らせを表示しない "
						onCheck={(e) => this.handleClick(e)}
						checked={this.state.checked}
						className="checkboxMessage hidden-mobile"
						iconStyle={{ color: 'rgba(0, 0, 0, 0.25)' }}
						labelStyle={{ color: 'rgba(0, 0, 0, 0.64)' }}
						inputStyle={{
							color: 'rgba(0, 0, 0, 0.25)',
							fontWeight: '100',
						}}
					/>
				</div>
				<div className="hidden-pc">
					<Checkbox
						label="今日はこのお知らせを表示しない "
						onCheck={(e) => this.handleClick(e)}
						checked={this.state.checked}
						iconStyle={{ color: 'rgba(0, 0, 0, 0.25)' }}
						labelStyle={{
							color: 'rgba(0, 0, 0, 0.64)',
							fontSize: 14,
						}}
						inputStyle={{
							color: 'rgba(0, 0, 0, 0.25)',
							fontWeight: '100',
						}}
					/>
				</div>
			</Dialog>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(DialogMessageOffline)
