import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'

export default class DialogUnBlock extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			funcAccept: null,
		}
	}

	acceptReply = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		const { userData } = this.props

		return (
			<Dialog
				modal={false}
				open={this.state.show}
				className="dialogReplyConfirm dialogReplyConfirmBlock"
				style={{ alignItems: 'center' }}
			>
				<i
					class="material-icons tab__icons closeUnBlock"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<p className="txtBlock1">
					現在このユーザーをブロックしています
				</p>
				<svg
					className="avatarBlock"
					width="64px"
					height="64px"
					viewBox="0 0 64 64"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					xlink="http://www.w3.org/1999/xlink"
				>
					<title>thumb_noimage</title>
					<desc>Created with Sketch.</desc>
					<g
						id="Page-1"
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
					>
						<g
							id="04-01-チャット---ブロック中"
							transform="translate(-608.000000, -346.000000)"
						>
							<g
								id="Group-4"
								transform="translate(466.000000, 283.000000)"
							>
								<g id="Group-2">
									<g
										id="Group"
										transform="translate(40.000000, 37.000000)"
									>
										<g
											id="Images/Thumbnail/Noimage"
											transform="translate(102.000000, 26.000000)"
										>
											<circle
												id="Oval-4"
												fill="#E9E9E9"
												cx="32"
												cy="32"
												r="32"
											></circle>
											<path
												d="M32,32 C37.8666667,32 42.6666667,27.2 42.6666667,21.3333333 C42.6666667,15.4666667 37.8666667,10.6666667 32,10.6666667 C26.1333333,10.6666667 21.3333333,15.4666667 21.3333333,21.3333333 C21.3333333,27.2 26.1333333,32 32,32 L32,32 Z M32,37.3333333 C24.8,37.3333333 10.6666667,40.8 10.6666667,48 L10.6666667,53.3333333 L53.3333333,53.3333333 L53.3333333,48 C53.3333333,40.8 39.2,37.3333333 32,37.3333333 L32,37.3333333 Z"
												id="Shape"
												fillOpacity="0.121659873"
												fill="#000000"
											></path>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</svg>
				<p className="userNameBlock">{userData.userName}</p>
				<br />
				<p className="txtBlock">解除しますか？</p>
				<button className="submitUnBlock" onClick={this.acceptReply}>
					ブロックを解除する
				</button>
				<p className="txtBlockClose" onClick={this.closeDialog}>
					キャンセル
				</p>
			</Dialog>
		)
	}
}
