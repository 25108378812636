import React, { Component } from 'react'
import CheckBoxCM from './CheckBoxCM'
import $ from 'jquery'
import Popup from 'reactjs-popup'

const worriesData = [
	{ title: '相性', value: '1' },
	{ title: '結婚', value: '2' },
	{ title: '離婚', value: '3' },
	{ title: '夫婦仲', value: '4' },
	{ title: '復縁', value: '5' },
	{ title: '不倫', value: '6' },
	{ title: '縁結び', value: '7' },
	{ title: '縁切り', value: '8' },
	{ title: '遠距離恋愛', value: '9' },
	{ title: '同性愛', value: '10' },
	{ title: '三角関係', value: '11' },
	{ title: '金運', value: '12' },
	{ title: '仕事', value: '13' },
	{ title: '起業', value: '14' },
	{ title: '転職', value: '15' },
	{ title: '対人関係', value: '16' },
	{ title: '自分の気持ち', value: '17' },
	{ title: '相手の気持ち', value: '18' },
	{ title: '家庭問題', value: '19' },
	{ title: '運勢', value: '20' },
	{ title: '開運方法', value: '21' },
]

class PopupWorries extends Component {
	constructor(props) {
		super(props)
		this.state = {
			worries: [],
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (
			nextProps.defaultValue !== undefined &&
			nextProps.defaultValue !== this.state.worries
		) {
			this.setState({
				worries: nextProps.defaultValue,
			})
		}
		return true
	}

	componentDidMount() {}

	changeValue = (value) => {
		let listData = this.state.worries
		if (listData.indexOf(value) < 0) {
			listData.push(value)
		} else {
			listData.splice(listData.indexOf(value), 1)
		}
		this.setState({
			worries: listData,
		})
		this.props.onData(listData)
	}

	dropDownShow = (name, value) => {
		this.setState({
			[name]: !value,
		})
	}

	render() {
		let listUserData = this.state.worries
		return (
			<div className="form-inline-row-CM">
				<Popup
					className={'popup-list'}
					trigger={
						<span className="label-text-92797 point-click">
							{listUserData.length > 0 &&
							listUserData.length !== 21 ? (
								<span className={'text-color-dropdown-green'}>
									指定あり
								</span>
							) : (
								'すべて'
							)}
							<span className="caret" />
						</span>
					}
					modal={true}
					contentStyle={{ width: '357px', borderRadius: 5 }}
				>
					{(close) => (
						<div className={'box-row-select-popup'}>
							<div className={'row-back-popup'}>
								<div
									className={'arrow-back-popup'}
									onClick={() => close()}
								>
									<i className="fas fa-chevron-left" />
								</div>
								<div className={'box-title-popup'}>
									悩み選択
								</div>
							</div>
							<div className={'content-row-select'}>
								{worriesData.map((item, key) => {
									return (
										<div
											key={key}
											className={'row-list-select'}
											onClick={() =>
												this.changeValue(item.value)
											}
										>
											<div
												className={'title-list-select'}
											>
												{item.title}
											</div>
											<div className={'icon-tick-select'}>
												{listUserData.indexOf(
													item.value
												) < 0 ? (
													<i className="fas fa-check icon-color-select-tick" />
												) : (
													<i className="fas fa-check active icon-color-select-tick" />
												)}
											</div>
										</div>
									)
								})}
							</div>
						</div>
					)}
				</Popup>
			</div>
		)
	}
}

export default PopupWorries
