// テンプレート操作
import {
	TEMPLATE_POST,
	TEMPLATE_GET,
	TEMPLATE_PUT,
	TEMPLATE_DELETE,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

// テンプレート登録
export const postTemplate = (params) => {
	return (dispatch) => {
		dispatch(showLoading())
		return request
			.post(glasConfig.url_base + glasConfig.path_template, params, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					params.templateId = response.data.data.templateId
					dispatch(resultSuccess(TEMPLATE_POST, params))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(TEMPLATE_POST, {}))
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + TEMPLATE_POST, error)
				throw error
			})
	}
}

// テンプレート取得
export const getTemplate = () => {
	return (dispatch) => {
		dispatch(showLoading())
		return request
			.get(glasConfig.url_base + glasConfig.path_template, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					dispatch(resultSuccess(TEMPLATE_GET, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(TEMPLATE_GET, {}))
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + TEMPLATE_GET, error)
				throw error
			})
	}
}

// テンプレート更新
export const putTemplate = (params) => {
	return (dispatch) => {
		dispatch(showLoading())
		return request
			.put(glasConfig.url_base + glasConfig.path_template, params, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				dispatch(hideLoading())
				// dispatch(resultSuccess(TEMPLATE_PUT_LOADING,params))
				if (response.data.code === 0) {
					dispatch(resultSuccess(TEMPLATE_PUT, params))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(TEMPLATE_PUT, {}))
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + TEMPLATE_PUT, error)
				throw error
			})
	}
}

// テンプレート削除
export const deleteTemplate = (templateId) => {
	return (dispatch) => {
		dispatch(showLoading())
		return request
			.delete(
				glasConfig.url_base + glasConfig.path_template + templateId,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					dispatch(resultSuccess(TEMPLATE_DELETE, templateId))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(TEMPLATE_DELETE, {}))
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + TEMPLATE_DELETE, error)
				throw error
			})
	}
}
