import {
	ILocalVideoTrack,
	IRemoteVideoTrack,
	ILocalAudioTrack,
	IRemoteAudioTrack,
} from 'agora-rtc-sdk-ng'
import React, { useRef, useEffect } from 'react'

const MediaPlayer = (props) => {
	const container = useRef(null)
	//   useEffect(() => {
	//     if (!container.current) return;
	//     props.videoTrack?.play(container.current);
	//     return () => {
	//       props.videoTrack?.stop();
	//     };
	//   }, [container, props.videoTrack]);

	useEffect(() => {
		if (props.audioTrack) {
			console.log('%c props.audioTrack: ', 'color: red', props.audioTrack)
			props.audioTrack.play()
		}

		return () => {
			props.audioTrack && props.audioTrack.stop()
		}
	}, [props.audioTrack])

	return (
		<div
			ref={container}
			className="video-player"
			style={{ visibility: 'hidden' }}
		></div>
	)
}

export default MediaPlayer
