import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import Checkbox from 'material-ui/Checkbox'
import { isAndroid,isMobile } from 'react-device-detect'

export default class DialogFirstTimeChat extends Component {
	constructor(props) {
		super(props)
		this.state = {
			funcAccept: null,
			checked: false
		}
	}

	checkAcceptChat() {
		const { socket } = this.props
		socket.on('userRequest', (res) => {
			const message = JSON.parse(res)
			if (message.msgType === 'RESPONSE_REQUEST_CHAT') {
				this.closeDialog()
			}
		})
	}

	acceptReply = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.props.onClose()
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.props.firstTimeChatOnline}
				// onRequestClose={this.closeDialog}
				className="dialogBlock"
				bodyStyle={{padding:isMobile? 25:50, paddingTop: isMobile ? 30 :30,paddingBottom: 15}}
				contentStyle={{width: '90%',}}
			>
				<i
					className="material-icons tab__icons closeBlockFirstTime"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<p className="txtDialogFirstTime" style={{marginBottom:isMobile? 20 : 27}}>
				こちらのマークが付いているお客様は {!isMobile?'\n':''}<span>「Stellaでチャット鑑定を初めて行うユーザー」</span> です。
				</p>
				<div style={{display: "flex", justifyContent: 'center'}}>
					<img src="/img/icon_first_time.png" className="imgIconFirstTimePoup"/>
				</div>
				<p className="txtDialogFirstTime2">普段よりも丁寧な鑑定を心がけることで、リピーターになりやすくなります !</p>
				<p className="txtDialogFirstTime2">システムに慣れてないユーザー様には、無料メッセージで優しく教えてあげましょう。</p>
				
				<div className="buttonsReplyBlock2" style={{ justifyContent: 'center', display: "flex",marginTop: 24}}>
					<button
						className="submitReplyBlock"
						style={{width:isMobile? 171 : 207,height:36,marginRight:0}}
						onClick={this.closeDialog}
					>
						OK
					</button>
				</div>
			</Dialog>
		)
	}
}
