import React, { PureComponent } from 'react'
import FlatButton from 'material-ui/FlatButton'

class ThumbnailProfile extends PureComponent {
	static defaultProps = {
		hideAction: false,
	}

	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		return (
			<div style={{ ...styles.container, ...this.props.containerStyle }}>
				<h3 style={styles.title}>
					プロフィール写真を魅力的に 加工します
				</h3>
				<div style={styles.containerDes}>
					<p style={styles.des1}>
						下記条件のお写真をご登録ください。
					</p>
					<p style={styles.des2}>
						条件を満たしていない場合は再登録となります。
					</p>
				</div>
				<img src="/img/samplephoto_mb.png" />
				{!this.props.hideAction && (
					<div style={{ marginTop: 8 }}>
						<FlatButton
							onClick={this.props.onClick}
							fullWidth
							label="閉じる"
							labelStyle={{ color: 'rgba(0, 0, 0, 0.38)' }}
						/>
					</div>
				)}
			</div>
		)
	}
}

export default ThumbnailProfile

const styles = {
	containerDes: {
		margin: '15px 0px',
	},
	container: {
		padding: '10px 15px',
		backgroundColor: 'rgba(235, 250, 248, 1)',
		margin: '15px 5px 0px 5px',
	},
	des1: {
		fontFamily: "'HiraKakuPro-W3'",
		fontSize: '12px',
		color: 'rgba(0, 0, 0, 0.87)',
		textAlign: 'left',
		lineHeight: '15px',
	},
	des2: {
		fontFamily: "'HiraKakuPro-W3'",
		fontSize: '12px',
		color: 'rgba(236, 64, 122, 0.87)',
		textAlign: 'left',
		lineHeight: '15px',
	},
	title: {
		height: '36px',
		width: '234px',
		fontFamily: "'Hiragino Sans'",
		fontSize: '18px',
		color: 'rgba(17, 173, 157, 1)',
		textAlign: 'center',
		margin: 'auto',
		marginBottom: 15,
	},
}
