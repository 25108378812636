import styled from 'styled-components'
import { POINT_REPORT } from '../../../../constants/PointReportConstants'
import { colors, fonts, DEVICE } from '../../../../util/StellaConstant'

/*
    @media ${DEVICE.mobileS} {

    }
    @media ${DEVICE.laptop} {

    }
    @media ${DEVICE.laptopL} {

    }
*/

export const Container = styled.div`
	@media ${DEVICE.mobileS} {
		width: 100%;
		height: 400px;
		padding-top: 11px;
		padding-bottom: 20px;
	}
	@media ${DEVICE.laptop} {
		width: 100%;
		height: 700px;
		padding-top: 21px;
	}
	@media ${DEVICE.laptopL} {
		width: 100%;
		height: 700px;
		padding-top: 16px;
	}
`