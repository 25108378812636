/**
 * エンドポイントが/ums/v1/fortunetellers/のCRUDアクションを集めてます
 * 参照はMYINFO_PROFILEのみ！！
 */
import {
	AUTH_LOGOUT,
	MYINFO_AVATAR_POST,
	MYINFO_PROFILE,
	MYINFO_PROFILE_ADD,
	MYINFO_PROFILE_MERGE,
	MYINFO_PROFILE_PUT,
	MYINFO_PROFILE_PUTTING,
	CHANGE_LIMIT_USER_CHATTING,
	LIST_REASON_DENY_AVATAR,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'
import axios from 'axios'
import jwtdecode from 'jwt-decode'

import { hideLoading, showLoading } from 'react-redux-loading-bar'
const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//自分のプロフィール参照
export const fetch = () => {
	return (dispatch) => {
		// console.log('%c REQUEST TELLER PROFILE', 'color: red');

		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()
		dispatch(showLoading())
		return request
			.get(glasConfig.url_base + glasConfig.path_teller_info + tellerId, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					dispatch(resultSuccess(MYINFO_PROFILE, response.data.data))
				} else if (response.data.code === 4) {
					Fetch.setLocalStorage()
					dispatch(resultSuccess(AUTH_LOGOUT))
				} else if (response.data.code === 3) {
					Fetch.setLocalStorage()
					dispatch(resultSuccess(AUTH_LOGOUT))
				} else {
					Fetch.setLocalStorage()
					dispatch(resultSuccess(AUTH_LOGOUT))
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + MYINFO_PROFILE, error)
				throw error
			})
	}
}

export const changeLimitUserChatting = (limit) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()
		const options = {
			method: 'PUT',
			url: `${glasConfig.url_base}${glasConfig.path_teller_info}${tellerId}/${glasConfig.maxNumberChat}?maxNumber=${limit}`,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}
		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(
							CHANGE_LIMIT_USER_CHATTING,
							response.data.data
						)
					)
				}
			})
			.catch((error) => {
				throw error
			})
	}
}

export const refreshNewToken = () => {
	// console.log('refresh new token');
	const token = localStorage.getItem('token')
	const params = {
		method: 'GET',
		headers: {
			Authorization: token,
		},
		url: glasConfig.url_base + glasConfig.refresh_token,
	}
	// console.log('refreshing');
	request(params)
		.then((response) => {
			if (response.data.code === 0) {
				// console.log('success new token');
				const responseData = response.data.data
				const token = responseData.token
				localStorage.setItem('token', token)
				const decoded = jwtdecode(token)
				localStorage.setItem(
					'expiredRemainTime',
					decoded.expiredTime
				)
			}
		})
		.catch((error) => {
			console.log(error)
		})
}

//更新系
export const put = (item, myprofile) => {
	return (dispatch, getState) => {
		dispatch(showLoading())
		const ActionItem = item === 'profile' ? '' : item

		//APIにリクエストするjsonを成型
		const requestData = (item, value) => {
			switch (item) {
				case 'profile': {
					value.fortuneTellerStyles = [].concat(value.styles)
					return value
				}
				case 'mood': {
					return { mood: value.mood }
				}
				case 'status': {
					return {
						status: value.fortuneTellerStatus,
						maxNumberUserChatting: value.maxNumberUserChatting,
					}
				}
			}
		}

		const params = requestData(item, myprofile)

		if (!params) {
			throw 'システムエラー'
		}

		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()

		const options = {
			method: 'PUT',
			url:
				glasConfig.url_base +
				glasConfig.path_teller_info +
				tellerId +
				'/' +
				ActionItem,
			data: params,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}
		dispatch(resultSuccess(MYINFO_PROFILE_PUTTING, {}))
		return request(options)
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					let profile = getState().MyProfile.data
					let ret = Object.assign({}, profile)
					if (item !== 'status') {
						ret = response.data.data
							? Object.assign({}, profile, {
								fortuneMethods: myprofile.fortuneMethods,
								fortuneTellerName:
									myprofile.fortuneTellerName,
								styles: myprofile.styles,
								judgementGenres: myprofile.judgementGenres,
								message: myprofile.message,
								avatarPath: myprofile.avatarPath,
								avatarStatus:
									response.data.data.avatarStatus,
								appealComments: myprofile.appealComments,
								gender: myprofile.gender
							})
							: Object.assign({}, profile, {
								fortuneMethods: myprofile.fortuneMethods,
								fortuneTellerName:
									myprofile.fortuneTellerName,
								styles: myprofile.styles,
								judgementGenres: myprofile.judgementGenres,
								message: myprofile.message,
								avatarPath: myprofile.avatarPath,
								appealComments: myprofile.appealComments,
								gender: myprofile.gender
							})
					}

					dispatch(
						resultSuccess(MYINFO_PROFILE_PUT, {
							savedProfile: true,
							data: ret,
							savedCode: 0,
						})
					)
				} else {
					dispatch(
						resultSuccess(MYINFO_PROFILE_PUT, {
							savedProfile: false,
							savedCode: response.data.code,
						})
					)
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + MYINFO_PROFILE_PUT, error)
				throw error
			})
	}
}

/**
 * stateに保存されているデータを一部更新する
 * 通知から左カラムの情報を更新したい場合など。
 */
export const merge = (req) => {
	return (dispatch) => dispatch(resultSuccess(MYINFO_PROFILE_MERGE, req))
}

/**
 * stateに保存されているデータを一部加算する
 * 通知から数字を追加する場合に使用
 */
export const add = (req) => {
	return (dispatch) => dispatch(resultSuccess(MYINFO_PROFILE_ADD, req))
}

//プロフィール画像
export const avatar = (data) => {
	return (dispatch) => {
		dispatch(resultSuccess(MYINFO_AVATAR_POST, data))
	}
}

// プロフィール画像同時登録
export const profileImagePost = (myProfile, params) => {
	return (dispatch) => {
		const tellerId = Fetch.tellerId()
		const token = localStorage.getItem('token')
		dispatch(showLoading())
		Promise.resolve()
			.then(() => {
				dispatch(hideLoading())
				return new Promise((resolve, reject) => {
					request
						.post(
							glasConfig.url_base + glasConfig.path_file,
							params.fileData,
							{
								headers: {
									Authorization: token,
								},
							}
						)
						.then((response) => {
							if (response.data.code === 0) {
								resolve(response.data.data)
							} else {
								throw { response }
							}
						})
						.catch((error) => {
							console.log(
								'error on call ' + MYINFO_PROFILE_PUT,
								error
							)
							throw error
						})
				})
			})
			.then((fileData) => {
				return new Promise((resolve, reject) => {
					params.profileData.avatarPath = fileData.filePath
					let data = Object.assign({}, params.profileData)
					data.fortuneTellerStyles = [].concat(data.styles)

					request
						.put(
							glasConfig.url_base +
							glasConfig.path_teller_info +
							tellerId,
							data,
							{
								headers: {
									Authorization: token,
								},
							}
						)
						.then((response) => {
							if (response.data.code === 0) {
								params.profileData.avatarPath =
									fileData.fileHost + fileData.filePath
								const myProfileData = {
									myProfile,
									data: {
										...myProfile.data,
										avatarStatus:
											response.data.data.avatarStatus,
									},
								}
								// 現在のプロフィール情報に更新したプロフィール情報をマージする
								let data = Object.assign(
									{},
									myProfileData.data,
									params.profileData
								)
								dispatch(
									resultSuccess(MYINFO_PROFILE_PUT, {
										savedProfile: true,
										data: data,
										savedCode: 0,
									})
								)
							} else {
								throw { response }
							}
						})
						.catch((error) => {
							console.log(
								'error on call ' + MYINFO_PROFILE_PUT,
								error
							)
							throw error
						})
				})
			})
	}
}

export const putUpdaetHiddenNewSampleVoice = () => {
	const token = localStorage.getItem('token')
	return axios
		.put(
			glasConfig.url_base + glasConfig.update_hidden_sample_voice,
			{},
			{
				headers: {
					Authorization: token,
				},
			}
		)
		.then((response) => {
			return response !== undefined && response.data.code === 0
		})
		.catch((error) => {
			console.log('error on call ' + MYINFO_PROFILE_PUT, error)
			throw error
		})
}

export const uploadfileAudioPost = (myProfile, params, MyProfile) => {
	const token = localStorage.getItem('token')
	return axios
		.post(glasConfig.url_base + glasConfig.path_file, params, {
			headers: {
				Authorization: token,
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((response) => {
			if (response !== undefined && response.data !== undefined) {
				return response.data.data.fileHost + response.data.data.filePath
			} else {
				throw { response }
			}
		})
		.catch((error) => {
			console.log('error on call ' + MYINFO_PROFILE_PUT, error)
			throw error
		})
}

export const getListReasonDenyAvt = () => {
	const token = localStorage.getItem('token')
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.get_list_reason_deny_avatar, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(LIST_REASON_DENY_AVATAR, response.data.data)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + LIST_REASON_DENY_AVATAR, error)
				throw error
			})
	}
}
