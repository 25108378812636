import styled from 'styled-components'
import { colors, fonts, DEVICE } from '../../../util/StellaConstant'

export const ContainerContent = styled.div`
	@media ${DEVICE.mobileS} {
		padding: 25px 17px 20px 17px;
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
		padding: 62px 38px 35px 39px;
	}
`

export const StyledCloseIcon = styled.div`
	@media ${DEVICE.mobileS} {
		display: none;
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
		display: flex;
		position: absolute;
		top: 20px;
		right: 23px;
	}
`

export const TopContent = styled.div`
	@media ${DEVICE.mobileS} {
		margin-bottom: 21px;
		span {
			font-family: ${fonts.HiraginoKakuGothicPro};
			font-size: 15px;
			color: ${colors.accent};
			line-height: 20px;
			font-weight: 600;
		}
		p {
			font-family: ${fonts.HiraginoKakuGothicPro};
			font-size: 15px;
			color: ${colors.black};
			line-height: 20px;
			font-weight: 500;
		}
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
		margin-bottom: 20px;
		span {
			font-family: ${fonts.HiraKakuProW6};
			font-size: 20px;
			color: ${colors.accent};
			line-height: 26px;
			font-weight: 600;
		}
		p {
			font-family: ${fonts.HiraKakuProW3};
			font-size: 20px;
			color: ${colors.black};
			line-height: 26px;
			font-weight: 500;
		}
	}
`

export const ImageTutorial = styled.img`
	@media ${DEVICE.mobileS} {
		width: 205px;
		height: 71px;
		display: flex;
		margin: 0 auto 19px;
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
		width: 269px;
		height: 97px;
		display: flex;
		margin: 0 auto 20px;
	}
`

export const BottomContent = styled.div`
	@media ${DEVICE.mobileS} {
		p {
			font-family: ${fonts.HiraginoKakuGothicPro};
			color: ${colors.darkJungleGreen};
			font-size: 12px;
			line-height: 18px;
			font-weight: 500;
		}
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
		p {
			font-family: ${fonts.HiraKakuProW3};
			color: ${colors.darkJungleGreen};
			font-size: 15px;
			line-height: 22px;
			font-weight: 500;
		}
	}
`

export const CenterBlock = styled.div`
	display: flex;
	justify-content: center;
`
