import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Category } from '../constants/ConfigStaffblog'
import StaffBlog from './StaffBlog'
import request from 'axios'
import { glasConfig } from '../constants/Config'
import { connect } from 'react-redux'
import * as CategoryBlogActions from './../actions/CategoryBlogAction'

class StaffBlogHome extends Component {
	constructor(props) {
		super(props)
		this.state = {
			page: 1,
			size: 12,
			category: 0,
			listCategory: []
		}
	}
	componentDidMount() {
		const { dispatch } = this.props
		dispatch(CategoryBlogActions.getCategoryBlog())
	}
	componentWillMount() {
		const { match, } = this.props
		this.getData(match)

	}

	componentWillReceiveProps(nextProps) {
		const { match } = nextProps
		this.getData(match)
	}

	/**
	 * 記事詳細を取得
	 */
	getData(match) {
		const params = {
			page: match.params.page === undefined ? 1 : match.params.page,
			size: match.params.size === undefined ? 10 : match.params.size,
			category:
				match.params.category === undefined
					? ''
					: match.params.category,
		}
		this.setState(params)
	}




	render() {
		const { CategoryBlog } = this.props
		const baseurl = '/home/staffblog/1'
		return (
			<div className="content">
				<h1 className="content__title">運営ブログ</h1>
				<nav className="lead-nav lead-nav-blog">
					<ul className="list lead_nav">
						{
							CategoryBlog && CategoryBlog.data.map((item, index) => {
								const className =
									item.category_id == this.state.category
										? 'btn-tab is-active'
										: 'btn-tab'
								return (
									<li key={item.term_id} className="list__item">
										<Link
											className={className}
											to={item.category_id == 0 ? baseurl : `${baseurl}/${item.category_id}`} /// do ben webview bao khong can lay dong, mac ke case bi sai
										>
											{item.category_name}
										</Link>
									</li>
								)
							})
						}
					</ul>
				</nav>
				<StaffBlog
					category={this.state.category}
					page={this.state.page}
					size={this.state.size}
					pager={true}
				/>
			</div>
		)
	}
}
const mapStateToProps = (store) => {
	return {
		CategoryBlog: store.CategoryBlog,
	}
}
export default connect(mapStateToProps)(StaffBlogHome)
