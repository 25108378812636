import {
	DO_SUSPEND,
	DO_SHOW_DIALOG_CHAT,
	DO_REMOVE_REQUEST,
} from '../constants/ActionTypes'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

export const doSuspend = (data) => {
	return (dispatch) => {
		dispatch(resultSuccess(DO_SUSPEND, data))
	}
}

export const doHideBell = () => {
	return (dispatch) => {
		dispatch(resultSuccess(DO_SHOW_DIALOG_CHAT))
	}
}

export const doRemoveRequest = (requestId) => {
	return (dispatch) => {
		dispatch(resultSuccess(DO_REMOVE_REQUEST, requestId))
	}
}
