import React, { Component } from 'react'
import ReactLoading from 'react-loading'

class Loading extends Component {
	render() {
		return this.props.display ? (
			<div style={styles.container}>
				<ReactLoading
					className="loading"
					type="spin"
					color="rgb(17,173,157)"
					height="64px"
					width="64px"
					delay="500msecs"
				/>
			</div>
		) : null
	}
}

export default Loading

const styles = {
	container: {
		height: window.innerHeight,
		width: window.innerWidth,
		position: 'fixed',
		background: 'rgba(158,158,158, 0.20)',
		top: 0,
		right: 0,
		offset: '111111',
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		zIndex: 99999,
	},
}
