import React, { Component } from 'react'

class InputUserName extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userName: '',
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.defaultValue !== this.state.userName) {
			this.setState({
				userName: nextProps.defaultValue,
			})
		}
		return true
	}

	componentWillUpdate(prevProps, prevState, snapshot) {
		if (prevProps.defaultValue !== this.state.userName) {
			this.setState({
				userName: prevProps.defaultValue,
			})
		}
	}

	changeValue = (event) => {
		let value = event.target.value
		this.setState({
			userName: value,
		})
		this.props.onData(this.state.userName)
	}

	sendDataProps = () => {
		this.setState({
			userName: this.state.userName.trim(),
		})
		this.props.onData(this.state.userName.trim())
	}

	render() {
		return (
			<div className={'form-inline-row-CM'}>
				{this.props.responsiveMobile !== undefined &&
				this.props.responsiveMobile ? (
					<label className="label-text-92797">
						{this.props.labelText}
					</label>
				) : null}
				<div>
					<input
						type="text"
						maxLength={10}
						defaultValue={this.state.userName}
						onBlur={() => this.sendDataProps()}
						value={this.state.userName}
						onChange={(event) => this.changeValue(event)}
						className={'input-line-botoom-92797'}
						placeholder={this.props.dataPlaceholder}
					/>
				</div>
			</div>
		)
	}
}

export default InputUserName
