import React, { Component } from 'react'
import RewardMenu from '../RewardMenu'
import './RewardExplanation.scss'
import { RewardExplanationAPI } from '../../service/api/ApiService'

class RewardExplanation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
		}
	}

	componentDidMount = async () => {
		let dataNew = null
		await RewardExplanationAPI().then(function (res) {
			if (res.code === 0) {
				dataNew = res.data
			}
		})

		this.setState({
			data: dataNew,
		})
	}

	render() {
		return (
			<div className="content reward reward-history">
				<h1 className="content__title">精算</h1>
				<RewardMenu />
				<div className={'box-reward-content-reward-explanation'}>
					<div className={'title-top'}>報酬説明</div>
					<div className={'description-title-header-top'}>
						ステラで稼働した際に得られる報酬は、ご自身のステージにより変わります。
					</div>
					<div className={'box-content-reward-2'}>
						<div className={'header-title-reward-2'}>
							現在のあなたのステージ
						</div>
						<div className={'value-title-reward-2'}>
							{this.state.data ? this.state.data.stageName : null}
						</div>
					</div>
					<div className={'box-content-reward-3'}>
						<div className={'title-reward-3'}>報酬内訳</div>
						<table className={'table-reward-3'}>
							<tr>
								<td>チャット鑑定</td>
								<td>
									{this.state.data
										? this.state.data.chat
										: null}
									pt/文字（送信、受信とも）
								</td>
							</tr>
							<tr>
								<td>チャット内の画像送信</td>
								<td>
									{this.state.data
										? this.state.data.sendFile
										: null}
									pt/枚（送信、受信とも）
								</td>
							</tr>
							<tr>
								<td>電話鑑定</td>
								<td>
									{this.state.data
										? this.state.data.call
										: null}
									pt/分（非課金ユーザーの場合
									{this.state.data
										? this.state.data.callNonCharge
										: null}
									pt/分）
								</td>
							</tr>
							<tr>
								<td>タイムライン</td>
								<td>
									{this.state.data
										? this.state.data.timeLine
										: null}
									pt/文字（お客様からのコメント）
								</td>
							</tr>
							<tr>
								<td>教えて！先生</td>
								<td>
									ベストアンサーごとに
									{this.state.data
										? this.state.data.bestAnswer
										: null}
									pt/件
								</td>
							</tr>
						</table>
					</div>
					<div className={'box-content-reward-5'}>
						※ステージについて詳しくは
						<a
							href={
								'https://fortune.stella-app.com/home/staffblog/detail/894'
							}
							target={'_blank'}
						>
							こちら
						</a>
						をご覧ください
					</div>
				</div>
			</div>
		)
	}
}

export default RewardExplanation
