import React, { Component } from 'react'
import { PrivateRoute } from './PrivateRoute'
import Setting from './Setting'
import SettingTemplates from './SettingTemplates'
import SettingAccount from './SettingAccount'
import SettingAgeAuth from './SettingAgeAuth'
import SettingPassword from './SettingPassword'
import RouteSettingBank from './RouteSettingBank'
import SettingContract from './SettingContract'

class RouteSetting extends Component {
	componentWillMount() {
		this.props.activeItem(5)
	}

	render() {
		return (
			<span>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}`}
					component={Setting}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/template`}
					component={SettingTemplates}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/account`}
					component={SettingAccount}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/ageauth`}
					component={SettingAgeAuth}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/password`}
					component={SettingPassword}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/bank`}
					component={RouteSettingBank}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/contract`}
					component={SettingContract}
				/>
			</span>
		)
	}
}

export default RouteSetting
