import styled from 'styled-components';
import { colors, fonts, DEVICE } from '../../../../util/StellaConstant';
 
const SHADOW_BUTTON = '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)';
 
const STYLES_FOR_BUTTON = {
	ACCEPT: {
		COLOR: colors.white,
		BACKGROUND: colors.mountainMeadow,
		SHADOW: SHADOW_BUTTON
	},
	CANCEL: {
		COLOR: colors.grey,
		BACKGROUND: 'transparent',
		SHADOW: 'none'
	}
}
 
export const Content = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 18px 0px 18px 0px;
   @media ${DEVICE.mobileS} {
     padding-right: 16px;
     padding-left: 16px;
   }
   @media ${DEVICE.tablet} {
     width: 90%;
   }
   @media ${DEVICE.laptop} {
     width: 85%;
   }
 `;
 
export const Title = styled.p`
   font-weight: bold;
   @media ${DEVICE.mobileS} {
     color: ${colors.mediumBlue};
     font-family: ${fonts.HiraKakuProW6};
     font-size: 15px;
     height: 15px;
   }
   @media ${DEVICE.tablet} {
     color: ${colors.mediumBlue};
     font-family: ${fonts.HiraKakuProW6};
     font-size: 20px;
     height: 20px;
     padding: 0px 6px 0px 6px;
   }
   @media ${DEVICE.laptop} {
     color: ${colors.mediumBlue};
     font-family: ${fonts.HiraKakuProW6};
     font-size: 18px;
     padding: 0px 0px 0px 0px;
   }
 `;
 
export const TextTransfer = styled.span`
   text-align: center;
   font-family: ${fonts.HiraKakuProW6};
   color: ${colors.pearlAqua};
   margin-bottom: 24px;
   font-weight: bold;
   @media ${DEVICE.mobileS} {
     font-size: 30px;
     height: 30px;
   }
   @media ${DEVICE.tablet} {
     font-size: 36px;
     height: 36px;
   }
   @media ${DEVICE.laptop} {
     font-size: 30px;
     height: 30px;
   }
 `;
 
export const TextDescription = styled.p`
   font-family: ${fonts.HiraginoKakuGothicPro};
   align-items: center;
   border-top: 1px solid ${colors.pastelGray};
   padding-top: 20px;
   
   @media ${DEVICE.mobileS} {
     font-size: 14px;
     text-align-last: center;
     line-height: 19px
   }
   @media ${DEVICE.tablet} {
     font-size: 20px;
     display: block;
     line-height: 28px;
   }
   @media ${DEVICE.laptop} {
     font-size: 15px;
     line-height: 24px;
   }
 `;
 
export const Group = styled.div`
   display: flex;
   flex-direction: column;
   @media ${DEVICE.mobileS} {
     width: 45%;
   }
   @media ${DEVICE.tablet} {
     width: 55%;
   }
   @media ${DEVICE.laptop} {
     width: 50%;
   }
 `;
 
export const StyledButton = styled.button`
     color: ${props => STYLES_FOR_BUTTON[props.buttonType].COLOR};
     background-color: ${props => STYLES_FOR_BUTTON[props.buttonType].BACKGROUND};
     box-shadow: ${props => STYLES_FOR_BUTTON[props.buttonType].SHADOW};
     pointer-events: ${props => props.isEnable ? 'auto' : 'none'};
     font-family: ${fonts.HiraginoKakuGothicPro};
     border: none;
     text-align: center;
     @media ${DEVICE.mobileS} {
       padding: 8px 0px 8px 0px;
       margin: 14px 4px 8px 4px;
       font-size: 15px;
     }
     @media ${DEVICE.tablet} {
	       font-size: 18px;
       margin: 14px 4px 8px 4px;
       padding: 10px 10px 10px 8px;
     }
     @media ${DEVICE.laptop} {
       font-size: 15px;
       margin: 14px 16px 8px 16px;
       padding: 8px 0px 8px 0px;
     }
 `;