import { CHAT_NUMBER_TOTAL } from '../constants/ActionTypes'

const initialState = {
	loaded: false,
	data: {
		chattingUnreadNumber: 0,
		chatedUnreadNumber: 0,
	},
}

export const ChatNumberTotal = (state = initialState, action) => {
	switch (action.type) {
		case CHAT_NUMBER_TOTAL:
			if(action.data.chattingUnreadNumber<0){
				action.data.chattingUnreadNumber=0
			}
			if(action.data.chatedUnreadNumber<0){
				action.data.chatedUnreadNumber=0
			}
			return { ...state, action: action.type, data: action.data }
		default:
			return state
	}
}
