import React, { Component } from 'react'

class SelectBoxList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selected: 0,
			listData: [],
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.defaultValue !== this.state.selected) {
			this.setState({
				selected: prevProps.defaultValue,
			})
		}
		if (prevProps.listData !== this.state.listData) {
			this.setState({
				listData: prevProps.listData,
			})
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.defaultValue !== this.state.selected) {
			this.setState({
				selected: nextProps.defaultValue,
			})
		}
		return true
	}

	changeValue = (event) => {
		let value = event.target.value
		this.setState({
			selected: value,
		})
		this.props.onData(value)
	}

	render() {
		return (
			<div className="row-box-inline-CM">
				<label className="label-text-92797">
					{this.props.labelsText !== undefined
						? this.props.labelsText
						: ''}
				</label>
				<select
					className={'form-select-sort-box-mobile'}
					onChange={(event) => this.changeValue(event)}
					value={this.state.selected}
				>
					{this.state.listData.length > 0 ? (
						this.state.listData.map((item, key) => {
							return (
								<option
									selected={
										item.value === this.props.selected
									}
									key={key}
									value={item.value}
								>
									{item.title}
								</option>
							)
						})
					) : (
						<option value={''}>すべて</option>
					)}
				</select>
			</div>
		)
	}
}

export default SelectBoxList
