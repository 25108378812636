import React, { useState } from 'react'
import { POINT_REPORT } from '../../../../constants/PointReportConstants'
import { ButtonSetTargetPoint } from '../../StyledPointReport'
import { CalendarBlock, LastDayActualPoint, Row, TargetPoint, ActualPoint, Title, TotalActualPoint, PointBonus } from '../../StyledPointReport/Calendar/StyledPointCalendar'
import Statistic from '../Common/Statistic'
import PointCalendar from '../Main/PointCalendar'
import '../../StyledPointReport/Calendar/PointCalendar.scss'

const ReportCalendar = props => {
	const { openSetTargetPoint, targetPoint, PointReportData } = props

	const commonData = PointReportData.commonData.value

	return (
		<CalendarBlock id='point-calendar'>
			<TargetPoint>
				<Title type={POINT_REPORT.TYPES.TARGET_POINT}>
					{POINT_REPORT.TITLES.TARGET_POINT.label}
				</Title>
				<Row align='flex-end'>
					<Statistic type={POINT_REPORT.TYPES.TARGET_POINT} data={targetPoint} />
					<ButtonSetTargetPoint
						onClick={openSetTargetPoint}
					>
						目標設定
					</ButtonSetTargetPoint>
				</Row>
			</TargetPoint>
			<ActualPoint>
				<Title type={POINT_REPORT.TYPES.ACTUAL_POINT}>
					{POINT_REPORT.TITLES.ACTUAL_POINT.label}
				</Title>
				<Row align='space-between'>
					<TotalActualPoint>合計</TotalActualPoint>
					<Statistic type={POINT_REPORT.TYPES.ACTUAL_POINT} data={commonData.pointEarnedThisMonth} />
					<LastDayActualPoint>&nbsp;&nbsp;&nbsp;&nbsp;／前日&nbsp;&nbsp;</LastDayActualPoint>
					<Statistic type={POINT_REPORT.TYPES.ACTUAL_POINT_ADDITION} data={commonData.pointEarnedLastDay} />
				</Row>
			</ActualPoint>
			<PointBonus>
				<Title type={POINT_REPORT.TYPES.POINT_BONUS}>
					{POINT_REPORT.TITLES.POINT_BONUS.label}
				</Title>
				<Row align='right'>
					<Statistic type={POINT_REPORT.TYPES.POINT_BONUS} data={commonData.freePointThisMonth} />
				</Row>
			</PointBonus>
			<PointCalendar />
		</CalendarBlock>
	)
}

export default ReportCalendar