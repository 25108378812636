import React, { Component } from 'react'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import * as moment from 'moment'
import * as Fetch from '../util/Fetch'
import RewardMenu from './RewardMenu'

export default class RewardHistory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
		}
	}

	componentWillMount() {
		this.getHistory()
	}

	/**
	 * 精算履歴を取得
	 */
	getHistory() {
		const url = glasConfig.url_outside + glasConfig.path_payment_history
		const params = {
			offset: 0,
			limit: 5000,
		}
		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({
						data: Object.keys(response.data.data.data).length
							? response.data.data.data
							: {},
					})
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	render() {
		return (
			<div className="content reward reward-history">
				<h1 className="content__title">精算</h1>
				<RewardMenu />
				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main">精算履歴</h1>
					</div>
					<div className="primary__inner">
						<div className="primary-title lv_2">
							<h1 className="primary-title__main">{}</h1>
						</div>
						<div className="frame-table hidden-mobile">
							<table className="table-th-head">
								<thead>
									<tr>
										<th className="table-align-center">
											精算対象期間
										</th>
										<th className="table-align-center">
											振込額
										</th>
										<th className="table-align-center">
											入金日
										</th>
										<th className="table-align-center">
											振込先
										</th>
									</tr>
								</thead>
								<tbody>
									{Object.values(this.state.data).map(
										(val, i) => {
											const lastymd =
												this.state.data[i + 1] ===
												undefined
													? ''
													: this.state.data[i + 1].ymd
											return (
												<tr key={val.pay_history_id}>
													<td className="table-align-center">
														{lastymd} 〜 {val.ymd}
													</td>
													<td className="table-align-center">
														{Fetch.formatNumber(
															val.money
														)}
														円
													</td>
													<td className="table-align-center">
														{val.payymd}
													</td>
													<td className="table-align-center">
														{val.bank.bank_name}{' '}
														{val.bank.siten_name}
														支店 {val.bank.kouzanum}{' '}
														{val.bank.kouzaname}
													</td>
												</tr>
											)
										}
									)}
								</tbody>
							</table>
						</div>
						<div className="display-mobile">
							<ul className="history-list">
								{Object.values(this.state.data).map(
									(val, i) => {
										const lastymd =
											this.state.data[i + 1] === undefined
												? ''
												: this.state.data[i + 1].ymd
										return (
											<li
												className="history-item"
												key={val.pay_history_id}
											>
												<div className="history-item__header">
													<div className="hih__left">
														入金日：{val.payymd}
													</div>
													<div className="hih__right">
														{Fetch.formatNumber(
															val.money
														)}
														円
													</div>
												</div>
												<div className="history-item__body">
													<div className="hib__date">
														精算対象期間：{lastymd}{' '}
														〜 {val.ymd}
													</div>
													<div className="hib__man">
														振込先：
														{val.bank.bank_name}{' '}
														{val.bank.siten_name}
														支店 {val.bank.kouzanum}{' '}
														{val.bank.kouzaname}
													</div>
												</div>
											</li>
										)
									}
								)}
							</ul>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
