import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import { connect } from 'react-redux'
import { fortuneTellerStatus } from '../constants/Config'
import * as ActionTypes from '../constants/ActionTypes'
import User from '../models/User'

const DIALOG_TIME_OUT = 60 // seconds
const TIMER_INTERVAL_THRESHOLD = 1000 // miliseconds

class DialogInactivity extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			countdownValue: DIALOG_TIME_OUT,
		}
		this.timer = null
		this.startTimer = this.startTimer.bind(this)
		this.countDown = this.countDown.bind(this)
		this.idleFlag = false
	}

	componentDidMount() {
		this.props.onRef(this)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.IdleManagement !== this.props.IdleManagement) {
			const { IdleManagement } = this.props
			if (
				IdleManagement.action ===
				ActionTypes.USER_IDLE_ACTION_CONTINUE_WAITING_SYNC
			) {
				console.log('USER_IDLE_ACTION_CONTINUE_WAITING_SYNC')
				if (!this.idleFlag) {
					this.clearTimer()
					this.handleCloseWhenYes()
				} else {
					this.idleFlag = false
				}
			}
			if (
				IdleManagement.action ===
				ActionTypes.USER_IDLE_ACTION_FINISH_WAITING_SYNC
			) {
				console.log('USER_IDLE_ACTION_FINISH_WAITING_SYNC')
				if (!this.idleFlag) {
					this.clearTimer()
					this.handleCloseWhenNo()
				} else {
					this.idleFlag = false
				}
			}
		}

		// Turn off popup when has force change offline status from other device
		if (prevProps.MyProfile !== this.props.MyProfile) {
			// console.log('MyProfile: Turn off popup when has force change offline status from other device');
			const { data } = this.props.MyProfile
			if (this.shouldClearTimer(data.fortuneTellerStatus)) {
				this.clearTimer()
				this.handleCloseWhenNo()
			}
		}
	}

	componentWillUnmount() {
		this.props.onRef(undefined)
	}

	openDialog = () => {
		if (this.timer || this.state.show) return null

		return this.setState(
			{ show: true, countdownValue: DIALOG_TIME_OUT },
			() => {
				this.timer = null
				this.startTimer()
			}
		)
	}

	shouldClearTimer(tellerStatus) {
		return (
			tellerStatus === fortuneTellerStatus.chatting ||
			tellerStatus === fortuneTellerStatus.chattingFull ||
			tellerStatus === fortuneTellerStatus.calling ||
			tellerStatus === fortuneTellerStatus.offline
		)
	}

	continueWaitingStatus = () => {
		// Yes option
		this.idleFlag = true

		const tellerId = this.props.MyProfile.data.fortuneTellerId
		const socket = User.getSocket()
		const message = {
			fromId: tellerId,
			msgType: 'NO_ACTION_STAY_WAITTING_CONFIRM',
		}
		console.log('-----tellerNoAction::NO_ACTION_STAY_WAITTING_CONFIRM-----')
		socket.emit('tellerNoAction', JSON.stringify(message))

		this.clearTimer()
		this.handleCloseWhenYes()
	}

	finishWaitingStatus = () => {
		// No option
		this.idleFlag = true

		this.offLine()
	}

	offLine = () => {
		const tellerId = this.props.MyProfile.data.fortuneTellerId
		const socket = User.getSocket()
		const message = {
			fromId: tellerId,
			msgType: 'NO_ACTION_OFFLINE_CONFIRM',
		}
		console.log('-----tellerNoAction::NO_ACTION_OFFLINE_CONFIRM-----')
		socket.emit('tellerNoAction', JSON.stringify(message))

		this.clearTimer()
		this.handleCloseWhenNo()
	}

	clearTimer() {
		if (this.timer) {
			clearInterval(this.timer)
			this.timer = null
		}
	}

	handleCloseWhenYes() {
		this.setState({ show: false }, () => this.props.onRestartIdle())
	}

	handleCloseWhenNo() {
		this.setState({ show: false }, () => this.props.onPauseIdle())
	}

	startTimer() {
		if (!this.timer && this.state.countdownValue > 0) {
			this.timer = setInterval(this.countDown, TIMER_INTERVAL_THRESHOLD)
		}
	}

	countDown() {
		const seconds = this.state.countdownValue - 1
		this.setState({ countdownValue: seconds })

		// countdownValue come to zero
		if (seconds === 0) {
			this.offLine()
		}
	}

	render() {
		return (
			<div>
				<Dialog
					modal={false}
					open={this.state.show}
					className="dialogBlock"
				>
					<p className="txtDialogBlock1 helpMarginTop">
						待機を続けますか？
					</p>
					<p className="textIdleTimer">
						待機終了まで残り：{this.state.countdownValue}秒
					</p>
					<div className="buttonsReplyBlock">
						<button
							className="submitReplyBlock"
							onClick={this.continueWaitingStatus}
						>
							はい
						</button>
						<button
							className="cancelReplyBlock"
							onClick={this.finishWaitingStatus}
						>
							いいえ
						</button>
					</div>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		UserProfile: state.MyProfile.data,
		MyProfile: state.MyProfile,
		IdleManagement: state.IdleManagement,
	}
}

export default connect(mapStateToProps)(DialogInactivity)
