/**
 * 左メニューの真ん中。お客様リスト（チャット・通話の履歴を表示）
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { TEXT_CHAT_DRAFT, glasConfig } from '../constants/Config'
import * as RequestHistoryActions from '../actions/RequestHistoryActions'
import * as ConfigMessage from '../constants/ConfigMessage'
import * as Fetch from '../util/Fetch'
import { Link } from 'react-router-dom'
import moment from 'moment'
import '../assets/scss/leftHistory.scss'
import {
	CHAT_NUMBER_TOTAL,
	REQUEST_HISTORY_READ_REFRESH,
	REQUEST_HISTORY_UNREAD_REFRESH,
	CHAT_DRAFT,
} from '../constants/ActionTypes'
import * as ChatNumberTotalAction from '../actions/ChatNumberTotalAction'
import * as ChatRequestActions from '../actions/ChatRequestActions'
import image_pin from '../assets/pin_message.png'
import image_un_pin from '../assets/unpin_message.png'
import MessageHistory from './LeftMenuHistory/MessageHistory'
import { isPWA } from '../helper/helpFunction'
import { getUserId } from '../assets/help/help'
require('moment/locale/ja')

class LeftHistory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			page: 0,
			data: [],
			endHistory: false,
			endLoadMore: 0,
			errorPin: false
		}
	}

	componentDidMount() {
		const { dispatch } = this.props


		Promise.all([
			dispatch(
				RequestHistoryActions.requestListPin()
			),

			dispatch(
				RequestHistoryActions.requestHistoryUnRead({
					page: 0,
					size: 24,
					inConversation: false,
				})
			),
			dispatch(
				RequestHistoryActions.requestHistoryRead({
					page: 0,
					size: glasConfig.leftmenu_per_page_history,
					inConversation: false,
				})
			),
			dispatch(ChatNumberTotalAction.fetch())])

		if (localStorage.hasOwnProperty("draft-chat")) {
			let logChatDraft = JSON.parse(localStorage.getItem("draft-chat"))
			dispatch(ChatRequestActions.addChatDraft(logChatDraft))
		}

	}

	componentWillUnmount() {
		const { dispatch } = this.props
		dispatch(RequestHistoryActions.resetRequestHistoryPage())
	}

	loadData() {
		const { dispatch, RequestHistoryRead } = this.props
		this.setState({
			endLoadMore: Object.keys(RequestHistoryRead.data).length,
		})
		this.setState({ page: this.state.page + 1 }, () => {
			dispatch(
				RequestHistoryActions.requestHistoryRead({
					page: this.state.page,

					size: glasConfig.leftmenu_per_page_history,
					inConversation: false,
				})
			)
			dispatch(
				RequestHistoryActions.requestHistoryUnRead({
					page: this.state.page,
					size: 24,
					inConversation: false,
					isLoadmore: true
				})
			)
		})
	}

	resultSuccess = (type, data) => {
		return {
			type: type,
			data,
		}
	}

	doReadMessages(userId, fromtId) {
		const {
			dispatch,
			RequestHistoryUnRead,
			RequestHistoryRead,
			ChatNumberTotal,
			ChatPin
		} = this.props
		const id = Fetch.tellerId() === userId ? fromtId : userId
		let objectPin = ChatPin.data.find(i => getUserId(i) === id)
		let objectRemove = RequestHistoryUnRead.data[fromtId]
		delete RequestHistoryUnRead.data[fromtId]
		let listUnRead = RequestHistoryUnRead.data
		let chattingUnreadNumber = ChatNumberTotal.data.chattingUnreadNumber
		let chatedUnreadNumber = ChatNumberTotal.data.chatedUnreadNumber
		if (objectPin !== undefined) {
			let unreadMsgBadgeOld = objectPin.unreadMsgBadge

			dispatch({
				type: CHAT_NUMBER_TOTAL,
				data: {
					chattingUnreadNumber: chattingUnreadNumber,
					chatedUnreadNumber: chatedUnreadNumber - unreadMsgBadgeOld,
				},
			})
			objectPin.unreadMsgBadge = 0
			dispatch(RequestHistoryActions.refreshItemPin({ message: objectPin }))
		}
		if (objectRemove !== undefined) {
			let unreadMsgBadgeOld = objectRemove.unreadMsgBadge

			dispatch({
				type: CHAT_NUMBER_TOTAL,
				data: {
					chattingUnreadNumber: chattingUnreadNumber,
					chatedUnreadNumber: chatedUnreadNumber - unreadMsgBadgeOld,
				},
			})
			objectRemove.unreadMsgBadge = 0
			let listRead = Object.assign(
				{ [objectRemove.fromId]: objectRemove },
				RequestHistoryRead.data
			)
			dispatch(
				this.resultSuccess(REQUEST_HISTORY_UNREAD_REFRESH, listUnRead)
			)
			dispatch(this.resultSuccess(REQUEST_HISTORY_READ_REFRESH, listRead))
		}
	}

	handleFilterDataDraft(data, ChatDraft) {
		let dataChat = data.map((res, i) => {
			let chatDraftUser = ChatDraft.data.find((item) => {
				if (res.fromId == item.fromId || res.toId == item.fromId) {
					return item
				}
			})

			res.chatDraft = ""
			res.serverTimeChatDraft = res.serverTime
			if (chatDraftUser && (chatDraftUser.serverTime >= res.serverTime)) {
				res.chatDraft = chatDraftUser.value
				if (chatDraftUser.value && chatDraftUser.serverTime >= res.serverTime)
					res.serverTimeChatDraft = chatDraftUser.serverTime
			}

			return res
		})

		return dataChat
	}

	renderRequestHistoryChat(messagePin, messageUnread, messageRead, ChatDraft) {
		const dataChat = this.handleFilterDataDraft(messageRead, ChatDraft)
		const pinDraft = this.handleFilterDataDraft(messagePin.filter(i => i.unreadMsgBadge === 0), ChatDraft)
		const pinUnread = messagePin.filter(i => i.unreadMsgBadge > 0)

		let dataChatDraft = dataChat.sort((a, b) => b.serverTimeChatDraft - a.serverTimeChatDraft)
		let dataPinDraft = pinDraft.sort((a, b) => b.serverTimeChatDraft - a.serverTimeChatDraft)
		let sortPinUnread = pinUnread.sort((a, b) => b.serverTime - a.serverTime)

		return [...sortPinUnread, ...dataPinDraft, ...messageUnread, ...dataChatDraft]
	}


	handlePinMessage(friendID, isPin, message, callbackErr) {
		const { dispatch } = this.props
		if (!isPin) {
			dispatch(
				RequestHistoryActions.pinChat({ friendID: friendID, message: { ...message, isPin: true } }, (errorCode) => {
					callbackErr(errorCode)
				})
			)
		} else {
			dispatch(
				RequestHistoryActions.unpinChat({ friendID: friendID, message: { ...message, isPin: false } }, (errorCode) => {
					callbackErr(errorCode)
				})
			)
		}

	}

	render() {
		const {
			RequestHistoryUnRead,
			RequestHistoryRead,
			chatUserId,
			closeNavLeft,
			ChatDraft,
			ChatPin
		} = this.props
		let moreButton = (
			<div
				style={{ marginBottom: 20 }}
				className="btn-raised color_default spread_width"
				onClick={() => this.loadData()}
			>
				もっと見る
			</div>
		)
		if (
			this.state.endLoadMore ===
			Object.keys(RequestHistoryRead.data).length
		) {
			moreButton = null
		}

		let requestUnRead = [];
		let requestRead = [];
		if (RequestHistoryUnRead.data) {
			requestUnRead = Object.values(RequestHistoryUnRead.data).map((res, i) => {
				res.check = true
				return res
			})
			ChatPin.data.map(item => {
				requestUnRead = requestUnRead.filter(i => getUserId(item) !== getUserId(i))
			})
		}

		if (RequestHistoryRead.data) {
			requestRead = Object.values(RequestHistoryRead.data).filter((res, i) => {
				if (Object.keys(RequestHistoryUnRead.data).find(s => s == res.fromId) === undefined) {
					res.check = false
					return res
				}
			})
			ChatPin.data.map(item => {
				requestRead = requestRead.filter(i => getUserId(item) !== getUserId(i))
			})
		}

		let requestHistoryAll = this.renderRequestHistoryChat(ChatPin.data, requestUnRead, [...requestRead], ChatDraft)

		return (
			<>
				<div
					className="secondary-definition-wrap"
					style={style.listChatWrap}
				>
					<dl className="secondary-definition">
						<dt className="secondary-definition__title">
							<b>対応履歴</b>
						</dt>
						<dd className="secondary-definition__data">
							<div className="list column_1">
								{
									requestHistoryAll &&
									requestHistoryAll.map((res, i) =>
										<MessageHistory
											key={i}
											data={res}
											chatUserId={chatUserId}
											closeNavLeft={closeNavLeft}
											unreadMsgBadge={res.unreadMsgBadge}
											check={res.check}
											doReadMessages={(toId, fromId, msgId, closeNavLeft) => this.doReadMessages(toId, fromId, msgId, closeNavLeft)}
											handlePinMessage={(friendID, isPin, message, callback) => this.handlePinMessage(friendID, isPin, message, callback)}
											maxPin={ChatPin.count === 5}
										/>
									)
								}
								{moreButton}
							</div>
						</dd>
					</dl>
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		RequestHistory: state.RequestHistory,
		RequestHistoryUnRead: state.RequestHistoryUnRead,
		RequestHistoryRead: state.RequestHistoryRead,
		ChatNumberTotal: state.ChatNumberTotal,
		ChatDraft: state.ChatDraft,
		ChatPin: state.RequestHistoryPin
	}
}

export default connect(mapStateToProps)(LeftHistory)

const style = {
	listChatWrap: {
		height: window.innerHeight - 372,
	},
}
