import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as MyProfileActions from '../actions/MyProfileActions'
import {
	AvatarStatus,
	Genres,
	glasConfig,
	Methods,
	UPLOAD_AVATAR,
	Styles,
} from '../constants/Config'
import SettingGenres from './SettingGenres'
import SettingMethods from './SettingMethods'
import SettingStyles from './SettingStyles'
import SettingMenu from './SettingMenu'
import SettingAppealComment from './SettingAppealComment'
import PreviewProfile from './PreviewProfile'
import ThumbnailProfile from './ThumbnailProfile'
import Dialog from 'material-ui/Dialog'
import SettingProfileImage from './SettingProfileImage'
import SparkMD5 from 'spark-md5'
import TextField from 'material-ui/TextField'
import Snackbar from 'material-ui/Snackbar'
import { PROFILE_SAVE_STATUS } from '../reducers/MyProfile'
import RequireSubmitChanges from '../components/RequireSubmitChanges'
import FlatButton from 'material-ui/FlatButton'
import '../assets/scss/settingProfile.scss'
import Popup from 'reactjs-popup'
import Icon_Start from '../assets/Icon_Start.png'
import Icon_Stop from '../assets/Icon_Stop.png'
import DetectRTC from 'detectrtc'
import ReactRecord from '../components/libs/react-record'
import User from '../models/User'
import $ from 'jquery'
import {
	MESSAGE_SELECTED_ONE,
	MESSAGE_SELECTED_ONE_TO_THREE,
	SETTING_TYPE,
	REQUIRE_ONE_SKILL,
	REQUIRE_THREE_SKILL,
	MESSAGE_NOT_SELECTED_GENRES,
	MESSAGE_NOT_SELECTED_STYLES,
} from '../constants/Config'
import { FormControl } from '@material-ui/core'
import { FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { isMobile } from 'react-device-detect'

const maxTimeCountDown = 60

const actionsRecord = {
	openRecord: 1,
	startRecord: 2,
	endRecord: 3,
	replayRecord: 4,
	removeRecord: 5,
}

const titleRecord = {
	2: '録音中',
	3: '録音内容を確認する',
	4: '録音内容を再生しています',
	5: '録音内容を削除しますか？',
}

const isPWA = () => window.innerWidth < 420

class Setting extends Component {
	constructor(props) {
		super(props)
		const { MyProfile } = this.props
		const fortuneTellerName = MyProfile.data.fortuneTellerName
			? MyProfile.data.fortuneTellerName
			: ''
		const fortuneTellerMessage = MyProfile.data.message
			? MyProfile.data.message
			: ''
		const fortuneTellerComments = MyProfile.data.appealComments
			? MyProfile.data.appealComments
			: []

		this.state = {
			showThumbnailProfile: false,
			tellerName: fortuneTellerName,
			tellerNameLength: fortuneTellerName.length,
			tellerMessage: fortuneTellerMessage,
			tellerMessageLength: fortuneTellerMessage.length,
			listAppealComments: this.updateListAppealCommentsValue(
				fortuneTellerComments,
				'setInitial'
			),
			allInputCommentIsActive: false,
			firstTimeSettingComment: false,
			dialogOpen: false,
			message: '',
			saveButton: false,
			genresDefaultChecked: [],
			methodsDefaultChecked: [],
			stylesDefaultChecked: [],
			genresChecked: [],
			methodsChecked: [],
			stylesChecked: [],
			stylesDisabled: [],
			genresDisabled: [],
			methodsDisabled: [],
			postImage: '',
			selectedAvatar: false,
			isValidAvatar: false,
			openPreviewDialog: false,
			profileImage: '/img/thumb_noimage.png',
			firstTimeSetting: true,
			focusOnMsgTxt: false,
			error: false,
			display: false,
			stageName: null,
			sampleVoicePath: null,
			isHiddenNewSampleVoice: false,

			recordRunning: false,
			checkSubmit: true,
			cancel: false,
			show: false,
			targetPathName: '',
			blockRecord: true,
			record: actionsRecord.openRecord,
			timeRecord: 0,
			timeMaxRecord: 0,
			audioBtnReplay: false,
			fileAudioRecord: null,
			gender: MyProfile.data.gender,
			updateAvatarProfile: false,
		}
		this.countDownTimer = 0
	}

	updateListAppealCommentsValue(appealValues, type) {
		if (type === 'setInitial') {
			let appealComments = [
				{ id: 1, active: true, disabled: false, value: '' },
				{ id: 2, active: true, disabled: true, value: '' },
				{ id: 3, active: false, disabled: false, value: '' },
			]
			appealComments.forEach((item, index) => {
				item.value = appealValues[index]
			})
			return appealComments
		}

		const listCommentUpdate = this.state.listAppealComments
		if (type === 'fillData') {
			listCommentUpdate.forEach((item, index) => {
				item.value = appealValues[index]
			})
			return listCommentUpdate
		}

		if (type === 'adaptiveShowThreeLineInput') {
			listCommentUpdate[1].disabled = false
			listCommentUpdate[2].active = true
		}

		if (type === 'adaptiveShowTwoLineInput') {
			listCommentUpdate[1].disabled = false
		}
	}

	componentWillMount() {
		const { dispatch, MyProfile } = this.props
		// dispatch(MyProfileActions.fetch('profile'));
		const fortuneTellerComments = MyProfile.data.appealComments
			? MyProfile.data.appealComments
			: []

		this.setState({
			genresDefaultChecked: MyProfile.data.judgementGenres,
			methodsDefaultChecked: MyProfile.data.fortuneMethods,
			stylesDefaultChecked: MyProfile.data.styles,
		})

		if (
			MyProfile.data.appealComments &&
			MyProfile.data.appealComments.length === 0
		) {
			this.setState({
				firstTimeSettingComment: true,
			})
		}

		const listAppealCommentsResponse = fortuneTellerComments
		const arrEmptyItem = listAppealCommentsResponse.filter(
			(item) => item.length === 0
		)
		const arrValidItem = listAppealCommentsResponse.filter(
			(item) => item.length > 0
		)
		const listCommentSort = arrValidItem.concat(arrEmptyItem)

		if (listCommentSort && listCommentSort.length === 0) {
			return
		}

		const listCommentUpdate = this.updateListAppealCommentsValue(
			listCommentSort,
			'fillData'
		)
		if (
			listCommentUpdate[1].value &&
			listCommentUpdate[1].value.trim() !== ''
		) {
			this.updateListAppealCommentsValue('', 'adaptiveShowThreeLineInput')
		} else {
			this.updateListAppealCommentsValue('', 'adaptiveShowTwoLineInput')
		}
	}

	socketConnectData = () => {
		User.getSocket().on('point', (res) => {
			const message = JSON.parse(res)
			if (message.msgType === 'POINT') {
				$('.popup-overlay').addClass('popupBeforeGift')
			}
		})

		User.getSocket().on('userRequest', (res) => {
			const message = JSON.parse(res)
			if (message.msgType === 'ACCEPT_CHAT') {
				$('.popup-overlay').removeClass('popupBeforeGift')
			}
		})
	}

	componentDidMount() {
		this.props.dispatch(MyProfileActions.getListReasonDenyAvt());
		this.props.dispatch(MyProfileActions.fetch());
		this.socketConnectData()
		this.props.history.block((location) => {
			if (!this.state.checkSubmit && !this.state.cancel) {
				this.setState({
					show: true,
					targetPathName: location.pathname,
				})
				return false
			}
		})
		// 
		if (this.props.MyProfile.data !== undefined && this.props.MyProfile.data.sampleVoicePath !== null) {
			this.setState({ sampleVoicePath: this.props.MyProfile.data.sampleVoicePath })
		}
		if (this.props.MyProfile.data !== undefined && this.props.MyProfile.data.avatarPath !== null) {
			this.setState({ profileImage: this.props.MyProfile.data.avatarPath })
		}

		DetectRTC.load()
		setTimeout(() => {
			this.setState({ blockRecord: DetectRTC.hasMicrophone })
			if (DetectRTC.isMobileDevice) {
				this.setState({ blockRecord: true })
			}
		}, 200);
	}
	componentWilUnmount() {
		window.removeEventListener('focus', () => { })
	}
	componentWillReceiveProps(nextProps) {
		let saveButton = this.state.saveButton

		if (
			nextProps.MyProfile.data.fortuneTellerName.replace(/\s/g, '')
				.length === 0 &&
			this.state.saveButton === false
		) {
			saveButton = true
		}
		if (
			nextProps.MyProfile.data.message &&
			nextProps.MyProfile.data.message.replace(/\s/g, '').length === 0 &&
			this.state.saveButton === false
		) {
			saveButton = true
		}
		let profileImage = '/img/thumb_noimage.png'
		let postImage = ''
		let isChangingAvatar = false
		if (
			nextProps.MyProfile.data.avatarPath &&
			nextProps.MyProfile.data.avatarPath !==
			`${glasConfig.url_image}/`
		) {
			profileImage = nextProps.MyProfile.data.avatarPath
		}

		if (nextProps.File.profileImageSrc) {
			isChangingAvatar = true
			postImage = nextProps.File.profileImageSrc
		}

		/*let record = actionsRecord.openRecord;
		if(nextProps.MyProfile.data.sampleVoicePath !== null && nextProps.MyProfile.data.sampleVoicePath !== ''){
			record = actionsRecord.endRecord;
		}*/

		if (!isChangingAvatar || !this.state.updateAvatarProfile) {
			this.setState({
				tellerName: nextProps.MyProfile.data.fortuneTellerName,
				tellerNameLength: nextProps.MyProfile.data.fortuneTellerName
					? nextProps.MyProfile.data.fortuneTellerName.length
					: 0,
				tellerMessage: nextProps.MyProfile.data.message,
				tellerMessageLength: this.state.tellerMessageLength,
				saveButton: saveButton,
				genresDefaultChecked: nextProps.MyProfile.data.judgementGenres,
				methodsDefaultChecked: nextProps.MyProfile.data.fortuneMethods,
				stylesDefaultChecked: nextProps.MyProfile.data.styles,
				firstTimeSetting:
					nextProps.MyProfile.data.judgementGenres.length == 0 ||
					nextProps.MyProfile.data.fortuneMethods.length == 0 ||
					nextProps.MyProfile.data.styles.length == 0,
				stageName: nextProps.MyProfile.data.stageName,
				sampleVoicePath: nextProps.MyProfile.data.sampleVoicePath,
				isHiddenNewSampleVoice:
					nextProps.MyProfile.data.isHiddenNewSampleVoice,
				// record: record,
			})

			if (nextProps.MyProfile.data.avatarStatus === AvatarStatus.DENY) {
				profileImage = '/img/thumb_noimage.png'
				postImage = ''

			}
		}

		this.setState({
			profileImage: profileImage,
			postImage: postImage,
			updateAvatarProfile: false
		})

		if (
			(typeof this.props.MyProfile.savedProfile === 'undefined' ||
				this.props.MyProfile.savedProfile === null) &&
			typeof nextProps.MyProfile.savedProfile !== 'undefined' &&
			nextProps.MyProfile.savedProfile !== null
		) {
			if (
				!nextProps.MyProfile.savedProfile &&
				nextProps.MyProfile.savedCode !== PROFILE_SAVE_STATUS.SUCCESS
			) {
				let message

				switch (nextProps.MyProfile.savedCode) {
					case PROFILE_SAVE_STATUS.LINK_HAS_NG_WORD:
						message =
							'ご利用のURLは設定を禁止されております。詳しくは事務局までお問い合わせください。'
						break
					case PROFILE_SAVE_STATUS.TWITTER_LINK_ERROR:
						message =
							'設定されたURLはTwitterのものではありませんので、正しいURLを選択してください。'
						break
					case PROFILE_SAVE_STATUS.FACEBOOK_LINK_ERROR:
						message =
							'設定されたURLはFacebookのものではありませんので、正しいURLを選択してください。'
						break
					case PROFILE_SAVE_STATUS.OTHER_LINK_ERROR:
						message =
							'正しいURLが設定できておりません。もう一度ご確認ください。'
						break
					case PROFILE_SAVE_STATUS.BANNED_WORD_IN_PROFILE:
						message =
							'プロフィールに禁止語句が含まれています '
						break
					default:
						message = ''
				}

				this.setState({
					error: true,
					submitSuccess: false,
					message: message,
				})
			} else {
				this.setState({
					error: false,
					submitSuccess: true,
					checkSubmit: true,
				})
			}
		}
	}

	getValueInputAppealComment = (e) => {
		const value = e.target.value
		const length = value.length
		const name = e.target.name
		this.cursor = e.target.selectionStart
		const listAppealComments = this.state.listAppealComments

		if (length > glasConfig.setting.comment) {
			return
		}

		if (name === 'firstTellerComment') {
			listAppealComments[0].value = value

			if (this.state.firstTimeSettingComment) {
				if (listAppealComments[0].value) {
					listAppealComments[1].disabled = false
				} else {
					listAppealComments[1].disabled = !this.state
						.allInputCommentIsActive
						? true
						: false
				}
			}
		}
		if (name === 'secondTellerComment') {
			listAppealComments[1].value = value

			if (this.state.firstTimeSettingComment) {
				this.setState({
					allInputCommentIsActive: true,
				})
			}

			if (this.state.firstTimeSettingComment) {
				if (
					!listAppealComments[1].disabled &&
					listAppealComments[1].value
				) {
					listAppealComments[2].active = true
				}
			} else {
				if (listAppealComments[1].value) {
					listAppealComments[2].active = true
				}
			}
		}
		if (name === 'thirdTellerComment') {
			listAppealComments[2].value = value
		}
		this.setState({ checkSubmit: false })
		return listAppealComments
	}

	handleChange(e) {
		if (e.target.value.length > 1500) {
			return false
		}
		if (e.target.value.length === 1501) {
			return
		}
		// 占い師名
		this.setState({ checkSubmit: false })
		if (e.target.name === 'tellerName') {
			const value = e.target.value.replace(/\s/g, '')
			const length = value.length
			if (length > glasConfig.setting.name) {
				return
			}
			this.setState({
				tellerName: value,
				tellerNameLength: length,
			})
		}

		// メッセージ
		if (e.target.name === 'tellerMessage') {
			const value = e.target.value
			const length = value.length
			this.cursor = e.target.selectionStart

			if (length > glasConfig.setting.message) {
				return
			}
			this.setState({
				tellerMessage: value,
				tellerMessageLength: length,
			})
		}

		// プロフィール写真
		if (e.target.name === 'tellerAvatar') {
			if (e.target.files && e.target.files.length) {
				this.setState({
					selectedAvatar: true,
				})
				this.handleOpen()
			}
		}
	}

	handleClick(event, isInputChecked, target, targetMessage) {
		this.setState({ checkSubmit: false })

		let checkCount = this.state[target + 'Checked'].length
			? this.state[target + 'Checked']
			: this.state[target + 'DefaultChecked']
		let checked = []
		let disabled = []
		let message = ''
		let state = {
			message: message,
			error: false,
			saveButton: false,
		}

		let countFlag = false
		// チェックした
		if (isInputChecked) {
			// 相談スタイルのみ個数制限
			if (
				target === 'styles' &&
				glasConfig.setting.styles >= checkCount.length + 1
			) {
				countFlag = true
				checked.push(event.target.value)
			} else if (target !== 'styles') {
				checked.push(event.target.value)
			}
			// チェック外した
		} else {
			checkCount = Array.prototype.filter.call(
				checkCount,
				(val) => val !== event.target.value
			)
		}

		for (let i in checkCount) {
			if (countFlag && checkCount[i] === event.target.value) {
				continue
			}
			checked.push(checkCount[i])
		}

		// 個数以上チェックした場合に無効化
		if (
			target === 'styles' &&
			glasConfig.setting.styles === checked.length
		) {
			disabled = Array.prototype.filter.call(
				Styles,
				(val) => checked.indexOf(val) === -1
			)
		}

		if (
			target === 'genres' &&
			glasConfig.setting.genres === checked.length
		) {
			disabled = Array.prototype.filter.call(
				Genres,
				(val) => checked.indexOf(val) === -1
			)
		}
		if (
			target === 'methods' &&
			glasConfig.setting.methods === checked.length
		) {
			disabled = Array.prototype.filter.call(
				Methods,
				(val) => checked.indexOf(val) === -1
			)
		}

		if (checkCount.length === 0) {
			message = targetMessage
		}
		state[target + 'Checked'] = checked
		// チェックを操作した時点でデフォルト値は不要なので全部削除
		state[target + 'DefaultChecked'] = []
		// 相談スタイル無効化項目
		state[target + 'Disabled'] = disabled
		state['firstTimeSetting'] = false

		if (this.state.saveButton === false && checked.length === 0) {
			state['saveButton'] = true
			state['error'] = true
			state['message'] = message
		}
		this.setState(state)
	}

	getAppealComments = () => {
		let listComments = []
		const listAppealComments = this.state.listAppealComments
		const listCommentsValid = listAppealComments.filter(
			(item) => item.value && item.value.length > 0
		)
		listCommentsValid.forEach((item, index) => {
			listComments[index] = item.value ? item.value.trim() : ''
		})
		return listComments
	}

	// フォームデータ取得
	getFormData() {
		const fortuneMethods = (() => {
			let checkbox = Array.prototype.filter.call(
				document.querySelectorAll('input[name="methods[]"]'),
				(elem) => elem.checked
			)
			let list = []
			Array.prototype.forEach.call(checkbox, (elem) => {
				list.push(elem.value)
			})
			return list
		})()
		const judgementGenres = (() => {
			let checkbox = Array.prototype.filter.call(
				document.querySelectorAll('input[name="genres[]"]'),
				(elem) => elem.checked
			)
			let list = []
			Array.prototype.forEach.call(checkbox, (elem) => {
				list.push(elem.value)
			})
			return list
		})()
		const styles = (() => {
			let checkbox = Array.prototype.filter.call(
				document.querySelectorAll('input[name="styles[]"]'),
				(elem) => elem.checked
			)
			let list = []
			Array.prototype.forEach.call(checkbox, (elem) => {
				list.push(elem.value)
			})
			return list
		})()

		const appealComments = this.getAppealComments()

		const fortuneTellerName = document.updateTeller.tellerName.value
		const tellerMessage = document.updateTeller.tellerMessage.value
		const sampleVoicePath = this.props.MyProfile.data.sampleVoicePath

		return {
			fortuneMethods,
			judgementGenres,
			styles,
			fortuneTellerName,
			tellerMessage,
			sampleVoicePath,
			appealComments,
		}
	}
	handleFocusMsgTxt(e) {
		e.target.selectionStart = this.cursor
		this.setState({ focusOnMsgTxt: true })
	}
	handleBlurMsgTxt() {
		if (this.state.focusOnMsgTxt && !this.state.tellerMessageLength) {
			this.setState({
				error: true,
				message: 'メッセージを入力してください',
			})
		}
	}

	validateComments() {
		const listAppealComments = this.state.listAppealComments

		if (
			(listAppealComments[0].value &&
				listAppealComments[0].value.trim() !== '') ||
			(listAppealComments[1].value &&
				listAppealComments[1].value.trim() !== '') ||
			(listAppealComments[2].value &&
				listAppealComments[2].value.trim() !== '')
		) {
			return false
		} else {
			return true
		}
	}

	validateSkillTeller = () => {
		const settingTypes = [
			SETTING_TYPE.GENRES,
			SETTING_TYPE.METHODS,
			SETTING_TYPE.STYLES,
		]
		let isValidSkillTeller = true
		let errorMessage = ''

		for (let type of settingTypes) {
			const checkedItem = this.state[type + 'Checked'].length
				? this.state[type + 'Checked']
				: this.state[type + 'DefaultChecked']

			if (
				type != SETTING_TYPE.METHODS &&
				checkedItem.length < REQUIRE_THREE_SKILL
			) {
				if (type === SETTING_TYPE.GENRES) {
					isValidSkillTeller = false
					if (checkedItem.length === 0) {
						errorMessage = MESSAGE_NOT_SELECTED_GENRES
						break
					} else {
						errorMessage = MESSAGE_SELECTED_ONE_TO_THREE
						break
					}
				} else if (type === SETTING_TYPE.STYLES) {
					isValidSkillTeller = false
					if (checkedItem.length === 0) {
						errorMessage = MESSAGE_NOT_SELECTED_STYLES
						break
					} else {
						errorMessage = MESSAGE_SELECTED_ONE_TO_THREE
						break
					}
				}
			} else if (
				type == SETTING_TYPE.METHODS &&
				checkedItem.length < REQUIRE_ONE_SKILL
			) {
				isValidSkillTeller = false
				errorMessage = MESSAGE_SELECTED_ONE
				break
			} else if (type == 'methods' && checkedItem.length < 1) {
				isValidSkillTeller = false
				errorMessage = MESSAGE_SELECTED_ONE
				break
			}
		}
		return {
			isValidSkillTeller,
			errorMessage,
		}
	}

	validateIntroduceMessage = () => {
		return this.state.tellerMessageLength > 0
	}

	handleSubmit(param = {}) {
		// 更新
		// if(e.target !== null && e.target.name === 'updateTeller'){
		const isValidSkillTeller = this.validateSkillTeller().isValidSkillTeller
		const errorMessage = this.validateSkillTeller().errorMessage
		const isValidIntroduceMessage = this.validateIntroduceMessage()

		// validate skill teller
		if (!isValidSkillTeller) {
			this.setState({ error: true, message: errorMessage })
			return
		}

		const { dispatch } = this.props
		let formData = this.getFormData()

		// validate introduce message
		if (!isValidIntroduceMessage) {
			this.setState({
				error: true,
				message: '自己紹介を入力してください',
			})
			return
		}

		if (this.state.selectedAvatar && this.state.postImage) {
			if (!this.state.isValidAvatar) {
				this.setState({
					error: true,
					message:
						'プロフィール画像が正常に登録できませんでした。再度ご登録をお願い致します。',
				})
				return
			}

			// 画像更新

			// canvasから画像生成
			let type = 'image/jpeg'
			let bin = atob(this.state.postImage.split(',')[1])
			let buffer = new Uint8Array(bin.length)
			for (let i = 0; i < bin.length; i++) {
				buffer[i] = bin.charCodeAt(i)
			}
			let blob = new Blob([buffer.buffer], { type: type })

			// チェックサム計算
			let fileReader = new FileReader()
			fileReader.onload = (event) => {
				let spark = new SparkMD5.ArrayBuffer()
				spark.append(event.target.result)
				let hash = spark.end()

				let imgFormData = new FormData()
				imgFormData.append('file', blob, 'profile.jpg')
				imgFormData.append('md5Sum', hash)

				const data = {
					fileData: imgFormData,
					profileData: {
						fortuneTellerName: formData.fortuneTellerName,
						message: formData.tellerMessage,
						fortuneMethods: formData.fortuneMethods,
						judgementGenres: formData.judgementGenres,
						styles: formData.styles,
						isNeedValidate: !this.state.firstTimeSetting,
						sampleVoicePath:
							this.props.MyProfile.data.sampleVoicePath,
					},
				}
				dispatch(
					MyProfileActions.profileImagePost(
						this.props.MyProfile,
						data
					)
				)
				document.updateTeller.tellerAvatar.value = ''
				this.setState({
					selectedAvatar: false,
				})
			}
			fileReader.readAsArrayBuffer(blob)
			dispatch(MyProfileActions.merge({
				reasonDenyAvatarType: 0
			}))
			// 画像未更新
		} else {
			let avt = null
			if (this.state.profileImage !== '/img/thumb_noimage.png') {
				avt = this.state.profileImage
			}

			if (!avt) {
				this.setState({
					error: true,
					message:
						'プロフィール画像が正常に登録できませんでした。再度ご登録をお願い致します。',
				})
				return
			}

			const data = {
				fortuneTellerName: formData.fortuneTellerName,
				message: formData.tellerMessage,
				fortuneMethods: formData.fortuneMethods,
				judgementGenres: formData.judgementGenres,
				styles: formData.styles,
				isNeedValidate: !this.state.firstTimeSetting,
				avatarPath: avt !== '/img/thumb_noimage.png' ? avt : '',
				gender: this.state.gender
			}

			if (param.urlAudio !== undefined && param.urlAudio !== null) {
				Object.assign(data, { sampleVoicePath: param.urlAudio })
			} else {
				Object.assign(data, {
					sampleVoicePath: this.props.MyProfile.data.sampleVoicePath,
				})
			}
			dispatch(MyProfileActions.put('profile', data))
		}
	}

	handleBlur(event) {
		// 占い師名
		if (event.target.name === 'tellerName') {
			const value = event.target.value.replace(/\s/g, '')
			const length = value.length
			let error = false
			let message = ''
			let saveButton = this.state.saveButton
			if (length == 0 && saveButton === false) {
				message = '名前を入力してください'
				error = true
				saveButton = true
			}
			this.setState({
				message: message,
				error: error,
				saveButton: saveButton,
			})
		}

		// メッセージ
		if (event.target.name === 'tellerMessage') {
			const value = event.target.value.replace(/\s/g, '')
			const length = value.length
			let error = false
			let message = ''
			let saveButton = this.state.saveButton
			if (length == 0 && saveButton === false) {
				message = 'メッセージを入力してください'
				error = true
				saveButton = false
			}
			this.setState({
				message: message,
				error: error,
				saveButton: saveButton,
			})
		}
	}

	// ダイアログ表示
	handleOpen() {
		this.setState({
			dialogOpen: true,
		})
	}

	// ダイアログ非表示
	handleClose(status) {
		if (status != 'upload') {
			document.updateTeller.tellerAvatar.value = ''
		}
		this.setState({
			dialogOpen: false,
		})
	}

	handleErrorClose() {
		this.setState({
			error: false,
			message: '',
		})
	}

	handleSubmitClose() {
		this.setState({
			submitSuccess: false,
		})
	}

	handleBtnClick(event) {
		event.preventDefault()
		document.updateTeller.tellerAvatar.click()
		this.setState({
			updateAvatarProfile: true
		})
	}

	validateAvaPath(file) {
		if (!file.size) {
			return false
			// this.setState({isValidAvatar : false});
		}

		const fileType = file.type
		const validFileExtensions = ['image/png', 'image/jpeg', 'image/jpg']

		if (validFileExtensions.indexOf(fileType) == -1) {
			this.setState({
				error: true,
				message:
					'プロフィール画像が正常に登録できませんでした。再度ご登録をお願い致します。',
			})
			return false
		}

		this.setState({
			isValidAvatar: validFileExtensions.indexOf(fileType) >= 0,
		})
		return true
	}
	handleFocusMsgTxt(e) {
		e.target.selectionStart = this.cursor
		this.setState({ focusOnMsgTxt: true })
	}

	handleBlurMsgTxt() {
		if (this.state.focusOnMsgTxt && !this.state.tellerMessageLength) {
			this.setState({
				error: true,
				message: 'メッセージを入力してください',
			})
		}
	}

	handleBtnPreviewOnApp(e) {
		e.preventDefault()

		const isValidSkillTeller = this.validateSkillTeller().isValidSkillTeller
		const errorMessage = this.validateSkillTeller().errorMessage

		if (!this.state.firstTimeSetting && !isValidSkillTeller) {
			this.setState({ error: true, message: errorMessage })
			return
		}

		if (this.state.focusOnMsgTxt && !this.state.tellerMessageLength) {
			this.setState({
				error: true,
				message: 'メッセージを入力してください',
			})
		}

		const { MyProfile, dispatch } = this.props

		let formData = this.getFormData()

		this.setState({ openPreviewDialog: true })
	}

	onClickCancel = () => {
		this.setState({ show: false })
	}

	onClickOk = () => {
		this.setState({ checkSubmit: true }, () => {
			this.props.history.push(this.state.targetPathName)
		})
	}

	countDown() {
		if (this.state.record === actionsRecord.openRecord) {
			clearInterval(this.countDownTimer)
		}

		let maxCountDown = maxTimeCountDown
		if (
			this.state.record === actionsRecord.endRecord ||
			this.state.record === actionsRecord.replayRecord
		) {
			let durationTimeAudio
			durationTimeAudio = this.recordAudioItem.duration
			if (
				!isNaN(durationTimeAudio) &&
				durationTimeAudio !== undefined &&
				durationTimeAudio !== Infinity
			) {
				durationTimeAudio =
					durationTimeAudio > maxTimeCountDown
						? maxTimeCountDown
						: durationTimeAudio
				maxCountDown = parseInt(durationTimeAudio)
			}
		}

		let countTotal = this.state.timeRecord + 1
		if (countTotal > maxCountDown) {
			clearInterval(this.countDownTimer)
			this.recordAudioItem.pause()
			this.recordAudioItem.currentTime = 0
			this.setState({
				record: actionsRecord.endRecord,
				audioBtnReplay: false,
				checkSubmit: false,
				recordRunning: false,
				timeRecord: 0,
			})
		} else {
			if (this.state.record === actionsRecord.startRecord) {
				this.setState({
					timeRecord: countTotal,
					timeMaxRecord: countTotal,
				})
			} else if (this.state.record >= actionsRecord.replayRecord) {
				this.setState({
					timeRecord: countTotal,
					timeMaxRecord: maxCountDown,
				})
			}
		}
	}

	onStart = () => {
		this.setState({
			record: actionsRecord.startRecord,
			timeRecord: 0,
			timeMaxRecord: 0,
		})

		this.countDownTimer = setInterval(this.countDown.bind(this), 1000)
	}

	startRecording = async () => {
		console.log('startRecording');
		this.setState({
			recordRunning: true,
		})
	}

	stopRecording = () => {
		clearInterval(this.countDownTimer)
		this.setState({
			record: actionsRecord.endRecord,
			checkSubmit: false,
			recordRunning: false,
		})
	}

	onData(recordedBlob) { }

	onStop(recordedBlob) {
		this.setState({
			sampleVoicePath: recordedBlob.blobURL,
			fileAudioRecord: recordedBlob,
			// timeMaxRecord: secondTime,
		})

		let MyProfile = this.props.MyProfile.data
		const { dispatch } = this.props
		MyProfile.sampleVoicePath = recordedBlob.blobURL
		dispatch({
			type: 'MYINFO_PROFILE',
			data: MyProfile,
		})
	}

	startReplayAudioFirstRecord = () => {
		console.log('startReplayAudioFirstRecord');
		this.recordAudioItem.play()
		this.countDownTimer = setInterval(this.countDown.bind(this), 1000)
		let dataSetStart = {
			audioBtnReplay: true,
			record: actionsRecord.replayRecord,
		}
		if (this.state.timeRecord === this.state.timeMaxRecord) {
			Object.assign(dataSetStart, { timeRecord: 0 })
		}
		this.setState(dataSetStart)
	}

	startReplayAudio = () => {
		this.recordAudioItem.play()
		this.countDownTimer = setInterval(this.countDown.bind(this), 1000)
		let dataSetStart = {
			audioBtnReplay: true,
			record: actionsRecord.replayRecord,
		}
		if (this.state.timeRecord === this.state.timeMaxRecord) {
			Object.assign(dataSetStart, { timeRecord: 0 })
		}
		this.setState(dataSetStart)
	}

	stopReplayAudio = () => {
		this.recordAudioItem.pause()
		// this.recordAudioItem.currentTime = 0;
		clearInterval(this.countDownTimer)
		this.setState({
			audioBtnReplay: false,
			record: actionsRecord.endRecord,
			// timeRecord: this.state.timeMaxRecord
		})
	}

	renderReplayAudio = (close) => {
		return (
			<div>
				<div className={'row-pc row-end-record-reListen'}>
					<button
						type={'button'}
						onClick={() => this.backAudioReplay()}
						className={
							'btn-action-run-time box-background-btn-record-default'
						}
					>
						削除
					</button>
					<div style={{ textAlign: 'center' }}>
						{this.state.audioBtnReplay ? (
							<img
								onClick={() => this.stopReplayAudio()}
								src={Icon_Stop}
								alt={''}
							/>
						) : (
							<img
								onClick={() =>
									this.startReplayAudioFirstRecord()
								}
								src={Icon_Start}
								alt={''}
							/>
						)}
					</div>
					<button
						type={'button'}
						onClick={() => this.confirmSaveAudio(close)}
						className={
							'btn-action-run-time box-background-btn-record-green'
						}
					>
						OK
					</button>
				</div>
				<div className={'row-mobile'}>
					<div style={{ textAlign: 'center' }}>
						{this.state.audioBtnReplay ? (
							<img
								onClick={() => this.stopReplayAudio()}
								src={Icon_Stop}
								alt={''}
							/>
						) : (
							<img
								onClick={() =>
									this.startReplayAudioFirstRecord()
								}
								src={Icon_Start}
								alt={''}
							/>
						)}
					</div>
					<div className={'row-end-record-reListen'}>
						<button
							type={'button'}
							onClick={() => this.backAudioReplay()}
							className={
								'btn-action-run-time box-background-btn-record-default'
							}
						>
							削除
						</button>
						<button
							type={'button'}
							onClick={() => this.confirmSaveAudio(close)}
							className={
								'btn-action-run-time box-background-btn-record-green'
							}
						>
							OK
						</button>
					</div>
				</div>
			</div>
		)
	}

	renderBoxReplayAfterEndRecord = (close) => {
		return (
			<div style={{ textAlign: 'center' }}>
				{this.state.audioBtnReplay ? (
					<img
						onClick={() => this.stopReplayAudio()}
						src={Icon_Stop}
						alt={''}
					/>
				) : (
					<img
						onClick={() => this.startReplayAudio()}
						src={Icon_Start}
						alt={''}
					/>
				)}
			</div>
		)
	}

	backAudioReplay = () => {
		this.setState({
			record: actionsRecord.removeRecord,
		})
	}

	backAudioreplay = () => {
		this.setState({
			record: 3,
		})
	}

	cancelDeleteAudioPopup = (close) => {
		close()
	}

	confirmSaveAudio = (close) => {
		close()
		clearInterval(this.countDownTimer)
		this.setState({
			audioBtnReplay: false,
			checkSubmit: false,
			recordRunning: false,
			timeRecord: 0,
		})
		this.recordAudioItem.pause()
		this.recordAudioItem.currentTime = 0
	}

	uploadFileAudio = (e) => {
		let MyProfile = this.props.MyProfile.data

		e.preventDefault()
		if (this.state.fileAudioRecord !== null) {
			let blob = new Blob(
				[this.state.fileAudioRecord.blob],
				this.state.fileAudioRecord.blob
			)

			let audioFormData = new FormData()
			let fileReader = new FileReader()
			fileReader.onload = (event) => {
				let spark = new SparkMD5.ArrayBuffer()
				spark.append(event.target.result)
				let hash = spark.end()

				audioFormData.append('file', blob, 'audioFileSave.mp3')
				audioFormData.append('md5Sum', hash)
				let urlAudio = MyProfileActions.uploadfileAudioPost(
					'profile',
					audioFormData,
					MyProfile
				)
				this.setState({
					fileAudioRecord: null,
				})
				urlAudio.then((res) => {
					if (res !== undefined) {
						let MyProfile = this.props.MyProfile.data
						MyProfile.sampleVoicePath = res
						const { dispatch } = this.props
						dispatch({
							type: 'MYINFO_PROFILE',
							data: MyProfile,
						})
						this.handleSubmit({ urlAudio: res })
					}
				})
			}
			fileReader.readAsArrayBuffer(blob)
		} else {
			this.handleSubmit()
		}
	}

	confirmDeleteAudio5 = (close = null) => {
		clearInterval(this.countDownTimer)
		let MyProfile = this.props.MyProfile.data
		MyProfile.sampleVoicePath = null
		const { dispatch } = this.props
		dispatch({
			type: 'MYINFO_PROFILE',
			data: MyProfile,
		})
		this.setState({
			record: actionsRecord.openRecord,
			timeRecord: 0,
			timeMaxRecord: 0,
			sampleVoicePath: null,
			audioBtnReplay: false,
			checkSubmit: true,
			fileAudioRecord: null,
		})
		if (close !== null) {
			close()
		}
	}

	renderBoxConfirmDeleteAudio5 = (close) => {
		return (
			<div>
				<button
					type={'button'}
					onClick={() => this.confirmDeleteAudio5(close)}
					className={
						'btn-action-run-time box-background-btn-record-green box-confirm-record'
					}
				>
					はい
				</button>
				<button
					type={'button'}
					onClick={() => this.backAudioreplay()}
					className={
						'btn-action-run-time box-background-btn-record-default'
					}
				>
					いいえ
				</button>
			</div>
		)
	}

	renderPopupConfirm = (close) => {
		return (
			<div>
				<div className="title-box-record">録音内容を削除しますか？</div>
				<button
					type={'button'}
					onClick={() => this.confirmDeleteAudio5(close)}
					className={
						'btn-action-run-time box-background-btn-record-green box-confirm-record'
					}
				>
					はい
				</button>
				<button
					type={'button'}
					onClick={() => this.cancelDeleteAudioPopup(close)}
					className={
						'btn-action-run-time box-background-btn-record-default'
					}
				>
					いいえ
				</button>
			</div>
		)
	}

	renderBoxRecord = () => {
		let maxCountDown =
			this.state.timeMaxRecord !== 0 &&
				this.state.record !== actionsRecord.startRecord
				? this.state.timeMaxRecord
				: maxTimeCountDown
		if (this.state.record > actionsRecord.startRecord) {
			if (this.state.audioBtnReplay) {
				maxCountDown = this.state.timeMaxRecord
			} else {
				maxCountDown = this.state.timeMaxRecord
				// maxCountDown = this.state.timeRecord;
			}
		}

		let timeRun
		if (this.state.timeRecord !== this.state.timeMaxRecord) {
			if (this.state.timeMaxRecord < 10) {
				timeRun = `0` + this.state.timeMaxRecord
			} else {
				timeRun = this.state.timeMaxRecord
			}
		} else {
			if (this.state.timeRecord < 10) {
				timeRun = `0` + this.state.timeRecord
			} else {
				timeRun = this.state.timeRecord
			}
		}

		if (this.state.record === actionsRecord.startRecord) {
			if (this.state.timeRecord < 10) {
				timeRun = `0` + this.state.timeRecord
			} else {
				timeRun = this.state.timeRecord
			}
		} else if (this.state.record === actionsRecord.endRecord) {
			if (
				this.state.timeRecord > 0 &&
				this.state.timeRecord !== this.state.timeMaxRecord
			) {
				if (this.state.timeRecord < 10) {
					timeRun = `0` + this.state.timeRecord
				} else {
					timeRun = this.state.timeRecord
				}
			} else {
				if (this.state.timeMaxRecord < 10) {
					timeRun = `0` + this.state.timeMaxRecord
				} else {
					timeRun = this.state.timeMaxRecord
				}
			}
		} else if (this.state.record === actionsRecord.replayRecord) {
			if (this.state.timeRecord < 10) {
				timeRun = `0` + this.state.timeRecord
			} else {
				timeRun = this.state.timeRecord
			}
		} else if (this.state.record === actionsRecord.endRecord) {
			if (this.state.timeMaxRecord < 10) {
				timeRun = `0` + this.state.timeMaxRecord
			} else {
				timeRun = this.state.timeMaxRecord
			}
		}

		return (
			<div>
				<div className={'box-line-timing'}>
					{/*<AudioRecorder />*/}
					<ReactRecord
						record={this.state.recordRunning}
						className="sound-wave-record-setting"
						onStart={this.onStart}
						onStop={this.onStop.bind(this)}
						onData={this.onData}
					/>
					<div className={'first-line'}>&nbsp;</div>
					<div className={'end-line'}>&nbsp;</div>
					<div className={'process-line'}>&nbsp;</div>
					<div
						className={'middle-line'}
						style={{
							width:
								'' +
								parseInt((timeRun / maxCountDown) * 100) +
								'%',
						}}
					>
						&nbsp;
					</div>
					<div
						className={'process-run-line'}
						style={{
							left:
								'' +
								parseInt((timeRun / maxCountDown) * 100) +
								'%',
						}}
					>
						&nbsp;
					</div>
				</div>
				<div className={'box-btn-record-setting'}>
					<p className={'time-run-record'}>00:{timeRun}</p>
				</div>
			</div>
		)
	}

	durationTimeAudio = async () => {
		if (!this.state.audioBtnReplay) {
			this.startReplayAudio()
		}
		let durationTimeAudio
		durationTimeAudio = this.recordAudioItem.duration

		if (
			!isNaN(durationTimeAudio) &&
			durationTimeAudio !== undefined &&
			durationTimeAudio !== Infinity
		) {
			durationTimeAudio =
				durationTimeAudio > maxTimeCountDown
					? maxTimeCountDown
					: durationTimeAudio
			this.setState({
				timeMaxRecord: parseInt(durationTimeAudio),
			})
		}
	}

	checkIsHiddenNewSampleVoice = () => {
		const { dispatch, MyProfile } = this.props
		if (
			MyProfile.data.isHiddenNewSampleVoice !== undefined &&
			!MyProfile.data.isHiddenNewSampleVoice
		) {
			MyProfile.data.isHiddenNewSampleVoice =
				MyProfileActions.putUpdaetHiddenNewSampleVoice()
			dispatch({
				type: 'MYINFO_PROFILE',
				data: MyProfile.data,
			})
		}
	}

	CloseBoxReplayAudio = (close) => {
		this.recordAudioItem.pause()
		this.recordAudioItem.currentTime = 0
		clearInterval(this.countDownTimer)
		this.setState({
			timeMaxRecord: this.state.timeMaxRecord,
			audioBtnReplay: false,
			timeRecord: 0,
		})
		close()
	}

	renderStatusUploadAvatar = () => {
		const { MyProfile } = this.props
		console.log('MyProfile', MyProfile)
		let element
		const reasonDenyAvatarType =
			MyProfile.data && MyProfile.data.reasonDenyAvatarType
		if (reasonDenyAvatarType !== 0 && MyProfile.reasonDenyAvt.length > 0) {
			element = (
				<div
					style={
						!isPWA()
							? styles.statusTellerAvatar
							: styles.statusTellerAvatarPWA
					}
				>
					<p style={styles.titleStatusTellerAvatar}>
						プロフィール画像が否認されました。
						{isPWA() && <br />}
						<span>
							【{MyProfile.reasonDenyAvt[reasonDenyAvatarType - 1].reason}】
						</span>
					</p>
					<p style={styles.solutionStatusTellerAvatar}>
						{MyProfile.reasonDenyAvt[reasonDenyAvatarType - 1].solution}
					</p>
				</div>
			)
		}
		return element
	}
	onChangeGender = (value) => {
		const gender = value === 'male' ? 1 : value === 'female' ? 2 : 3
		this.setState({ gender, checkSubmit: false })
	}
	render() {
		const { MyProfile } = this.props
		if (!MyProfile.loaded) {
			return ''
		}

		let imageSituation = ''
		if (
			MyProfile.data.verifyAge === 'Reviewing' ||
			parseInt(MyProfile.data.verifyAge, 10) === 0
		) {
			imageSituation = (
				<figcaption className="prof-thumbnail__figcaption">
					審査中
				</figcaption>
			)
		} else if (
			MyProfile.data.verifyAge === 'ReviewNG' ||
			parseInt(MyProfile.data.verifyAge, 10) === -1
		) {
			imageSituation = (
				<figcaption className="prof-thumbnail__figcaption">
					審査NG
				</figcaption>
			)
		}

		let avatarStatus = ''
		if (MyProfile.data.avatarStatus == AvatarStatus.PENDDING) {
			avatarStatus = (
				<figcaption
					className="prof-thumbnail__figcaption"
					style={{ backgroundColor: '#ec407a' }}
				>
					審査中
				</figcaption>
			)
		} else if (MyProfile.data.avatarStatus == AvatarStatus.MODIFY) {
			avatarStatus = (
				<figcaption
					className="prof-thumbnail__figcaption"
					style={{ backgroundColor: '#0000ff' }}
				>
					加工中
				</figcaption>
			)
		}

		const tellerNameLength =
			glasConfig.setting.name > this.state.tellerNameLength
				? glasConfig.setting.name -
				this.state.tellerNameLength +
				' / 12'
				: ''
		const tellerMessageLength =
			glasConfig.setting.message > this.state.tellerMessageLength
				? glasConfig.setting.message -
				this.state.tellerMessageLength +
				' / 1500'
				: ''

		const actions = []
		return (
			<div className="content">
				{/* <Loading display={this.state.display}/> */}
				<h1 className="content__title">設定</h1>
				<SettingMenu />
				<div className="primary">
					<div className="primary-title" style={styles.topTitle}>
						<h1 className="primary-title__main">
							プロフィール情報
						</h1>
						<p className="primary-title__subtitle subtitle_setting">
							お客様に公開される情報です
						</p>
					</div>
					<div className="primary__inner">
						<form
							name="updateTeller"
							onSubmit={(e) => this.uploadFileAudio(e)}
						>
							<div className="definition wide_bottom prof-thumbnail-section">
								<p className="definition__title hidden-sp">
									プロフィール写真
								</p>
								{this.renderStatusUploadAvatar()}
								<div style={{ marginTop: 15 }}>
									<div className="prof-thumbnail-section__left">
										<dl className="definition wide_bottom">
											<dd className="definition__data">
												<div className={isPWA() ? "prof-thumbnail" : "prof-avatar"}>
													<figure className="prof-thumbnail__figure">
														<img
															className="prof-thumbnail__image"
															src="/img/spacer.gif"
															style={{
																backgroundImage: `url(${this.state
																	.postImage ||
																	this.state
																		.profileImage
																	})`,
															}}
															alt=""
														/>
														{imageSituation}
														{avatarStatus}
													</figure>
													<div className="prof-thumbnail__button btn-wrap" style={isPWA() ? {} : { marginTop: 15 }}>
														<button
															type={'button'}
															className="btn-raised"
															onClick={(e) =>
																this.handleBtnClick(
																	e
																)
															}
															style={isPWA() ? {} : { width: 220 }}
														>
															<i className="material-icons btn__icons">
																insert_photo
															</i>
															写真選択
														</button>
														<input
															type="file"
															name="tellerAvatar"
															accept="image/*"
															onChange={(e) =>
																this.handleChange(
																	e
																)
															}
															style={{
																display: 'none',
															}}
														/>
														<div className="pc_hide">
															<FlatButton
																onClick={() =>
																	this.setState(
																		{
																			showThumbnailProfile: true,
																		}
																	)
																}
																label="ご登録写真の条件について"
																labelStyle={{
																	color: 'rgba(17, 173, 157, 1)',
																	paddingLeft: 0,
																	paddingRight: 0,
																	fontSize: 13,
																}}
																primary={true}
																disableTouchRipple
																hoverColor="transparent"
															/>
														</div>
													</div>
												</div>
											</dd>
											{this.props.MyProfile.data
												.avatarStatus == 0 && (
													<dd>
														<div className="pc_hide">
															<ThumbnailProfile
																hideAction
															/>
														</div>
													</dd>
												)}
											{!isPWA() && <div style={{ marginTop: 15 }}>
												※条件を満たしていない場合は再登録 となり登録までお時間がかかります。
											</div>}
											<div className='link-suggest-upload'>
												<a href={'https://fortune.stella-app.com/home/staffblog/detail/152'}>{`【運営ブログ】 \n→プロフィール画像を用意しよう`}</a>
											</div>
										</dl>

									</div>
									<div className="prof-thumbnail__right">
										<div className="thumbnail-guide__image" id="icon-speech-bubble">
											<img style={{ objectFit: 'contain' }} src="/img/suggest_upload_avatar.gif" />
										</div>
									</div>
								</div>
								<dl className="definition" style={{ maxWidth: '300px' }}>
									<dt className="definition__title">
										名前
									</dt>
									<dd className="definition__data">
										<div className="textfield">
											<TextField
												className="textfield__input"
												name="tellerName"
												maxLength="12"
												id="handle_name"
												hintText="名前を入力してください"
												onBlur={(e) =>
													this.handleBlur(e)
												}
												onChange={(e) =>
													this.handleChange(e)
												}
												value={
													this.state
														.tellerName
												}
												floatingLabelText={
													tellerNameLength
												}
												floatingLabelFixed={
													true
												}
												style={
													textfieldStyles.block
												}
												floatingLabelStyle={
													textfieldStyles.label
												}
												inputStyle={
													textfieldStyles.textarea
												}
											/>
										</div>

									</dd>
								</dl>
							</div>
							<div className='container_gender'>
								<FormControl>
									<FormLabel className='gender' id="demo-radio-buttons-group-label">性別</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue={this.state.gender === 1 ? "male" : this.state.gender === 2 ? 'female' : 'other'}
										name="radio-buttons-group"
										className='list_gender'
										onChange={(e, value) => this.onChangeGender(value)}
									>
										<FormControlLabel value="male" control={<Radio className={this.state.gender === 1 ? 'gender_check' : 'gender_uncheck'} />} label="男性" />
										<FormControlLabel value="female" control={<Radio className={this.state.gender === 2 ? 'gender_check' : 'gender_uncheck'} />} label="女性" />
										<FormControlLabel value="other" control={<Radio className={this.state.gender === 3 ? 'gender_check' : 'gender_uncheck'} />} label="その他" />
									</RadioGroup>
								</FormControl>
							</div>
							<SettingGenres
								checked={this.state.genresChecked}
								defaultChecked={this.state.genresDefaultChecked}
								disabled={this.state.genresDisabled}
								handleClick={this.handleClick.bind(this)}
							/>
							<SettingMethods
								checked={this.state.methodsChecked}
								defaultChecked={
									this.state.methodsDefaultChecked
								}
								disabled={this.state.methodsDisabled}
								handleClick={this.handleClick.bind(this)}
							/>
							<SettingStyles
								checked={this.state.stylesChecked}
								defaultChecked={this.state.stylesDefaultChecked}
								disabled={this.state.stylesDisabled}
								handleClick={this.handleClick.bind(this)}
							/>

							{/* ==================================Appeal Comment================================== */}
							{/* =================================END Appeal Comment==================================== */}
							<dl className="definition wide_bottom">
								<dt className="definition__title">自己紹介</dt>
								<dd className="definition__data">
									<div className="textfield spread_width">
										<TextField
											className="textfield__textarea"
											fullWidth={true}
											type="text"
											name="tellerMessage"
											id="introduction"
											placeholder="ご自身の経歴や占術について記入してくだい"
											defaultValue={
												this.state.tellerMessage
											}
											onChange={(e) =>
												this.handleChange(e)
											}
											floatingLabelText={
												tellerMessageLength
											}
											floatingLabelFixed={true}
											multiLine={true}
											rows={3}
											onFocus={(e) =>
												this.handleFocusMsgTxt(e)
											}
											onBlur={(e) =>
												this.handleBlurMsgTxt(e)
											}
											rowsMax={100}
											hintStyle={textfieldStyles.hint}
											floatingLabelStyle={
												textfieldStyles.label
											}
											textareaStyle={
												textfieldStyles.textarea
											}
											maxLength={1500}
											maxlength="1500"
										/>
									</div>
								</dd>
							</dl>
							<dl className="definition wide_bottom setting-external-url">
								<div className="definition__title title-record-implement-voice">
									サンプルボイス{' '}
									{this.props.MyProfile.data
										.isHiddenNewSampleVoice ? null : (
										<div className={'new-border-title'}>
											NEW
										</div>
									)}
								</div>
								<div
									onClick={() =>
										this.checkIsHiddenNewSampleVoice()
									}
								>
									{
										<Popup
											closeOnDocumentClick={false}
											ref="popupRecordAudio"
											trigger={
												this.props.MyProfile.data.sampleVoicePath == "" || this.props.MyProfile.data.sampleVoicePath == null ? (
													<button
														type={'button'}
														className={
															'btn-sample-voice'
														}
													>
														録音する
													</button>
												) : null
											}
											modal={true}
											contentStyle={{
												width: '357px',
												borderRadius: 5,
											}}
										>
											{(close) => (
												<div
													className={
														'box-voice-teller'
													}
												>
													{!this.state.blockRecord ? (
														<div
															className={
																'box-voice-teller-warning'
															}
														>
															お客様に公開されるサンプルボイスです。
															自己紹介と一言メッセージを吹き込みましょう。
														</div>
													) : (
														<div>
															<div
																className={
																	'title-box-record'
																}
															>
																{this.state
																	.record ===
																	actionsRecord.openRecord ||
																	this.state
																		.record ===
																	actionsRecord.startRecord
																	? titleRecord[
																	actionsRecord
																		.startRecord
																	]
																	: this.state
																		.record ===
																		actionsRecord.endRecord
																		? titleRecord[
																		actionsRecord
																			.endRecord
																		]
																		: this.state
																			.record ===
																			actionsRecord.replayRecord
																			? titleRecord[
																			actionsRecord
																				.replayRecord
																			]
																			: titleRecord[
																			actionsRecord
																				.removeRecord
																			]}
															</div>
															{this.state
																.record ===
																actionsRecord.openRecord ||
																this.state
																	.record ===
																actionsRecord.startRecord ||
																this.state
																	.record ===
																actionsRecord.endRecord ||
																this.state
																	.record ===
																actionsRecord.replayRecord
																? this.renderBoxRecord()
																: null}
															<div
																className={
																	'box-btn-record-setting'
																}
															>
																{this.state
																	.record ===
																	actionsRecord.openRecord ? (
																	<button
																		type={
																			'button'
																		}
																		onClick={() =>
																			this.startRecording()
																		}
																		className={
																			'btn-action-run-time box-background-btn-record-green'
																		}
																	>
																		開始
																	</button>
																) : this.state
																	.record ===
																	actionsRecord.startRecord ? (
																	<button
																		type={
																			'button'
																		}
																		onClick={() =>
																			this.stopRecording()
																		}
																		className={
																			'btn-action-run-time box-background-btn-record-pink'
																		}
																	>
																		終了
																	</button>
																) : this.state
																	.record ===
																	actionsRecord.endRecord ? (
																	this.renderReplayAudio(
																		close
																	)
																) : this.state
																	.record ===
																	actionsRecord.replayRecord ? (
																	this.renderReplayAudio(
																		close
																	)
																) : (
																	this.renderBoxConfirmDeleteAudio5(
																		close
																	)
																)}
															</div>
														</div>
													)}
												</div>
											)}
										</Popup>
									}
									{(this.props.MyProfile.data.sampleVoicePath) && (
										<div>
											<Popup
												closeOnDocumentClick={false}
												closeOnEscape={false}
												onOpen={() =>
													this.durationTimeAudio()
												}
												trigger={
													<button
														type={'button'}
														className={
															'btn-sample-voice-green box-confirm-record'
														}
													>
														再生する
													</button>
												}
												modal={true}
												contentStyle={{
													width: '357px',
													borderRadius: 5,
												}}
											>
												{(close) => (
													<div
														className={
															'box-voice-teller'
														}
													>
														<div
															style={{
																textAlign:
																	'left',
																padding:
																	'23px 0 18px 22px',
															}}
														>
															{
																titleRecord[
																actionsRecord
																	.replayRecord
																]
															}
														</div>
														<div
															className={
																'icon-close-modal'
															}
															onClick={() =>
																this.CloseBoxReplayAudio(
																	close
																)
															}
														>
															x
														</div>
														{this.state.record ===
															actionsRecord.openRecord ||
															this.state.record ===
															actionsRecord.startRecord ||
															this.state.record ===
															actionsRecord.endRecord ||
															this.state.record ===
															actionsRecord.replayRecord
															? this.renderBoxRecord()
															: null}
														<div
															className={
																'box-btn-record-setting'
															}
														>
															{this.renderBoxReplayAfterEndRecord(
																close
															)}
														</div>
													</div>
												)}
											</Popup>
											<Popup
												closeOnDocumentClick={false}
												closeOnEscape={false}
												trigger={
													<button
														type={'button'}
														className={
															'btn-sample-voice-default'
														}
													>
														削除
													</button>
												}
												modal={true}
												contentStyle={{
													width: '357px',
													borderRadius: 5,
												}}
											>
												{(close) => (
													<div
														className={
															'box-voice-teller'
														}
													>
														<div
															className={
																'box-btn-record-setting'
															}
														>
															{this.renderPopupConfirm(
																close
															)}
														</div>
													</div>
												)}
											</Popup>
										</div>
									)}
								</div>
								<p className="note-warning-sample-voice">
									お客様に公開されるサンプルボイスです。自己紹介と一言メッセージを吹き込みましょう。
								</p>
							</dl>
							<audio
								controls
								className={'audio-listion-replay'}
								ref={(recordAudioItem) =>
									(this.recordAudioItem = recordAudioItem)
								}
								src={this.state.sampleVoicePath}
							/>
							{!isMobile && <div className='text_note'>※チャットや通話・プロフィール・タイムラインなどで個人のホームページやLINE、SNS(TwitterやFacebook)などの宣伝・誘導を行う事は、利用規約により固く禁じております。</div>}


							<div className="btn-wrap">
								<input
									type="button"
									value="プロフィールをプレビューする"
									style={styles.previewBtn}
									onClick={(e) =>
										this.handleBtnPreviewOnApp(e)
									}
									className="btn-raised color_default hidden-sp"
								// disabled={this.state.saveButton}
								/>
								<input
									type="button"
									value="プレビュー"
									style={styles.previewBtn}
									onClick={(e) =>
										this.handleBtnPreviewOnApp(e)
									}
									className="btn-raised color_default-sp input1"
								// disabled={this.state.saveButton}
								/>
								<input
									type="submit"
									value="登録"
									className="btn-raised color_default hidden-sp"
								// disabled={this.state.saveButton}
								/>
								<input
									type="submit"
									value="登録"
									className="btn-raised color_default-sp input2"
								// disabled={this.state.saveButton}
								/>
							</div>
						</form>
					</div>
					<Dialog
						title="プロフィール写真"
						actions={actions}
						modal={false}
						open={this.state.dialogOpen}
						onRequestClose={(s) => {
							this.handleClose(s)
						}}
						autoDetectWindowHeight={true}
						autoScrollBodyContent={true}
						bodyClassName="prof-dialog-wrap"
						contentClassName="avatar-dialog"
					>
						<div className="prof-dialog-wrap__subtitle hidden-sp">
							ご本人以外の写真の使用は禁止です。
						</div>
						<SettingProfileImage
							onClose={(s) => {
								this.handleClose(s)
							}}
							{...this.props}
							validateAvaPath={(file) =>
								this.validateAvaPath(file)
							}
						/>
					</Dialog>
					<Dialog
						title={false}
						contentStyle={styles.previewDialogStyle}
						autoScrollBodyContent
						actions={[
							<FlatButton
								label="OK"
								primary={true}
								keyboardFocused={true}
								onClick={() =>
									this.setState({ openPreviewDialog: false })
								}
							/>,
						]}
						modal={true}
						open={this.state.openPreviewDialog}
					>
						<PreviewProfile
							image={`${this.state.postImage || this.state.profileImage
								}`}
							name={this.state.tellerName}
							message={this.state.tellerMessage}
							genresChecked={
								this.state.genresDefaultChecked.length
									? this.state.genresDefaultChecked
									: this.state.genresChecked
							}
							methodsChecked={
								this.state.methodsDefaultChecked.length
									? this.state.methodsDefaultChecked
									: this.state.methodsChecked
							}
							stylesChecked={
								this.state.stylesDefaultChecked.length
									? this.state.stylesDefaultChecked
									: this.state.stylesChecked
							}
							profile={this.props.MyProfile.data}
							listAppealComments={this.state.listAppealComments}
						/>
					</Dialog>
					<Dialog
						style={{ paddingTop: 0 }}
						overlayStyle={{ paddingTop: 0 }}
						bodyStyle={{ padding: 0, margin: '0px 10px' }}
						repositionOnUpdate={false}
						autoDetectWindowHeight={false}
						modal={false}
						contentStyle={{
							width: '85%',
							transform: 'none',
							top: 40,
						}}
						open={this.state.showThumbnailProfile}
					>
						<ThumbnailProfile
							onClick={() =>
								this.setState({ showThumbnailProfile: false })
							}
							containerStyle={{ backgroundColor: '#fff' }}
						/>
					</Dialog>
				</div >
				<Snackbar
					open={this.state.error}
					message={this.state.message}
					autoHideDuration={5000}
					onRequestClose={(e) => this.handleErrorClose(e)}
					className="message-dialog"
				/>
				<Snackbar
					open={this.state.submitSuccess}
					message="正常に保存しました。審査完了をお待ちください。"
					autoHideDuration={5000}
					onRequestClose={(e) => this.handleSubmitClose(e)}
					className="message-dialog"
				/>
				<RequireSubmitChanges
					{...this.props}
					onClickCancel={() => this.onClickCancel()}
					onClickOk={() => this.onClickOk()}
					show={this.state.show}
					targetPathName={this.state.targetPathName}
				/>
			</div >
		)
	}
}

const styles = {
	previewDialogStyle: {
		width: 380,
		maxWidth: 'none',
	},
	previewBtn: {
		marginRight: 15,
	},
	topTitle: {
		marginBottom: 32,
	},
	statusTellerAvatar: {
		border: '2px solid rgba(236, 64, 122, 1)',
		borderRadius: 2,
		boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
		paddingTop: 19,
		paddingLeft: 19,
		paddingRight: 19,
		paddingBottom: 19,
		marginTop: 13,
		marginBottom: 7,
	},
	statusTellerAvatarPWA: {
		border: '2px solid rgba(236, 64, 122, 1)',
		borderRadius: 2,
		boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
		paddingTop: 19,
		paddingLeft: 19,
		paddingRight: 19,
		paddingBottom: 19,
		marginTop: 13,
		marginBottom: 7,
		textAlign: 'center',
	},
	titleStatusTellerAvatar: {
		fontFamily: 'Hiragino Kaku Gothic Pro',
		fontSize: 15,
		color: 'rgba(236, 64, 122, 1)',
		fontWeight: 'bold',
		marginBottom: 13,
	},
	solutionStatusTellerAvatar: {
		fontFamily: 'Hiragino Kaku Gothic Pro',
		fontSize: 15,
		color: 'rgba(33, 33, 33, 1)',
		textAlign: 'left',
	},
}

const textfieldStyles = {
	label: {
		bottom: -55,
		top: 'auto',
		right: -16,
	},
	hint: {
		top: 0,
		bottom: 'auto',
	},
	textarea: {
		marginTop: 0,
		marginBottom: 0,
		height: '100%',
	},
	block: {
		height: 44,
		width: '100%',
	},
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		File: state.File,
	}
}

export default connect(mapStateToProps)(Setting)
