import React, { Component } from 'react'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import StaffBlogList from './StaffBlogList'
import StaffBlogPager from './StaffBlogPager'
import $ from 'jquery'
import { StyledContainerHomeBlogList } from './Home/Styled/StyledHomeComponents'

export default class StaffBlog extends Component {
	constructor(props) {
		super(props)
		this.state = {
			total: 0,
			totalpages: 0,
			page: 1,
			category: '',
			data: {},
		}
	}

	componentWillMount() {
		const { category, page, size } = this.props
		this.getData({
			category: category,
			size: size,
			page: page,
		})
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('運営ブログ')
	}
	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
	}

	componentWillReceiveProps(nextProps) {
		const { category, page, size } = nextProps
		this.getData({
			category: category,
			size: size,
			page: page,
		})
	}

	/**
	 * 記事詳細を取得
	 */
	getData(res) {
		let params = {
			page: res.page,
			size: res.size === undefined ? 10 : res.size
		}
		
		if (res.category !== undefined && res.category) {
			params.categories = res.category
		}
		
		const url = glasConfig.url_base + glasConfig.get_lst_blog
		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		request(options)
			.then((response) => {
				this.setState({
					total: Math.ceil(response.data.data.total / params.size),
					totalpages: Math.ceil(response.data.data.total / params.size),
					page: params.page,
					category:
						params.categories === undefined
							? ''
							: params.categories,
					data: Object.keys(response.data).length
						? response.data.data.list
						: {},
				})
			})
			.catch((error) => {
				//throw error
			})
	}

	render() {
		const { exclude, pager } = this.props
		if (this.state.data.length) {
			return (
				<div>
					<StyledContainerHomeBlogList>
						<StaffBlogList
							data={this.state.data}
							exclude={exclude}
						/>
					</StyledContainerHomeBlogList>
					<StaffBlogPager
						pager={pager}
						page={this.state.page}
						totalpages={this.state.totalpages}
						category={this.state.category}
					/>
				</div>
			)
		}
		return null
	}
}
