import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'


export const TabPanel = (props) => {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && (
					<>{children}</>
			)}
		</div>
	)
}