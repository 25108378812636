import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
	glasConfig,
	katakanaList,
} from '../constants/Config'
import SettingMenu from './SettingMenu'
import RewardMenu from './RewardMenu'
import * as MyBankActions from '../actions/MyBankActions'
import $ from 'jquery'

/**
 * 口座情報確認
 */

class SettingBankAccountConfirm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bankCode:
				this.props.Bank.data.bankCode ||
				this.props.location.state.bankCode,
			branchCode:
				this.props.Bank.data.branchCode ||
				this.props.location.state.branchCode,
			accountNo:
				this.props.Bank.data.accountNo ||
				this.props.location.state.accountNo,
			accountName:
				this.props.Bank.data.accountName ||
				this.props.location.state.accountName,
			back: false,
		}
	}

	handleSubmit(event) {
		event.preventDefault()
		event.stopPropagation()

		let data = {
			bankcode: this.state.bankCode,
			sitencode: this.state.branchCode,
			accountNumber: this.state.accountNo,
			accountHolder: this.state.accountName,
		}

		const { dispatch } = this.props
		dispatch(MyBankActions.accountInput(data))
	}

	handleClick(event) {
		this.setState({
			back: true,
		})
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('銀行口座情報編集')

		const { dispatch } = this.props
		dispatch(MyBankActions.getBankCode(this.state.bankCode))
		dispatch(
			MyBankActions.getBranchCode(
				this.state.bankCode,
				this.state.branchCode
			)
		)
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
		$('.menu-header').removeClass('hiddenClass')
	}

	render() {
		if (this.state.back) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -4)
						.join('/')}/setting/bank/account/${this.state.bankCode
						}/${this.state.branchCode}`}
				/>
			)
		}
		if (this.props.Bank.regist) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -2)
						.join('/')}/top`}
				/>
			)
		}

		let bank_name = ''
		let branch_name = ''

		if (this.props.Bank.bank.length) {
			bank_name = this.props.Bank.bank[0].bank_name
		}

		if (this.props.Bank.branch.length) {
			branch_name = this.props.Bank.branch[0].siten_name
		}

		return (
			<div className="content bank-detail bank-confirm">
				<h1 className="content__title">設定</h1>
				{/* <SettingMenu /> */}
				<RewardMenu />
				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main">銀行口座情報</h1>
					</div>
					<div className="primary__inner">
						<div className="primary-content">
							<div className="bank-box margin_bottom hidden-mobile">
								<table className="table-th-left">
									<tbody>
										<tr>
											<th className="table-align-left">
												銀行名
											</th>
											<td className="table-align-left">
												{bank_name}
											</td>
										</tr>
										<tr>
											<th className="table-align-left">
												支店名
											</th>
											<td className="table-align-left">
												{branch_name}(支店コード：
												{this.state.branchCode})
											</td>
										</tr>
										<tr>
											<th className="table-align-left">
												口座種別
											</th>
											<td className="table-align-left">
												普通預金
											</td>
										</tr>
										<tr>
											<th className="table-align-left">
												口座番号
											</th>
											<td className="table-align-left">
												{this.state.accountNo}
											</td>
										</tr>
										<tr>
											<th className="table-align-left">
												口座名義
											</th>
											<td className="table-align-left">
												{this.state.accountName}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div className="primary-content__header display-mobile">
								<div className="pch__row">
									<div className="primary-content__header__left">
										銀行名
									</div>
									<div className="primary-content__header__right">
										{bank_name}
									</div>
								</div>
								<div className="pch__row">
									<div className="primary-content__header__left">
										支店名
									</div>
									<div className="primary-content__header__right">
										{branch_name}(支店コード：
										{this.state.branchCode})
									</div>
								</div>
								<div className="pch__row">
									<div className="primary-content__header__left">
										口座種別
									</div>
									<div className="primary-content__header__right">
										普通預金
									</div>
								</div>
								<div className="pch__row">
									<div className="primary-content__header__left">
										口座番号
									</div>
									<div className="primary-content__header__right">
										{this.state.accountNo}
									</div>
								</div>
								<div className="pch__row">
									<div className="primary-content__header__left">
										口座名義
									</div>
									<div className="primary-content__header__right">
										{this.state.accountName}
									</div>
								</div>
							</div>
							<div className="primary-content__body">
								<p className="margin-bottom-20">
									上記でお間違いなければ「登録」ボタンを押してください。
								</p>
								<form
									name="bankAccountForm"
									onSubmit={(e) => this.handleSubmit(e)}
								>
									<input
										type="button"
										className="btn-raised margin_right"
										value="戻る"
										onClick={(e) => this.handleClick(e)}
									/>
									<input
										type="submit"
										className="btn-raised color_default"
										value="登録"
									/>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps)(SettingBankAccountConfirm)
