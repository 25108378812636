import {
	FILE_UPLOAD_POST,
	FILE_UPLOAD_GET,
	FILE_UPLOAD_PUT,
	FILE_UPLOAD_DELETE,
	FILE_CONVERT_CANVAS_TO_IMAGE_GET,
} from '../constants/ActionTypes'

const initialState = {
	data: {},
}

export const File = (state = initialState, action) => {
	switch (action.type) {
		case FILE_UPLOAD_POST:
			return Object.assign({}, state, action.data)
		case FILE_UPLOAD_GET:
			return Object.assign({}, state, action.data)
		case FILE_UPLOAD_PUT:
			return Object.assign({}, state, {
				filePath: action.data.filePath,
			})
		case FILE_UPLOAD_DELETE:
			return Object.assign({}, state, {
				fileId: '',
				filePath: '',
			})
		case FILE_CONVERT_CANVAS_TO_IMAGE_GET:
			return Object.assign({}, state, action.data)
		default:
			return state
	}
}
