import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import StaffBlogHome from './StaffBlogHome'
import StaffBlogDetail from './StaffBlogDetail'

class RouteStaffBlog extends Component {
	render() {
		return (
			<Switch>
				<PrivateRoute
					exact
					path={`${this.props.match.path}/detail/:id`}
					component={StaffBlogDetail}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/:page/:category?`}
					component={StaffBlogHome}
				/>
			</Switch>
		)
	}
}

export default RouteStaffBlog
