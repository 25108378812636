import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import request from 'axios'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import AnswerCategories from './AnswerCategories'
import * as MyProfileActions from '../actions/MyProfileActions'
import AnswerMenu from './AnswerMenu'
import AnswerItem from './AnswerItem'
import {
	glasConfig,
	QuestionStatus,
	QuestionCategory,
	ROUTER_PAGE,
	MAX_SIZE_ANSWER_SHOW,
} from './../constants/Config'
import { isPWA } from '../helper/helpFunction'
import AnswerCategoriesWeb from './Answer/AwserCategoriesWeb'

class AnswerManagement extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageRecords: [],
			currentPage: 0,
			dataloaded: false,
			totalRecords: 0,
			listGoodAnswer: [],
			statusAnswer: localStorage.getItem('statusAnswer')
				? parseInt(localStorage.getItem('statusAnswer'))
				: 1,
			categoryAnswer: localStorage.getItem('categoryAnswer')
				? parseInt(localStorage.getItem('categoryAnswer'))
				: 0,
			sortByAnswer: localStorage.getItem('sortByAnswer')
				? parseInt(localStorage.getItem('sortByAnswer'))
				: 1,
			showTarget: false,
		}
	}

	componentWillMount() {
		this.getQuestions()
		const { dispatch } = this.props
		dispatch(AnswerManagementActions.getGoodAnswer())
	}

	componentWillReceiveProps(nextProps) {
		let dataloaded = true
		const { data, page } = nextProps.AnswerManagement
		this.setState({
			pageRecords: data.list,
			currentPage: page,
			totalRecords: data.totalRecord,
			dataloaded,
		})
	}

	showQuestions = () => {
		if (
			this.state.dataloaded &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<div>
					対応履歴がありません
				</div>

			)
		} else if (!this.state.dataloaded) {
			return (
				<div>
					対応履歴を読み込んでいます...
				</div>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return <AnswerItem item={current} key={index.toString()} />
			}
		)
	}

	handlePage(event, page) {
		event.preventDefault()
		this.getQuestions(page)
		window.scrollTo(0, 0)
	}

	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}

		let currentPage = parseInt(this.state.currentPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.answermanagement.answer
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0
		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1
		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = currentPage - showHalfPage
		let loopEnd = currentPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (currentPage !== 0) {
			firstPage = false
		}
		if (currentPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			lastPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li key={'first_page'} className="list__item">
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = currentPage > 0 ? currentPage - 1 : 0
			prevNav.push(
				<li key={'navigate_before-page'} className="list__item">
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = currentPage !== endPage ? currentPage + 1 : endPage
			nextNav.push(
				<li key={'navigate_next'} className="list__item">
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li key={'last_page'} className="list__item">
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === currentPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item" key={i}>
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className="primary__inner">
				<div className="pager">
					<ul className="list">
						{prevNav}
						{pageNav}
						{nextNav}
					</ul>
				</div>
			</div>
		)
	}

	createUrlQuery = (page) => {
		const { statusAnswer, categoryAnswer, sortByAnswer } = this.state
		let postPage = page || 0
		let params = []
		if (
			statusAnswer === QuestionStatus.ALL &&
			categoryAnswer === QuestionCategory.ALL
		) {
			params = ['tab=2', `page=${postPage}`, `sortBy=${sortByAnswer}`]
		} else if (statusAnswer === QuestionStatus.ALL) {
			params = [
				'tab=2',
				`page=${postPage}`,
				`sortBy=${sortByAnswer}`,
				`category=${categoryAnswer}`,
			]
		} else if (categoryAnswer === QuestionCategory.ALL) {
			params = [
				'tab=2',
				`page=${postPage}`,
				`sortBy=${sortByAnswer}`,
				`type=${statusAnswer}`,
			]
		} else {
			params = [
				'tab=2',
				`page=${postPage}`,
				`sortBy=${sortByAnswer}`,
				`category=${categoryAnswer}`,
				`type=${statusAnswer}`,
			]
		}

		return `?${params.join('&')}`
	}

	getQuestions = (page) => {
		const { dispatch } = this.props
		if (isPWA()) {
			dispatch(
				AnswerManagementActions.getAllQuestion(
					this.createUrlQuery(page),
					page
				)
			)
		} else {
			dispatch(
				AnswerManagementActions.getAllQuestionV2(
					this.createUrlQueryPC(page),
					page
				)
			)
		}
	}

	createUrlQueryPC = (page) => {
		const { categoryAnswer, showTarget } = this.state
		let postPage = page || 0
		let params = []
		if (categoryAnswer === QuestionCategory.ALL) {
			params = [
				'tab=all',
				`page=${postPage}`,
				`showTarget=${showTarget}`,
				`size=${MAX_SIZE_ANSWER_SHOW}`
			]
		} else {
			params = [
				'tab=all',
				`category=${categoryAnswer}`,
				`showTarget=${showTarget}`,
				`page=${postPage}`,
				`size=${MAX_SIZE_ANSWER_SHOW}`
			]
		}

		return `?${params.join('&')}`
	}

	changeConditionsSearchQuestion = (conditions) => {
		localStorage.setItem('statusAnswer', conditions.statusAnswer)
		localStorage.setItem('categoryAnswer', conditions.categoryAnswer)
		localStorage.setItem('sortByAnswer', conditions.sortByAnswer)
		this.setState(
			{
				statusAnswer: conditions.statusAnswer,
				categoryAnswer: conditions.categoryAnswer,
				sortByAnswer: conditions.sortByAnswer,
			},
			() => {
				this.getQuestions()
			}
		)
	}

	changeConditionsSearchQuestionPC = (conditions) => {
		localStorage.setItem('categoryAnswer', conditions.categoryAnswer)
		this.setState(
			{
				categoryAnswer: conditions.categoryAnswer,
				showTarget: conditions.showTarget
			},
			() => {
				this.getQuestions()
			}
		)
	}

	readAnswerWasLiked = (textId) => {
		request
			.put(
				glasConfig.url_base + glasConfig.get_view_best_answer + `?textId=${textId}`, null,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				// if (response.data.code === 0) {
				// 	this.setState({listGoodAnswer:response.data.data})
				// }
			})
			.catch((error) => {
				throw error
			})

	}

	updateShowBestAnswer() {
		request
			.put(
				glasConfig.url_base + glasConfig.update_shown_best_anwser_text, null,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.props.history.push(ROUTER_PAGE.PAGE_BEST_ANSWER)
				}
			})
			.catch((error) => {
				throw error
			})
	}

	openWelcomeAnswer = () => {
		this.props.dispatch(AnswerManagementActions.openDialogWelComeAnswer())
	}

	render() {
		const { AnswerManagement, MyProfile } = this.props
		const showPopUpBestAnswer = MyProfile.data.isShowBestAnswerText
		const lastNotificationLikeAnswer = AnswerManagement.goodAnswer.find(s => s.isClickViewText === false)

		return (
			<div className="content">
				<h1 className="content__title hidden-sp">
					教えて！先生
					<i
						className="material-icons content__icon-welcome-answer"
						onClick={this.openWelcomeAnswer}
					>
						help_outline
					</i>
				</h1>
				<div>
					{(AnswerManagement.showPopUpBestAnswer ||
						showPopUpBestAnswer) && (
							<div className="popUpBestAnswer" id="hidden-popup__best-awswer">
								<div onClick={() => this.updateShowBestAnswer()}>
									<span className="titlePopUpBestAnswer" id="popup-bestanswer__mobile">
										あなたの回答がベストアンサーに選ばれました！
									</span>
									<button className="buttonBestAnswer hidden-sp">
										報酬を受け取る
									</button>
								</div>
							</div>
						)}
					{
						lastNotificationLikeAnswer !== undefined &&
						<div
							className="popUpBestAnswer" id="hidden-popup__best-awswer"
							onClick={() => this.readAnswerWasLiked(lastNotificationLikeAnswer.textId)}
						>
							<Link
								to={`/home/answer/detail/${lastNotificationLikeAnswer.questionId}`}
							>
								<span className="titlePopUpBestAnswer" id="popup-bestanswer__mobile">
									あなたの回答にGoodがつきました！
								</span>
							</Link>
							<Link
								to={`/home/answer/detail/${lastNotificationLikeAnswer.questionId}`}
							>
								<button className="buttonBestAnswer hidden-sp">
									回答を見に行く
								</button>
							</Link>
						</div>
					}
				</div>
				<AnswerMenu />
				{isPWA() ?
					<AnswerCategories
						changeConditionsSearchQuestion={
							this.changeConditionsSearchQuestion
						}
					/>
					:
					<AnswerCategoriesWeb
						changeConditionsSearchQuestionPC={
							this.changeConditionsSearchQuestionPC
						}
					/>
				}
				{this.showQuestions()}
				{this.setPager()}
				<div
					className="content__text-welcome-answer hidden-pc"
					onClick={this.openWelcomeAnswer}
				>
					教えて！先生とは？
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
		MyProfile: store.MyProfile
	}
}

export default connect(mapStateToProps)(AnswerManagement)
