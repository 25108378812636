import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import * as TemplateActions from '../actions/TemplateActions'
import * as Fetch from '../util/Fetch'
import SettingMenu from './SettingMenu'
import SettingTemplate from './SettingTemplate'
import { ModalSettingTemplate } from './Modals/Setting/ModalSettingTemplate'
import { isPWA } from '../helper/helpFunction'

class SettingTemplates extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isShowPopupTemplate: false
		}
		this.resetTogglePopUpTemplate = this.resetTogglePopUpTemplate.bind(this)
	}

	componentWillMount() {
		const { dispatch } = this.props
		const tellerId = Fetch.tellerId()

		// テンプレート取得
		dispatch(TemplateActions.getTemplate(tellerId))
	}

	showPopupTemplate() {
		this.setState({
			isShowPopupTemplate: true
		})
	}

	resetTogglePopUpTemplate() {
		this.setState({
			isShowPopupTemplate: false
		})
	}

	render() {
		const { Template } = this.props

		let formNo = 0
		let templates = []
		if (Object.keys(Template.data).length) {
			templates = Template.data.map((template) => {
				formNo++
				return (
					<SettingTemplate
						key={`formTemplate${formNo}`}
						formNo={formNo}
						templateTitle={template.templateTitle}
						templateContent={template.templateContent}
						templateId={template.templateId}
					/>
				)
			})
		}
		if (glasConfig.template.template > Object.keys(Template.data).length) {
			let start = Object.keys(Template.data).length
			for (start; start < glasConfig.template.template; start++) {
				formNo++
				templates.push(
					<SettingTemplate
						key={`formTemplate${formNo}`}
						formNo={formNo}
						templateTitle={''}
						templateContent={''}
						templateId={''}
					/>
				)
			}
		}

		return (
				<>
					<ModalSettingTemplate 
						isShow={this.state.isShowPopupTemplate} 
						resetToggle={this.resetTogglePopUpTemplate}
					/>
				
					<div className="content">
						<h1 className="content__title">設定</h1>
						<SettingMenu />
						<div className="primary">
							<div className="primary-title primary-title-template">
								<h1 className="primary-title__main hidden-sp">
									テンプレート
								</h1>
								<p className="primary-title__description primary-title__description-template">
									チャット相談の際に仕様できる、メッセージテンプレートを編集できます。
									<br />
									テンプレートの内容は無料で送信されます。
								</p>
								{ !isPWA() &&
									<div className='link-appeal__mesage'>
										<span onClick={() => this.showPopupTemplate()}>＞テンプレートのサンプルを見る</span>
									</div>
								}
							</div>
							<div className="primary__inner_template">{templates}</div>
						</div>
					</div>
				</>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		Template: state.Template,
	}
}

export default connect(mapStateToProps)(SettingTemplates)
