import React, { Component } from 'react'

class SelectBox extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selected: 0,
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.defaultValue !== this.state.selected) {
			this.setState({
				selected: nextProps.defaultValue,
			})
		}
		return true
	}

	changeValue = (event) => {
		let value = event.target.value
		this.setState({
			selected: value,
		})
		this.props.onData(value)
	}

	render() {
		return (
			<div className="form-inline-row-CM">
				{this.props.labelHidden ? null : (
					<label className="label-text-92797">購入履歴</label>
				)}
				<select
					className={'select-line-botoom-92797'}
					onChange={(event) => this.changeValue(event)}
					value={this.state.selected}
				>
					<option value={''}>すべて</option>
					<option value={1}>有</option>
					<option value={0}>なし</option>
				</select>
			</div>
		)
	}
}

export default SelectBox
