import React from 'react'
import { Link } from 'react-router-dom'
import * as moment from 'moment'
import * as Fetch from '../util/Fetch'
import {
	StyledCategoryItem,
	StyledCategoryList,
} from './Blog/Styled/StyledBlogComponent'

const StaffBlogList = ({ data, exclude }) => {
	return Object.values(data).map((res) => {
		if (exclude === res.blog_id) {
			return null
		}

		const backGroundImage = res.featured_image
		const categoryData = res.categories

		return (
			<div key={`staffblog_${res.blog_id}`} className="list__item">
				<div className="card" style={{ height: '100%', paddingTop: 5 }}>
					<Link
						className="card-main"
						to={`/home/staffblog/detail/${res.blog_id}`}
					>
						<figure className="card-main__figure">
							<img
								src={'/img/spacer.gif'}
								style={{
									backgroundImage: backGroundImage ? `url(${backGroundImage})` : "",
									backgroundSize: 'contain',
								}}
								alt={`staffblog_${res.blog_id}`}
							/>
						</figure>
						<div className="card-main__inner">
							<h3 className="card-main__title">
								{res.title}
							</h3>
							<p className="card-main__time">
								{moment(res.created_time, 'YYYYMMDDHHmmss').format('YYYY/MM/DD HH:mm')}
							</p>
						</div>
					</Link>
					<StyledCategoryList>
						{/* <ul className="list"> */}
						{categoryData
							? categoryData.map((categoryVal) => {
								return (
									<StyledCategoryItem
										key={`staffblog_${res.id}_${categoryVal.category_id}`}
										className="list__item"
									>
										<Link
											to={`/home/staffblog/1/${categoryVal.category_id}`}
										>
											{categoryVal.category_name}
										</Link>
									</StyledCategoryItem>
								)
							})
							: ''}
						{/* </ul> */}
					</StyledCategoryList>
				</div>
			</div>
		)
	})
}

export default StaffBlogList
