/**
 * アラート系のダイアログ
 */
import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

export default class DialogRequirePhoneNumber extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			ridirect: false,
		}
	}

	componentWillMount() {
		const { show } = this.props
		this.setState({
			show: show,
		})
	}

	render() {
		const { closeFunc, data } = this.props

		if (!this.state.show) {
			return null
		}

		const handleClose = () => {
			closeFunc()
		}

		const message = data.message ? data.message : ''

		const actions = [
			<FlatButton
				label="キャンセル"
				primary={true}
				keyboardFocused={true}
				onClick={(e) => handleClose()}
			/>,
			<FlatButton
				href="/home/setting/account"
				label="電話番号を登録"
				primary={true}
				keyboardFocused={true}
				onClick={(e) => handleClose()}
			/>,
		]

		return (
			<div>
				<Dialog
					title="電話番号登録"
					modal={false}
					actions={actions}
					open={this.state.show}
					onRequestClose={(e) => handleClose()}
				>
					<div>{message}</div>
				</Dialog>
			</div>
		)
	}
}
