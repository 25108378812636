import React from 'react'
import Dialog from 'material-ui/Dialog'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const ModalMissingAnswer = (props) => {
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow(props.show)
	}, [props.show])

	const closeDialog = () => setShow(true)

	const onCancel = () => {
		closeDialog()
		props.resetToggle()
	}

	return (
		<div>
			<Dialog
				modal={false}
				open={show}
				bodyClassName="modal-missing__profile"
				contentClassName="modal-missing__profile-paper"
			>
				<i
					className="material-icons modal-missing__profile-close__icon"
					onClick={onCancel}
				>
					close
				</i>
				<div className="modal-missing__profile-title">
					プロフィール設定
				</div>
				<div className="modal-missing__profile-content">
					お仕事を開始するには「プロフィール情報の登録」 および「プロフィール画像の認証」を完了させてください。
				</div>
				<div className="modal-missing__profile-buttons">
					<input
						type="button"
						value="キャンセル"
						className="modal-missing__profile-button__cancel"
						onClick={onCancel}
					/>
					<a
						className="modal-missing__profile-button__redirect"
						href="/home/setting"
					>
						プロフィール設定へ
					</a>
				</div>
			</Dialog>
		</div>
	)
}
