/**
 * チャット申し込みダイアログ
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Dialog from 'material-ui/Dialog'
import {
	DetailAnswerInChat,
	StellaAnswerWithMyAnswer,
} from '../../../service/api/ApiService'
import { categoryAnswer } from '../../../helper/helpFunction'
import moment from 'moment'

class DialogDetailAnswer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			detailAnswerUser: null,
			detailAnswerTeller: null,
		}
	}

	_onOk = () => {
		const { closeFunc } = this.props
		closeFunc()
	}

	_onClose = () => {
		let { closeFunc } = this.props
		closeFunc()
	}

	componentDidMount = async () => {
		let dataNewAnswerUser = null
		let dataNewAnswerTeller = null
		if (this.props.data !== undefined && this.props.data !== null) {
			dataNewAnswerUser = this.props.data
			let params = { questionId: dataNewAnswerUser.questionId }
			await DetailAnswerInChat(params).then(function (res) {
				if (res.code !== undefined && res.code === 0) {
					dataNewAnswerTeller = res.data
				}
			})
		}
		this.setState({
			detailAnswerUser: dataNewAnswerUser,
			detailAnswerTeller: dataNewAnswerTeller,
		})
	}

	render() {
		const { show, data, closeFunc } = this.props

		if (!show || this.state.detailAnswerUser === null) {
			return null
		}

		return (
			<div>
				<Dialog
					modal={false}
					open={show}
					bodyClassName="dialog-content-body-answer"
					contentClassName="dialog-content-content-answer"
					onRequestClose={closeFunc}
				>
					<div className={'box-detail-answer-chat'}>
						<div className={'header-dialog-detail-answer'}>
							<div className={'left-header-dialog'}>
								教えて！先生
							</div>
							<div className={'right-header-dialog'}>
								<span onClick={() => this._onClose()}>X</span>
							</div>
						</div>
						<div className={'box-content-detail-answer'}>
							<div className={'row-first-detail-answer'}>
								<div className={'category-detail-answer'}>
									<span
										className={
											'category-item-detail-answer'
										}
									>
										{categoryAnswer(
											this.state.detailAnswerUser.category
										)}
									</span>
								</div>
								<div className={'date-detail-answer'}>
									{moment(
										this.state.detailAnswerUser.createTime
									).format('YYYY/MM/DD hh:mm')}
								</div>
							</div>
							<div className={'row-body-detail-answer-user'}>
								<pre>{this.state.detailAnswerUser.content}</pre>
							</div>
							<div className={'title-answer-teller'}>
								あなたの回答
							</div>
							<div className={'row-body-detail-answer-teller'}>
								{this.state.detailAnswerTeller !== null
									? this.state.detailAnswerTeller
											.answerContent
									: null}
							</div>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(DialogDetailAnswer)
