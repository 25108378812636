import styled from 'styled-components'
import { AVATAR_BY_GENDER } from '../../../constants/CallConstants'
import { colors, fonts, DEVICE } from '../../../util/StellaConstant'

/*
    @media ${DEVICE.mobileS} {

    }
    @media ${DEVICE.tablet} {

    }
    @media ${DEVICE.laptop} {

    }
*/

export const BottomBar = styled.div`
	@media ${DEVICE.mobileS} {
		display: flex;
		background-color: ${colors.white};
		padding: 26px 43px 26px 36px;
		justify-content: space-between;
		align-items: center;
		height: 100%;
		flex-direction: column;
	}
	@media ${DEVICE.tablet} {
		display: flex;
		background-color: ${colors.white};
		padding: 26px 43px 26px 36px;
		justify-content: space-between;
		align-items: center;
		height: auto;
		flex-direction: row;
	}
	@media ${DEVICE.laptop} {
		display: flex;
		background-color: ${colors.white};
		padding: 26px 43px 26px 36px;
		justify-content: space-between;
		align-items: center;
		height: auto;
		flex-direction: row;
	}
`

export const LeftBottomBar = styled.div`
	@media ${DEVICE.mobileS} {
		display: grid;
		grid-template-areas:
			'avatar'
			'name'
			'time';
		grid-auto-rows: min-content;
		grid-gap: 3px 21px;
		justify-items: center;
		margin-top: 62px;
	}
	@media ${DEVICE.tablet} {
		display: grid;
		grid-template-areas:
			'avatar name'
			'avatar time'
			'avatar remaining';
		grid-auto-rows: min-content;
		grid-gap: 3px 21px;
		justify-items: left;
		margin-top: 0px;
	}
	@media ${DEVICE.laptop} {
		display: grid;
		grid-template-areas:
			'avatar name'
			'avatar time'
			'remaining';
		grid-auto-rows: min-content;
		grid-gap: 3px 21px;
		justify-items: left;
		margin-top: 0px;
	}
`

export const RightBottomBar = styled.div`
	@media ${DEVICE.mobileS} {
		display: flex;
		flex-direction: row;
	}
	@media ${DEVICE.tablet} {
		display: flex;
		flex-direction: row;
	}
	@media ${DEVICE.laptop} {
		display: flex;
		flex-direction: row;
	}
`

export const UserAvatar = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: avatar;
		background-image: url(${(props) => AVATAR_BY_GENDER[props.userGender]});
		background-size: cover;
		width: 128px;
		height: 128px;
		margin-bottom: 40px;
	}
	@media ${DEVICE.tablet} {
		grid-area: avatar;
		background-image: url(${(props) => AVATAR_BY_GENDER[props.userGender]});
		background-size: cover;
		width: 90px;
		height: 90px;
		margin-bottom: 0px;
	}
	@media ${DEVICE.laptop} {
		grid-area: avatar;
		background-image: url(${(props) => AVATAR_BY_GENDER[props.userGender]});
		background-size: cover;
		width: 90px;
		height: 90px;
		margin-bottom: 0px;
	}
`

export const UserNameBottom = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: name;
		font-family: ${fonts.HiraKakuProW6};
		font-size: 20px;
		font-weight: bold;
		color: ${colors.blackGray};
		align-self: flex-end;
		margin-bottom: 16px;
	}
	@media ${DEVICE.tablet} {
		grid-area: name;
		font-family: ${fonts.HiraKakuProW6};
		font-size: 20px;
		font-weight: bold;
		color: ${colors.blackGray};
		align-self: flex-end;
		margin-bottom: 0px;
	}
	@media ${DEVICE.laptop} {
		grid-area: name;
		font-family: ${fonts.HiraKakuProW6};
		font-size: 20px;
		font-weight: bold;
		color: ${colors.blackGray};
		align-self: flex-end;
		margin-bottom: 0px;
	}
`

export const TimeOfCall = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: time;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 16px;
		color: ${colors.blackGray};
	}
	@media ${DEVICE.tablet} {
		grid-area: time;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 16px;
		color: ${colors.blackGray};
	}
	@media ${DEVICE.laptop} {
		grid-area: time;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 16px;
		color: ${colors.blackGray};
	}
`
export const RemainingTime = styled.div`
	@media ${DEVICE.mobileS} {
		grid-area: remaining;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 13px;
		color: ${colors.accent};
	}
	@media ${DEVICE.tablet} {
		grid-area: remaining;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 13px;
		color: ${colors.accent};
	}
	@media ${DEVICE.laptop} {
		grid-area: remaining;
		font-family: ${fonts.HiraKakuProW3};
		font-size: 13px;
		color: ${colors.accent};
	}
`

export const ContainerIconButton = styled.div`
	@media ${DEVICE.mobileS} {
		width: 64px;
		height: 64px;
		border: 1px solid
			${(props) =>
				props.buttonType === 'ENDCALL'
					? colors.pasterRed
					: colors.pasterPurple};
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: ${(props) =>
			props.buttonType === 'ENDCALL' ? '0 28px 67px' : '0 0 67px'};
	}
	@media ${DEVICE.tablet} {
		width: 64px;
		height: 64px;
		border: 1px solid
			${(props) =>
				props.buttonType === 'ENDCALL'
					? colors.pasterRed
					: colors.pasterPurple};
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: ${(props) => (props.buttonType === 'ENDCALL' ? '0 28px' : '0')};
	}
	@media ${DEVICE.laptop} {
		width: 64px;
		height: 64px;
		border: 1px solid
			${(props) =>
				props.buttonType === 'ENDCALL'
					? colors.pasterRed
					: colors.pasterPurple};
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: ${(props) => (props.buttonType === 'ENDCALL' ? '0 28px' : '0')};
	}
`
