import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export default (props) => {
	let pathName = window.location.pathname

	let manage =
		'btn-tab' + (pathName === '/home/introduction' ? ' is-active' : '')
	let registration =
		'btn-tab' +
		(pathName.indexOf('/home/introduction/registration') !== -1
			? ' is-active'
			: '')
	let introducedCustomer =
		'btn-tab' +
		(pathName.indexOf('/home/introduction/customer') !== -1
			? ' is-active'
			: '')

	return (
		<nav className="lead-nav">
			<ul className="list lead_nav list-customer tab-intro">
				<li className="list__item">
					<Link className={manage} to="/home/introduction">
						鑑定士を紹介する
					</Link>
				</li>
				<li className="list__item">
					<Link className={introducedCustomer} to="/home/introduction/customer">
						お客様を紹介する
					</Link>
				</li>
				{props.isIntroduced && (
					<li className="list__item">
						<Link
							className={registration}
							to="/home/introduction/registration"
						>
							紹介での登録
						</Link>
					</li>
				)}
			</ul>
		</nav>
	)
}
