import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'

export default class DialogCantBlock extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			funcAccept: null,
		}
	}

	acceptReply = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				onRequestClose={this.closeDialog}
				className="dialogReplyConfirm dialogCantBlock"
			>
				<i
					class="material-icons tab__icons closeUnBlock"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<p
					style={{
						fontSize: 16,
						marginTop: -16,
						color: 'rgba(0, 0, 0, 0.87)',
					}}
				>
					現在、鑑定中の為ブロックする事ができません。
				</p>
				<p style={{ fontSize: 16, color: 'rgba(0, 0, 0, 0.87)' }}>
					ブロックするには、まず鑑定を終了してください。
				</p>
				<button className="submitCantBlock" onClick={this.closeDialog}>
					OK
				</button>
			</Dialog>
		)
	}
}
