import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import request from 'axios'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import DialogConfirmPayoff from '../components/Modals/ModalConfirmPayoff/Dialogs/DialogConfirmPayoff'
import RewardInfo from './Reward/RewardInfo'
export default class RewardRegistForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			registflg: 0, // 0:申請なし、1:申請あり
			transferdate: '',
			registdate: '',
			openDialogPayoff: false,
			bankaccount: 0, // 0:銀行情報なし、1:あり
			commission: 0, // 精算手数料
			bankminpay: Fetch.checkChangeFeeDate()
				? glasConfig.payoff_min_new
				: glasConfig.payoff_min, // 銀行振込による精算の最低金額
			reward: 0,
			taxsalary: 0,
			pointtransfer: 0,
			isinvoice: false,
			is_regis_invoice: false,
			point: 0
		}
	}

	componentWillMount() {
		const { MyProfile } = this.props
		this.setState({
			point: MyProfile.data.point
		})
		this.comfirmRegist()
	}

	/**
	 * 精算の確認
	 */
	comfirmRegist() {
		const url = glasConfig.url_outside + glasConfig.path_payment
		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({
						transferdate: response.data.data.transferdate,
						registflg: response.data.data.registflg,
						bankaccount: response.data.data.bankaccount,
						commission: response.data.data.commission,
						bankminpay: response.data.data.bankminpay,
						reward: response.data.data.reward,
						taxsalary: response.data.data.taxsalary,
						pointtransfer: response.data.data.pointtransfer,
						isinvoice: response.data.data.isinvoice,
						is_regis_invoice: response.data.data.is_regis_invoice,
					})
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	/**
	 * 精算の申請
	 */
	handlerRegist() {
		const { MyProfile } = this.props

		const url = glasConfig.url_outside + glasConfig.path_payment
		const params = {
			point: MyProfile.data.point,
		}
		const options = {
			method: 'PUT',
			url: url,
			data: params,
			headers: {
				Authorization: localStorage.getItem('token'),
				'Content-Type': 'application/json',
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ registflg: 1 })

					const tellerId = Fetch.tellerId()
					request.put(
						glasConfig.url_base +
						glasConfig.update_mission_introduce,
						{
							missionType: 'REQUEST_PAYOFF',
							tellerId,
						},
						{
							headers: {
								Authorization: localStorage.getItem('token'),
							},
						}
					)
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	/**
	 * 精算申請可能かの判定
	 * return {boolean} true:可能
	 */
	chkRegist() {
		const { MyProfile } = this.props
		if (
			this.state.bankaccount === 1 &&
			this.state.registflg === 0 &&
			this.state.bankminpay <= MyProfile.data.point
		) {
			return true
		}
		return false
	}

	submitButtonPayOff() {
		const { MyProfile } = this.props
		this.setState({
			point: MyProfile.data.point
		})
		this.comfirmRegist()
		this.setState({ openDialogPayoff: true })
	}

	render() {
		const { MyProfile } = this.props

		const point = Math.floor(this.state.point)

		let ClassCheckOut = 'checkout-box'
		if (this.state.registflg === 1) {
			ClassCheckOut = ClassCheckOut + ' checkout_done'
		} else {
			if (this.chkRegist()) {
				ClassCheckOut = ClassCheckOut + ' checkout_ok'
			} else {
				ClassCheckOut = ClassCheckOut + ' checkout_ng'
			}
		}

		return (
			<div className="checkout-box-wrap">
				<div className={ClassCheckOut}>
					{this.state.registflg === 1 ? (
						<div>
							<p className="checkout-box__title">精算申請完了</p>
							<p className="checkout-box__text">
								精算申請が完了しました
								<br />
								お振込予定日は
								<strong className="checkout-box__emphasis">
									{this.state.transferdate}
								</strong>
								です
								<br />
								今しばらくお待ちくださいませ
							</p>
						</div>
					) : (
						<div id="padding-table-payoff">
							<table className="table-payoff">
								<tbody>
									<tr >
										<th >
											精算申請ポイント
										</th>
										<td >
											{Fetch.formatNumber(point)}PT
										</td>
									</tr>
									<tr >
										<th>
											・基本報酬
										</th>
										<td>
											{Fetch.formatNumber(this.state.reward)}円
										</td>
									</tr>
									<tr >
										<th id="height-table">
											・消費税
										</th>
										<td id="height-table">
											{Fetch.formatNumber(this.state.taxsalary)}円
										</td>
									</tr>
									<tr>
										<th>
											・振込手数料
										</th>
										<td>
											-{Fetch.formatNumber(this.state.commission)}円
										</td>
									</tr>
									<tr >
										<th >
											{this.state.isinvoice ? "支払い予定額(税込)" : "支払い予定額(税抜)"}
										</th>
										<td >
											{Fetch.formatNumber(this.state.pointtransfer)}
											円
										</td>
									</tr>
								</tbody>
							</table>
							<p className="checkout-box__text" id="payoff-margin__bottom">
								<CheckOutext
									MyProfile={MyProfile}
									data={this.state}
								/>
							</p>
							<div className="btn-wrap">
								{this.state.bankaccount === 0 ? (
									<Link
										className="btn-raised color_default"
										to="/home/setting/bank/top"
									>
										銀行口座を登録する
									</Link>
								) : (
									<button
										className="btn-raised color_default"
										disabled={!this.chkRegist()}
										// onClick={() => this.handlerRegist()}
										onClick={() => this.submitButtonPayOff()}
									>
										精算する
									</button>
								)}
							</div>
							<DialogConfirmPayoff
								openDialogPayoff={this.state.openDialogPayoff}
								closeDialogPayoff={() => this.setState({ openDialogPayoff: false })}
								transferAmount={Fetch.formatNumber(this.state.pointtransfer)}
								bankaccount={this.state.bankaccount}
								checkRegist={this.chkRegist()}
								myProfile={MyProfile}
								handleRegist={() => this.handlerRegist()}
							/>
						</div>
					)}
				</div>
				<RewardInfo commission={this.state.commission} is_regis_invoice={this.state.is_regis_invoice} />
			</div>

		)
	}
}

const CheckOutext = ({ MyProfile, data }) => {
	const restPoint = data.bankminpay - MyProfile.data.point
	if (data.bankaccount === 0) {
		return (
			<strong className="checkout-box__emphasis">
				お振込先が未登録のため精算できません
			</strong>
		)
	} else {
		if (data.registflg === 0) {
			if (data.bankminpay <= MyProfile.data.point) {
				return (
					<span>
						ただいま精算申請されますと
						<br />
						お振込予定日は{' '}
						<strong className="checkout-box__emphasis">
							{data.transferdate}
						</strong>{' '}
						となります
					</span>
				)
			} else {
				return (
					<strong className="checkout-box__emphasis">
						精算可能額まであと
						<br />
						{Fetch.formatNumber(
							Number.isInteger(restPoint)
								? restPoint
								: restPoint.toFixed(2)
						)}
						円（
						{Fetch.formatNumber(
							Number.isInteger(restPoint)
								? restPoint
								: restPoint.toFixed(2)
						)}
						PT）必要です
					</strong>
				)
			}
		}
	}
}
