import React, { useState } from 'react'
import Dropdown from 'react-dropdown'
import { isAndroid,isMobile } from 'react-device-detect'
import Checkbox from 'material-ui/Checkbox'
import Snackbar from 'material-ui/Snackbar'

const CHECKED_YES = 1
const CHECKED_NO = 0
const EnvBug = [
	{ value: 0, label: '選択してください' },
	{ value: 1, label: 'iOSアプリ版' },
	{ value: 2, label: 'Andoridアプリ版' },
	{ value: 3, label: 'PC版（ブラウザ：Google Chrome)' },
	{ value: 4, label: 'PC版（ブラウザ：safari)' },
	{ value: 5, label: 'PC版（ブラウザ：その他)' },
	{ value: 6, label: 'スマホブラウザ版（iOS)' },
	{ value: 7, label: 'スマホブラウザ版（Android)' },
]
const OtherEnvBug = [
	{ value: 0, label: '選択してください' },
	{ value: 1, label: 'iOSアプリ版' },
	{ value: 2, label: 'Andoridアプリ版' },
	{ value: 3, label: 'PC版' },
	{ value: 4, label: 'スマホブラウザ版' },
]

const defaultOption1 = EnvBug[0]
const defaultOption2 = OtherEnvBug[0]


function FormBug(props) {
	const [image, setImage] = useState({
		error: false,
		message: '',
		image: null,
	})

	const [data, setData] = useState({
		envBug: {value:0,label:defaultOption1.label},
		otherEnvBug: {value:0,label:defaultOption2.label},
		content: '',
		file: null,
		checked: CHECKED_YES
	})

	const [err,setErr]=useState({
		envBug:false,
		content:false
	})

	const onChange = (name, value) => {
		setData({
			...data,
			[name]: value
		})
	}

	const getImageData = (file) => {
		// image/gif   
		let fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		fileReader.onload = (ev) => {
			setImage({
				error: false,
				message: '',
				image: ev.target.result,
			})
		}
	}
	const selectFile = (event) => {
		let fileType =event.target.files[0].type
		console.log('fileType',event.target.files[0])
		if(fileType==='image/png' || fileType==='image/jpeg' || fileType==='image/jpg' || fileType==='image/gif'){
			getImageData(event.target.files[0])
			setData({ ...data, file: event.target.files[0] })
		}else{
			setImage({error:true})
		}
	}

	const onAccept=()=>{
		let envBug=false
		let content=false
		if(data.envBug.value==0){
			envBug=true
		}else{
			envBug=false
		}

		if(data.content.replace(/\s/g, '').length===0){
			content=true
		}else{
			content=false
		}

		setErr({envBug,content})

		if(!content && !envBug){
			props.onChangeStepCategory(1,{...data,image:image.image})
		}
	}

	return (
		<div>
			<p className="titleTextField">症状が発生したご利用の環境</p>
			<Dropdown
				className="dropdown-contact"
				options={EnvBug} onChange={(e) => onChange('envBug', e)} value={defaultOption1} placeholder="Select an option" />
		    {
				err.envBug &&
                <p className="errMsgContact">
                    環境を選択してください
                </p>
			}

			<p className="titleTextField">他で兼用している環境があればお答えください（任意）</p>
			<Dropdown
				className="dropdown-contact"
				options={OtherEnvBug} onChange={(e) => onChange('otherEnvBug', e)} value={defaultOption2} placeholder="Select an option" />


			<p className="titleTextField">不具合の詳細（症状・日時・お客様のお名前など具体的にお書きください）</p>
			<textarea
				className="inputCus inputContact"
				onChange={(e) => onChange('content', e.target.value)}
				placeholder="例：3月9日に山田ステラ様からチャット相談の申し込みがあり、「チャット開始」ボタンを押すと、画面が真っ白になる"
				value={data.content}
			/>
			{
				err.content&&
                <p className="errMsgContact">
                	お問い合わせ内容を入力してください
			    </p>
			}
			<p className="titleTextField" style={{marginBottom:0,marginTop:30}}>不具合時のスクリーンショット(任意) </p>
			<div className="fileContact">
				<div className="btn-wrap btnFile">
					<label className="btn-raised color_white">
						<p className="btn-raised__text">
                            ファイルを選択
						</p>
						<input
							type="file"
							className="btn-raised__file inputChooseFile"
							accept="image/png, image/jpeg, image/jpg, image/gif"
							onChange={(e) => selectFile(e)}
						/>
					</label>
				</div>
				{
					isMobile ?
					<>
						{image.image && (
							<img
								src={image.image}
								className="imageContact"
							/>
						)}
							<p className="txtFileContact">
								{image.image
									? '写真1枚'
									: 'ファイル未選択'}
							</p>
					</>
						:
					<>
						<p className="txtFileContact">
							{image.image
								? '写真1枚'
								: 'ファイル未選択'}
						</p>
						{image.image && (
							<img
								src={image.image}
								className="imageContact"
							/>
						)}
					</>
				}
			</div>
			<p className="text-message-contact">
                ※症状がわかる画面のスクリーンショットをいただくと調査がスムーズに行えますので、撮影が可能な場合はご協力いただけると幸いです。
			</p>

			<p className="titleTextField">返信の有無</p>
			<div
				className={
					isAndroid
						? 'checkBoxContact checkBoxContactAndroid'
						: 'checkBoxContact checkBoxContactIOS'
				}
			>
				<Checkbox
					label="必要"
					onCheck={() => onChange('checked', CHECKED_YES)}
					checked={data.checked === CHECKED_YES}
					style={{ color: '#c7bfbf' }}
					// iconStyle={{fill:'rgba(17, 173, 157, 1)' }}
					labelStyle={{fontWeight: 'normal' }}
				/>
				<Checkbox
					label="不要"
					onCheck={() => onChange('checked', CHECKED_NO)}
					checked={data.checked === CHECKED_NO}
					style={{ color: '#c7bfbf' }}
					// iconStyle={{fill:'rgba(17, 173, 157, 1)' }}
					labelStyle={{fontWeight: 'normal' }}
				/>
			</div>
			<button
				onClick={onAccept}
				className="submitContact"
			>
                内容確認
			</button>
			<Snackbar
				open={image.error}
				message={'メッセージを送信できません。このユーザーが存在していません。'}
				autoHideDuration={2500}
				onRequestClose={e => setImage({...image,error:false})}
			/>
		</div>
	)
}

export default FormBug