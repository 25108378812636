import React, { Component } from 'react'
import { connect } from 'react-redux'
import { StellaAnswerWithMyAnswer } from '../../../service/api/ApiService'
import { categoryAnswer } from '../../../helper/helpFunction'
import moment from 'moment'
import DialogNotifi from '../../Dialog'

const pageDefault = 1
const limitSize = 20

class AnswerLogUser extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dataNew: [],
			userInfo: this.props.UserInfo,
			userId: this.props.UserInfo.userId,
			page: pageDefault,
			size: limitSize,
			waitingLoadMoreData: false,
			totalLengthEnd: false,
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (
			prevProps.UserInfo !== undefined &&
			prevProps.UserInfo.userId !== this.props.UserInfo.userId
		) {
			this.setState({
				dataNew: [],
				userInfo: this.props.UserInfo,
				userId: this.props.UserInfo.userId,
				page: pageDefault,
				size: limitSize,
				waitingLoadMoreData: false,
			})
			let params = { page: pageDefault, size: limitSize }
			params.userId = this.props.UserInfo.userId
			this.callApiGetData(params)
		}
	}

	componentDidMount = () => {
		let params = { page: pageDefault, size: this.state.size }
		params.userId = this.props.UserInfo.userId
		this.callApiGetData(params)
	}

	callApiGetData = async (params) => {
		let dataNew = []
		let totalLengthEnd = false

		await StellaAnswerWithMyAnswer(params).then(function (res) {
			if (res.code !== undefined && res.code === 0) {
				dataNew = res.data.list
				if (dataNew.length === 0) {
					totalLengthEnd = true
				}
			}
		})

		this.setState({
			dataNew: this.state.dataNew.concat(dataNew),
			waitingLoadMoreData: false,
			totalLengthEnd: totalLengthEnd,
		})
	}

	viewDetailAnswerInChat = (data) => {
		DialogNotifi.getDialog().handleOpen('DETAIL_ANSWER', data, true)
	}

	componentWillMount() {
		window.addEventListener('scroll', this.loadMore)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.loadMore)
	}

	loadMore = async (event) => {
		let scrollTop = event.target.scrollTop
		let clientHeight = event.target.clientHeight
		let scrollHeight = event.target.scrollHeight
		if (!this.state.waitingLoadMoreData) {
			if (scrollTop + clientHeight + 1 >= scrollHeight) {
				let pageNew = this.state.page + 1
				let params = { page: pageNew, size: this.state.size }
				params.userId = this.state.userId
				if (!this.state.totalLengthEnd) {
					this.setState({
						waitingLoadMoreData: true,
						page: pageNew,
					})
					await this.callApiGetData(params)
				}
			}
		}
	}

	convertText(text) {
		return text.replace(/\n/g, '<br/>')
	}

	render() {
		return (
			<>
				<div
					className={
						this.state.waitingLoadMoreData
							? 'box-list-answer-chat-log loading-list'
							: 'box-list-answer-chat-log'
					}
					onScroll={(event) => this.loadMore(event)}
				>
					{this.state.dataNew.length > 0
						? this.state.dataNew.map((item, key) => {
								return (
									<div
										key={key}
										className={'item-answer-log'}
										onClick={() =>
											this.viewDetailAnswerInChat(item)
										}
									>
										<div
											className={
												'list-category-answer-log'
											}
										>
											<span
												className={
													'category-item-answer'
												}
											>
												{categoryAnswer(item.category)}
											</span>
										</div>
										<div
											className="detail-answer-log"
											dangerouslySetInnerHTML={{
												__html: this.convertText(
													item.content
												),
											}}
										/>
										<div className={'date-answer-log'}>
											{moment(item.createTime).format(
												'YYYY/MM/DD HH:mm'
											)}
										</div>
									</div>
								)
						  })
						: null}
				</div>
			</>
		)
	}
}

const mapStateToProps = () => {
	return {}
}

export default connect(mapStateToProps)(AnswerLogUser)
