import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import { isAndroid,isMobile } from 'react-device-detect'

export default class DialogContactConfirm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			funcAccept: null,
		}
	}

	acceptReply = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				className="dialogBlock"
				contentStyle={!isMobile?{width:'60%'}:{}}
			>
				<i
					className="material-icons tab__icons closeBlock"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<p className="txtDialogBlock1" style={{ marginBottom: 16 }}>
					お問い合わせ完了
				</p>
				<p className="txtDialogBlock2" style={{marginBottom: 5 }}>お問合せを受け付けました。</p>
				<p className="txtDialogBlock2" style={{marginBottom: 5 }}>
					カスタマーサポートからの返信は
				</p>
				<p className="txtDialogBlock2" style={{marginBottom: 5 }}>
					お問い合わせ履歴で確認できます。
				</p>
				<button
					className={
						isAndroid
							? 'submitCantBlock'
							: 'submitCantBlock submitCantBlockIOS'
					}
					onClick={this.acceptReply}
				>
					OK
				</button>
				<p
					className="textCancelContact"
					onClick={this.closeDialog}
					style={{
						marginTop: 12,
						textAlign: 'center',
						fontSize: 14,
						color: 'rgba(0, 0, 0, 0.38)',
					}}
				>
					閉じる
				</p>
			</Dialog>
		)
	}
}
