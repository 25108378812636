import {
	CHAT_REQUEST_DEFAULT,
	CHAT_REQUEST_UNREAD,
	CHAT_REQUEST_UNREAD_REFRESH,
} from '../constants/ActionTypes'
import * as Fetch from '../util/Fetch'
import { getUserId } from '../assets/help/help'

const initialState = {
	loaded: false,
	data: {},
}

export const ChatRequestUnRead = (state = initialState, action) => {
	switch (action.type) {
		case CHAT_REQUEST_UNREAD: {
			let listNew = {}
			if (action.data.length > 0) {
				action.data.map((item) => {
					Object.assign(listNew, { [getUserId(item)]: item })
				})
			}
			return {
				loaded: true,
				data: listNew,
			}
		}
		case CHAT_REQUEST_UNREAD_REFRESH: {
			const sortable2 = Object.entries({ ...action.data})
			.sort(([,a],[,b]) => b.serverTime - a.serverTime)
			.reduce((r, [k, v]) =>  ({ ...r, [k]: v }), {})
			return {
				loaded: true,
				data: sortable2,
			}
		}
		case CHAT_REQUEST_DEFAULT: {
			return {
				loaded: false,
				data: {},
			}
		}
		default: {
			return state
		}
	}
}
