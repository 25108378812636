import React from 'react'
import Dialog from 'material-ui/Dialog'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const ModalSettingTemplate = (props) => {
	const [isShow, setShow] = useState(false)

	useEffect(() => {
		setShow(props.isShow)
	}, [props.isShow])

	const closeDialog = () => setShow(true)

	const onCancel = () => {
		closeDialog()
		props.resetToggle()
	}

	return (
		<div>
			<Dialog
				modal={false}
				open={isShow}
				bodyClassName="modal-missing__profile setting-popup-overflow"
				contentClassName="modal-missing__profile-paper setting-popup-template"

			>
				<div className="cancel-dialog__right">
                    <span onClick={() => onCancel()}>X</span>
                </div>
				<div className="modal-missing__profile-content" id="content-appeal__message">
                    <img className="image-setting-template__item" src="/img/settingTemplate1.png" />
                    <img className="image-setting-template__item" src="/img/settingTemplate2.png" />
                    <img className="image-setting-template__item" src="/img/settingTemplate3.png" />
                    <img className="image-setting-template__item" src="/img/settingTemplate4.png" />
				</div>
				<div className="modal-appeal__message-btn">
					<input
						type="button"
						value="OK"
						className="modal-appeal__message-btn-cancel"
						onClick={onCancel}
					/>
				</div>
			</Dialog>
		</div>
	)
}
