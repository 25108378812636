import {
	GET_REVIEWS_WITH_FILTERS,
	PIN_REVIEW,
	RESET_STATUS,
	GET_PINED_REVIEW,
	UNPIN_REVIEW,
	UPDATE_PIN_STATUS_OF_REVIEW_ITEM,
	UPDATE_REVIEW_TUTORIAL,
	RESET_REVIEW_LIST,
} from '../constants/ActionTypes'
import { glasConfig, TimeLineContentType } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//レビュー取得
// export const reviewGet = (params) => {
//   return (dispatch) => {
//     const token = localStorage.getItem('token')

//     return request
//       .get(glasConfig.url_base + glasConfig.path_review + params, {
//         headers: {
//           Authorization: token
//         }
//       })
//       .then(response => {
//         if (response.data.code === 0) {
//           dispatch(resultSuccess(REVIEW_GET, response.data))
//         } else {
//           throw 'システムエラー'
//         }
//       })
//       .catch(error => {
//         console.log('error on call ' + REVIEW_GET, error)
//         throw error
//       })
//   }
// }

export const getPinedReviews = (params) => {
	return (dispatch) => {
		const { tellerId } = params

		const token = localStorage.getItem('token')
		return request
			.get(
				`${glasConfig.url_base}${glasConfig.path_pined_review}?fortuneTellerId=${tellerId}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					if (response.data.data !== null) {
						dispatch(
							resultSuccess(GET_PINED_REVIEW, response.data.data)
						)
					} else {
						dispatch(resultSuccess(GET_PINED_REVIEW, []))
					}
				}
			})
			.catch((error) => {
				console.error(error)
				throw error
			})
	}
}
export const getReviewsWithFilters = (params) => {
	return (dispatch) => {
		const { contentFilter, page, size, initial } = params
		const token = localStorage.getItem('token')
		return request
			.get(
				`${glasConfig.url_base}${glasConfig.get_all_review_teller_profile}?havingContent=${contentFilter}&page=${page}&size=${size}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					if (response.data.data !== null) {
						dispatch(
							resultSuccess(GET_REVIEWS_WITH_FILTERS, {
								responseData: response.data.data,
								initial,
							})
						)
					} else {
						dispatch(resultSuccess(GET_REVIEWS_WITH_FILTERS, []))
					}
				}
			})
			.catch((error) => {
				console.error(error)
				throw error
			})
	}
}

export const updateReviewTutorial = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.put(
				`${glasConfig.url_base}${glasConfig.path_update_review_tutorial}`,
				{},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					console.log('Updated')
				}
			})
			.catch((error) => {
				console.error(error)
				throw error
			})
	}
}

export const pinReview = (params) => {
	return (dispatch) => {
		const { reviewId } = params
		const token = localStorage.getItem('token')
		return request
			.put(
				`${glasConfig.url_base}${glasConfig.path_pin_review}?reviewId=${reviewId}`,
				{},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(PIN_REVIEW, reviewId))
				}
			})
			.catch((error) => {
				console.error(error)
				throw error
			})
			.finally(() => {
				dispatch(resultSuccess(RESET_STATUS))
			})
	}
}

export const unpinReview = (params) => {
	return (dispatch) => {
		const { reviewId } = params
		const token = localStorage.getItem('token')
		return request
			.put(
				`${glasConfig.url_base}${glasConfig.path_unpin_review}?reviewId=${reviewId}`,
				{},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(UNPIN_REVIEW, reviewId))
				}
			})
			.catch((error) => {
				console.error(error)
				throw error
			})
			.finally(() => {
				dispatch(resultSuccess(RESET_STATUS))
			})
	}
}

export const updatePinStatusOfReviewItem = (params) => {
	return (dispatch) => {
		const { reviewId, status } = params
		dispatch(
			resultSuccess(UPDATE_PIN_STATUS_OF_REVIEW_ITEM, {
				reviewId,
				status,
			})
		)
	}
}

export const resetStatus = () => {
	return (dispatch) => {
		dispatch(resultSuccess(RESET_STATUS))
	}
}
export const resetReviewList = () => {
	return (dispatch) => {
		dispatch(resultSuccess(RESET_REVIEW_LIST))
	}
}
