/**
 * 次回出演設定（ヘッダーとホーム２箇所から利用される）
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as NextLoginActions from '../actions/NextLoginActions'
import * as Fetch from '../util/Fetch'
import DatePicker from 'material-ui/DatePicker'
import TimePicker from 'material-ui/TimePicker'
import * as AuthActions from '../actions/AuthActions'
import moment from 'moment'
require('moment/locale/ja')

class NextLogin extends Component {
	constructor(props) {
		super(props)

		this.state = {
			logout: false,
			Date: null,
			Time: null,
			displayTimePicker: true,
			displaySaveButton: true,
		}
	}

	componentWillMount() {
		const { MyProfile } = this.props
		const reloginTime = MyProfile.data.reloginTime
		let momentObj = moment(reloginTime, 'YYYYMMDDHHmmss').add(-(new Date().getTimezoneOffset() / 60), 'hours')
	
		const defaultDate = moment(reloginTime, 'YYYYMMDDHHmmss').isValid()
			? new Date(
					momentObj.year(),
					momentObj.month(),
					momentObj.date(),
					momentObj.hour(),
					momentObj.minute(),
					momentObj.second()
			  )
			: new Date()
		this.setState({
			Date: defaultDate,
			Time: defaultDate,
			displaySaveButton: false,
		})
	}

	handleChangeDate = (event, date) => {
		//保存ボタンの表示
		this.setState({
			Date: date,
			displayTimePicker: false,
			displaySaveButton: this.state.Time ? false : true,
		})
		//timepickerを表示
		this.refs.timepicker.openDialog()
	}

	handleChangeDateSp = (event, date) => {
		//保存ボタンの表示
		this.setState({
			Date: date,
			displayTimePicker: false,
			displaySaveButton: this.state.Time ? false : true,
		})
		//timepickerを表示
		this.refs.timepickerSp.openDialog()
	}

	handleChangeTime = (event, date) => {
		this.setState({
			Time: date,
			displayTimePicker: false,
			displaySaveButton: this.state.Date ? false : true,
		})
	}

	getNextLogin() {
		if (!(this.state.Date && this.state.Time)) {
			return null
		}
		const date = Fetch.formatDate(this.state.Date, 'YYYYMMDD')
		const time = moment(this.state.Time).format('HHmm')
		return date + time
	}

	saveNextLogin() {
		//次回ログインを保存する
		const reloginTime = this.getNextLogin()
		const localOfset = -(new Date().getTimezoneOffset() / 60)
		if (!reloginTime) {
			return null
		}
		const { dispatch } = this.props
		dispatch(NextLoginActions.put({ reloginTime, localOfset }))
	}

	//ログアウト。次回ログインを保存する場合はsave:trueを指定
	handleLogout(save) {
		const { dispatch } = this.props
		const reloginTime = save === true ? this.getNextLogin() : ''
		const localOfset = -(new Date().getTimezoneOffset() / 60)
		// if(!isSafari && !isIOS){
		//   FireMsg.requestPermission()
		//   .then(() => {
		//     //ユーザー毎のトークンを取得
		//     FireMsg.getToken()
		//       .then((token) => {
		//         dispatch(AuthActions.logout({reloginTime, localOfset, pushNotificationToken : token}))
		//       })
		//       .catch((err) => {
		//         console.log('Unable to retrieve refreshed token ', err)
		//       })
		//   })
		//   .catch((err) => {
		//     dispatch(AuthActions.logout({reloginTime, localOfset}))
		//     console.log('Unable to get permission to notify.', err)
		//   })
		// } else {
		dispatch(
			AuthActions.logout({
				reloginTime,
				localOfset,
				pushNotificationToken: localStorage.getItem(
					'pushNotificationToken'
				),
			})
		)
		// }
	}

	render() {
		const { show, place, closeFunc } = this.props

		if (!show) {
			return null
		}

		//カレンダーのフォーマット
		const DateTimeFormat = global.Intl.DateTimeFormat
		const options = {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		}
		const dateTimeFormat = new DateTimeFormat('ja-JP', options).format
		const DayWeek = ['日', '月', '火', '水', '木', '金', '土']
		const defaultDate = this.state.Date

		return (
			<div>
				<ul
					className={
						place === 'logout'
							? 'list logout-dialog-list hidden-sp'
							: 'bar-line-list hidden-sp'
					}
				>
					<li
						className={
							place === 'logout'
								? 'list__item'
								: 'bar-line-list__item'
						}
					>
						<div className="textfield">
							<DatePicker
								DateTimeFormat={DateTimeFormat}
								locale="ja-JP"
								formatDate={dateTimeFormat}
								firstDayOfWeek={0}
								name="nextloginDate"
								hintText="次回ログイン日時"
								okLabel="OK"
								cancelLabel="キャンセル"
								defaultDate={defaultDate}
								formatDate={(dt) =>
									`${dt.getFullYear()}年${
										dt.getMonth() + 1
									}月${dt.getDate()}日(${
										DayWeek[dt.getDay()]
									})`
								}
								onChange={this.handleChangeDate}
								textFieldStyle={logoutDateStyle}
							/>
						</div>
					</li>
					<li
						className={
							place === 'logout'
								? 'list__item'
								: 'bar-line-list__item'
						}
					>
						<div className="textfield">
							<TimePicker
								pedantic={true}
								name="nextloginTime"
								ref="timepicker"
								hintText=""
								format="24hr"
								okLabel="OK"
								cancelLabel="キャンセル"
								defaultTime={defaultDate}
								// disabled={this.state.displayTimePicker}
								onChange={this.handleChangeTime}
								textFieldStyle={logoutTimeStyle}
							/>
						</div>
					</li>
				</ul>
				<ul
					className={
						place === 'logout'
							? 'list logout-dialog-list ts-ul1 display-mobile'
							: 'bar-line-list ts-ul1 display-mobile'
					}
				>
					<li
						className={
							place === 'logout'
								? 'list__item'
								: 'bar-line-list__item'
						}
					>
						<div className="textfield picker">
							<DatePicker
								DateTimeFormat={DateTimeFormat}
								locale="ja-JP"
								formatDate={dateTimeFormat}
								firstDayOfWeek={0}
								name="nextloginDate"
								hintText="次回ログイン日時"
								dialogContainerStyle={{ top: -35 }}
								okLabel="OK"
								dialogContainerStyle={{ top: -35 }}
								cancelLabel="キャンセル"
								defaultDate={defaultDate}
								formatDate={(dt) =>
									`${dt.getFullYear()}/${
										dt.getMonth() + 1
									}/${dt.getDate()}`
								}
								onChange={this.handleChangeDateSp}
								textFieldStyle={logoutDateStyle}
							/>
						</div>
					</li>
					<li
						className={
							place === 'logout'
								? 'list__item'
								: 'bar-line-list__item'
						}
					>
						<div className="textfield picker">
							<TimePicker
								pedantic={true}
								name="nextloginTime"
								ref="timepickerSp"
								hintText=""
								format="24hr"
								okLabel="OK"
								cancelLabel="キャンセル"
								dialogBodyStyle={{ overflow: 'scroll' }}
								defaultTime={defaultDate}
								// disabled={this.state.displayTimePicker}
								onChange={this.handleChangeTime}
								textFieldStyle={logoutTimeStyle}
							/>
						</div>
					</li>
				</ul>

				{place === 'logout' ? (
					<div className="logout-dialog-btn-wrap">
						<div className="btn-wrap margin_bottom">
							<button
								onClick={() => this.handleLogout(true)}
								disabled={this.state.displaySaveButton}
								className="btn-raised color_default"
							>
								設定してログアウト
							</button>
						</div>
						<div className="btn-wrap margin_bottom">
							<button
								onClick={() => this.handleLogout()}
								className="btn-raised"
							>
								設定せずログアウト
							</button>
						</div>
						<div className="btn-wrap margin_bottom">
							<a href="javascript:;" onClick={() => closeFunc()}>
								キャンセル
							</a>
						</div>
					</div>
				) : (
					<div className="btn-wrap bar-line__btn ts-div1">
						<button
							onClick={() => this.saveNextLogin()}
							disabled={this.state.displaySaveButton}
							className="btn-raised color_default hidden-sp"
						>
							保存
						</button>
						<span
							onClick={() => this.saveNextLogin()}
							className="saveDateTime display-mobile"
						>
							変更
						</span>
					</div>
				)}
			</div>
		)
	}
}

const logoutDateStyle = {
	width: '170px',
	margin: '0 4px',
}
const logoutTimeStyle = {
	width: '80px',
	margin: '0 4px',
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(NextLogin)
