import styled from 'styled-components'
import { colors, fonts, DEVICE } from '../../../util/StellaConstant'

/*
    @media ${DEVICE.mobileS} {

    }
    @media ${DEVICE.tablet} {

    }
    @media ${DEVICE.laptop} {

    }
*/

export const StyledContainerMainContent = styled.div`
	@media ${DEVICE.mobileS} {
		padding: 65px 10px 48px 10px;
	}
	@media ${DEVICE.tablet} {
		padding: 100px 10px 48px 288px;
	}
	@media ${DEVICE.laptop} {
		padding: 100px 10px 48px 288px;
	}
`

export const StyledHomeMissionItem = styled.div`
	@media ${DEVICE.mobileS} {
		padding: 16px;
		border-bottom: 1px solid #e0e0e0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.mission-item__left {
			width: calc(100% - 64px - 16px);
		}
		.mission-item__right {
			width: 64px;
			height: fit-content;

			button {
				margin-top: 0;
			}
		}
	}
	@media ${DEVICE.tablet} {
		.mission-item__left {
			width: calc(100% - 125px - 16px);
		}
		.mission-item__right {
			width: 125px;
			height: fit-content;

			button {
				margin-top: 0;
			}
		}
	}
	@media ${DEVICE.laptop} {
	}
`

export const StyledContainerHomeBlogList = styled.div`
	@media ${DEVICE.mobileS} {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 16px 8px;
		margin-bottom: 15px;
		margin-top: 4px;

		.card {
			margin: 0;
			border-radius: 4px;
			box-shadow: 2px 3px 8px 1px rgb(0 0 0 / 27%);
		}
	}
`

export const StyledContainerHomeMission = styled.div`
	@media ${DEVICE.mobileS} {
		background: #fff;
		border-radius: 4px;
		box-shadow: 2px 3px 8px 1px rgb(0 0 0 / 27%);
		margin-bottom: 8px;
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
	}
`

export const FlexDiv = styled.div`
	@media ${DEVICE.mobileS} {
		flex-direction: column-reverse;

		display: flex;
	}
	@media ${DEVICE.tablet} {
		flex-direction: column-reverse;
	}
	@media ${DEVICE.laptop} {
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}
`

export const StyledHomeColumn = styled.div`
	@media ${DEVICE.mobileS} {
		margin-top: 16px;
		width: 100%;
	}
	@media ${DEVICE.tablet} {
		width: 100%;
	}
	@media ${DEVICE.laptop} {
		width: ${(props) => `calc((100% - ${props.gap}px)/${props.column})`};
		max-width: ${(props) =>
			`calc((100% - ${props.gap}px)/${props.column})`};
	}
`

export const StyledTitle = styled.h2`
	@media ${DEVICE.mobileS} {
		font-size: 18px;
		margin-bottom: 8px;
		color: ${colors.black};
		line-height: 24px;
		font-weight: 600;
		font-family: ${fonts.HiraKakuProW6};
		display: flex;
		align-items: center;

		a {
			font-size: 11px;
			margin-left: 8px;
		}
	}
	@media ${DEVICE.tablet} {
	}
	@media ${DEVICE.laptop} {
	}
`
