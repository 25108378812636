import {
	CONTACT_GET_HISTORY,
	CONTACT_POST_QUESTION,
	CONTACT_GET_DETAIL,
	CONTACT_RESOLVE_QUESTION,
	CONTACT_GET_UNREAD,
} from '../constants/ActionTypes'

const initialState = {
	loading: false,
	data: {},
	detail: {},
	post: {},
	resolve: {},
	unread: {},
}

export const Contact = (state = initialState, action) => {
	switch (action.type) {
		case CONTACT_GET_HISTORY:
			return Object.assign({}, state, action.data)
		case CONTACT_GET_DETAIL:
			return Object.assign({}, state, { detail: action.data })
		case CONTACT_POST_QUESTION:
			return Object.assign({}, state, { post: action.data })
		case CONTACT_RESOLVE_QUESTION:
			return Object.assign({}, state, { resolve: action.data })
		case CONTACT_GET_UNREAD:
			return Object.assign({}, state, { unread: action.data })
		default:
			return state
	}
}
