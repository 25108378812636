import { getUserId } from '../assets/help/help'
import { REFRESH_ITEM_PIN_CHAT, REQUEST_HISTORY_PIN, REQUEST_PIN_CHAT, REQUEST_UNPIN_CHAT } from '../constants/ActionTypes'

const initialState = {
	loaded: false,
	data: {},
	count: 0
}

export const RequestHistoryPin = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_HISTORY_PIN: {
			let listPins = {}
			if (action.data) listPins = action.data

			return {
				...state,
				loaded: true,
				data: listPins,
				count: listPins.length
			}
		}
		case REQUEST_PIN_CHAT: {
			let listNew = state.data
			if (listNew.filter(i => getUserId(i) === getUserId(action.data[0])).length === 0) {
				listNew = [...action.data, ...listNew]
			}
			const listMsgUnRead = listNew.filter(i => i.unreadMsgBadge > 0)
			const listMsgRead = listNew.filter(i => i.unreadMsgBadge === 0)
			const sortableUnread = listMsgUnRead
				.sort((a, b) => b.serverTime - a.serverTime)
			const sortableRead = listMsgRead
				.sort((a, b) => b.serverTime - a.serverTime)
			const endData = [...sortableUnread, ...sortableRead]
			return {
				...state,
				data: endData,
				count: state.count + 1
			}
		}
		case REQUEST_UNPIN_CHAT: {
			const newData = state.data.filter(i => i.msgId !== action.data.msgId)
			return {
				loaded: true,
				data: newData,
				count: state.count - 1
			}
		}
		case REFRESH_ITEM_PIN_CHAT: {
			let itemUnread = state.data.find(i => getUserId(i) === getUserId(action.data))
			itemUnread.value = action.data.value
			itemUnread.unreadMsgBadge = action.data.unreadMsgBadge
			itemUnread.msgType = action.data.msgType
			itemUnread.serverTime = action.data.serverTime
			itemUnread.check = true
			const listMsgUnRead = state.data.filter(i => i.unreadMsgBadge > 0)
			const listMsgRead = state.data.filter(i => i.unreadMsgBadge === 0)
			const sortableUnread = listMsgUnRead
				.sort((a, b) => b.serverTime - a.serverTime)
			const sortableRead = listMsgRead
				.sort((a, b) => b.serverTime - a.serverTime)
			const endData = [...sortableUnread, ...sortableRead]
			return {
				...state,
				data: endData
			}
		}
		default: {
			return state
		}
	}
}
