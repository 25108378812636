import { STAFFBLOG_FETCH } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, isload, data) => {
	return {
		type: type,
		loaded: isload,
		data,
	}
}

export const fetchData = (page, size) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.get_lst_blog, {
				params: {
					page: page,
					size: size
				},
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				dispatch(resultSuccess(STAFFBLOG_FETCH, true, response.data.data))
			})
			.catch((error) => {
				// console.log('error on call ' + STAFFBLOG_FETCH, error)
				// throw error
			})
	}
}
