import React, { useState, useEffect } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, dateFnsLocalizer, momentLocalizer } from 'react-big-calendar'
import { getDay, startOfWeek, parse, format } from 'date-fns'
import ja from 'date-fns/locale/ja'
import MonthHeader from '../CustomCalendar/MonthHeader'
import DayInMonth from '../CustomCalendar/DayInMonth'
import PointPerDay from '../CustomCalendar/PointPerDay'
import { generateDataForCalendar, getCurrentMonth } from '../../../../constants/PointReportConstants'
import Toolbar from '../CustomCalendar/Toolbar'
import * as PointReportActions from '../../../../actions/PointReportActions'
import { connect } from 'react-redux'
import moment from 'moment'

//! Read https://date-fns.org/v2.27.0/docs/format to get some patterns, example: 'ccc', 'd', ...

const localizer = dateFnsLocalizer({
	format,
	parse,
	getDay,
	startOfWeek: (date, options) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
	locales: { 'ja': ja },
})

const formats = {
	weekdayFormat: (date, culture, localizer) => localizer.format(date, 'ccc', culture),
	dateFormat: (date, culture, localizer) => localizer.format(date, 'd', culture),
	monthHeaderFormat: (date, culture, localizer) => `${localizer.format(date, 'y', culture)}年${localizer.format(date, 'M', culture)}月`
}

const components = {
	toolbar: Toolbar,
	month: {
		header: MonthHeader,
		dateHeader: DayInMonth,
	},
	eventWrapper: PointPerDay,
}

const PointCalendar = props => {
	const { dispatch, PointReport } = props

	const [dataForCalendar, setDataForChart] = useState([])

	useEffect(() => {
		const currentMonth = getCurrentMonth()
		dispatch(PointReportActions.getCalendarData({ month: currentMonth }))
	}, [])

	useEffect(() => {
		const result = generateDataForCalendar(PointReport.calendarData.value, moment(PointReport.calendarData.currentMonth, 'YYYYMM').format('MM'))
		setDataForChart(result)
	}, [PointReport.calendarData.value])
	return (
		<>
			<Calendar
				localizer={localizer}
				events={dataForCalendar}
				startAccessor="start"
				endAccessor="end"
				culture='ja'
				components={components}
				formats={formats}
				views={['month']}
				style={{
					gridArea: 'PointCalendar',
					width: '100%',
					// height: 500
				}}
			/>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		PointReport: state.PointReport
	}
}

export default connect(mapStateToProps)(PointCalendar)