import { MYINFO_ACCOUNT_AGE_AUTH_POST } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

// 画像認証ファイルアップロード
export const ageVerify = (formData) => {
	return (dispatch) => {
		return request
			.post(
				glasConfig.url_base + glasConfig.path_ageverify_s3storage,
				formData,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let data = {
						data: response.data.data,
						posted: true,
					}
					dispatch(resultSuccess(MYINFO_ACCOUNT_AGE_AUTH_POST, data))
				} else {
					dispatch(resultSuccess(MYINFO_ACCOUNT_AGE_AUTH_POST, {}))
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + MYINFO_ACCOUNT_AGE_AUTH_POST,
					error
				)
				throw error
			})
	}
}
