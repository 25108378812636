import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import DialogInactivity from './DialogInactivity'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import { connect } from 'react-redux'
import { fortuneTellerStatus } from '../constants/Config'
import * as ActionTypes from '../constants/ActionTypes'
// import * as WebSocket from "../util/WebSocket";
import DialogChatRef from './Refs/DialogChatRef'
import User from '../models/User'

const IDLE_TIMEOUT_MS = 10000
const IDLE_DEBOUNCE_MS = 250

class IdleContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			idleTimeout: IDLE_TIMEOUT_MS,
			inactiveAt: null,
		}
		this.idleTimer = null
		this.onIdle = this._onIdle.bind(this)
		this.onActive = this.onActive.bind(this)
		this.inactivityRef = React.createRef()
	}

	componentDidMount() {
		this.idleTimer.pause()
		this.getIdleTimeout()
	}

	noActionPopupShowing() {
		const { IdleManagement } = this.props
		return IdleManagement.action === ActionTypes.USER_IDLE_ACTION_SHOW_POPUP
	}

	componentDidUpdate(prevProps) {
		// When teller login
		if (prevProps.Auth !== this.props.Auth) {
			const { tellerId } = this.props.Auth
			if (tellerId) {
				this.getIdleTimeout()
			}
		}

		// Show popup when state of store changed
		if (prevProps.IdleManagement !== this.props.IdleManagement) {
			const { IdleManagement } = this.props
			if (this.noActionPopupShowing() && IdleManagement.data) {
				this.pauseIdleTimer()
				this.showIdlePopup()
				this.setState({ inactiveAt: IdleManagement.data.idleAt })
			}
		}

		// Start Idle timer
		if (prevProps.MyProfile !== this.props.MyProfile) {
			const { data } = this.props.MyProfile
			if (this.shouldRestartIdleTimer(data.fortuneTellerStatus)) {
				this.restartIdleTimer()
			}
			if (this.shouldPauseIdleTimer(data.fortuneTellerStatus)) {
				this.pauseIdleTimer()
			}
		}
	}

	shouldRestartIdleTimer(tellerStatus) {
		return (
			tellerStatus === fortuneTellerStatus.waiting ||
			tellerStatus === fortuneTellerStatus.chat ||
			tellerStatus === fortuneTellerStatus.call
		)
	}

	shouldPauseIdleTimer(tellerStatus) {
		return (
			tellerStatus === fortuneTellerStatus.chatting ||
			tellerStatus === fortuneTellerStatus.chattingFull ||
			tellerStatus === fortuneTellerStatus.calling ||
			tellerStatus === fortuneTellerStatus.offline
		)
	}

	getIdleTimeout() {
		const token = localStorage.getItem('token')
		if (!token) { return }

		const params = {
			method: 'GET',
			headers: {
				Authorization: token,
			},
			url: glasConfig.url_base + glasConfig.path_get_idle_time,
		}

		request(params)
			.then((response) => {
				// console.log(response);
				if (response.data.code === 0) {
					const { limitTime } = response.data.data
					this.setState({ idleTimeout: limitTime * 60 * 1000 })
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	restartIdleTimer = () => {
		// console.log('-----restartIdleTimer-----');
		this.idleTimer.reset()
	}

	pauseIdleTimer = () => {
		// console.log('-----pauseIdleTimer-----');
		this.idleTimer.pause()
	}

	render() {
		return (
			<div>
				<IdleTimer
					ref={(ref) => {
						this.idleTimer = ref
					}}
					element={document}
					onIdle={this.onIdle}
					onAction={this.onActive}
					debounce={IDLE_DEBOUNCE_MS}
					timeout={this.state.idleTimeout}
				/>
				{this.props.children}
				<DialogInactivity
					socket={this.socket}
					onRestartIdle={this.restartIdleTimer.bind(this)}
					onPauseIdle={this.pauseIdleTimer.bind(this)}
					onRef={(ref) => (this.inactivityRef = ref)}
				/>
			</div>
		)
	}

	onActive(e) {
		if (this.state.inactiveAt) {
			console.log('-----Active again-----')

			this.setState({ inactiveAt: null })

			// Emit noAction event for end sleep
			const tellerId = this.props.MyProfile.data.fortuneTellerId
			const message = {
				fromId: tellerId,
				msgType: 'NO_ACTION_END_SLEEP',
			}
			console.log('-----tellerNoAction::NO_ACTION_END_SLEEP-----')
			User.getSocket().emit('tellerNoAction', JSON.stringify(message))
		}
	}

	showIdlePopup() {
		this.inactivityRef.openDialog()
	}

	_onIdle(e) {
		console.log('Idle at', this.idleTimer.getLastActiveTime())
		this.pauseIdleTimer()

		// Check if Modal Request Chat is showing then reset interval
		if (DialogChatRef.isShowing()) {
			this.restartIdleTimer()
			return false
		}

		// Emit noAction event when use is inactive
		const tellerId = this.props.MyProfile.data.fortuneTellerId
		const message = {
			fromId: tellerId,
			msgType: 'NO_ACTION_TIME_OUT',
		}
		console.log('-----tellerNoAction::NO_ACTION_TIME_OUT-----')
		User.getSocket().emit('tellerNoAction', JSON.stringify(message))
	}
}

const mapStateToProps = (state) => ({
	Auth: state.Auth,
	MyProfile: state.MyProfile,
	IdleManagement: state.IdleManagement,
})

export default connect(mapStateToProps)(IdleContainer)
