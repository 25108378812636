import React, { Component } from 'react'

export default class DropDown extends Component {
	constructor(props) {
		super(props)
		this.state = {
			displayList: false,
		}

		this.toggleList = this.toggleList.bind(this)
	}

	toggleList() {
		this.setState({
			displayList: !this.state.displayList,
		})
	}

	handleClick(event, id) {
		const { handleChange } = this.props
		this.toggleList()
		handleChange(id)
	}

	render() {
		const { selectedId, list, idField, nameField, placeHolder, className } =
			this.props
		let selectedName = placeHolder
		const items = Array.prototype.map.call(list, (current, index) => {
			if (current[idField] != selectedId) {
				return (
					<li
						key={`drop-down-item-${index}`}
						className="drop-down__item"
						onClick={(e) => this.handleClick(e, current[idField])}
					>
						{current[nameField]}
					</li>
				)
			} else {
				selectedName = current[nameField]
				return false
			}
		})

		if (selectedName !== placeHolder) {
			items.unshift(
				<li
					className="drop-down__item"
					onClick={(e) => this.handleClick(e, 0)}
				>
					{placeHolder}
				</li>
			)
		}

		const wrapClass =
			typeof className !== 'undefined' && className
				? `drop-down ${className}`
				: 'drop-down'
		const listClass = this.state.displayList
			? 'drop-down__list show'
			: 'drop-down__list'

		return (
			<div className={wrapClass}>
				<div className="drop-down__selected" onClick={this.toggleList}>
					{selectedName}
				</div>
				<ul className={listClass}>{items}</ul>
			</div>
		)
	}
}
