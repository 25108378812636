import { FOLLOWERS_GET } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//フォロワー一覧取得
export const fetch = (req) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const params =
			'?tab=followers&page=' +
			req.page +
			'&size=' +
			req.size +
			'&sortBy=' +
			req.sortByCreatedAt
		const url = glasConfig.url_base + glasConfig.path_user_info_v2 + params

		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(FOLLOWERS_GET, response.data.data))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + FOLLOWERS_GET, error)
				throw error
			})
	}
}
