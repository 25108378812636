import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import * as TemplateActions from '../actions/TemplateActions'
import TextField from 'material-ui/TextField'
import Snackbar from 'material-ui/Snackbar'

class SettingTemplate extends Component {
	constructor(props) {
		super(props)
		const templateTitle = this.props.templateTitle
			? this.props.templateTitle
			: ''
		this.state = {
			templateTitle: templateTitle,
			templateTitleLength: templateTitle.length,
			templateContent: this.props.templateContent,
			templateId: this.props.templateId,
			templateDisable: 'disabled',
			templateGet: false,
			templateTitleError: false,
			templateTitleErrorMessage: '',
			templateContentError: false,
			templateContentErrorMessage: '',
			templateExist: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		const templateTitle = nextProps.templateTitle
			? nextProps.templateTitle
			: ''
		this.setState({
			templateTitle: templateTitle,
			templateTitleLength: templateTitle.length,
			templateContent: nextProps.templateContent,
			templateId: nextProps.templateId,
			templateDisable: 'disabled',
			templateExist: nextProps.Template.templateExist,
			display: false,
		})
	}

	handleBlur(e) {
		if (e.target.name === 'templateTitle') {
			if (e.target.value.length === 0) {
				this.setState({
					templateTitleError: true,
					templateTitleErrorMessage:
						'テンプレートタイトルを入力してください',
				})
			}
		}

		if (e.target.name === 'templateContent') {
			if (e.target.value.length === 0) {
				this.setState({
					templateContentError: true,
					templateContentErrorMessage:
						'テンプレート内容を入力してください',
				})
			}
		}
	}

	handleChange(e) {
		if (e.target.name === 'templateTitle') {
			const value = e.target.value.replace(/\s/g, '')
			const length = value.length
			if (length > glasConfig.template.title) {
				return
			}

			let templateDisable =
				(value && this.state.templateContent) ||
				(!value && !this.state.templateContent && this.state.templateId)
					? ''
					: 'disabled'

			this.setState({
				templateTitle: value,
				templateTitleLength: length,
				templateDisable,
			})
		}
		if (e.target.name === 'templateContent') {
			const value = e.target.value
			let templateDisable =
				(this.state.templateTitle && value) ||
				(!this.state.templateTitle && !value && this.state.templateId)
					? ''
					: 'disabled'

			this.setState({
				templateContent: e.target.value,
				templateDisable,
			})
		}
	}

	handleKeyUp(e) {
		let templateTitle =
			e.target.parentNode.parentNode.parentNode.templateTitle.value
		let templateContent =
			e.target.parentNode.parentNode.parentNode.templateContent.value

		this.setState(
			Object.assign({}, this.state, {
				templateTitle: templateTitle,
				templateContent: templateContent,
			})
		)
	}

	handleTemplateTitleErrorClose() {
		this.setState({
			templateTitleError: false,
			templateTitleErrorMessage: '',
		})
	}

	handleTemplateContentErrorClose() {
		this.setState({
			templateContentError: false,
			templateContentErrorMessage: '',
		})
	}

	handleTemplateExistClose() {
		this.setState({
			templateExist: false,
		})
	}

	handleSubmit(e) {
		e.preventDefault()
		const { dispatch } = this.props
		let data = {}

		if (this.state.templateId) {
			if (!this.state.templateTitle && !this.state.templateContent) {
				dispatch(TemplateActions.deleteTemplate(this.state.templateId))
			} else {
				data = {
					templateTitle: this.state.templateTitle,
					templateContent: this.state.templateContent,
					templateId: this.state.templateId,
					templateGet: true,
				}
				dispatch(TemplateActions.putTemplate(data))
			}
		} else {
			data = {
				templateTitle: this.state.templateTitle,
				templateContent: this.state.templateContent,
				templateGet: true,
			}
			dispatch(TemplateActions.postTemplate(data))
		}
	}

	render() {
		let templateTitleLength =
			glasConfig.template.title > this.state.templateTitleLength
				? glasConfig.template.title -
				  this.state.templateTitleLength +
				  '/6'
				: ''
		let templateId = null

		if (this.props.templateId) {
			templateId = (
				<input
					type="hidden"
					name="templateId"
					value={`${this.state.templateId}`}
				/>
			)
		}

		return (
			<div className="template-block">
				<form
					name={`form${this.props.formNo}`}
					onSubmit={(e) => this.handleSubmit(e)}
					className={`form${this.props.formNo}`}
				>
					<div className="template-block__title">
						<span className="template-title">タイトル</span>
						<TextField
							type="text"
							name="templateTitle"
							hintText="タイトル入力"
							maxLength={glasConfig.template.title}
							onBlur={(e) => this.handleBlur(e)}
							value={`${this.state.templateTitle}`}
							onChange={(e) => this.handleChange(e)}
							onKeyUp={(e) => this.handleKeyUp(e)}
							fullWidth={true}
							floatingLabelStyle={textfieldStyles.label}
							textareaStyle={textfieldStyles.textarea}
							floatingLabelText={templateTitleLength}
							floatingLabelFixed={true}
							className="template-title"
						/>
					</div>
					<div className="template-block__body">
						<TextField
							type="text"
							name="templateContent"
							floatingLabelFixed={true}
							floatingLabelText="内容"
							hintText="テンプレート内容を入力してください"
							value={`${this.state.templateContent}`}
							onChange={(e) => this.handleChange(e)}
							onBlur={(e) => this.handleBlur(e)}
							onKeyUp={(e) => this.handleKeyUp(e)}
							fullWidth={true}
						/>
						{templateId}
					</div>
					<div className="template-block__btn">
						<input
							type="submit"
							className="btn-raised color_default"
							value="保存"
							disabled={this.state.templateDisable}
						/>
					</div>
				</form>
				<Snackbar
					open={this.state.templateTitleError}
					message={this.state.templateTitleErrorMessage}
					autoHideDuration={1000}
					onRequestClose={(e) =>
						this.handleTemplateTitleErrorClose(e)
					}
				/>
				<Snackbar
					open={this.state.templateContentError}
					message={this.state.templateContentErrorMessage}
					autoHideDuration={1000}
					onRequestClose={(e) =>
						this.handleTemplateContentErrorClose(e)
					}
				/>
				<Snackbar
					open={this.state.templateExist}
					message={'テンプレートのタイトルが既存しています。'}
					autoHideDuration={1000}
					onRequestClose={(e) => this.handleTemplateExistClose(e)}
				/>
			</div>
		)
	}
}

const textfieldStyles = {
	label: {
		bottom: -40,
		top: 'auto',
		right: -16,
	},
	hint: {
		top: 0,
		bottom: 'auto',
	},
	textarea: {
		marginTop: 0,
		marginBottom: 0,
		height: '100%',
	},
	block: {
		height: 44,
	},
}

const mapStateToProps = (store) => {
	return { Template: store.Template }
}

export default connect(mapStateToProps)(SettingTemplate)
