/**
 * 通知履歴
 */
import { MYINFO_NOTIFICATION } from '../constants/ActionTypes'
import { TYPE_LOCALSTORAGE, glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

export const fetch = (req) => {
	if (!req.size) {
		return
	}

	return (dispatch) => {
		const params = {
			page: req.page,
			size: req.size,
		}

		const url = glasConfig.url_base + glasConfig.path_jpns_notifications
		const token = localStorage.getItem('token')

		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: token,
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0 && response.data.length) {
					dispatch(
						resultSuccess(MYINFO_NOTIFICATION, response.data.data)
					)
				}
			})
			.catch((error) => {
				throw error
			})
	}
}

export const updatePushNotificationToken = (req) => {
	return (dispatch) => {
		const params = {
			deviceId: req.deviceId,
			pushNotificationToken: req.pushNotificationToken,
			applicationVersion: req.applicationVersion
		}
		const url = glasConfig.url_base + glasConfig.path_jpns_update_notification
		const token = localStorage.getItem('token')

		const options = {
			method: 'PUT',
			url: url,
			data: params,
			headers: {
				Authorization: token,
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					localStorage.setItem(TYPE_LOCALSTORAGE.PUSH_NOTIFICATION_TOKEN,req.pushNotificationToken)
				}
			})
			.catch((error) => {
				throw error
			})
	}
}
