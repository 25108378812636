import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'

class MissionMenu extends PureComponent {
	componentDidMount() {
		// let currentTab = $('.lead-nav .btn-tab.is-active').parent()
		//
		// if (currentTab.length) {
		//   let ul = currentTab.parent()
		//   let currentTabPos = currentTab.offset()
		//   let ulPos = ul.offset()
		//   let navWidth = ul.parent().outerWidth();
		//   let scrollLeft = currentTabPos.left - ulPos.left - 8 - ((navWidth - currentTab.outerWidth()) / 2);
		//   $('.lead-nav').scrollLeft(scrollLeft);
		// }
	}

	render() {
		let pathName = window.location.pathname
		let daily =
			'btn-tab' +
			(pathName === '/home/mission/daily' ? ' is-active' : '')
		let start_dash =
			'btn-tab' +
			(pathName === '/home/mission/start-dash' ? ' is-active' : '')
		let elementary =
			'btn-tab' +
			(pathName === '/home/mission/elementary' ? ' is-active' : '')
		let intermediate =
			'btn-tab' +
			(pathName === '/home/mission/intermediate' ? ' is-active' : '')
		let advanced =
			'btn-tab' +
			(pathName === '/home/mission/advanced' ? ' is-active' : '')
		let special =
			'btn-tab' +
			(pathName === '/home/mission/special' ? ' is-active' : '')
		let archived =
			'btn-tab' +
			(pathName === '/home/mission/archived' ? ' is-active' : '')

		return (
			<nav className="lead-nav" id="lead-nav">
				<ul className="list lead_nav">
					<li className="list__item">
						{isMobile ?
							<Link
								to="/home/mission/elementary"
								className={elementary}
							>
								初級
							</Link>
							:
							<Link
								to="/home/mission/daily"
								className={daily}
								id="menu-padding"
							>
								デイリー
							</Link>
						}
					</li>
					<li className="list__item">
						{isMobile ?
							<Link
								to="/home/mission/intermediate"
								className={intermediate}
							>
								中級
							</Link>
							:
							<Link
								to="/home/mission/start-dash"
								className={start_dash}
							>
								スタートダッシュ
							</Link>
						}
					</li>
					{isMobile && <li className="list__item">
						<Link to="/home/mission/advanced" className={advanced}>
							上級
						</Link>
					</li>}
					<li className="list__item">
						<Link to="/home/mission/special" className={special}>
							ゲリラ
						</Link>
					</li>
					<li className="list__item">
						<Link to="/home/mission/archived" className={archived}>
							達成済み
						</Link>
					</li>
				</ul>
			</nav>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(MissionMenu)
