/**
 * チャットリクエスト関連
 */
import {
	CHAT_REQUEST,
	CHAT_REQUEST_ANSWER,
	CHAT_REQUEST_MERGE,
	CHAT_REQUEST_DELETE,
	CHAT_REQUEST_UNREAD,
	CHAT_REQUEST_READ,
	CHAT_REQUEST_DEFAULT,
	CHAT_REQUEST_NEW,
	ADD_CHAT_DRAFT,
	CHANGE_STATUS_CHAT_DRAFT,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'
import axios from 'axios'
import { msgTypeMap } from '../constants/ConfigMessage'
import { display } from '@mui/system'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

export const fetch = (req) => {
	return (dispatch) => {
		const params = {
			page: req.page,
			size: req.size,
			inConversation: true,
		}

		const tellerId = Fetch.tellerId()
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat

		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					let data = {}
					if (response.data.data) {
						for (var i in response.data.data) {
							const userId =
								response.data.data[i].toId === tellerId
									? response.data.data[i].fromId
									: response.data.data[i].toId
							data[userId] = response.data.data[i]
						}
					}
					dispatch(resultSuccess(CHAT_REQUEST, data))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + CHAT_REQUEST, error)
				throw error
			})
	}
}

/**
 * 相談申し込みのキャンセル・許可
 * @param {object} req
 */
export const post = (req) => {
	return (dispatch) => {
		const params = {
			accept: req.accept,
		}

		const token = localStorage.getItem('token')
		const url =
			glasConfig.url_base + glasConfig.path_chat_request + req.msgId

		const options = {
			method: 'PUT',
			url: url,
			data: params,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					if (!req.accept) {
						dispatch(resultSuccess(CHAT_REQUEST_DELETE, req.userId))
						return
					}
					dispatch(
						requestChatUnRead({
							page: 0,
							size: 24,
							inConversation: true,
						})
					)
					dispatch(
						requestChatRead({
							page: 0,
							size: 24,
							inConversation: true,
						})
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log('error on call ' + CHAT_REQUEST_ANSWER, error)
				throw error
			})
	}
}

/**
 * stateに保存されているデータを一部更新する
 * 通知から左カラムの情報を更新したい場合など。
 */
export const merge = (req) => {
	return (dispatch) => dispatch(resultSuccess(CHAT_REQUEST_MERGE, req))
}

/**
 * stateに保存されているデータを一部削除する
 * 通知から左カラムの情報を更新したい場合など。
 */
export const del = (userId) => {
	return (dispatch) => dispatch(resultSuccess(CHAT_REQUEST_DELETE, userId))
}

export const addItemToList = (listSet, item, idItemSet, typeDispatch) => {
	if (listSet[idItemSet] !== undefined) {
		// listSet[idItemSet].unreadMsgBadge = listSet[idItemSet].unreadMsgBadge + 1;
	} else {
		listSet = Object.assign({ [idItemSet]: item }, listSet)
	}
	return (dispatch) => dispatch(resultSuccess(typeDispatch, listSet))
}

export const removeItemFromList = (listSet, idItem, typeDispatch) => {
	if (listSet[idItem] !== undefined) {
		delete listSet[idItem]
	}
	return (dispatch) => dispatch(resultSuccess(typeDispatch, listSet))
}

export const listChatRequest = (listSet = {}) => {
	return (dispatch) => dispatch(resultSuccess(CHAT_REQUEST_NEW, listSet))
}

export const requestChatUnRead = (req) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat_unread_v2
		let params = [
			'tab=all',
			`page=${req.page}`,
			`size=${req.size}`,
			`inConversation=${req.inConversation}`,
		]
		let makeParam = `?${params.join('&')}`
		axios
			.get(url + makeParam, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				// dispatch(resultSuccess(CHAT_REQUEST_DEFAULT, response.data.data));
				dispatch(resultSuccess(CHAT_REQUEST_UNREAD, response.data.data))
			})
	}
}

export const requestChatRead = (req) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat_read_v2
		let params = [
			'tab=all',
			`page=${req.page}`,
			`size=${req.size}`,
			`inConversation=${req.inConversation}`,
		]
		let makeParam = `?${params.join('&')}`
		axios
			.get(url + makeParam, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				// dispatch(resultSuccess(CHAT_REQUEST_DEFAULT, response.data.data));
				dispatch(resultSuccess(CHAT_REQUEST_READ, response.data.data))
			})
	}
}
export const requestChatReadFirstOpen = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat_read_v2
		let params = [
			'tab=all',
			'page=0',
			'size=24',
			'inConversation=true',
		]
		let makeParam = `?${params.join('&')}`
		return axios
			.get(url + makeParam, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				// dispatch(resultSuccess(CHAT_REQUEST_DEFAULT, response.data.data));
				return new Promise(function (resolve, reject) {
					resolve(response.data.data)
				})
			})
	}
}
export const requestChatUnReadFirstOpen = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat_unread_v2
		let params = [
			'tab=all',
			'page=0',
			'size=24',
			'inConversation=true',
		]
		let makeParam = `?${params.join('&')}`
		return axios
			.get(url + makeParam, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				// dispatch(resultSuccess(CHAT_REQUEST_DEFAULT, response.data.data));
				return new Promise(function (resolve, reject) {
					resolve(response.data.data)
				})
			})
	}
}

export const addChatDraft = (chatDraft) => {
	return (dispatch) => {
		dispatch(resultSuccess(ADD_CHAT_DRAFT, chatDraft))
	}
}

export const changeStatusChatDraft = (chatDraft) => {
	return (dispatch) => {
		dispatch(resultSuccess(CHANGE_STATUS_CHAT_DRAFT, chatDraft))
	}
}


