import { useState } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { MICROPHONE_STATUS } from '../../../constants/Config'

export default function useAgoraRTC() {
	const [microphones, setMicrophones] = useState([])
	const [playbackDevices, setPlaybackDevices] = useState([])
	const [microStatus, setMicroStatus] = useState('')
	const [playbackStatus, setPlaybackStatus] = useState('')

	const getMicrophones = async () => {
		AgoraRTC.getMicrophones()
			.then((microphones) => {
				setMicrophones(microphones)
			})
			.catch((error) => {
				console.log(
					'%c  error:',
					'color: #0e93e0;background: #aaefe5;',
					error.code
				)
			})
	}

	const getPlaybackDevices = async () => {
		AgoraRTC.getPlaybackDevices()
			.then((playbackDevices) => {
				setPlaybackDevices(playbackDevices)
			})
			.catch((error) => {
				console.log(
					'%c  error:',
					'color: #0e93e0;background: #aaefe5;',
					error.code
				)
			})
	}

	const onMicrophoneChanged = async () => {
		AgoraRTC.onMicrophoneChanged = (info) => {
			console.log('MICROPHONE changed!', info.state)
			setMicroStatus(info.state)
		}
	}

	const onPlaybackDeviceChanged = async () => {
		AgoraRTC.onPlaybackDeviceChanged = (info) => {
			console.log('SPEAKER changed!', info.state)
			setPlaybackStatus(info.state)
		}
	}

	return {
		microphones,
		microStatus,
		playbackDevices,
		playbackStatus,
		getMicrophones,
		getPlaybackDevices,
		onMicrophoneChanged,
		onPlaybackDeviceChanged,
	}
}
