/**
 * チャット申し込みダイアログ
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Dialog from 'material-ui/Dialog'

class DialogPointGift extends Component {
	constructor(props) {
		super(props)
		/**
		 * show: ダイアログ true:表示,false:非表示
		 * time: カウントダウン表示秒数
		 */
		this.state = {
			show: false,
			data: {},
		}
	}

	_onOk = () => {
		const { closeFunc, data, history, location } = this.props
		const pathNew = `/home/chat/${data.fromId}`
		if (location.pathname != pathNew) history.push(pathNew)
		closeFunc()
	}

	render() {
		const { show, data, closeFunc } = this.props

		if (!show) {
			return null
		}

		return (
			<div>
				<Dialog
					modal={false}
					open={show}
					bodyClassName="dialog__content"
					contentClassName="dialog-content"
					onRequestClose={closeFunc}
				>
					<p style={{ textAlign: 'center' }}>
						{data.friendName}さんからギフトが贈られました！
					</p>

					<img
						style={{
							backgroundColor: '#ccc',
							width: 150,
							height: 150,
							margin: '20px auto',
							overflow: 'hidden',
							borderRadius: 75,
							objectFit: 'cover',
						}}
						src={data.giftFilePath}
					/>

					<p style={{ textAlign: 'center' }}>
						{parseFloat(data.point).toFixed(2)}加算
					</p>

					<div className="logout-dialog-btn-wrap">
						<div className="btn-wrap margin_bottom btn-dialog">
							<button
								onClick={this._onOk}
								className="btn-raised color_default"
							>
								OK
							</button>
						</div>
						<div className="btn-wrap margin_bottom btn-bottom-dialog">
							<a
								href="javascript:;"
								onClick={closeFunc}
								style={{ color: '#c6c6c6' }}
							>
								キャンセル
							</a>
						</div>
					</div>
				</Dialog>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default withRouter(connect(mapStateToProps)(DialogPointGift))
