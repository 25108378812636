import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CATEGORY_MISSTION, glasConfig, MissionLevel, MissionLevelId, MissionLevelV2 } from '../constants/Config'
import MissionMenu from './MissionMenu'
import * as MissionActions from '../actions/MissionActions'
import * as moment from 'moment'
import { isMobile } from 'react-device-detect'
import MissionDefault from './Mission/MissionDefault'

class MissionArchived extends Component {
	constructor(props) {
		super(props)
		this.state = {
			page: 0,
			hasLoadMore: true
		}
		this.loadMore = this.loadMore.bind(this)
		this.fetchDataMission = this.fetchDataMission.bind(this)
	}
	loadMore() {
		const { Mission } = this.props
		if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
			if (Mission.missionAchieved.isHasMission) {
				this.setState({
					page: this.state.page + 1,
					hasLoadMore: false
				})
			}
			this.fetchDataMission(this.state.page)
		}
	}

	fetchDataMission(page) {
		const { dispatch } = this.props
		dispatch(MissionActions.getMissionsAchivedV2(CATEGORY_MISSTION.ACHIEVED, page))
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.loadMore)
	}

	componentDidMount() {
		const { dispatch, Mission } = this.props
		if (!Mission.loaded.archivedMissions) {
			dispatch(MissionActions.getArchivedMissions())
		}
		if (this.state.hasLoadMore) {
			dispatch(MissionActions.resetMission(CATEGORY_MISSTION.ACHIEVED))
			this.fetchDataMission(this.state.page)
		}
		window.addEventListener('scroll', this.loadMore)
	}

	render() {
		const { Mission } = this.props
		const missions = Mission.archivedMissions.map((mission) => {
			return <ArchivedItem mission={mission} />
		})
		const missions_v2 = Mission.missionAchieved.missions.map((mission) => {
			return <ArchivedItem mission={mission} />
		})

		let content = isMobile ? missions : missions_v2
		if((isMobile && !missions.length) || (!isMobile && !missions_v2.length)) {
			content = <MissionDefault />
		}

		return (
			<div className="content setting-mission">
				<h1 className="content__title">ミッション</h1>
				<MissionMenu />
				<div className="primary" id={!isMobile && "unset-height-mission"}>
					<ul>{content}</ul>
				</div>
			</div>
		)
	}
}

class ArchivedItem extends Component {
	render() {
		const { mission } = this.props
		let date = new Date()
		let addHours = Math.abs(date.getTimezoneOffset()) / 60

		return (
			<li
				key={mission.id}
				className={isMobile ? `mission-item mission-archived-item mission-level-${mission.catalog} clearfix` : `mission-item mission-archived-item mission-level-${mission.catalog === MissionLevelId.DAILY || mission.catalog === MissionLevelId.SPECIAL ? mission.catalog : 'startdash'} clearfix`}
			>
				<div className="mission-archived-item__left"
					id={!isMobile && "archived-img-center"}
					>
					<img src="/img/mission_done.png" />
				</div>
				<div className="mission-archived-item__right">
					<div className="mission-item__header clearfix"
						id={!isMobile && "archived-hidden-top"}
						>
						<span className="mission-item__level">
							{isMobile ? MissionLevel[mission.catalog] : MissionLevelV2[mission.catalog]}
						</span>
						{!isMobile && <div className='block-line' ></div>}
						<span className="mission-item__title">
							{mission.title}
						</span>
					</div>
					<div className="mission-item__footer clearfix">
						<span className="mission-item__archivedTime">
							達成日：
							{moment(mission.archievedPointTime).format(
								'YYYY/MM/DD'
							)}
						</span>
						<span className="mission-item__point">
							報酬：
							<span>
								{Number.isInteger(mission.grandedPoint)
									? mission.grandedPoint
									: mission.grandedPoint.toFixed(2)}
								PT
							</span>
						</span>
					</div>
				</div>
			</li>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		Mission: state.Mission,
	}
}

export default connect(mapStateToProps)(MissionArchived)
