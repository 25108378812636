import React from 'react'
import { ButtonNavigate, ToolbarLabel, WrapperToolbar } from '../../StyledPointReport/Calendar/StyledCustomCalendar'
import { getNextMonth, getPreviousMonth, POINT_REPORT } from '../../../../constants/PointReportConstants'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import moment from 'moment'
import { colors } from '../../../../util/StellaConstant'
import * as PointReportActions from '../../../../actions/PointReportActions'
import { connect } from 'react-redux'

const Toolbar = (props) => {
	const { date, view, views, label, localizer, onNavigate, onView, children , dispatch} = props

	const onNavigateToPreviousMonth = () => {
		onNavigate(POINT_REPORT.BUTTON_NAVIGATE.PREVIOUS)

		// call api get point for prev month
		const currentMonth = moment(date)
		const prevMonth = getPreviousMonth(currentMonth)
		dispatch(PointReportActions.getCalendarData({ month: prevMonth }))
	}

	const onNavigateToNextMonth = () => {
		onNavigate(POINT_REPORT.BUTTON_NAVIGATE.NEXT)

		// call api get point for next month
		const currentMonth = moment(date)
		const nextMonth = getNextMonth(currentMonth)
		dispatch(PointReportActions.getCalendarData({ month: nextMonth }))
	}

	const isDisabledFutureMonth = moment(date).isSame(new Date(), 'month')

	return (
		<WrapperToolbar>
			<ButtonNavigate
				type="button"
				buttonType={POINT_REPORT.BUTTON_NAVIGATE.PREVIOUS}
				onClick={onNavigateToPreviousMonth}
			>
				<ArrowBackIosIcon fontSize='12'/>
			</ButtonNavigate>
			<ToolbarLabel>{label}</ToolbarLabel>
			<ButtonNavigate
				type="button"
				buttonType={POINT_REPORT.BUTTON_NAVIGATE.NEXT}
				disabled={isDisabledFutureMonth}
				onClick={onNavigateToNextMonth}
				style={{
					cursor: isDisabledFutureMonth ? 'not-allowed' : 'pointer'
				}}
			>
				<ArrowForwardIosIcon
					fontSize='12'
					style={{
						color: isDisabledFutureMonth ? colors.oldLavender : colors.black,
						opacity: isDisabledFutureMonth ? 0.4 : 1
					}}
				/>
			</ButtonNavigate>
		</WrapperToolbar>
	)
}

const mapStateToProps = (state) => {
	return {
		PointReport: state.PointReport
	}
}

export default connect(mapStateToProps)(Toolbar)