import React from 'react';
import { Component } from "react";
import { connect } from 'react-redux';
import DialogNotifi from '../Dialog';

class FloatingEvent extends Component {
    constructor(props) {
        super(props)
    }

    openDialog(item) {
        DialogNotifi.getDialog().handleOpen(
            'EVENT_RANKING',
            { item }
        )
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.RankingEvent.dataAnnounced.length > 0) {
            DialogNotifi.getDialog().handleOpen(
                'EVENT_RANKING',
                { item: nextProps.RankingEvent.dataAnnounced[0] }
            )
        }
    }

    render() {
        const lstEvent = this.props.RankingEvent.data
        return (
            <div style={{ display: 'flex' }}>
                {lstEvent.map((i, index) => {
                    return (
                        <div key={i.rankingEventId} onClick={() => this.openDialog(i)}
                            className='ranking_event_icon_floating'
                            style={{ right: 145 * index + (10 * index + 20) }}
                        >
                            <img src="/img/ic_floating_event.png" />
                        </div>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        RankingEvent: state.RankingEvent
    }
}

export default connect(mapStateToProps)(FloatingEvent)