import {
	CHAT_REQUEST_DEFAULT,
	CHAT_REQUEST_READ,
	CHAT_REQUEST_READ_REFRESH,
} from '../constants/ActionTypes'
import { getUserId } from '../assets/help/help'

const initialState = {
	loaded: false,
	data: {},
}

export const ChatRequestRead = (state = initialState, action) => {
	switch (action.type) {
		case CHAT_REQUEST_READ: {
			let listNew = {}
			if (action.data.length > 0) {
				action.data.map((item) => {
					Object.assign(listNew, { [getUserId(item)]: item })
				})
			}
			return {
				loaded: true,
				data: listNew,
			}
		}
		case CHAT_REQUEST_READ_REFRESH: {
			return {
				loaded: true,
				data: action.data,
			}
		}
		case CHAT_REQUEST_DEFAULT: {
			return {
				loaded: false,
				data: {},
			}
		}
		default: {
			return state
		}
	}
}
