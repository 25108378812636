/**
 * 年齢認証のダイアログ
 *
 */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

class DialogAgeAuth extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
		}
	}

	componentWillMount() {
		const { show } = this.props
		this.setState({
			show: show,
		})
	}

	render() {
		const { closeFunc } = this.props

		if (!this.state.show) {
			return null
		}

		const handleClose = () => {
			closeFunc()
			this.props.history.push(`/home`)
			return null
		}

		const handleAgeAuth = () => {
			closeFunc()
			this.props.history.push(`/home/setting/ageauth`)
			return null
		}

		const actions = [
			<FlatButton
				label="キャンセル"
				primary={true}
				keyboardFocused={true}
				onClick={(e) => handleClose()}
			/>,
			<FlatButton
				label="年齢認証する"
				primary={true}
				keyboardFocused={true}
				onClick={(e) => handleAgeAuth()}
			/>,
		]

		return (
			<div>
				<Dialog
					title="年齢認証"
					modal={true}
					actions={actions}
					open={this.state.show}
				>
					<div>お仕事を開始するには年齢認証を行ってください。</div>
				</Dialog>
			</div>
		)
	}
}

export default withRouter(DialogAgeAuth)
