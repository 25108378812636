import axios from 'axios'

let assignObjectHeader = {
	'access-control-allow-methods': 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
	'access-control-allow-headers':
		'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
	'access-control-allow-origin': '*',
	'content-type': 'application/json',
	allow: 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
	'access-control-expose-headers': 'Access-Control-*',
}

/*axit post*/
export async function axiosPOST(Url, param = {}, header = {}) {
	//Object.assign(header, assignObjectHeader);
	return axios
		.post(Url, param, { headers: header })
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			return err.response
		})
}

/*axit get*/
export async function axiosGET(Url, header = {}) {
	Object.assign(header, assignObjectHeader)
	return axios
		.get(Url, { headers: header })
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			return err.response
		})
}

/*axit delete*/
export async function axiosDELETE(Url, header = {}) {
	Object.assign(header, assignObjectHeader)
	return await axios
		.delete(Url, { headers: header })
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			return err.response
		})
}

/*axit put*/
export async function axiosPUT(Url, header = {}) {
	Object.assign(header, assignObjectHeader)
	return await axios
		.put(Url, { headers: header })
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			return err.response
		})
}
