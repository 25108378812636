import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'

export default class DialogFirstLogin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
		}
	}

	gotoBlogDetailScreen = () => {
		this.setState({ show: false })
		this.props.history.push('/home/staffblog/detail/1925')
	}

	gotoSettingProfileScreen = () => {
		this.setState({ show: false })
		this.props.history.push('/home/setting')
	}

	openDialog = () => {
		this.setState({ show: true })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				className="dialogReplyConfirm dialogReplyConfirmBlock dialogFirstLogin"
				style={{ alignItems: 'center' }}
			>
				<i
					class="material-icons tab__icons closeUnBlock dialog-first-login-button-close"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<div className="dialog-first-login-title dialog-first-login-content">
					Stellaへのご登録ありがとうございます！
				</div>
				<div className="dialog-first-login-content">
					お仕事を開始するためには下記の設定が必要です。
				</div>
				<div className="dialog-first-login-content">
					<div className="dialog-first-login-text-note">
						・年齢認証の完了
					</div>
					<div className="dialog-first-login-text-note">
						・プロフィール画像の認証完了
					</div>
					<div className="dialog-first-login-text-note">
						・プロフィール情報の登録
					</div>
				</div>
				<div className="dialog-first-login-content">
					登録の仕方にご不安があれば、
					<span
						className="dialog-first-login-text-link"
						onClick={this.gotoBlogDetailScreen}
					>
						お仕事マニュアル
					</span>
					をご確認ください
				</div>
				<div className="dialog-first-login-area-button">
					<button
						className="submitUnBlock dialog-first-login-button"
						onClick={this.gotoSettingProfileScreen}
					>
						設定する
					</button>
				</div>
			</Dialog>
		)
	}
}
