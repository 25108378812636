/* eslint-disable no-mixed-spaces-and-tabs */
import { isMobile } from 'react-device-detect'
import {
	MISSION_GET,
	MISSION_GET_ARCHIVED,
	MISSION_START,
	MISSION_GET_POINT,
	MISSION_GET_V2,
	MISSION_GET_START_DASH,
	MISSION_GET_DAILY,
	MISSION_GET_SPECIAL,
	MISSION_GET_ARCHIVED_V2,
	MISSION_RESET
} from '../constants/ActionTypes'
import { MissionLevelId } from '../constants/Config'

const initialState = {
	loaded: {
		missions: false,
		archivedMissions: false,
	},
	missions: [],
	missions_v2: [],
	missionStartDash: {
		missions: [],
		isHasMission: false
	},
	missionDaily: {
		missions: [],
		isHasMission: false
	},
	missionSpecial: {
		missions: [],
		isHasMission: false
	},
	missionAchieved: {
		missions: [],
		isHasMission: false
	},
	archivedMissions: [],
}

export const Mission = (state = initialState, action) => {
	let loaded, newState
	switch (action.type) {
		case MISSION_GET:
			let missions = Object.assign([], action.data)
			return Object.assign({}, state, { missions })
		case MISSION_GET_V2:
			let missions_v2 = Object.assign([], action.data)
			return Object.assign({}, state, { missions_v2 })
		case MISSION_GET_START_DASH:
			let missions_startdash = state.missionStartDash.missions.concat(action.data)
			const unique_missions_startdash = missions_startdash
				.map(e => e['id'])
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter(obj => missions_startdash[obj])
				.map(e => missions_startdash[e])
			return Object.assign({}, state, {
				missionStartDash: {
					missions: unique_missions_startdash,
					isHasMission: action.data.length > 0 ? true : false
				}
			})
		case MISSION_GET_DAILY:
			let missions_daily = state.missionDaily.missions.concat(action.data)
			const unique_missions_daily = missions_daily
				.map(e => e['id'])
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter(obj => missions_daily[obj])
				.map(e => missions_daily[e])
			return Object.assign({}, state, {
				missionDaily: {
					missions: unique_missions_daily,
					isHasMission: action.data.length > 0 ? true : false
				}
			})
		case MISSION_GET_SPECIAL:
			let missions_special = state.missionSpecial.missions.concat(action.data)
			const unique_missions_special = missions_special
				.map(e => e['id'])
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter(obj => missions_special[obj])
				.map(e => missions_special[e])
			return Object.assign({}, state, {
				missionSpecial: {
					missions: unique_missions_special,
					isHasMission: action.data.length > 0 ? true : false
				}
			})
		case MISSION_GET_ARCHIVED_V2:
			let missions_achieved = state.missionAchieved.missions.concat(action.data)
			const unique_missions_achieved = missions_achieved
				.map(e => e['id'])
				.map((e, i, final) => final.indexOf(e) === i && i)
				.filter(obj => missions_achieved[obj])
				.map(e => missions_achieved[e])
			return Object.assign({}, state, {
				missionAchieved: {
					missions: unique_missions_achieved,
					isHasMission: action.data.length > 0 ? true : false
				}
			})
		case MISSION_GET_ARCHIVED:
			let archivedMissions = Object.assign([], action.data)
			archivedMissions.sort(function (a, b) {
				return b.archievedPointTime - a.archievedPointTime
			})
			loaded = Object.assign({}, state.loaded)
			loaded.archivedMissions = true
			return Object.assign({}, state, { archivedMissions, loaded })
		case MISSION_START:
			if (action.data !== null) {
				newState = Object.assign({}, state)
				state.missions.forEach((mission, index) => {
					if (mission.id == action.data.id) {
						newState.missions[index].tellerStart = true
						newState.missions[index].completeUnit =
							action.data.completeUnit
					}
				})
				return Object.assign({}, newState)
			}

			return state
		case MISSION_GET_POINT:
			if (action.data !== null) {
				newState = Object.assign({}, state)
				if (isMobile) {
					state.missions.forEach((mission, index) => {
						if (mission.id == action.data.id) {
							newState.missions.splice(index, 1)
							newState.archivedMissions.unshift(mission)
						}
					})
				} else if (action.data.isHome) {
					state.missions_v2.forEach((mission, index) => {
						if (mission.id == action.data.id) {
							newState.missions_v2.splice(index, 1)
							newState.missionAchieved.missions.unshift(mission)
						}
					})
				}
				else {
					if (action.data.catelog === MissionLevelId.DAILY) {
						state.missionDaily.missions.forEach((mission, index) => {
							if (mission.id == action.data.id) {
								newState.missionDaily.missions.splice(index, 1)
								newState.missionAchieved.missions.unshift(mission)
							}
						})
					} else if (action.data.catelog === MissionLevelId.SPECIAL) {
						state.missionSpecial.missions.forEach((mission, index) => {
							if (mission.id == action.data.id) {
								newState.missionSpecial.missions.splice(index, 1)
								newState.missionAchieved.missions.unshift(mission)
							}
						})
					}
					else {
						state.missionStartDash.missions.forEach((mission, index) => {
							if (mission.id == action.data.id) {
								newState.missionStartDash.missions.splice(index, 1)
								newState.missionAchieved.missions.unshift(mission)
							}
						})
					}
				}
				return Object.assign({}, newState)
			}
			return state
		case MISSION_RESET :
			if (action.data === MISSION_GET_SPECIAL) {
				return Object.assign({}, state, {
					missionSpecial: {
						missions: [],
						isHasMission: true
					}
				})
			} else if (action.data === MISSION_GET_START_DASH) {
				return Object.assign({}, state, {
					missionStartDash: {
						missions: [],
						isHasMission: true
					}
				})

			} else if (action.data === MISSION_GET_ARCHIVED_V2) {
				return Object.assign({}, state, {
					missionAchieved: {
						missions: [],
						isHasMission: true
					}
				})
			} else if (action.data === MISSION_GET_DAILY) {
				return Object.assign({}, state, {
					missionDaily: {
						missions: [],
						isHasMission: true
					}
				})
			}
			
		default:
			return state
	}
}
