import {
	GO_TO_POINT_REPORT_SCREEN_AT_FIRST_TIME,
	SET_TARGET_POINT,
	GET_CHART_DATA,
	GET_CALENDAR_DATA,
	GET_COMMON_DATA_FOR_POINT_REPORT,
	GET_DEFAULT_TARGET_POINT
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

export const updateStatusPointReportScreen = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.put(
				`${glasConfig.url_base}${glasConfig.update_status_point_report_screen}`,
				{},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(GO_TO_POINT_REPORT_SCREEN_AT_FIRST_TIME, ''))
				}
			})
			.catch((error) => {
				throw error
			})
	}
}

export const setTargetPoint = (params) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		request
			.post(
				`${glasConfig.url_base}${glasConfig.path_set_target_point}`,
				params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code == 0) {
					dispatch(resultSuccess(SET_TARGET_POINT, {targetPoint: params.targetPoint, option: params.option }))
				}
			})
			.catch(error => { throw error })
	}
}

export const getDefaultTargetPoint = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		return request
			.get(glasConfig.url_base + glasConfig.path_point_report_get_default_target_point, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(GET_DEFAULT_TARGET_POINT, response.data.data))
				}
			})
			.catch(error => {
				throw error
			})
	}
}

export const getChartData = (params) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		return request
			.get(`${glasConfig.url_base}${glasConfig.path_point_report_get_chart_data}?monthTime=${params.monthTime}`, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(GET_CHART_DATA, response.data.data))
				}
			})
			.catch(error => {
				throw error
			})
	}
}

export const getCalendarData = (params) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		return request
			.get(`${glasConfig.url_base}${glasConfig.path_point_report_get_calendar_data}?monthTime=${params.month}`, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(GET_CALENDAR_DATA, {data: response.data.data, currentMonth: params.month}))
				}
			})
			.catch(error => {
				throw error
			})
	}
}

export const getCommonData = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')

		return request
			.get(`${glasConfig.url_base}${glasConfig.path_point_report_get_common_data}`, {
				headers: {
					Authorization: token
				}
			})
			.then(response => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(GET_COMMON_DATA_FOR_POINT_REPORT, response.data.data))
				}
			})
			.catch(error => {
				throw error
			})
	}
}

//レビュー取得
// export const reviewGet = (params) => {
//   return (dispatch) => {
//     const token = localStorage.getItem('token')

//     return request
//       .get(glasConfig.url_base + glasConfig.path_review + params, {
//         headers: {
//           Authorization: token
//         }
//       })
//       .then(response => {
//         if (response.data.code === 0) {
//           dispatch(resultSuccess(REVIEW_GET, response.data))
//         } else {
//           throw 'システムエラー'
//         }
//       })
//       .catch(error => {
//         console.log('error on call ' + REVIEW_GET, error)
//         throw error
//       })
//   }
// }

// export const getPinedReviews = (params) => {
// 	return (dispatch) => {
// 		const { tellerId } = params

// 		const token = localStorage.getItem('token')
// 		return request
// 			.get(
// 				`${glasConfig.url_base}${glasConfig.path_pined_review}?fortuneTellerId=${tellerId}`,
// 				{
// 					headers: {
// 						Authorization: token,
// 					},
// 				}
// 			)
// 			.then((response) => {
// 				if (response.data.code === 0) {
// 					if (response.data.data !== null) {
// 						dispatch(
// 							resultSuccess(GET_PINED_REVIEW, response.data.data)
// 						)
// 					} else {
// 						dispatch(resultSuccess(GET_PINED_REVIEW, []))
// 					}
// 				}
// 			})
// 			.catch((error) => {
// 				console.error(error)
// 				throw error
// 			})
// 	}
// }
// export const getReviewsWithFilters = (params) => {
// 	return (dispatch) => {
// 		const { starFilter, contentFilter, page, size, initial } = params
// 		const token = localStorage.getItem('token')
// 		return request
// 			.get(
// 				`${glasConfig.url_base}${glasConfig.path_review_v2}?optionStar=${starFilter}&havingContent=${contentFilter}&page=${page}&size=${size}`,
// 				{
// 					headers: {
// 						Authorization: token,
// 					},
// 				}
// 			)
// 			.then((response) => {
// 				if (response.data.code === 0) {
// 					if (response.data.data !== null) {
// 						dispatch(
// 							resultSuccess(GET_REVIEWS_WITH_FILTERS, {
// 								responseData: response.data.data,
// 								initial,
// 							})
// 						)
// 					} else {
// 						dispatch(resultSuccess(GET_REVIEWS_WITH_FILTERS, []))
// 					}
// 				}
// 			})
// 			.catch((error) => {
// 				console.error(error)
// 				throw error
// 			})
// 	}
// }

// export const updateReviewTutorial = () => {
// 	return (dispatch) => {
// 		const token = localStorage.getItem('token')
// 		return request
// 			.put(
// 				`${glasConfig.url_base}${glasConfig.path_update_review_tutorial}`,
// 				{},
// 				{
// 					headers: {
// 						Authorization: token,
// 					},
// 				}
// 			)
// 			.then((response) => {
// 				if (response.data.code === 0) {
// 					console.log('Updated')
// 				}
// 			})
// 			.catch((error) => {
// 				console.error(error)
// 				throw error
// 			})
// 	}
// }

// export const pinReview = (params) => {
// 	return (dispatch) => {
// 		const { reviewId } = params
// 		const token = localStorage.getItem('token')
// 		return request
// 			.put(
// 				`${glasConfig.url_base}${glasConfig.path_pin_review}?reviewId=${reviewId}`,
// 				{},
// 				{
// 					headers: {
// 						Authorization: token,
// 					},
// 				}
// 			)
// 			.then((response) => {
// 				if (response.data.code === 0) {
// 					dispatch(resultSuccess(PIN_REVIEW, reviewId))
// 				}
// 			})
// 			.catch((error) => {
// 				console.error(error)
// 				throw error
// 			})
// 			.finally(() => {
// 				dispatch(resultSuccess(RESET_STATUS))
// 			})
// 	}
// }

// export const unpinReview = (params) => {
// 	return (dispatch) => {
// 		const { reviewId } = params
// 		const token = localStorage.getItem('token')
// 		return request
// 			.put(
// 				`${glasConfig.url_base}${glasConfig.path_unpin_review}?reviewId=${reviewId}`,
// 				{},
// 				{
// 					headers: {
// 						Authorization: token,
// 					},
// 				}
// 			)
// 			.then((response) => {
// 				if (response.data.code === 0) {
// 					dispatch(resultSuccess(UNPIN_REVIEW, reviewId))
// 				}
// 			})
// 			.catch((error) => {
// 				console.error(error)
// 				throw error
// 			})
// 			.finally(() => {
// 				dispatch(resultSuccess(RESET_STATUS))
// 			})
// 	}
// }

// export const updatePinStatusOfReviewItem = (params) => {
// 	return (dispatch) => {
// 		const { reviewId, status } = params
// 		dispatch(
// 			resultSuccess(UPDATE_PIN_STATUS_OF_REVIEW_ITEM, {
// 				reviewId,
// 				status,
// 			})
// 		)
// 	}
// }

// export const resetStatus = () => {
// 	return (dispatch) => {
// 		dispatch(resultSuccess(RESET_STATUS))
// 	}
// }
// export const resetReviewList = () => {
// 	return (dispatch) => {
// 		dispatch(resultSuccess(RESET_REVIEW_LIST))
// 	}
// }
