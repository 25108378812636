import React, { Component } from 'react'
import imgErr from '../assets/stenny.png'

class Maintenance extends Component {
	render() {
		return (
			<div style={style.container}>
				<h3 style={style.title}>*メンテナンスのお知らせ*</h3>
				<img style={style.imgErr} src={imgErr} alt={''} />
				<p style={style.body}>ただいまメンテナンス中のため</p>
				<p style={style.body}>
					ステラのサービスがご利用いただけません。
				</p>
				<p style={style.body}>
					メンテナンス終了までしばらくお待ちください
				</p>
			</div>
		)
	}
}

const style = {
	container: {
		backgroundColor: '#F6F6F6',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	title: {
		fontWeight: 'bold',
		fontSize: 24,
	},
	body: {
		paddingBottom: 10,
		color: '#504E53',
	},
	imgErr: {
		width: 200,
		height: 300,
		margin: 20,
		objectFit: 'contain',
	},
}

export default Maintenance
