import React from 'react'
import { useStopwatch } from 'react-timer-hook'

const TimerInCall = () => {
	const { seconds, minutes, hours } = useStopwatch({ autoStart: true })

	const formatedSeconds = seconds < 10 ? `0${seconds}` : seconds
	const formatedMinutes = minutes < 10 ? `0${minutes}` : minutes
	const getFormatedHours = () => {
		let result
		if(hours === 0) {
			result = ''
		} else if(0 < hours < 10) {
			result = `0${hours}:`
		} else {
			result = `${hours}:`
		}
		return result
	}

	const formatedHours = getFormatedHours()
	return (
        <>
            <span>{formatedHours}</span>
            <span>{formatedMinutes}</span>
            :
            <span>{formatedSeconds}</span>
        </>
	)
}

export default TimerInCall
