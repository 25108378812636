import { useState, useEffect } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { BUTTON_INCALL } from '../../../constants/CallConstants'

export default function useAgora(client) {
	// const [localVideoTrack, setLocalVideoTrack] = useState(undefined);
	const [localAudioTrack, setLocalAudioTrack] = useState(undefined)
	// console.log('%c localAudioTrack: ', 'color: red' , localAudioTrack);

	const [joinState, setJoinState] = useState(false)

	const [remoteUsers, setRemoteUsers] = useState([])

	async function createLocalAudioTracks(audioConfig) {
		const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack(
			audioConfig
		)
		setLocalAudioTrack(microphoneTrack)
		return microphoneTrack
	}

	async function join(appid, channel, token, uid = null) {
		if (!client) return
		const microphoneTrack = await createLocalAudioTracks()
		await client.join(appid, channel, token, uid)

		await client.publish([microphoneTrack])

		window.client = client
		// window.videoTrack = cameraTrack;
		setJoinState(true)
	}
	async function leave() {
		if (localAudioTrack) {
			localAudioTrack.stop()
			localAudioTrack.close()
		}
		// if (localVideoTrack) {
		//   localVideoTrack.stop();
		//   localVideoTrack.close();
		// }
		setRemoteUsers([])
		setJoinState(false)
		await client.leave()
	}

	const mute = (type = '', remoteUsers = []) => {
		if (type === BUTTON_INCALL.MICRO) {
			localAudioTrack.setEnabled(false)
		} else if (type === BUTTON_INCALL.SPEAKER) {
			remoteUsers.map((user) => user.audioTrack.setVolume(0))
		} else {
			return
		}
	}

	const unMute = (type = '', remoteUsers = []) => {
		if (type === BUTTON_INCALL.MICRO) {
			localAudioTrack.setEnabled(true)
		} else if (type === BUTTON_INCALL.SPEAKER) {
			remoteUsers.map((user) => user.audioTrack.setVolume(100))
		} else {
			return
		}
	}

	useEffect(() => {
		if (!client) return
		setRemoteUsers(client.remoteUsers)

		const handleUserPublished = async (user, mediaType) => {
			await client
				.subscribe(user, mediaType)
				.then((response) => console.log(response))
				.catch((err) => console.log(err))
			// toggle rerender while state of remoteUsers changed.
			setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers))
		}
		const handleUserUnpublished = (user) => {
			setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers))
		}
		const handleUserJoined = (user) => {
			console.log(
				'%c  USER JOINED:',
				'color: #0e93e0;background: #aaefe5;',
				user
			)
			setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers))
		}
		const handleUserLeft = (user) => {
			console.log(
				'%c  USER LEFT:',
				'color: #0e93e0;background: #aaefe5;',
				user
			)
			setRemoteUsers((remoteUsers) => Array.from(client.remoteUsers))
		}

		client.on('user-published', handleUserPublished)
		client.on('user-unpublished', handleUserUnpublished)
		client.on('user-joined', handleUserJoined)
		client.on('user-left', handleUserLeft)
		return () => {
			client.off('user-published', handleUserPublished)
			client.off('user-unpublished', handleUserUnpublished)
			client.off('user-joined', handleUserJoined)
			client.off('user-left', handleUserLeft)
		}
	}, [client])

	return {
		localAudioTrack,
		// localVideoTrack,
		joinState,
		leave,
		join,
		remoteUsers,
		mute,
		unMute,
	}
}
