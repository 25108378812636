import React, { Component } from 'react'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import Snackbar from 'material-ui/Snackbar'
import KeyboardDatePicker from 'material-ui/DatePicker'
import RewardMenu from './RewardMenu'
import * as Fetch from '../util/Fetch'

export default class RewardHistory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fromDate: '',
			toDate: '',
			error: false,
			messageError: '',
			isDisableBtn: false,
		}
	}

	/**
	 * 精算履歴を取得
	 */
	getHistory() {
		const url = glasConfig.url_outside + glasConfig.get_history_export
		const params = {
			from_date: Fetch.formatDate(this.state.fromDate, 'YYYY-MM-DD'),
			to_date: Fetch.formatDate(this.state.toDate, 'YYYY-MM-DD'),
		}
		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			params,
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.status === 200) {
					const fromDate = Fetch.formatDate(
						this.state.fromDate,
						'YYYY-MM-DD'
					)
					const toDate = Fetch.formatDate(
						this.state.toDate,
						'YYYY-MM-DD'
					)
					this.downloadFile(
						response.data.src,
						`data${fromDate}_${toDate}.pdf`
					)
				}
			})

			.catch((error) => {})

			.finally(() => {
				this.setState({
					isDisableBtn: false,
				})
			})
	}

	downloadFile(hostUrl, filename) {
		const file = glasConfig.url_webview + hostUrl
		if (window.navigator.msSaveOrOpenBlob)
			// IE10+
			window.navigator.msSaveOrOpenBlob(file, filename)
		else {
			// Others
			let link = document.createElement('a')
			link.href = file
			link.download = filename
			document.body.appendChild(link)
			link.click()

			setTimeout(function () {
				document.body.removeChild(link)
			}, 0)
		}
	}

	handleChangeDate(date, target) {
		//保存ボタンの表示
		this.setState({
			[target]: date,
		})
	}

	handleClose = () => {
		this.setState({
			error: false,
			messageError: '',
		})
	}

	handleExport = () => {
		const fromDate = new Date(this.state.fromDate).getTime()
		const toDate = new Date(this.state.toDate).getTime()

		if (isNaN(fromDate) || isNaN(toDate)) {
			this.setState({
				error: true,
				messageError: '期間は開始から終了まで選択してください。',
			})
			return
		}

		if (toDate - fromDate < 0) {
			this.setState({
				error: true,
				messageError: '期間指定が正しくありません。',
			})
			return
		}

		this.setState(
			{
				isDisableBtn: true,
			},
			() => this.getHistory()
		)
	}

	openDialogDate(target) {
		this.refs[target].openDialog()
	}

	render() {
		const DateTimeFormat = global.Intl.DateTimeFormat
		const options = {
			weekday: 'short',
			year: 'numeric',
			month: 'short',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			second: 'numeric',
		}
		const dateTimeFormat = new DateTimeFormat('ja-JP', options).format
		const DayWeek = ['日', '月', '火', '水', '木', '金', '土']

		return (
			<div
				className="content reward reward-payoff"
				style={{ paddingBottom: 'unset' }}
			>
				<h1 className="content__title">精算</h1>
				<RewardMenu />
				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main">支払証明書発行</h1>
					</div>
					<div className="primary__inner">
						<div
							className="frame-table hidden-mobile"
							style={myStyle.innerPayoff}
						>
							<p style={myStyle.textTitle}>
								指定頂いた期間に振込された支払証明書が発行されます。
							</p>
							<div style={myStyle.wrapDate}>
								<KeyboardDatePicker
									id="date-picker-dialog"
									DateTimeFormat={DateTimeFormat}
									locale="ja-JP"
									value={
										this.state.fromDate.length
											? ''
											: this.state.fromDate
									}
									firstDayOfWeek={0}
									name="nextloginDate"
									hintText="YYYY/MM/DD"
									okLabel="OK"
									cancelLabel="キャンセル"
									formatDate={(dt) =>
										`${dt.getFullYear()}年${
											dt.getMonth() + 1
										}月${dt.getDate()}日(${
											DayWeek[dt.getDay()]
										})`
									}
									onChange={(event, date) =>
										this.handleChangeDate(date, 'fromDate')
									}
									textFieldStyle={myStyle.inputFromDate}
									className="reward-payoff__from-date"
									hintStyle={myStyle.hintText}
									ref="fromDate"
								/>
								<div
									style={myStyle.wrapArrowIcon}
									onClick={() =>
										this.openDialogDate('fromDate')
									}
								>
									<span
										style={myStyle.arrowIcon}
										className={
											'glyphicon glyphicon-menu-down'
										}
									/>
								</div>
								<span style={myStyle.myIcon}>~</span>
								<KeyboardDatePicker
									DateTimeFormat={DateTimeFormat}
									locale="ja-JP"
									firstDayOfWeek={0}
									value={
										this.state.toDate.length
											? ''
											: this.state.toDate
									}
									name="nextloginDate"
									hintText="YYYY/MM/DD"
									okLabel="OK"
									cancelLabel="キャンセル"
									formatDate={(dt) =>
										`${dt.getFullYear()}年${
											dt.getMonth() + 1
										}月${dt.getDate()}日(${
											DayWeek[dt.getDay()]
										})`
									}
									onChange={(event, date) =>
										this.handleChangeDate(date, 'toDate')
									}
									textFieldStyle={myStyle.inputToDate}
									className="reward-payoff__to-date"
									hintStyle={myStyle.hintText}
									ref="toDate"
								/>
								<div
									style={myStyle.wrapArrowIcon}
									onClick={() =>
										this.openDialogDate('toDate')
									}
								>
									<span
										style={myStyle.arrowIcon}
										className={
											'glyphicon glyphicon-menu-down'
										}
									/>
								</div>
								<button
									disabled={this.state.isDisableBtn}
									onClick={this.handleExport}
									style={myStyle.btnExport}
									className="btn-raised color_default"
								>
									PDFをダウンロードする
								</button>
							</div>
							<p style={myStyle.textContent}>
								※支払証明を提出する際に必要な期間は事務局では回答できません。
								<br />
								※住所、氏名は自動的に入力されませんので、印刷後にご本人様にて記載をお願い致します。
								<br />
								※1月の第一営業日に振込される金額は12月に獲得した事が明確なため、12月分に含まれます。
							</p>
						</div>
						<div className="display-mobile">
							<p style={myStyle.textTitle}>
								指定頂いた期間に振込された支払証明書が発行されます。
							</p>
							<div style={myStyle.wrapDateMobile}>
								<KeyboardDatePicker
									DateTimeFormat={DateTimeFormat}
									locale="ja-JP"
									formatDate={dateTimeFormat}
									firstDayOfWeek={0}
									value={
										this.state.fromDate.length
											? ''
											: this.state.fromDate
									}
									name="nextloginDate"
									hintText="YYYY/MM/DD"
									okLabel="OK"
									cancelLabel="キャンセル"
									formatDate={(dt) =>
										`${dt.getFullYear()}年${
											dt.getMonth() + 1
										}月${dt.getDate()}日(${
											DayWeek[dt.getDay()]
										})`
									}
									onChange={(event, date) =>
										this.handleChangeDate(date, 'fromDate')
									}
									textFieldStyle={myStyle.inputFromDateMobile}
									className="reward-payoff__from-date"
									hintStyle={myStyle.hintTextMobile}
									ref="fromDate"
								/>
								<div
									style={myStyle.wrapArrowIconMobile}
									onClick={() =>
										this.openDialogDate('fromDate')
									}
								>
									<span
										style={myStyle.arrowIconMobile}
										className={
											'glyphicon glyphicon-menu-down'
										}
									/>
								</div>
								<span style={myStyle.myIcon}>~</span>
								<KeyboardDatePicker
									DateTimeFormat={DateTimeFormat}
									locale="ja-JP"
									formatDate={dateTimeFormat}
									value={
										this.state.toDate.length
											? ''
											: this.state.toDate
									}
									firstDayOfWeek={0}
									name="nextloginDate"
									hintText="YYYY/MM/DD"
									okLabel="OK"
									cancelLabel="キャンセル"
									formatDate={(dt) =>
										`${dt.getFullYear()}年${
											dt.getMonth() + 1
										}月${dt.getDate()}日(${
											DayWeek[dt.getDay()]
										})`
									}
									onChange={(event, date) =>
										this.handleChangeDate(date, 'toDate')
									}
									textFieldStyle={myStyle.inputToDateMobile}
									className="reward-payoff__to-date"
									hintStyle={myStyle.hintTextMobile}
									ref="toDate"
								/>
								<div
									style={myStyle.wrapArrowIconMobile}
									onClick={() =>
										this.openDialogDate('toDate')
									}
								>
									<span
										style={myStyle.arrowIconMobile}
										className={
											'glyphicon glyphicon-menu-down'
										}
									/>
								</div>
							</div>
							<div style={myStyle.buttonSubmit}>
								<button
									disabled={this.state.isDisableBtn}
									onClick={this.handleExport}
									style={myStyle.btnExportMobile}
									className="btn-raised color_default"
								>
									PDFをダウンロードする
								</button>
							</div>
							<p style={myStyle.textContentMobile}>
								※支払証明を提出する際に必要な期間は事務局では回答できません。
								<br />
								※住所、氏名は自動的に入力されませんので、印刷後にご本人様にて記載をお願い致します。
								<br />
								※1月の第一営業日に振込される金額は12月に獲得した事が明確なため、12月分に含まれます。
							</p>
						</div>
					</div>
				</div>
				<Snackbar
					open={this.state.error}
					message={this.state.messageError}
					autoHideDuration={5000}
					onRequestClose={(e) => this.handleClose(e)}
					className="message-dialog"
				/>
			</div>
		)
	}
}

const myStyle = {
	iconPayoff: {
		position: 'relative',
		right: 23,
	},
	inputToDate: {
		marginLeft: 14,
		border: '1px solid rgba(0, 0, 0, 0.26)',
		height: 36,
		width: 130,
		textAlign: 'center',
		fontSize: 13,
		lineHeight: '10px',
	},
	inputToDateMobile: {
		marginLeft: 8,
		border: '1px solid rgba(0, 0, 0, 0.26)',
		height: 30,
		width: 110,
		textAlign: 'center',
		fontSize: 13,
		lineHeight: '10px',
	},
	inputFromDate: {
		border: '1px solid rgba(0, 0, 0, 0.26)',
		height: 36,
		width: 130,
		textAlign: 'center',
		fontSize: 13,
		lineHeight: '10px',
	},
	inputFromDateMobile: {
		border: '1px solid rgba(0, 0, 0, 0.26)',
		height: 30,
		width: 110,
		textAlign: 'center',
		fontSize: 12,
		lineHeight: '10px',
	},
	innerPayoff: {
		paddingLeft: 12,
	},
	textTitle: {
		fontSize: 15,
		color: 'rgba(0, 0, 0, 0.54)',
		margin: '25px 0',
	},
	textContent: {
		fontSize: 12,
		color: 'rgba(0, 0, 0, 1)',
		lineHeight: '28px',
		marginTop: 25,
	},
	textContentMobile: {
		fontSize: 11,
		color: 'rgba(0, 0, 0, 1)',
		lineHeight: '15px',
		marginTop: 25,
	},
	btnExport: {
		marginLeft: 19,
		padding: '0 32px',
	},
	myIcon: {
		fontSize: 26,
		color: 'rgba(33, 33, 33, 1)',
	},
	btnExportMobile: {
		margin: '15px auto',
	},
	buttonSubmit: {
		display: 'flex',
		justifyContent: 'center',
	},
	arrowIcon: {
		display: 'flex',
		justifyContent: 'center',
		lineHeight: '36px',
	},
	arrowIconMobile: {
		display: 'flex',
		justifyContent: 'center',
		lineHeight: '30px',
	},
	wrapArrowIcon: {
		width: '36px',
		height: '36px',
		border: '1px solid rgba(0, 0, 0, 0.26)',
		borderLeft: 'none',
		marginRight: '14px',
	},
	wrapArrowIconMobile: {
		width: '30px',
		height: '30px',
		border: '1px solid rgba(0, 0, 0, 0.26)',
		borderLeft: 'none',
		marginRight: '8px',
	},
	wrapDate: {
		display: 'flex',
		alignItems: 'center',
	},
	wrapDateMobile: {
		display: 'flex',
		alignItems: 'center',
		margin: 'auto',
		width: 'max-content',
	},
	hintText: {
		lineHeight: '36px',
		bottom: 'unset',
		paddingLeft: 10,
	},
	hintTextMobile: {
		lineHeight: '30px',
		bottom: 'unset',
		paddingLeft: 10,
		fontSize: 12,
	},
}
