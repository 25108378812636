import {
	USER_IDLE_ACTION_HIDE_POPUP,
	USER_IDLE_ACTION_SHOW_POPUP,
	USER_IDLE_ACTION_CONTINUE_WAITING_SYNC,
	USER_IDLE_ACTION_FINISH_WAITING_SYNC,
} from '../constants/ActionTypes'

const initialState = {
	loaded: false,
	data: null,
}

export const IdleManagement = (state = initialState, action) => {
	switch (action.type) {
		case USER_IDLE_ACTION_SHOW_POPUP:
			return {
				...state,
				action: action.type,
				loaded: true,
				data: action.data,
			}
		case USER_IDLE_ACTION_HIDE_POPUP:
			return {
				...state,
				action: action.type,
				loaded: true,
				data: action.data,
			}
		case USER_IDLE_ACTION_CONTINUE_WAITING_SYNC:
			return {
				...state,
				action: action.type,
				loaded: true,
				data: action.data,
			}
		case USER_IDLE_ACTION_FINISH_WAITING_SYNC:
			return {
				...state,
				action: action.type,
				loaded: true,
				data: action.data,
			}
		default:
			return state
	}
}
