export const receiveNotiList = [
	{
		id: 1,
		label: 'お客様からチャット・電話相談リクエストがあった場合は通知する',
		checkboxId: 'recieve_noti_mail',
		receiveState: 'receiveNotiByEmail',
	},
	{
		id: 2,
		label: 'お客様からレビューがあった場合は通知する',
		checkboxId: 'recieve_noti_review',
		receiveState: 'receiveReviewNotiByEmail',
	},
	{
		id: 3,
		label: 'お客様からお気に入りがあった場合は通知する',
		checkboxId: 'recieve_noti_favorite',
		receiveState: 'receiveFavoriteNotiByEmail',
	},
	{
		id: 4,
		label: 'ログイン予定時間の1時間前に通知する',
		checkboxId: 'recieve_noti_before_login',
		receiveState: 'receiveReloginNotiByEmail',
	},
]

export const receiveNotiListSP = [
	{
		id: 1,
		label: 'チャット・電話相談リクエスト受信時',
		checkboxId: 'recieve_noti_mail',
		receiveState: 'receiveNotiByEmail',
	},
	{
		id: 2,
		label: 'レビュー受信時',
		checkboxId: 'recieve_noti_review',
		receiveState: 'receiveReviewNotiByEmail',
	},
	{
		id: 3,
		label: 'お気に入りされた時',
		checkboxId: 'recieve_noti_favorite',
		receiveState: 'receiveFavoriteNotiByEmail',
	},
	{
		id: 4,
		label: 'ログイン予定時間の1時間前',
		checkboxId: 'recieve_noti_before_login',
		receiveState: 'receiveReloginNotiByEmail',
	},
]
export const ringStoneListSP = [
	{ id: 1, label: 'メロディ1', checkboxId: 'ring_stone_1', file: '/receipt1.mp3' },
	{ id: 2, label: 'メロディ2', checkboxId: 'ring_stone_2', file: '/receipt2.mp3' },
	{ id: 3, label: 'メロディ3', checkboxId: 'ring_stone_3', file: '/receipt3.mp3' },
	{ id: 4, label: 'メロディ4', checkboxId: 'ring_stone_4', file: '/receipt4.mp3' },
	{ id: 5, label: 'メロディ5', checkboxId: 'ring_stone_5', file: '/receipt5.mp3' }
]