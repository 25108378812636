import {
	TEMPLATE_POST,
	TEMPLATE_GET,
	TEMPLATE_PUT,
	TEMPLATE_DELETE,
	TEMPLATE_PUT_LOADING,
} from '../constants/ActionTypes'

const initialState = {
	data: {},
	templateExist: false,
}

export const Template = (state = initialState, action) => {
	let ret = []
	let flag = false
	switch (action.type) {
		case TEMPLATE_PUT_LOADING:
			state.data.forEach((cur, index) => {
				if (cur.templateId === action.data.templateId) {
					flag = true
					ret.push(Object.assign({}, cur, action.data))
				} else {
					ret.push(cur)
				}
			})
			if (flag === false) {
				ret.push(action.data)
			}
			return { data: ret }
		case TEMPLATE_POST:
			ret = state.data

			if (typeof action.data.templateId !== 'undefined') {
				ret.push(action.data)
			}

			return Object.assign({}, state, { data: ret })
		case TEMPLATE_GET:
			return Object.assign({}, state, {
				data: action.data,
			})
		case TEMPLATE_PUT:
			state.data.forEach((cur, index) => {
				if (cur.templateId === action.data.templateId) {
					flag = true
					ret.push(Object.assign({}, cur, action.data))
				} else {
					ret.push(cur)
				}
			})
			if (flag === false) {
				return Object.assign(
					{},
					{ ...state, templateExist: true },
					{ data: ret }
				)
			}

			return Object.assign({}, state, { data: ret })
		case TEMPLATE_DELETE:
			ret = state.data

			for (let x in ret) {
				if (ret[x].templateId == action.data) {
					ret.splice(x, 1)
					break
				}
			}

			return Object.assign({}, state, { data: ret })
		default:
			return state
	}
}
