import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'

export default class DialogTooFewCharacter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
		}
	}

	openDialog = () => {
		this.setState({ show: true })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				onRequestClose={this.closeDialog}
				contentClassName="dialog__too-few-character-container"
				bodyClassName="dialog__too-few-character-body"
			>
				<div className="dialog__too-few-character__title ">
					文字数が不足しています。
				</div>
				<div className="dialog__too-few-character__content">
					お客様の満足度向上のため、
				</div>
				<div className="dialog__too-few-character__subcontent">
					回答は80文字以上でご記入ください。
				</div>
				<div className="dialog__too-few-character__button-area">
					<button
						className="dialog__too-few-character__button-ok"
						onClick={this.closeDialog}
					>
						OK
					</button>
				</div>
			</Dialog>
		)
	}
}
