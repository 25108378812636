import React from 'react'
import LoadingBar from 'react-redux-loading-bar'

export default class Loading extends React.Component {
	render() {
		return (
			<LoadingBar
				style={{
					backgroundColor: 'rgb(17,173,157)',
					zIndex: 999999,
					position: 'fixed',
					top: 0,
				}}
			/>
		)
	}
}
