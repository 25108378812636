export default class DialogChatRef {
	static modal
	static show

	static setModal(modal) {
		this.modal = modal
	}

	static getModal() {
		return this.modal
	}

	static isShowing() {
		return this.show
	}

	static setShowModal(value) {
		this.show = value
	}
}
