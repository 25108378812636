import styled from 'styled-components'
import { colors, fonts, DEVICE } from '../../../util/StellaConstant'
import UserAvatarGif from '../../../assets/bg_request_chat.gif'
import { AVATAR_BY_GENDER } from '../../../constants/CallConstants'
import BellPC from '../../../assets/bell-pc.png'
import BellMB from '../../../assets/bell-mb.png'
import CallIcon from '../../../assets/call_icon.png'
/*
	@media ${DEVICE.mobileS} {

	}
	@media ${DEVICE.tablet} {

	}
	@media ${DEVICE.laptop} {

	}
*/

const COLOR_FOR_CALLING = {
	ACCEPT: {
		AREA: 'ACCEPT',
		BACKGROUND: colors.pearlAqua,
		TEXT: colors.white,
		SHADOW: true,
	},
	PENDING: {
		AREA: 'PENDING',
		BACKGROUND: colors.ghostWhite,
		TEXT: colors.mediumBlue,
		SHADOW: true,
	},
	DECLINE: {
		AREA: 'DECLINE',
		BACKGROUND: colors.white,
		TEXT: colors.pastelGray,
		SHADOW: false,
	},
}
export const Container = styled.div` 
	background-color: white;
	padding: 20px 0px;
	border-radius: 4px;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24)
`

export const Title = styled.p`
	@media ${DEVICE.mobileS} {
		font-size: 17px;
		text-align: center;
		font-family: ${fonts.HiraginoKakuGothicPro};
		color: ${colors.mediumBlue};
		line-height: 21px;
		font-weight: bold
	}
	@media ${DEVICE.tablet} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 24px;
		color: ${colors.mediumBlue};
		line-height: 21px;
		font-weight: bold
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 17px;
		color: ${colors.mediumBlue};
		line-height: 21px;
		font-weight: bold
	}
`

export const BackgroundUserAvatar = styled.div`
	@media ${DEVICE.mobileS} {
		background-image: url(${UserAvatarGif});
		background-size: cover;
		width: 170px;
		height: 170px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 13px auto 0;
	}
	@media ${DEVICE.tablet} {
		margin: 10px auto 0;
	}
	@media ${DEVICE.laptop} {
	}
`

export const UserAvatar = styled.div`
	@media ${DEVICE.mobileS} {
		background-image: url(${CallIcon});
		background-size: cover;
		width: 90px;
		height: 90px;
		margin-bottom: 7px;
	}
	@media ${DEVICE.tablet} {
		background-image: url(${CallIcon});
		background-size: cover;
		width: 90px;
		height: 90px;
		margin-bottom: 0px;
	}
	@media ${DEVICE.laptop} {
		background-image: url(${CallIcon});
		background-size: cover;
		width: 90px;
		height: 90px;
		margin-bottom: 0px;
	}
`
export const Info = styled.div`
	display: flex;
	justify-content: center
`
export const UserName = styled.p`
    @media ${DEVICE.mobileS} {
        text-align: center;
        line-height: 21px;
        font-family: ${fonts.HiraginoKakuGothicPro};
        font-size: 21px;
        color: ${colors.pearlAqua};
        margin-bottom: 0px;
        font-weight: bold;
		margin-left: 8px
    }
    @media ${DEVICE.tablet} {
        font-family: ${fonts.HiraKakuProW3};
        font-size: 30px;
        color: ${colors.pearlAqua};
        margin-bottom: 17px;
    }
    @media ${DEVICE.laptop} {
        font-family: ${fonts.HiraginoKakuGothicPro};
        font-size: 20px;
        color: ${colors.pearlAqua};
        margin-bottom: 6px;
    }
`

export const CountdownTimer = styled.p`
	@media ${DEVICE.mobileS} {
		line-height: 21px;
		text-align: center;
		margin-bottom: 29px;

		font-family: ${fonts.HiraKakuProW3};
		font-size: 24px;
		background-color: ${colors.accent};
		color: ${colors.white};
		padding: 10px
	}
	@media ${DEVICE.tablet} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 24px;
		background-color: ${colors.accent};
		color: ${colors.white};
		padding: 10px
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 20px;
		background-color: ${colors.accent};
		color: ${colors.white};
		padding: 10px
	}
`

export const ButtonArea = styled.div`
	@media ${DEVICE.mobileS} {
		display: grid;
		grid-template-areas:
			'ACCEPT PENDING'
			'DECLINE DECLINE';
		grid-template-columns: 34% 34%;
		grid-gap: 18px 29px;
		justify-content: center;
		align-self: center;
	}
	@media ${DEVICE.tablet} {
		grid-gap: 8px 12px;
		justify-content: center;
		display: flex
	}
	@media ${DEVICE.laptop} {
		grid-gap: 8px 12px;
		justify-content: center;
		display: flex
	}
`

export const Button = styled.button`
	@media ${DEVICE.mobileS} {
		grid-area: ${(props) => COLOR_FOR_CALLING[props.buttonType].AREA};
		background-color: ${(props) =>
		COLOR_FOR_CALLING[props.buttonType].BACKGROUND};
		color: ${(props) => COLOR_FOR_CALLING[props.buttonType].TEXT};
		border: none;
		outline: none;
		padding: 11px 0 10px;
		border-radius: 2px;
		box-shadow: ${(props) =>
		COLOR_FOR_CALLING[props.buttonType].SHADOW
			? '0 2px 2px 0 rgba(0, 0, 0, 0.24),0 0 2px 0 rgba(0, 0, 0, 0.12)'
			: ''};
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 15px;
		text-decoration-line: ${(props) => props.buttonType === 'DECLINE'
		? 'underline'
		: 'none'};
		margin-top: 5px;
		width: ${(props) => props.buttonType === 'DECLINE' ? '100%' : '40%'}
	}
	@media ${DEVICE.tablet} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 18px;
		
	}
	@media ${DEVICE.laptop} {
		font-size: 15px;
	}
`

export const NoticeArea = styled.div`
	@media ${DEVICE.mobileS} {
		margin-top: 7px;
		align-self: center
	}
	@media ${DEVICE.tablet} {
		margin-top: 18px;
		align-self: center
	}
	@media ${DEVICE.laptop} {
		margin-top: 14px;
		align-self: center
	}
`

export const Notice = styled.p`
	@media ${DEVICE.mobileS} {
		line-height: 16px;
		text-align: left;
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 12px;
		color: ${colors.white};
	}
	@media ${DEVICE.tablet} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 14px;
		color: ${colors.white};
		margin-left: 36px;
		font-weight: 500
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 12px;
		color: ${colors.white};
		font-weight: 500
	}
`

export const ContainerIconPendingCall = styled.div`
	@media ${DEVICE.mobileS} {
		position: fixed;
		top: 30px;
		right: 30px;
		z-index: 99999999;
	}
	@media ${DEVICE.tablet} {
		top: 50px;
		right: 50px;
	}
	@media ${DEVICE.laptop} {
		top: 50px;
		right: 50px;
	}
`

export const ImageIconPendingCall = styled.div`
	@media ${DEVICE.mobileS} {
		width: 48px;
		height: 48px;
		background-image: url(${BellMB});
	}
	@media ${DEVICE.tablet} {
		width: 62px;
		height: 62px;
		background-image: url(${BellPC});
	}
	@media ${DEVICE.laptop} {
		width: 62px;
		height: 62px;
		background-image: url(${BellPC});
	}
`
export const Memo = styled.div`
	margin: 4px 20px
`
export const HeaderMemo = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`
export const MemoTimer = styled.p`
	font-size: 10px;
	color: rgba(0, 0, 0, 0.87);
`
export const MemoAnswer = styled.div`
	border: ${(props) => props.isAnswered ? '1px solid rgba(17, 173, 157, 1)' : '1px solid rgba(158, 158, 158, 1)'};
	border-radius: 24px;
	align-items: center;
	padding: 2px 4px;
	display: flex; 
	font-size: 10px;
	color: ${(props) => props.isAnswered ?  colors.pearlAqua : '#9e9e9e'};
	font-weight: 600
`
export const MemoContent = styled.div` 
	background-color: rgba(239, 250, 248, 1);
	padding: 8px 12px;
	border-radius: 4px;
	margin: 8px 0px;
	color: ${(props) => props.isAnswered ? '#262626' : '#9e9e9e'};
	font-size: 12px;
	word-break: break-word;
	max-height: 100px;
	overflow-y: auto;
	white-space: pre-wrap;
`