/**
 * チャット申し込みダイアログ
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import * as ChatRequestActions from '../actions/ChatRequestActions'
import { withRouter } from 'react-router'
import moment from 'moment'
// import Dialog from 'material-ui/Dialog'
import Dialog from '@mui/material/Dialog'

import { fortuneTellerStatus } from '../constants/Config'
import * as MyProfileActions from '../actions/MyProfileActions'
import * as SuspendRequestChatActions from '../actions/SuspendRequestChatActions'
import { Link } from 'react-router-dom'
import BellRef from './ChatContent/Frames/BellRef'
import Bell from './ChatContent/Frames/Bell'
import { pauseNotificationSound, playNotificationSound } from '../util/Sound'
import { Container, HeaderMemo, Info, Memo, MemoAnswer, MemoContent, MemoTimer, Title, UserName } from './Call/StyledComponents/StyledDialogRequestCallComponents'
import { colors } from '../util/StellaConstant'

const isOnScreenTabletOrLess = window.outerWidth <= 768
class DialogChat extends Component {
	constructor(props) {
		super(props)
		/**
		 * show: ダイアログ true:表示,false:非表示
		 * time: カウントダウン表示秒数
		 */
		this.state = {
			show: false,
			time: 0,
		}
	}

	componentWillMount() { }

	componentDidMount() {
		console.log('%c DIALOGCHAT ', 'color: red')

		const { data, show, MyProfile } = this.props
		this.getCountDownStartTime(data)
		this.setState({
			show: show,
			time: this.getCountDownStartTime(data),
		})
		this.interval = setInterval(() => this.countDown(), 1000)
		if (MyProfile.data.isDisableSoundRequestChat && MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.chatting) {
			return
		}
		return playNotificationSound()
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	/**
	 * 多重で送信されてくる場合、最新を反映させる
	 * @param {*} nextProps
	 */
	componentWillReceiveProps(nextProps) {
		this.setState({
			show: nextProps.show,
			time: this.getCountDownStartTime(nextProps.data),
		})
	}

	countDown() {
		const time = this.state.time - 1
		this.setState({
			time: time < 0 ? 0 : time,
		})
	}

	/**
	 * チャットリクエストからカウントダウン開始秒数を取得
	 * @param {object} data チャットリクエストのリクエスト内容
	 * @return {number} カウントダウン開始秒数
	 */
	getCountDownStartTime(data) {
		if (!data) {
			return 0
		}
		moment.locale('ja')
		if (!moment(data.serverTime, 'YYYYMMDDHHmmss').isValid()) {
			//申請時間が不正の場合
			return 0
		}
		const registTime = moment(data.serverTime, 'YYYYMMDDHHmmss').add(
			9,
			'hours'
		)
		const diff = moment().diff(registTime, 'seconds')
		if (diff >= glasConfig.chatrequest_limit) {
			//90秒以上経っていればダイアログでチャット開始ボタンは非表示
			return 0
		}
		return glasConfig.chatrequest_limit - diff
	}

	checkFullChat = () => {
		const numberUserInListChat =
			document.querySelectorAll(
				'.accpet-user .btn-customer.customer_choose'
			).length +
			document.querySelectorAll(
				'.accpet-user .btn-customer.customer_default'
			).length
		if (
			numberUserInListChat + 1 ==
			this.props.MyProfile.data.maxNumberUserChatting
		) {
			this.doChangeStatus(fortuneTellerStatus.chattingFull)
		}
	}

	doChangeStatus(status) {
		const { dispatch, MyProfile } = this.props
		const data = MyProfile.data
		data['fortuneTellerStatus'] = status
		dispatch(MyProfileActions.put('status', data))
	}

	handleSuspendRequestChat = () => {
		const { dispatch, data, closeFunc } = this.props
		pauseNotificationSound()
		closeFunc()
		this.setState({
			show: false,
		})
		dispatch(SuspendRequestChatActions.doSuspend(data))
	}

	render() {
		const { data, closeFunc, dispatch } = this.props
		if (!this.state.show) {
			return null
		}

		const tellerId = Fetch.tellerId()
		const userId = data.toId === tellerId ? data.fromId : data.toId
		let userName = data.userName ? data.userName : data.fromName
		if (userName === undefined) {
			userName = data.friendName
		}

		//ゼロ秒以下の場合時間切れのため、チャット開始できないようにする
		// const canStart = (this.state.time <= 0) ? false : true

		const handleChatStart = () => {
			const { MyProfile } = this.props
			if (MyProfile.data.fortuneTellerStatus === 5) {
				return
			}
			closeFunc()
			dispatch(
				ChatRequestActions.post({
					userId: userId,
					msgId: data.msgId,
					accept: true,
				})
			)
			this.checkFullChat()
			this.props.history.push(`/home/chat/${userId}`)

			dispatch(SuspendRequestChatActions.doRemoveRequest(data.msgId))
			BellRef.getModal().forceUpdateBell()
		}

		const handleClose = () => {
			closeFunc()
		}

		const handleCancel = () => {
			const { MyProfile } = this.props
			if (MyProfile.data.fortuneTellerStatus === 5) {
				return
			}
			dispatch(
				ChatRequestActions.post({
					userId: userId,
					msgId: data.msgId,
					accept: false,
				})
			)
			closeFunc()
			dispatch(SuspendRequestChatActions.doRemoveRequest(data.msgId))
			BellRef.getModal().forceUpdateBell()
		}

		// const actions = [
		//   <FlatButton
		//     label="チャットを開始"
		//     primary={true}
		//     disabled={!canStart}
		//     onClick={()=>handleChatStart()}
		//   />,
		//   <FlatButton
		//     label="申し込みキャンセル"
		//     primary={true}
		//     keyboardFocused={true}
		//     onClick={()=>handleCancel()}
		//   />,
		// ]

		const secStyle = {
			textAlign: 'center',
			color: 'white',
			padding: '8px 0',
			fontSize: '20px',
			fontFamily: 'HiraKakuPro-W3',
			backgroundColor: colors.accent
		}
		const commonInfoChat = data.commonInfoRequestCallChatResponse
		return (
			<div>
				<Dialog
					open={this.state.show}
					bodyClassName="dialog__content"
					contentClassName="dialog-content"
					onRequestClose={() => handleClose()}
					PaperProps={{
						style: {
							backgroundColor: "transparent",
							boxShadow: "none",
							minHeight: '500px'
						},
					}}
				>
					<Container>
						<Title>チャット相談申し込み</Title>
						<Info style={{ marginTop: 10 }}>
							{commonInfoChat.isFirstTimeUseChat && <img src="/img/icon_firsttime.png" />}
							<UserName>{userName}さん</UserName>
						</Info>
						<Memo>
							<HeaderMemo>
								<MemoTimer>
									最終対応：{commonInfoChat.lastTimeConversation ? moment(commonInfoChat.lastTimeConversation).format('YYYY/MM/DD HH:mm') : '（初回）'}
								</MemoTimer>
								<MemoAnswer isAnswered={commonInfoChat.isAnswered}>
									<i className="material-icons"
										style={{
											fontSize: commonInfoChat.isAnswered ? 16 : 10,
											borderWidth: commonInfoChat.isAnswered ? 0 : 1,
											borderColor: commonInfoChat.isAnswered ? colors.pearlAqua : 'rgba(158, 158, 158, 1)',
											color: commonInfoChat.isAnswered ? colors.pearlAqua : 'rgba(158, 158, 158, 1)',
											marginRight: 4,
											padding: 1,
											borderRadius: 24,
											borderStyle: 'solid',

										}}>{commonInfoChat.isAnswered ? 'check_circle' : 'clear'}</i>
									教えて！先生
								</MemoAnswer>
							</HeaderMemo>
							<MemoContent isAnswered={!!commonInfoChat.memo}>
								{!!commonInfoChat.memo && <div style={{ color: colors.pearlAqua, fontWeight: 600, fontSize: 12, textAlign: 'center' }}>メモ</div>}
								{!!commonInfoChat.memo ? commonInfoChat.memo : 'メモはありません'}
							</MemoContent>
						</Memo>
						{this.state.time ? (
							<div
								className="dialog__chat-countdown"
								style={secStyle}
							>
								{this.state.time}秒
							</div>
						) : (
							''
						)}
						<div className="logout-dialog-btn-wrap">
							<div
								className="btn-wrap margin_bottom btn-dialog dialog__chat-button"
								style={styles.buttons}
							>
								<button
									style={styles.buttonStart}
									onClick={() => handleChatStart()}
									className="btn-raised color_default dialog__chat-button__acceptchat"
								>
									{isOnScreenTabletOrLess
										? '開始する'
										: 'チャットを開始'}
								</button>
								<button
									style={styles.buttonSuspend}
									onClick={() => this.handleSuspendRequestChat()}
									className="dialog__chat-button__suspendchat"
								>
									保留
								</button>
							</div>
						</div>
						<div className="btn-wrap margin_bottom" style={{ display: 'grid' }}>
							<button
								onClick={() => handleCancel()}
								style={styles.buttonCancel}
							>
								この依頼を受け取らない
							</button>
						</div>
					</Container>
					<div style={styles.noticeArea}>
						<p
							className="dialog__chat-notice"
							style={styles.notice}
						>
							※原則、キャンセルはしないようにしてください
						</p>
						<p
							className="dialog__chat-notice"
							style={styles.notice}
						>
							※保留で現在入力中のチャットに戻れます
						</p>
					</div>
				</Dialog>
			</div>
		)
	}
}

const styles = {
	buttons: {
		display: 'flex',
		paddingLeft: 18,
		paddingRight: 18,
		justifyContent: 'center',
		alignItems: 'center',
	},
	buttonStart: {
		width: 150,
		marginRight: 43,
		padding: 0,
	},
	buttonSuspend: {
		fontSize: 15,
		color: 'rgba(0,0,0,0.87)',
		width: 150,
		backgroundColor: 'rgba(249, 249, 249, 1)',
		borderRadius: 2,
		border: 'none',
		paddingTop: 7,
		paddingBottom: 7,
		boxShadow:
			'0px 2px 2px 0px rgba(0, 0, 0, 0.24), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
	},
	buttonCancel: {
		color: 'rgba(203, 203, 203, 1)',
		fontSize: 15,
		fontFamily: 'HiraKakuPro-W3',
		border: 'none',
		background: 'none',
		outline: 'none',
		textDecoration: 'underline',
		alignSelf: 'center'
	},
	notice: {
		color: 'white',
		fontSize: 12,
		textAlign: 'left',
	},
	noticeArea: {
		marginTop: 14,
		alignSelf: 'center'
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default withRouter(connect(mapStateToProps)(DialogChat))
