/**
 * チャット画面　右カラムの通話履歴
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import moment from 'moment'


export class CallLogRef {
  static modal;
  static setModal(modal) {
    this.modal = modal;
  }
  static getModal() {
    return this.modal;
  }
}


class ChatUserLogCall extends Component {

  constructor (props) {
    super(props)
    this.state = {
      userId: null,
      calllog: [],
      page: 0,
    }
    CallLogRef.setModal(this)
  }

  componentDidMount() {
    this.loadChatHistory({ userId: this.props.UserInfo.userId})
  }

  componentWillReceiveProps (nextProps) {
    if (!nextProps.show){
      return
    }
    if (nextProps.UserInfo.userId !== this.props.UserInfo.userId ) {
      this.loadChatHistory({
        userId: nextProps.UserInfo.userId
      })
    } else {
      if (!this.state.page) {
        this.loadChatHistory({
          userId: nextProps.UserInfo.userId
        })
      }
    }
  }


  /**
   * チャットログを取得する
   * 取得したと同時にサーバ側で既読に勝手になる
   * @param {object} req
   * @return {void}
   */
  loadChatHistory (req) {

    const params = {
      userId: req.userId,
      filter: 'all',
      page: req.page ? req.page : 0,
      size: 10,
    }

    const url = glasConfig.url_base + glasConfig.path_logcalls
    const token = localStorage.getItem('token')

    const options = {
      method: 'GET',
      url: url ,
      params,
      headers: {
          'Authorization': token
      },
      json: true
    }

    request(options)
      .then(response => {
        if (response.data.code === 0 && response.data.data.length) {
          let tmpdata = []
          if ( params.page ) {
            tmpdata = [...this.state.calllog, ...response.data.data]
          } else {
            tmpdata = response.data.data
          }
          this.setState({
            calllog: tmpdata,
            page: params.page + 1,
          })
        } else {
          if (params.page === 0 && this.state.page > 0 ) {
            this.setState({
              calllog: [],
              page: 0
            })
          }
        }
      })
      .catch(error => {
        //throw error
      })
  }


  /**
   * 通話履歴をクリックして開いたときのイベント
   */
  showCallLog () {
    const { UserInfo } = this.props
    if ( this.state.page === 0 ) {
      this.loadChatHistory({
        userId: UserInfo.userId
      })
    }
  }

  render () {
    const { show, UserInfo } = this.props

    if ( !(UserInfo.userId && show) ) {
      return ''
    }
    console.log('%c [STELLA] this.state.calllog: ', 'color: red' , this.state.calllog);
    if (!this.state.calllog.length) {
      return (
        <div className="chat-call-history"><p className="chat-call-history__summary">履歴は見つかりませんでした</p></div>
      )
    }


    const handleScroll = () => {
      const messageList = this.refs.messageList
      if ( messageList.scrollTop + messageList.clientHeight === messageList.scrollHeight ) {
        this.loadChatHistory({
          userId: UserInfo.userId,
          page: this.state.page,
        })
      }
    }

    const Loglist = Object.values(this.state.calllog).map( (res, i) => {
          return (
            <CallList key={"calllist-"+i} data={res} />
          )
        })

    return (
      <div className="chat-call-history" style={Styles}>
        <ul className="list column_1" ref="messageList" style={ListStyle} onScroll={()=>handleScroll()}>
          {Loglist}
        </ul>
      </div>
    )
  }

}

const CallList = ({ data }) => {
	if (!data) {
		return ''
	}
	const key = data.time + data.callStatus

	const dateFormated = moment(data.time, 'YYYYMMDDHHmmss')
		.add(9, 'hours')
		.format('YYYY/MM/DD HH:mm')

	const hourCall =
		Math.floor(data.duration / 3600) < 10
			? '0' + Math.floor(data.duration / 3600)
			: Math.floor(data.duration / 3600)
	const minCall =
		Math.floor(data.duration / 60) < 10
			? '0' + Math.floor(data.duration / 60)
			: Math.floor(data.duration / 60)
	const secondCall =
		data.duration % 60 < 10
			? '0' + (data.duration % 60)
			: data.duration % 60
	let timeCall = `${hourCall}:${minCall}:${secondCall}`

	return (
		<li key={key} className="list__item" style={{ float: 'left' }}>
			<div className="chat-call-history__summary">
				<p className="chat-call-history__date">{dateFormated}</p>
				<p className="chat-call-history__time">通話時間 {timeCall}</p>
			</div>
		</li>
	)
}

const ListStyle = {
	wordWrap: 'break-word',
	margin: '0',
	overflowY: 'auto',
	padding: '0',
	flexGrow: '1',
	order: '1',
}
const Styles = {
	height: '60px',
	margin: 0,
	backgroundColor: '#F5F8FF',
	msFlex: 1,
	WebkitBoxFlex: 1,
	MozBoxFlex: 1,
	msBoxFlex: 1,
	boxFlex: 1,
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	alignContent: 'flex-start',
	alignItems: 'inherit',
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(ChatUserLogCall)
