import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import request from 'axios'
import * as AuthActions from '../actions/AuthActions'
import * as MyProfileActions from '../actions/MyProfileActions'
import { FireMsg } from '../util/Firebase'
import TextField from 'material-ui/TextField'
import LoadingBar from './LoadingBar'
import Snackbar from 'material-ui/Snackbar'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import Dialog from 'material-ui/Dialog'

class PasswordReset extends Component {
	constructor(props) {
		super(props)
		this.state = {
			newPassword: '',
			renewPassword: '',
			message: '',
			error: false,
			errorCode: false,
			messageErrorCode: '',
			buttonResetDisable: false,
		}
	}
	componentDidMount() {
		const authCode = this.props.match.params.auth_code
		const url =
			glasConfig.url_base + glasConfig.path_password + '/' + authCode
		const options = {
			method: 'GET',
			url: url,
			data: { authCode },
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code !== 0) {
					this.setState({
						errorCode: true,
						messageErrorCode:
							'有効期限が切れております。再度お試しくださいませ。',
						buttonResetDisable: true,
					})
				}
			})
			.catch((error) => {})
	}
	handleSubmit = (event) => {
		if (event.target.name === 'passwordForm') {
			event.preventDefault()

			const { dispatch } = this.props
			const authCode = this.props.match.params.auth_code
			const deviceType = Fetch.fetchUserAgent()
			const deviceName = Fetch.getDeviceName()

			let newPassword = event.target.newPassword.value
			let renewPassword = event.target.renewPassword.value

			let error = false
			let message = ''
			if (this.validPasswordValue(newPassword, renewPassword)) {
				error = true
				message = 'パスワードが一致しません'
			} else if (this.validPasswordLength(newPassword.length)) {
				error = true
				message = `パスワードは${glasConfig.account.password}文字以上入力してください`
			} else if (
				!(
					this.validPasswordValue(newPassword, renewPassword) ||
					this.validPasswordLength(newPassword.length)
				)
			) {
				const params = {
					newPassword: newPassword,
					applicationName: glasConfig.application,
					deviceType: deviceType,
					deviceName: deviceName,
					deviceId: deviceType,
					pushNotificationToken: '',
					adjustId: '',
					applicationVersion: '1.2',
				}

				const url =
					glasConfig.url_base +
					glasConfig.path_password +
					'/' +
					authCode
				const options = {
					method: 'PUT',
					url: url,
					data: params,
					json: true,
				}

				request(options)
					.then((response) => {
						if (response.data.code === 0) {
							window.location.pathname = '/announce'
						}
					})
					.catch((error) => {})
			}

			this.setState({
				error: error,
				message: message,
			})
		}
	}

	handleChange(event) {
		let form = document.passwordForm

		let newPassword = form.newPassword.value.length
		let renewPassword = form.renewPassword.value.length
		if (newPassword && renewPassword && this.state.errorCode) {
			this.setState({
				buttonResetDisable: false,
			})
		}
	}

	validPasswordValue(newPassword, renewPassword) {
		return newPassword === renewPassword ? false : true
	}

	validPasswordLength(newPassword) {
		return newPassword >= glasConfig.account.password ? false : true
	}

	handleErrorClose() {
		this.setState({
			error: false,
			errorCode: false,
		})
	}

	render() {
		return (
			<div>
				<LoadingBar />
				<div className="auth-box">
					<Link className="sitelogo" to="/login">
						<img
							className="sitelogo__image"
							src="/img/stella_logo_color.png"
							alt="ステラロゴ"
						/>
					</Link>

					<div className="auth-box__inner">
						<label className="reset_pass">パスワード再設定</label>
						<form
							name="passwordForm"
							onSubmit={(e) => this.handleSubmit(e)}
						>
							<div className="textfield">
								<TextField
									hintText="新しいパスワード"
									id="new_pass"
									className="textfield__input"
									name="newPassword"
									onChange={(e) => this.handleChange(e)}
									fullWidth={true}
									type="password"
								/>
							</div>
							<div className="textfield">
								<TextField
									hintText="新しいパスワード（再入力）"
									id="confirm_pass"
									className="textfield__input"
									name="renewPassword"
									onChange={(e) => this.handleChange(e)}
									fullWidth={true}
									type="password"
								/>
							</div>
							<div className="btn-wrap">
								<button
									className="btn-raised color_default spread_width"
									name="submitBtn"
									disabled={this.state.buttonResetDisable}
								>
									変更
								</button>
							</div>
						</form>
					</div>
				</div>
				<Snackbar
					open={this.state.error}
					message={this.state.message}
					autoHideDuration={4000}
					onRequestClose={(e) => this.handleErrorClose(e)}
				/>
				<Dialog modal={true} open={this.state.errorCode}>
					<div className="textMessageError">
						{' '}
						有効期限が切れております。再度お試しくださいませ。
					</div>
					<Link to="/login">
						{' '}
						<button className="btn-raised color_default btn-reset-password">
							<span style={{ color: 'white' }}>OK</span>
						</button>
					</Link>
				</Dialog>
			</div>
		)
	}
}

export default PasswordReset
