import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
	glasConfig,
	katakanaList,
} from '../constants/Config'
import SettingMenu from './SettingMenu'
import RewardMenu from './RewardMenu'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import * as MyBankActions from '../actions/MyBankActions'
import $ from 'jquery'

/**
 * 銀行頭文字選択
 */

class SettingBankInitials extends Component {
	constructor(props) {
		super(props)
		this.state = {
			initials: this.props.match.params.kana,
			banks: null,
			branches: false,
			code: null,
			back: false,
			disabled: 'disabled',
			selectCode: 0,
			selectName: '',
		}
	}

	componentWillMount() {
		const { dispatch } = this.props
		dispatch(MyBankActions.getInitialBank(this.props.match.params.kana))
	}

	saveBankCode = (bankCode) => {
		sessionStorage.setItem('bankCode', bankCode)
	}

	handleSubmit(event) {
		event.preventDefault()

		let selectCode = this.state.selectCode
		this.saveBankCode(selectCode)
		this.setState({
			branches: true,
			code: selectCode,
		})
	}

	handleClick(event) {
		this.setState({
			back: true,
		})
	}

	handleChange(event, key, payload) {
		let disabled = ''
		let selectCode = 0
		let selectName = ''
		let selectBank = null

		if (this.props.Bank.bank) {
			selectBank = Array.prototype.filter.call(
				this.props.Bank.bank,
				(current, index) => {
					return current.bank_code == payload
				}
			)
		}

		if (selectBank.length > 0) {
			selectCode = selectBank[0].bank_code
			selectName = selectBank[0].bank_name
		}

		if (selectName !== '') {
			disabled = ''
		} else {
			disabled = 'disabled'
		}
		this.setState({
			disabled: disabled,
			selectCode: selectCode,
			selectName: selectName,
		})
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('銀行口座情報編集')
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
		$('.menu-header').removeClass('hiddenClass')
	}

	render() {
		if (this.state.branches) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -2)
						.join('/')}/branches/${this.state.code}`}
				/>
			)
		}
		if (this.state.back) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -2)
						.join('/')}/top`}
				/>
			)
		}

		let bank = null
		if (this.props.Bank.bank) {
			bank = Array.prototype.map.call(
				this.props.Bank.bank,
				(current, index) => {
					return (
						<MenuItem
							value={current.bank_code}
							primaryText={current.bank_name}
						/>
					)
				}
			)
		}

		return (
			<div class="content bank-detail bank-initial">
				<h1 className="content__title">設定</h1>
				{/* <SettingMenu /> */}
				<RewardMenu />
				<div class="primary">
					<div class="primary-title">
						<h1 class="primary-title__main">銀行口座情報</h1>
					</div>
					<div class="primary__inner">
						<p>下記より銀行名を選択してください。</p>
						<form
							name="bankForm"
							onSubmit={(e) => this.handleSubmit(e)}
						>
							<SelectField
								value={this.state.selectCode}
								onChange={(e, k, p) =>
									this.handleChange(e, k, p)
								}
								name="selectBank"
								hintText="選択してください"
							>
								<MenuItem
									value={0}
									primaryText="選択してください"
								/>
								{bank}
							</SelectField>
							<div>
								<input
									type="button"
									name="returnButton"
									class="btn-raised margin_right"
									onClick={(e) => this.handleClick(e)}
									value="戻る"
								/>
								<input
									type="submit"
									name="nextButton"
									class="btn-raised color_default"
									value="次へ"
									disabled={this.state.disabled}
								/>
							</div>
						</form>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		Bank: state.Bank,
	}
}

export default connect(mapStateToProps)(SettingBankInitials)
