import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import * as MyAgeAuthActions from '../actions/MyAgeAuthActions'
import SparkMD5 from 'spark-md5'
import Loading from './Loading'
import Snackbar from 'material-ui/Snackbar'
import { MESSAGE_TEXT_ERROR, VARIABLE_STATIC } from '../constants/Config'

class SettingAgeAuth extends Component {
	constructor(props) {
		super(props)
		this.state = {
			message: '',
			error: false,
			image: '',
			imageBack: '',
			identityCardUpload: false,
			loading: false,
			snackOpen: false,
			errorValidate: "",
		}
	}

	handleDrag(event) {
		event.preventDefault()
		event.stopPropagation()
	}

	handleDrop(event) {
		event.preventDefault()
		event.stopPropagation()

		if (event.target.id === 'identityCardArea') {
			this.getImageData(event.target.id, event.dataTransfer.files, event)
			if (document.ageAuth.identityCard.files)
				document.ageAuth.identityCard.files = event.dataTransfer.files
		} else if (event.target.id === 'identityCardBackArea') {
			this.getImageData(event.target.id, event.dataTransfer.files, event)
			if (document.ageAuth.identityCardBack.files)
				document.ageAuth.identityCardBack.files = event.dataTransfer.files
		}
	}

	handleChange(event) {
		if (event.target.name === 'identityCard') {
			this.getImageData(event.target.name, event.target.files, event)
			if (document.ageAuth.identityCard.files)
				document.ageAuth.identityCard.files = event.target.files
		} else if (event.target.name === 'identityCardBack') {
			this.getImageData(event.target.name, event.target.files, event)
			if (document.ageAuth.identityCardBack.files)
				document.ageAuth.identityCardBack.files = event.target.files
		}
	}

	getImageData(target, files, event) {
		Array.prototype.forEach.call(files, (element, index, array) => {
			if (element.name && !/(\.png|\.jpg|\.jpeg)$/i.test(element.name)) {
				this.setState({
				  snackOpen: true,
				  errorValidate: MESSAGE_TEXT_ERROR.VALIDATE_IMAGE
				})
				event.target.value = null
			} else if (element.size && (element.size) / (VARIABLE_STATIC.SIZE_LENGTH) > VARIABLE_STATIC.MAX_SIZE_IMAGE_UPLOAD * VARIABLE_STATIC.SIZE_LENGTH) {
				this.setState({
					snackOpen: true,
					errorValidate: MESSAGE_TEXT_ERROR.VALIDATE_IMAGE_MAX_SIZE
				})
				event.target.value = null
			} else if (target === 'identityCard' || target === 'identityCardArea') {
				let fileReader = new FileReader()
				fileReader.readAsDataURL(element)
				fileReader.onload = (ev) => {
					this.setState({
						error: false,
						message: '',
						image: ev.target.result,
						identityCardUpload: true,
					})
				}
			} else if (
				target === 'identityCardBack' ||
				target === 'identityCardBackArea'
			) {
				let fileReader = new FileReader()
				fileReader.readAsDataURL(element)
				fileReader.onload = (ev) => {
					this.setState({
						error: false,
						message: '',
						imageBack: ev.target.result,
					})
				}
			}
		})
	}

	handleSubmit(event) {
		this.setState({ loading: true })
		event.preventDefault()

		const { dispatch } = this.props
		let formData = new FormData()
		let files = []

		if (document.ageAuth.identityCard.value) {
			let file = document.ageAuth.identityCard.files[0]
			formData.append('transferFiles', file)
			files.push(file)
		}
		if (document.ageAuth.identityCardBack.value) {
			let file = document.ageAuth.identityCardBack.files[0]
			formData.append('transferFiles', file)
			files.push(file)
		}

		this.getAllCheckSums(files).then((hashes) => {
			hashes.forEach((hash) => {
				formData.append('checksums', hash)
			})
			if (!this.state.snackOpen) {
				dispatch(MyAgeAuthActions.ageVerify(formData))
			}
		})
	}

	async getAllCheckSums(files) {
		return await Promise.all(
			files.map(async (file) => {
				return await this.getCheckSum(file)
			})
		)
	}

	getCheckSum(file) {
		return new Promise((resolve) => {
			let reader = new FileReader()
			let spark = new SparkMD5.ArrayBuffer()
			reader.onload = (event) => {
				let data = event.target.result
				spark.append(data)
				let hash = spark.end()
				resolve(hash)
			}
			reader.readAsArrayBuffer(file)
		})
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.MyAccountAgeAuth.posted !==
			this.props.MyAccountAgeAuth.posted
		) {
			window.location.reload()
			this.setState({ loading: false })
		}
	}

	// ページ表示振り分け
	dispatchPage() {
		if (
			this.props.MyAccount.data.verifyAge !== 'Verified' &&
			this.props.MyAccount.data.verifyAge !== 'Pending' &&
			this.props.MyAccount.data.verifyAge !== 'Reviewing'
		) {
			return this.registAgeAuth()
		} else {
			return this.completeAgeAuth()
		}
	}

	handleRequestClose(event) {
		this.setState({
			snackOpen: false,
		})
	}

	// 年齢認証登録
	registAgeAuth() {
		let dropBox = {
			border: 'gray solid 1em',
			padding: '2em',
			marginBottom: '1em',
		}

		let errorMessage = this.state.message ? <p>{this.state.message}</p> : ''
		let readImage = this.state.image ? (
			<figure>
				<img src={this.state.image} />
			</figure>
		) : (
			''
		)
		let readImageBack = this.state.imageBack ? (
			<figure>
				<img src={this.state.imageBack} />
			</figure>
		) : (
			''
		)
		let identityCardUpload = this.state.identityCardUpload ? '' : 'disabled'

		return (
			<div className="content">
				<h1 className="content__title">設定</h1>
				<ol className="breadcrumb">
					<li className="breadcrumb__item">
						<Link to="/home/setting/account">アカウント編集</Link>
					</li>
					<li className="breadcrumb__item">
						<p>年齢認証</p>
					</li>
				</ol>
				<div className="content__inner layout_settings-age">
					<div className="primary">
						<div className="primary-title">
							<h1 className="primary-title__main">年齢認証</h1>
						</div>
						<div className="primary__inner">
							<p>
								ご本人様確認と年齢確認のため身分証明書の提出をお願いいたします。
							</p>
							{errorMessage}
							<form
								name="ageAuth"
								onSubmit={(e) => this.handleSubmit(e)}
							>
								<div className="age-box">
									<figure className="age-box__figure">
										<img
											src="/img/settings_age_auth_flow.png"
											alt="身分証明書提出フロー"
										/>
									</figure>
									<div className="age-box__inner">
										<h2 className="age-box__title">
											撮影した写真を選択して申請
										</h2>
										<div
											id="identityCardArea"
											className="drag-and-drop color_identification"
											onDragOver={(e) =>
												this.handleDrag(e)
											}
											onDragEnter={(e) =>
												this.handleDrag(e)
											}
											onDrop={(e) => this.handleDrop(e)}
										>
											<p className="drag-and-drop__title">
												身分証写真を選択
											</p>
											<div className="btn-wrap">
												<label className="btn-raised color_white">
													<p className="btn-raised__text">
														ファイルを選択
													</p>
													<input
														className="btn-raised__file"
														type="file"
														name="identityCard"
														accept="image/*"
														onChange={(e) =>
															this.handleChange(e)
														}
													/>
												</label>
											</div>
											{readImage}
										</div>
										<div
											id="identityCardBackArea"
											className="drag-and-drop color_driver"
											onDragOver={(e) =>
												this.handleDrag(e)
											}
											onDragEnter={(e) =>
												this.handleDrag(e)
											}
											onDrop={(e) => this.handleDrop(e)}
										>
											<p className="drag-and-drop__title">
												免許証裏面の申請が必要な方は選択
											</p>
											<div className="btn-wrap">
												<label className="btn-raised color_white">
													<p className="btn-raised__text">
														ファイルを選択
													</p>
													<input
														type="file"
														className="btn-raised__file"
														name="identityCardBack"
														accept="image/*"
														onChange={(e) =>
															this.handleChange(e)
														}
													/>
												</label>
											</div>
											{readImageBack}
										</div>
										<div className="btn-wrap width_drag-and-drop">
											<button
												className="btn-raised color_default spread_width"
												disabled={identityCardUpload}
												onClick={(e) =>
													this.handleSubmit(e)
												}
											>
												申請する
											</button>
										</div>
									</div>
								</div>
							</form>
							<p className="text-annotation margin_bottom">
								年齢認証は24時間以内に完了します。
							</p>
							<p className="text-annotation">
								申請頂いた証明書の画像は、すべてサービスとは別のサーバーにて厳重管理しております。ご本人確認・年齢確認以外の目的で使用することはありません。
							</p>
						</div>
					</div>
				</div>
				<Loading display={this.state.loading} />
				<Snackbar
					bodyStyle={{ lineHeight: "24px", height: "auto", padding: "10px" }}
					open={this.state.snackOpen}
					message={this.state.errorValidate}
					autoHideDuration={4000}
					onRequestClose={(e) => this.handleRequestClose(e)}
				/>
			</div>
		)
	}

	// 年齢認証登録完了
	completeAgeAuth() {
		return (
			<div className="content">
				<h1 className="content__title">設定</h1>
				<ol className="breadcrumb">
					<li className="breadcrumb__item">
						<Link to="/home/setting/account">アカウント編集</Link>
					</li>
					<li className="breadcrumb__item">
						<p>年齢認証</p>
					</li>
				</ol>
				<div className="content__inner layout_settings-age">
					<div className="primary">
						<div className="primary-title layout_settings-result">
							<h1 className="primary-title__main">
								身分証の登録が完了しました
							</h1>
							<div className="primary__inner layout_settings-result">
								<p>
									身分証明書の確認は24時間以内に行われます。
									<br />
									確認後は「通知」に結果を送信いたしますので、
									<br />
									今しばらくお待ちくださいませ。
								</p>
								<Link to="/home/setting/account">
									アカウント編集に戻る
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		return <div>
			{this.dispatchPage()}
		</div>
	}
}

const mapStateToProps = (state) => {
	return {
		MyAccountAgeAuth: state.MyAccountAgeAuth,
		MyAccount: state.MyAccount,
	}
}

export default connect(mapStateToProps)(SettingAgeAuth)
