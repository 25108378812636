import { NEWS_FETCH } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, isload, data) => {
	return {
		type: type,
		loaded: isload,
		data,
	}
}

//チャット履歴（商談中:conversation=true）
export const fetchData = (page, size) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.path_news, {
				params: {
					page: page,
					size: size,
				},
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(NEWS_FETCH, true, response.data.data)
					)
				} else {
					dispatch(resultSuccess(NEWS_FETCH, true, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + NEWS_FETCH, error)
				throw error
			})
	}
}
