import Tab from '@mui/material/Tab'
import React from 'react'
import { colors, DEVICE } from '../../../../util/StellaConstant'
import { isPWA } from '../../../../helper/helpFunction'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
	root: {
		[`@media ${DEVICE.mobileS}`]: {
			fontFamily: 'HiraKakuPro-W3 !important',
			fontSize: '12px !important',
			'&.Mui-selected': {
				color: `${colors.pearlAqua} !important`,
			},
		},
		[`@media ${DEVICE.laptop}`]: {
			fontSize: '20px !important',
		},
		[`@media ${DEVICE.laptopL}`]: {
			fontSize: '15px !important',
		},

	}
})

const CustomTab = (props) => {
	const { label, ...other } = props
	const classes = useStyles()
	return (
		<Tab classes={{ root: classes.root }} label={label} {...other}/>
	)
}

export default CustomTab