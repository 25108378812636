import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as TimelineActions from '../actions/TimelineActions'
import { Gender } from '../constants/Config'
import * as moment from 'moment'
import TimelineSubCommentContent from './TimelineSubCommentContent'
import TimelineCommentInputContent from './TimelineCommentInputContent'

class TimelineCommentContent extends Component {
	constructor(props) {
		super(props)
		this.state = {
			disabled: 'disabled',
			delCommentId: '',
		}
		this.handleClick = this.handleClick.bind(this)
	}

	componentWillMount() {
		const { dispatch, postId, commentNumber } = this.props
		if (commentNumber > 0) {
			dispatch(TimelineActions.timelineCommentGet({ postId: postId }))
		}
	}

	componentWillReceiveProps(nextProps) { }

	handleChange(event) {
		let disabled = 'disabled'
		if (event.target.value.length !== 0) {
			disabled = ''
		}

		this.setState({
			disabled: disabled,
		})
	}

	acceptDeleteComment = () => {
		const { dispatch } = this.props
		dispatch(TimelineActions.timelineCommentDelete(this.state.delCommentId))
		document.getElementById(
			`commentId${this.state.delCommentId}`
		).style.display = 'none'
	}

	handleClick(event, commentId) {
		const { confirmDelDialog } = this.props
		this.setState({ delCommentId: commentId }, () => {
			confirmDelDialog.openDialog(() => this.acceptDeleteComment())
		})
	}
	checkAge = (birthday) => {
		const currentYear = moment().year()
		const currentMonth = moment().month() + 1
		const currentDay = moment().day()
		const yearBirth = moment(birthday, 'YYYYMMDD').year()
		const monthBirth = Number(moment(birthday).format("MM"))
		const dayBirth = Number(moment(birthday).format("DD"))
		let age = currentYear - yearBirth
		if (currentMonth < monthBirth) {
			return age - 1
		} else if (currentMonth === monthBirth) {
			if (currentDay < dayBirth) {
				return age - 1
			}
			return age
		}
		return age;
	}
	// 全コメント取得
	getComments() {
		let comments = []

		if (
			this.props.Timeline.hasOwnProperty('comments') &&
			this.props.commentNumber > 0
		) {
			let toYear = moment().year()
			if (this.props.postId in this.props.Timeline.comments) {
				for (let current of this.props.Timeline.comments[
					this.props.postId
				]) {
					comments.push(this.getComment(current, toYear))
					comments.push(this.getSubComment(current, toYear))
				}
			}
		}

		return comments
	}

	// コメント取得
	getComment(current, toYear) {
		let comments = []

		return (
			<li
				className={
					current.approved ? 'list__item' : 'list__item not_approved'
				}
				key={current.commentId}
				id={`commentId${current.commentId}`}
			>
				<div className="timeline-comment comment_customer">
					<p className="timeline-comment__name">
						{this.checkAge(current.birthday)}代 {this.props.checkGender(current.gender)}
					</p>
					<p className="timeline-comment__time">
						{this.props.checkDiffDate(current.createdTime)}
					</p>
					<div className="timeline-comment__main">
						{current.commentValue}
					</div>
					<TimelineCommentInputContent
						postId={current.postId}
						commentId={current.commentId}
						userId={current.userId}
					/>
				</div>
			</li>
		)
	}

	// サブコメント取得
	getSubComment(current, toYear) {
		const currentTellerId = this.props.MyProfile.data.fortuneTellerId
		return (
			<TimelineSubCommentContent
				{...this.props}
				currentTellerId={currentTellerId}
				group={this.checkAge(current.birthday)}
				subcomments={current.subcomments}
				onClick={this.handleClick}
			/>
		)
	}

	render() {
		let comments = this.getComments()
		if (comments && this.props.isView) {
			return (
				<div className="timeline__inner border_top">
					<ul className="list_comment column_1">{comments}</ul>
				</div>
			)
		} else {
			return false
		}
	}
}

const mapStateToProps = (store) => {
	return {
		Timeline: store.Timeline,
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(TimelineCommentContent)
