import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { glasConfig } from '../constants/Config'
import { Category } from '../constants/ConfigStaffblog'
import request from 'axios'
import * as moment from 'moment'
import StaffBlog from './StaffBlog'
import $ from 'jquery'
import { StyledContainerMainContent } from './Home/Styled/StyledHomeComponents'
import { connect } from 'react-redux'
import * as CategoryBlogActions from './../actions/CategoryBlogAction'
class StaffBlogDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
		}
	}

	componentWillMount() {
		this.getData(this.props.match.params.id)
	}

	componentDidMount() {
		const { dispatch } = this.props
		dispatch(CategoryBlogActions.getCategoryBlog())
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('運営ブログ')
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
	}

	componentWillReceiveProps(nextProps) {
		this.getData(nextProps.match.params.id)
	}

	/**
	 * 記事詳細を取得
	 */
	getData(id) {
		const url = glasConfig.url_base + glasConfig.blog_detail + '/' + id
		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		request(options)
			.then((response) => {
				this.setState({
					data: response.data.code === 0
						? response.data.data
						: {},
				})
			})
			.catch((error) => {
				//throw error
			})
	}
	render() {
		const { CategoryBlog } = this.props
		if (!Object.keys(this.state.data).length) {
			return null
		}
		return (
			<StyledContainerMainContent>
				<h1 className="content__title">運営ブログ</h1>
				<Link to="/home/staffblog/1" className="link-to-staff-log">
					一覧に戻る
				</Link>
				<ol className="breadcrumb hidden-sp">
					<li className="breadcrumb__item">
						<Link to="/home/staffblog/1">運営ブログ</Link>
					</li>
					{this.state.data.categories.map((res) => {
						return (
							<li
								key={res.category_name}
								className="breadcrumb__item hidden-sp"
							>
								<p>{res.category_name}</p>
							</li>
						)
					})}
				</ol>
				<div className="card-wrap">
					<div className="card view_detail">
						<div className="card-main">
							<div className="card-main__inner">
								<h3 className="card-main__title">
									{this.state.data.title}
								</h3>
								<p className="card-main__time">
									{moment(this.state.data.created_time, 'YYYYMMDDHHmmss').format(
										'YYYY/MM/DD HH:mm'
									)}
								</p>
								<ul className="list">
									{this.state.data.categories
										.map((res) => {
											return (
												<li className="list__item">
													<Link
														to={`/home/staffblog/1/${res.category_id}`}
													>
														{res.category_name}
													</Link>
												</li>
											)
										})}
								</ul>
								<div
									className="card-main__content"
									dangerouslySetInnerHTML={{
										__html: this.state.data.content,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<h2 className="content__title lv_2">関連記事</h2>
				<StaffBlog
					category={this.state.data.categories[0].category_id}
					page={1}
					size={7}
					exclude={this.state.data.blog_id}
				/>
			</StyledContainerMainContent>
		)
	}
}
const mapStateToProps = (store) => {
	return {
		CategoryBlog: store.CategoryBlog,
	}
}
export default connect(mapStateToProps)(StaffBlogDetail)