import {
	USER_IDLE_ACTION_SHOW_POPUP,
	USER_IDLE_ACTION_HIDE_POPUP,
	USER_IDLE_ACTION_CONTINUE_WAITING_SYNC,
	USER_IDLE_ACTION_FINISH_WAITING_SYNC,
} from '../constants/ActionTypes'

export const resultSuccess = (type, loaded, data) => {
	return { type, loaded, data }
}

export const showPopup = () => {
	return (dispatch) => {
		dispatch(
			resultSuccess(USER_IDLE_ACTION_SHOW_POPUP, true, {
				idleAt: new Date(),
			})
		)
	}
}

export const hidePopup = () => {
	return (dispatch) => {
		dispatch(
			resultSuccess(USER_IDLE_ACTION_HIDE_POPUP, true, { idleAt: null })
		)
	}
}

export const syncContinueWaiting = () => {
	return (dispatch) => {
		dispatch(
			resultSuccess(USER_IDLE_ACTION_CONTINUE_WAITING_SYNC, true, {
				idleAt: null,
			})
		)
	}
}

export const syncFinishWaiting = () => {
	return (dispatch) => {
		dispatch(
			resultSuccess(USER_IDLE_ACTION_FINISH_WAITING_SYNC, true, {
				idleAt: null,
			})
		)
	}
}
