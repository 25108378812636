import React from 'react'
import { Link } from 'react-router-dom'
import { colors, fonts } from '../../../../util/StellaConstant'
import { RedirectDailyReward } from '../../StyledPointReport'

const ButtonRedirectDailyReward = () => {

	return (
		<>
			<RedirectDailyReward>
				<Link
					to="/home/reward/daily"
					style={{
						display: 'block',
						padding: '11px 26.5px',
						fontFamily: fonts.HiraginoKakuGothicPro,
						fontSize: 16,
						color: colors.mediumBlue,
						textDecoration: 'none'
					}}
				>
					日別報酬を見る
				</Link>
			</RedirectDailyReward>
		</>
	)
}

export default ButtonRedirectDailyReward