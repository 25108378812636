import {
	REQUEST_HISTORY,
	REQUEST_HISTORY_MERGE,
} from '../constants/ActionTypes'

const initialState = {
	loaded: false,
	data: {},
	page: 0,
	endHistory: false,
}

export const RequestHistory = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_HISTORY: {
			return Object.assign({}, state, {
				loaded: true,
				data: action.data.data,
				page: action.data.page,
				endHistory: action.data.endHistory,
			})
		}
		case REQUEST_HISTORY_MERGE: {
			if (action.data.userId) {
				let orgData = {}
				if (!state.data[action.data.userId]) {
					if (action.data.data.unreadMessageBadge === undefined) {
						action.data.data.unreadMessageBadge = 1
					}
					orgData = action.data.data
				} else {
					orgData = state.data[action.data.userId]
					if (action.data.data.unreadMessageBadge !== 0) {
						action.data.data.unreadMessageBadge =
							orgData.unreadMessageBadge === undefined
								? 0
								: orgData.unreadMessageBadge + 1
					}
					//一旦結合させる
					Object.assign(orgData, action.data.data)
					//先頭にもってくるために、一旦state.dataから削除する
					// delete state.data[action.data.userId]
				}
				//先頭に追加
				if (action.data.sort)
					state.data = Object.assign(
						{
							[action.data.userId]: orgData,
						},
						state.data
					)
				else state.data[action.data.userId] = orgData
			}
			return Object.assign({}, state, {
				loaded: true,
			})
		}
		default: {
			return state
		}
	}
}
