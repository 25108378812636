import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import NotFound from './NotFound'
import Header from './Header'
import LeftMenu from './LeftMenu'
import Home from './Home'
import Chat from './Chat'
import Timeline from './Timeline'
import RouteReward from './RouteReward'
import RouteAnswer from './RouteAnswer'
import RouteSetting from './RouteSetting'
import Menu from './Menu'
import RouteCustomerManagement from './RouteCustomerManagement'
import RouteAnalysis from './RouteAnalysis'
import DialogWarningFullChat from './DialogWarningFullChat'
import * as ChatRequestActions from '../actions/ChatRequestActions'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import Review from './Review'
import Help from './Help'
import RouteStaffBlog from './RouteStaffBlog'
import * as WebSocket from '../util/WebSocket'
import * as MyProfileActions from '../actions/MyProfileActions'
import * as CallActions from '../actions/CallActions'
import { FireMsg } from '../util/Firebase'
import RouteMission from './RouteMission'
import RouteContact from './RouteContact'
import ContactCreate from './ContactCreate'
import { stringify } from 'querystring'
import RouteIntroduction from './introduction/route'
import User from '../models/User'
import Bell from './ChatContent/Frames/Bell'
import * as Fetch from '../util/Fetch'
import moment from 'moment'
import CallController from './Call/CallController'
import AgoraRTC from 'agora-rtc-sdk-ng'
import { isPWA } from '../helper/helpFunction'
import PointReport from './PointReport/PointReport'
import FloatingEvent from './Event/FloatingEvent'


const isComfortable = AgoraRTC.checkSystemRequirements()
class Layout extends Component {
	constructor(props) {
		super(props)
		User.connectToSocket()
		this.socket = User.getSocket()
		this.state = {
			chatUserId: '',
			showDialogWarningFullChat: false,
			bottomItemActive: 1,
			pathname: ''
		}
		this.userNameOfflineRequest = ''
	}

	activeItem = (item) => {
		this.setState({ bottomItemActive: item })
	}

	componentWillMount() {
		const { dispatch, MyProfile } = this.props
		if (!MyProfile.loaded) {
			dispatch(MyProfileActions.fetch())
		}
		this.setState({ pathname: this.props.history.location.pathname })

		this.handleRemoveAnswerDraftExpired()

	}

	handleRemoveAnswerDraftExpired = () => {
		this.props.dispatch(AnswerManagementActions.removeAnswerDraft())
	}

	componentDidMount() {
		const { dispatch } = this.props
		dispatch(CallActions.getAgoraConfigurations())
		const tellerId = Fetch.tellerId()
		const deviceType = Fetch.fetchUserAgent()
		const message = {
			fromId: tellerId,
			deviceType,
			msgType: 'AUTHEN',
			value: localStorage.getItem('token'),
			time: moment.utc(420).format('YYYYMMDDhhmmss'),
			applicationVersion: 0.0,
		}

		this.socket.on('disconnect', () => {
			console.log(
				'%c DISCONNECT: ',
				'color: #0e93e0;background: #aaefe5;',
				this.socket
			)
		})

		this.socket.on('connect', () => {
			console.log(
				'%c  CONNECTED:',
				'color: #0e93e0;background: #aaefe5;',
				this.socket
			)
			this.socket.emit('authen', JSON.stringify(message))
		})

		if (FireMsg) {
			FireMsg.onMessage((payload) => {
				console.log('%c [STELLA] payload: ', 'color: red', payload)
				const pushData = JSON.parse(payload.data.data)
				const pushAlert = pushData.alert
				this.userNameOfflineRequest = pushAlert['loc-args'][0]
				if (pushAlert['loc-key'] === 'push_pick_best_answer') {
					const numberUnreadBestAnswer = localStorage.getItem(
						'numberUnreadBestAnswer'
					)
						? parseInt(
							localStorage.getItem('numberUnreadBestAnswer')
						)
						: 0
					localStorage.setItem(
						'numberUnreadBestAnswer',
						numberUnreadBestAnswer + 1
					)
					localStorage.setItem('showPopUpBestAnswer', 'true')
					dispatch(
						AnswerManagementActions.add(
							numberUnreadBestAnswer + 1,
							true
						)
					)
				} else if (pushAlert['loc-key'] === 'push_like_teller_answer') {
					localStorage.setItem(
						'lastNotificationLikeAnswer',
						pushData.data.questionId
					)
					dispatch(AnswerManagementActions.getGoodAnswer())
					dispatch(
						MyProfileActions.add({
							numberUnreadNotification: 1,
						})
					)
				} else if (pushAlert['loc-key'] === 'push_chat') {
					dispatch(
						MyProfileActions.add({
							numberUnreadNotification: 0,
						})
					)
				} else if (pushAlert['loc-key'] === 'push_teller_introduce_user') {
					dispatch(
						MyProfileActions.add({
							numberUnreadNotification: 1,
						})
					)
				}
				else {
					dispatch(
						MyProfileActions.add({
							numberUnreadNotification: 1,
						})
					)
				}
				if (pushAlert['loc-key'] === 'push_like') {
					//いいね
				}
				// alert(payload.notification.title+"\r\n"
				//       +payload.notification.body+"\r\n"
				//       +payload.notification.icon);
			})
		}
	}

	componentWillUnmount() {
		// this.socket.disconnect()
	}


	componentWillReceiveProps(nextProps) {
		if (this.state.pathname !== nextProps.history.location.pathname) {
			this.setState({ pathname: nextProps.history.location.pathname })
		}
		const requestPath = nextProps.location.pathname
		const pathinfo = requestPath.split('/')
		const userId =
			pathinfo['2'] === 'chat' && pathinfo['3'] ? pathinfo['3'] : ''
		this.setState({
			userId: userId,
		})
	}

	closeWarningFullChatDialog = () => {
		const { data } = this.props.ChatRequest
		if (data[this.state.chatUserId]) {
			this.props.dispatch(
				ChatRequestActions.post({
					userId: this.state.chatUserId,
					msgId: data[this.state.chatUserId].msgId,
					accept: false,
				})
			)
		}
	}

	openWarningFullChatDialog = (chatUserId) => {
		const { MyProfile } = this.props
		this.setState({ chatUserId })
		this.dialog.openDialog(MyProfile.data.maxNumberUserChatting)
	}

	render() {
		const { MyProfile, match, Call } = this.props

		if (!MyProfile.loaded) {
			return null
		}



		return (
			<div>
				{(isComfortable && !isPWA()) ? (
					<CallController
						socket={this.socket}
						dispatch={this.props.dispatch}
						history={this.props.history}
						pathname={this.state.pathname}
						direction={this.props.history}
					/>
				) : null}
				<Bell fromHome />
				<Header
					bottomItemActive={this.state.bottomItemActive}
					userId={this.state.userId}
					{...this.props}
				/>
				<LeftMenu
					userId={this.state.userId}
					history={this.props.history}
					userNameOfflineRequest={this.userNameOfflineRequest}
					openWarningFullChatDialog={(chatUserId) =>
						this.openWarningFullChatDialog(chatUserId)
					}
					socket={this.socket}
				/>
				<Switch>
					<Route
						exact
						path={`${match.path}`}
						render={(props) => (
							<Home activeItem={this.activeItem} {...props} />
						)}
					/>
					<Route
						exact
						path={`${match.path}/chat/:userid`}
						render={(props) => (
							<Chat socket={this.socket} {...props} />
						)}
					/>
					<Route
						exact
						path={`${match.path}/timeline`}
						render={(props) => (
							<Timeline activeItem={this.activeItem} {...props} />
						)}
					/>
					<Route
						path={`${match.path}/answer`}
						render={(props) => (
							<RouteAnswer
								activeItem={this.activeItem}
								{...props}
							/>
						)}
					/>
					<Route
						path={`${match.path}/reward`}
						render={(props) => (
							<RouteReward
								activeItem={this.activeItem}
								{...props}
							/>
						)}
					/>
					<Route
						path={`${match.path}/analysis`}
						render={(props) => <RouteAnalysis {...props} />}
					/>
					<Route
						path={`${match.path}/setting`}
						render={(props) => (
							<RouteSetting
								activeItem={this.activeItem}
								{...props}
							/>
						)}
					/>
					<Route
						path={`${match.path}/customermanagement`}
						render={(props) => (
							<RouteCustomerManagement
								activeItem={this.activeItem}
								{...props}
							/>
						)}
					/>
					<Route path={`${match.path}/review`} component={Review} />
					<Route path={`${match.path}/point-report`} component={PointReport} />
					<Route
						path={`${match.path}/contact`}
						component={RouteContact}
					/>
					<Route path={`${match.path}/help`} component={Help} />
					<Route path={`${match.path}/help/:id?`} component={Help} />
					<Route
						path={`${match.path}/staffblog`}
						component={RouteStaffBlog}
					/>
					<Route
						path={`${match.path}/menu`}
						render={(props) => (
							<Menu activeItem={this.activeItem} {...props} />
						)}
					/>
					<Route
						path={`${match.path}/mission`}
						render={(props) => (
							<RouteMission
								activeItem={this.activeItem}
								{...props}
							/>
						)}
					/>
					<Route
						path={`${match.path}/introduction`}
						render={(props) => (
							<RouteIntroduction
								activeItem={this.activeItem}
								{...props}
							/>
						)}
					/>
					<Route component={NotFound} />
				</Switch>
				<DialogWarningFullChat
					ref={(dialog) => (this.dialog = dialog)}
					closeWarningFullChatDialog={this.closeWarningFullChatDialog}
				/>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		ChatRequest: state.ChatRequest,
		AnswerManagement: state.AnswerManagement,
		Call: state.Call,
	}
}

export default connect(mapStateToProps)(Layout)
