import React, { Component } from 'react'
import { connect } from 'react-redux'
import TimelineContent from './TimelineContent'
import DialogWarningDeleteTimeLine from './DialogWarningDeleteTimeLine'
import User from '../models/User'

class Timeline extends Component {
	constructor(props) {
		super(props)
		// this.socket = WebSocket.connect()
		this.socket = User.getSocket()
	}

	componentWillMount() {
		this.props.activeItem(2)
	}

	componentWillUnmount() {
		// this.socket.disconnect()
	}

	render() {
		return (
			<div className="content">
				<TimelineContent
					{...this.props}
					confirmDelDialog={this.confirmDelTimelineDialog}
				/>
				<DialogWarningDeleteTimeLine
					ref={(ref) => (this.confirmDelTimelineDialog = ref)}
					dispatch={this.props.dispatch}
				/>
			</div>
		)
	}
}

// グローバルなstateから必要な値をとってきて、コンポーネントのthis.propsとしてセット
// 今回はstateをまるごと返してます
const mapStateToProps = (state) => {
	return {
		tellerId: state.Auth.tellerId,
		Auth: state.Auth.data,
	}
}

//connect関数でReduxとReactコンポーネントを繋ぐ
export default connect(mapStateToProps)(Timeline)
