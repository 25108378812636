/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useReducer } from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import { DEVICE } from '../../../util/StellaConstant'
import {
	BUTTON_INCALL_STATUS,
	BUTTON_INCALL,
	BUTTON_TYPE,
	CALLTYPE,
	CALL_GENDER,
} from '../../../constants/CallConstants'
import {
	BottomBar,
	FakeOverlay,
	LeftBottomBar,
	RemainingTime,
	RightBottomBar,
	TimeOfCall,
	TopBar,
	UserAvatar,
	UserName,
	UserNameBottom,
} from '../StyledComponents/StyledDialogInCallComponents'
import ButtonWithIcon from '../frames/ButtonWithIcon'
import { connect } from 'react-redux'
import DialogConfirmEndCall from './DialogConfirmEndCall'
import MediaPlayer from '../frames/MediaPlayer'
import TimerInCall from '../frames/TimerInCall'
import { isTablet } from 'react-device-detect'
import * as MyProfileActions from '../../../actions/MyProfileActions'


const reducer = (state, newState) => {
	return { ...state, ...newState }
}

const DialogInCall = (props) => {
	// let checkPathName = props.history.includes('home/chat')

	const classes = useStyles()
	const {
		show,
		closeCallScreen,
		remoteUsers,
		socket,
		mute,
		unMute,
		join,
		joinState,
		endACall,
		CallStoredData,
		resetCallParams,
		remainingTime
	} = props

	const [open, setOpen] = useState(false)
	const [openEndCall, setOpenEndCall] = useState(false)
	const [remaining, setRemaining] = useState()

	const callParams = CallStoredData.callParams
	const userName = callParams.callerName || 'NAME'
	const gender = CALL_GENDER[callParams.gender]
	const [devices, setDevices] = useReducer(reducer, {
		MICRO: BUTTON_INCALL_STATUS.ON,
		ENDCALL: BUTTON_INCALL_STATUS.ON,
		SPEAKER: BUTTON_INCALL_STATUS.ON,
	})

	useEffect(() => {
		setOpen(show)
	}, [show])
	useEffect(() => {
		if (!remainingTime) {
			setRemaining('-')
			return
		}
		setRemaining(remainingTime)
	}, [remainingTime])

	useEffect(() => {
		if (callParams.callee) {
			join(
				callParams.appId,
				callParams.sessionId,
				callParams.tokenId,
				callParams.uid
			)
			sendPUBLISHSTREAM()
		}
		const intervalID = setInterval(() => {
			MyProfileActions.refreshNewToken()
		}, 60000)

		return () => {
			clearInterval(intervalID)
		}

	}, [])


	useEffect(() => {
		if (document.getElementsByClassName('MuiDialog-container')[0] !== undefined)
			document.getElementsByClassName('MuiDialog-container')[0].removeAttribute('tabindex')
	})

	useEffect(() => {
		console.log('%c remoteUsers: ', 'color: red', remoteUsers[0])
	}, [remoteUsers])

	const sendPUBLISHSTREAM = () => {
		const params = {
			caller: callParams.caller,
			callee: callParams.callee,
			owner: callParams.owner,
			messageType: CALLTYPE.PUBLISH_STREAM,
			sessionId: callParams.sessionId,
			socketId: callParams.socketId,
		}
		socket.emit('CALL_MESSAGE', JSON.stringify(params))
	}

	const handleClickDevice = (type) => {
		if (type === BUTTON_INCALL.ENDCALL) {
			openConfirmEndCall()
		}
		if (devices[type] === BUTTON_INCALL_STATUS.ON) {
			setDevices({ [type]: BUTTON_INCALL_STATUS.OFF })

			// HANDLE MUTE DEVICES
			mute(type, remoteUsers)
		} else {
			setDevices({ [type]: BUTTON_INCALL_STATUS.ON })

			// HANDLE UNMUTE DEVICES
			unMute(type, remoteUsers)
		}
	}

	const closeDialog = () => {
		closeCallScreen()
	}

	const openConfirmEndCall = () => {
		setOpenEndCall(true)
	}

	const closeConfirmEndCall = () => {
		setOpenEndCall(false)
	}

	return (
		<>
			{
				joinState ? (
					// <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, zIndex: 99999, backgroundColor: 'rgba(0, 0, 0, 0.4)' }}>
					<div className={classes.wrap}>
						<Dialog
							open={open}
							// open={true}
							classes={{
								root: classes.root,
								paper: classes.paper,
								scrollPaper: classes.scrollPaper,
							}}
							BackdropProps={{
								classes: {
									root: classes.backdrop,
								},
							}}
						>
							<BottomBar>
								<LeftBottomBar>
									<UserAvatar userGender={gender} />
									<UserNameBottom>{userName}</UserNameBottom>
									<TimeOfCall>
										<TimerInCall />
									</TimeOfCall>
									<RemainingTime>
										残り{remaining}分通話できます
									</RemainingTime>
								</LeftBottomBar>
								<RightBottomBar>
									<ButtonWithIcon
										buttonType={BUTTON_INCALL.MICRO}
										status={devices.MICRO}
										onClick={() =>
											handleClickDevice(BUTTON_INCALL.MICRO)
										}
									/>
									<ButtonWithIcon
										buttonType={BUTTON_INCALL.ENDCALL}
										status={devices.ENDCALL}
										onClick={() =>
											handleClickDevice(BUTTON_INCALL.ENDCALL)
										}
									/>
									<ButtonWithIcon
										buttonType={BUTTON_INCALL.SPEAKER}
										status={devices.SPEAKER}
										onClick={() =>
											handleClickDevice(BUTTON_INCALL.SPEAKER)
										}
									/>
								</RightBottomBar>
							</BottomBar>
						</Dialog>
					</div>
				) : null
			}
			{
				remoteUsers.map((user) => (
					<MediaPlayer audioTrack={user.audioTrack} />
				))
			}
			<DialogConfirmEndCall
				showConfirm={openEndCall}
				endACall={endACall}
				closeConfirm={closeConfirmEndCall}
				resetCallParams={resetCallParams}
			/>
		</>
	)
}

const useStyles = makeStyles({
	wrap: {
		[`@media ${DEVICE.mobileS}`]: {
			position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, zIndex: 99999, backgroundColor: 'rgba(0, 0, 0, 0.4)'
		},
		[`@media ${DEVICE.tablet}`]: {
			position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, zIndex: 99999, backgroundColor: 'rgba(0, 0, 0, 0.4)'
		},
		[`@media ${DEVICE.laptop}`]: {
			position: 'absolute', left: 0, right: 'calc(100% - 256px)', bottom: 0, top: 0, zIndex: 99999, backgroundColor: 'rgba(0, 0, 0, 0.4)'
		},
	},
	root: {
		[`@media ${DEVICE.mobileS}`]: {
			top: '44px !important',
			zIndex: '99999 !important',
		},
		[`@media ${DEVICE.tablet}`]: {
			top: '0 !important',
			left: '256px !important',
			width: 'calc(100% - 256px)',
			zIndex: '99999 !important',
		},
		[`@media ${DEVICE.laptop}`]: {
			top: 'calc(100% - 142px) !important',
			left: '256px !important',
			width: 'calc(100% - 256px)',
			zIndex: '99999 !important',
		},
	},
	backdrop: {
		[`@media ${DEVICE.mobileS}`]: {
			top: '44px !important',
		},
		[`@media ${DEVICE.tablet}`]: {
			top: '0 !important',
			left: '256px !important',
		},
		[`@media ${DEVICE.laptop}`]: {
			// top: '0 !important',
			right: '256px !important',
			bottom: 'calc(100% - 68px) !important',
			left: '256px !important',
		},
	},
	paper: {
		[`@media ${DEVICE.mobileS}`]: {
			width: '100%',
			height: '100%',
			margin: '0px !important',
			maxWidth: '100% !important',
			maxHeight: '100% !important',
			borderRadius: '0 !important',
			backgroundColor: 'transparent !important',
			alignSelf: 'flex-end',
		},
		[`@media ${DEVICE.tablet}`]: {
			width: '100%',
			height: 'auto',
			margin: '0px !important',
			maxWidth: '100% !important',
			maxHeight: '100% !important',
			borderRadius: '0 !important',
			backgroundColor: 'transparent !important',
			alignSelf: 'flex-end',
		},
		[`@media ${DEVICE.laptop}`]: {
			width: '100%',
			height: 'auto',
			margin: '0px !important',
			maxWidth: '100% !important',
			maxHeight: '100% !important',
			borderRadius: '0 !important',
			backgroundColor: 'transparent !important',
			alignSelf: 'flex-end',
		},
	},
	scrollPaper: {
		[`@media ${DEVICE.mobileS}`]: {},
		[`@media ${DEVICE.tablet}`]: {},
		[`@media ${DEVICE.laptop}`]: {},
	},
})
const mapStateToProps = (state) => {
	return {
		CallStoredData: state.Call,
	}
}

export default connect(mapStateToProps)(DialogInCall)
