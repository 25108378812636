import React from 'react';
import { Dialog } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
	Title,
	Content,
	TextTransfer,
	TextDescription,
	Group,
} from '../StyledComponents/StyledDialogConfirmPayoffComponents';
import { Button } from '../Frames/Button';
import { colors, DEVICE } from '../../../../util/StellaConstant';
 
import { CONFIRM_PAYOFF_BUTTON } from '../../../../util/StellaConstant';
 
const DialogConfirmPayoff = (props) => {
	const classes = useStyles();
	const {
		openDialogPayoff,
		closeDialogPayoff,
		transferAmount,
		bankaccount,
		checkRegist,
		myProfile,
		handleRegist,
	} = props;
	return (
		<Dialog
			open={openDialogPayoff}
			onClose={closeDialogPayoff}
			classes={{ paper: classes.paper }}
		>
			<Title>振込予定額</Title>
			<Content>
				<TextTransfer>{transferAmount}円</TextTransfer>
				<TextDescription>
           こちらの金額でお間違いないでしょうか？
				</TextDescription>
			</Content>
 
			<Group>
				{bankaccount === 0 ? (
					<Link
						className='btn-raised color_default'
						to='/home/setting/bank/top'
					>
             銀行口座を登録する
					</Link>
				) : (
					<button
						className="btn-raised color_default"
						onClick={() => handleRegist()}
					>
					    精算する
					</button>
				// 		<Button
				// 			className="btn-raised color_default"
				// 			buttonType={CONFIRM_PAYOFF_BUTTON.ACCEPT}
				// 			onClick={() => handleRegist(myProfile)}
				// 			isEnable={checkRegist}
				// 		>
				//  精算する
				// 		</Button>
				)}

				<div className="mission-item__close popup-item_close">
					<a onClick={closeDialogPayoff}>
						{/* キャンセル */}
						キャンセル
					</a>
				</div>

				{/* <Button
					buttonType={CONFIRM_PAYOFF_BUTTON.CANCEL}
					onClick={closeDialogPayoff}
					isEnable={true}
				>
           キャンセル
				</Button> */}
			</Group>
		</Dialog>
	);
};
 
const useStyles = makeStyles({
	paper: {
		display: 'flex !important',
		flexDirection: 'column !important',
		alignItems: 'center !important',
		padding: '36px 14px 14px 14px !important',
		backgroundColor: `${colors.white} !important`,
		[`@media ${DEVICE.mobileS}`]: {
			width: '80%',
			maxWidth: '260px !important',
			borderRadius: '2px !important',
			boxShadow: ` 0 19px 38px 0 ${colors.grey}, 0 15px 12px 0 ${colors.lightGrey} !important`,
		},
		[`@media ${DEVICE.tablet}`]: {
			width: '50%',
			maxWidth: '510px !important',
			borderRadius: '0px',
			boxShadow: 'none',
		},
		[`@media ${DEVICE.laptop}`]: {
			width: '38%',
			maxWidth: '410px !important',
			borderRadius: '0px',
			boxShadow: 'none',
		},
	},
});
export default DialogConfirmPayoff;