import {
	OPEN_PRE_CALL_SCREEN,
	CLOSE_PRE_CALL_SCREEN,
	OPEN_IN_CALL_SCREEN,
	CLOSE_IN_CALL_SCREEN,
	UPDATE_CALL_PARAMS,
	GET_AGORA_CONFIGURATION,
	RESET_CALL_PARAMS,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'

const resultSuccess = (type, error = '', data = '') => {
	return {
		type: type,
		errormsg: error,
		data,
	}
}

export const updateCallParams = (params) => {
	return (dispatch) => {
		dispatch(resultSuccess(UPDATE_CALL_PARAMS, '', params))
	}
}

export const resetCallParams = () => {
	return (dispatch) => {
		dispatch(resultSuccess(RESET_CALL_PARAMS))
	}
}

export const getAgoraConfigurations = () => {
	const token = localStorage.getItem('token')
	return (dispatch) => {
		return request
			.get(glasConfig.url_base + glasConfig.path_agora_configurations, {
				headers: { Authorization: token },
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(
							GET_AGORA_CONFIGURATION,
							'',
							response.data.data
						)
					)
				}
			})
			.catch((error) => {
				console.log('error on call ' + GET_AGORA_CONFIGURATION, error)
				dispatch(
					resultSuccess(GET_AGORA_CONFIGURATION, 'システムエラー', {})
				)
			})
	}
}
