import { MYINFO_ACCOUNT_PASSWORD_PUT } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'
import SparkMD5 from 'spark-md5'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

// アカウント情報更新
export const put = (nowPassword, newPassword) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()

		return request
			.put(
				glasConfig.url_base +
					glasConfig.path_teller_info +
					tellerId +
					'/passwords',
				{
					oldPassword: SparkMD5.hash(nowPassword),
					newPassword: newPassword,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(
							MYINFO_ACCOUNT_PASSWORD_PUT,
							response.data.data
						)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + MYINFO_ACCOUNT_PASSWORD_PUT,
					error
				)
				throw error
			})
	}
}
