import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducers from '../reducers'

const middlewares = [thunk]

export default function configureStore() {
	const store = createStore(rootReducers, applyMiddleware(...middlewares))
	return store
}
