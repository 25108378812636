import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import $ from 'jquery'
import './Reward/RewardExplanation.scss'

class SettingMenu extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			authAvatar: true,
			authAge: true,
		}
	}

	authInfo = (myAccount, myProfile) => {
		const authAvatar = !myProfile.data.avatarPath
		const authAge = Object.keys(myAccount.data).length
			? myAccount.data.verifyAge === 'None'
			: myProfile.data.verifyAge === 'None'

		this.setState({ authAvatar, authAge })
	}

	componentWillReceiveProps(nextProps) {
		this.authInfo(nextProps.MyAccount, nextProps.MyProfile)
	}

	componentDidMount() {
		this.authInfo(this.props.MyAccount, this.props.MyProfile)
		let currentTab = $('.lead-nav .btn-tab.is-active').parent()

		if (currentTab.length) {
			let ul = currentTab.parent()
			let currentTabPos = currentTab.offset()
			let ulPos = ul.offset()
			let navWidth = ul.parent().outerWidth()
			let scrollLeft =
				currentTabPos.left -
				ulPos.left -
				8 -
				(navWidth - currentTab.outerWidth()) / 2
			$('.lead-nav').scrollLeft(scrollLeft)
		}
	}

	render() {
		const bankRouteRegex = new RegExp('^/home/setting/bank.+$')
		let pathName = window.location.pathname

		let setting =
			'btn-tab' + (pathName === '/home/setting' ? ' is-active' : '')
		let template =
			'btn-tab' +
			(pathName.indexOf('/home/setting/template') !== -1
				? ' is-active'
				: '')
		let account =
			'btn-tab' +
			(pathName.indexOf('/home/setting/account') !== -1
				? ' is-active'
				: '')
		let bank =
			'btn-tab' + (bankRouteRegex.exec(pathName) ? ' is-active' : '')
		let contract =
			'btn-tab' +
			(pathName.indexOf('/home/setting/contract') !== -1
				? ' is-active'
				: '')

		return (
			<>
			<nav className="lead-nav" id="lead-nav">
				<ul className="list lead_nav scroll-menu-list-answer">
					<li className="list__item">
						<Link to="/home/setting" className={setting}>
							プロフィール設定
							<Warning show={this.state.authAvatar} />
						</Link>
					</li>
					<li className="list__item">
						<Link to="/home/setting/template" className={template}>
							テンプレート編集
						</Link>
					</li>
					<li className="list__item">
						<Link to="/home/setting/account" className={account}>
							アカウント編集
							<Warning show={this.state.authAge} />
						</Link>
					</li>
					{/* <li className="list__item">
						<Link to="/home/setting/bank/top" className={bank}>
							銀行口座情報編集
						</Link>
					</li> */}
					<li className="list__item">
						<Link to="/home/setting/contract" className={contract}>
							利用規約
						</Link>
					</li>
				</ul>
			</nav>
			</>
		)
	}
}

const Warning = ({ show }) => {
	return show ? <b style={styles.warningIcon}> ! </b> : ''
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		MyAccount: state.MyAccount,
	}
}

export default connect(mapStateToProps)(SettingMenu)

const styles = {
	warningIcon: {
		color: 'red',
	},
}
