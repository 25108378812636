import React, { PureComponent } from 'react'
import request from 'axios'
import { glasConfig } from '../../constants/Config'
import Slider from 'react-slick'
import * as Fetch from '../../util/Fetch'
import "./HomeBanner.css";
import InstructionCallingMB from '../../assets/instruction_for_calling_mb.png'
import InstructionCallingTL from '../../assets/instruction_for_calling_tl.png'
import { getDeviceName } from '../../util/Fetch'
import AgoraRTC from "agora-rtc-sdk-ng";
import { isIpad, isPWA } from '../../helper/helpFunction'
import { isTablet } from 'react-device-detect'

const LINK_TO_APP = {
	ANDROID: 'https://play.google.com/store/apps/details?id=com.stella.fortune',
	IOS: 'https://apps.apple.com/app/id1428178675?mt=8'
}

class HomeBanner extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			banner: [],
			additionalBanner: {
				bannerId: 'bannerId',
				imagePath: InstructionCallingMB,
				redirectURL: '',
				title: null,
				webImagePath: InstructionCallingMB
			}
		}
	}
	getAdditionalBanner = () => {
		const deviceName = getDeviceName()
		if (deviceName === 'Mac') {
			this.setState({ additionalBanner: { ...this.state.additionalBanner, redirectURL: LINK_TO_APP.IOS } })
		} else if (deviceName === 'Android') {
			this.setState({ additionalBanner: { ...this.state.additionalBanner, redirectURL: LINK_TO_APP.ANDROID } })
		}
	}

	componentDidMount() {
		this.getAdditionalBanner()
		const token = localStorage.getItem('token')
		const params = {
			method: 'GET',
			headers: {
				Authorization: token,
			},
			url: glasConfig.url_base + glasConfig.path_get_banner
		};

		request(params).then(response => {
			if (response.data.code === 0) {
				if (isPWA() || isIpad() || isTablet) {
					this.setState({
						banner: [this.state.additionalBanner, ...response.data.data,],
					})
				} else {
					this.setState({
						banner: response.data.data
					})
				}
			}
		}).catch(error => {
			console.log(error);
		})
	}



	render() {
		var settings = {
			dots: true,
			infinite: true,
			// centerMode : true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 6000
		};
		const deviceType = Fetch.fetchUserAgent()
		if (this.state.banner.length > 1) {
			return (
				<Slider {...settings}>
					{
						this.state.banner.map((b, index) => (
							<a href={b.redirectURL} key={index}>
								<div key={b.bannerId} className={'test'}>
									<img alt={''}
										src={deviceType === 3 ? b.webImagePath : b.imagePath}
										style={deviceType === 3 ? { width: window.innerWidth - 16, height: ((window.innerWidth - 16) / 1280) * 200 }
											: { width: window.innerWidth - 16, height: ((window.innerWidth - 16) / 320) * 50 }}
									/>
								</div>
							</a>
						))
					}
				</Slider>
			)
		}

		return this.state.banner.map((b, index) => (
			<a href={b.redirectURL} key={index}>
				<div key={b.bannerId} className="singleImgContainer">
					<img
						className="singleImgContainer_img"
						alt={''}
						src={deviceType === 3 ? b.webImagePath : b.imagePath}
						style={
							deviceType === 3
								? {
									width: window.innerWidth - 16,
									height:
										((window.innerWidth - 16) / 1280) *
										200,
								}
								: {
									width: window.innerWidth - 16,
									height:
										((window.innerWidth - 16) / 320) *
										50,
								}
						}
					/>
				</div>
			</a>
		))
	}
}

export default HomeBanner
