import {
	MYINFO_ACCOUNT,
	MYINFO_ACCOUNT_PUT,
	MYINFO_ACCOUNT_PUT_FALSE,
} from '../constants/ActionTypes'

const initialState = {
	data: {},
	loadedGetAccount: false,
	loadedPutAccount: false,
	loading: true,
}

export const MyAccount = (state = initialState, action) => {
	switch (action.type) {
		case 'MYINFO_ACCOUNT_LOADDING':
			return Object.assign({}, { ...state, loadedGetAccount: false })
		case MYINFO_ACCOUNT:
			return Object.assign(
				{},
				{ ...state, loadedGetAccount: true },
				{
					data: action.data,
				}
			)
		case MYINFO_ACCOUNT_PUT:
			return Object.assign(
				{},
				{ ...state, loadedPutAccount: true, loading: false },
				{ data: action.data }
			)
		case MYINFO_ACCOUNT_PUT_FALSE:
			return Object.assign(
				{},
				{ ...state, loadedPutAccount: true, loading: true },
				{ data: action }
			)
		default:
			return state
	}
}
