import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { PrivateRoute } from './PrivateRoute'
import NotFound from './NotFound'
import Reward from './Reward'
import RewardDaily from './RewardDaily'
import RewardHistory from './RewardHistory'
import RewardExplanation from './Reward/RewardExplanation'
import RewardPayOff from './RewardPayOff'
import RouteSettingBank from './RouteSettingBank'

class RouteReward extends Component {
	componentWillMount() {
		this.props.activeItem(4)
	}

	render() {
		return (
			<Switch>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}`}
					component={Reward}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/daily/:month?`}
					component={RewardDaily}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/history`}
					component={RewardHistory}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/RewardExplanation`}
					component={RewardExplanation}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/bank`}
					component={RouteSettingBank} />
				<PrivateRoute
					path={`${this.props.match.path}/payoff`}
					component={RewardPayOff}
				/>
				<Route component={NotFound} />
			</Switch>
		)
	}
}

export default RouteReward
