import React, { useState, useEffect } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { Container } from '../../StyledPointReport/Chart/StyledPointChart'
import { colors } from '../../../../util/StellaConstant'
import { getMaxYAxis, generateDataForChart, POINT_REPORT, getTicks, getCurrentMonth } from '../../../../constants/PointReportConstants'
import { isPWA } from '../../../../helper/helpFunction'
import * as PointReportActions from '../../../../actions/PointReportActions'
import { connect } from 'react-redux'

const PointChart = props => {
	const { dispatch, targetPoint, PointReport } = props
	const [dataForChart, setDataForChart] = useState([])

	useEffect(() => {
		const currentMonth = getCurrentMonth()
		dispatch(PointReportActions.getChartData({ monthTime: currentMonth }))
	}, [])

	useEffect(() => {
		const result = generateDataForChart(PointReport.chartData.value, +targetPoint)
		setDataForChart(result)
	}, [PointReport.chartData.value, PointReport.targetPoint.value])

	const ticks = getTicks()

	return (
		<Container>
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					width='100%'
					height='100%'
					data={dataForChart}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="1 1 1 1" horizontal={false} />
					<XAxis
						type="category"
						dataKey={POINT_REPORT.DATAKEY.DAY}
						ticks={ticks}
						interval={0}
						scale={POINT_REPORT.DATAKEY.DAY}
					/>
					<YAxis
						width={48}
						yAxisId="right"
						orientation="right"
						tickCount={11}
						domain={[0, dataMax => getMaxYAxis(dataMax)]}
						tickFormatter={tickItem => tickItem.toLocaleString()}
					/>
					<Tooltip />
					<Legend
						align='left'
						iconType='square'
					/>
					<Line
						name="目標ポイント"
						yAxisId="right"
						type="monotone"
						dataKey={POINT_REPORT.DATAKEY.TARGET_POINT}
						stroke={colors.pastelPink}
						dot={false}
						activeDot={false}
					/>
					<Line
						name="獲得ポイント"
						yAxisId="right"
						type="monotone"
						dataKey={POINT_REPORT.DATAKEY.ACTUAL_POINT}
						stroke={colors.pearlAqua}
						activeDot={false}
					/>
				</LineChart>
			</ResponsiveContainer>
		</Container>
	)
}



const mapStateToProps = (state) => {
	return {
		PointReport: state.PointReport
	}
}

export default connect(mapStateToProps)(PointChart)