import { useState } from 'react'

export default function useSound() {
	// eslint-disable-next-line no-unused-vars
	const [sound, setSound] = useState(null)

	const createSound = (soundConfig) => {
		const soundPath = soundConfig.soundPath || ''
		const soundName = soundConfig.soundName || 'defaultSound'
		const attributes = soundConfig.attributes || {}

		const soundEffect = new Audio(soundPath)

		if (Object.keys(attributes).length > 0) {
			Object.keys(attributes).map((key) => {
				soundEffect[key] = attributes[key]
			})
		}

		setSound(soundEffect)
		window[soundName] = soundEffect
	}

	const getSound = name => window[name]

	const playSound = (name) => {
		// if(sound) {
		//     sound.autoplay = true
		//     // onClick of first interaction on page before I need the sounds
		//     // (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
		//     sound.src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

		//     // later on when you actually want to play a sound at any point without user interaction
		//     sound.src = soundConfig.soundPath;

		//     sound.play();
		// }
		if (window[name]) {
			window[name].autoplay = true
			// onClick of first interaction on page before I need the sounds
			// (This is a tiny MP3 file that is silent and extremely short - retrieved from https://bigsoundbank.com and then modified)
			// window[name].src = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

			// // later on when you actually want to play a window[name] at any point without user interaction
			// window[name].src = soundConfig.soundPath

			window[name].muted = false


			window[name].play()
		}
	}

	const stopSound = (name) => {

		if (window[name]) {
			window[name].pause()
		}
		// if(sound) {
		//     sound.pause();
		//     console.log('%c [STELLA] sound.pause(): ', 'color: red' , sound.pause());

		// }
	}

	return {
		createSound,
		getSound,
		playSound,
		stopSound
	}
}
