import React, { Component } from 'react'

import moment from 'moment'
import {
	QuestionCategory,
	glasConfig,
} from './../../constants/Config'
import Dialog from 'material-ui/Dialog'
import request from 'axios'
import { Link } from 'react-router-dom'
import DialogAutoBestAnswer from './Dialog/DialogAutoBestAnswer'
import DialogManualBestAnswer from './Dialog/DialogManualBestAnswer'

class AnswerItemLogWeb extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showPopUp: false,
			showPopUpManual: false,
		}
	}

	getQuestionCategory(category) {
		let textCategory = ''
		switch (category) {
			case QuestionCategory.MARRIAGE_OR_DIVORCE: {
				textCategory = '結婚/離婚'
				break
			}
			case QuestionCategory.MARRIAGE: {
				textCategory = '縁結び'
				break
			}
			case QuestionCategory.DIVORCE: {
				textCategory = '縁切り'
				break
			}
			case QuestionCategory.REPUDIATION: {
				textCategory = '復縁'
				break
			}
			case QuestionCategory.INFIDELITY: {
				textCategory = '不倫'
				break
			}
			case QuestionCategory.LOVE: {
				textCategory = '恋愛 '
				break
			}
			case QuestionCategory.WORK: {
				textCategory = ' 仕事'
				break
			}
			case QuestionCategory.INTERPERSONAL: {
				textCategory = '対人関係'
				break
			}
			case QuestionCategory.FORTUNE: {
				textCategory = '運勢'
				break
			}
			case QuestionCategory.LUCKY_MONEY: {
				textCategory = '金運'
				break
			}
			case QuestionCategory.METAL: {
				textCategory = 'メンタル'
				break
			}
			case QuestionCategory.OTHER: {
				textCategory = 'その他'
				break
			}
		}

		return textCategory
	}

	showPopUp = () => {
		this.setState({ showPopUp: true })
		this.getPoint()
	}

	showPopUpManual = () => {
		this.setState({ showPopUpManual: true })
		this.getPoint()
	}

	closePopUp = () => {
		this.setState({ 
			showPopUp: false, 
			showPopUpManual: false
		})
	}

	getPoint() {
		const token = localStorage.getItem('token')
		request
			.get(
				glasConfig.url_base +
					glasConfig.path_answer +
					`reward?questionId=${this.props.item.questionId}`,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.props.item.isGetReward = true
				}
			})
	}

	render() {
		const { item } = this.props
		const questionCategory = this.getQuestionCategory(item.category)
		const questionCreateTime = new Date(item.createTime)
		const questionCreateTimeFormated =
			moment(questionCreateTime).format('YYYY/MM/DD')
		const hasBreakLine = item.content.search(/\n/) > 0
		const isLongContent = item.content.split(/\r\n|\r|\n/).length > 2

		return (
			<div className="best-answer-item">
				<div className="best-answer-item-left" style={{ width: !item.hasBestAnswer ? '100%' : '80%' }}>
					<Link
						to={`/home/answer/detail/${this.props.item.questionId}`}
						style={{ textDecoration: 'none' }}
					>
						<div className="answer-item-top">
							<div className="answer-item-category">
								{questionCategory}
							</div>
							<div
								className="answer-item-status hidden-sp"
								style={{ color: 'rgba(167, 142, 218, 1)' }}
							>
								{item.deadLineToAnswer <= 0 ? '受付終了' : ''}
							</div>
						</div>
						{hasBreakLine ? (
							<div
								className={
									isLongContent
										? 'answer-item-long-content'
										: 'answer-item-short-content'
								}
							>
								<pre>{item.content}</pre>
							</div>
						) : (
							<div className="answer-item-content">
								{item.content}
							</div>
						)}
						<div className="answer-item-bottom">
							<div className="answer-item-username">
								{item.nickName}さん {item.age}歳
							</div>
							<div className="answer-item-status-profile">
								<i
									className={
										item.isShowMyProfile
											? 'material-icons btn__icons icon-active'
											: 'material-icons btn__icons'
									}
								>
									person
								</i>
							</div>
							<div className="answer-item-status-time hidden-mobile">
								{questionCreateTimeFormated}
							</div>
							<div className="answer-item-status-amount-answer">
                                <img src='/img/icon_comment.png' className="img-icon_commnet"></img>
								<span>{item.totalAnswer}</span>
							</div>
						</div>
						<div className="answer-item-status-time hidden-pc">
							{questionCreateTimeFormated}
						</div>
					</Link>
				</div>
                {item.hasBestAnswer && 
                    <div className="best-answer-item-right">
                        <button
                            className={
                                !item.isGetReward
                                    ? 'getPointBestAnswerActive'
                                    : 'getPointBestAnswerInActive'
                            }
                            onClick={!item.isGetReward ? item.isManualBestAnswer ? this.showPopUpManual : this.showPopUp : undefined}
                        >
                            {item.isGetReward ? '報酬受取済' : '報酬受取'}
                        </button>
                    </div>
                }

				<DialogManualBestAnswer showPopUp={this.state.showPopUpManual} 
					closePopUp={this.closePopUp}
					item={item}
					userId={this.props.item.userId} />
				<DialogAutoBestAnswer showPopUp={this.state.showPopUp} 
					closePopUp={this.closePopUp} 
					item={item}  />
			</div>
		)
	}
}

export default AnswerItemLogWeb
