import React, { Component } from 'react'
import SettingMenu from './SettingMenu'
import RewardMenu from './RewardMenu'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import request from 'axios'
import { glasConfig } from '../constants/Config'
import * as MyBankActions from '../actions/MyBankActions'

class BankAccount extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bankName: '',
			branchName: '',
			branchCode: '',
			accountNumber: '',
			accountHolder: '',
			hidden: true,
			redirect: false,
		}
	}

	componentWillMount() {
		request
			.get(glasConfig.url_outside + glasConfig.path_bank_account, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					if (
						response.data.data.accountHolder &&
						response.data.data.accountNumber &&
						response.data.data.bankcode &&
						response.data.data.sitencode
					) {
						request
							.get(
								glasConfig.url_outside +
								glasConfig.path_bank +
								'/' +
								response.data.data.bankcode
							)
							.then((res) => {
								if (response.data.code === 0) {
									if (res.data.data && res.data.data.length) {
										this.setState({
											bankName:
												res.data.data[0].bank_name,
										})
									}
								}
							})
						request
							.get(
								glasConfig.url_outside +
								glasConfig.path_bank +
								'/' +
								response.data.data.bankcode +
								glasConfig.path_bank_branch +
								'/' +
								response.data.data.sitencode
							)
							.then((res) => {
								if (response.data.code === 0) {
									if (res.data.data && res.data.data.length) {
										this.setState({
											branchName:
												res.data.data[0].siten_name,
										})
									}
								}
							})

						this.setState({
							hidden: false,
							accountNumber: response.data.data.accountNumber,
							accountHolder: response.data.data.accountHolder,
							branchCode: response.data.data.sitencode,
						})
					} else {
						this.setState({
							redirect: true,
						})
					}
				} else {
					console.log(response.data.message)
				}
			})
			.catch((error) => {
				console.log('error on call ', error)
			})
	}

	changeBankAccount() {
		this.setState(
			{
				redirect: true,
			},
			() => {
				const { dispatch } = this.props
				dispatch(MyBankActions.accountEdit({ regist: false }))
			}
		)
	}

	render() {
		if (this.state.redirect) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -2)
						.join('/')}/bank/list`}
				/>
			)
		}
		return (
			<div className="content bank-detail bank-account">
				<h1 className="content__title">設定</h1>
				{/* <SettingMenu /> */}
				<RewardMenu />
				<div
					className="primary"
					style={this.state.hidden ? style.hidden : {}}
				>
					<div className="primary-title">
						<h1 className="primary-title__main">銀行口座情報</h1>
					</div>
					<div className="primary__inner">
						<div className="primary-content">
							<div className="primary-content__body">
								<div className="bank-box margin_bottom">
									<table className="table-th-left">
										<tbody>
											<tr>
												<th className="table-align-left">
													銀行名
												</th>
												<td className="table-align-left">
													{this.state.bankName}
												</td>
											</tr>
											<tr>
												<th className="table-align-left">
													支店名
												</th>
												<td className="table-align-left">
													{this.state.branchName}
													(支店コード :{' '}
													{this.state.branchCode})
												</td>
											</tr>
											<tr>
												<th className="table-align-left">
													口座番号
												</th>
												<td className="table-align-left">
													{this.state.accountNumber}
												</td>
											</tr>
											<tr>
												<th className="table-align-left">
													口座名義
												</th>
												<td className="table-align-left">
													{this.state.accountHolder}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<input
									type="button"
									className="btn-raised color_default"
									value="変更"
									onClick={() => this.changeBankAccount()}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const style = {
	hidden: {
		display: 'none',
	},
}

const mapStateToProps = (state) => {
	return { Bank: state.Bank }
}

export default connect(mapStateToProps)(BankAccount)
