import React, { useEffect, useRef, useState } from 'react';
import { glasConfig } from '../../constants/Config';
import DialogGetPoint from '../homeTellerIntroductionTeller/DialogGetPoint';
import IntroductionMenu from './IntroductionMenu';
import './styleIntroduction.css'
import request from 'axios'
import { copyToClipboard, isMobile } from '../../util/StellaConstant';
import iconMail from '../../assets/icon_mail.png'
import iconLine from '../../assets/icon_LINE.png'
import iconTwitter from '../../assets/icon_twitter.png'
import iconFacebook from '../../assets/icon_facebook.png'
import Snackbar from 'material-ui/Snackbar'
import * as Fetch from '../../util/Fetch'

Number.prototype.formatCurrency = function (n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
}

const URL_ONELINK_SMART_SCRIPT = glasConfig.url_one_link_appflyer
export const IntroductionCustomer = (props) => {
    const { isIntroduced } = props
    const [listAchievement, setListAcievement] = useState([])
    const [numberPoint, setNumberPoint] = useState(0)
    const [linkInvite, setLinkInvite] = useState('')
    const [showPopUp, setShowPopup] = useState(null)
    const tellerId = Fetch.tellerId()
    let urlIntro = `${glasConfig.urlIntro}${tellerId}`
    let contentIntro = `占いアプリStellaなら、チャットと電話でいつでもご相談可能です。\nここからアプリをインストールして登録すると、初回登録の無料ポイントがもらえます！\n`
    const dialogGetPoint = useRef(null)

    const StepIntroduction = ({ title, content, active }) => (
        <div className="item-step-in">
            <div className="btn-step-in">
                <p>{title}</p>
            </div>
            <div style={{ flex: 1 }}>
                <p
                    className={`step-content-in ${active && 'step-content-in-active'
                        }`}
                >
                    {content}
                </p>
            </div>
        </div>
    )
    const getListAchievement = () => {
        const token = localStorage.getItem('token')
        return request
            .get(
                glasConfig.url_base +
                glasConfig.get_list_reward_introduce_customer,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            )
            .then((response) => {
                if (response.data.code === 0) {
                    const data = response.data.data || []
                    setListAcievement(data)
                }
            })
            .catch((error) => {
                throw error
            })
    }
    const getPointSetting = () => {
        const token = localStorage.getItem('token')
        return request
            .get(
                glasConfig.url_base + glasConfig.get_setting_point_introduce_customer,
                {
                    headers: { Authorization: token },
                }
            )
            .then((response) => {
                if (response.data.code === 0) {
                    const { point } = response.data.data
                    setNumberPoint(point)
                    window.LineIt.loadButton()
                }
            })
            .catch((error) => {
                throw error
            })
    }

    useEffect(() => {
        getPointSetting()
        getListAchievement()
    }, [])

    const getPoint = (rewardId) => {
        dialogGetPoint.current.show({ rewardId, numberPoint, isIntroduceCustomer: true })
    }

    useEffect(() => {
        const initializeAppFlyer = () => {
            const oneLinkURL = URL_ONELINK_SMART_SCRIPT
            const params = `?deep_link_sub1=${tellerId}`
            setLinkInvite(oneLinkURL + params)
        };
        initializeAppFlyer()

    }, []);
    return (
        <div className="content">
            <DialogGetPoint
                ref={dialogGetPoint}
                onClose={getListAchievement}
            />
            <h1 className="content__title hidden-sp">紹介</h1>
            <IntroductionMenu isIntroduced={isIntroduced} />
            <div className="primary">
                <div className="primary-title hidden-sp">
                    <p
                        style={{
                            fontFamily: 'HiraKakuPro-W3',
                            fontSize: '20px',
                        }}
                    >
                        お客様を紹介する
                    </p>
                </div>
                <div className="primary-body-in frame-table">
                    {listAchievement[0] && (
                        <button
                            class="btn-raised color_default btnGetPointIntro"
                            onClick={() => getPoint(
                                listAchievement[0].rewardId
                            )}
                        >
                            {'紹介ボーナスを受け取る'}
                        </button>
                    )}

                    <h4
                        style={{
                            padding: '20px 0',
                            fontWeight: 'bold',
                            fontFamily: 'HiraKakuPro-W6',
                        }}
                    >
                        <span>
                            お客様を紹介して
                        </span>
                        <span style={{ color: 'rgba(236, 64, 122, 1)' }}>
                            {numberPoint.formatCurrency()}ポイントGET
                        </span>
                        <span>!</span>
                    </h4>
                    <div>
                        <p
                            style={{
                                marginBottom: 6,
                                fontSize: '15px',
                                fontWeight: 'bold',
                            }}
                        >
                            〜紹介の流れ〜
                        </p>

                        <div style={{ display: 'inline-block' }}>
                            <StepIntroduction
                                title="STEP1"
                                content="下記のいずれかの方法でステラを紹介！"
                                active={false}
                            />
                            <StepIntroduction
                                title="STEP2"
                                content="紹介した方が紹介URLからステラに登録"
                                active={false}
                            />
                            <StepIntroduction
                                title="STEP3"
                                content={`あなたに${numberPoint.formatCurrency()}ポイント×何人でもOK！`}
                                active={true}
                            />
                        </div>
                        <p
                            style={{
                                fontFamily: 'Hiragino Kaku Gothic Pro',
                                fontSize: 12,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            ※初めての登録のみに限ります。過去に登録していたことがある場合は対象となりません。
                        </p>
                        <p
                            style={{
                                fontFamily: 'Hiragino Kaku Gothic Pro',
                                fontSize: 12,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            ※以下の行為を行われた場合、紹介ボーナスは没収となります。
                        </p>
                        <p
                            style={{
                                fontFamily: 'Hiragino Kaku Gothic Pro',
                                fontSize: 12,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            ・ご自身がアカウントを作成しポイントを移す・紹介ポイントを獲得するなどの行為
                        </p>
                        <p
                            style={{
                                fontFamily: 'Hiragino Kaku Gothic Pro',
                                fontSize: 12,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            ・ユーザーを騙す形でポイントを獲得する行為
                        </p>
                        <p
                            style={{
                                fontFamily: 'Hiragino Kaku Gothic Pro',
                                fontSize: 12,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        >
                            ・その他事務局が不正と判断したもの
                        </p>
                    </div>
                    <div style={{ paddingTop: 20 }}>
                        <p
                            className="list-social"
                            style={{ marginBottom: 20, fontWeight: 'bold' }}
                        >
                            ▼紹介に使用したい方法をクリック！
                        </p>

                        <div style={{ display: 'flex' }}>
                            <div style={{ marginRight: 20 }}>
                                {isMobile ? (
                                    <div className="icon-line-mb">
                                        <div style={{ opacity: 0 }}>
                                            <div
                                                className="line-it-button"
                                                data-lang="en"
                                                data-type="share-b"
                                                data-ver="3"
                                                data-url={
                                                    contentIntro + linkInvite
                                                }
                                                data-color="default"
                                                data-size="large"
                                                data-count="false"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <a
                                        href={`https://social-plugins.line.me/lineit/share?url=${linkInvite}&text=${contentIntro}&from=line_scheme`}
                                        target="_blank"
                                    >
                                        <img
                                            alt={''}
                                            src={iconLine}
                                            style={{
                                                width: 40,
                                                height: 40,
                                            }}
                                        />
                                    </a>
                                )}
                            </div>

                            <div
                                style={{
                                    marginRight: 20,
                                    cursor: 'pointer',
                                }}
                            >
                                <img
                                    alt={''}
                                    onClick={() => {
                                        window.FB.ui(
                                            {
                                                method: 'share',
                                                href: linkInvite,
                                                quote: contentIntro,
                                            },
                                            function (response) {
                                            }
                                        )
                                    }}
                                    src={iconFacebook}
                                    style={{ width: 40, height: 40 }}
                                />
                            </div>

                            <div style={{ marginRight: 20 }}>
                                <a
                                    target="_blank"
                                    href={`https://twitter.com/intent/tweet?text=${contentIntro + linkInvite}`}
                                >
                                    <img
                                        alt={''}
                                        src={iconTwitter}
                                        style={{ width: 40, height: 40 }}
                                    />
                                </a>
                            </div>

                            <div style={{ marginRight: 20 }}>
                                <a
                                    target="_blank"
                                    href={`mailto:?body=${encodeURIComponent(contentIntro.concat(linkInvite))}`}
                                >
                                    <img
                                        alt={''}
                                        src={iconMail}
                                        style={{ width: 40, height: 40 }}
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* messenger facebook
                        <a href={`fb-messenger://share/?link=${urlIntro}&title=${contentIntro}`}>Send In Messenger</a> */}

                    <div className="c-input-in">
                        <input
                            disabled
                            placeholder="https://"
                            value={linkInvite}
                        />
                        <button
                            class="btn-raised color_default"
                            onClick={() => {
                                setShowPopup(true)
                                copyToClipboard(linkInvite)
                                setTimeout(() => {
                                    setShowPopup(false)
                                }, 700);
                            }}
                        >
                            コピー
                        </button>
                    </div>
                    <Snackbar
                        open={showPopUp}
                        message={'コピーしました'}
                        autoHideDuration={1500}
                    />
                </div>
                {/* <div className="customer-list-sp display-mobile">{getSupportHistorySP()}</div> */}
            </div>
        </div>
    )
}