import moment from "moment"

export const colors = {
	lightBlack: 'rgba(0, 0, 0, 0.1)',
	black: 'rgba(0, 0, 0, 0.87)',
	white: 'rgba(255, 255, 255, 1)',
	ghostWhite: 'rgba(249, 249, 249, 1)',
	pearlAqua: 'rgba(17, 173, 157, 1)',
	mediumBlue: 'rgba(0, 0, 0, 0.87)',
	darkJungleGreen: 'rgba(33, 33, 33, 1)',
	accent: 'rgba(236, 64, 122, 1)',
	pastelGray: 'rgba(203, 203, 203, 1)',
	oxfordBlue: 'rgba(0, 0, 0, 0.25)',
	blackGray: 'rgba(77, 75, 81, 1)',
	oldLavender: 'rgba(117, 117, 117, 1)',
	timberWorf: 'rgba(216, 216, 216, 1)',
	pasterPurple: 'rgba(174, 169, 185, 1)',
	pasterRed: 'rgba(255, 102, 102, 1)',
	taupeGray: 'rgba(139, 132, 152, 1)',
	mintCream: 'rgba(246, 255, 254, 1)',
	manatee: 'rgba(158, 158, 158, 1)',
	azureMist: 'rgba(241, 255, 253, 1)',
	snow: 'rgba(255, 245, 248, 1)',
	pinkPearl: 'rgba(226, 182, 197, 1)',
	dimGray: 'rgba(111, 111, 111, 1)',
	onyx: 'rgba(0, 0, 0, 0.12)',
	gainsboro: 'rgba(217, 217, 217, 1)',
	pastelPink: 'rgba(255, 204, 221, 1)',
	munsell: 'rgba(243, 243, 243, 1)',
	taupe: 'rgba(56, 53, 60, 0.87)',
	brandeisBlue: 'rgba(0, 88, 255, 0.86)',
	red: 'rgba(255, 0, 0, 0.87)',
	primaryText: 'rgba(0, 0, 0, 1)'
}

export const fonts = {
	HiraKakuProW3: 'HiraKakuPro-W3',
	HiraKakuProW6: 'HiraKakuPro-W6',
	HiraginoKakuGothicPro: 'Hiragino Kaku Gothic Pro',
}

const SIZE = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1280px',
	desktop: '2560px',
}

export const DEVICE = {
	mobileS: `(min-width: ${SIZE.mobileS})`,
	mobileM: `(min-width: ${SIZE.mobileM})`,
	mobileL: `(min-width: ${SIZE.mobileL})`,
	tablet: `(min-width: ${SIZE.tablet})`,
	laptop: `(min-width: ${SIZE.laptop})`,
	laptopL: `(min-width: ${SIZE.laptopL})`,
	desktop: `(min-width: ${SIZE.desktop})`,
	desktopL: `(min-width: ${SIZE.desktop})`,
}

export const LAPTOP_SCREEN = `@media ${DEVICE.laptop}`
export const TABLET_SCREEN = `@media ${DEVICE.tablet}`
export const MOBILE_SCREEN = `@media ${DEVICE.mobileS}`

export const CONFIRM_PAYOFF_BUTTON = {
	ACCEPT: 'ACCEPT',
	CANCEL: 'CANCEL'
}


export const isMobile = (() => {
	if (
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/webOS/i) ||
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPad/i) ||
		navigator.userAgent.match(/iPod/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i)
	)
		return true
	return false
})()

export function copyToClipboard(textToCopy) {
	var textArea

	function isOS() {
		//can use a better detection logic here
		return navigator.userAgent.match(/ipad|iphone/i)
	}

	function createTextArea(text) {
		textArea = document.createElement('textArea')
		textArea.readOnly = true
		textArea.contentEditable = true
		textArea.value = text
		document.body.appendChild(textArea)
	}

	function selectText() {
		var range, selection

		if (isOS()) {
			range = document.createRange()
			range.selectNodeContents(textArea)
			selection = window.getSelection()
			selection.removeAllRanges()
			selection.addRange(range)
			textArea.setSelectionRange(0, 999999)
		} else {
			textArea.select()
		}
	}

	function copyTo() {
		document.execCommand('copy')
		document.body.removeChild(textArea)
	}

	createTextArea(textToCopy)
	selectText()
	copyTo()
}

export function formatSeconds(seconds) {
    const duration = moment.duration(seconds, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const secs = duration.seconds();

    // Định dạng chuỗi thành HH:mm:ss
    const formatted = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    return formatted;
}

export const isInt = (number) => Number(number) === number && number % 1 === 0


export const formatPoint = (value) => {
    let result = ''
    if (!value) {
        result = "0"
    } else {
        result = isInt(+value) ? parseInt(value.toFixed(2).toString()).toLocaleString() : (+value.toFixed(2)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        })
    }
    return result
}