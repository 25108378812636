/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import { colors, DEVICE } from '../../../util/StellaConstant'
import { POINT_REPORT, setLimitForTargetPoint } from '../../../constants/PointReportConstants'
import {
	Button,
	ButtonArea,
	Content,
	Ruler,
	Title,
	CheckboxArea
} from '../StyledPointReport/Dialogs/StyledDialogSetTargetPoint'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useReducer } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import InputSetPoint from '../Frames/Dialogs/InputSetPoint'
import * as PointReportActions from '../../../actions/PointReportActions'
import { connect } from 'react-redux'

const reducer = (state, newState) => {
	return { ...state, ...newState }
}

const CHECKBOXES = [
	{
		name: POINT_REPORT.TYPE_OF_CHECKBOXES.SAME_AS_PREVIOUS_MONTH,
		multiplier: 1,
		label: '前月の獲得ポイント'
	},
	{
		name: POINT_REPORT.TYPE_OF_CHECKBOXES.INCREASE_10_PERCENT,
		multiplier: 1.1,
		label: '前月の獲得ポイントから1.1倍'
	},
	{
		name: POINT_REPORT.TYPE_OF_CHECKBOXES.INCREASE_20_PERCENT,
		multiplier: 1.2,
		label: '前月の獲得ポイントから1.2倍'
	},
	{
		name: POINT_REPORT.TYPE_OF_CHECKBOXES.OPTIONAL_NUMBER,
		multiplier: 2,
		label: <InputSetPoint />
	},
]

const initialState = {
	[POINT_REPORT.TYPE_OF_CHECKBOXES.SAME_AS_PREVIOUS_MONTH]: { id: 0, checked: false, value: null },
	[POINT_REPORT.TYPE_OF_CHECKBOXES.INCREASE_10_PERCENT]: { id: 1, checked: false, value: null },
	[POINT_REPORT.TYPE_OF_CHECKBOXES.INCREASE_20_PERCENT]: { id: 2, checked: false, value: null },
	[POINT_REPORT.TYPE_OF_CHECKBOXES.OPTIONAL_NUMBER]: { id: 3, checked: false, value: null },
}

const DialogSetTargetPoint = (props) => {
	const { dispatch, show, closeSetTargetPoint, targetPoint, setTargetPoint, tellerId, pointEarnedLastMonth, PointReportData } = props
	const classes = useStyles()
	const inputOptionalPoint = useRef(null)

	const [open, setOpen] = useState(false)
	const [checkboxes, setCheckboxes] = useReducer(reducer, initialState)

	useEffect(() => {
		setOpen(show)
	}, [show])

	useEffect(() => {
		if(PointReportData.targetPoint.isLoaded) {
			setDefaultTargetPoint()
		}
	}, [PointReportData.targetPoint.isLoaded])

	const setDefaultTargetPoint = () => {
		const intOption = PointReportData.targetPoint.value.option
		const defaultPoint = PointReportData.targetPoint.value.targetPoint
		setCheckboxes({
			...initialState, 
			[POINT_REPORT.TYPE_OF_CHECKBOXES[POINT_REPORT.TARGET_POINT_OPTION[intOption]]]: {
				checked: true,
				value: defaultPoint,
				id: intOption
			}
		})
	}

	const setTargetPointSameAsPrevMonth = () => {
		setCheckboxes({
			...initialState,
			[POINT_REPORT.TYPE_OF_CHECKBOXES.SAME_AS_PREVIOUS_MONTH]: {
				checked: true,
				value: pointEarnedLastMonth,
				id: 0
			}
		})
	}

	const closeDialog = () => {
		setOpen(false)
		closeSetTargetPoint()
	}

	const onSubmit = () => {
		let targetPoint = null
		const activeCheckboxKey = Object.keys(checkboxes).find(key => checkboxes[key].checked === true)
		const activeCheckboxValue = Object.values(checkboxes).find(checkbox => checkbox.checked === true).value
		const activeCheckboxId = Object.values(checkboxes).find(checkbox => checkbox.checked === true).id

		if(activeCheckboxKey === POINT_REPORT.TYPE_OF_CHECKBOXES.OPTIONAL_NUMBER) {
			const inputValue = inputOptionalPoint.current.value
			if(inputValue !== '') {
				if(+inputValue > 10000000) {
					targetPoint = 10000000
				} else {
					targetPoint = inputOptionalPoint.current.value
				}
				dispatch(PointReportActions.setTargetPoint({
					tellerId,
					targetPoint: +targetPoint,
					option: POINT_REPORT.TARGET_POINT_OPTION_INDEX['OPTIONAL_NUMBER']
				}))
			} else {
				setTargetPointSameAsPrevMonth()
				targetPoint = setLimitForTargetPoint(pointEarnedLastMonth)
				dispatch(PointReportActions.setTargetPoint({
					tellerId,
					targetPoint: setLimitForTargetPoint(+targetPoint),
					option: POINT_REPORT.TARGET_POINT_OPTION_INDEX['SAME_AS_PREVIOUS_MONTH']
				}))
			}
		} else {
			targetPoint = setLimitForTargetPoint(activeCheckboxValue)

			dispatch(PointReportActions.setTargetPoint({
				tellerId,
				targetPoint: setLimitForTargetPoint(+targetPoint),
				option: activeCheckboxId
			}))
		}
		setTargetPoint(targetPoint)

		setTimeout(() => {
			closeDialog()
		}, 1000)
	}
	
	const onCheck = event => {
		const checkboxType = event.target.name
		const pointLastMonth = setLimitForTargetPoint(pointEarnedLastMonth)
		if(checkboxes[checkboxType].checked !== true) {
			setCheckboxes({
				...initialState, 
				[checkboxType]: {
					...checkboxes[checkboxType],
					checked: !checkboxes[checkboxType].checked,
					value: pointLastMonth * CHECKBOXES.find(checkbox => checkbox.name === checkboxType).multiplier,
				}
			})
		}
	}

	return (
		<Dialog
			open={open}
			classes={{ paper: classes.paper }}
		>
			<IconButton
				aria-label="close"
				onClick={closeDialog}
				sx={{
					position: 'absolute',
					right: 0,
					top: 0,
					color: colors.oldLavender,
				}}
			>
				<CloseIcon />
			</IconButton>
			<Title>目標設定</Title>
			<Ruler />
			<Content>設定はいつでも変更することができます。</Content>
			<CheckboxArea>
				{
					CHECKBOXES.map((checkbox, index) => {
						const isNotOptinalNumber = checkbox.name !== POINT_REPORT.TYPE_OF_CHECKBOXES.OPTIONAL_NUMBER
						return (
							<FormControlLabel
								control={
									<Checkbox
										checked={checkboxes[checkbox.name].checked}
										onChange={onCheck}
										name={checkbox.name}
										sx={{
											'&.Mui-checked': {
												color: colors.pearlAqua,
											},
										}}
									/>
								}
								label={
									isNotOptinalNumber
										? checkbox.label
										: <InputSetPoint
											checked={checkboxes[checkbox.name].checked}
											inputRef={inputOptionalPoint}
											targetPoint={targetPoint}
										/>
								}
							/>
						)
					})
				}

			</CheckboxArea>
			<ButtonArea>
				<Button buttonType="ACCEPT" onClick={onSubmit}>
					目標設定する
				</Button>
			</ButtonArea>
		</Dialog>
	)
}

const useStyles = makeStyles({
	paper: {
		[`@media ${DEVICE.mobileS}`]: {
			width: '90%',
			maxWidth: 288,
			padding: '28px 22px 26px 19px'
		},
		[`@media ${DEVICE.laptop}`]: {
			width: '45.9%',
			maxWidth: 470,
			padding: '45px 24px 35px 29px'
		},
		[`@media ${DEVICE.laptopL}`]: {
			width: '41.3%',
			maxWidth: 530,
			padding: '37px 28px 36px 37px'
		},
	},
})

const mapStateToProps = (state) => {
	return {
		PointReportData: state.PointReport
	}
}

export default connect(mapStateToProps)(DialogSetTargetPoint)