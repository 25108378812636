import styled from 'styled-components'
import { colors, fonts, DEVICE } from '../../../util/StellaConstant'

/*
    @media ${DEVICE.mobileS} {

    }
    @media ${DEVICE.tablet} {

    }
    @media ${DEVICE.laptop} {

    }
*/

export const Title = styled.p`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 17px;
		color: ${colors.mediumBlue};
		line-height: 21px;
		margin-bottom: 16px;
	}
	@media ${DEVICE.tablet} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 24px;
		color: ${colors.mediumBlue};
		line-height: 21px;
		margin-bottom: 16px;
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 17px;
		color: ${colors.mediumBlue};
		line-height: 21px;
		margin-bottom: 16px;
	}
`

export const Content = styled.p`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 17px;
		color: ${colors.mediumBlue};
		line-height: 21px;
		margin-bottom: 14px;
	}
	@media ${DEVICE.tablet} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 20px;
		color: ${colors.mediumBlue};
		margin-bottom: 14px;
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 17px;
		color: ${colors.mediumBlue};
		margin-bottom: 14px;
	}
`

export const ButtonArea = styled.div`
	@media ${DEVICE.mobileS} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media ${DEVICE.tablet} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media ${DEVICE.laptop} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

export const Button = styled.button`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 18px;
		color: ${(props) =>
		props.buttonType === 'ACCEPT' ? colors.white : colors.mediumBlue};
		border: none;
		outline: none;
		background-color: ${(props) =>
		props.buttonType === 'ACCEPT'
			? colors.pearlAqua
			: colors.ghostWhite};
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
			0 0 2px 0 rgba(0, 0, 0, 0.12);
		width: 104px;
		padding: 8px 0 7px;
		margin-right: ${(props) =>
		props.buttonType === 'ACCEPT' ? '15px' : '0'};
	}
	@media ${DEVICE.tablet} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 18px;
		color: ${(props) =>
		props.buttonType === 'ACCEPT' ? colors.white : colors.mediumBlue};
		border: none;
		outline: none;
		background-color: ${(props) =>
		props.buttonType === 'ACCEPT'
			? colors.pearlAqua
			: colors.ghostWhite};
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
			0 0 2px 0 rgba(0, 0, 0, 0.12);
		width: 104px;
		padding: 8px 0 7px;
		margin-right: ${(props) =>
		props.buttonType === 'ACCEPT' ? '15px' : '0'};
	}
	@media ${DEVICE.laptop} {
		border: none;
		outline: none;
		background-color: ${(props) =>
		props.buttonType === 'ACCEPT'
			? colors.pearlAqua
			: colors.ghostWhite};
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
			0 0 2px 0 rgba(0, 0, 0, 0.12);
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 15px;
		color: ${(props) =>
		props.buttonType === 'ACCEPT' ? colors.white : colors.mediumBlue};
		width: 104px;
		padding: 8px 0 7px;
		margin-right: ${(props) =>
		props.buttonType === 'ACCEPT' ? '15px' : '0'};
	}
`
