import React from 'react'
import { isInt } from '../../../../constants/PointReportConstants'
import { Point } from '../../StyledPointReport/Calendar/StyledCustomCalendar'

const PointPerDay = ({ event }) => {
	const point = isInt(+event.title) ? parseInt(event.title).toLocaleString() : (+event.title).toLocaleString(undefined, {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})
	return (
		<>
			{
				event.title !== 0
					? <Point>{point}</Point>
					: <div></div>
			}
		</>
	)
}

export default PointPerDay