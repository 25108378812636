/**
 * websocket接続用
 */
import { DEVICE_TYPE_FOR_CALLING_SIP_SERVER, glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import io from 'socket.io-client'
import moment from 'moment'
import { isPWA } from '../helper/helpFunction'

export function connect() {
	// if(this.socket)
	//   return this.socket;

	const socket = io.connect(glasConfig.url_websocket)
	const tellerId = Fetch.tellerId()
	const deviceType = isPWA() ? DEVICE_TYPE_FOR_CALLING_SIP_SERVER : Fetch.fetchUserAgent()
	const message = {
		fromId: tellerId,
		deviceType,
		msgType: 'AUTHEN',
		value: localStorage.getItem('token'),
		time: moment.utc(420).format('YYYYMMDDhhmmss'),
		applicationVersion: '0.0',
	}

	// console.log('SOCKET CONNECTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT');

	socket.emit('authen', JSON.stringify(message))
	// socket.on('authen', (res) => {
	//   const message = JSON.parse(res)
	//   if (message.msgType !== "AUTHEN_SUCCESS"){
	//     console.log('認証失敗')
	//   }
	// })
	// this.socket = socket;
	return socket
}
