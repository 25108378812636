import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export default () => {
	const [isMobile, setIsMobile] = useState(true)
	useEffect(() => {
		setIsMobile(window.innerWidth <= 768);
		window.addEventListener("resize", resize);
		return () => window.removeEventListener("resize", resize);
	})
	const resize = () => {
		setIsMobile(window.innerWidth <= 768);
	}


	let pathName = window.location.pathname

	let reward = 'btn-tab' + (pathName === '/home/reward' ? ' is-active' : '')
	let daily =
		'btn-tab' +
		(pathName.indexOf('/home/reward/daily') !== -1 ? ' is-active' : '')
	let history =
		'btn-tab' +
		(pathName.indexOf('/home/reward/history') !== -1 ? ' is-active' : '')
	let RewardExplanation =
		'btn-tab' +
		(pathName.indexOf('/home/reward/RewardExplanation') !== -1
			? ' is-active'
			: '')
	let payoff =
		'btn-tab' +
		(pathName.indexOf('/home/reward/payoff') !== -1 ? ' is-active' : '')

	const bankRouteRegex = new RegExp('^\/home\/reward\/bank.+$');
	let bank = 'btn-tab' + (bankRouteRegex.exec(pathName) ? ' is-active' : '');

	return (
		<nav className="lead-nav">
			<ul className="list lead_nav scroll-menu-list-answer scroll-menu-list-reward">
				<li className="list__item">
					<Link to="/home/reward" className={reward}>
						報酬確認・精算
					</Link>
				</li>
				<li className="list__item">
					<Link to="/home/reward/daily" className={daily}>
						日別報酬
					</Link>
				</li>
				{
					isMobile ?
						<>
							<li className="list__item"><Link to='/home/reward/bank/top' className={bank} >銀行口座情報編集</Link></li>
							<li className="list__item"><Link to='/home/reward/history' className={history}>精算履歴</Link></li>
						</>
						:
						<>
							<li className="list__item"><Link to='/home/reward/history' className={history}>精算履歴</Link></li>
							<li className="list__item"><Link to='/home/reward/bank/top' className={bank} >銀行口座情報編集</Link></li>
						</>
				}
				<li className="list__item">
					<Link
						to="/home/reward/RewardExplanation"
						className={RewardExplanation}
					>
						報酬説明
					</Link>
				</li>
				<li className="list__item">
					<Link to="/home/reward/payoff" className={payoff}>
						支払証明書発行
					</Link>
				</li>
			</ul>
		</nav>
	)
}
