import styled from 'styled-components'
import { colors, fonts, DEVICE } from '../../../util/StellaConstant'

export const StyledCategoryList = styled.div`
	display: flex;
	padding: 0 16px 20px;

	@media ${DEVICE.mobileS} {
		flex-direction: column;
	}
	@media ${DEVICE.tablet} {
		flex-direction: column;
	}
	@media ${DEVICE.laptop} {
		flex-direction: row;
		flex-wrap: wrap;
	}
`

export const StyledCategoryItem = styled.div`
	padding: 4px 8px;
	background-color: rgba(0, 0, 0, 0.08);
	border-radius: 4px;
	font-size: 13px;
	// white-space: nowrap;
	width: fit-content;
	margin-bottom: 4px;
	margin-right: 4px;
	// overflow-x: scroll;

	a {
		color: rgba(0, 0, 0, 0.54);
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.2);
	}

	a:hover {
		text-decoration: none;
	}

	// @media ${DEVICE.mobileS} {
	//     max-width: calc((100vw - 20px - 64px - 58px)/2);
	// }
	// @media ${DEVICE.tablet} {
	//     max-width: 173px;
	// }
	// @media ${DEVICE.laptop} {
	//     max-width: 115px;
	//     // &::-webkit-scrollbar {
	//     //     display:none;
	//     // }
	// }
	// @media ${DEVICE.laptopL} {
	//     max-width: 219px;
	// }
`
