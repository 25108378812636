/**
 * 通話着信ダイアログ
 *
 */
import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { glasConfig } from '../constants/Config'
import moment from 'moment'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

class DialogCall extends Component {
	constructor(props) {
		super(props)
		/**
		 * show: true:ダイアログ表示
		 * time: カウントダウン表示秒数
		 */
		this.state = {
			show: false,
			time: 0,
		}
	}

	componentWillMount() {
		const { data } = this.props
		this.setState({
			show: this.isShow(data.endType),
			time: this.getCountDownStartTime(data),
		})
	}

	componentDidMount() {
		this.interval = setInterval(() => this.countDown(), 1000)
	}

	componentWillUnmount() {
		clearInterval(this.interval)
	}

	/**
	 * WSから送信されてくるendTypeによって表示・非表示を判定しダイアログを制御
	 * @param {*} nextProps
	 */
	componentWillReceiveProps(nextProps) {
		this.setState({
			show: this.isShow(nextProps.data.endType),
			time: this.getCountDownStartTime(nextProps.data),
		})
	}

	/**
	 * 着信、キャンセルなどによってダイアログの表示を判定する
	 * @return {boolean}
	 */
	isShow(type) {
		switch (type) {
			case 'INCALL': {
				return true
			}
			case 'CANCEL': {
				return false
			}
			case 'BUSY': {
				return false
			}
			case 'CLOSE': {
				return false
			}
			default: {
				return false
			}
		}
	}

	/**
	 * Webなので通話開始ボタンの表示はないけど、カウントダウン時間を取得しています
	 * @return {number} カウントダウン開始秒数
	 */
	getCountDownStartTime(data) {
		if (!data) {
			return 0
		}
		if (!this.isShow(data.endType)) {
			return 0
		}
		moment.locale('ja')
		if (!moment(data.serverTime, 'YYYYMMDDHHmmss').isValid()) {
			//申請時間が不正の場合
			return 0
		}
		const registTime = moment(data.serverTime, 'YYYYMMDDHHmmss').add(
			9,
			'hours'
		)
		const diff = moment().diff(registTime, 'seconds')
		if (diff >= glasConfig.chatrequest_limit) {
			//90秒以上経っていればダイアログでチャット開始ボタンは非表示
			return 0
		}
		return glasConfig.chatrequest_limit - diff
	}

	countDown() {
		const time = this.state.time - 1
		this.setState({
			time: time < 0 ? 0 : time,
		})
	}

	/**
	 * お客様情報を表示のクリックイベント
	 */
	handleUserInfo() {
		const { data } = this.props
		const userId = data.fromId
		this.handleClose()
		this.props.history.push(`/chat/${userId}`)
	}

	render() {
		const { closeFunc, data } = this.props

		if (!this.state.show) {
			return null
		}

		const handleClose = () => {
			closeFunc()
		}

		const handleUserInfo = () => {
			closeFunc()
			this.props.history.push(`/home/chat/${data.fromId}`)
			return null
		}

		let userName = data.userName ? data.userName : data.fromName
		if (userName === undefined) {
			userName = data.friendName
		}

		const actions = [
			<FlatButton
				label="お客様情報を表示"
				primary={false}
				keyboardFocused={true}
				onClick={() => handleUserInfo()}
			/>,
		]

		const customDialogStyle = {
			width: '405px',
		}

		const secStyle = {
			textAlign: 'center',
			color: 'red',
			padding: '20px 0',
			fontSize: '1.2em',
		}

		const styleCancel = {
			color: '#c6c6c6',
		}

		const captionStyle = {
			color: '#c6c6c6',
			fontSize: '0.7em',
		}

		return (
			<div>
				<Dialog
					title="通話相談申し込み"
					modal={false}
					actions={actions}
					contentStyle={customDialogStyle}
					open={this.state.show}
					onRequestClose={(e) => handleClose()}
				>
					<div>
						{userName}さんから着信があります。
						<br />
						{glasConfig.chatrequest_limit}
						秒以内に通話に応答してください
					</div>
					<div style={secStyle}>{this.state.time}秒</div>
					{/* <div>通話可能時間：------</div>
          <div>前回の通話日：------</div> */}
				</Dialog>
			</div>
		)
	}
}

export default withRouter(DialogCall)
