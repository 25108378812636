/**
 * チャット画面 右カラムのユーザー情報表示
 */
import React, { Component } from 'react'
import moment from 'moment'
import ChatUserMemo from './ChatUserMemo'
import ChatUserLogCall from './ChatUserLogCall'
import { glasConfig, Gender, TYPE_ENABLE_CLOSE_CHAT } from '../constants/Config'
import { connect } from 'react-redux'
import DialogBlock from '../components/DialogBlock'
import DialogFirstTimeChat from '../components/DialogFirstTimeChat'
import DialogOutChat from '../components/DialogOutChat'
import DialogCantBlock from '../components/DialogCantBlock'
import DialogUnBlock from '../components/DialogUnBlock'
import request from 'axios'
import $ from 'jquery'
import AnswerLogUser from './ChatContent/ChatLogRightMenu/AnswerLogUser'
import '../assets/scss/AnswerLogUser.scss'
import DetailAnswerList from './ChatContent/ChatLogRightMenu/DetailAnswerList'
import DetailAnswerPWA from './ChatContent/ChatLogRightMenu/DetailAnswerPWA'
import * as ChatRequestActions from '../actions/ChatRequestActions'
import { isPWA } from '../helper/helpFunction'
import ModalReportUser from './Modals/ModalReportUser'

class ChatUserInfo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			tab: 'memo',
			isResetMemo: false,
			firstTimeChatOnline: false,
			isFirstTimeUseChat: false,
			showDialogReportUser: false,
			tellerReportReasons: [],
			handleSuccessReport: null
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.UserInfo.userId !== undefined && this.props.UserInfo.userId !== prevProps.UserInfo.userId) {
			request
				.get(glasConfig.url_base + glasConfig.path_chat_history + `/checkfirstchat?userId=${this.props.UserInfo.userId}`, {
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				})
				.then((response) => {
					if (response.data.code === 0) {
						this.setState({ isFirstTimeUseChat: response.data.data.isFirstTimeUseChat })
					}
				})
				.catch((error) => {
					console.log('error', error)
					throw error
				})
		}

	}

	componentDidMount() {
		this.getReasonReport()
	}

	showTab(tab) {
		this.setState({
			tab: tab,
		})
	}

	updateResetMemo = () => {
		this.setState({
			isResetMemo: false,
		})
	}

	closeChatInfo = () => {
		$('.chat-box-customer').removeClass('show_chat-box-customer')
		$('.chat-karte-list-wrap').removeClass('hiddenClass')
		$('.chat-memo-history').removeClass('hiddenNotImportant')
		$('.chat-memo-history__nav').removeClass('hiddenClass')
		$('.chat-memo__fullscreen').removeClass('hiddenClass')
		$('body').removeClass('noScroll')
		this.setState({
			tab: 'memo',
			isResetMemo: true,
		})
	}

	switchTab = (event) => {
		event.stopPropagation()
	}

	showPopUpBlock = () => {
		this.dialogBlock.openDialog(() => this.acceptBlock())
	}

	showDialogReportUser = () => {
		const friendId = this.props.UserInfo.userId
		this.props.reload(friendId)
		this.setState({ showDialogReportUser: true })
	}

	showPopupOutChat = () => {
		this.dialogOutChat.openDialog()
	}



	acceptBlock() {
		let params = {}
		params.friendId = this.props.UserInfo.userId
		request
			.post(glasConfig.url_base + glasConfig.path_block, params, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					window.location.href = '/home/customermanagement/block'
				}

			})
			.catch((error) => {
				throw error
			})
	}

	showPopUpUnBlock = () => {
		this.dialogUnBlock.openDialog(() => this.acceptUnBlock())
	}

	acceptUnBlock() {
		const friendId = this.props.UserInfo.userId
		request
			.delete(
				glasConfig.url_base +
				glasConfig.path_block +
				`?friendId=${friendId}`,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.props.reload(friendId)
				}
				this.enableChatDraft(friendId)
			})
			.catch((error) => {
				throw error
			})
	}

	getReasonReport() {
		request
			.get(
				glasConfig.url_base +
				glasConfig.get_reason_report,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ tellerReportReasons: response.data.data.tellerReportReasons })
				}
			})
			.catch((error) => {
				throw error
			})
	}

	reportUser(reason) {
		let params = {}
		params.reportedId = this.props.UserInfo.userId
		params.reportReason = reason.value
		request
			.post(
				glasConfig.url_base +
				glasConfig.report_user,
				params,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ handleSuccessReport: true })
				} else {
					this.setState({ handleSuccessReport: false })
				}
			})
			.catch((error) => {
				this.setState({ handleSuccessReport: false })
				throw error
			})
	}


	enableChatDraft(userId) {
		const { dispatch } = this.props
		let data = {
			'userId': userId,
			'serverTime': moment().utc().format("YYYYMMDDHHmmss")
		}
		dispatch(ChatRequestActions.changeStatusChatDraft(data))
	}

	showPopUpCantBlock = () => {
		this.dialogCantBlock.openDialog()
	}

	render() {
		const {
			UserInfo,
			DetailAnswerChatLog,
			ChatRequestUnRead,
			ChatRequestRead,
			CallStoredData
		} = this.props
		let isShowBtnCloseChat = false
		if (
			(ChatRequestUnRead.data[UserInfo.userId] && TYPE_ENABLE_CLOSE_CHAT.indexOf(ChatRequestUnRead.data[UserInfo.userId].msgType) !== -1)
			|| (ChatRequestRead.data[UserInfo.userId] && TYPE_ENABLE_CLOSE_CHAT.indexOf(ChatRequestRead.data[UserInfo.userId].msgType) !== -1)
		) {
			isShowBtnCloseChat = true
		}

		if (UserInfo.userId === undefined) {
			return null
		}

		const worries = Array.isArray(UserInfo.worries)
			? UserInfo.worries.join('、')
			: '-----'
		const userData = (
			<li className="list__item" style={{ float: 'left' }}>
				<section className="chat-karte is_principal">
					<h2 className="chat-karte-title">
						<i className="material-icons chat-karte-title__icon">
							person
						</i>
						<span className="chat-karte-title__text">相談者</span>
					</h2>
					<div className="chat-karte-name">
						<p className="chat-karte-name__main text-overflow_ellipsis">
							{UserInfo.userName}
						</p>
						<p className="chat-karte-name__sex">
							(
							{UserInfo.gender ? Gender[UserInfo.gender] : '----'}
							)
						</p>
						{this.state.isFirstTimeUseChat &&
							<span onClick={() => this.setState({ firstTimeChatOnline: true })}>
								<img src="/img/icon_firsttime.png" className="imgIconFirstTime" />
							</span>
						}
					</div>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">ふりがな</dt>
						<dd className="chat-karte-chart__data">
							{UserInfo.nickName ? UserInfo.nickName : '---'}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">生年月日</dt>
						<dd className="chat-karte-chart__data">
							{`${moment(UserInfo.birthday).format('YYYY-MM-DD')}(${UserInfo.currentAge}歳)`}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">星座</dt>
						<dd className="chat-karte-chart__data">
							{getZodiac(UserInfo.birthday)}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">出生地</dt>
						<dd className="chat-karte-chart__data">
							{UserInfo.placeOfBirth === 'UNKNOWN' || !UserInfo.placeOfBirth
								? '---'
								: placeFilter(UserInfo.placeOfBirth)}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">出生時間</dt>
						<dd className="chat-karte-chart__data">
							{moment(UserInfo.timeOfBirth, 'HHmm').isValid()
								? moment(UserInfo.timeOfBirth, 'HHmm').format(
									'HH:mm'
								)
								: '---'}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">悩み</dt>
						<dd className="chat-karte-chart__data">{worries}</dd>
					</dl>
					<dl className="chat-karte-chart">
						{ChatRequestUnRead.data[UserInfo.userId] ||
							ChatRequestRead.data[UserInfo.userId] ? (
							<>
								<button
									disabled={CallStoredData.joinState === true}
									className="buttonBlock"
									id="buttonCantBlock"
									onClick={() => isPWA() ? this.showPopUpCantBlock() : {}}
								>
									{isPWA() ? 'ブロック' : "通報する"}
								</button>
								{isShowBtnCloseChat ? (
									<button
										disabled={CallStoredData.joinState === true}
										className={CallStoredData.joinState === true ? "button-out-chat" : "buttonBlock button-out-chat"}
										onClick={this.showPopupOutChat}
									>
										退席
									</button>
								) : null}
							</>
						) : UserInfo.isBlocked ? (
							<button
								className="buttonBlock"
								id="buttonUnBlock"
								onClick={this.showPopUpUnBlock}
							>
								ブロック解除
							</button>
						) : (
							<button
								className="buttonBlock"
								id="buttonBlock"
								onClick={() => isPWA() ? this.showPopUpBlock() : this.showDialogReportUser()}
							>
								{isPWA() ? 'ブロック' : "通報する"}
							</button>
						)}
					</dl>
				</section>
			</li>
		)

		return (
			<div className="wrap-chat-box-customer">
				<div className="chat-box-customer" style={{ height: this.props.CallStoredData.joinState == true ? "calc(100% - 140px)" : "100%" }}>
					{/* mobile show data */}
					<div
						className="chat-karte-list-wrap"
						id="js_chat_karte"
						onClick={(event) => this.switchTab(event)}
					>
						<div
							className={
								'make-menu-right-log-chat mobile-screen-show-chat-log'
							}
						>
							<ul className="">
								<li
									className=""
									style={{ zIndex: 99 }}
									onClick={() => this.showTab('memo')}
								>
									<a
										className={
											this.state.tab === 'memo' ?
												'is-active' : ''
										}
										href="javascript:void(0)"
									>
										相談者情報
									</a>
								</li>
								<li
									className=""
									style={{ zIndex: 99 }}
									onClick={() => this.showTab('calllog')}
								>
									<a
										className={
											this.state.tab === 'calllog' ?
												'is-active' : ""
										}
										href="javascript:void(0)"
									>
										通話履歴
									</a>
								</li>
								<li
									className=""
									style={{ zIndex: 99 }}
									onClick={() => this.showTab('answerLog')}
								>
									<a
										className={
											this.state.tab === 'answerLog' ?
												'is-active' : ""
										}
										href="javascript:void(0)"
									>
										教えて！先生
									</a>
								</li>
							</ul>
						</div>
						<ul
							className={
								this.state.tab === 'memo'
									? 'chat-karte-list list column_1'
									: 'chat-karte-list list column_1 hiddenNotImportant'
							}
						>
							{userData}
							<Partner name="相手1" partner={UserInfo.partner1} />
							<Partner name="相手2" partner={UserInfo.partner2} />
						</ul>
						<div className="call-log-sp call-log-sp-show">
							<ChatUserLogCall
								show={this.state.tab === 'calllog'}
								UserInfo={UserInfo}
							/>
						</div>
						{this.state.tab === 'answerLog' && (
							<DetailAnswerList UserInfo={UserInfo} />
						)}
					</div>
					{/* pc show mobile data*/}
					<div className="" id="js_chat_memo_history">
						<nav className="tab-bar chat-memo-history__nav make-menu-right-log-chat">
							<ul>
								<li>
									<a
										className={
											this.state.tab === 'memo' ?
												'is-active' : ""
										}
										onClick={() => this.showTab('memo')}
										href="javascript:void(0)"
									>
										メモ
									</a>
								</li>
								<li>
									<a
										className={
											this.state.tab === 'calllog' ?
												'is-active' : ""
										}
										onClick={() => this.showTab('calllog')}
										href="javascript:void(0)"
									>
										通話履歴
									</a>
								</li>
								<li>
									<a
										className={
											this.state.tab === 'answerLog' ?
												'is-active' : ""
										}
										onClick={() =>
											this.showTab('answerLog')
										}
										href="javascript:void(0)"
									>
										教えて！先生{' '}
									</a>
								</li>
							</ul>
						</nav>
						{this.state.tab === 'memo' && (
							<ChatUserMemo
								joinState={this.props.CallStoredData.joinState}
								updateResetMemo={this.updateResetMemo}
								isResetMemo={this.state.isResetMemo}
								show={this.state.tab === 'memo'}
								UserInfo={UserInfo}
							/>
						)}
						{this.state.tab === 'calllog' && (
							<ChatUserLogCall
								show={this.state.tab === 'calllog'}
								UserInfo={UserInfo}
							/>
						)}
						{this.state.tab === 'answerLog' && (
							<AnswerLogUser UserInfo={UserInfo} />
						)}
					</div>
					{DetailAnswerChatLog.data !== null && <DetailAnswerPWA />}
					<div className="btn-close-tab" onClick={this.closeChatInfo}>
						<i className="material-icons tab__icons">clear</i>
					</div>
				</div>

				<DialogBlock
					ref={(dialog) => (this.dialogBlock = dialog)}
					socket={this.props.socket}
				/>
				<DialogFirstTimeChat
					ref={(dialog) => (this.dialogFirstTime = dialog)}
					socket={this.props.socket}
					firstTimeChatOnline={this.state.firstTimeChatOnline}
					onClose={() => this.setState({ firstTimeChatOnline: false })}
				/>
				<DialogCantBlock
					ref={(dialog) => (this.dialogCantBlock = dialog)}
				/>
				<ModalReportUser
					isShowDialog={this.state.showDialogReportUser}
					onClose={() => this.setState({ showDialogReportUser: false, handleSuccessReport: null })}
					onBlock={() => this.acceptBlock()}
					handleReport={(e) => this.reportUser(e)}
					userInfo={this.props.UserInfo}
					reasonReport={this.state.tellerReportReasons}
					handleSuccessReport={this.state.handleSuccessReport}
					socket={this.props.socket}
				/>
				<DialogUnBlock
					ref={(dialog) => (this.dialogUnBlock = dialog)}
					userData={this.props.UserInfo}
				/>
				<DialogOutChat
					socket={this.props.socket}
					ref={(dialog) => (this.dialogOutChat = dialog)}
					userData={this.props.UserInfo}
				/>
			</div>
		)
	}
}

/**
 * ユーザーのパートナー情報
 * @param {*} props ユーザーのパートナー情報
 */
const Partner = (props) => {
	let partnerData = {
		name: '---',
		nickName: '---',
		gender: '---',
		birthday: '---',
		placeOfBirth: '---',
		timeOfBirth: '---',
		relation: '---',
		currentAge: '---',
	}

	if (props.partner.name) partnerData.name = props.partner.name
	if (props.partner.nickName) partnerData.nickName = props.partner.nickName
	if (props.partner.gender) partnerData.gender = Gender[props.partner.gender]
	if (props.partner.birthday)
		partnerData.birthday = moment(props.partner.birthday).format(
			'YYYY-MM-DD'
		)
	if (props.partner.placeOfBirth && props.partner.placeOfBirth !== 'UNKNOWN')
		partnerData.placeOfBirth = placeFilter(props.partner.placeOfBirth)
	if (moment(props.partner.timeOfBirth, 'HHmm').isValid())
		partnerData.timeOfBirth = moment(
			props.partner.timeOfBirth,
			'HHmm'
		).format('HH:mm')
	if (props.partner.relation) partnerData.relation = props.partner.relation
	if (props.partner.currentAge) partnerData.currentAge = props.partner.currentAge

	return (
		<li className="list__item" style={{ float: 'left' }}>
			<section className="chat-karte is_partner">
				<h2 className="chat-karte-title">
					<i className="material-icons chat-karte-title__icon">
						person_outline
					</i>
					<span className="chat-karte-title__text">{props.name}</span>
				</h2>
				<div className="chat-karte-name">
					<p className="chat-karte-name__main text-overflow_ellipsis">{partnerData.name}</p>
					<p className="chat-karte-name__sex">
						({partnerData.gender})
					</p>
				</div>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">ふりがな</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.nickName ? partnerData.nickName : '---'}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">生年月日</dt>
					<dd className="chat-karte-chart__data">
						{`${partnerData.birthday}(${partnerData.currentAge}歳)`}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">星座</dt>
					<dd className="chat-karte-chart__data">
						{getZodiac(partnerData.birthday) !== '' ? getZodiac(partnerData.birthday) : '---'}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">出生地</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.placeOfBirth}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">出生時間</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.timeOfBirth}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">関係</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.relation}
					</dd>
				</dl>
			</section>
		</li>
	)
}

const placeFilter = (place) => {
	let exceptPlaces = ['東京', '大阪', '京都', '北海道']
	return exceptPlaces.indexOf(place) === -1 ? `${place}県` : place
}

const getZodiac = (birthday) => {
	const zodiacs = [
		{
			name: 'おひつじ座',
			start: {
				month: 3,
				day: 21,
			},
			end: {
				month: 4,
				day: 19,
			},
		},
		{
			name: 'おうし座',
			start: {
				month: 4,
				day: 20,
			},
			end: {
				month: 5,
				day: 20,
			},
		},
		{
			name: 'ふたご座',
			start: {
				month: 5,
				day: 21,
			},
			end: {
				month: 6,
				day: 21,
			},
		},
		{
			name: 'かに座',
			start: {
				month: 6,
				day: 22,
			},
			end: {
				month: 7,
				day: 22,
			},
		},
		{
			name: 'しし座',
			start: {
				month: 7,
				day: 23,
			},
			end: {
				month: 8,
				day: 22,
			},
		},
		{
			name: 'おとめ座',
			start: {
				month: 8,
				day: 23,
			},
			end: {
				month: 9,
				day: 22,
			},
		},
		{
			name: 'てんびん座',
			start: {
				month: 9,
				day: 23,
			},
			end: {
				month: 10,
				day: 23,
			},
		},
		{
			name: 'さそり座',
			start: {
				month: 10,
				day: 24,
			},
			end: {
				month: 11,
				day: 22,
			},
		},
		{
			name: 'いて座',
			start: {
				month: 11,
				day: 23,
			},
			end: {
				month: 12,
				day: 21,
			},
		},
		{
			name: 'やぎ座',
			start: {
				month: 12,
				day: 22,
			},
			end: {
				month: 1,
				day: 19,
			},
		},
		{
			name: 'みずがめ座',
			start: {
				month: 1,
				day: 20,
			},
			end: {
				month: 2,
				day: 18,
			},
		},
		{
			name: 'うお座',
			start: {
				month: 2,
				day: 19,
			},
			end: {
				month: 3,
				day: 20,
			},
		},
	]
	const date = moment(birthday)
	const month = date.month() + 1
	const day = date.date()
	let zodiacName = ''

	for (let x in zodiacs) {
		let zodiac = zodiacs[x]

		if (
			(month == zodiac.start.month && day >= zodiac.start.day) ||
			(month == zodiac.end.month && day <= zodiac.end.day)
		) {
			zodiacName = zodiac.name
			break
		}
	}

	return zodiacName
}

const mapStateToProps = (state) => {
	return {
		ChatRequest: state.ChatRequest,
		ChatRequestUnRead: state.ChatRequestUnRead,
		ChatRequestRead: state.ChatRequestRead,
		DetailAnswerChatLog: state.DetailAnswerChatLog,
		CallStoredData: state.Call,
	}
}

export default connect(mapStateToProps)(ChatUserInfo)
