import React, { Component } from 'react'

import { ANSWER_TELLER, QuestionCategory, QuestionStatusResponse } from './../constants/Config'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { isPWA } from '../helper/helpFunction'

class AnswerItem extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	getQuestionCategory(category) {
		let textCategory = ''
		switch (category) {
			case QuestionCategory.MARRIAGE_OR_DIVORCE: {
				textCategory = '結婚/離婚'
				break
			}
			case QuestionCategory.MARRIAGE: {
				textCategory = '縁結び'
				break
			}
			case QuestionCategory.DIVORCE: {
				textCategory = '縁切り'
				break
			}
			case QuestionCategory.REPUDIATION: {
				textCategory = '復縁'
				break
			}
			case QuestionCategory.INFIDELITY: {
				textCategory = '不倫'
				break
			}
			case QuestionCategory.LOVE: {
				textCategory = '恋愛 '
				break
			}
			case QuestionCategory.WORK: {
				textCategory = ' 仕事'
				break
			}
			case QuestionCategory.INTERPERSONAL: {
				textCategory = '対人関係'
				break
			}
			case QuestionCategory.FORTUNE: {
				textCategory = '運勢'
				break
			}
			case QuestionCategory.LUCKY_MONEY: {
				textCategory = '金運'
				break
			}
			case QuestionCategory.METAL: {
				textCategory = 'メンタル'
				break
			}
			case QuestionCategory.OTHER: {
				textCategory = 'その他'
				break
			}
		}

		return textCategory
	}

	getQuestionStatus = (item) => {
		if (item.status === QuestionStatusResponse.ANSWERING) {
			let amountMiliSecondsOfOneHour = 3600 * 1000
			let amountMiliSecondsOfOneMinute = 60 * 1000
			let hours = Math.floor(
				item.deadLineToAnswer / amountMiliSecondsOfOneHour
			)
			let minutes = Math.floor(
				(item.deadLineToAnswer - hours * amountMiliSecondsOfOneHour) /
					amountMiliSecondsOfOneMinute
			)
			return `回答受付期限: 残り${hours}時間${minutes}分`
		} else {
			return '受付終了'
		}
	}

	render() {
		const { item } = this.props
		const questionCategory = this.getQuestionCategory(item.category)
		const questionStatus = this.getQuestionStatus(item)
		const questionCreateTime = new Date(item.createTime)
		const questionCreateTimeFormated =
			moment(questionCreateTime).format('YYYY/MM/DD')
		const isAnswerLogRoute =
			window.location.pathname.includes('answer/answer-log')
		const hasBreakLine = item.content.search(/\n/) > 0
		const isLongContent = item.content.split(/\r\n|\r|\n/).length > 2

		return (
			<Link
				to={
					this.props.flagAnswerLog
						? `detail/${item.questionId}`
						: `answer/detail/${item.questionId}`
				}
				style={{ textDecoration: 'none' }}
			>
				<div className="answer-item">
					<div className="answer-item-top">
						<div className="answer-item-category">
							{questionCategory}
						</div>
						{!isAnswerLogRoute && (
							<div className="answer-item-status">
								{questionStatus}
							</div>
						)}
					</div>
					{hasBreakLine ? (
						<div
							className={
								isLongContent
									? 'answer-item-long-content'
									: 'answer-item-short-content'
							}
						>
							<pre>{item.content}</pre>
						</div>
					) : (
						<div className="answer-item-content">
							{item.content}
						</div>
					)}

					<div className="answer-item-bottom">
						<div>
							<p className="answer-item-username">
								{item.nickName}さん {item.age}歳
							</p>
							<p className="answer-item-status-profile">
								<i
									className={
										item.isShowMyProfile
											? 'material-icons btn__icons icon-active'
											: 'material-icons btn__icons'
									}
								>
									person
								</i>
							</p>
						</div>
						<p className="answer-item-status-time hidden-mobile">
							{questionCreateTimeFormated}
						</p>
						<div className="answer-item-status-amount-answer" id={!isPWA() && "comment-answer-web__item"}>
							{ !isPWA() ?
								<img src='/img/icon_comment.png' className="img-icon_commnet"></img>
								:
								<i className="material-icons btn__icons icon-amount-answer">
									comment
								</i>
							}
							{(item.isTarget) ? 
								<div className="tooltip-answer">
									{item.totalAnswer}
									<img className="img-lable__target" src="/img/lable_answer.png"></img>
								</div>
							: 
								<span>{item.totalAnswer}</span>
							}
						</div>
					</div>
					<div className="answer-item-status-time hidden-pc">
						{questionCreateTimeFormated}
					</div>
				</div>
			</Link>
		)
	}
}

export default AnswerItem
