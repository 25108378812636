import React, { Component } from 'react'
import { connect } from 'react-redux'
import IntroductionMenu from './IntroductionMenu'
import * as Fetch from '../../util/Fetch'
import { glasConfig } from '../../constants/Config'
import './styleIntroduction.css'
import request from 'axios'

import iconMail from '../../assets/icon_mail.png'
import iconLine from '../../assets/icon_LINE.png'
import iconTwitter from '../../assets/icon_twitter.png'
import iconFacebook from '../../assets/icon_facebook.png'
import DialogGetPoint from '../homeTellerIntroductionTeller/DialogGetPoint'
import Snackbar from 'material-ui/Snackbar'
import { copyToClipboard, isMobile } from '../../util/StellaConstant'


Number.prototype.formatCurrency = function (n, x) {
	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
	return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,')
}

const StepIntroduction = ({ title, content, active }) => (
	<div className="item-step-in">
		<div className="btn-step-in">
			<p>{title}</p>
		</div>
		<div style={{ flex: 1 }}>
			<p
				className={`step-content-in ${active && 'step-content-in-active'
					}`}
			>
				{content}
			</p>
		</div>
	</div>
)

class Introduction extends Component {
	constructor(props) {
		super(props)
		this.state = {
			numberPoint: 0,
			listAchievement: [],
			numberPointForUser: 0,
		}
	}

	componentDidMount() {
		// window.LineIt.loadButton();
		// window.FB.XFBML.parse();
		window.FB.init({
			// appId: '479553706330987',
			appId: '1727739773937893',
			autoLogAppEvents: true,
			xfbml: true,
			version: 'v5.0',
		})

		this.getPointSetting()
		this.getListAchievement()
		this.getPointSettingForUser()
	}

	getPointSetting = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base + glasConfig.get_point_setting_introduction,
				{
					headers: { Authorization: token },
					params: { isIntroduced: false },
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					const { point } = response.data.data
					this.setState({ numberPoint: point })
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getPointSettingForUser = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base + glasConfig.get_point_setting_introduction,
				{
					headers: { Authorization: token },
					params: { isIntroduced: true },
				}
			)
			.then(async (response) => {
				if (response.data.code === 0) {
					const { point } = response.data.data
					await this.setState({ numberPointForUser: point })
					window.LineIt.loadButton()
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getListAchievement = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
				glasConfig.get_list_achievement_introduction_home,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					const data = response.data.data || []
					this.setState({ listAchievement: data })
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getPoint = (rewardId) => () => {
		const { numberPoint } = this.state
		this.refs.dialogGetPoint.show({ rewardId, numberPoint })
	}

	render() {
		const {
			numberPoint,
			listAchievement = [],
			numberPointForUser,
		} = this.state
		const { isIntroduced } = this.props
		const tellerId = Fetch.tellerId()

		let contentIntro = `PCでもスマホでも好きな方でお仕事できる、占い・カウンセリングアプリの「Stella」を紹介します。%0D%0A下のURLからアプリをインストールして登録！稼働をして初めての精算をすると、さらに${numberPointForUser}ptsのボーナスがもらえます！%0D%0A`
		let contentIntroFacebook = `PCでもスマホでも好きな方でお仕事できる、占い・カウンセリングアプリの「Stella」を紹介します。\n下のURLからアプリをインストールして登録！稼働をして初めての精算をすると、さらに${numberPointForUser}ptsのボーナスがもらえます！\n`
		let urlIntro = `${glasConfig.urlIntro}${tellerId}`
		return (
			<div className="content">
				<DialogGetPoint
					ref="dialogGetPoint"
					onClose={() => {
						this.getListAchievement()
					}}
				/>
				<h1 className="content__title hidden-sp">紹介</h1>
				<IntroductionMenu isIntroduced={isIntroduced} />
				<div className="primary">
					<div className="primary-title hidden-sp">
						<p
							style={{
								fontFamily: 'HiraKakuPro-W3',
								fontSize: '20px',
							}}
						>
							友人・知人を紹介する
						</p>
					</div>
					<div className="primary-body-in frame-table">
						{!!listAchievement[0] && (
							<button
								class="btn-raised color_default btnGetPointIntro"
								onClick={this.getPoint(
									listAchievement[0].rewardId
								)}
							>
								{'紹介ボーナスを受け取る'}
							</button>
						)}

						<h4
							style={{
								padding: '20px 0',
								fontWeight: 'bold',
								fontFamily: 'HiraKakuPro-W6',
							}}
						>
							<span>
								ご友人やお知り合いの鑑定士の方を紹介して
							</span>
							<span style={{ color: 'rgba(236, 64, 122, 1)' }}>
								{numberPoint.formatCurrency()}ポイントGET
							</span>
							<span>!</span>
						</h4>
						<div>
							<p
								style={{
									marginBottom: 6,
									fontSize: '15px',
									fontWeight: 'bold',
								}}
							>
								〜紹介の流れ〜
							</p>

							<div style={{ display: 'inline-block' }}>
								<StepIntroduction
									title="STEP1"
									content="下記のいずれかの方法でステラを紹介！"
									active={false}
								/>
								<StepIntroduction
									title="STEP2"
									content="紹介した方が紹介URLからステラに登録"
									active={false}
								/>
								<StepIntroduction
									title="STEP3"
									content="紹介した方が稼働をして精算する"
									active={false}
								/>
								<StepIntroduction
									title="STEP4"
									content={`あなたと紹介した方に${numberPoint.formatCurrency()}ポイント×何人でもOK！`}
									active={true}
								/>
							</div>
							<p
								style={{
									fontFamily: 'Hiragino Kaku Gothic Pro',
									fontSize: 12,
									color: 'rgba(0, 0, 0, 0.87)',
								}}
							>
								※登録から30日以内に精算を完了させることが条件です！
							</p>
						</div>
						<div style={{ paddingTop: 20 }}>
							<p
								className="list-social"
								style={{ marginBottom: 20, fontWeight: 'bold' }}
							>
								▼紹介に使用したい方法をクリック！
							</p>

							<div style={{ display: 'flex' }}>
								<div style={{ marginRight: 20 }}>
									{isMobile ? (
										<div className="icon-line-mb">
											<div style={{ opacity: 0 }}>
												<div
													className="line-it-button"
													data-lang="en"
													data-type="share-b"
													data-ver="3"
													data-url={
														contentIntro + urlIntro
													}
													data-color="default"
													data-size="large"
													data-count="false"
												/>
											</div>
										</div>
									) : (
										<a
											href={`https://social-plugins.line.me/lineit/share?url=${urlIntro}&text=${'PCでもスマホでも好きな方でお仕事できる、占い・カウンセリングアプリの「Stella」を紹介します。'}&from=line_scheme`}
											target="_blank"
										>
											<img
												alt={''}
												src={iconLine}
												style={{
													width: 40,
													height: 40,
												}}
											/>
										</a>
									)}
								</div>

								<div
									style={{
										marginRight: 20,
										cursor: 'pointer',
									}}
								>
									<img
										alt={''}
										onClick={() => {
											window.FB.ui(
												{
													method: 'share',
													href: urlIntro,
													quote: contentIntroFacebook,
												},
												function (response) { }
											)
										}}
										src={iconFacebook}
										style={{ width: 40, height: 40 }}
									/>
								</div>

								<div style={{ marginRight: 20 }}>
									<a
										target="_blank"
										href={`https://twitter.com/intent/tweet?text=${contentIntro + urlIntro}`}
									>
										<img
											alt={''}
											src={iconTwitter}
											style={{ width: 40, height: 40 }}
										/>
									</a>
								</div>
								<div style={{ marginRight: 20 }}>
									<a
										target="_blank"
										href={`mailto:?body=${contentIntro + urlIntro
											}`}
									>
										<img
											alt={''}
											src={iconMail}
											style={{ width: 40, height: 40 }}
										/>
									</a>
								</div>
							</div>
						</div>

						{/* messenger facebook
                        <a href={`fb-messenger://share/?link=${urlIntro}&title=${contentIntro}`}>Send In Messenger</a> */}

						<div className="c-input-in">
							<input
								disabled
								placeholder="https://"
								value={urlIntro}
							/>
							<button
								class="btn-raised color_default"
								onClick={() => {
									copyToClipboard(urlIntro)
									this.setState({ showPopUp: true })
								}}
							>
								コピー
							</button>
						</div>
						<Snackbar
							open={this.state.showPopUp}
							message={'コピーしました'}
							autoHideDuration={1500}
						/>
					</div>
					{/* <div className="customer-list-sp display-mobile">{this.getSupportHistorySP()}</div> */}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		CustomerManagement: store.CustomerManagement,
	}
}

export default connect(mapStateToProps)(Introduction)
