/**
 * 左メニューのオフライン時にリクエストをくれたユーザー一覧
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import LeftOfflineList from './LeftOfflineList'
import { LeftOfflineSortList } from './../constants/Config'
import * as RequestOfflineActions from '../actions/RequestOfflineActions'
import { getUserId } from '../assets/help/help'

export const callApiGetSortRequestOffline = (props) => {
	let { dispatch } = props
	const getValueSort = localStorage.getItem('valueSortRequestOffline')
	if (getValueSort) {
		let value = parseInt(getValueSort)
		dispatch(
			RequestOfflineActions.fetch({
				orderBy: LeftOfflineSortList[value].orderBy,
				sortBy: LeftOfflineSortList[value].sortBy,
			})
		)
	} else {
		dispatch(RequestOfflineActions.fetch())
	}
}

class LeftOffline extends Component {
	constructor(props) {
		super(props)
		this.state = {
			offset: 0,
			limit: 20,
			valueSort: 0,
			isOpenSort: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!this.props.show && nextProps.show) {
			this.setState({
				offset: 0,
			})
		}
	}

	loadData() {
		this.setState({
			offset: this.state.offset + this.state.limit,
		})
	}

	onChangeSort = (value) => {
		this.setState({ valueSort: value })
		localStorage.setItem('valueSortRequestOffline', value)
		callApiGetSortRequestOffline(this.props)
	}

	componentDidMount() {
		const getValueSort = localStorage.getItem('valueSortRequestOffline')
		if (getValueSort) {
			this.setState({
				valueSort: parseInt(getValueSort),
			})
		}
		callApiGetSortRequestOffline(this.props)
	}

	render() {
		const {
			RequestOffline,
			ChatRequestRead,
			ChatRequestUnRead,
			chatUserId,
			userNameOfflineRequest,
			closeNavLeft,
		} = this.props
		const maxNumber = Object.keys(RequestOffline.data).length

		if (maxNumber === 0) {
			return (
				<div>
					<dt className="secondary-definition__title">
						<b>リクエスト受付</b>
					</dt>
					<dd className="secondary-definition__data">
						<p className="secondary-definition__nodata">
							リクエストはありません
						</p>
					</dd>
				</div>
			)
		}

		const num = this.state.offset + this.state.limit
		const moreButton =
			maxNumber <= num ? (
				''
			) : (
				<div className="list__item">
					<button
						className="btn-customer customer_request"
						onClick={() => this.loadData()}
					>
						もっと見る
					</button>
				</div>
			)

		const UserList = Object.values(RequestOffline.data).map((res, i) => {
			let ChatRequestReadOld = ChatRequestRead.data
			let ChatRequestUnReadOld = ChatRequestUnRead.data
			let userIdCheck = getUserId(res)
			let checkChatRequest = true
			// let listCheckChatRequest = Object.assign(ChatRequestReadOld, ChatRequestUnReadOld);
			if (
				ChatRequestReadOld[userIdCheck] !== undefined ||
				ChatRequestUnReadOld[userIdCheck] !== undefined
			) {
				checkChatRequest = false
			}

			if (i < num && checkChatRequest) {
				return (
					<LeftOfflineList
						key={`offlinelist_${i}`}
						data={res}
						chatUserId={chatUserId}
						closeNavLeft={closeNavLeft}
						userNameOfflineRequest={userNameOfflineRequest}
					/>
				)
			} else {
				return <></>
			}
		})

		return (
			<div className="secondary-definition__container">
				<dt className="secondary-definition__title-request">
					<div>リクエスト受付</div>
					<div
						className="secondary-definition__select-sort"
						onClick={() =>
							this.setState({
								isOpenSort: !this.state.isOpenSort,
							})
						}
					>
						<span>
							{LeftOfflineSortList[this.state.valueSort].label}{' '}
							<span
								style={{ marginLeft: 5 }}
								className={'glyphicon glyphicon-menu-down'}
							/>{' '}
						</span>
						{this.state.isOpenSort && (
							<ul>
								{LeftOfflineSortList.map((item, index) => {
									return (
										<li
											className={
												this.state.valueSort ===
												item.value && 'active'
											}
											key={index}
											onClick={() =>
												this.onChangeSort(item.value)
											}
										>
											{item.label}
										</li>
									)
								})}
							</ul>
						)}
					</div>
				</dt>
				<dd className="secondary-definition__data">
					<div className="list column_1 content-request-list-offline">
						{UserList}
						{moreButton}
					</div>
				</dd>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		RequestOffline: state.RequestOffline,
		ChatRequestRead: state.ChatRequestRead,
		ChatRequestUnRead: state.ChatRequestUnRead,
	}
}

export default connect(mapStateToProps)(LeftOffline)
