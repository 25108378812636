import * as WebSocket from '../util/WebSocket'

export default class User {
	static token
	static tellerId
	static socket

	static setToken(token) {
		this.token = token
	}

	static setTellerId(tellerId) {
		this.tellerId = tellerId
	}

	static connectToSocket() {
		if (!this.socket) {
			this.socket = WebSocket.connect()
		}
	}

	static disconnectToSocket() {
		this.socket.disconnect()
		this.socket = null
	}

	static getToken() {
		return this.token
	}

	static getTellerId() {
		return this.tellerId
	}

	static getSocket() {
		return this.socket
	}
}
