/**
 * 通話・チャットリクエスト（左カラムの対応中(inConversation:true)、相談依頼申請一覧(inConversation:false)）
 */
import React, { Component } from 'react'
import { GenderAvator } from '../constants/Config'
import * as ConfigMessage from '../constants/ConfigMessage'
import moment from 'moment'
import exif from 'exif-js'
import $ from 'jquery'
import Snackbar from 'material-ui/Snackbar'
import '../assets/scss/ChatContentMessageDetails.scss'
require('moment/locale/ja')

export default class ChatContentMessageDetails extends Component {
	constructor(props) {
		super(props)

		this.imageLoaded = false
		this.modal = null
		this.state = {
			errorClickPicture: false,
		}
	}

	componentDidMount() {
		const { data, getOnBottom } = this.props

		let messageList = $('.chat-list-area')
		let actualList = messageList.find('ul')
		let t = this
		let heightWindow = $(window).height()
		if (typeof this.imageRef !== 'undefined') {
			this.imageRef.addEventListener('load', function () {
				exif.getData(this, function () {
					//         let orientation = exif.getTag(this, "Orientation");
					let imageClone = this.parentElement.cloneNode(true)

					//         if (typeof orientation !== 'undefined' && orientation == 6) {
					//             let width = this.offsetWidth;
					//             let height = this.offsetHeight;
					//             this.style.transform = 'rotate(90deg)';
					//             this.style.transformOrigin = '0 100%';
					//             this.style.position = 'relative';
					//             this.style.top = `-${height}px`;
					//             this.parentElement.style.height = `${width}px`;
					//             imageClone.getElementsByTagName("img")[0].style.width = '60%'
					//             imageClone.getElementsByTagName("img")[0].style.transform = 'rotate(90deg)'
					//             imageClone.getElementsByTagName("img")[0].style.marginTop = '80px'
					//         } else {
					imageClone.getElementsByTagName('img')[0].style.width = ''
					imageClone.getElementsByTagName(
						'img'
					)[0].style.maxHeight = `${heightWindow - 50}px`
					imageClone.getElementsByTagName(
						'img'
					)[0].style.marginBottom = '0'
					//         }

					//         if (parseInt(getOnBottom())) {
					//           //messageList.prop('scrollTop', actualList.height() + 16 - messageList.height());
					//         }
					t.modal = $(this).closest('.chat-list').find('.modal')
					t.modal.find('.modal-content').append(imageClone)
					t.imageLoaded = true
				})
			})
		}

		if (typeof this.loadingIcon !== 'undefined') {
			this.loadingIcon.addEventListener('load', function () {
				if (parseInt(getOnBottom())) {
					//messageList.prop('scrollTop', actualList.height() + 16 - messageList.height());
				}
			})
		}
		$(document).keydown(function (event) {
			if (event.keyCode == 27) {
			}
		})
	}

	handleClickPicture(e) {
		if (this.imageLoaded) {
			this.modal.show()
		} else {
			this.setState({
				errorClickPicture: true,
			})
		}
	}
	closeModalPicture(e) {
		this.modal.hide()
	}
	handleErrorFileClose(e) {
		this.setState({
			errorClickPicture: false,
		})
	}

	// shouldComponentUpdate(nextProps, nextState) {
	//   if (this.props.data.message.msgId == nextProps.data.message.msgId) {
	//     if (this.props.idMessageOfflineOpened != nextProps.data.message.isShow) {
	//       return true
	//     }
	//   }
	//   return false
	// }

	isFileMessage = (msgType) => {
		return (
			msgType === 'SENT_FILE' ||
			msgType === 'SEND_FILE' ||
			msgType === 'SEND_FILE_FREE' ||
			msgType === 'SENT_FILE_FREE' ||
			msgType === 'SEND_FILE_UNOFFICIAL' ||
			msgType === 'SEND_FILE_FREE_UNOFFICIAL' ||
			msgType === 'SEND_STAMP' ||
			msgType === 'SEND_STAMP_UNOFFICIAL'
		)
	}

	isUnofficialMessage = (msgType, official) => {
		return (
			msgType === 'CHARGE_TEXT_UNOFFICIAL' ||
			msgType === 'SEND_FILE_UNOFFICIAL' ||
			msgType === 'FREE_TEXT_UNOFFICIAL' ||
			msgType === 'SEND_FILE_FREE_UNOFFICIAL' ||
			(msgType === 'SENT_FILE_FREE' && official === false)
		)
	}

	isFreeUnofficialMessage = (msgType, official) => {
		return (
			msgType === 'FREE_TEXT_UNOFFICIAL' ||
			msgType === 'SEND_FILE_FREE_UNOFFICIAL' ||
			(msgType === 'SENT_FILE_FREE' && official === false)
		)
	}

	isNotStamp = (msgType, official) => {
		return msgType !== 'SEND_STAMP' && msgType !== 'SEND_STAMP_UNOFFICIAL'
	}

	render() {
		let { data } = this.props
		moment.locale('ja')
		const msgTime = data.message.serverTime
			? moment(data.message.serverTime, 'YYYYMMDDHHmmss')
					.add(9, 'hours')
					.format('HH:mm')
			: moment.unix(data.message.senddate).format('YYYY-MM-DD HH:mm:ss')
		const msgId = data.message.msgId
		const fromId = data.message.fromId
		const msgType = data.message.msgType
		const msgMap = ConfigMessage.getMessageData(data.message)
		if (msgType === 'SENT_FILE_FREE' && data.official === false) {
			msgMap.label = 'オフラインメッセージ'
		}
		const msg = msgMap.msg
		const name = data.MyId === fromId ? data.MyName : data.UserName
		const avatartPath =
			data.MyId === fromId
				? data.MyAvatartPath
				: GenderAvator[data.UserGender]

		let messageFile = ''
		if (this.isFileMessage(msgType)) {
			messageFile = (
				<div className="message-image">
					<img
						src={msg}
						ref={(image) => (this.imageRef = image)}
						style={{
							width: this.isNotStamp(msgType) ? 300 : 142,
							display: 'block',
						}}
						onClick={
							this.isNotStamp(msgType)
								? (e) => this.handleClickPicture(e)
								: null
						}
					/>
				</div>
			)
		} else if (msgType === 'SEND_GIFT') {
			// const text = `${msg.substring(msg.search('（')).replace(/（|使|用|）/g,'')}加算されました。`
			const point = parseFloat(
				data.message.price || data.message.giftPrice || 0
			).toFixed(2)
			messageFile = (
				<div className="message-image">
					<p>{name}さんからギフトが届きました！</p>
					<p>{point}pts加算されました。</p>
					<img
						src={data.message.giftFilePath}
						style={{
							width: 150,
							display: 'block',
							height: 150,
							objectFit: 'cover',
							margin: '10px 0',
							borderRadius: 75,
							backgroundColor: '#ccc',
							overflow: 'hidden',
						}}
					/>
				</div>
			)
		} else {
			messageFile =
				msg && msg.match('\n')
					? msg.split('\n').map((m, i) => {
						if(m==="")
							return <br key={`${msgId}_${i}`}/>;
						return <p key={`${msgId}_${i}`}>{m}</p>
					  })
					: msg
		}

		return (
			<div key={msgId}>
				<div className="chat-list">
					<figure className="chat-list-figure">
						<img
							className="chat-list-figure__image"
							src="/img/spacer.gif"
							style={{ backgroundImage: `url(${avatartPath})` }}
							alt="プロフィール画像"
						/>
					</figure>
					<div className="chat-list-status">
						<p className="chat-list-status__name">{name}</p>
						<p className="chat-list-status__time">{msgTime}</p>
						{data.message.readTime && data.MyId === fromId ? (
							<p className="chat-list-status__read">既読</p>
						) : (
							''
						)}
					</div>
					{msgMap.label && data.MyId === fromId ? (
						<div
							className={
								this.isUnofficialMessage(msgType, data.official)
									? 'chat-list-label-off'
									: 'chat-list-label'
							}
						>
							<p
								className={
									this.isUnofficialMessage(
										msgType,
										data.official
									)
										? 'chat-list-label__text_off'
										: 'chat-list-label__text_free make-chat-list-label-free'
								}
							>
								{msgMap.label}
							</p>
							{this.isUnofficialMessage(
								msgType,
								data.official
							) && (
								<p
									className={
										this.isFreeUnofficialMessage(
											data.message.msgType,
											data.official
										)
											? 'free_unofficial'
											: 'pay_unofficial'
									}
								>
									{this.isFreeUnofficialMessage(
										data.message.msgType,
										data.official
									)
										? '無料'
										: '有料'}
								</p>
							)}
							<p
								style={{
									marginLeft: 4,
									color: 'rgba(0, 0, 0, 0.54)',
									marginTop: 1,
								}}
							>
								{data.message.msgType ===
									'CHARGE_TEXT_UNOFFICIAL' ||
								data.message.msgType === 'SEND_FILE_UNOFFICIAL'
									? data.message.isShow
										? '開封済み'
										: '未開封'
									: ''}
							</p>
						</div>
					) : (
						''
					)}
					<div className="chat-list-message">
						<div>
							{msgMap.icon ? (
								<i className="material-icons">{msgMap.icon}</i>
							) : (
								''
							)}
							{messageFile}
						</div>
					</div>
					<div
						className="modal"
						onFocus={(e) => this.closeModalPicture(e)}
					>
						<span
							className="closePicture"
							onClick={(e) => this.closeModalPicture(e)}
						>
							&times;
						</span>
						<div className="modal-content"></div>
					</div>
				</div>
				<Snackbar
					open={this.state.errorClickPicture}
					message={'読み込み中です。少々お待ちください。'}
					autoHideDuration={2000}
					onRequestClose={(e) => this.handleErrorFileClose(e)}
				/>
			</div>
		)
	}
}
