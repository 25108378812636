import {
	BANKS_GET,
	BANK_GET,
	BANK_POST,
	BANK_BACK,
	BANK_BRANCHS_GET,
	BANK_BRANCH_GET,
	BANK_BRANCH_POST,
	BANK_BRANCH_BACK,
	BANK_ACCOUNT_EDIT,
	BANK_ACCOUNT_CONFIRM_POST,
	BANK_ACCOUNT_CONFIRM_BACK,
	BANK_ACCOUNT_POST,
	GET_LIST_BANK,
} from '../constants/ActionTypes'

const initialState = {
	data: {},
	bank: {},
	branch: {},
	regist: false,
	listBank: []
}

export const Bank = (state = initialState, action) => {
	switch (action.type) {
		case BANKS_GET:
			return Object.assign({}, state, {
				bankInitial: action.data,
				data: [],
			})
		case BANK_GET:
			return Object.assign({}, state, { bank: action.data, data: [] })
		case BANK_POST:
			return Object.assign({}, state, action.data)
		case BANK_BACK:
			return Object.assign({}, state, action.data)
		case BANK_BRANCHS_GET:
			return Object.assign({}, state, {
				branchInitial: action.data,
				data: [],
			})
		case BANK_BRANCH_GET:
			return Object.assign({}, state, { branch: action.data, data: [] })
		case BANK_BRANCH_POST:
			return Object.assign({}, state, action.data)
		case BANK_BRANCH_BACK:
			return Object.assign({}, state, action.data)
		case BANK_ACCOUNT_EDIT:
			return Object.assign({}, state, action.data)
		case BANK_ACCOUNT_CONFIRM_POST:
			return Object.assign({}, state, action)
		case BANK_ACCOUNT_CONFIRM_BACK:
			return Object.assign({}, initialState)
		case BANK_ACCOUNT_POST:
			return Object.assign({}, state, action, { regist: true })
		case GET_LIST_BANK:
			return Object.assign({}, state, action, { listBank: action.data })
		default:
			return state
	}
}
