import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'

const DialogAutoBestAnswer = (props) => {
    const {
        showPopUp, closePopUp, item
    } = props
    return (
        <Dialog
					open={showPopUp}
					modal={false}
					contentStyle={{ maxWidth: '400px', maxHeight: '444px' }}
					className="dialogGetBestAnswer"
				>
					<div className="titleGetPoint">
						<b>報酬を受け取りました！</b>
					</div>
					<div className="mission-item__rewardContent">
						<img src="/img/mission_getreward.png" />
						<div className="congratulations congratulationsGetPoint">
							ベストアンサーおめでとう！
						</div>
						<div className="point pointBestAnswer" style={{ bottom: "30px" }}>
							{item.pointRewardBestAnswer}PT GET!
						</div>
					</div>
					<div className="mission-item__close">
						<a className="btn-raised color_default" onClick={() => closePopUp()}>OK</a>
					</div>
				</Dialog>
    )
}

export default DialogAutoBestAnswer