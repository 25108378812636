import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as SuspendRequestChatActions from '../../../actions/SuspendRequestChatActions'
import DialogNotifi from '../../../components/Dialog'
import BellPC from '../../../assets/bell-pc.png'
import BellMobile from '../../../assets/bell-mb.png'
import { getDeviceType } from '../../../helper/helpFunction'
import BellRef from './BellRef'
import { playNotificationSound } from '../../../util/Sound'
import { fortuneTellerStatus } from '../../../constants/Config'

class Bell extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dumpState: 0,
		}
		BellRef.setModal(this)
	}

	forceUpdateBell = () => {
		this.setState({ dumpState: this.state.dumpState + 1 })
	}

	doShowDialogChat = () => {
		const { dispatch, SuspendRequestChat, MyProfile } = this.props
		const message = SuspendRequestChat.message
		const lengthListRequest = message.length
		const dataPersisted = localStorage.getItem('requestSuspend')
		const parseDataPersisted =
			dataPersisted !== null ? JSON.parse(dataPersisted) : null

		dispatch(SuspendRequestChatActions.doHideBell())
		if (MyProfile.data.isDisableSoundRequestChat && MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.chatting) {
		} else {
			playNotificationSound(MyProfile.data.ringtoneNumber)
		}
		if (lengthListRequest !== 0) {
			DialogNotifi.getDialog().handleOpen(
				'CHAT',
				message[lengthListRequest - 1]
			)
		} else {
			DialogNotifi.getDialog().handleOpen(
				'CHAT',
				parseDataPersisted[JSON.parse(dataPersisted).length - 1]
			)
		}
	}

	render() {
		const { fromHome } = this.props
		const { isShowBell } = this.props.SuspendRequestChat
		const deviceType = getDeviceType()
		const imgBell = deviceType === 'mobile' ? BellMobile : BellPC
		const dataPersisted = localStorage.getItem('requestSuspend')
		const parseDataPersisted =
			dataPersisted !== null && JSON.parse(dataPersisted)
		return (
			<>
				{fromHome ? null : (
					<>
						{parseDataPersisted.length > 0 ? (
							<div
								className="suspend-request-chat__wrap-icon-bell"
								style={styles.wrapBell}
								onClick={() => this.doShowDialogChat()}
							>
								<img
									className="suspend-request-chat__icon-bell"
									src={imgBell}
								/>
							</div>
						) : null}
					</>
				)}
			</>
		)
	}
}

const styles = {
	wrapBell: {},
}

const mapStateToProps = (state) => {
	return {
		SuspendRequestChat: state.SuspendRequestChat,
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(Bell)
