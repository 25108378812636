import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import { MissionLevel, MissionLevelId, MissionLevelV2, MissionUnit } from '../constants/Config'
import * as MissionActions from '../actions/MissionActions'
import { isMobile } from 'react-device-detect'

export default class MissionItem extends Component {
	constructor(props) {
		super(props)
		this.state = {
			started: false,
			showDetail: false,
			showReward: false,
			isStarting: false,
			isGetting: false,
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			showDetail: false,
			showReward: false,
			isStarting: false,
			isGetting: false,
		})
	}

	toggleDetailPopup() {
		this.setState({
			showDetail: !this.state.showDetail,
		})
	}

	toggleRewardPopup() {
		this.setState({
			showReward: !this.state.showReward,
		})
	}

	getPoint(id, type) {
		if (!this.state.isGetting) {
			const { dispatch } = this.props

			this.setState({
				isGetting: true,
			})

			dispatch(MissionActions.getPoint(id, type))
		}
	}

	startMission(id) {
		if (!this.state.isStarting) {
			const { dispatch } = this.props

			this.setState({
				isStarting: true,
			})

			dispatch(MissionActions.startMission(id))
		}
	}

	render() {
		const { mission } = this.props
		// mission.completeUnit = 100
		let completed =
			mission.unit && mission.completeUnit
				? mission.unit > mission.completeUnit
					? (mission.completeUnit / mission.unit) * 100
					: 100
				: 0
		let isCompleted =
			mission.tellerStart && mission.unit <= mission.completeUnit
				? true
				: false
		let startBtn = (
			<button
				className="btn-raised color_default"
				onClick={() => this.startMission(mission.id)}
			>
				ミッションを始める
			</button>
		)

		if (mission.tellerStart) {
			startBtn = (
				<button
					className="btn-raised color_default"
					onClick={() => this.toggleDetailPopup()}
				>
					ミッションを続ける
				</button>
			)
		}
		return (
			<li
				key={mission.id}
				className={!isMobile ? `mission-item mission-level-${mission.catalog === MissionLevelId.DAILY || mission.catalog === MissionLevelId.SPECIAL ? mission.catalog : 'startdash'} clearfix${isCompleted ? ' completed' : ''}${mission.tellerStart ? ' started' : ''}`
					: `mission-item mission-level-${mission.catalog
					} clearfix${isCompleted ? ' completed' : ''}${mission.tellerStart ? ' started' : ''
					}`}
			>
				<div
					className="mission-item__left"
					onClick={() => this.toggleDetailPopup()}
				>
					<div className="mil__header">
						<span className="mission-item__level">
							{isMobile ? MissionLevel[mission.catalog] : MissionLevelV2[mission.catalog]}
						</span>
						{!isMobile && <div className='block-line' />}
						<span className="mission-item__title">
							{mission.title}
						</span>
					</div>
					<div className="mission-item__progressBar">
						<div
							className="mission-item__completed"
							style={{ width: completed + '%' }}
						></div>
					</div>
					<div className="mil__footer">
						<span className="mission-item__progress">
							{isCompleted
								? '達成！'
								: `達成状況：${mission.tellerStart
									? mission.completeUnit
									: 0
								}/${mission.unit}${MissionUnit[mission.condition]
								}`}
						</span>
						<div className="mission-item__point display-mobile">
							報酬：<span>{mission.grandedPoint}PT</span>
						</div>
					</div>
				</div>
				<div className="mission-item__right clearfix"
					id={!isMobile && "margin-top-mission__item" }
				 >
					<div className="mission-item__point hidden-mobile">
						報酬：<span>{mission.grandedPoint}PT</span>
					</div>
					<div className="mission-item__btn">
						<button
							className="btn-raised color_default hidden-mobile"
							onClick={() => this.toggleRewardPopup()}
							disabled={!isCompleted ? 'disabled' : ''}
						>
							報酬受取
						</button>
						<button
							className="btn-raised color_default display-mobile"
							onClick={() => this.toggleRewardPopup()}
							disabled={!isCompleted ? 'disabled' : ''}
						>
							受取
						</button>
					</div>
				</div>
				<Dialog
					open={this.state.showDetail}
					modal={false}
					bodyClassName={isMobile ? `mission-item__detail mission-level-${mission.catalog}` : `mission-item__detail mission-level-${mission.catalog === MissionLevelId.DAILY || mission.catalog === MissionLevelId.SPECIAL ? mission.catalog : 'startdash'}`}
					contentStyle={{
						maxWidth: '400px',
						maxHeight: '444px',
						overflowY: 'auto',
						overflowX: 'hidden'
					}}
					onRequestClose={() => this.toggleDetailPopup()}
				>
					<div className="mission-item__level">
						{isMobile ? MissionLevel[mission.catalog] : MissionLevelV2[mission.catalog]}
					</div>
					<div className="mission-item__title">{mission.title}</div>
					<div className="mission-item__desc-1">
						{mission.description1}
					</div>
					<div className="mission-item__hint">
						<span>★</span>ヒント<span>★</span>
					</div>
					<div className="mission-item__desc-2">
						{mission.description2}
					</div>
					{/* <div className="mission-item__start">{startBtn}</div> */}
					<div className="mission-item__close">
						<a onClick={() => this.toggleDetailPopup()}>
							{/* キャンセル */}
							OK
						</a>
					</div>
				</Dialog>
				<Dialog
					open={this.state.showReward}
					modal={false}
					bodyClassName={isMobile ? `mission-item__reward mission-level-${mission.catalog}` : `mission-item__reward mission-level-${mission.catalog === MissionLevelId.DAILY || mission.catalog === MissionLevelId.SPECIAL ? mission.catalog : 'startdash'}`}
					contentStyle={{ maxWidth: '400px', maxHeight: '444px', overflowX: 'hidden' }}
					onRequestClose={() => this.toggleRewardPopup()}
				>
					<div className="mission-item__level">
						{isMobile ? MissionLevel[mission.catalog] : MissionLevelV2[mission.catalog]}
					</div>
					<div className="mission-item__rewardContent">
						<img src="/img/mission_getreward.png" />
						<div className="congratulations">
							ミッション達成おめでとう！
						</div>
						<div className="point">{mission.grandedPoint}PT</div>
					</div>
					<div className="mission-item__getReward">
						<button
							className="btn-raised color_accent"
							onClick={() => this.getPoint(mission.id, mission.catalog)}
						>
							報酬を受け取る！
						</button>
					</div>
					<div className="mission-item__close">
						<a onClick={() => this.toggleRewardPopup()}>
							キャンセル
						</a>
					</div>
				</Dialog>
			</li>
		)
	}
}
