import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import {
	glasConfig,
	katakanaList,
} from '../constants/Config'
import SettingMenu from './SettingMenu'
import RewardMenu from './RewardMenu'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import * as MyBankActions from '../actions/MyBankActions'
import DropDown from './DropDown'
import $ from 'jquery'

/**
 * 銀行支店選択
 */

class SettingBankBranchInitials extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bankCode: this.props.match.params.code,
			initials: this.props.match.params.kana,
			branches: null,
			account: false,
			branchCode: null,
			back: false,
			disabled: 'disabled',
			selectCode: 0,
		}

		this.selectBranch = this.selectBranch.bind(this)
	}

	componentWillMount() {
		const { dispatch } = this.props
		dispatch(
			MyBankActions.getInitialBranch(
				this.state.bankCode,
				this.state.initials
			)
		)
	}

	getParentName(path) {
		return path.split('/').slice(0, -1).join('/')
	}

	handleSubmit(event) {
		event.preventDefault()

		let selectCode = this.state.selectCode

		this.setState({
			account: true,
			branchCode: selectCode,
		})
	}

	handleClick(event) {
		this.setState({
			back: true,
		})
	}

	selectBranch(payload) {
		let disabled = ''
		let selectCode = 0
		let selectBranch = null

		if (this.props.Bank.branch) {
			selectBranch = Array.prototype.filter.call(
				this.props.Bank.branch,
				(current, index) => {
					return current.siten_code == payload
				}
			)
		}

		if (selectBranch.length > 0) {
			selectCode = selectBranch[0].siten_code
		}

		if (selectCode !== 0) {
			disabled = ''
		} else {
			disabled = 'disabled'
		}
		this.setState({
			disabled: disabled,
			selectCode: selectCode,
		})
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('銀行口座情報編集')
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
		$('.menu-header').removeClass('hiddenClass')
	}

	render() {
		if (this.state.account) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -4)
						.join('/')}/account/${this.state.bankCode}/${this.state.branchCode
						}`}
				/>
			)
		}
		if (this.state.back) {
			return (
				<Redirect
					to={`${this.props.match.path
						.split('/')
						.slice(0, -3)
						.join('/')}/${this.state.bankCode}`}
				/>
			)
		}

		let katakanaListStyle = {
			marginRight: '1em',
		}

		let katakana = null
		if (this.state.katakana) {
			katakana = this.state.katakana
		}

		let branch = null
		var mobileBranch = []
		if (this.props.Bank.branch) {
			branch = Array.prototype.map.call(
				this.props.Bank.branch,
				(current, index) => {
					if (
						current.bank_code == this.props.Bank.bank[0].bank_code
					) {
						mobileBranch.push(current)
						return (
							<MenuItem
								value={current.siten_code}
								primaryText={current.siten_name}
							/>
						)
					}
				}
			)
		}

		let bank_name = ''
		if (this.props.Bank.bank.length) {
			bank_name = this.props.Bank.bank[0].bank_name
		}

		return (
			<div className="content bank-detail bank-branch-initial-setting">
				<h1 className="content__title">設定</h1>
				{/* <SettingMenu /> */}
				<RewardMenu />
				<div className="primary">
					<div className="hidden-mobile">
						<div className="primary-title">
							<h1 className="primary-title__main">
								銀行口座情報
							</h1>
						</div>
						<div className="primary__inner">
							<div className="bank-box">
								<table className="table-th-left">
									<tbody>
										<tr>
											<th className="table-align-left">
												銀行名
											</th>
											<td className="table-align-left">
												{bank_name}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="primary-title">
						<h1 className="primary-title__main">支店名選択</h1>
					</div>
					<div className="primary__inner">
						<div className="primary-content">
							<div className="primary-content__header">
								<div className="primary-content__header__left">
									銀行名
								</div>
								<div className="primary-content__header__right">
									{bank_name}
								</div>
							</div>
							<div className="primary-content__body">
								<div className="primary-title lv_2">
									<h2 className="primary-title__main display-mobile">
										支店名選択
									</h2>
									<p className="hidden-mobile">
										下記より該当する支店名を選択してください。
									</p>
									<form
										name="branchForm"
										onSubmit={(e) => this.handleSubmit(e)}
									>
										<SelectField
											value={this.state.selectCode}
											onChange={(e, k, p) =>
												this.selectBranch(p)
											}
											hintText="選択してください"
											className="hidden-mobile"
										>
											<MenuItem
												value={0}
												primaryText="選択してください"
											/>
											{branch}
										</SelectField>
										<DropDown
											placeHolder="選択してください"
											handleChange={this.selectBranch}
											selectedId={this.state.selectCode}
											list={mobileBranch}
											idField="siten_code"
											nameField="siten_name"
											className="display-mobile"
										/>
										<div>
											<input
												type="button"
												name="returnButton"
												className="btn-raised margin_right"
												onClick={(e) =>
													this.handleClick(e)
												}
												value="戻る"
											/>
											<input
												type="submit"
												name="nextButton"
												className="btn-raised color_default"
												value="次へ"
												disabled={this.state.disabled}
											/>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return state
}

export default connect(mapStateToProps)(SettingBankBranchInitials)
