/**
 * HOME画面のメインの表示部分
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import NextLogin from './NextLogin'

class HomeNextLogin extends Component {
	constructor(props) {
		super(props)
	}

	componentWillMount() {}

	render() {
		const { MyProfile } = this.props
		return (
			<div>
				<div className="bar ts-bar">
					<i className="material-icons bar-line__icons hidden-sp">
						schedule
					</i>
					<p className="bar-line__text ts-p">次回ログイン予定 :</p>
					<div className="bar-line-list-wrap ts-div2">
						<NextLogin show={true} MyProfile={MyProfile} />
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(HomeNextLogin)
