import React, { Component } from 'react'
import { Provider } from 'react-redux'
import App from '../../App'
import { BrowserRouter as Router } from 'react-router-dom'
import ScrollToTop from './ScrollTop'

export default class Root extends Component {
	render() {
		const { store } = this.props
		return (
			<Provider store={store}>
				<Router>
					<ScrollToTop>
						<App />
					</ScrollToTop>
				</Router>
			</Provider>
		)
	}
}
