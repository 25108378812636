import {
	REFRESH_HISTORY_UNREAD_UN_PIN,
	REQUEST_DELETE_MESSAGE_UNREAD,
	REQUEST_HISTORY_DEFAULT,
	REQUEST_HISTORY_UNREAD,
	REQUEST_HISTORY_UNREAD_CHECK,
	REQUEST_HISTORY_UNREAD_DEFAULT,
	REQUEST_HISTORY_UNREAD_REFRESH,
} from '../constants/ActionTypes'
import * as Fetch from '../util/Fetch'
import { getUserId } from '../assets/help/help'

const initialState = {
	loaded: false,
	data: {},
	checkUnread: ''
}

export const RequestHistoryUnRead = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_HISTORY_UNREAD: {
			let listNew = {}
			if (action.data.data) {
				if (action.data.data.length > 0) {
					action.data.data.map((item, key) => {
						Object.assign(listNew, { [getUserId(item)]: item })
					})
				}
			}
			if (action.data.isLoadmore) {
				listNew = {
					...state.data, ...listNew
				}
			}
			return {
				...state,
				loaded: true,
				data: listNew,
			}
		}
		case REQUEST_HISTORY_UNREAD_REFRESH: {
			return {
				...state,
				loaded: true,
				data: action.data,
			}
		}
		case REQUEST_HISTORY_UNREAD_DEFAULT: {
			return {
				...state,
				loaded: false,
				data: {},
			}
		}
		case REQUEST_HISTORY_UNREAD_CHECK: {
			return {
				...state,
				checkUnread: action.data
			}
		}
		case REFRESH_HISTORY_UNREAD_UN_PIN: {
			let listNew = state.data
			if (action.data) {
				if (action.data.length > 0) {
					action.data.map((item) => {
						Object.assign(listNew, { [getUserId(item)]: item })
					})
				}
			}
			const sortable2 = Object.entries(listNew)
				.sort(([, a], [, b]) => b.serverTime - a.serverTime)
				.reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
			return {
				...state,
				loaded: true,
				data: sortable2,
			}
		}
		case REQUEST_DELETE_MESSAGE_UNREAD: {
			delete state.data[getUserId(action.data)]
			return Object.assign({}, state, {
				loaded: true,
			})
		}
		default: {
			return state
		}
	}
}
