import {
    BANNER_WORD,
} from '../constants/ActionTypes'

const initialState = {
    data: [],
}

export const BannedWord = (state = initialState, action) => {
    switch (action.type) {
        case BANNER_WORD: {
            return {
                data: action.data,
            }
        }

        default: {
            return state
        }
    }
}
