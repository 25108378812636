/* eslint-disable no-unused-vars */
import React from 'react'
import { connect } from 'react-redux'
import { HIDE_PENDING_BELL_CALL, OPEN_PRE_CALL_SCREEN } from '../../../constants/ActionTypes'
import { ContainerIconPendingCall, ImageIconPendingCall } from '../StyledComponents/StyledDialogRequestCallComponents'
import useSound from '../../../hooks/useSound'
import { SOUNDS } from '../../../constants/Config'


const IconPendingCall = props => {
	const { playSound } = useSound()
	const { CallStoredData, openPreCallScreen, hidePendingBellCall } = props

	const isShow = CallStoredData.pendingCallIcon.isShow

	const handleShowRequestCall = () => {
		openPreCallScreen()
		hidePendingBellCall()

		playSound(SOUNDS.CALL.RINGING.NAME)
	}

	return (
		<>
			{isShow ? (
				<ContainerIconPendingCall onClick={handleShowRequestCall}>
					<ImageIconPendingCall />
				</ContainerIconPendingCall>
			) : null}
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		CallStoredData: state.Call,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		openPreCallScreen: () => dispatch({ type: OPEN_PRE_CALL_SCREEN }),
		hidePendingBellCall: () => dispatch({ type: HIDE_PENDING_BELL_CALL }),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(IconPendingCall)
