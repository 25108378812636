/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import { DEVICE } from '../../../util/StellaConstant'
import { Button, ButtonArea, Content, Title } from '../StyledComponents/StyledDialogConfirmEndcallComponents'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'


const DialogAlertMicro = props => {
	const classes = useStyles()
	const { showConfirm, closeConfirm } = props
	const [open, setOpen] = useState(true)

	useEffect(() => {
		setOpen(showConfirm)
	}, [showConfirm])

	const closeDialog = () => {
		setOpen(false)
		closeConfirm()
	}
	return (
		<Dialog
			open={open}
			// open={true}
			classes={{
				root: classes.root,
				paper: classes.paper,
			}}
			BackdropProps={{
				classes: {
					root: classes.backdrop,
				}
			}}
		>
			<IconButton
				aria-label="close"
				onClick={closeDialog}
				sx={{
					position: 'absolute',
					right: 7,
					top: 7,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			{/* <Title>通話を終了します</Title> */}
			<Content style={{ textAlign: 'center' }}>
				<p>通話のためにマイクの設定を許可し、一度ブラウザを更新してください。</p>
				<p>設定の変更については、Google Chromeをご利用の場合、<a href="https://support.google.com/chrome/answer/2693767?hl=ja&co=GENIE.Platform%3DDesktop" target="_blank">こちら</a>を参考してください。</p>
			</Content>
			<ButtonArea>
				<Button
					buttonType="ACCEPT"
					onClick={closeDialog}
				>
					OK
				</Button>
			</ButtonArea>
		</Dialog>
	)
}

const useStyles = makeStyles({
	root: {
		[`@media ${DEVICE.mobileS}`]: {

		},
		[`@media ${DEVICE.tablet}`]: {

		},
		[`@media ${DEVICE.laptop}`]: {
			left: '256px !important',
			width: 'calc(100% - 256px)',
			zIndex: '999999 !important',
		},
	},
	backdrop: {
		[`@media ${DEVICE.mobileS}`]: {

		},
		[`@media ${DEVICE.tablet}`]: {

		},
		[`@media ${DEVICE.laptop}`]: {
			left: '256px !important',
		},
	},
	paper: {
		[`@media ${DEVICE.mobileS}`]: {
			margin: '0 !important',
			width: 'calc(90% - 33px - 33px)',
			padding: '37px 33px 27px'
		},
		[`@media ${DEVICE.tablet}`]: {
			margin: '0 !important',
			width: 301,
			padding: '37px 33px 27px'
		},
		[`@media ${DEVICE.laptop}`]: {
			margin: '0 !important',
			width: 400,
			padding: '37px 33px 27px'
		},
	},
})

export default DialogAlertMicro
