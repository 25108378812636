import { Dialog } from 'material-ui';
import React, { useCallback, useEffect, useState } from 'react';
import Dropdown from 'react-dropdown'
import { Link } from 'react-router-dom';
import Loading from '../../Loading';
import './ModalReportUser.scss'

const ModalReportUser = (props) => {
    const [loading, setLoading] = useState(false)
    const [isShow, setShowDialog] = useState(false)
    const [disableBtn, setDisableBtn] = useState(true)
    const [showConfirmReport, setShowConfirmReport] = useState(false)
    const [reason, setReason] = useState(null)
    const [reasonReport, setReasonReport] = useState([])
    const defaultValue = {
        value: 0,
        label: "選択してください"
    }

    useEffect(() => {
        setShowDialog(props.isShowDialog)
    }, [props.isShowDialog])

    useEffect(() => {
        let data = []
        props.reasonReport.map((i, index) => {
            const obj = {
                value: index + 1,
                label: i
            }
            data.push(obj)
        })
        setReasonReport([defaultValue, ...data])
    }, [props.reasonReport])

    useEffect(() => {
        props.socket.on('userRequest', (res) => {
            const message = JSON.parse(res)
            if (message.msgType === 'RESPONSE_REQUEST_CHAT') {
                onClose()
            }
        })
    }, [])

    const onClose = () => {
        setReason(null)
        setShowDialog(false)
        setShowConfirmReport(false)
        props.onClose()
    }

    useEffect(() => {
        if (props.handleSuccessReport) {
            setLoading(false)
            setShowConfirmReport(true)
            setReason(null)
        }
    }, [props.handleSuccessReport])

    const onReport = () => {
        setLoading(true)
        props.handleReport(reason)

        setTimeout(() => {
            setLoading(false)
        }, 15000);
    }

    const onBlock = () => {
        props.onBlock()
    }

    const ModalConfirmReport = () => {
        return (
            <div>
                <p className='text-confirm'>通報ありがとうございました。</p>
                <p className='text-confirm' >事務局側で確認し、適宜対処を行います。</p>
                <p className='text-confirm' style={{ marginBlock: 15 }}>ユーザーからの嫌がらせを受けているなどの場合は、このままユーザーをブロックすることができます。</p>
                <p className='text-confirm'><span>通報理由が不適切であり、かつブロックもされていた場合、「不当なブロック」と判断されペナルティの対象になる場合もございます</span>のでご注意ください。</p>
                <div style={{ display: 'ruby-text', marginTop: 25 }}>
                    <button className='btn-report' onClick={onBlock}>ブロックする</button>
                    <button className='btn-cancel' onClick={onClose}>もどる</button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (reason) {
            setDisableBtn(reason.value === defaultValue.value)
            return
        }
        setDisableBtn(true)
    }, [reason])

    const ModalReport = () => {
        return (
            <>
                <div className='title-modal'>ユーザーを通報する</div>
                <div className='border' />
                <div className='info-content'>
                    <div className='info-user'>
                        <p style={{ width: "50%" }}>ユーザー名</p>
                        <div >{props.userInfo.userName}</div>
                    </div>
                    <div className='info-user'>
                        <p style={{ width: "50%", marginBlock: 5 }}>チャット相談回数</p>
                        <div >{props.userInfo.numberChatTimes}回</div>
                    </div>
                    <div className='info-user'>
                        <p style={{ width: "50%" }}>電話相談回数</p>
                        <div >{props.userInfo.numberCallTimes}回</div>
                    </div>
                </div>
                <div className='border' />
                <div className='title-reason'>通報理由</div>
                <Dropdown
                    value={reasonReport[0]}
                    className="dropdown-report"
                    options={reasonReport}
                    onChange={(e) => {
                        setReason(e)
                    }}
                    placeholder="選択してください" />
                <div style={{ color: '#b1b1b1de', fontSize: 12 }}>※上記に該当する項目がない場合、通報は認められない可能性がございます。</div>
                <div style={{ alignItems: 'center', display: "ruby-text" }}>
                    <button onClick={onReport}
                        disabled={disableBtn}
                        className='btn-report'
                        style={disableBtn ? { backgroundColor: 'rgba(229, 229, 229, 1', color: 'rgba(0, 0, 0, 0.38)' } : {}}>
                        このユーザーを通報する
                    </button>
                </div>
                <p className='text-report' style={{ color: '#000000de' }}>通報が認められるのは<span>「嫌がらせ」や「不適切な発言」を受けている、</span></p>
                <p className='text-report' style={{ color: '#000000de' }}>あるいはユーザーが <span>「未成年など鑑定ができない」</span>などの場合です。</p>
                <p className='text-report' style={{ marginTop: 10 }}>以下のような理由では通報は認められません。</p>
                <p className='text-report'>・挨拶がなかった </p>
                <p className='text-report'>・レビューが気に入らなかった </p>
                <p className='text-report'>・ユーザーの所持ポイントが少なすぎる </p>
                <p className='text-report'>・返事がない / すぐ退出された </p>
                <p className='text-report'>・ユーザーの態度が気に障った </p>
                <p className='text-report'>・波長が合わない</p>
                <p className='text-report'>・同業者である</p>
                <p className='text-report' style={{ marginTop: 10, color: '#000000de' }}>判断に迷われる場合は、お気軽に<Link to='/home/contact'>お問い合わせフォーム</Link>よりご連絡くださいませ。</p>
            </>
        )
    }

    return (
        <>
            <Dialog modal={false}
                open={isShow}
                className="dialogBlock"
                bodyStyle={{ height: 800 }}
                contentStyle={{ width: '90%' }}>
                <i
                    className="material-icons tab__icons closeBlock"
                    onClick={onClose}
                >
                    clear
                </i>
                {!showConfirmReport && ModalReport()}
                {showConfirmReport && ModalConfirmReport()}
            </Dialog>
            <Loading display={loading} />
        </>
    )
}

export default ModalReportUser