import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { isPWA } from '../helper/helpFunction'
import { ModalMissingAnswer } from './Modals/ModalAnswer/ModalMissingAnswer'
import { ANSWER_TELLER } from '../constants/Config'
import DialogNotifi from './Dialog'
import * as Fetch from '../util/Fetch'

class AnswerMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showModalMissingAnswer: false,
		}
	}

	resetToggleMissing = () => {
		this.setState({
			showModalMissingAnswer: false,
		})
		window.open("/home", "_parent")
	}

	componentDidMount() {
		const { MyProfile } = this.props
		if (!MyProfile.data.isAllowChangeStatus && !isPWA()) {
			this.setState({
				showModalMissingAnswer: true
			})
		}

		if (!Fetch.isAgeVerify(MyProfile.data.verifyAge) && !isPWA() && MyProfile.data.isAllowChangeStatus) {
			DialogNotifi.getDialog().handleOpen('AGEAUTH')
		}
	}

	render() {
		const { AnswerManagement } = this.props
		const showPopUpBestAnswer = localStorage.getItem('showPopUpBestAnswer')
		let pathName = window.location.pathname
		let answer =
			'btn-tab' + (pathName === '/home/answer' ? ' is-active' : '')
		let bestAnswer =
			'btn-tab' +
			(pathName.indexOf('/home/answer/best-answer') !== -1
				? ' is-active'
				: '')
		let answerLog =
			'btn-tab' +
			(pathName.indexOf('/home/answer/answer-log') !== -1
				? ' is-active'
				: '')

		return (
			<div>
				<nav
					className={
						pathName === '/home/answer' &&
						showPopUpBestAnswer === 'true'
							? 'lead-nav answer-nav lead-nav-answer'
							: 'lead-nav answer-nav'
					}
				>
					<ul className="list lead_nav">
						<li className="list__item">
							<Link className={answer} to="/home/answer">
								すべての相談
							</Link>
						</li>
						{isPWA() && 
							<li className="list__item">
								<Link
									className={bestAnswer}
									to="/home/answer/best-answer"
									data-badge={
										AnswerManagement.numberUnreadBestAnswer
									}
								>
									ベストアンサー{' '}
								</Link>
							</li>
						}
						<li className="list__item">
							<Link
								className={answerLog}
								to="/home/answer/answer-log"
							>
								回答履歴
							</Link>
						</li>
					</ul>
				</nav>
				<ModalMissingAnswer
					show={this.state.showModalMissingAnswer}
					resetToggle={this.resetToggleMissing}
				/>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(AnswerMenu)
