import React, { Component } from 'react'
import CheckBoxCM from './CheckBoxCM'
import $ from 'jquery'

const worriesData = {
	worries1: { title: '相性', value: '1' },
	worries2: { title: '結婚', value: '2' },
	worries3: { title: '離婚', value: '3' },
	worries4: { title: '夫婦仲', value: '4' },
	worries5: { title: '復縁', value: '5' },
	worries6: { title: '不倫', value: '6' },
	worries7: { title: '縁結び', value: '7' },
	worries8: { title: '縁切り', value: '8' },
	worries9: { title: '遠距離恋愛', value: '9' },
	worries10: { title: '同性愛', value: '10' },
	worries11: { title: '三角関係', value: '11' },
	worries12: { title: '金運', value: '12' },
	worries13: { title: '仕事', value: '13' },
	worries14: { title: '起業', value: '14' },
	worries15: { title: '転職', value: '15' },
	worries16: { title: '対人関係', value: '16' },
	worries17: { title: '自分の気持ち', value: '17' },
	worries18: { title: '相手の気持ち', value: '18' },
	worries19: { title: '家庭問題', value: '19' },
	worries20: { title: '運勢', value: '20' },
	worries21: { title: '開運方法', value: '21' },
}

class DropdownListWorries extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dropDownShow_worries: false,
			hoverdropdown: false,
			worries: [],
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.defaultValue !== this.state.worries) {
			this.setState({
				worries: nextProps.defaultValue,
			})
		}
		return true
	}

	handleHiddenShowBox = (event) => {
		let checkClassActive = $(
			'.dropdown-worries-botoom-92797[data-expand=true][data-hoverdropdown=false]'
		).hasClass('open')
		if (checkClassActive) {
			this.setState({
				dropDownShow_worries: false,
			})
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', (event) =>
			this.handleHiddenShowBox(event)
		)
	}

	handleMoveInBoxDropdown = () => {
		this.setState({
			hoverdropdown: !this.state.hoverdropdown,
		})
	}

	changeValue = (value) => {
		let listData = this.state.worries
		if (listData.indexOf(value) < 0) {
			listData.push(value)
		} else {
			listData.splice(listData.indexOf(value), 1)
		}
		this.setState({
			worries: listData,
		})
		this.props.onData(listData)
	}

	dropDownShow = (name, value) => {
		this.setState({
			[name]: !value,
			hoverdropdown: true,
		})
	}

	render() {
		let listUserData = this.state.worries
		return (
			<div className="row-box-inline-CM-child">
				<div className="form-inline-row-CM">
					{this.props.labelHidden ? null : (
						<label className="label-text-92797 ml-title-worries">
							今の悩み
						</label>
					)}
					<div
						className={
							'dropdown-worries-botoom-92797 ' +
							(this.state.dropDownShow_worries ? 'open' : '') +
							''
						}
						onMouseLeave={(event) =>
							this.handleMoveInBoxDropdown(
								this.state.hoverdropdown
							)
						}
						onMouseEnter={(event) =>
							this.handleMoveInBoxDropdown(
								this.state.hoverdropdown
							)
						}
						data-hoverdropdown={this.state.hoverdropdown}
						data-expand={this.state.dropDownShow_worries}
					>
						<span
							className="label-text-92797 point-click"
							onClick={() =>
								this.dropDownShow(
									'dropDownShow_worries',
									this.state.dropDownShow_worries
								)
							}
						>
							{listUserData.length > 0 &&
							listUserData.length !== 21 ? (
								<span className={'text-color-dropdown-green'}>
									指定あり
								</span>
							) : (
								'すべて'
							)}
							<span className="caret" />
						</span>
						<div className="dropdown-box-show">
							<div className="row-box-dropdown-CM-worries row">
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries1.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries1.value
												)
											}
											titleText={
												worriesData.worries1.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries2.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries2.value
												)
											}
											titleText={
												worriesData.worries2.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries3.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries3.value
												)
											}
											titleText={
												worriesData.worries3.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries4.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries4.value
												)
											}
											titleText={
												worriesData.worries4.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries5.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries5.value
												)
											}
											titleText={
												worriesData.worries5.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries6.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries6.value
												)
											}
											titleText={
												worriesData.worries6.title
											}
											locationText={true}
										/>
									</label>
								</div>
							</div>
							<div className="row-box-dropdown-CM-worries row">
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries7.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries7.value
												)
											}
											titleText={
												worriesData.worries7.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries8.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries8.value
												)
											}
											titleText={
												worriesData.worries8.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries9.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries9.value
												)
											}
											titleText={
												worriesData.worries9.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries10.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries10.value
												)
											}
											titleText={
												worriesData.worries10.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries11.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries11.value
												)
											}
											titleText={
												worriesData.worries11.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries12.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries12.value
												)
											}
											titleText={
												worriesData.worries12.title
											}
											locationText={true}
										/>
									</label>
								</div>
							</div>
							<div className="row-box-dropdown-CM-worries row">
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries13.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries13.value
												)
											}
											titleText={
												worriesData.worries13.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries14.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries14.value
												)
											}
											titleText={
												worriesData.worries14.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries15.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries15.value
												)
											}
											titleText={
												worriesData.worries15.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries16.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries16.value
												)
											}
											titleText={
												worriesData.worries16.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries17.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries17.value
												)
											}
											titleText={
												worriesData.worries17.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries18.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries18.value
												)
											}
											titleText={
												worriesData.worries18.title
											}
											locationText={true}
										/>
									</label>
								</div>
							</div>
							<div className="row-box-dropdown-CM-worries row">
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries19.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries19.value
												)
											}
											titleText={
												worriesData.worries19.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries20.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries20.value
												)
											}
											titleText={
												worriesData.worries20.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797">
										<CheckBoxCM
											defaultValue={
												listUserData !== undefined &&
												listUserData.indexOf(
													worriesData.worries21.value
												) > -1
											}
											onData={() =>
												this.changeValue(
													worriesData.worries21.value
												)
											}
											titleText={
												worriesData.worries21.title
											}
											locationText={true}
										/>
									</label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797"></label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797"></label>
								</div>
								<div className="form-inline-row-CM col-md-2">
									<label className="label-text-92797"></label>
								</div>
							</div>
						</div>
					</div>
					<div
						className={
							this.props.hiddenWorries
								? 'box-worries-value hidden'
								: 'box-worries-value'
						}
					>
						{this.state.worries.length > 0 &&
						this.state.worries.length < 21
							? this.state.worries.map((item, key) => {
									let stringValue = 'worries' + item
									return (
										<span key={key}>
											{worriesData[stringValue].title}
											{key < this.state.worries.length - 1
												? ', '
												: ''}
										</span>
									)
							  })
							: null}
					</div>
				</div>
			</div>
		)
	}
}

export default DropdownListWorries
