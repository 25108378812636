import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class PasswordAnnounce extends Component {
	render() {
		return (
			<div class="announce">
				<div
					className="img_announce"
					style={{
						marginLeft: 'auto',
						marginRight: 'auto',
						maxWidth: 255,
					}}
				>
					<Link className="sitelogo" to="/login">
						<img
							className="sitelogo__image"
							src="/img/stella_logo_color.png"
							alt="ステラロゴ"
						/>
					</Link>
				</div>
				<div className="content__inner password_announce">
					<div className="primary">
						<div className="primary-title layout_settings-result">
							<h1
								className="primary-title__main"
								style={{ color: 'rgba(17, 173, 157, 1)' }}
							>
								パスワード再設定完了
							</h1>
							<div className="primary__inner layout_settings-result">
								<p className="noti__reset-pw-success">
									パスワードの再設定が完了しました。 <br />
									新しい情報で再ログインしてください。
								</p>
								<div className="btn-wrap">
									<Link to="/login">
										<button className="btn-raised color_default spread_width">
											ログインに戻る
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default PasswordAnnounce
