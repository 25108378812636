/**
 * 通話・チャットリクエスト（左カラムの対応中(inConversation:true)、相談依頼申請一覧(inConversation:false)）
 */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { isPWA } from '../helper/helpFunction'

export default class LeftFollowerList extends Component {
	render() {
		const { chatUserId, listData } = this.props
		moment.locale('ja')

		return (
			<div>
				{listData.length > 0 ? (
					listData.map((item, key) => {
						return (
							<Link
								key={key}
								className="list__item"
								style={{ width: '100%' }}
								to={`/home/chat/${item.userId}`}
							>
								<div
									className={
										chatUserId === item.userId
											? 'btn-customer customer_request-choose'
											: 'btn-customer customer_default'
									}
								>
									<p className="btn-customer__item is_name" style={isPWA() ? {} : { maxWidth: 130 }}>
										{item.userName}
										{item.isNew === true ? (
											<span
												className={
													'text-new-follower-area'
												}
											>
												NEW
											</span>
										) : null}
									</p>
									<p className="btn-customer__item is_time">
										{moment(
											item.favoriteTime,
											'YYYYMMDDHmmss',
											'ja'
										)
											.add(9, 'hours')
											.fromNow()}
									</p>
								</div>
							</Link>
						)
					})
				) : (
					<p className={'text-white padding-text'}>
						フォロワーはいません。お客様があなたをお気に入り登録すると追加されます。
					</p>
				)}
			</div>
		)
	}
}
