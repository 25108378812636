import { glasConfig } from "../constants/Config"
import request from 'axios'
import { BANNER_WORD } from "../constants/ActionTypes"

const resultSuccess = (type, data) => {
    return {
        type: type,
        data,
    }
}

export const getBannedWord = (type) => {
    return (dispatch) => {
        const token = localStorage.getItem('token')
        const url = glasConfig.url_base + glasConfig.get_banned_word + type

        const options = {
            method: 'GET',
            url: url,
            headers: {
                Authorization: token,
                'Content-Type': 'application/json',
            },
            json: true,
        }
        return request(options)
            .then((response) => {
                if (response.data.code === 0) {
                    const data = response.data.data
                    dispatch(resultSuccess(BANNER_WORD, data))
                } else {
                    throw 'システムエラー'
                }
            })
            .catch((error) => {
                throw error
            })
    }
}
