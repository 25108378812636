import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
	glasConfig,
	representativeBank,
	katakanaList,
} from '../constants/Config'
import request from 'axios'
import SettingMenu from './SettingMenu'
import RewardMenu from './RewardMenu'
import * as MyBankActions from '../actions/MyBankActions'
import $ from 'jquery'

/**
 * 銀行選択
 */

class SettingBank extends Component {
	constructor(props) {
		super(props)
		this.state = {
			initials: '',
			hidden: false,
		}
	}

	componentWillMount() {
		const { dispatch } = this.props
		request
			.get(glasConfig.url_outside + glasConfig.path_bank_account, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					if (
						!response.data.data.accountHolder ||
						!response.data.data.accountNumber ||
						!response.data.data.bankcode ||
						!response.data.data.sitencode
					) {
						this.setState({
							hidden: true,
						})
					}
				}
			})
		dispatch(MyBankActions.getBank())
		dispatch(MyBankActions.getListBank())
	}



	componentWillReceiveProps(nextProps) {
		let initials = []
		let banks = nextProps.Bank.bankInitial

		let re = /([^A-Za-z]).*/
		if (Array.isArray(banks)) {
			banks.forEach((current, index) => {
				let matches = current.bank_furi.match(re)
				let zenkaku = this.toZenkakuKatakana(matches[1])
				if (initials.indexOf(zenkaku) === -1) {
					initials.push(zenkaku)
				}
			})
		}

		initials.sort((a, b) => {
			a = this.toKatakanaHiragana(a.toString())
			b = this.toKatakanaHiragana(b.toString())
			if (a < b) {
				return -1
			} else if (a > b) {
				return 1
			}
			return 0
		})

		this.getKatakanaInitial(initials)
	}

	componentDidMount() {
		$('.secondary-header').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('銀行口座情報編集')
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
		$('.menu-header').removeClass('hiddenClass')
	}

	toZenkakuKatakana(str) {
		let ret = []

		let m = {
			0xff61: 0x300c,
			0xff62: 0x300d,
			0xff63: 0x3002,
			0xff64: 0x3001,
			0xff65: 0x30fb, // 。「」、・
			0xff67: 0x30a1,
			0xff68: 0x30a3,
			0xff69: 0x30a5,
			0xff6a: 0x30a7,
			0xff6b: 0x30a9, // ァ
			0xff6c: 0x30e3,
			0xff6d: 0x30e5,
			0xff6e: 0x30e7,
			0xff6f: 0x30c3,
			0xff70: 0x30fc, // ャュョッー
			0xff71: 0x30a2,
			0xff72: 0x30a4,
			0xff73: 0x30a6,
			0xff74: 0x30a8,
			0xff75: 0x30aa, // ア
			0xff76: 0x30ab,
			0xff77: 0x30ad,
			0xff78: 0x30af,
			0xff79: 0x30b1,
			0xff7a: 0x30b3, // カ
			0xff7b: 0x30b5,
			0xff7c: 0x30b7,
			0xff7d: 0x30b9,
			0xff7e: 0x30bb,
			0xff7f: 0x30bd, // サ
			0xff80: 0x30bf,
			0xff81: 0x30c1,
			0xff82: 0x30c4,
			0xff83: 0x30c6,
			0xff84: 0x30c8, // タ
			0xff85: 0x30ca,
			0xff86: 0x30cb,
			0xff87: 0x30cc,
			0xff88: 0x30cd,
			0xff89: 0x30ce, // ナ
			0xff8a: 0x30cf,
			0xff8b: 0x30d2,
			0xff8c: 0x30d5,
			0xff8d: 0x30d8,
			0xff8e: 0x30db, // ハ
			0xff8f: 0x30de,
			0xff90: 0x30df,
			0xff91: 0x30e0,
			0xff92: 0x30e1,
			0xff93: 0x30e2, // マ
			0xff94: 0x30e4,
			0xff95: 0x30e6,
			0xff96: 0x30e8, // ヤ
			0xff97: 0x30e9,
			0xff98: 0x30ea,
			0xff99: 0x30eb,
			0xff9a: 0x30ec,
			0xff9b: 0x30ed, // ラ
			0xff9c: 0x30ef,
			0xff66: 0x30f2,
			0xff9d: 0x30f3, // ワヲン
			0xff9e: 0x309b,
			0xff9f: 0x309c, // 濁点、半濁点
		}

		let c = str.charCodeAt(0)
		ret.push(m[c] || ret)
		return String.fromCharCode.apply(null, ret)
	}

	toKatakanaHiragana(src) {
		return src.replace(/[\u30a1-\u30f6]/g, (match) => {
			let chr = match.charCodeAt(0) - 0x60
			return String.fromCharCode(chr)
		})
	}

	getKatakanaInitial(initials) {
		let parentPath = this.getParentName(this.props.match.path)

		let katakana = Array.prototype.map.call(
			katakanaList,
			(current, index) => {
				if (initials.indexOf(current.katakana) !== -1) {
					return (
						<li
							className="list__item"
							key={current.katakana + index}
						>
							<Link
								to={{
									pathname: `${parentPath}/initials/${current.initials}`,
								}}
								className="bank-link width_short"
							>
								{current.katakana}
							</Link>
						</li>
					)
				} else {
					return (
						<li
							className="list__item"
							key={current.katakana + index}
						>
							<span className="bank-link width_short">
								{current.katakana}
							</span>
						</li>
					)
				}
			}
		)

		this.setState({
			katakana: katakana,
		})
	}

	getParentName(path) {
		return path.split('/').slice(0, -1).join('/')
	}

	saveBankCode = (bankCode) => {
		sessionStorage.setItem('bankCode', bankCode)
	}

	render() {
		let parentPath = this.getParentName(this.props.match.path)
		let bank = this.props.Bank.listBank.map(
			(item, index) => {
				return (
					<li
						className="list__item"
						onClick={() => this.saveBankCode(item.bank_code)}
						key={item.bank_code}
					>
						<Link
							to={{
								pathname: `${parentPath}/branches/${item.bank_code}`,
							}}
							className="bank-link width_long"
						>
							{item.bank_name}
						</Link>
					</li>
				)
			}
		)

		let katakana = null
		if (this.state.katakana) {
			katakana = this.state.katakana
		}

		return (
			<div className="content bank-detail bank-setting">
				<h1 className="content__title">設定</h1>
				{/* <SettingMenu /> */}
				<RewardMenu />
				<div className="primary">
					<div className="primary-title">
						<h1 className="primary-title__main">銀行口座情報</h1>
					</div>
					<div className="primary__inner">
						<div className="primary-content">
							{this.state.hidden && (
								<div className="primary-content__header">
									<p>
										報酬の振込先銀行口座を登録してください。
									</p>
								</div>
							)}
							<div className="primary-content__body">
								<div className="primary-title lv_2">
									<h2 className="primary-title__main">
										銀行名を選択
									</h2>
									<ul className="list flex_start margin_bottom">
										{bank}
									</ul>
								</div>
								<div className="primary-title lv_2">
									<h2 className="primary-title__main">
										上記以外の銀行の場合
										<span className="hidden-mobile">
											は、下記より該当する銀行名の先頭文字を選択してください。
										</span>
									</h2>
									<ul className="list japanese_syllabary margin_bottom">
										{katakana}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return { Bank: state.Bank }
}

export default connect(mapStateToProps)(SettingBank)
