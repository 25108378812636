import React, { Component } from 'react'
import { PrivateRoute } from './PrivateRoute'
import CustomerManagement from './CustomerManagement'
import CustomerManagementFollower from './CustomerManagementFollower'
import CustomerManagementNotificationHistory from './CustomerManagementNotificationHistory.js'
import CustomerManagementCreateNotification from './CustomerManagementCreateNotification'
import CustomerManagementEditNotification from './CustomerManagementEditNotification'
import CustomerManagementBlock from './CustomerManagementBlock'

class RouteCustomerManagement extends Component {
	componentWillMount() {
		this.props.activeItem(3)
	}

	render() {
		return (
			<span>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}`}
					component={CustomerManagement}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/follower`}
					component={CustomerManagementFollower}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/notification`}
					component={CustomerManagementNotificationHistory}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/notification/create`}
					component={CustomerManagementCreateNotification}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/notification/edit/:notificationId`}
					component={CustomerManagementEditNotification}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/block`}
					component={CustomerManagementBlock}
				/>
			</span>
		)
	}
}

export default RouteCustomerManagement
