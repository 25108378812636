import React, { Component } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/es'
registerLocale('ja', ja)

class DatePickerCM extends Component {
	constructor(props) {
		super(props)
		this.state = {
			startDate: '',
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (this.state.startDate !== nextProps.defaultValue) {
			this.setState({
				startDate: nextProps.defaultValue,
			})
		}
		return true
	}

	handleChange(value) {
		if (value === null) {
			value = ''
		}
		/*this.setState({
            startDate: value,
        });*/
		this.props.onData(value)
	}

	render() {
		return (
			<div
				className={
					'form-inline-row-CM box-input-range-92798 ' +
					this.props.responsiveMobile
				}
			>
				{this.props.labelHidden ? null : (
					<label
						className={
							'label-text-92797 ' + this.props.classLabelTop !==
							undefined
								? this.props.classLabelTop + ' label-text-92797'
								: 'label-text-92797'
						}
					>
						{this.props.labelText !== undefined
							? this.props.labelText
							: ''}
					</label>
				)}
				<DatePicker
					locale="ja"
					selected={this.state.startDate}
					onChange={(value) => this.handleChange(value)}
					dateFormat={'yyyy/MM/dd'}
					// peekNextMonth
					// showMonthDropdown
					// showYearDropdown
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
				/>
				{this.props.iconRight !== undefined ? (
					<span className="glyphicon glyphicon-calendar label-text-92797 label-date-92797" />
				) : null}
				{this.props.middleLine !== undefined ? (
					<span className="label-text-92797">~</span>
				) : null}
			</div>
		)
	}
}

export default DatePickerCM
