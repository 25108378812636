import {
	OPEN_PRE_CALL_SCREEN,
	CHANGE_JOINSTATE,
	CLOSE_PRE_CALL_SCREEN,
	OPEN_IN_CALL_SCREEN,
	CLOSE_IN_CALL_SCREEN,
	UPDATE_CALL_PARAMS,
	GET_AGORA_CONFIGURATION,
	SHOW_PENDING_BELL_CALL,
	HIDE_PENDING_BELL_CALL,
	RESET_CALL_PARAMS
} from '../constants/ActionTypes'
import { AGORA, CALL_SCREEN } from '../constants/CallConstants'

const initialState = {
	agoraConfigurations: {},
	error: '',
	pendingCallIcon: { isShow: false },
	preCall: {
		data: {},
		status: CALL_SCREEN.STATUS.INACTIVE,
	},
	inCall: {
		data: {},
		status: CALL_SCREEN.STATUS.INACTIVE,
	},
	callParams: {
		appId: null,
		owner: null,
		caller: null,
		callee: null,
		messageType: null,
		sessionId: null,
		socketId: null,
		tokenId: null,
		uid: null,
		callType: null,
		callerName: null,
		timeStamp: null,
		timeoutSettings: {
			pong: null,
			makeCall: null,
			connect: null,
			ringing: null,
			receiverNoAnswer: null,
			pickup: null,
			ringingExpire: null,
		},
	},
}

export const Call = (state = initialState, action) => {
	switch (action.type) {
	case OPEN_PRE_CALL_SCREEN: {
		return {
			...state,
			preCall: {
				...state.preCall,
				status: CALL_SCREEN.STATUS.ACTIVE,
			},
		}
	}
	case CLOSE_PRE_CALL_SCREEN: {
		return {
			...state,
			preCall: {
				...state.preCall,
				status: CALL_SCREEN.STATUS.INACTIVE,
			},
		}
	}
	case OPEN_IN_CALL_SCREEN: {
		return {
			...state,
			inCall: { ...state.inCall, status: CALL_SCREEN.STATUS.ACTIVE },
		}
	}
	case CLOSE_IN_CALL_SCREEN: {
		return {
			...state,
			inCall: {
				...state.inCall,
				status: CALL_SCREEN.STATUS.INACTIVE,
			},
		}
	}
	case SHOW_PENDING_BELL_CALL: {
		return {
			...state,
			pendingCallIcon: { ...state.pendingCallIcon, isShow: true },
		}
	}
	case HIDE_PENDING_BELL_CALL: {
		return {
			...state,
			pendingCallIcon: { ...state.pendingCallIcon, isShow: false },
		}
	}
	case UPDATE_CALL_PARAMS: {
		return {
			...state,
			callParams: { ...state.callParams, ...action.data },
		}
	}
	case GET_AGORA_CONFIGURATION: {
		return {
			...state,
			agoraConfigurations: action.data,
			callParams: {
				...state.callParams,
				appId: action.data.agoraAppId,
			},
		}
	}
	case RESET_CALL_PARAMS: {
		return {
			...state,
			callParams: {
				...state.callParams,
				owner: null,
				caller: null,
				callee: null,
				messageType: null,
				sessionId: null,
				socketId: null,
				tokenId: null,
				uid: null,
				callType: null,
				callerName: null,
				timeStamp: null,
				timeoutSettings: {
					pong: null,
					makeCall: null,
					connect: null,
					ringing: null,
					receiverNoAnswer: null,
					pickup: null,
					ringingExpire: null,
				},
			},
		}
	}
	case CHANGE_JOINSTATE: {
		return {
			...state,
			joinState:action.data
		}
	}
	default: {
		return state
	}
	}
}
