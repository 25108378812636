import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class StaffBlogPager extends Component {
	getPager(page, totalpages) {
		const numPager = totalpages <= 5 ? totalpages : 5
		let startPager = totalpages <= 5 || page <= 2 ? 1 : page - 2
		let endPager = 0
		if (totalpages - page < 2) {
			endPager = totalpages
			startPager = endPager - 5 <= 0 ? 1 : endPager - 5 + 1
		} else {
			endPager = numPager < 5 ? numPager : startPager + 5 - 1
		}
		let pageList = {}
		const nowpage = Number(page)
		for (let i = startPager; i <= endPager; i++) {
			pageList[i] = nowpage === i ? 'btn is-active' : 'btn'
		}
		return pageList
	}

	render() {
		const { pager, page, totalpages, category } = this.props

		if (pager !== true) {
			return null
		}

		const nowpage = Number(page)
		const lastpage = Number(totalpages)
		const pageList = this.getPager(nowpage, lastpage)

		return (
			<div className="pager">
				<ul className="list">
					<li className="list__item">
						<Link
							className="btn"
							disabled={nowpage === 1}
							to={`/home/staffblog/1/${category}`}
						>
							<i className="material-icons">first_page</i>
						</Link>
					</li>
					<li className="list__item">
						<Link
							className="btn"
							disabled={nowpage === 1}
							to={`/home/staffblog/${nowpage - 1}/${category}`}
						>
							<i className="material-icons">navigate_before</i>
						</Link>
					</li>
					{Object.keys(pageList).map((i) => {
						return (
							<li key={i} className="list__item">
								<Link
									className={pageList[i]}
									to={`/home/staffblog/${i}/${category}`}
								>
									{i}
								</Link>
							</li>
						)
					})}
					<li className="list__item">
						<Link
							className="btn"
							disabled={nowpage === lastpage}
							to={`/home/staffblog/${nowpage + 1}/${category}`}
						>
							<i className="material-icons">navigate_next</i>
						</Link>
					</li>
					<li className="list__item">
						<Link
							className="btn"
							disabled={nowpage === lastpage}
							to={`/home/staffblog/${lastpage}/${category}`}
						>
							<i className="material-icons">last_page</i>
						</Link>
					</li>
				</ul>
			</div>
		)
	}
}
