/**
 * 左メニュー
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import LeftProfile from './LeftProfile'
import LeftHistory from './LeftHistory'
import LeftRequest from './LeftRequest'
import LeftFollower from './LeftFollower'
import HeaderNotifi from './HeaderNotifi'
import { glasConfig, fortuneTellerStatusToString } from '../constants/Config'
import * as ChatRequestActions from '../actions/ChatRequestActions'
import * as FollowersActions from '../actions/FollowersActions'
import * as RequestHistoryActions from '../actions/RequestHistoryActions'
import * as MyProfileActions from '../actions/MyProfileActions'
import * as ChatNumberTotalAction from '../actions/ChatNumberTotalAction'
import * as UserInfoAction from '../actions/UserInfoActions'
import FontIcon from 'material-ui/FontIcon'
import { Navbar } from 'react-bootstrap'
import Badge from 'material-ui/Badge'
import NotificationsIcon from 'material-ui/svg-icons/social/notifications'
import request from 'axios'
import $ from 'jquery'
import { CALL_SCREEN } from '../constants/CallConstants'
class LeftMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: 'Request',
			showLeftMenu: false,
			showNotifiLog: false,
			showUserInfo: false,
			UserData: '',
			registBank: false,
			questionData: {},
			questionId: null,
		}
	}

	componentWillMount() {
		// this.loadItem(this.state.show);
		// const { dispatch } = this.props;
		request
			.get(glasConfig.url_outside + glasConfig.path_bank_account, {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					if (
						!response.data.data.accountHolder ||
						!response.data.data.accountNumber ||
						!response.data.data.bankcode ||
						!response.data.data.sitencode
					) {
						this.setState({
							registBank: false,
						})
					} else {
						this.setState({
							registBank: true,
						})
					}
				}
			})
	}

	showItem(e, item) {
		e.stopPropagation()
		// this.loadItem(item);
		this.setState({
			show: item,
		})
	}

	loadItem(item) {
		const { dispatch, RequestOffline, ChatRequest } = this.props
		switch (item) {
			case 'Request': {
				if (!ChatRequest.loaded) {
					dispatch(ChatRequestActions.fetch({ page: 0, size: 100 }))
				}
				/*if (!RequestOffline.loaded) {
		  dispatch(RequestOfflineActions.fetch())
		}*/
				break
			}
			case 'History': {
				dispatch(
					RequestHistoryActions.fetch({
						page: 0,
						size: glasConfig.leftmenu_per_page_history,
						inConversation: false,
					})
				)
				break
			}
			case 'Follower': {
				dispatch(
					FollowersActions.fetch({
						page: 0,
						size: glasConfig.leftmenu_maxlength_follower,
						sortByCreatedAt: glasConfig.sortASC,
					})
				)
				break
			}
			default: {
				return
			}
		}
	}

	handeleBadge(kind) {
		const { dispatch, MyProfile } = this.props
		if (MyProfile.data[kind] !== 0) {
			dispatch(
				MyProfileActions.merge({
					[kind]: 0,
				})
			)
		}
		if (kind === 'numberUnreadNotification') {
			this.setState({
				showNotifiLog: !this.state.showNotifiLog,
			})
		}
	}

	showNavLeft = () => {
		this.setState({ showLeftMenu: true })
	}

	closeNavLeft = () => {
		this.setState({ showLeftMenu: false })
	}

	showUserInfo = () => {
		$('.chat-box-customer').addClass('show_chat-box-customer')
		$('.chat-memo-history').addClass('hiddenNotImportant')
		$('.chat-call-history').addClass('hiddenNotImportant')
		$('body').addClass('noScroll')
	}

	showLogMemory = () => {
		$('.chat-box-customer').addClass('show_chat-box-customer')
		$('.chat-karte-list-wrap').addClass('hiddenClass')
		$('.chat-memo-history__nav').addClass('hiddenClass')
		$('.chat-memo__fullscreen').addClass('hiddenClass')
		$('body').addClass('noScroll')
	}

	showUserInfoAnswer = () => {
		const className = $('.content').attr('class')
		if (className.includes('hidden-sp')) {
			$('.formAnswer').addClass('hidden-sp')
		}
		$('.answer-user-profile-sp').addClass('show_answer-user-profile-sp')
	}

	componentWillReceiveProps(nextProps) {
		const { userId } = nextProps

		if (!userId || userId === this.props.userId) {
			return
		}
		this.props.dispatch(UserInfoAction.fetchProfile(userId))
	}

	goBack = () => {
		if (window.location.pathname.includes('bank/list')) {
			if (this.state.registBank) {
				window.location.pathname = '/home/setting/bank/top'
			} else {
				window.history.back()
			}
		} else if (window.location.pathname.includes('home/contact/detail')) {
			window.location.pathname = '/home/contact/history'
		} else {
			window.history.back()
		}
	}

	getAnswerUserName = () => {
		const questionId = window.location.pathname.slice(
			20,
			window.location.pathname.length
		)
		const token = localStorage.getItem('token')
		request
			.get(glasConfig.url_base + glasConfig.path_answer + questionId, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({
						questionData: response.data.data,
						questionId,
					})
				}
			})
			.catch((error) => console.log(error))
	}

	componentDidUpdate(prevProps) {
		const { dispatch } = this.props
		if (prevProps.ChatRequest.data !== this.props.ChatRequest.data) {
			dispatch(
				RequestHistoryActions.fetch({
					page: 0,
					size: glasConfig.leftmenu_per_page_history,
				})
			)
		}
	}

	handleBackAnswer = () => {
		const className = $('.content').attr('class')
		if (className.includes('hidden-sp')) {
			$('.timeline-form-wrap-sp').addClass('hiddenClass')
			$('.layout_timeline').removeClass('hiddenClass')
			$('.navbar-fixed-bottom').removeClass('hiddenClass')
			$('.menu-header').removeClass('hiddenClass')
			$('.content').removeClass('hidden-sp')
		} else {
			if (window.location.pathname.includes('/home/contact/detail')) {
				window.location.pathname = 'home/contact/history'
			}
			window.history.back()
		}
	}

	checkRequestOnline() {
		const { socket } = this.props
		socket.on('userRequest', (res) => {
			const message = JSON.parse(res)
			if (
				message.msgType === 'RESPONSE_REQUEST_CHAT' &&
				this.state.show !== 'Request'
			) {
				this.setState({
					show: 'Request',
				})
			}
		})
	}

	componentDidMount() {
		const { dispatch } = this.props
		this.checkRequestOnline()
		/*chatting*/
		dispatch(
			ChatRequestActions.requestChatUnRead({
				page: 0,
				size: 24,
				inConversation: true,
			})
		)
		dispatch(
			ChatRequestActions.requestChatRead({
				page: 0,
				size: 24,
				inConversation: true,
			})
		)

		/*chatted history*/
		dispatch(
			RequestHistoryActions.requestListPin()
		)
		dispatch(
			RequestHistoryActions.requestHistoryUnRead({
				page: 0,
				size: 24,
				inConversation: false,
			})
		)
		dispatch(
			RequestHistoryActions.requestHistoryRead({
				page: 0,
				size: glasConfig.leftmenu_per_page_history,
				inConversation: false,
			})
		)
		/*favoried*/
		dispatch(
			FollowersActions.fetch({
				page: 0,
				size: glasConfig.leftmenu_maxlength_follower,
				sortByCreatedAt: glasConfig.sortASC,
			})
		)
		dispatch(ChatNumberTotalAction.fetch())

		/*if (window.location.pathname === ('/home/chat/' + this.props.userId)) {
	  this.setState({
		show: 'History',
	  });
	}*/
		dispatch(UserInfoAction.fetchProfile(this.props.userId))
	}

	render() {
		const {
			MyProfile,
			UserInfo,
			Follower,
			RequestHistory,
			userId,
			userNameOfflineRequest,
			ChatNumberTotal,
			CallStoredData
		} = this.props
		const currentQuestionId = window.location.pathname.slice(
			20,
			window.location.pathname.length
		)
		if (
			this.state.questionId &&
			currentQuestionId !== this.state.questionId
		) {
			this.getAnswerUserName()
		}

		const status = MyProfile.data.fortuneTellerStatus
		const statusToString = fortuneTellerStatusToString[status]
		const showComponent = this.state.show
		const numberNoti =
			MyProfile.data.numberUnreadNotification > 99
				? '99+'
				: MyProfile.data.numberUnreadNotification

		const isInAnswer = window.location.pathname.includes('answer/detail')
		let unreadMessageBadge = ChatNumberTotal.data.chattingUnreadNumber
		let unreadMessageBadgeHistory = ChatNumberTotal.data.chatedUnreadNumber
		const setLeftMenuToTop = CallStoredData.preCall.status === CALL_SCREEN.STATUS.ACTIVE || CallStoredData.inCall.status === CALL_SCREEN.STATUS.ACTIVE
		return (
			<div>
				<div
					className={
						this.state.showLeftMenu
							? 'overlay secondary-show'
							: 'overlay secondary-hidden'
					}
					onClick={
						this.state.showLeftMenu ? this.closeNavLeft : () => { }
					}
				>
					<Navbar fixedTop className="secondary-header">
						<Badge
							style={{
								paddingLeft: 10,
								paddingTop: 15,
								float: 'left',
								paddingRight: 4,
							}}
							onClick={this.showNavLeft}
							className="left-badge"
							badgeContent={unreadMessageBadge}
							secondary={true}
							badgeStyle={
								unreadMessageBadge > 0
									? { top: 6, left: 26 }
									: { display: 'none' }
							}
						>
							<i className="material-icons">sms</i>
						</Badge>
						{isInAnswer ? (
							<i
								className="material-icons icon-back"
								onClick={this.handleBackAnswer}
								style={{}}
							>
								keyboard_arrow_left
							</i>
						) : (
							<span
								className="message_text"
								onClick={this.showNavLeft}
							>
								{statusToString}
							</span>
						)}
						<Link className="link-to-home" to="/home">
							<FontIcon className="material-icons">home</FontIcon>
						</Link>
						<div className="user_name_hidden">
							{UserInfo.data.userName}
						</div>
						{isInAnswer ? (
							<div className="user_name_answer">
								{this.state.questionData.nickName}さんの質問
							</div>
						) : null}
						{isInAnswer ? (
							<i
								className={
									this.state.questionData.isShowMyProfile
										? 'material-icons btn__icons userAnswerActive'
										: 'material-icons btn__icons userAnswer'
								}
								onClick={this.showUserInfoAnswer}
							>
								person
							</i>
						) : (
							<Badge
								className="noti_icon"
								onClick={() =>
									this.handeleBadge(
										'numberUnreadNotification'
									)
								}
								style={{
									float: 'right',
									paddingTop: 12,
									paddingRight: 18,
								}}
								badgeContent={
									numberNoti > 99
										? numberNoti + '+'
										: numberNoti
								}
								secondary={true}
								badgeStyle={
									numberNoti > 0
										? { top: 5, left: 23 }
										: { display: 'none' }
								}
							>
								<NotificationsIcon />
							</Badge>
						)}
						<div className="nav_right_chat">
							<button
								className="pencil"
								onClick={this.showLogMemory}
							>
								<img src={'/img/pencil.png'} alt={''} />
							</button>
							<button
								className="user"
								onClick={this.showUserInfo}
							>
								<img src={'/img/account.png'} alt={''} />
							</button>
						</div>
					</Navbar>
					<div className="header-back hiddenClass">
						<i
							className="material-icons icon-back"
							onClick={this.goBack}
						>
							keyboard_arrow_left
						</i>
						<span className="component-title" />
					</div>
					<HeaderNotifi
						show={this.state.showNotifiLog}
						closeFunc={() =>
							this.handeleBadge('numberUnreadNotification')
						}
					/>
				</div>

				<div
					className={
						this.state.showLeftMenu
							? 'secondary secondary-show'
							: `secondary secondary-hidden ${setLeftMenuToTop ? 'on-top' : ''}`
					}
				>
					<LeftProfile
						closeNavLeft={this.closeNavLeft}
						MyProfile={MyProfile}
					/>
					{/*<LeftTabChat />*/}
					<div className="secondary-customer">
						<nav className="tab-bar" id="js_customer_nav">
							<ul className="list column_3">
								<li className="list__item">
									<a
										data-badge={
											unreadMessageBadge > 99
												? '99+'
												: unreadMessageBadge > 0
													? unreadMessageBadge
													: 0
										}
										onClick={(e) =>
											this.showItem(e, 'Request')
										}
										className={
											showComponent === 'Request'
												? 'tab is-active'
												: 'tab'
										}
									>
										<i className="material-icons tab__icons">
											sms
										</i>
									</a>
								</li>
								<li className="list__item">
									<a
										data-badge={
											unreadMessageBadgeHistory > 99
												? '99+'
												: unreadMessageBadgeHistory > 0
													? unreadMessageBadgeHistory
													: 0
										}
										onClick={(e) =>
											this.showItem(e, 'History')
										}
										className={
											showComponent === 'History'
												? 'tab is-active'
												: 'tab'
										}
									>
										<i className="material-icons tab__icons">
											history
										</i>
									</a>
								</li>
								<li className="list__item">
									<a
										onClick={(e) =>
											this.showItem(e, 'Follower')
										}
										className={
											showComponent === 'Follower'
												? 'tab is-active'
												: 'tab'
										}
									>
										<i className="material-icons tab__icons">
											favorite
										</i>
									</a>
								</li>
							</ul>
						</nav>
						{showComponent === 'Request' ? (
							<LeftRequest
								chatUserId={userId}
								userNameOfflineRequest={userNameOfflineRequest}
								openWarningFullChatDialog={(chatUserId) =>
									this.props.openWarningFullChatDialog(
										chatUserId
									)
								}
								closeNavLeft={this.closeNavLeft}
								history={this.props.history}
							/>
						) : showComponent === 'History' ? (
							<LeftHistory
								data={RequestHistory}
								closeNavLeft={this.closeNavLeft}
								chatUserId={userId}
							/>
						) : showComponent === 'Follower' ? (
							<LeftFollower data={Follower} chatUserId={userId} />
						) : null}
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		ChatRequest: state.ChatRequest,
		RequestOffline: state.RequestOffline,
		RequestHistory: state.RequestHistory,
		ChatNumberTotal: state.ChatNumberTotal,
		Follower: state.Follower,
		UserInfo: state.UserInfo,
		CallStoredData: state.Call
	}
}

export default connect(mapStateToProps)(LeftMenu)
