import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import * as moment from 'moment'
import AnalysisMenu from './AnalysisMenu'

class MonthlyAnalysis extends Component {
	constructor(props) {
		super(props)
		this.state = {
			year: moment().format('YYYY'),
			data: {},
			statusItems: [],
			dataMonth: [],
			dataSP: {},
			tab: 'monthly',
		}
	}

	componentWillMount() {
		this.getAnalysis(this.props.match.params.year)
	}

	// componentDidMount() {
	//   this.checkOtherTeller()
	// }

	// checkOtherTeller = () => {
	//   const lastTellerId = localStorage.getItem('lastTellerId')
	//   const tellerId = localStorage.getItem('tellerId')
	//   if (lastTellerId !== tellerId) {
	//     localStorage.setItem('lastTellerId', tellerId)
	//     window.location.reload()
	//   }
	// }

	componentWillReceiveProps(nextProps) {
		this.getAnalysis(nextProps.match.params.year)
		this.setState({ year: nextProps.match.params.year })
	}

	/**
	 * 日別ログを取得
	 */
	getAnalysis(yyyy) {
		const year = yyyy === undefined ? moment().format('YYYY') : yyyy
		// if (!moment(year, "YYYY").isValid()) {
		//   return
		// }
		// let statusItems = []
		// const amountDays = moment(month, 'YYYYMM').daysInMonth();
		// for (let i = 0; i < amountDays + 1; i++) {
		//   statusItems.push(false);
		// }
		const url = glasConfig.url_base + glasConfig.path_analysis
		const startDay = moment(year + '01', 'YYYYMM')
			.startOf('month')
			.format('YYYY-MM-DD')
		const startTime = moment(startDay, 'YYYY-MM-DD').valueOf()
		const endDay = moment(year + '12', 'YYYYMM')
			.endOf('month')
			.add(1, 'days')
			.format('YYYY-MM-DD')
			.valueOf()
		const endTime = moment(endDay, 'YYYY-MM-DD').valueOf()
		const params = {
			tab: this.state.tab,
			startTime,
			endTime,
		}
		const token = localStorage.getItem('token')
		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: token,
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ year, data: response.data.data })
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	formatTime(totalSeconds) {
		var hours = Math.floor(totalSeconds / 3600)
		var minutes = Math.floor((totalSeconds - hours * 3600) / 60)
		var seconds = totalSeconds - hours * 3600 - minutes * 60

		seconds = Math.round(seconds * 100) / 100

		var result = hours < 10 ? '0' + hours : hours
		result += ':' + (minutes < 10 ? '0' + minutes : minutes)
		result += ':' + (seconds < 10 ? '0' + seconds : seconds)

		return result
	}

	showMore(index) {
		let newStatusItems = this.state.statusItems
		newStatusItems[index] = !this.state.statusItems[index]
		this.setState({ statusItems: newStatusItems }, () => {})
	}

	showAnalysis() {
		if (this.state.data.list) {
			return Array.prototype.map.call(
				this.state.data.list,
				(current, index) => {
					return (
						<tr key={index}>
							<td>{moment(current.time).format('M')}月</td>
							<td>{this.formatTime(current.waitingTime)}</td>
							<td>{current.footPrint}人</td>
							<td>{current.numberOfRequests}回</td>
							<td>{current.chatConnections}回</td>
							<td>{current.callConnections}回</td>
							<td>{current.favoriteNumber}回</td>
							<td>{current.reviewedNumber}個</td>
						</tr>
					)
				}
			)
		}
	}

	showAnalysisSP() {
		const { statusItems } = this.state
		if (this.state.data.list) {
			return Array.prototype.map.call(
				this.state.data.list,
				(current, index) => {
					return (
						<div className="rowBody analysisItem">
							<div className="rowReward total">
								<div className="rewardItemLeftCol">
									<span className="titleLeft">
										{moment(current.time).format('M')}月
									</span>
								</div>
								<div className="rewardItemRightCol">
									<span className="titleRight">
										{this.formatTime(current.waitingTime)}
									</span>
									<i
										className="material-icons btn__icons iconExpand"
										onClick={() => this.showMore(index + 1)}
									>
										{statusItems[index + 1]
											? 'expand_less'
											: 'expand_more'}
									</i>
								</div>
							</div>
							{statusItems[index + 1] ? (
								<div className="detailReward">
									<div className="singleRow singleRowImg rowItem">
										<div className="rowLeftCol">
											足あと数
										</div>
										<div className="rowRightCol rowRightColImg">
											<img
												src="/img/foot_print.png"
												className="iconAnalysis"
											/>
											<div className="valueRight">
												{current.footPrint}人
											</div>
										</div>
									</div>
									<div className="singleRow singleRowImg rowItem">
										<div className="rowLeftCol">
											リクエスト数
										</div>
										<div className="rowRightCol rowRightColImg">
											<img
												src="/img/reserve.png"
												className="iconAnalysis"
											/>
											<div className="valueRight">
												{current.numberOfRequests}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem">
										<div className="rowLeftCol">
											チャット接続数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												sms
											</i>
											<div className="valueRight">
												{current.chatConnections}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem">
										<div className="rowLeftCol">
											通話接続数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												call
											</i>
											<div className="valueRight">
												{current.callConnections}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem">
										<div className="rowLeftCol">
											お気に入りされた数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												favorite
											</i>
											<div className="valueRight">
												{current.favoriteNumber}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem lastSingleRow">
										<div className="rowLeftCol">
											レビュー数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												star
											</i>
											<div className="valueRight">
												{current.reviewedNumber}個
											</div>
										</div>
									</div>
								</div>
							) : null}
						</div>
					)
				}
			)
		}
	}

	render() {
		const { MyProfile, dispatch } = this.props
		const { data, statusItems } = this.state
		let momentObj = moment(
			MyProfile.data.registerTime,
			'YYYYMMDDHHmmss'
		).add(9, 'hours')
		let registerYear = momentObj.year()
		let currentDate = new Date()
		let currentYear = currentDate.getFullYear()
		let year = moment(this.state.year, 'YYYY').year()

		let canPrev = true
		let canNext = true

		if (year <= registerYear) {
			year = registerYear
			canPrev = false
		}

		if (year >= currentYear) {
			year = currentYear
			canNext = false
		}

		let prev = canPrev
			? moment(this.state.year, 'YYYYMM')
					.subtract(1, 'years')
					.format('YYYY')
			: this.state.year
		let next = canNext
			? moment(this.state.year, 'YYYYMM').add(1, 'years').format('YYYY')
			: this.state.year
		let prevClass = canPrev
			? 'rdh__navigation__prev is-active'
			: 'rdh__navigation__prev'
		let nextClass = canNext
			? 'rdh__navigation__next is-active'
			: 'rdh__navigation__next'

		return (
			<div className="content reward analysis-daily">
				<h1 className="content__title">アクセス解析</h1>
				<AnalysisMenu />
				<div className="primary primaryReward primaryAnalysis">
					<div className="primary-title titleDailyReward">
						<h1 className="primary-title__main">月別</h1>
						<div className="primary-title lv_2 hidden-mobile dateTimeReward">
							<h1 className="primary-title__main">{year}年</h1>
							<div className="reward-daily__header">
								<div className="rdh__navigation">
									<Link
										to={`/home/analysis/monthly/${prev}`}
										className={prevClass}
									>
										<i className="fas fa-chevron-left"></i>
									</Link>
									<Link
										to={`/home/analysis/monthly/${next}`}
										className={nextClass}
									>
										<i className="fas fa-chevron-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="frame-table-noti hidden-sp analysis-table">
						<table className="table-data table-analysis">
							<thead>
								<tr>
									<th className="table-align-center">週</th>
									<th className="table-align-center">
										待機時間
									</th>
									<th className="table-align-center">
										足あと数
									</th>
									<th className="table-align-center">
										リクエスト数
									</th>
									<th className="table-align-center">
										チャット接続数
									</th>
									<th className="table-align-center">
										通話接続数
									</th>
									<th className="table-align-center">
										お気に入りされた数
									</th>
									<th className="table-align-center">
										レビューのついた数
									</th>
								</tr>
							</thead>
							<tbody>
								<tr className="monthAnalysis">
									<td>合計</td>
									<td>{this.formatTime(data.waitingTime)}</td>
									<td>{data.footPrint}人</td>
									<td>{data.numberOfRequests}回</td>
									<td>{data.chatConnections}回</td>
									<td>{data.callConnections}回</td>
									<td>{data.favoriteNumber}回</td>
									<td>{data.reviewedNumber}個</td>
								</tr>
								{this.showAnalysis()}
							</tbody>
						</table>
					</div>
					<div className="primary__inner">
						<div className="primary-content">
							<div className="primary-content__body content__body-analysis">
								<div className="reward-daily__header display-mobile">
									<div className="rdh__date">{year}年</div>
									<div className="rdh__navigation">
										<Link
											to={`/home/analysis/monthly/${prev}`}
											className={prevClass}
										>
											<i className="fas fa-chevron-left"></i>
										</Link>
										<Link
											to={`/home/analysis/monthly/${next}`}
											className={nextClass}
										>
											<i className="fas fa-chevron-right"></i>
										</Link>
									</div>
									<div className="rewardItem firstRowMobileDown analysisItem">
										<div className="firstRowTop">
											<div className="rewardItemLeftCol">
												<span
													style={{
														marginLeft: 12,
														fontSize: 10,
													}}
												>
													月
												</span>
											</div>
											<div className="rewardItemRightCol">
												<div
													style={{
														width: 100,
														textAlign: 'center',
														fontSize: 10,
													}}
												>
													待機時間
												</div>
											</div>
										</div>
										<div className="firstRowBody">
											<div className="rowReward total">
												<div className="rewardItemLeftCol">
													<span className="titleLeft">
														{year}年
													</span>
												</div>
												<div className="rewardItemRightCol">
													<span className="titleRight">
														{this.formatTime(
															data.waitingTime
														)}
													</span>
													<i
														className="material-icons btn__icons iconExpand"
														onClick={() =>
															this.showMore(0)
														}
													>
														{statusItems[0]
															? 'expand_less'
															: 'expand_more'}
													</i>
												</div>
											</div>
											{statusItems[0] ? (
												<div className="detailReward">
													<div className="singleRow singleRowImg rowItem">
														<div className="rowLeftCol">
															足あと数
														</div>
														<div className="rowRightCol rowRightColImg">
															<img
																src="/img/foot_print.png"
																className="iconAnalysis"
															/>
															<div className="valueRight">
																{data.footPrint}
																人
															</div>
														</div>
													</div>
													<div className="singleRow singleRowImg rowItem">
														<div className="rowLeftCol">
															リクエスト数
														</div>
														<div className="rowRightCol rowRightColImg">
															<img
																src="/img/reserve.png"
																className="iconAnalysis"
															/>
															<div className="valueRight">
																{
																	data.numberOfRequests
																}
																回
															</div>
														</div>
													</div>
													<div className="singleRow rowItem">
														<div className="rowLeftCol">
															チャット接続数
														</div>
														<div className="rowRightCol">
															<i className="material-icons btn__icons">
																sms
															</i>
															<div className="valueRight">
																{
																	data.chatConnections
																}
																回
															</div>
														</div>
													</div>
													<div className="singleRow rowItem">
														<div className="rowLeftCol">
															通話接続数
														</div>
														<div className="rowRightCol">
															<i className="material-icons btn__icons">
																call
															</i>
															<div className="valueRight">
																{
																	data.callConnections
																}
																回
															</div>
														</div>
													</div>
													<div className="singleRow rowItem">
														<div className="rowLeftCol">
															お気に入りされた数
														</div>
														<div className="rowRightCol">
															<i className="material-icons btn__icons">
																favorite
															</i>
															<div className="valueRight">
																{
																	data.favoriteNumber
																}
																回
															</div>
														</div>
													</div>
													<div className="singleRow rowItem lastSingleRow">
														<div className="rowLeftCol">
															レビュー数
														</div>
														<div className="rowRightCol">
															<i className="material-icons btn__icons">
																star
															</i>
															<div className="valueRight">
																{
																	data.reviewedNumber
																}
																個
															</div>
														</div>
													</div>
												</div>
											) : null}
										</div>
									</div>
									<div className="rowsSP">
										{this.showAnalysisSP()}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(MonthlyAnalysis)
