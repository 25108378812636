/**
 * サイトからのお知らせを表示
 */
import React from 'react'
import moment from 'moment'

export const HomeNews = ({ data }) => {
	if (!data) return null

	if (!Object.keys(data.data).length) {
		return null
	}

	require('moment/locale/ja')
	moment.locale('ja')

	return (
		<div>
			<h2 className="content__title lv_2">お知らせ</h2>
			<div className="primary margin_bottom">
				<div className="primary__inner">
					<ul className="list">
						<li className="list__item">
							{Object.values(data.data).map((val) => {
								return (
									<dl
										key={val.id}
										className="definition title_column"
									>
										<dt className="definition__title">
											{moment(
												val.fromTime,
												'YYYYMMDDHHmmss'
											)
												.add(9, 'hours')
												.format('YYYY-MM-DD')}
										</dt>
										<dd className="definition__data">
											{val.content}
										</dd>
									</dl>
								)
							})}
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
