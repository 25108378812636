import React from 'react'
import { IconButton } from '@mui/material'
import { Mic, MicOff, CallEnd, VolumeUp, VolumeOff } from '@mui/icons-material'
import { ContainerIconButton } from '../StyledComponents/StyledDialogInCallComponents'
import { makeStyles } from '@mui/styles'
import { colors, DEVICE } from '../../../util/StellaConstant'

const BUTTONS_INCALL = {
	MICRO: {
		ON: <Mic fontSize="large" />,
		OFF: <MicOff fontSize="large" />,
	},
	SPEAKER: {
		ON: <VolumeUp fontSize="large" />,
		OFF: <VolumeOff fontSize="large" />,
	},
	ENDCALL: {
		ON: <CallEnd fontSize="large" />,
		OFF: <CallEnd fontSize="large" />,
	},
}

const ButtonWithIcon = (props) => {
	const { buttonType, status, onClick } = props
	const classes = useStyles({ buttonType })
	return (
		<ContainerIconButton buttonType={buttonType}>
			<IconButton
				onClick={onClick}
				classes={{
					root: classes.root,
				}}
			>
				{BUTTONS_INCALL[buttonType][status]}
			</IconButton>
		</ContainerIconButton>
	)
}

const useStyles = makeStyles((theme) => ({
	root: (props) => ({
		[`@media ${DEVICE.mobileS}`]: {
			width: '100%',
			height: '100%',
			color: `${
				props.buttonType === 'ENDCALL'
					? colors.white
					: colors.pasterPurple
			} !important`,
			backgroundColor: `${
				props.buttonType === 'ENDCALL' ? colors.pasterRed : colors.white
			} !important`,
		},
		[`@media ${DEVICE.tablet}`]: {
			width: '100%',
			height: '100%',
			color: `${
				props.buttonType === 'ENDCALL'
					? colors.white
					: colors.pasterPurple
			} !important`,
			backgroundColor: `${
				props.buttonType === 'ENDCALL' ? colors.pasterRed : colors.white
			} !important`,
		},
		[`@media ${DEVICE.laptop}`]: {
			width: '100%',
			height: '100%',
			color: `${
				props.buttonType === 'ENDCALL'
					? colors.white
					: colors.pasterPurple
			} !important`,
			backgroundColor: `${
				props.buttonType === 'ENDCALL' ? colors.pasterRed : colors.white
			} !important`,
		},
	}),
}))

export default ButtonWithIcon
