import React, { Component } from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import DialogGetPoint from './DialogGetPoint'
import { glasConfig } from '../../constants/Config'
import request from 'axios'
import * as Fetch from '../../util/Fetch'
import moment from 'moment'

class HomeTellerIntroductionTeller extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isIntroduced: false,
			listAchievement: [],
			achievementTellerIsIntroduced: false,
			registerTime: 0,
			numberPoint1: 0,
			numberPoint2: 0,
			pointRewardCustomer: 0,
			listAchievementCustomer: []
		}
	}

	componentDidMount() {
		this.getIsIntroduced()
		this.getListAchievement()
		this.getAchievementTellerIsIntroduced()
		this.getPointSetting1()
		this.getPointSetting2()
		this.getListAchievementCustomer()
		this.getPointSettingRewardCustomer()
	}

	getListAchievementCustomer = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
				glasConfig.get_list_reward_introduce_customer,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					const data = response.data.data || []
					this.setState({ listAchievementCustomer: data })
				}
			})
			.catch((error) => {
				throw error
			})
	}

	 getPointSettingRewardCustomer = () => {
        const token = localStorage.getItem('token')
        return request
            .get(
                glasConfig.url_base + glasConfig.get_setting_point_introduce_customer,
                {
                    headers: { Authorization: token },
                }
            )
            .then((response) => {
                if (response.data.code === 0) {
                    const { point } = response.data.data
                    this.setState({pointRewardCustomer: point})
                }
            })
            .catch((error) => {
                throw error
            })
    }

	getIsIntroduced = () => {
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()
		return request
			.get(glasConfig.url_base + glasConfig.path_teller_info + tellerId, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					const { isIntroduced, registerTime } = response.data.data
					this.setState({ isIntroduced, registerTime })
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getListAchievement = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
				glasConfig.get_list_achievement_introduction_home,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					const data = response.data.data
					this.setState({ listAchievement: data })
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getAchievementTellerIsIntroduced = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
				glasConfig.get_achievement_teller_is_introduced,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0 && response.data.data) {
					const { rewardId = null } = response.data.data
					this.setState({ achievementTellerIsIntroduced: rewardId })
				} else {
					// console.log('object');
					// throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getPointSetting1 = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base + glasConfig.get_point_setting_introduction,
				{
					headers: {
						Authorization: token,
					},
					params: {
						isIntroduced: false,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					const { point } = response.data.data
					this.setState({ numberPoint1: point })
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}
	getPointSetting2 = () => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base + glasConfig.get_point_setting_introduction,
				{
					headers: {
						Authorization: token,
					},
					params: {
						isIntroduced: true,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					const { point } = response.data.data
					this.setState({ numberPoint2: point })
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}

	getPoint = (rewardId, numberPoint, isIntroduceCustomer) => () => {
		this.refs.dialogGetPoint.show({ rewardId, numberPoint, isIntroduceCustomer })
	}
	render() {
		const {
			isIntroduced,
			listAchievement,
			achievementTellerIsIntroduced,
			registerTime,
			numberPoint1,
			numberPoint2,
			listAchievementCustomer,
			pointRewardCustomer
		} = this.state
		const registerTimeFM = moment(registerTime, 'YYYYMMDDHHmm')
			.add(9, 'h')
			.add(30, 'days')
		let time = registerTimeFM.diff(moment(), 'hours')
		if (time > 24) {
			time = Math.ceil(time / 24)
			time = time + '日'
		} else {
			time = time + '時'
		}
		return (
			<div className="home-teller-in">
				<DialogGetPoint
					ref="dialogGetPoint"
					onClose={() => {
						this.getAchievementTellerIsIntroduced()
						this.getListAchievement()
					}}
				/>
				{!!listAchievementCustomer[0] && (
					<div className="bar bar1">
						<span>紹介したお客様が登録を完了しました！</span>
						<button
							className="btn-raised color_default hidden-sp"
							onClick={this.getPoint(
								listAchievementCustomer[0].rewardId,
								pointRewardCustomer,
								true
							)}
						>
							ポイントを受け取る
						</button>
						<span
							onClick={this.getPoint(
								listAchievementCustomer[0].rewardId,
								pointRewardCustomer,
								true
							)}
							className="hidden-pc"
						>
							受け取る
						</span>
					</div>
				)}
				{!!listAchievement[0] && (
					<div className="bar bar1">
						<span>紹介した方が条件を達成しました！</span>
						<button
							className="btn-raised color_default hidden-sp"
							onClick={this.getPoint(
								listAchievement[0].rewardId,
								numberPoint1
							)}
						>
							ポイントを受け取る
						</button>
						<span
							onClick={this.getPoint(
								listAchievement[0].rewardId,
								numberPoint1
							)}
							className="hidden-pc"
						>
							受け取る
						</span>
					</div>
				)}

				{!!achievementTellerIsIntroduced && (
					<div className="bar bar1">
						<span>紹介ボーナスの条件を達成しました！</span>
						<button
							className="btn-raised color_default hidden-sp"
							onClick={this.getPoint(
								achievementTellerIsIntroduced,
								numberPoint2
							)}
						>
							ポイントを受け取る
						</button>
						<span
							onClick={this.getPoint(
								achievementTellerIsIntroduced,
								numberPoint2
							)}
							className="hidden-pc"
						>
							受け取る
						</span>
					</div>
				)}

				{!!isIntroduced && (
					<div className="bar bar2">
						<span>
							紹介ボーナスをゲットしましょう！［期限まであと{time}
							］
						</span>
						<Link to="/home/introduction/registration">
							<button className="btn-raised color_default hidden-sp">
								確認
							</button>
							<span className="hidden-pc">確認</span>
						</Link>
					</div>
				)}
			</div>
		)
	}
}

export default HomeTellerIntroductionTeller
