import React, { Component } from 'react'

import { glasConfig } from './../constants/Config'
import { connect } from 'react-redux'
import request from 'axios'
import moment from 'moment'

class AnswerOtherTeller extends Component {
	constructor(props) {
		super(props)
		this.state = {
			firstAnswersOfOtherTeller: [],
		}
	}

	componentWillMount() {
		this.getAnswersOfOtherTeller(this.props.questionId)
	}

	getAnswersOfOtherTeller = (questionId) => {
		const { MyProfile } = this.props
		const tellerId = MyProfile.data.fortuneTellerId
		let params = [`questionId=${questionId}`, `page=${0}`, `size=${10}`]
		const paramsFormatted = `?${params.join('&')}`
		const token = localStorage.getItem('token')
		request
			.get(
				glasConfig.url_base +
					glasConfig.path_answer +
					'answer/' +
					paramsFormatted,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let answersOfOtherTellers = []
					answersOfOtherTellers = response.data.data.list
					answersOfOtherTellers.forEach((element, index) => {
						if (element.tellerId == tellerId) {
							answersOfOtherTellers.splice(index, 1)
						}
					})
					let firstAnswersOfOtherTeller = answersOfOtherTellers.slice(
						0,
						3
					)
					this.setState({ firstAnswersOfOtherTeller })
				}
			})
			.catch((error) => console.log(error))
	}

	formatContent = (content) => {
		let lines = (content.match(/\n/g) || []).length
		if (lines > 1) {
		}

		return content
	}

	render() {
		const { firstAnswersOfOtherTeller } = this.state
		const listAnswers = firstAnswersOfOtherTeller.map((item, index) => {
			const answerCreateTime = new Date(item.answerTime)
			const answerCreateTimeFormated =
				moment(answerCreateTime).format('YYYY/MM/DD')
			const content = this.formatContent(item.answerContent)
			return item.isApproved ? (
				<li className="answer-other-item" key={index}>
					<div className="answer-other-item-top">
						<div
							className="answer-other-item-time"
							style={{
								float: item.isBestAnswer ? 'left' : 'none',
							}}
						>
							{answerCreateTimeFormated}
						</div>
						<div className="answerPostedLikeOther">
							<i
								className={
									item.totalLike === 0
										? 'fas fa-heart fa-heart0'
										: 'fas fa-heart'
								}
							></i>
							<span>{item.totalLike}</span>
						</div>
						{item.isBestAnswer && (
							<div className="answer-other-item-best-answer">
								<img src="/img/icBestAnswer.png" />
							</div>
						)}
					</div>
					<div className="answer-other-item-content">{content}</div>
				</li>
			) : null
		})

		return <ul className="list-answer-other">{listAnswers}</ul>
	}
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(AnswerOtherTeller)
