import React, { Component } from 'react'
import CustomerManagementMenu from './CustomerManagementMenu'
import Select from 'react-select'
import DatePicker, { registerLocale } from 'react-datepicker'
import { glasConfig } from '../constants/Config'
import 'react-datepicker/dist/react-datepicker.css'
import ja from 'date-fns/locale/ja'
import request from 'axios'
import Snackbar from 'material-ui/Snackbar'
import $ from 'jquery'
import Loading from './Loading'
import { isMobile, isIOS } from 'react-device-detect'
registerLocale('ja', ja)

const options = [
	{ value: 1, label: '相談履歴のあるユーザー' },
	{ value: 2, label: 'お気に入りユーザー' },
]

class CustomerManagementEditNotification extends Component {
	constructor(props) {
		super(props)
		this.state = {
			date: new Date(),
			errMsg: '',
			target: null,
			content: '',
			notificationId: props.match.params.notificationId,
			errorUpdate: false,
			tmpDate: new Date(),
		}
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.menu-header').addClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
	}

	componentDidMount() {
		this.getNotification()
		$('.secondary-header').addClass('hiddenClass')
		$('.menu-header').removeClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('プッシュ通知作成')
	}

	getNotification() {
		const { notificationId } = this.state
		request
			.get(
				glasConfig.url_base +
					glasConfig.path_setting_noti +
					`/${notificationId}`,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				const data = response.data.data
				if (response.data.code == 0) {
					this.setState({
						content: data.content,
						date: new Date(data.timeToPush),
						target: options[data.target - 1],
					})
				}
			})
			.catch((error) => console.log(error))
	}

	handleChange = (selectedOption) => {
		this.setState({ target: selectedOption })
	}

	onSubmitDate = () => {
		this.setState({ date: this.state.tmpDate })
		this.datePicker.setOpen(false)
	}

	onClickOutside = () => {
		this.datePicker.setOpen(true)
	}

	handleChangeContent = (e) => {
		const content = e.target.value
		const length = content.replace(/\s/g, '').length
		if (length > glasConfig.maxlength_push_noti) {
			return
		}
		this.setState({ content })
	}

	openDatePicker = () => {
		this.datePicker.setOpen(true)
	}

	handleSubmit = () => {
		const { target, content, date } = this.state
		let currentTime = new Date()
		currentTime = currentTime.getTime()
		const selectTime = date.getTime()
		if (content.length === 0) {
			this.setState({ errMsg: '※通知内容が入力されていません' })
			return
		}
		if (
			(selectTime - currentTime) / 60000 <
			glasConfig.mintime_setting_noti
		) {
			this.setState({
				errMsg: '※指定日時が15分前に設定されています。現在時刻より15分後に設定してください。',
			})
			return
		}
		this.setState({ loading: true })
		const token = localStorage.getItem('token')
		let params = {}
		params.target = target.value
		params.content = content
		params.timeToPush = selectTime
		params.notificationSettingId = this.state.notificationId
		request
			.put(glasConfig.url_base + glasConfig.path_setting_noti, params, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code == 0) {
					window.location.href =
						'/home/customermanagement/notification'
				} else if (response.data.code == 229) {
					this.setState({
						errMsg: '※1日に送信可能な上限数を越えました。また明日以降設定してください',
						loading: false,
					})
				} else if (response.data.code == 231) {
					this.setState({ errorUpdate: true, loading: false })
					setTimeout(
						(window.location.href =
							'/home/customermanagement/notification'),
						2000
					)
				} else {
					this.setState({ loading: false })
				}
			})
			.catch((error) => console.log(error))
	}

	render() {
		const customStyles = {
			option: (provided, state) => ({
				...provided,
				backgroundColor: state.isSelected ? '#11ad9d' : '#fff',
			}),
			singleValue: (provided, state) => {
				return { ...provided }
			},
		}

		return (
			<div className="content contentNoti">
				<div className="hidden-sp">
					<h1 className="content__title">顧客管理</h1>
					<CustomerManagementMenu />
					<div className="primary">
						<div className="primary-title hidden-sp">
							<h1 className="primary-title__main">
								プッシュ通知
							</h1>
							<div className="select">
								<p className="title-cus-manage">送信対象者</p>
								<Select
									value={this.state.target}
									onChange={this.handleChange}
									options={options}
									styles={customStyles}
									defaultValue={options[0]}
								/>
							</div>
							<div className="textArea">
								<p className="title-cus-manage">通知内容</p>
								<textarea
									className="inputCus"
									onChange={(e) =>
										this.handleChangeContent(e)
									}
									placeholder="お客様へアピールするために、今日の運勢などを書いて投稿してみましょう"
									value={this.state.content}
									onKeyPress={(e) => {
										e.key === 'Enter' && e.preventDefault()
									}}
								/>
								<span className="countCharacters">
									{glasConfig.maxlength_push_noti -
										this.state.content.length}
									/50文字
								</span>
							</div>
							<div className="selectTime">
								<p className="titleSelectTime">
									日時
									<span className="timeSpan2">
										※送信時の15分後以降から選択できます。
									</span>
								</p>
								<DatePicker
									ref={(picker) => (this.datePicker = picker)}
									selected={this.state.date}
									onChange={(date) =>
										this.setState({ tmpDate: date })
									}
									timeInputLabel="投稿時間:"
									dateFormat="MM/dd/yyyy HH:mm"
									showTimeInput
									locale="ja"
									dateFormatCalendar="yyyy年 M月"
									shouldCloseOnSelect={false}
									onClickOutside={this.onClickOutside}
									open={this.state.isOpening}
									onChangeRaw={this.handleDateChangeRaw}
								>
									<button
										onClick={this.onSubmitDate}
										className="submitDate"
									>
										決定
									</button>
								</DatePicker>
								<i
									onClick={this.openDatePicker}
									className="material-icons btn__icons iconDateNoti"
								>
									keyboard_arrow_down
								</i>
							</div>
							<p className="errNoti">{this.state.errMsg}</p>
							<button
								onClick={this.handleSubmit}
								className="submitNoti"
							>
								送信予約
							</button>
						</div>
					</div>
				</div>
				<div className="hidden-pc">
					<div className="select">
						<p className="title-cus-manage">送信対象者</p>
						<Select
							value={this.state.target}
							onChange={this.handleChange}
							options={options}
							styles={customStyles}
							defaultValue={options[0]}
						/>
					</div>
					<div className="textArea">
						<p className="title-cus-manage">通知内容</p>
						<textarea
							className="inputCus"
							onChange={(e) => this.handleChangeContent(e)}
							placeholder="お客様へアピールするために、今日の運勢などを書いて投稿してみましょう"
							value={this.state.content}
							onKeyPress={(e) => {
								e.key === 'Enter' && e.preventDefault()
							}}
						/>
					</div>
					{isMobile && (
						<span className="countCharacters">
							{glasConfig.maxlength_push_noti -
								this.state.content.length}
							/50文字
						</span>
					)}
					<div className="selectTime">
						<p className="titleSelectTime">
							日時
							<span className="timeSpan2">
								※送信時の15分後以降から選択できます。
							</span>
						</p>
						<DatePicker
							ref={(picker) => (this.datePicker = picker)}
							selected={this.state.date}
							onChange={(date) =>
								this.setState({ tmpDate: date })
							}
							timeInputLabel="投稿時間:"
							dateFormat="MM/dd/yyyy HH:mm"
							showTimeInput
							locale="ja"
							dateFormatCalendar="yyyy年 M月"
							shouldCloseOnSelect={false}
							onClickOutside={this.onClickOutside}
							open={this.state.isOpening}
							onChangeRaw={this.handleDateChangeRaw}
						>
							<button
								onClick={this.onSubmitDate}
								className="submitDate"
							>
								決定
							</button>
						</DatePicker>
						<i
							onClick={this.openDatePicker}
							className="material-icons btn__icons iconDateNoti"
						>
							keyboard_arrow_down
						</i>
					</div>
					<p className="errNoti">{this.state.errMsg}</p>
					<button onClick={this.handleSubmit} className="submitNoti">
						送信予約
					</button>
				</div>
				<Snackbar
					open={this.state.errorUpdate}
					message={
						'プッシュ通知はユーザー様に届きました。編集はできません。'
					}
					autoHideDuration={1500}
				/>
				<Loading display={this.state.loading} />
			</div>
		)
	}
}

export default CustomerManagementEditNotification
