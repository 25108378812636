import {
	AUTH_TELLERID,
	AUTH_LOGIN,
	AUTH_LOGOUT,
} from '../constants/ActionTypes'

const initialState = {
	errormsg: '',
	tellerId: '',
	data: {},
}

export const Auth = (state = initialState, action) => {
	switch (action.type) {
		case AUTH_TELLERID: {
			return Object.assign({}, state, {
				errormsg: action.errormsg,
				tellerId: action.data,
			})
		}
		case AUTH_LOGIN: {
			return Object.assign({}, state, {
				errormsg: action.errormsg,
				tellerId: action.data.fortuneTellerId,
				data: action.data,
			})
		}
		case AUTH_LOGOUT: {
			return initialState
		}
		default: {
			return state
		}
	}
}
