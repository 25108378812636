import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import { Link } from 'react-router-dom'

const DialogManualBestAnswer = (props) => {
    const {
		showPopUp,
		item,
        userId,
        closePopUp
	} = props

    return (
        <Dialog
            open={showPopUp}
            modal={false}
            contentStyle={{ maxWidth: '400px', maxHeight: '444px' }}
            className="dialogGetBestAnswer"
        >
            <div className="titleGetPoint">
                <b>報酬を受け取りました！</b>
            </div>
            <div className="mission-item__rewardContent">
                <img src="/img/mission_getreward.png" />
                <div className="congratulations congratulationsGetPoint">
                    ベストアンサーおめでとう！
                </div>
                <div className="point pointBestAnswer" style={{ bottom: "70px" }}>
                    {item.pointRewardBestAnswer}PT GET!
                </div>
                <div className="dialog-reward-description">
                    <p>結果のおさらいと共に</p>
                    <p>お客様にさらに提案をしてみましょう!</p>
                </div>
            </div>
            <div className="mission-item__getReward">
                <Link
                    to={`/home/chat/${userId}`}
                >
                    <button
                        className="btn-raised color_accent"
                    >
                        お客様のページへ
                    </button>
                </Link>
            </div>
            <div className="dialog-reward-text__note">
                <p>上手な提案の仕方は<Link to="/home/staffblog/detail/9284" className="text-link"><b>こちら</b></Link></p>
            </div>
            <div className="mission-item__close">
                <a onClick={() => closePopUp()}>キャンセル</a>
            </div>
        </Dialog>
    )
}

export default DialogManualBestAnswer