import {
	GET_PINED_REVIEW,
	GET_REVIEWS_WITH_FILTERS,
	PIN_REVIEW,
	RESET_REVIEW_LIST,
	RESET_STATUS,
	UNPIN_REVIEW,
	UPDATE_PIN_STATUS_OF_REVIEW_ITEM,
} from '../constants/ActionTypes'

const initialState = {
	pinedReviews: { data: [], isLoaded: false },
	reviewsWithFilters: { data: [], isLoaded: false, hasMoreReviews: false },
	pinData: { isDone: false, reviewId: null },
	unpinData: { isDone: false, reviewId: null },
}

export const Review = (state = initialState, action) => {
	switch (action.type) {
		case GET_PINED_REVIEW:
			return {
				...state,
				pinedReviews: {
					data: action.data,
					isLoaded: true,
				},
			}
		case GET_REVIEWS_WITH_FILTERS:
			let stateReview = [...state.reviewsWithFilters.data]
			let result = action.data.initial
				? action.data.responseData
				: stateReview.concat(action.data.responseData)

			return {
				...state,
				reviewsWithFilters: {
					data: result,
					isLoaded: true,
					hasMoreReviews: action.data.length > 0,
				},
			}
		case UPDATE_PIN_STATUS_OF_REVIEW_ITEM:
			const reviewId = action.data.reviewId
			const status = action.data.status

			let newReviews = [...state.reviewsWithFilters.data]
			let newPinedReviews = [...state.pinedReviews.data]

			if (status === 'pined') {
				const pinedReview = newReviews.find(
					(review) => review.reviewId === reviewId
				)

				if (pinedReview) {
					pinedReview.isPinned = true
					newPinedReviews = [pinedReview, ...newPinedReviews]
					newReviews = newReviews.filter(
						(review) => review.reviewId !== reviewId
					)
				}
			}

			if (status === 'unpined') {
				const unpinedReview = newPinedReviews.find(
					(review) => review.reviewId === reviewId
				)

				if (unpinedReview) {
					unpinedReview.isPinned = false
					newReviews = [unpinedReview, ...newReviews]
					newPinedReviews = newPinedReviews.filter(
						(review) => review.reviewId !== reviewId
					)
				}
			}

			return {
				...state,
				pinedReviews: {
					...state.pinedReviews,
					data: newPinedReviews,
				},
				reviewsWithFilters: {
					...state.reviewsWithFilters,
					data: newReviews,
				},
			}
		case PIN_REVIEW:
			return {
				...state,
				pinData: { isDone: true, reviewId: action.data },
			}
		case UNPIN_REVIEW:
			return {
				...state,
				unpinData: { isDone: true, reviewId: action.data },
			}
		case RESET_STATUS:
			return {
				...state,
				pinData: { isDone: false, reviewId: null },
				unpinData: { isDone: false, reviewId: null },
			}
		case RESET_REVIEW_LIST:
			return {
				...state,
				reviewsWithFilters: {
					data: [],
					isLoaded: false,
					hasMoreReviews: false,
				},
			}

		default:
			return state
	}
}
