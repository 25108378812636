import styled from 'styled-components'
import { colors, fonts, DEVICE } from '../../../../util/StellaConstant'

/*
    @media ${DEVICE.mobileS} {

    }
    @media ${DEVICE.laptop} {

    }
    @media ${DEVICE.laptopL} {

    }
*/

export const Title = styled.p`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW6};
		font-size: 20px;
		color: ${colors.pearlAqua};
		margin-bottom: 14px;
		font-weight: 700;
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 26px;
		margin-bottom: 20px;
	}
	@media ${DEVICE.laptopL} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 27px;
		margin-bottom: 19px;
	}
`

export const Content = styled.p`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 15px;
		color: ${colors.mediumBlue};
	}
	@media ${DEVICE.laptop} {
		font-size: 22px;
	}
	@media ${DEVICE.laptopL} {
		font-size: 17px;
	}
`

export const ButtonArea = styled.div`
	@media ${DEVICE.mobileS} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media ${DEVICE.laptop} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	@media ${DEVICE.laptopL} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`

export const Button = styled.button`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 14px;
		color: ${(props) =>
		props.buttonType === 'ACCEPT' ? colors.white : colors.mediumBlue};
		border: none;
		outline: none;
		background-color: ${(props) =>
		props.buttonType === 'ACCEPT'
			? colors.pearlAqua
			: colors.ghostWhite};
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
			0 0 2px 0 rgba(0, 0, 0, 0.12);
		padding: 11px 43px;
	}
	@media ${DEVICE.laptop} {
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 18px;
		color: ${(props) =>
		props.buttonType === 'ACCEPT' ? colors.white : colors.mediumBlue};
		border: none;
		outline: none;
		background-color: ${(props) =>
		props.buttonType === 'ACCEPT'
			? colors.pearlAqua
			: colors.ghostWhite};
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
			0 0 2px 0 rgba(0, 0, 0, 0.12);
		padding: 11px 23px 11px 25px;
	}
	@media ${DEVICE.laptopL} {
		border: none;
		outline: none;
		background-color: ${(props) =>
		props.buttonType === 'ACCEPT'
			? colors.pearlAqua
			: colors.ghostWhite};
		border-radius: 2px;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24),
			0 0 2px 0 rgba(0, 0, 0, 0.12);
		font-family: ${fonts.HiraginoKakuGothicPro};
		font-size: 15px;
		color: ${(props) =>
		props.buttonType === 'ACCEPT' ? colors.white : colors.mediumBlue};
		padding: 11px 58.5px 10px 58.5px;
	}
`

export const Notice = styled.div`
	@media ${DEVICE.mobileS} {
		font-family: ${fonts.HiraKakuProW3};
		font-size: 15px;
		color: ${colors.accent};
		margin-bottom: 21px;
	}
	@media ${DEVICE.laptop} {
		font-size: 22px;
		margin-bottom: 33px;
	}
	@media ${DEVICE.laptopL} {
		font-size: 17px;
		margin-bottom: 39px;
	}
`
export const Ruler = styled.div`
	@media ${DEVICE.mobileS} {
		width: 100%;
		height: 1px;
		background-color: ${colors.gainsboro};
		margin-bottom: 22px;
	}
	@media ${DEVICE.laptop} {
		width: 400px;
		margin-bottom: 12px;
	}
	@media ${DEVICE.laptopL} {
		width: 375px;
		margin-bottom: 11px;
	}
`