import {
	CUSTOMER_MANAGEMENT_SUPPORT_HISTORY_GET,
	CUSTOMER_MANAGEMENT_FOLLOWER_GETTING,
	ADD_MARK_CUSTOMER_REPORT_V2,
	CUSTOMER_MANAGEMENT_FOLLOWER_GET,
	CUSTOMER_MANAGEMENT_NOTIFICATION_GET,
	CUSTOMER_MANAGEMENT_BLOCK_GET,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//対応履歴取得
export const supportHistoryGet = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
					glasConfig.path_customer_report_v2 +
					params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						data: response.data.data,
						page: page || 0,
					}
					dispatch(
						resultSuccess(
							CUSTOMER_MANAGEMENT_SUPPORT_HISTORY_GET,
							res
						)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + CUSTOMER_MANAGEMENT_SUPPORT_HISTORY_GET,
					error
				)
				throw error
			})
	}
}

//フォロワー取得
export const followerGet = (params) => {
	return (dispatch) => {
		dispatch(resultSuccess(CUSTOMER_MANAGEMENT_FOLLOWER_GETTING))
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
					glasConfig.path_customer_report_v2 +
					params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(
							CUSTOMER_MANAGEMENT_FOLLOWER_GET,
							response.data
						)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + CUSTOMER_MANAGEMENT_FOLLOWER_GET,
					error
				)
				if (error.response.status &&
					400 <= error.response.status &&
					error.response.status < 500
				) {
					console.log(error.response.data)
					console.log(error.response.status)
					console.log(error.response.headers)
				} else {
					throw error
				}
			})
	}
}

export const AddMarkCustomerReportV2 = (params) => {
	return (dispatch) => {
		dispatch(resultSuccess(ADD_MARK_CUSTOMER_REPORT_V2))
		const token = localStorage.getItem('token')
		return request
			.post(
				glasConfig.url_base +
					glasConfig.add_mark_customer_report_v2 +
					params,
				{},
				{
					headers: {
						Authorization: token,
						'Content-Type': 'application/json',
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					// dispatch(resultSuccess(ADD_MARK_CUSTOMER_REPORT_V2, response.data))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + CUSTOMER_MANAGEMENT_FOLLOWER_GET,
					error
				)
				if (
					400 <= error.response.status &&
					error.response.status < 500
				) {
					console.log(error.response.data)
					console.log(error.response.status)
					console.log(error.response.headers)
				} else {
					throw error
				}
			})
	}
}

export const notificationGet = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(
				glasConfig.url_base +
					glasConfig.path_customer_notification +
					params,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						data: response.data.data,
						page: page || 0,
					}
					dispatch(
						resultSuccess(CUSTOMER_MANAGEMENT_NOTIFICATION_GET, res)
					)
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + CUSTOMER_MANAGEMENT_NOTIFICATION_GET,
					error
				)
			})
	}
}

export const blockGet = (params, page) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.get(glasConfig.url_base + glasConfig.path_block + params, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					let res = {
						data: response.data.data,
						page: page || 0,
					}
					dispatch(resultSuccess(CUSTOMER_MANAGEMENT_BLOCK_GET, res))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				console.log(
					'error on call ' + CUSTOMER_MANAGEMENT_NOTIFICATION_GET,
					error
				)
			})
	}
}
