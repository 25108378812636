import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

export default class DialogWarningDeleteTimeLine extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			funcAccept: null,
		}
	}

	acceptDelete = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		const actions = [
			<FlatButton
				label="いいえ"
				primary={true}
				onClick={this.closeDialog}
			/>,
			<FlatButton
				label="はい"
				primary={true}
				keyboardFocused={true}
				onClick={this.acceptDelete}
			/>,
		]

		return (
			<Dialog
				modal={false}
				actions={actions}
				open={this.state.show}
				onRequestClose={this.closeDialog}
			>
				<div>削除しますか？</div>
			</Dialog>
		)
	}
}
