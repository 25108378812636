import { PureComponent } from 'react'
import jwtdecode from 'jwt-decode'
import request from 'axios'
import { glasConfig } from '../constants/Config'

export default class BaseController extends PureComponent {
	refreshNewToken = () => {
		// console.log('refresh new token');
		const token = localStorage.getItem('token')
		const params = {
			method: 'GET',
			headers: {
				Authorization: token,
			},
			url: glasConfig.url_base + glasConfig.refresh_token,
		}
		// console.log('refreshing');
		request(params)
			.then((response) => {
				if (response.data.code === 0) {
					// console.log('success new token');
					const responseData = response.data.data
					const token = responseData.token
					localStorage.setItem('token', token)
					const decoded = jwtdecode(token)
					localStorage.setItem(
						'expiredRemainTime',
						decoded.expiredTime
					)
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}

	componentDidMount() {
		var interval
		const date = new Date()
		window.onfocus = () => {
			interval = setInterval(() => {
				const expiredRemainTime =
					localStorage.getItem('expiredRemainTime')
				if (
					expiredRemainTime &&
					expiredRemainTime - 300000 <= date.getTime()
				) {
					// Check before 5 minutes
					this.refreshNewToken()
				}
			}, 2000)
		}

		window.onblur = () => {
			clearInterval(interval)
			this.refreshNewToken()
		}
	}

	render() {
		return false
	}
}
