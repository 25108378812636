import React, { PureComponent } from 'react'
import RemoveCircleIcon from 'material-ui/svg-icons/content/remove-circle'
import CheckCircleIcon from 'material-ui/svg-icons/action/check-circle'
import BusyCircleIcon from 'material-ui/svg-icons/action/watch-later'
import { fortuneTellerStatus } from '../constants/Config'
import moment from 'moment'
import LabelIcon from 'material-ui/svg-icons/action/label'
import StarIcon from 'material-ui/svg-icons/toggle/star'
export default class PreviewProfile extends PureComponent {
	constructor(props) {
		super(props)
	}

	getStatus(status) {
		if (status == fortuneTellerStatus.offline) {
			const { reloginTime, lastTimeLogin } = this.props.profile
			const lastTimeLoginFromNow = moment(lastTimeLogin).fromNow()
			const reloginMoment = moment(reloginTime, 'YYYYMMDDHHmmss')
				.add(9, 'hours')
				.format('MM/DD HH:mm')
			const reloginDate = new Date(reloginMoment)
			const today = new Date(moment().format('MM/DD HH:mm'))
			const isPastday = reloginDate.getTime() < today.getTime()
			const label =
				moment(reloginTime, 'YYYYMMDDHHmmss').isValid() && !isPastday
					? ` (${reloginMoment})`
					: '（リクエスト受付中！)'
			return (
				<div style={styles.time}>
					<CheckCircleIcon style={styles.checkCircleIcon} />{' '}
					{lastTimeLoginFromNow + label}
				</div>
			)
		}
		if (
			status == fortuneTellerStatus.calling ||
			status == fortuneTellerStatus.chatting
		) {
			return (
				<div style={styles.timeBusy}>
					<BusyCircleIcon style={styles.busyCircleIcon} /> 電話相談中
				</div>
			)
		}
		if (
			status == fortuneTellerStatus.call ||
			status == fortuneTellerStatus.waiting ||
			status == fortuneTellerStatus.chat
		) {
			return (
				<div style={styles.timeOk}>
					<CheckCircleIcon style={styles.checkCircleIcon} />{' '}
					今すぐ相談OK
				</div>
			)
		}
	}

	render() {
		const { backgroundAvatarPath } = this.props.profile
		let backgroundAvatar = backgroundAvatarPath
			? backgroundAvatarPath
			: '../img/preview_profile_top_bg.png'
		return (
			<div style={styles.container}>
				<div>
					<div style={styles.containerTop}>
						<img src={backgroundAvatar} style={styles.bgTop} />
						{backgroundAvatarPath ? (
							''
						) : (
							<img src={this.props.image} style={styles.avatar} />
						)}
					</div>
					<div style={styles.infoTopContainer}>
						{this.getStatus(this.props.profile.fortuneTellerStatus)}
						<div style={styles.nameTeller}>{this.props.name}</div>
					</div>
					<div style={styles.infoBottomContainer}>
						<div style={styles.infoItem}>
							<div style={styles.labelIBC}>相談スタイル</div>
							<div style={styles.itemsIBC}>
								{this.props.genresChecked.map((v, i) => {
									return (
										<Chip key={`genres-${i}`} label={v} />
									)
								})}
							</div>
						</div>
						<div style={styles.infoItem}>
							<div style={styles.labelIBC}>得意占術</div>
							<div style={styles.itemsIBC}>
								{this.props.methodsChecked.map((v, i) => {
									return (
										<Chip key={`methods-${i}`} label={v} />
									)
								})}
							</div>
						</div>
						<div style={styles.infoItem}>
							<div style={styles.labelIBC}>相談スタイル</div>
							<div style={styles.itemsIBC}>
								{this.props.stylesChecked.map((v, i) => {
									return (
										<Chip key={`styles-${i}`} label={v} />
									)
								})}
							</div>
						</div>
					</div>

					<div style={styles.desContainer}>
						<div style={styles.titleDes}>先生からのメッセージ</div>
						<div style={styles.contentDes}>
							{`${this.props.message}`
								.split('\n')
								.map((item, key) => {
									return (
										<span key={key}>
											{item}
											<br />
										</span>
									)
								})}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const Chip = ({ label }) => {
	return <div style={styles.chip}>{label}</div>
}

const styles = {
	textContentComment: {},
	titleDes: {
		paddingTop: 10,
		paddingBottom: 10,
		marginTop: 10,
		color: '#8b8498',
		fontSize: 13.5,
		textAlign: 'left',
		marginLeft: 12,
	},
	contentDes: {
		backgroundColor: '#fff',
		borderRadius: 10,
		fontSize: 13.5,
		marginLeft: 12,
		marginRight: 12,
		padding: '6px 8px',
		color: '#4d4b51',
		minHeight: 90,
		textAlign: 'left',
		wordBreak: 'break-all',
	},
	desContainer: {
		backgroundImage: `url("../img/bg_des.png")`,
		paddingBottom: 10,
	},
	infoBottomContainer: {
		marginLeft: 12,
		marginRight: 12,
	},
	infoItem: {
		display: 'flex',
		flexDirection: 'row',
	},
	chip: {
		color: '#a78eda',
		backgroundColor: '#f5f0fd',
		borderRadius: '18px',
		padding: '4px',
		display: 'inline-block',
		fontSize: 11,
		margin: '5px 5px 0px 5px',
	},
	labelIBC: {
		color: '#8b8498',
		fontSize: 13,
		flex: 2,
		marginTop: 6,
		textAlign: 'left',
	},
	itemsIBC: {
		flex: 4,
		textAlign: 'left',
	},
	infoTopContainer: {
		borderBottom: '1px solid #e0e0e0',
		marginLeft: 12,
		marginRight: 12,
		paddingBottom: 10,
	},
	appealCommentContainer: {
		borderBottom: '1px solid #e0e0e0',
		backgroundImage: `url("../img/bg_des.png")`,
	},
	titleAppealComment: {
		paddingTop: 10,
		paddingBottom: 5,
		marginTop: 10,
		color: '#8b8498',
		fontSize: 13.5,
		textAlign: 'left',
		marginLeft: 12,
	},
	contentAppealComment: {
		backgroundColor: '#fff',
		marginTop: 5,
		marginBottom: 12,
		marginLeft: 12,
		marginRight: 12,
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 10,
		paddingRight: 10,
		textAlign: 'left',
		alignItems: 'left',
		fontSize: 12,
		borderRadius: 5,
		display: 'flex',
	},
	labelIcon: {
		color: 'rgb(106, 214, 203)',
		width: 17,
		height: 17,
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	time: {
		color: '#8b8498',
		fontSize: 9,
	},
	timeOk: {
		color: '#6ad6cb',
		fontSize: 9,
	},
	timeBusy: {
		color: '#f78282',
		fontSize: 9,
	},
	nameTeller: {
		color: '#4d4b51',
		fontSize: 13.5,
	},
	removeCircleIcon: {
		width: 11,
		color: '#aea9b9',
	},
	checkCircleIcon: {
		width: 11,
		color: '#6ad6cb',
	},
	busyCircleIcon: {
		width: 11,
		color: '#f78282',
	},
	container: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	containerTop: {
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: 115,
	},
	bgTop: {
		width: '100%',
		marginBottom: 0,
		height: '100%',
	},
	avatar: {
		display: 'block',
		height: 72,
		width: 72,
		margin: 0,
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		borderRadius: '51%',
		border: '2px solid #ffffff',
		marginTop: -50,
	},
}
