import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import * as MyProfileActions from '../actions/MyProfileActions'
import DialogNotifi from './Dialog'
import RewardMenu from './RewardMenu'
import RewardRegistForm from './RewardRegistForm'
import RewardInfo from './Reward/RewardInfo'

class Reward extends Component {
	constructor(props) {
		super(props)
	}

	componentWillMount() {
		const { dispatch, MyProfile } = this.props
		if (!MyProfile.loaded) {
			dispatch(MyProfileActions.fetch())
		}
	}

	componentDidMount() {
		//年齢認証がまだの場合は待機できない
		const { MyProfile } = this.props
		if (!Fetch.isAgeVerify(MyProfile.data.verifyAge)) {
			if (Object.keys(this.refs).length) {
				DialogNotifi.getDialog().handleOpen('AGEAUTH')
			}
		}
	}

	render() {
		const { MyProfile } = this.props

		return (
			<div>
				<div className="content reward reward-default">
					<h1 className="content__title">精算</h1>
					<RewardMenu />
					<div className="primary">
						<div className="primary-title">
							<h1 className="primary-title__main">
								報酬確認・精算
							</h1>
						</div>
						<RewardRegistForm MyProfile={MyProfile} />
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(Reward)
