import React, { Component } from 'react'
import { glasConfig } from '../constants/Config'
import Checkbox from 'material-ui/Checkbox'
import TextField from 'material-ui/TextField'
import { isMobile } from 'react-device-detect'

export default class SettingAppealComment extends Component {
	getValueInputAppealComment(e) {
		this.props.getValueInputAppealComment(e)
	}

	setMultiline() {
		if (isMobile) return true
		return false
	}
	render() {
		const { data } = this.props
		const valueTemp = data.value ? data.value.length : 0
		const countLength =
			glasConfig.setting.comment > valueTemp ? `${valueTemp}/30` : ''
		return (
			<dd className="definition__data">
				<div className="textfield spread_width">
					<TextField
						className="textfield__textarea"
						fullWidth={true}
						name={`${
							data.id == 1
								? 'first'
								: data.id == 2
								? 'second'
								: 'third'
						}TellerComment`}
						disabled={data.disabled}
						id="comment_introduction"
						placeholder="アピールポイントを追加"
						value={data.value === undefined ? '' : data.value}
						onChange={(e) => this.getValueInputAppealComment(e)}
						floatingLabelText={countLength}
						floatingLabelFixed={true}
						multiLine={this.setMultiline()}
						floatingLabelStyle={textfieldStyles.label}
						textareaStyle={textfieldStyles.textarea}
						maxlength={`${glasConfig.setting.comment}`}
					/>
				</div>
			</dd>
		)
	}
}

const textfieldStyles = {
	label: {
		bottom: -40,
		top: 'auto',
		right: 0,
	},
	hint: {
		top: 0,
		bottom: 'auto',
	},
	textarea: {
		marginTop: 0,
		marginBottom: 0,
		height: '100%',
	},
	block: {
		height: 44,
		width: '100%',
	},
}
