import React, { Component } from 'react'
import * as ConfigMessage from '../constants/ConfigMessage'
import * as Fetch from '../util/Fetch'
import moment from 'moment'
import '../assets/scss/leftRequest.scss'
import { connect } from 'react-redux'
import {
	addItemToList,
	removeItemFromList,
	post,
} from '../actions/ChatRequestActions'
import {
	CHAT_NUMBER_TOTAL,
	CHAT_REQUEST_READ_REFRESH,
} from '../constants/ActionTypes'
import DialogNotifi from './Dialog'
import { Link } from 'react-router-dom'
import { pauseSound, playNotificationSound } from '../util/Sound'
import { isPWA } from '../helper/helpFunction'
import { fortuneTellerStatus } from '../constants/Config'
require('moment/locale/ja')

class LeftRequestList extends Component {
	disableWhenClick(event) {
		const {
			dispatch,
			chatUserId,
			data,
			closeNavLeft,
			ChatNumberTotal,
			ChatRequestUnRead,
			ChatRequestRead,
			openWarningFullChatDialog,
			maxNumberChating,
			MyProfile,
		} = this.props
		const tellerId = Fetch.tellerId()
		const userId = data.fromId === tellerId ? data.toId : data.fromId
		// let lengthUserChatting = Object.keys(ChatRequestRead.data).length;
		if (
			MyProfile.data.remainChatNumber === 0 &&
			event.target.id === 'isRequest'
		) {
			openWarningFullChatDialog(userId)
			dispatch(post({ userId: userId, msgId: data.msgId, accept: false }))
			return false
		}

		let chattingUnreadNumber = ChatNumberTotal.data.chattingUnreadNumber
		let chatedUnreadNumber = ChatNumberTotal.data.chatedUnreadNumber
		let ChatRequestUnReadOld = ChatRequestUnRead.data[userId]
		// let ChatRequestReadOld = ChatRequestRead.data[userId];
		let totalChatRequestUnRead = 0
		let totalChatRequestRead = 0

		let nowMoment = moment.now()
		let timeCall = moment(this.props.data.serverTime, 'YYYYMMDDHHssmm')
			.add(9, 'hours')
			.add(2, 'minutes')
			.format('x')
		if (
			data.msgType === 'ACCEPT_CHAT' &&
			timeCall + 1000 * 90 > nowMoment
		) {
			if (MyProfile.data.isDisableSoundRequestChat && MyProfile.data.fortuneTellerStatus === fortuneTellerStatus.chatting) {
			} else {
				playNotificationSound(MyProfile.data.ringtoneNumber)
			}
			pauseSound()
			DialogNotifi.getDialog().handleOpen('CHAT', data)
		}

		if (ChatRequestUnReadOld !== undefined) {
			totalChatRequestUnRead =
				totalChatRequestUnRead + ChatRequestUnReadOld.unreadMsgBadge
			ChatRequestUnReadOld.unreadMsgBadge = 0
			dispatch(
				removeItemFromList(
					ChatRequestUnRead.data,
					userId,
					CHAT_REQUEST_READ_REFRESH
				)
			)
			dispatch(
				addItemToList(
					ChatRequestRead.data,
					ChatRequestUnReadOld,
					userId,
					CHAT_REQUEST_READ_REFRESH,
					'READ'
				)
			)
		}

		/*if(ChatRequestReadOld !== undefined){
	  totalChatRequestRead = totalChatRequestRead + ChatRequestReadOld.unreadMsgBadge;
	  dispatch(removeItemFromList(ChatRequestRead.data, userId, CHAT_REQUEST_READ_REFRESH));
	  dispatch(addItemToList(ChatRequestRead.data, ChatRequestReadOld, userId, CHAT_REQUEST_READ_REFRESH, 'READ'));
	}*/
		dispatch({
			type: CHAT_NUMBER_TOTAL,
			data: {
				chattingUnreadNumber:
					chattingUnreadNumber -
					totalChatRequestUnRead -
					totalChatRequestRead,
				chatedUnreadNumber: chatedUnreadNumber,
			},
		})

		if (chatUserId === userId) {
			event.preventDefault()
		}

		if (data.msgType !== 'ACCEPT_CHAT') {
			this.props.history.push(`/home/chat/${userId}`)
		}
		closeNavLeft()
	}

	render() {
		const { data, chatUserId } = this.props

		/*if (!data.fromId) {
	  return null
	}*/

		const tellerId = Fetch.tellerId()
		const userId = data.fromId === tellerId ? data.toId : data.fromId
		const msgMap = ConfigMessage.getMessageData(
			data,
			'displayTextLeftHistory'
		)
		const message = msgMap.msg

		let nowMoment = moment.now()
		let dateSendMessage = (data.chatDraft && data.serverTimeChatDraft >= data.serverTime ? data.serverTimeChatDraft : data.serverTime)
		let timeCall = moment(this.props.data.serverTime, 'YYYYMMDDHHssmm')
			.add(9, 'hours')
			.add(2, 'minutes')
			.format('x')
		let stringClassRequestChat = ''
		if (
			data.msgType === 'ACCEPT_CHAT' &&
			timeCall + 1000 * 90 > nowMoment
		) {
			stringClassRequestChat =
				'btn-customer customer-request-chat animate-flicker'
		} else {
			if (chatUserId === userId) {
				stringClassRequestChat = 'btn-customer customer_choose'
			} else {
				stringClassRequestChat = 'btn-customer customer-request-chat'
			}
		}

		/*let nowMoment = moment.now();
	let timeCall = moment(this.props.data.serverTime, 'YYYYMMDDHHssmm').add(9, 'hours').add(2, 'minutes').format('x');
	if((timeCall + 1000 * 90) > nowMoment){
	  stringClassRequestChat = stringClassRequestChat + 'animate-flicker';
	}*/

		return (
			<Link
				to={'#'}
				key={'request_' + data.msgId + data.serverTime}
				onClick={(e) => this.disableWhenClick(e, data)}
				className={'list__item'}
				id={data.msgType === 'ACCEPT_CHAT' && 'isRequest'}
			>
				<div
					className={stringClassRequestChat}
					id={data.msgType === 'ACCEPT_CHAT' && 'isRequest'}
				>
					<div className='row-horizontal'>
						<div className='row'>
							<p className="btn-customer__item is_name" style={isPWA() ? { maxWidth: '80%' } : { maxWidth: 150 }}
								id={data.msgType === 'ACCEPT_CHAT' && 'isRequest'}>
								{data.friendName === undefined
									? data.userName
									: data.friendName}
							</p>
							{data.unreadMsgBadge > 0 ? (
								<div>
									{
										isPWA() ?
											<div className={'unread-pwa'}>
												{data.unreadMsgBadge > 99 ? '99+' : data.unreadMsgBadge}
											</div> :
											<div className={'unread-msg-box'}>
												{data.unreadMsgBadge > 99 ? '99+' : data.unreadMsgBadge}
											</div>
									}
								</div>
							) : null}
						</div>
						<p className="btn-customer__item is_time"
							id={data.msgType === 'ACCEPT_CHAT' && 'isRequest'}>
							{moment(
								moment(dateSendMessage, 'YYYYMMDDHHmmss')
									.add(9, 'hours')
									.format('YYYY-MM-DD HH:mm:ss')
							).fromNow()}
						</p>
					</div>
					<p
						className="btn-customer__item is_comment"
						id={data.msgType === 'ACCEPT_CHAT' && 'isRequest'}
					>
						{message}
					</p>
				</div>
			</Link>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		ChatNumberTotal: state.ChatNumberTotal,
		ChatRequestUnRead: state.ChatRequestUnRead,
		ChatRequestRead: state.ChatRequestRead,
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(LeftRequestList)
