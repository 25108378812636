/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import { colors, DEVICE } from '../../../util/StellaConstant'
import {
	Button,
	ButtonArea,
	Content,
	Notice,
	Ruler,
	Title,
} from '../StyledPointReport/Dialogs/StyledDialogPointReportGuide'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { connect } from 'react-redux'

const DialogPointReportGuide = (props) => {
	const { MyProfile, openSetTargetPoint } = props
	const classes = useStyles()
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if(!MyProfile.data.isClickTabPointReport) {
			setOpen(true)
		}
	}, [MyProfile])

	const closeDialog = () => {
		setOpen(false)
	}

	const setTargetPoint = () => {
		closeDialog()
		setTimeout(() => {
			openSetTargetPoint()
		}, 0)
		
	}

	return (
		<Dialog
			open={open}
			classes={{
				paper: classes.paper,
			}}
		>
			<IconButton
				aria-label="close"
				onClick={closeDialog}
				sx={{
					position: 'absolute',
					right: 0,
					top: 0,
					color: colors.oldLavender,
				}}
			>
				<CloseIcon />
			</IconButton>
			<Title>「レポート」とは？</Title>
			<Ruler />
			<Content>
				このページでは、稼ぎたい目標金額を設定し、<br />
				獲得状況と目標達成状況をグラフで確認することができます。<br /><br />
				グラフには「今月の獲得ポイント」と「目標ポイント」の推移が表示されます。
			</Content>
			<Notice>斜め線の目標ポイントよりも、獲得ポイントが高ければ目標達成ペースです!!</Notice>
			<ButtonArea>
				<Button buttonType="ACCEPT" onClick={setTargetPoint}>
					目標設定する
				</Button>
			</ButtonArea>
		</Dialog>
	)
}

const useStyles = makeStyles({
	paper: {
		[`@media ${DEVICE.mobileS}`]: {
			width: '90%',
			maxWidth: 288,
			padding: '28px 22px 25px 19px'
		},
		[`@media ${DEVICE.laptop}`]: {
			width: '45.9%',
			maxWidth: 470,
			padding: '45px 21px 29px 29px'
		},
		[`@media ${DEVICE.laptopL}`]: {
			width: '41.3%',
			maxWidth: 530,
			padding: '37px 28px 36px 33px'
		},
	},
})

const mapStateToProps = (store) => {
	return {
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(DialogPointReportGuide)
