import { MYINFO_ACCOUNT_AGE_AUTH_POST } from '../constants/ActionTypes'

const initialState = {
	data: {},
	posted: false,
}

export const MyAccountAgeAuth = (state = initialState, action) => {
	switch (action.type) {
		case MYINFO_ACCOUNT_AGE_AUTH_POST:
			return Object.assign({}, state, action.data)
		default:
			return state
	}
}
