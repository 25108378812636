import React, { Component } from 'react'
import { glasConfig, Styles } from '../constants/Config'
import Checkbox from 'material-ui/Checkbox'

export default class SettingStyles extends Component {
	handleClick(event, isInputChecked) {
		this.props.handleClick(
			event,
			isInputChecked,
			'styles',
			'相談スタイルを選択してください'
		)
	}

	render() {
		let list = []
		let styles = Styles

		for (let i in styles) {
			let checked = false
			let disabled = false
			let defaultChecked = false
			if (this.props.checked.indexOf(styles[i]) !== -1) {
				checked = true
			}
			if (this.props.disabled.indexOf(styles[i]) !== -1) {
				disabled = true
			}
			if (
				this.props.defaultChecked !== undefined &&
				this.props.defaultChecked.indexOf(styles[i]) !== -1
			) {
				defaultChecked = true
			} else if (
				this.props.defaultChecked !== undefined &&
				this.props.defaultChecked.length === glasConfig.setting.styles
			) {
				disabled = true
			}
			list.push(
				<li className="list__item" key={styles[i]}>
					<Checkbox
						label={styles[i]}
						onCheck={(e, i) => this.handleClick(e, i)}
						checked={checked}
						disabled={disabled}
						defaultChecked={defaultChecked}
						id={'styles-' + i}
						name="styles[]"
						value={styles[i]}
					/>
				</li>
			)
		}

		return (
			<dl className="definition wide_bottom">
				<dt className="definition__title">
					相談スタイル
					<span className="definition__description">3つ選択</span>
				</dt>
				<dd className="definition__data data_styles">
					<ul className="list flex_start">{list}</ul>
				</dd>
			</dl>
		)
	}
}
