export default class BellRef {
	static modal
	static dumpState

	static setModal(modal) {
		this.modal = modal
	}

	static getModal() {
		return this.modal
	}
}
