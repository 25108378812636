import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

export default class DialogContactConfirm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
		}
	}

	openDialog = () => {
		this.setState({ show: true })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				className="dialogShowImage"
			>
				<i
					className="material-icons tab__icons closeBlock closeImageShow"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<img src={this.props.imageShow} />
			</Dialog>
		)
	}
}
