import React, { Component } from 'react'

import request from 'axios'
import { glasConfig, Gender } from './../constants/Config'
import moment from 'moment'

class AnswerUserInfo extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userData: {},
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.userId === this.props.userId) {
			return
		}
		this.loadUserData(nextProps.userId)
	}

	loadUserData = (userId) => {
		const url = glasConfig.url_base + glasConfig.path_user_info + userId
		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: localStorage.getItem('token'),
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({
						userData: response.data.data,
					})
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	render() {
		const { userData } = this.state
		const worries =
			Array.isArray(userData.worries) && userData.worries.length > 0
				? userData.worries.join('、')
				: '---'

		const userDataItem = (
			<li className="list__item">
				<section className="chat-karte is_principal">
					<h2 className="chat-karte-title">
						<i className="material-icons chat-karte-title__icon">
							person
						</i>
						<span className="chat-karte-title__text">相談者</span>
					</h2>
					<div className="chat-karte-name">
						<p className="chat-karte-name__main">
							{userData.userName}
						</p>
						<p className="chat-karte-name__sex">
							(
							{userData.gender ? Gender[userData.gender] : '----'}
							)
						</p>
					</div>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">ふりがな</dt>
						<dd className="chat-karte-chart__data">
							{userData.nickName ? userData.nickName : '---'}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">生年月日</dt>
						<dd className="chat-karte-chart__data">
							{moment(userData.birthday).format('YYYY-MM-DD')}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">出生地</dt>
						<dd className="chat-karte-chart__data">
							{!userData.placeOfBirth
								? '---'
								: placeFilter(userData.placeOfBirth)}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">出生時間</dt>
						<dd className="chat-karte-chart__data">
							{moment(userData.timeOfBirth, 'HHmm').isValid()
								? moment(userData.timeOfBirth, 'HHmm').format(
										'HH:mm'
								  )
								: '---'}
						</dd>
					</dl>
					<dl className="chat-karte-chart">
						<dt className="chat-karte-chart__title">悩み</dt>
						<dd className="chat-karte-chart__data">{worries}</dd>
					</dl>
				</section>
			</li>
		)

		return !this.props.isShowMyProfile ? (
			<p
				style={{
					textAlign: 'center',
					width: 180,
					margin: 'auto',
					paddingTop: 220,
				}}
			>
				お客様のご希望により、 カルテは非表示と なっています。
			</p>
		) : (
			<ul className="chat-karte-list list column_1">
				{userDataItem}
				<Partner name="相手1" partner={userData.partner1} />
				<Partner name="相手2" partner={userData.partner2} />
			</ul>
		)
	}
}

const Partner = (props) => {
	var partnerData = {
		name: '---',
		nickName: '---',
		gender: '---',
		birthday: '---',
		placeOfBirth: '---',
		timeOfBirth: '---',
		relation: '---',
	}

	if (props.partner) {
		if (props.partner.name) partnerData.name = props.partner.name
		if (props.partner.nickName)
			partnerData.nickName = props.partner.nickName
		if (props.partner.gender)
			partnerData.gender = Gender[props.partner.gender]
		if (props.partner.birthday)
			partnerData.birthday = moment(props.partner.birthday).format(
				'YYYY-MM-DD'
			)
		if (
			props.partner.placeOfBirth &&
			props.partner.placeOfBirth !== 'UNKNOWN'
		)
			partnerData.placeOfBirth = placeFilter(props.partner.placeOfBirth)
		if (moment(props.partner.timeOfBirth, 'HHmm').isValid())
			partnerData.timeOfBirth = moment(
				props.partner.timeOfBirth,
				'HHmm'
			).format('HH:mm')
		if (props.partner.relation)
			partnerData.relation = props.partner.relation
	}

	return (
		<li className="list__item">
			<section className="chat-karte is_partner">
				<h2 className="chat-karte-title">
					<i className="material-icons chat-karte-title__icon">
						person_outline
					</i>
					<span className="chat-karte-title__text">{props.name}</span>
				</h2>
				<div className="chat-karte-name">
					<p className="chat-karte-name__main">{partnerData.name}</p>
					<p className="chat-karte-name__sex">
						({partnerData.gender})
					</p>
				</div>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">ふりがな</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.nickName ? partnerData.nickName : '---'}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">生年月日</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.birthday}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">出生地</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.placeOfBirth}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">出生時間</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.timeOfBirth}
					</dd>
				</dl>
				<dl className="chat-karte-chart">
					<dt className="chat-karte-chart__title">関係</dt>
					<dd className="chat-karte-chart__data">
						{partnerData.relation}
					</dd>
				</dl>
			</section>
		</li>
	)
}

const placeFilter = (place) => {
	let exceptPlaces = ['東京', '大阪', '京都', '北海道']
	return exceptPlaces.indexOf(place) === -1 ? `${place}県` : place
}

const getZodiac = (birthday) => {
	const zodiacs = [
		{
			name: 'おひつじ座',
			start: {
				month: 3,
				day: 21,
			},
			end: {
				month: 4,
				day: 19,
			},
		},
		{
			name: 'おうし座',
			start: {
				month: 4,
				day: 20,
			},
			end: {
				month: 5,
				day: 20,
			},
		},
		{
			name: 'ふたご座',
			start: {
				month: 5,
				day: 21,
			},
			end: {
				month: 6,
				day: 21,
			},
		},
		{
			name: 'かに座',
			start: {
				month: 6,
				day: 22,
			},
			end: {
				month: 7,
				day: 22,
			},
		},
		{
			name: 'しし座',
			start: {
				month: 7,
				day: 23,
			},
			end: {
				month: 8,
				day: 22,
			},
		},
		{
			name: 'おとめ座',
			start: {
				month: 8,
				day: 23,
			},
			end: {
				month: 9,
				day: 22,
			},
		},
		{
			name: 'てんびん座',
			start: {
				month: 9,
				day: 23,
			},
			end: {
				month: 10,
				day: 23,
			},
		},
		{
			name: 'さそり座',
			start: {
				month: 10,
				day: 24,
			},
			end: {
				month: 11,
				day: 22,
			},
		},
		{
			name: 'いて座',
			start: {
				month: 11,
				day: 23,
			},
			end: {
				month: 12,
				day: 21,
			},
		},
		{
			name: 'やぎ座',
			start: {
				month: 12,
				day: 22,
			},
			end: {
				month: 1,
				day: 19,
			},
		},
		{
			name: 'みずがめ座',
			start: {
				month: 1,
				day: 20,
			},
			end: {
				month: 2,
				day: 18,
			},
		},
		{
			name: 'うお座',
			start: {
				month: 2,
				day: 19,
			},
			end: {
				month: 3,
				day: 20,
			},
		},
	]

	const date = moment(birthday)
	const month = date.month() + 1
	const day = date.date()
	let zodiacName = ''

	for (let x in zodiacs) {
		let zodiac = zodiacs[x]

		if (
			(month == zodiac.start.month && day >= zodiac.start.day) ||
			(month == zodiac.end.month && day <= zodiac.end.day)
		) {
			zodiacName = zodiac.name
			break
		}
	}

	return zodiacName
}

export default AnswerUserInfo
