import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as TimelineActions from '../actions/TimelineActions'
import { Gender } from '../constants/Config'
import * as moment from 'moment'

class TimelineLikeContent extends Component {
	constructor(props) {
		super(props)
	}

	componentWillMount() {
		const { dispatch, postId, likeNumber } = this.props
		if (likeNumber > 0) {
			dispatch(TimelineActions.timelineLikeGet({ postId: postId }))
		}
	}

	componentWillReceiveProps(nextProps) { }

	checkAge = (birthday) => {
		const currentYear = moment().year()
		const currentMonth = moment().month() + 1
		const currentDay = moment().day()
		const yearBirth = moment(birthday, 'YYYYMMDD').year()
		const monthBirth = Number(moment(birthday).format("MM"))
		const dayBirth = Number(moment(birthday).format("DD"))
		let age = currentYear - yearBirth
		if (currentMonth < monthBirth) {
			return age - 1
		} else if (currentMonth === monthBirth) {
			if (currentDay < dayBirth) {
				return age - 1
			}
			return age
		}
		return age;
	}
	render() {
		let likes = ''

		if (
			this.props.Timeline.hasOwnProperty('likes') &&
			this.props.likeNumber > 0
		) {
			if (this.props.postId in this.props.Timeline.likes) {
				likes = Array.prototype.map.call(
					this.props.Timeline.likes[this.props.postId],
					(current, index) => {
						return (
							<li
								className="list__item"
								key={current.userId + current.time}
							>
								<div className="timeline-comment">
									<p className="timeline-comment__name">
										{this.checkAge(current.birthday)}代{' '}
										{this.props.checkGender(current.gender)}
									</p>
									<p className="timeline-comment__time">
										{this.props.checkDiffDate(current.time)}
									</p>
								</div>
							</li>
						)
					}
				)
			}
		}

		if (this.props.isView) {
			return (
				<div className="timeline__inner border_top">
					<ul className="list column_1">{likes}</ul>
				</div>
			)
		} else {
			return false
		}
	}
}

const mapStateToProps = (store) => {
	return {
		Timeline: store.Timeline,
	}
}

export default connect(mapStateToProps)(TimelineLikeContent)
