import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactMenu from './ContactMenu'
import Checkbox from 'material-ui/Checkbox'
import ContactConfirm from './ContactConfirm'
import * as ContactActions from '../actions/ContactActions'
import SparkMD5 from 'spark-md5'
import DialogContactConfirm from './DialogContactConfirm'
import { glasConfig } from '../constants/Config'
import $ from 'jquery'
import DialogShowImage from './DialogShowImage'
import { isAndroid,isMobile } from 'react-device-detect'
import * as moment from 'moment'
import Loading from './Loading'
import Snackbar from 'material-ui/Snackbar'
import { LinkItUrl } from 'react-linkify-it';

const CHECKED_YES = 1
const CHECKED_NO = 0

class ContactDetail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			content: '',
			checked: CHECKED_YES,
			image: null,
			submitted: false,
			file: null,
			threadId: this.props.match.params.id,
			data: {},
			imageShow: '',
			loading: false,
			errImg:false
		}
	}

	componentWillUnmount() {
		$('.secondary-header').removeClass('hiddenClass')
		$('.menu-header').removeClass('hiddenClass')
		$('.header-back').addClass('hiddenClass')
		$('.component-title').text('')
	}

	componentDidMount() {
		this.getDetailThread()
		$('.secondary-header').addClass('hiddenClass')
		$('#lead-nav').addClass('hiddenClass')
		$('.header-back').removeClass('hiddenClass')
		$('.component-title').text('お問い合わせ/返信内容')
	}

	getDetailThread() {
		const { dispatch, MyProfile } = this.props
		dispatch(
			ContactActions.getDetailThread({
				threadId: this.state.threadId,
				userId: MyProfile.data.fortuneTellerId,
			})
		)
	}

	componentDidUpdate(prevProps) {
		if (prevProps.Contact.detail.data !== this.props.Contact.detail.data) {
			this.setState({
				data: this.props.Contact.detail.data,
				loading: false,
			})
		}
		if (
			prevProps.Contact.resolve.data !== this.props.Contact.resolve.data
		) {
			this.getDetailThread()
		}
		if (prevProps.Contact.post.data !== this.props.Contact.post.data) {
			this.setState({ loading: false })
			window.location.reload()
		}
	}

	selectFile = (event) => {
		let fileType =event.target.files[0].type
		if(fileType==='image/png' || fileType==='image/jpeg' || fileType==='image/jpg' || fileType==='image/gif'){
			this.getImageData(event.target.files[0])
			this.setState({ file: event.target.files[0] })
		}else{
			this.setState({errImg:true})
		}
	}

	getImageData(file) {
		let fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		fileReader.onload = (ev) => {
			this.setState({
				error: false,
				message: '',
				image: ev.target.result,
			})
		}
	}

	handleChangeContent(e) {
		const content = e.target.value
		this.setState({ content })
	}

	handleClick(type) {
		this.setState({ checked: type })
	}

	onSubmit = () => {
		const length = this.state.content.replace(/\s/g, '').length
		if (length <= 0) {
			this.setState({ inValidContent: true })
			return
		}
		this.setState({ submitted: true })
	}

	showDialog = () => {
		this.dialogConfirm.openDialog(() => this.onConfirm())
	}

	onConfirm() {
		let params = {}
		let formData = new FormData()
		const { image, file, content, checked, threadId } = this.state
		const { dispatch, MyProfile } = this.props
		this.setState({ loading: true })
		if (image) {
			formData.append('file', file)
			let fileReader = new FileReader()
			let spark = new SparkMD5.ArrayBuffer()
			fileReader.onload = (event) => {
				let data = event.target.result
				spark.append(data)
				let hash = spark.end()
				formData.append('md5Sum', hash)
				params.fileData = formData
				params.body = content
				params.userId = MyProfile.data.fortuneTellerId
				params.userName = MyProfile.data.fortuneTellerName
				params.reply = checked
				params.threadId = threadId

				dispatch(ContactActions.postFileQuestion(params))
			}
			fileReader.readAsArrayBuffer(this.state.file)
		} else {
			params.body = content
			params.userId = MyProfile.data.fortuneTellerId
			params.userName = MyProfile.data.fortuneTellerName
			params.reply = checked
			params.threadId = threadId
			dispatch(ContactActions.postQuestion(params))
		}
	}

	onCancel = () => {
		this.setState({ submitted: false })
	}

	onResolveQuestion = () => {
		const { dispatch } = this.props
		dispatch(
			ContactActions.resolveQuestion({ threadId: this.state.threadId })
		)
	}

	showImage = (name) => {
		this.setState({ imageShow: glasConfig.url_image + '/' + name })
		this.dialogShowImage.openDialog()
	}

	//  isValidURL = (string)=> {
	// 	var res = string.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm);
	// 	if(res!==null){
	// 		return <a className="linkUrl" target="_blank" href={string}>{string}</a>
	// 	}
	// 	return string
	//   };

	render() {
		const { data } = this.state
		const regexToMatch = /@([\w_]+)/;

		return (
			<div className="content contactContent contactContentDetail">
				<h1 className="content__title hidden-sp">お問い合わせ</h1>
				<ContactMenu />
				<p className="contact-detail-title hidden-sp">
					お問い合わせ履歴 &gt; お問い合わせ/返信内容
				</p>
				{this.state.submitted ? (
					<ContactConfirm
						item={this.state}
						onCancel={this.onCancel}
						onConfirm={this.showDialog}
					/>
				) : (
					<div>
						<div className="primary contactPrimary">
							<div className="primary-title hidden-sp">
								<h1 className="primary-title__main">
									お問い合わせ/返信内容
								</h1>
							</div>
							<div className="support-wrap">
								<div className="support-content">
									{data.resultData &&
										data.resultData.map((item, index) => (
											<div className="support-detail-area" key={index}>
												{item['kind'] ==
													glasConfig.contact
														.kindUserSend ? (
													<div className="support-question">
														<p className="support-title">
															お問い合わせ内容
														</p>
														<div className="support-subject">{item.subject}</div>
														<div className="support-question-datetime">
															{moment(
																item[
																'senddate'
																],
																'YYYY-MM-DD HH:mm'
															).format(
																'YYYY/MM/DD HH:mm'
															)}
														</div>
														{(item.content !== '' && item.content!==null)&&
														<>
															<div className="support-content">ご利用環境</div>
															<div className="support-content">{item.content}</div>
														</>
														}
														<div className="support-question-content">
															<LinkItUrl
															component={(match, key) => <a href={match} key={key}>{match}</a>}
															regex={regexToMatch}
															>
																{item['body']}
															</LinkItUrl>
														</div>
														{item['images'].length >
															0 &&
															item['images'].map(
																(
																	image,
																	idx
																) => (
																	<a
																		key={idx}
																		onClick={() =>
																			this.showImage(
																				image.filename
																			)
																		}
																		className="link-image"
																	>
																		添付ファイル
																	</a>
																)
															)}
													</div>
												) : (
													<div className="support-reply">
														<div className="support-reply-inner">
															<div className="support-reply-inner-top-admin">
																<div className="support-question-title">
																	{item[
																		're_send_id'
																	]
																		? 'カスタマーサポートからの返信'
																		: item[
																		'subject'
																		]}
																</div>
																<div className="support-question-datetime">
																	{moment(
																		item[
																		'senddate'
																		],
																		'YYYY-MM-DD HH:mm'
																	).format(
																		'YYYY/MM/DD HH:mm'
																	)}
																</div>
																<p className="support-question-content-admin">
																	<LinkItUrl
																	component={(match, key) => <a href={match} key={key}>{match}</a>}
																	regex={regexToMatch}
																	>
																		{item.body}
																	</LinkItUrl>
																</p>
															</div>
															{item['images']
																.length > 0 &&
																item[
																	'images'
																].map(
																	(
																		image,
																		idx
																	) => (
																		<a
																			key={idx}
																			onClick={() =>
																				this.showImage(
																					image.filename
																				)
																			}
																			className="link-image"
																		>
																			添付ファイル
																			<br></br>
																		</a>
																	)
																)}
														</div>
														{!data.isClose && (
															<div className="support-reply-confirm">
																<span classname="support-reply-text">
																	この内容で解決しましたか？
																</span>
																<button
																	onClick={
																		this
																			.onResolveQuestion
																	}
																>
																	解決した
																</button>
															</div>
														)}
													</div>
												)}
											</div>
										))}
								</div>
							</div>
						</div>
						<div className="primary contactPrimary">
						<div className="primary-title" style={isMobile?{paddingLeft:15,paddingTop:3}:{}}>
								<h1 className="primary-title__main title-contact-rep">
									返信フォーム
								</h1>
							</div>
							<p className="titleTextBox">お問い合わせ内容</p>
							<textarea
								className="inputCus inputContact"
								onChange={(e) => this.handleChangeContent(e)}
								placeholder="具体的にお問い合わせ内容をご記入ください。 スクリーンショットを添付いただきますと対応がスムーズになります。"
								value={this.state.content}
							/>
							{this.state.inValidContent && (
								<p className="errMsgContact">
									お問い合わせ内容を入力してください
								</p>
							)}
							<div className="fileContact" style={{marginBottom:44}}>
								<div className="btn-wrap btnFile">
									<label className="btn-raised color_white">
										<p className="btn-raised__text">
											ファイルを選択
										</p>
										<input
											type="file"
											className="btn-raised__file inputChooseFile"
											accept="image/*"
											onChange={(e) => this.selectFile(e)}
										/>
									</label>
								</div>
								<p className="txtFileContact">
									{this.state.image
										? '写真1枚'
										: 'ファイル未選択'}
								</p>
								{this.state.image && (
									<img
										src={this.state.image}
										className="imageContact"
									/>
								)}
							</div>
							<p className="titleTextBox">返信の有無</p>
							<div
								className={
									isAndroid
										? 'checkBoxContact checkBoxContactAndroid'
										: 'checkBoxContact checkBoxContactIOS'
								}
							>
								<Checkbox
									label="必要"
									onCheck={(e) =>
										this.handleClick(CHECKED_YES)
									}
									checked={this.state.checked === CHECKED_YES}
									style={{ color: '#c7bfbf' }}
									// iconStyle={{fill:'rgba(17, 173, 157, 1)' }}
									labelStyle={{fontWeight: 'normal' }}
								/>
								<Checkbox
									label="不要"
									onCheck={(e) =>
										this.handleClick(CHECKED_NO)
									}
									checked={this.state.checked === CHECKED_NO}
									style={{ color: '#c7bfbf' }}
									// iconStyle={{fill:'rgba(17, 173, 157, 1)' }}
									labelStyle={{fontWeight: 'normal' }}
								/>
							</div>
							<button
								onClick={this.onSubmit}
								className="submitContact"
							>
								内容確認
							</button>
						</div>
					</div>
				)}
				<DialogContactConfirm
					ref={(dialog) => (this.dialogConfirm = dialog)}
				/>
				<DialogShowImage
					ref={(dialog) => (this.dialogShowImage = dialog)}
					imageShow={this.state.imageShow}
				/>
				<Loading display={this.state.loading} />
				<Snackbar
					open={this.state.errImg}
					message={'メッセージを送信できません。このユーザーが存在していません。'}
					autoHideDuration={2500}
					onRequestClose={e => this.setState({errImg:false})}
				/>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		MyProfile: store.MyProfile,
		Contact: store.Contact,
	}
}

export default connect(mapStateToProps)(ContactDetail)
