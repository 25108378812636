import React, { Component } from 'react'
import { Link } from 'react-router-dom'
export default () => {
	let pathName = window.location.pathname

	let manage =
		'btn-tab' +
		(pathName === '/home/customermanagement' ? ' is-active' : '')
	let follower =
		'btn-tab' +
		(pathName.indexOf('/home/customermanagement/follower') !== -1
			? ' is-active'
			: '')
	let notification =
		'btn-tab' +
		(pathName.indexOf('/home/customermanagement/notification') !== -1
			? ' is-active'
			: '')
	let block =
		'btn-tab' +
		(pathName.indexOf('/home/customermanagement/block') !== -1
			? ' is-active'
			: '')

	return (
		<nav className="lead-nav">
			<ul className="list lead_nav list-customer">
				<li className="list__item">
					<Link className={manage} to="/home/customermanagement">
						対応履歴
					</Link>
				</li>
				<li className="list__item">
					<Link
						className={follower}
						to="/home/customermanagement/follower"
					>
						フォロワー
					</Link>
				</li>
				<li className="list__item">
					<Link
						className={notification}
						to="/home/customermanagement/notification"
					>
						プッシュ通知
					</Link>
				</li>
				<li className="list__item">
					<Link className={block} to="/home/customermanagement/block">
						ブロックリスト
					</Link>
				</li>
				{/* <li className="list__item">
					<a className="btn-tab">顧客検索(実装予定)</a>
				</li> */}
			</ul>
		</nav>
	)
}
