import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CATEGORY_MISSTION, glasConfig, MissionLevelId, SCROLL_LOAD_MORE } from '../constants/Config'
import MissionMenu from './MissionMenu'
import * as MissionActions from '../actions/MissionActions'
import MissionItem from './MissionItem'
import { isMobile } from 'react-device-detect'
import MissionDefault from './Mission/MissionDefault'

class MissionSpecial extends Component {
	constructor(props) {
		super(props)
		this.state = {
			page: 0,
			hasLoadMore: true
		}
		this.loadMore = this.loadMore.bind(this)
		this.fetchDataMission = this.fetchDataMission.bind(this)
	}
	loadMore() {
		const { Mission } = this.props
		if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
			if (Mission.missionSpecial.isHasMission) {
				this.setState({
					page: this.state.page + 1,
					hasLoadMore: false
				})
			}
			this.fetchDataMission(this.state.page)
		}
	}

	fetchDataMission(page) {
		const { dispatch } = this.props
		dispatch(MissionActions.getMissionsSpecial(CATEGORY_MISSTION.SPECIAL, page))
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.loadMore)
	}


	componentDidMount() {
		const { dispatch } = this.props
		dispatch(MissionActions.getMissions())

		if (this.state.hasLoadMore) { 
			dispatch(MissionActions.resetMission(CATEGORY_MISSTION.SPECIAL))
			this.fetchDataMission(this.state.page)
		}
		window.addEventListener('scroll', this.loadMore)
	}

	render() {
		const { Mission, dispatch } = this.props

		const missions = Mission.missions.map((mission) => {
			if (mission.catalog == MissionLevelId['SPECIAL']) {
				return <MissionItem mission={mission} dispatch={dispatch} />
			}
		})
		const missions_v2 = Mission.missionSpecial.missions.map((mission) => {
			return <MissionItem mission={mission} dispatch={dispatch} />
		})

		let content = isMobile ? missions : missions_v2
		if((isMobile && !missions.length) || (!isMobile && !missions_v2.length)) {
			content = <MissionDefault />
		}

		return (
			<div className="content setting-mission">
				<h1 className="content__title">ミッション</h1>
				<MissionMenu />
				<div className="primary" id={!isMobile && "unset-height-mission"}>
					<ul>{content}</ul>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		Mission: state.Mission,
	}
}

export default connect(mapStateToProps)(MissionSpecial)
