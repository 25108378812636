import React, { Component } from 'react'
import { isAndroid } from 'react-device-detect'

const CHECKED_YES = 1

class ContactConfirm extends Component {
	render() {
		const { item } = this.props
		return (
			<div className="primary contactPrimary">
				<div className="primary-title hidden-sp">
					<h1 className="primary-title__main">
						お問い合わせ内容確認
					</h1>
				</div>
				<p className="titleTextBox">お問い合わせ内容</p>
				<p className="contentConfirm">{item.content}</p>
				<div className="fileContact">
					{item.image ? (
						<img src={item.image} className="imageConfirm" />
					) : null}
				</div>
				<p className="titleTextBox notMarginTop">返信の有無</p>
				<div
					className={
						isAndroid
							? 'checkBoxContact checkBoxContactAndroid'
							: 'checkBoxContact checkBoxContactIOS'
					}
				>
					<p style={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 15 }}>
						{item.checked === CHECKED_YES ? '必要' : '不要'}
					</p>
				</div>
				<div
					style={{
						height: 1,
						background: 'rgba(0, 0, 0, 0.12)',
						width: '90%',
						marginLeft: 12,
						marginTop: 24,
						marginBottom: 24,
					}}
				></div>
				<p
					style={{
						color: 'rgba(0, 0, 0, 0.87)',
						fontSize: 15,
						marginLeft: 24,
					}}
				>
					上記にお間違いがないかご確認の上、「送信」ボタンを押してください。
				</p>
				<p
					style={{
						color: 'rgba(177, 177, 177, 1)',
						fontSize: 15,
						marginLeft: 24,
						marginTop: 4,
					}}
				>
					返信の有無にて「不要」を選択している場合は、事務局からの返信はございませんので、
				</p>
				<p
					style={{
						color: 'rgba(177, 177, 177, 1)',
						fontSize: 15,
						marginLeft: 24,
					}}
				>
					ご了承くださいませ。
				</p>
				<div className="btnsContact hidden-sp">
					<button
						className="submitContact"
						onClick={this.props.onConfirm}
					>
						送信
					</button>
					<button
						className="cancelContact"
						onClick={this.props.onCancel}
					>
						戻る
					</button>
				</div>
				<div
					className="btnsContact hidden-pc"
					style={{ display: 'block' }}
				>
					<button
						className="cancelContact"
						onClick={this.props.onCancel}
					>
						戻る
					</button>
					<button
						className="submitContact"
						onClick={this.props.onConfirm}
					>
						送信
					</button>
				</div>
			</div>
		)
	}
}

export default ContactConfirm
