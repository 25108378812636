import React, { Component } from 'react'
import CheckBoxCM from './CheckBoxCM'
import $ from 'jquery'

const zodiacDefault = {
	aries: { title: 'おひつじ座', value: 'aries' },
	taurus: { title: 'おうし座', value: 'taurus' },
	gemini: { title: 'ふたご座', value: 'gemini' },
	cancer: { title: 'かに座', value: 'cancer' },
	leo: { title: 'しし座', value: 'leo' },
	virgo: { title: 'おとめ座', value: 'virgo' },
	libra: { title: 'てんびん座', value: 'libra' },
	scorpio: { title: 'さそり座', value: 'scorpio' },
	sagittarius: { title: 'いて座', value: 'sagittarius' },
	capricorn: { title: 'やぎ座', value: 'capricorn' },
	aquarius: { title: 'みずがめ座', value: 'aquarius' },
	pisces: { title: 'うお座', value: 'pisces' },
}

class DropdownListZodiac extends Component {
	constructor(props) {
		super(props)
		this.state = {
			dropDownShow: false,
			zodiacs: [],
			hoverdropdown: false,
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.defaultValue !== this.state.zodiacs) {
			this.setState({
				zodiacs: nextProps.defaultValue,
			})
		}
		return true
	}

	handleHiddenShowBox = (event) => {
		let checkClassActive = $(
			'.dropdown-line-botoom-92797[data-expand=true][data-hoverdropdown=false]'
		).hasClass('open')
		if (checkClassActive) {
			this.setState({
				dropDownShow: false,
			})
		}
	}

	componentDidMount() {
		document.addEventListener('mousedown', (event) =>
			this.handleHiddenShowBox(event)
		)
	}

	handleMoveInBoxDropdown = () => {
		this.setState({
			hoverdropdown: !this.state.hoverdropdown,
		})
	}

	changeValue = (value) => {
		let listData = this.state.zodiacs
		if (listData.indexOf(value) < 0) {
			listData.push(value)
		} else {
			listData.splice(listData.indexOf(value), 1)
		}
		this.setState({
			zodiacs: listData,
		})
		this.props.onData(listData)
	}

	dropDownShow = (name, value) => {
		this.setState({
			[name]: !value,
			hoverdropdown: true,
		})
	}

	render() {
		let listUserData = this.state.zodiacs
		return (
			<div className="form-inline-row-CM">
				{this.props.labelHidden ? null : (
					<label className="label-text-92797">星座</label>
				)}
				<div
					className={
						'dropdown-line-botoom-92797 ' +
						(this.state.dropDownShow ? 'open' : '') +
						''
					}
					onMouseLeave={(event) =>
						this.handleMoveInBoxDropdown(this.state.hoverdropdown)
					}
					onMouseEnter={(event) =>
						this.handleMoveInBoxDropdown(this.state.hoverdropdown)
					}
					data-hoverdropdown={this.state.hoverdropdown}
					data-expand={this.state.dropDownShow}
				>
					<span
						className="label-text-92797 point-click"
						onClick={() =>
							this.dropDownShow(
								'dropDownShow',
								this.state.dropDownShow
							)
						}
					>
						{listUserData.length > 0 &&
						listUserData.length !== 12 ? (
							<span className={'text-color-dropdown-green'}>
								指定あり
							</span>
						) : (
							'すべて'
						)}
						<span className="caret" />
					</span>
					<div className="dropdown-box-show">
						<div className="row-box-dropdown-CM row">
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.aries.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.aries.value
											)
										}
										titleText={zodiacDefault.aries.title}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.taurus.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.taurus.value
											)
										}
										titleText={zodiacDefault.taurus.title}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.gemini.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.gemini.value
											)
										}
										titleText={zodiacDefault.gemini.title}
										locationText={true}
									/>
								</label>
							</div>
						</div>
						<div className="row-box-dropdown-CM row">
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.cancer.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.cancer.value
											)
										}
										titleText={zodiacDefault.cancer.title}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.leo.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.leo.value
											)
										}
										titleText={zodiacDefault.leo.title}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.virgo.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.virgo.value
											)
										}
										titleText={zodiacDefault.virgo.title}
										locationText={true}
									/>
								</label>
							</div>
						</div>
						<div className="row-box-dropdown-CM row">
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.libra.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.libra.value
											)
										}
										titleText={zodiacDefault.libra.title}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.scorpio.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.scorpio.value
											)
										}
										titleText={zodiacDefault.scorpio.title}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.sagittarius.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.sagittarius.value
											)
										}
										titleText={
											zodiacDefault.sagittarius.title
										}
										locationText={true}
									/>
								</label>
							</div>
						</div>
						<div className="row-box-dropdown-CM row">
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.capricorn.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.capricorn.value
											)
										}
										titleText={
											zodiacDefault.capricorn.title
										}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.aquarius.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.aquarius.value
											)
										}
										titleText={zodiacDefault.aquarius.title}
										locationText={true}
									/>
								</label>
							</div>
							<div className="form-inline-row-CM col-md-4">
								<label className="label-text-92797">
									<CheckBoxCM
										defaultValue={
											listUserData !== undefined &&
											listUserData.indexOf(
												zodiacDefault.pisces.value
											) > -1
										}
										onData={() =>
											this.changeValue(
												zodiacDefault.pisces.value
											)
										}
										titleText={zodiacDefault.pisces.title}
										locationText={true}
									/>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default DropdownListZodiac
