import React, { useState } from 'react'
import { Input } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { colors } from '../../../../util/StellaConstant'


const InputSetPoint = (props) => {
	const { checked, inputRef, targetPoint } = props
	const classes = useStyles()
	
	// const handleValidate = event => {
	// 	const keyCode = event.keyCode
	// 	const value = event.target.value

	// 	if(value === '1000000') {
	// 		if(keyCode === 48 || keyCode === 8) {
	// 			// can delete
	// 		} else {
	// 			event.preventDefault()
	// 		}
	// 	} else {
	// 		if(value.length >= 7) {
	// 			if(keyCode !== 8) {
	// 				event.preventDefault()
	// 			}
	// 		}
	// 	}
	// 	if(keyCode < 48 || keyCode > 57) {
	// 		if(keyCode !== 8) {
	// 			event.preventDefault()
	// 		}
	// 	}
	// }
	return (
		<Input
			type='number'
			placeholder='自分で決める'
			classes={{ root: classes.root }}
			disabled={!checked}
			inputRef={inputRef}
			defaultValue={checked ? targetPoint : ''}
			// onKeyDown={handleValidate}
		/>
	)
}

const useStyles = makeStyles({
	root: {
		'&::after': {
			borderBottom: `2px solid ${colors.pearlAqua} !important`
		}
	}
})

export default InputSetPoint