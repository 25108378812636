import React from 'react'
import { POINT_REPORT } from '../../../../constants/PointReportConstants'
import { MonthHeaderLabel, MonthHeaderWrapper } from '../../StyledPointReport/Calendar/StyledCustomCalendar'

const MonthHeader = ({ date, label, localizer }) => {

	const getDay = () => {
		const day = parseInt(localizer.format(date, 'c'))
		let result = 'NORMAL_DAY'
		switch (day) {
		case POINT_REPORT.DATE_INDEX.DATE_FNS.SATURDAY:
			result = 'SATURDAY'
			break
		case POINT_REPORT.DATE_INDEX.DATE_FNS.SUNDAY:
			result = 'SUNDAY'
			break
		default:
			break
		}
		return result
	}
	return (
		<MonthHeaderWrapper>
			<MonthHeaderLabel day={getDay()}>{label}</MonthHeaderLabel>
		</MonthHeaderWrapper>
	)
}

export default MonthHeader