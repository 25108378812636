import React, { Component } from 'react'
import { connect } from 'react-redux'

import AnswerMenu from './AnswerMenu'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import BestAnswerItem from './BestAnswerItem'
import { glasConfig } from './../constants/Config'
import request from 'axios'

class BestAnswerList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			pageRecords: [],
			currentPage: 0,
			dataloaded: false,
			totalRecords: 0,
		}
	}

	componentWillMount() {
		this.getMyBestAnswers()
		this.setAmountUnreadBestAnswer()
		localStorage.setItem('showPopUpBestAnswer', 'false')
	}

	setAmountUnreadBestAnswer() {
		const { dispatch } = this.props
		const token = localStorage.getItem('token')
		const options = {
			method: 'PUT',
			url:
				glasConfig.url_base +
				glasConfig.path_jpns_notifications +
				'/unreadbestanswer',
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}
		request(options).then((response) => {
			if (response.data.code === 0) {
				localStorage.setItem('numberUnreadBestAnswer', 0)
				dispatch(AnswerManagementActions.add(0), false)
			}
		})
	}

	createUrlQuery = (page) => {
		let postPage = page || 0
		let params = [`page=${postPage}`]
		return `?${params.join('&')}`
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.AnswerManagement.bestAnswers) {
			let dataloaded = true
			this.setState({
				pageRecords: nextProps.AnswerManagement.bestAnswers.data.list,
				currentPage: nextProps.AnswerManagement.bestAnswers.page,
				totalRecords:
					nextProps.AnswerManagement.bestAnswers.data.totalRecord,
				dataloaded,
			})
		}
	}

	getMyBestAnswers = (page) => {
		const { dispatch } = this.props
		dispatch(
			AnswerManagementActions.getBestAnswers(
				this.createUrlQuery(page),
				page
			)
		)
	}

	showMyBestAnswers = () => {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<BestAnswerItem
						item={current}
						key={index}
						path={this.props.match.path}
					/>
				)
			}
		)
	}

	handlePage(event, page) {
		event.preventDefault()
		this.getMyBestAnswers(page)
		window.scrollTo(0, 0)
	}

	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}

		let currentPage = parseInt(this.state.currentPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.answermanagement.answer
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0
		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1
		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = currentPage - showHalfPage
		let loopEnd = currentPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}
		// if(currentPage == 0 || currentPage == 1){
		//   loopEnd = loopEnd + 1;
		// }

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (currentPage !== 0) {
			firstPage = false
		}
		if (currentPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			lastPage = false
		}
		if (currentPage >= 0 && endPage !== currentPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = currentPage > 0 ? currentPage - 1 : 0
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = currentPage !== endPage ? currentPage + 1 : endPage
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === currentPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item" key={i}>
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className="primary__inner">
				<div className="pager">
					<ul className="list">
						{prevNav}
						{pageNav}
						{nextNav}
					</ul>
				</div>
			</div>
		)
	}

	openWelcomeAnswer = () => {
		this.props.dispatch(AnswerManagementActions.openDialogWelComeAnswer())
	}

	render() {
		return (
			<div className="content">
				<h1 className="content__title hidden-sp">
					教えて！先生
					<i
						class="material-icons content__icon-welcome-answer"
						onClick={this.openWelcomeAnswer}
					>
						help_outline
					</i>
				</h1>
				<AnswerMenu />
				{this.showMyBestAnswers()}
				{this.setPager()}
				<div
					className="content__text-welcome-answer hidden-pc"
					onClick={this.openWelcomeAnswer}
				>
					教えて！先生とは？
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
	}
}

export default connect(mapStateToProps)(BestAnswerList)
