import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import * as moment from 'moment'
import AnalysisMenu from './AnalysisMenu'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

class PeriodicallyAnalysis extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: {},
			statusItems: [],
			dataSP: {},
			tab: 'period',
			startDay: new Date(),
			endDay: new Date(),
			nowPage: 0,
			size: 20,
			diffDays: 1,
		}
	}

	componentDidMount() {
		this.checkOtherTeller()
	}

	checkOtherTeller = () => {
		const lastTellerId = localStorage.getItem('lastTellerId')
		const tellerId = localStorage.getItem('tellerId')
		if (lastTellerId !== tellerId) {
			localStorage.setItem('lastTellerId', tellerId)
			window.location.reload()
		} else {
			this.getAnalysis(this.state.startDay, this.state.endDay)
		}
	}

	countDays() {
		const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
		const diffDays = Math.round(
			Math.abs((this.state.endDay - this.state.startDay) / oneDay)
		)

		return diffDays + 1
	}

	componentWillReceiveProps(nextProps) {
		if (!this.state.data.list) {
			this.setState({ startDay: new Date(), endDay: new Date() }, () => {
				this.getAnalysis(this.state.startDay, this.state.endDay)
			})
		}
	}

	/**
	 * 日別ログを取得
	 */
	getAnalysis(startDay, endDay) {
		let startDayFormat = moment(startDay).startOf('day')
		const startTime = new Date(startDayFormat).getTime()
		let endDayFormat = moment(endDay).endOf('day')
		const endTime = new Date(endDayFormat).getTime()

		const url = glasConfig.url_base + glasConfig.path_analysis
		const params = {
			tab: this.state.tab,
			startTime,
			endTime,
			page: this.state.nowPage,
			size: this.state.size,
		}
		const token = localStorage.getItem('token')
		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: token,
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					this.setState({ data: response.data.data })
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	showMore(index) {
		let newStatusItems = this.state.statusItems
		newStatusItems[index] = !this.state.statusItems[index]
		this.setState({ statusItems: newStatusItems }, () => {})
	}

	showAnalysis() {
		if (this.state.data.list) {
			return Array.prototype.map.call(
				this.state.data.list,
				(current, index) => {
					return (
						<tr key={index}>
							<td>{moment(current.time).format('MM/DD')}</td>
							<td>{this.formatTime(current.waitingTime)}</td>
							<td>{current.footPrint}人</td>
							<td>{current.numberOfRequests}回</td>
							<td>{current.chatConnections}回</td>
							<td>{current.callConnections}回</td>
							<td>{current.favoriteNumber}回</td>
							<td>{current.reviewedNumber}個</td>
						</tr>
					)
				}
			)
		}
	}

	handlePage(event, page) {
		event.preventDefault()
		const { startDay, endDay } = this.state
		this.setState({ nowPage: page }, () => {
			this.getAnalysis(startDay, endDay)
		})
	}

	setPager() {
		if (this.state.data.list) {
			const total = this.state.diffDays
			if (total === 0 || total === undefined) {
				return false
			}

			let nowPage = parseInt(this.state.nowPage, 10)
			let totalRecords = parseInt(total, 10)
			let item = this.state.size
			let endPage =
				totalRecords > item ? parseInt(totalRecords / item, 10) : 0
			endPage =
				endPage !== 0 && totalRecords % item !== 0
					? endPage
					: endPage - 1

			let showPage = endPage < 5 ? endPage : 5
			let showHalfPage = Math.floor(showPage / 2)

			let loopStart = nowPage - showHalfPage
			let loopEnd = nowPage + showHalfPage

			if (loopStart <= -1) {
				loopStart = 0
				loopEnd = showPage - 1
			}
			if (loopEnd > endPage) {
				loopStart = endPage - showPage
				loopEnd = endPage
			}

			let firstPage = true
			let prevPage = true
			let lastPage = true
			let nextPage = true

			if (nowPage !== 0) {
				firstPage = false
			}
			if (nowPage !== 0 && endPage !== 0) {
				prevPage = false
			}
			if (nowPage >= 0 && endPage !== nowPage) {
				lastPage = false
			}
			if (nowPage >= 0 && endPage !== nowPage) {
				nextPage = false
			}

			let prevNav = []
			if (endPage > 0) {
				prevNav.push(
					<li className="list__item">
						<button
							className="btn"
							disabled={firstPage}
							onClick={(e) => this.handlePage(e, 0)}
						>
							<i className="material-icons">first_page</i>
						</button>
					</li>
				)
				let prev = nowPage > 0 ? nowPage - 1 : 0
				prevNav.push(
					<li className="list__item">
						<button
							className="btn"
							disabled={prevPage}
							onClick={(e) => this.handlePage(e, prev)}
						>
							<i className="material-icons">navigate_before</i>
						</button>
					</li>
				)
			}

			let nextNav = []
			if (endPage > 0) {
				let next = nowPage !== endPage ? nowPage + 1 : endPage
				nextNav.push(
					<li className="list__item">
						<button
							className="btn"
							disabled={nextPage}
							onClick={(e) => this.handlePage(e, next)}
						>
							<i className="material-icons">navigate_next</i>
						</button>
					</li>
				)
				nextNav.push(
					<li className="list__item">
						<button
							className="btn"
							disabled={lastPage}
							onClick={(e) => this.handlePage(e, endPage)}
						>
							{' '}
							<i className="material-icons">last_page</i>
						</button>
					</li>
				)
			}

			let pageNav = []
			for (let i = loopStart; i <= loopEnd; i++) {
				let pageClass = ['btn']
				if (i === nowPage) {
					pageClass.push('is-active')
				}

				pageNav.push(
					<li className="list__item">
						<button
							className={pageClass.join(' ')}
							onClick={(e) => this.handlePage(e, i)}
						>
							{i + 1}
						</button>
					</li>
				)
			}

			return (
				<div className="primary__inner">
					<div className="pager">
						<ul className="list">
							{prevNav}
							{pageNav}
							{nextNav}
						</ul>
					</div>
				</div>
			)
		}
	}

	openDatePicker(type) {
		type === 'start'
			? this.datePickerStart.setOpen(true)
			: this.datePickerEnd.setOpen(true)
	}

	onSubmit = () => {
		this.setState(
			{
				diffDays: this.countDays(),
				statusItems: [],
				nowPage: 0,
				currentStart: this.state.startDay,
				currentEnd: this.state.endDay,
			},
			() => {
				this.getAnalysis(this.state.startDay, this.state.endDay)
			}
		)
	}

	changeStartDay(day) {
		this.setState({ startDay: day })
	}

	changeEndDay(day) {
		this.setState({ endDay: day })
	}

	formatTime(totalSeconds) {
		var hours = Math.floor(totalSeconds / 3600)
		var minutes = Math.floor((totalSeconds - hours * 3600) / 60)
		var seconds = totalSeconds - hours * 3600 - minutes * 60

		seconds = Math.round(seconds * 100) / 100

		var result = hours < 10 ? '0' + hours : hours
		result += ':' + (minutes < 10 ? '0' + minutes : minutes)
		result += ':' + (seconds < 10 ? '0' + seconds : seconds)

		return result
	}

	showAnalysisSP() {
		const { statusItems } = this.state
		if (this.state.data.list) {
			return Array.prototype.map.call(
				this.state.data.list,
				(current, index) => {
					return (
						<div className="rowBody analysisItem">
							<div className="rowReward total">
								<div className="rewardItemLeftCol">
									<span className="titleLeft">
										{moment(current.time).format('M月D日')}
									</span>
								</div>
								<div className="rewardItemRightCol">
									<span className="titleRight">
										{this.formatTime(current.waitingTime)}
									</span>
									<i
										className="material-icons btn__icons iconExpand"
										onClick={() => this.showMore(index + 1)}
									>
										{statusItems[index + 1]
											? 'expand_less'
											: 'expand_more'}
									</i>
								</div>
							</div>
							{statusItems[index + 1] ? (
								<div className="detailReward">
									<div className="singleRow singleRowImg rowItem">
										<div className="rowLeftCol">
											足あと数
										</div>
										<div className="rowRightCol rowRightColImg">
											<img
												src="/img/foot_print.png"
												className="iconAnalysis"
											/>
											<div className="valueRight">
												{current.footPrint}人
											</div>
										</div>
									</div>
									<div className="singleRow singleRowImg rowItem">
										<div className="rowLeftCol">
											リクエスト数
										</div>
										<div className="rowRightCol rowRightColImg">
											<img
												src="/img/reserve.png"
												className="iconAnalysis"
											/>
											<div className="valueRight">
												{current.numberOfRequests}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem">
										<div className="rowLeftCol">
											チャット接続数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												sms
											</i>
											<div className="valueRight">
												{current.chatConnections}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem">
										<div className="rowLeftCol">
											通話接続数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												call
											</i>
											<div className="valueRight">
												{current.callConnections}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem">
										<div className="rowLeftCol">
											お気に入りされた数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												favorite
											</i>
											<div className="valueRight">
												{current.favoriteNumber}回
											</div>
										</div>
									</div>
									<div className="singleRow rowItem lastSingleRow">
										<div className="rowLeftCol">
											レビュー数
										</div>
										<div className="rowRightCol">
											<i className="material-icons btn__icons">
												star
											</i>
											<div className="valueRight">
												{current.reviewedNumber}個
											</div>
										</div>
									</div>
								</div>
							) : null}
						</div>
					)
				}
			)
		}
	}

	render() {
		const { MyProfile, dispatch } = this.props
		const { data, statusItems } = this.state

		return (
			<div className="content reward analysis-daily">
				<h1 className="content__title">アクセス解析</h1>
				<AnalysisMenu />
				<div className="primary primaryReward primaryAnalysis">
					<div className="primary-title titleDailyReward">
						<h1 className="primary-title__main">期間</h1>
					</div>
					<div className="date-picker-access hidden-sp">
						<div className="selectTime">
							<DatePicker
								ref={(picker) =>
									(this.datePickerStart = picker)
								}
								selected={this.state.startDay}
								onChange={(startDay) =>
									this.changeStartDay(startDay)
								}
								dateFormat="yyyy/MM/dd"
								locale="ja"
								dateFormatCalendar="yyyy年 M月"
								className="picker-access access-start"
							/>
							<i
								onClick={() => this.openDatePicker('start')}
								className="material-icons btn__icons iconDateAccess"
							>
								keyboard_arrow_down
							</i>
						</div>
						<p class="centerIconAccess">~</p>
						<div className="selectTime">
							<DatePicker
								ref={(picker) => (this.datePickerEnd = picker)}
								selected={this.state.endDay}
								onChange={(endDay) => this.changeEndDay(endDay)}
								dateFormat="yyyy/MM/dd"
								locale="ja"
								dateFormatCalendar="yyyy年 M月"
								className="picker-access access-end"
							/>
							<i
								onClick={() => this.openDatePicker('end')}
								className="material-icons btn__icons iconDateAccess"
							>
								keyboard_arrow_down
							</i>
						</div>
						<button
							onClick={this.onSubmit}
							className="submitDateAccess"
						>
							表示
						</button>
					</div>
					<p className="period-access"></p>
					<div className="frame-table-noti hidden-sp analysis-table">
						<table className="table-data table-analysis">
							<thead>
								<tr>
									<th className="table-align-center">日</th>
									<th className="table-align-center">
										待機時間
									</th>
									<th className="table-align-center">
										足あと数
									</th>
									<th className="table-align-center">
										リクエスト数
									</th>
									<th className="table-align-center">
										チャット接続数
									</th>
									<th className="table-align-center">
										通話接続数
									</th>
									<th className="table-align-center">
										お気に入りされた数
									</th>
									<th className="table-align-center">
										レビューのついた数
									</th>
								</tr>
							</thead>
							{this.state.data.list &&
								this.state.data.list.length > 0 && (
									<tbody>
										<tr className="monthAnalysis">
											<td>合計</td>
											<td>
												{this.formatTime(
													data.waitingTime
												)}
											</td>
											<td>{data.footPrint}人</td>
											<td>{data.numberOfRequests}回</td>
											<td>{data.chatConnections}回</td>
											<td>{data.callConnections}回</td>
											<td>{data.favoriteNumber}回</td>
											<td>{data.reviewedNumber}個</td>
										</tr>
										{this.showAnalysis()}
									</tbody>
								)}
						</table>
					</div>
					<div className="primary__inner hidden-pc">
						<div className="primary-content">
							<div className="primary-content__body content__body-analysis">
								<div className="date-picker-access">
									<div className="selectTime">
										<DatePicker
											ref={(picker) =>
												(this.datePickerStart = picker)
											}
											selected={this.state.startDay}
											onChange={(startDay) =>
												this.changeStartDay(startDay)
											}
											dateFormat="yyyy/MM/dd"
											locale="ja"
											dateFormatCalendar="yyyy年 M月"
											className="picker-access access-start"
										/>
										<i
											onClick={() =>
												this.openDatePicker('start')
											}
											className="material-icons btn__icons iconDateAccess"
										>
											keyboard_arrow_down
										</i>
									</div>
									<p class="centerIconAccess">~</p>
									<div className="selectTime selectTimeRight">
										<DatePicker
											ref={(picker) =>
												(this.datePickerEnd = picker)
											}
											selected={this.state.endDay}
											onChange={(endDay) =>
												this.changeEndDay(endDay)
											}
											dateFormat="yyyy/MM/dd"
											locale="ja"
											dateFormatCalendar="yyyy年 M月"
											className="picker-access access-end"
										/>
										<i
											onClick={() =>
												this.openDatePicker('end')
											}
											className="material-icons btn__icons iconDateAccess"
										>
											keyboard_arrow_down
										</i>
									</div>
								</div>
								<button
									onClick={this.onSubmit}
									className="submitDateAccess"
								>
									表示
								</button>
								{this.state.data.list &&
									this.state.data.list.length > 0 && (
										<p className="access-period-time">
											{moment(
												this.state.currentStart
											).format('YYYY年MM月DD日')}
											〜
											{moment(
												this.state.currentEnd
											).format('YYYY年MM月DD日')}
										</p>
									)}
								{this.state.data.list &&
									this.state.data.list.length > 0 && (
										<div className="reward-daily__header display-mobile">
											<div className="rewardItem firstRowMobileDown analysisItem">
												<div className="firstRowTop">
													<div className="rewardItemLeftCol">
														<span
															style={{
																marginLeft: 12,
																fontSize: 10,
															}}
														>
															日時
														</span>
													</div>
													<div className="rewardItemRightCol">
														<div
															style={{
																width: 100,
																textAlign:
																	'center',
																fontSize: 10,
															}}
														>
															待機時間
														</div>
													</div>
												</div>
												<div className="firstRowBody">
													<div className="rowReward total">
														<div className="rewardItemLeftCol">
															<span className="titleLeft">
																合計
															</span>
														</div>
														<div className="rewardItemRightCol">
															<span className="titleRight">
																{this.formatTime(
																	data.waitingTime
																)}
															</span>
															<i
																className="material-icons btn__icons iconExpand"
																onClick={() =>
																	this.showMore(
																		0
																	)
																}
															>
																{statusItems[0]
																	? 'expand_less'
																	: 'expand_more'}
															</i>
														</div>
													</div>
													{statusItems[0] ? (
														<div className="detailReward">
															<div className="singleRow singleRowImg rowItem">
																<div className="rowLeftCol">
																	足あと数
																</div>
																<div className="rowRightCol rowRightColImg">
																	<img
																		src="/img/foot_print.png"
																		className="iconAnalysis"
																	/>
																	<div className="valueRight">
																		{
																			data.footPrint
																		}
																		人
																	</div>
																</div>
															</div>
															<div className="singleRow singleRowImg rowItem">
																<div className="rowLeftCol">
																	リクエスト数
																</div>
																<div className="rowRightCol rowRightColImg">
																	<img
																		src="/img/reserve.png"
																		className="iconAnalysis"
																	/>
																	<div className="valueRight">
																		{
																			data.numberOfRequests
																		}
																		回
																	</div>
																</div>
															</div>
															<div className="singleRow rowItem">
																<div className="rowLeftCol">
																	チャット接続数
																</div>
																<div className="rowRightCol">
																	<i className="material-icons btn__icons">
																		sms
																	</i>
																	<div className="valueRight">
																		{
																			data.chatConnections
																		}
																		回
																	</div>
																</div>
															</div>
															<div className="singleRow rowItem">
																<div className="rowLeftCol">
																	通話接続数
																</div>
																<div className="rowRightCol">
																	<i className="material-icons btn__icons">
																		call
																	</i>
																	<div className="valueRight">
																		{
																			data.callConnections
																		}
																		回
																	</div>
																</div>
															</div>
															<div className="singleRow rowItem">
																<div className="rowLeftCol">
																	お気に入りされた数
																</div>
																<div className="rowRightCol">
																	<i className="material-icons btn__icons">
																		favorite
																	</i>
																	<div className="valueRight">
																		{
																			data.favoriteNumber
																		}
																		回
																	</div>
																</div>
															</div>
															<div className="singleRow rowItem lastSingleRow">
																<div className="rowLeftCol">
																	レビュー数
																</div>
																<div className="rowRightCol">
																	<i className="material-icons btn__icons">
																		star
																	</i>
																	<div className="valueRight">
																		{
																			data.reviewedNumber
																		}
																		個
																	</div>
																</div>
															</div>
														</div>
													) : null}
												</div>
											</div>
											<div className="rowsSP">
												{this.showAnalysisSP()}
											</div>
										</div>
									)}
							</div>
						</div>
					</div>
				</div>
				{this.setPager()}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(PeriodicallyAnalysis)
