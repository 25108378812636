import React, { Component } from 'react'
import { PrivateRoute } from './PrivateRoute'
import { Route, Switch } from 'react-router-dom'

import BestAnswerList from './BestAnswerList'
import AnswerManagement from './AnswerManagement'
import AnswerLogs from './AnswerLogs'
import AnswerDetail from './AnswerDetail'

export default class RouteAnswer extends Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Switch>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}`}
					component={AnswerManagement}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/best-answer`}
					component={BestAnswerList}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/answer-log`}
					component={AnswerLogs}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/detail/:id`}
					component={AnswerDetail}
				/>
			</Switch>
		)
	}
}
