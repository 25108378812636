/**
 * エンドポイントが/ums/v1/fortuneteller/:tellerid/account
 *
 */
import {
	MYINFO_ACCOUNT,
	MYINFO_ACCOUNT_PUT,
	MYINFO_ACCOUNT_PUT_FALSE,
	MYINFO_PROFILE_PUT,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import axios from 'axios'
import { convertUrlQueryParam } from '../helper/helpFunction'
const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

// アカウント情報取得
export const fetch = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()
		dispatch(resultSuccess('MYINFO_ACCOUNT_LOADDING'))
		dispatch(showLoading())
		return request
			.get(
				glasConfig.url_base +
				glasConfig.path_teller_info +
				tellerId +
				'/accounts',
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					dispatch(resultSuccess(MYINFO_ACCOUNT, response.data.data))
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + MYINFO_ACCOUNT, error)
				throw error
			})
	}
}

// アカウント情報更新
export const put = (email, listCheckbox) => {
	return (dispatch, getState) => {
		dispatch(showLoading())
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()
		let accountData = getState().MyAccount.data
		return request
			.put(
				glasConfig.url_base +
				glasConfig.path_teller_info +
				tellerId +
				'/accounts',
				{
					email: email,
					receiveNotiByEmail: listCheckbox.receiveNotiByEmail,
					receiveReviewNotiByEmail:
						listCheckbox.receiveReviewNotiByEmail,
					receiveFavoriteNotiByEmail:
						listCheckbox.receiveFavoriteNotiByEmail,
					receiveReloginNotiByEmail:
						listCheckbox.receiveReloginNotiByEmail,
				},
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				dispatch(hideLoading())
				if (response.data.code === 0) {
					response.data.data.verifyAge = accountData.verifyAge
					dispatch(
						resultSuccess(MYINFO_ACCOUNT_PUT, response.data.data)
					)
				} else {
					dispatch(
						resultSuccess(
							MYINFO_ACCOUNT_PUT_FALSE,
							response.data.data
						)
					)
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				dispatch(resultSuccess(MYINFO_ACCOUNT_PUT_FALSE, error))
				console.log('error on call ' + MYINFO_ACCOUNT_PUT, error)
				throw error
			})
	}
}
export const updateRingTone = (myProfile, params, callback) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()
		const paramsUrl = convertUrlQueryParam(params)
		const ringtoneNumber = params.ringtoneNumber
		const isDisableSoundRequestChat = params.isDisableSoundRequestChat
		const isDisableSoundMessage = params.isDisableSoundMessage
		return axios
			.put(glasConfig.url_base + glasConfig.path_teller_info + tellerId + glasConfig.update_ringtone + paramsUrl, {}, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {

				if (response.data.code === 0) {
					callback()
					const myProfileData = {
						myProfile,
						data: {
							...myProfile.data,
							ringtoneNumber,
							isDisableSoundRequestChat,
							isDisableSoundMessage
						},
					}
					// 現在のプロフィール情報に更新したプロフィール情報をマージする
					let data = Object.assign(
						{},
						myProfileData.data,
						ringtoneNumber,
						isDisableSoundRequestChat,
						isDisableSoundMessage
					)
					dispatch(
						resultSuccess(MYINFO_PROFILE_PUT, {
							savedProfile: true,
							data: data,
							savedCode: 0,
						})
					)
				} else {
					throw { response }
				}
			})
			.catch((error) => {
				console.log('error on call ' + MYINFO_PROFILE_PUT, error)
				throw error
			})
	}
}