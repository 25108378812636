import React, { Component } from 'react'
import { PrivateRoute } from './PrivateRoute'
import ContactCreate from './ContactCreate'
import ContactHistory from './ContactHistory'
import ContactDetail from './ContactDetail'

class RouteContact extends Component {
	render() {
		return (
			<span>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}`}
					component={ContactCreate}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/history`}
					component={ContactHistory}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/detail/:id`}
					component={ContactDetail}
				/>
			</span>
		)
	}
}

export default RouteContact
