import React from 'react'
import Tabs from '@mui/material/Tabs'
import { colors } from '../../../../util/StellaConstant'
import { isIpad, isPWA } from '../../../../helper/helpFunction'
import { makeStyles } from '@mui/styles'
import { isTablet } from 'react-device-detect'

const CustomTabs = props => {
	const { children, value, onChange, ...other } = props
	const classes = useStyles()
	return (
		<Tabs
			classes={{
				root: classes.root,
				indicator: classes.indicator,
			}}
			value={value}
			onChange={onChange}
			indicatorColor="secondary"
			variant={isPWA() || isIpad() || isTablet ? 'fullWidth' : 'standard'}
			
			{...other}

		>
			{children}
		</Tabs>
	)
}

const useStyles = makeStyles({
	root: {
		borderBottom: `1px solid ${colors.lightBlack}`
	},
	indicator: {
		backgroundColor: `${colors.pearlAqua} !important`
	},
})

export default CustomTabs