import MaleAvatar from '../assets/thumb_male_new.png'
import FemaleAvatar from '../assets/thumb_female_new.png'
import OtherAvatar from '../assets/thumb_noimage_new.png'

export const CALLTYPE = {
	CONNECT: 'CONNECT',
	NOTIFY: 'NOTIFY',
	NOTIFY_RECEIVED: 'NOTIFY_RECEIVED',
	RINGING: 'RINGING',
	PICK_UP: 'PICK_UP',
	STREAMING_READY: 'STREAMING_READY',
	PUBLISH_STREAM: 'PUBLISH_STREAM',
	CALL_PING: 'CALL_PING',
	CALL_PONG: 'CALL_PONG',
	END_CALL: 'END_CALL',
	STATE_END: 'STATE_END',
	DETROY_STREAM: 'DETROY_STREAM',
	CANCEL_CALL: 'CANCEL_CALL',
	DECLINE_CALL: 'DECLINE_CALL',
	RECONNECT: 'RECONNECT',
	REMAIN_TIME_CALL: 'REMAIN_TIME_CALL',
}

export const MICROPHONE_STATUS = {
	INACTIVE: 'INACTIVE',
	ACTIVE: 'ACTIVE',
}

export const CALL_SCREEN = {
	STATUS: {
		ACTIVE: true,
		INACTIVE: false,
	},
}

export const AGORA = {
	PING_TIME: 1000,

	ENDCALL_STATUS: {
		SUCCESS: 'SUCCESS',
		CANCEL: 'CANCEL',
		DECLINE: 'DECLINE',
		TIMEOUT: 'TIMEOUT',
		ERROR_STREAM: 'ERROR_STREAM',
		OUT_OF_MONEY: 'OUT_OF_MONEY',
		SERVER_BUSY: 'SERVER_BUSY',
		CALL_ID_NOT_EXISTED: 'CALL_ID_NOT_EXISTED',
		NO_ANSWER: 'NO_ANSWER',
		CAN_NOT_JOIN_CHANNEL: 'CAN_NOT_JOIN_CHANNEL',
	},
	ENDCALL: {
		SUCCESS: {
			CODE: 1,
			REASON: 'SUCCESS',
		},
		CANCEL: {
			CODE: 3,
			REASON: 'CANCEL',
		},
		DECLINE: {
			CODE: 4,
			REASON: 'DECLINE',
		},
		TIMEOUT: {
			CODE: 5,
			REASON: 'TIMEOUT',
		},
		ERROR_STREAM: {
			CODE: 6,
			REASON: 'ERROR_STREAM',
		},
		OUT_OF_MONEY: {
			CODE: 7,
			REASON: 'OUT_OF_MONEY',
		},
		SERVER_BUSY: {
			CODE: 8,
			REASON: 'SERVER_BUSY',
		},
		CALL_ID_NOT_EXISTED: {
			CODE: 9,
			REASON: 'CALL_ID_NOT_EXISTED',
		},
		NO_ANSWER: {
			CODE: 10,
			REASON: 'NO_ANSWER',
		},
		CAN_NOT_JOIN_CHANNEL: {
			CODE: 11,
			REASON: 'CAN_NOT_JOIN_CHANNEL',
		},
	},
}

export const BUTTON_TYPE = {
	ACCEPT: 'ACCEPT',
	PENDING: 'PENDING',
	DECLINE: 'DECLINE',
	MUTE: 'MUTE',
	UNMUTE: 'UNMUTE',
	ENDCALL: 'ENDCALL',
	VOLUMEUP: 'VOLUMEUP',
	VOLUMEOFF: 'VOLUMEOFF',
}

export const AVATAR_BY_GENDER = {
	MALE: MaleAvatar,
	FEMALE: FemaleAvatar,
	OTHER: OtherAvatar,
	ALL: OtherAvatar,
}

export const BUTTON_INCALL = {
	MICRO: 'MICRO',
	ENDCALL: 'ENDCALL',
	SPEAKER: 'SPEAKER',
}

export const BUTTON_INCALL_STATUS = {
	ON: 'ON',
	OFF: 'OFF',
}

export const CALL_GENDER = {
	1: 'MALE',
	2: 'FEMALE',
	3: 'OTHER',
	4: 'ALL',
}
