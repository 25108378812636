import {
    ADD_CHAT_DRAFT, CHANGE_STATUS_CHAT_DRAFT,
} from '../constants/ActionTypes'
import moment from 'moment'

const initialState = {
	loaded: false,
	data: [],
}

export const ChatDraft = (state = initialState, action) => {
	switch (action.type) {
        case ADD_CHAT_DRAFT: {
			localStorage.setItem("draft-chat", JSON.stringify(action.data))
			
			return {
				loaded: true,
				data:  action.data,
			}
		}

		case CHANGE_STATUS_CHAT_DRAFT: {
			let getUserChatDraft = []
			if (localStorage.hasOwnProperty("draft-chat")) {
				let dataChatDraft = JSON.parse(localStorage.getItem("draft-chat"))
				getUserChatDraft = dataChatDraft.map((res) => { 
					if (res.fromId == action.data.userId) {
						if (action.data.serverTime) res.serverTime = action.data.serverTime
					}
					return res
				})

				localStorage.setItem("draft-chat", JSON.stringify(getUserChatDraft))
			}

			return {
				loaded: true,
				data: getUserChatDraft,
			}
		}
		default: {
			return state
		}
	}
}
