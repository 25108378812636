import React, { useEffect, useState } from 'react'
import * as ConfigMessage from '../../constants/ConfigMessage'
import '../../assets/scss/leftHistory.scss'
import * as Fetch from '../../util/Fetch'
import image_pin from '../../assets/pin_message.png'
import image_un_pin from '../../assets/unpin_message.png'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { isPWA } from '../../helper/helpFunction'
const MessageHistory = (props) => {
	const { data,
		chatUserId,
		closeNavLeft,
		check = true,
		doReadMessages,
		handlePinMessage,
		maxPin
	} = props
	const msgMap = ConfigMessage.getMessageData(
		data,
		'displayTextLeftHistory'
	)
	const tellerId = Fetch.tellerId()
	const userId = data.fromId === tellerId ? data.toId : data.fromId

	let dateSendMessage = (data.chatDraft && data.serverTimeChatDraft >= data.serverTime ? data.serverTimeChatDraft : data.serverTime)

	const [isPin, setPin] = useState(data.isPin)
	useEffect(() => {
		setPin(data.isPin)
	}, [data])
	const handleClickPin = (e) => {
		e.preventDefault()
		e.stopPropagation()
		if (isPin) {
			handlePinMessage(userId, isPin, data, errorPin)
			setPin(!isPin)
		} else {
			if (!maxPin) {
				handlePinMessage(userId, isPin, data, errorPin)
				setPin(!isPin)
			} else {
				alert('ピン留めできるのは最大5件までです')
			}
		}
	}
	const errorPin = (errorCode) => {
		if (errorCode === 246) {
			alert('ピン留めできるのは最大5件までです')
			setPin(isPin)
			return
		}
		alert('チャットルームをピン留めできません')
		setPin(isPin)
		return
	}

	if (data.accountDeactive) {
		return null
	}
	return (
		<Link
			to={`/home/chat/${userId}`}
			className="list__item"
			key={data.msgId + data.serverTime}
			onClick={
				closeNavLeft
			}
		>
			<div
				onClick={() => doReadMessages(
					data.toId,
					data.fromId,
					[data.msgId],
					closeNavLeft
				)}
				className={
					chatUserId === userId
						? 'btn-customer customer_request-choose'
						: 'btn-customer customer_default'
				}
			>
				<div className='row-horizontal'>
					<div className='row'>
						<div className="btn-customer__item is_name" style={isPWA() ? {} : { maxWidth: 130 }}>
							{data.friendName === undefined
								? data.userName
								: data.friendName}
						</div>
						{data.unreadMsgBadge > 0 && check ? (
							<div>
								{
									isPWA() ?
										<div className={'unread-pwa'}>
											{data.unreadMsgBadge > 99 ? '99+' : data.unreadMsgBadge}
										</div> :
										<div className={'unread-msg-box'}>
											{data.unreadMsgBadge > 99 ? '99+' : data.unreadMsgBadge}
										</div>
								}
							</div>
						) : null}
					</div>
					<p className="btn-customer__item is_time">
						{moment(
							moment(dateSendMessage, 'YYYYMMDDHHmmss')
								.add(9, 'hours')
								.format('YYYY-MM-DD HH:mm:ss')
						).fromNow()}
					</p>
				</div>
				<div className='row-horizontal'>
					<div className="btn-customer__item is_comment">
						{msgMap.msg}
					</div>
					{!isPWA() && <img
						onClick={handleClickPin}
						className={'image_pin_message'}
						src={isPin ? image_pin : image_un_pin}
						alt={''}
					/>}
				</div>
			</div >
		</Link >
	)
}

export default MessageHistory