/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import { DEVICE } from '../../../util/StellaConstant'
import {
	Button,
	ButtonArea,
	Content,
	Title,
} from '../StyledComponents/StyledDialogConfirmEndcallComponents'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

const DialogConfirmEndCall = (props) => {
	const classes = useStyles()
	const { showConfirm, closeConfirm, endACall, resetCallParams } = props
	const [open, setOpen] = useState(false)

	useEffect(() => {
		setOpen(showConfirm)
	}, [showConfirm])

	const closeDialog = () => {
		setOpen(false)
		closeConfirm()
	}

	const endCall = () => {
		console.log('%c END CALL: ', 'color: red')
		endACall()
		closeConfirm()
		resetCallParams()
	}
	return (
		<Dialog
			open={open}
			// open={true}
			classes={{
				root: classes.root,
				paper: classes.paper,
			}}
			BackdropProps={{
				classes: {
					root: classes.backdrop,
				},
			}}
		>
			<IconButton
				aria-label="close"
				onClick={closeDialog}
				sx={{
					position: 'absolute',
					right: 14,
					top: 12,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<Title>通話を終了します</Title>
			<Content>よろしいですか？</Content>
			<ButtonArea>
				<Button buttonType="ACCEPT" onClick={endCall}>
					はい
				</Button>
				<Button buttonType="DECLINE" onClick={closeDialog}>
					いいえ
				</Button>
			</ButtonArea>
		</Dialog>
	)
}

const useStyles = makeStyles({
	root: {
		[`@media ${DEVICE.mobileS}`]: {

		},
		[`@media ${DEVICE.tablet}`]: {
			left: '256px !important',
			width: 'calc(100% - 256px)',
			zIndex: '999999 !important',
		},
		[`@media ${DEVICE.laptop}`]: {
			left: '256px !important',
			width: 'calc(100% - 256px)',
			zIndex: '999999 !important',
		},
	},
	backdrop: {
		[`@media ${DEVICE.mobileS}`]: {

		},
		[`@media ${DEVICE.tablet}`]: {

		},
		[`@media ${DEVICE.laptop}`]: {
			left: '256px !important',
		},
	},
	paper: {
		[`@media ${DEVICE.mobileS}`]: {
			margin: '0 !important',
			width: 'calc(90% - 33px - 33px)',
			padding: '37px 33px 27px'
		},
		[`@media ${DEVICE.tablet}`]: {
			margin: '0 !important',
			width: 301,
			padding: '37px 33px 27px'
		},
		[`@media ${DEVICE.laptop}`]: {
			margin: '0 !important',
			width: 301,
			padding: '37px 33px 27px'
		},
	},
})

export default DialogConfirmEndCall
