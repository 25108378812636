/**
 * Created by ToanNC on 9/11/2017.
 */
import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import * as Fetch from '../util/Fetch'

export const PrivateRoute = ({ component: Component, ...rest }) => {
	const expire = Fetch.chkExpireToken()
	return (
		<Route
			{...rest}
			render={(props) =>
				expire ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/login',
						}}
					/>
				)
			}
		/>
	)
}
