import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactMenu from './ContactMenu'
import * as moment from 'moment'
import * as ContactActions from '../actions/ContactActions'
import { glasConfig } from '../constants/Config'

const CHECKED_YES = 1
// const CHECKED_NO = 2;

class ContactHistory extends Component {
	constructor(props) {
		super(props)
		this.state = {
			content: '',
			checked: CHECKED_YES,
			image: null,
			pageRecords: [],
			totalRecords: 0,
			nowPage: 0,
			dataloaded: false,
		}
	}

	componentDidMount() {
		const { dispatch } = this.props
		dispatch(ContactActions.getContactHistory(this.createUrlQuery()))
	}

	componentDidUpdate(prevProps) {
		if (prevProps.Contact.data !== this.props.Contact.data) {
			let dataloaded = true
			this.setState({
				pageRecords: this.props.Contact.data.list,
				totalRecords: this.props.Contact.data.total,
				nowPage: this.props.Contact.page,
				dataloaded,
			})
		}
	}

	handleChange = (event) => {
		this.getImageData(event.target.files[0])
	}

	createUrlQuery(page) {
		const { MyProfile } = this.props
		let postPage = page || 0
		let params = [
			`userId=${MyProfile.data.fortuneTellerId}`,
			'size=10',
			`page=${postPage}`,
		]
		return `?${params.join('&')}`
	}

	linkTo(id) {
		this.props.history.push(`/home/contact/detail/${id}`)
	}

	getContactHistory() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<tr
						className={
							current.readflg === '0' &&
							'contact-table__content-unread'
						}
						onClick={() => this.linkTo(current.id)}
						key={current.notificationSettingId}
					>
						<td style={{ width: '20%' }}>
							{current.ymd
								? moment(
										current.ymd,
										'YYYY-MM-DD HH:mm'
								  ).format('YYYY/MM/DD HH:mm')
								: '-'}
						</td>
						<td
							style={{ width: '30%' }}
							className={
								this.formatBody(
									current.admin_id != 0 ? '-' : current.body
								) === '-'
									? 'noContent'
									: ''
							}
						>
							{this.formatBody(
								current.admin_id != 0 ? '-' : current.body
							)}
						</td>
						<td
							style={{ width: '30%' }}
							className={
								this.formatBody(current.nearestAnswer) === '-'
									? 'noContent'
									: ''
							}
						>
							{this.formatBody(current.nearestAnswer)}
						</td>
						<td style={{ width: '20%' }}>
							{this.getStatus(current)}
						</td>
					</tr>
				)
			}
		)
	}

	getStatus(current) {
		let result = ''
		if (current.admin_id) {
			if (current.isClose) {
				result = '解決済み'
			} else {
				result = '対応中'
			}
		} else {
			if (current.nearestAnswer) {
				if (current.isClose) {
					result = '解決済み'
				} else {
					result = '対応中'
				}
			} else {
				result = '-'
			}
		}

		return result
	}

	formatBody(content) {
		let result = ''
		if (content) {
			if (content.length < 30) {
				result = content
			} else {
				result = content.substring(0, 30) + '...'
			}
		} else {
			result = '-'
		}

		return result
	}

	handlePage(event, page) {
		const { dispatch } = this.props
		event.preventDefault()
		dispatch(
			ContactActions.getContactHistory(this.createUrlQuery(page), page)
		)
	}

	getImageData(file) {
		let fileReader = new FileReader()
		fileReader.readAsDataURL(file)
		fileReader.onload = (ev) => {
			this.setState({
				error: false,
				message: '',
				image: ev.target.result,
			})
		}
	}

	handleClick(type) {
		this.setState({ checked: type })
	}

	setPager() {
		if (
			this.state.totalRecords === 0 ||
			this.state.totalRecords === undefined
		) {
			return false
		}

		let nowPage = parseInt(this.state.nowPage, 10)
		let totalRecords = parseInt(this.state.totalRecords, 10)
		let item = glasConfig.usermanagement.management
		let endPage =
			totalRecords > item ? parseInt(totalRecords / item, 10) : 0
		endPage =
			endPage !== 0 && totalRecords % item !== 0 ? endPage : endPage - 1

		let showPage = endPage < 5 ? endPage : 5
		let showHalfPage = Math.floor(showPage / 2)

		let loopStart = nowPage - showHalfPage
		let loopEnd = nowPage + showHalfPage

		if (loopStart <= -1) {
			loopStart = 0
			loopEnd = showPage - 1
		}
		if (loopEnd > endPage) {
			loopStart = endPage - showPage
			loopEnd = endPage
		}

		let firstPage = true
		let prevPage = true
		let lastPage = true
		let nextPage = true

		if (nowPage !== 0) {
			firstPage = false
		}
		if (nowPage !== 0 && endPage !== 0) {
			prevPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			lastPage = false
		}
		if (nowPage >= 0 && endPage !== nowPage) {
			nextPage = false
		}

		let prevNav = []
		if (endPage > 0) {
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={firstPage}
						onClick={(e) => this.handlePage(e, 0)}
					>
						<i className="material-icons">first_page</i>
					</button>
				</li>
			)
			let prev = nowPage > 0 ? nowPage - 1 : 0
			prevNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={prevPage}
						onClick={(e) => this.handlePage(e, prev)}
					>
						<i className="material-icons">navigate_before</i>
					</button>
				</li>
			)
		}

		let nextNav = []
		if (endPage > 0) {
			let next = nowPage !== endPage ? nowPage + 1 : endPage
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={nextPage}
						onClick={(e) => this.handlePage(e, next)}
					>
						<i className="material-icons">navigate_next</i>
					</button>
				</li>
			)
			nextNav.push(
				<li className="list__item">
					<button
						className="btn"
						disabled={lastPage}
						onClick={(e) => this.handlePage(e, endPage)}
					>
						{' '}
						<i className="material-icons">last_page</i>
					</button>
				</li>
			)
		}

		let pageNav = []
		for (let i = loopStart; i <= loopEnd; i++) {
			let pageClass = ['btn']
			if (i === nowPage) {
				pageClass.push('is-active')
			}

			pageNav.push(
				<li className="list__item">
					<button
						className={pageClass.join(' ')}
						onClick={(e) => this.handlePage(e, i)}
					>
						{i + 1}
					</button>
				</li>
			)
		}

		return (
			<div className="primary__inner">
				<div className="pager">
					<ul className="list">
						{prevNav}
						{pageNav}
						{nextNav}
					</ul>
				</div>
			</div>
		)
	}

	getContactHistorySP() {
		if (
			this.state.dataloaded === true &&
			(this.state.totalRecords == 0 ||
				this.state.totalRecords == undefined)
		) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴がありません
					</td>
				</tr>
			)
		} else if (this.state.dataloaded === false) {
			return (
				<tr>
					<td className="table-align-center" colSpan="6">
						対応履歴を読み込んでいます...
					</td>
				</tr>
			)
		}

		return Array.prototype.map.call(
			this.state.pageRecords,
			(current, index) => {
				return (
					<div
						className={`warpper-item-noti warpper-item-contact ${
							current.readflg === '0' &&
							'contact-tableSP__content-unread'
						}`}
						onClick={() => this.linkTo(current.id)}
						key={current.userId + current.notificationSettingId}
					>
						<div className="top-item">
							<span className="contactTime">
								{current.ymd
									? moment(
											current.ymd,
											'YYYY-MM-DD HH:mm'
									  ).format('YYYY/MM/DD HH:mm')
									: '-'}
							</span>
							<span className="contactStatus">
								ステータス: {this.getStatus(current)}
							</span>
						</div>
						<div className="content-item">
							<ul className="list-info">
								<li className="content-noti">
									お問い合わせ内容 : <br></br>{' '}
									{this.formatBody(
										current.admin_id != 0
											? '-'
											: current.body
									)}
								</li>
								<li className="info-user">
									カスタマーサポートからの回答 : <br></br>{' '}
									{this.formatBody(current.nearestAnswer)}
								</li>
							</ul>
						</div>
					</div>
				)
			}
		)
	}

	render() {
		return (
			<div className="content">
				<h1 className="content__title hidden-sp">お問い合わせ</h1>
				<ContactMenu />
				<div className="primary">
					<div className="primary-title hidden-sp">
						<h1 className="primary-title__main">
							お問い合わせ履歴
						</h1>
					</div>
					<div className="contact-table hidden-sp">
						<table className="table-data">
							<thead>
								<tr>
									<th
										style={{ width: '20%' }}
										className="table-align-center"
									>
										日付
									</th>
									<th
										style={{ width: '30%' }}
										className="table-align-center"
									>
										お問い合わせ内容
									</th>
									<th
										style={{ width: '30%' }}
										className="table-align-center"
									>
										スタッフからの回答
									</th>
									<th
										style={{ width: '20%' }}
										className="table-align-center"
									>
										ステータス
									</th>
								</tr>
							</thead>
							<tbody>{this.getContactHistory()}</tbody>
						</table>
					</div>
					<div className="customer-list-sp display-mobile">
						{this.getContactHistorySP()}
					</div>
					{this.setPager()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		MyProfile: store.MyProfile,
		Contact: store.Contact,
	}
}

export default connect(mapStateToProps)(ContactHistory)
