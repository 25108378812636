import {
	USER_INFO_FETCH,
	USER_INFO_POST,
	USER_INFO_MERGE,
	UPDATE_TELLER_FREE_CHAT_SETTING,
} from '../constants/ActionTypes'

const initialState = {
	loaded: false,
	data: {},
}

export const UserInfo = (state = initialState, action) => {
	switch (action.type) {
		case USER_INFO_FETCH: {
			return Object.assign({}, state, {
				loaded: true,
				data: action.data,
			})
		}
		case USER_INFO_POST: {
			return Object.assign({}, state, {
				loaded: true,
				data: action.data,
			})
		}
		case USER_INFO_MERGE: {
			if (state.data.userId === action.data.userId) {
				Object.assign(state.data, action.data)
			}
			return Object.assign({}, state, {
				loaded: true,
			})
		}
		case UPDATE_TELLER_FREE_CHAT_SETTING: {
			if (state.data.userId === action.data.userId) {
				state.data.isTellerSettingFreeChat = action.data.isSettingFreeChat
			}
			
			return Object.assign({}, state, {
				loaded: true,
			})
		}
		default: {
			return state
		}
	}
}
