import React, { Component } from 'react'

import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import * as Fetch from './../util/Fetch'
import {
	QuestionCategoryList,
	QuestionSortByList,
	QuestionStatusList,
} from './../constants/Config'

class AnswerCategories extends Component {
	constructor(props) {
		super(props)
		this.state = {
			statusAnswer: localStorage.getItem('statusAnswer')
				? parseInt(localStorage.getItem('statusAnswer'))
				: 1,
			categoryAnswer: localStorage.getItem('categoryAnswer')
				? parseInt(localStorage.getItem('categoryAnswer'))
				: 0,
			sortByAnswer: localStorage.getItem('sortByAnswer')
				? parseInt(localStorage.getItem('sortByAnswer'))
				: 1,
		}
	}

	onSelectAnswerStatus = (event, index, value) => {
		this.setState({ statusAnswer: value }, () =>
			this.props.changeConditionsSearchQuestion(this.state)
		)
	}

	onSelectAnswerCategory = (event, index, value) => {
		this.setState({ categoryAnswer: value }, () =>
			this.props.changeConditionsSearchQuestion(this.state)
		)
	}

	onSelectAnswerSortBy = (event, index, value) => {
		this.setState({ sortByAnswer: value }, () =>
			this.props.changeConditionsSearchQuestion(this.state)
		)
	}

	render() {
		const deviceType = Fetch.fetchUserAgent()
		return (
			<div className="answer-categories">
				<span className="answer-category-title">絞り込み</span>
				<ul className="list-answer-select">
					<li className="answer-category-item">
						<SelectField
							onChange={this.onSelectAnswerStatus}
							value={this.state.statusAnswer}
							style={{
								flex: 1,
								top: '4px',
								fontSize: '15px',
								width: 124,
							}}
							selectedMenuItemStyle={{ color: 'rgb(17,173,157)' }}
							className="select-feild"
							autoWidth={true}
							menuItemStyle={
								deviceType !== 3 ? { fontSize: 11 } : {}
							}
						>
							{QuestionStatusList.map((item, index) => {
								return (
									<MenuItem
										value={item.value}
										primaryText={item.label}
										key={index}
									/>
								)
							})}
						</SelectField>
					</li>
					<li className="answer-category-item">
						<SelectField
							onChange={this.onSelectAnswerCategory}
							value={this.state.categoryAnswer}
							style={{
								flex: 1,
								top: '4px',
								fontSize: '15px',
								width: 124,
							}}
							selectedMenuItemStyle={{ color: 'rgb(17,173,157)' }}
							className="select-feild"
							autoWidth={true}
							menuItemStyle={
								deviceType !== 3 ? { fontSize: 11 } : {}
							}
						>
							{QuestionCategoryList.map((item, index) => {
								return (
									<MenuItem
										value={item.value}
										primaryText={item.label}
										key={index}
									/>
								)
							})}
						</SelectField>
					</li>
					<li className="answer-category-item">
						<SelectField
							onChange={this.onSelectAnswerSortBy}
							value={this.state.sortByAnswer}
							style={{
								flex: 1,
								top: '4px',
								fontSize: '15px',
								width: 154,
							}}
							selectedMenuItemStyle={{ color: 'rgb(17,173,157)' }}
							className="select-feild"
							autoWidth={true}
							menuItemStyle={
								deviceType !== 3
									? { width: 96, fontSize: 10 }
									: {}
							}
						>
							{QuestionSortByList.map((item, index) => {
								return (
									<MenuItem
										value={item.value}
										primaryText={item.label}
										key={index}
										className="itemSelectAnswer"
									/>
								)
							})}
						</SelectField>
					</li>
				</ul>
			</div>
		)
	}
}

export default AnswerCategories
