import {
	USER_INFO_FETCH,
	USER_INFO_POST,
	USER_INFO_MERGE,
	UPDATE_TELLER_FREE_CHAT_SETTING,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import request from 'axios'

const resultSuccess = (type, isload, data) => {
	return {
		type: type,
		loaded: isload,
		data,
	}
}

export const fetchProfile = (userId) => {
	return (dispatch) => {
		const url = glasConfig.url_base + glasConfig.path_user_info + userId
		const token = localStorage.getItem('token')

		const options = {
			method: 'GET',
			url: url,
			headers: {
				Authorization: token,
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(USER_INFO_FETCH, true, response.data.data)
					)
				} else {
					dispatch(resultSuccess(USER_INFO_FETCH, true, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + USER_INFO_FETCH, error)
				throw error
			})
	}
}

export const post = (item, req) => {
	return (dispatch) => {
		const requestData = (item, value) => {
			if (item === 'memo') {
				return {
					friendId: value.userId,
					memo: value.memo,
				}
			}
		}

		const requestUrl = (item) => {
			if (item === 'memo') {
				return glasConfig.path_memos
			}
		}

		const url = glasConfig.url_base + requestUrl(item)

		return request
			.post(url, requestData(item, req), {
				headers: {
					Authorization: localStorage.getItem('token'),
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(USER_INFO_POST, true, req))
				}
				return response
			})
			.catch((error) => {
				console.log('error on call ' + USER_INFO_POST, error)
				throw error
			})
	}
}

/**
 * stateに保存されているデータを一部更新する
 * 通知から左カラムの情報を更新したい場合など。
 */
export const merge = (req) => {
	return (dispatch) => dispatch(resultSuccess(USER_INFO_MERGE, true, req))
}


export const updateTellerFreeChatSetting = (req) => {
    return (dispatch) => {
		const token = localStorage.getItem('token')
		return request
			.put(
				`${glasConfig.url_base}${glasConfig.update_teller_free_chat_setting}`,
				req,
				{
					headers: {
						Authorization: token,
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(UPDATE_TELLER_FREE_CHAT_SETTING, true, {...response.data.data, userId: req.userId}))
				}
			})
			.catch((error) => {
				throw error
			})
	}
}
