/**
 * Home画面に出すスタッフブログ
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
	StyledHomeColumn,
	StyledTitle,
	StyledContainerHomeBlogList,
} from './Home/Styled/StyledHomeComponents'
import StaffBlogList from './StaffBlogList'

class HomeStaffBlog extends Component {
	render() {
		const { data } = this.props

		if (!data.loaded) {
			return null
		}

		if (!Object.keys(data).length) {
			return null
		}

		return (
			<StyledHomeColumn column={2} gap={24}>
				<StyledTitle>運営ブログ</StyledTitle>
				<StyledContainerHomeBlogList>
					<StaffBlogList data={data.data.list} />
				</StyledContainerHomeBlogList>
				<Link to="/home/staffblog/1" className="link-to-staff">
					運営ブログをもっと見る
				</Link>
			</StyledHomeColumn>
		)
	}
}

const mapStateToProps = (state) => {
	return {}
}

export default connect(mapStateToProps)(HomeStaffBlog)
