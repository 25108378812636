import {
	CUSTOMER_MANAGEMENT_SUPPORT_HISTORY_GET,
	CUSTOMER_MANAGEMENT_FOLLOWER_GETTING,
	CUSTOMER_MANAGEMENT_FOLLOWER_GET,
	CUSTOMER_MANAGEMENT_NOTIFICATION_GET,
	CUSTOMER_MANAGEMENT_BLOCK_GET,
} from '../constants/ActionTypes'

const initialState = {
	loading: false,
	data: {},
}

export const CustomerManagement = (state = initialState, action) => {
	switch (action.type) {
		case CUSTOMER_MANAGEMENT_SUPPORT_HISTORY_GET:
			return Object.assign({}, state, action.data)
		case CUSTOMER_MANAGEMENT_FOLLOWER_GETTING:
			return { ...state, loading: true }
		case CUSTOMER_MANAGEMENT_FOLLOWER_GET:
			return Object.assign({}, state, action.data)
		case CUSTOMER_MANAGEMENT_NOTIFICATION_GET:
			return Object.assign({}, state, action.data)
		case CUSTOMER_MANAGEMENT_BLOCK_GET:
			return Object.assign({}, state, action.data)
		default:
			return state
	}
}
