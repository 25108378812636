/**
 * リクエスト履歴（左カラムの真ん中のタブをクリック）
 */
import {
	AUTH_LOGOUT,
	REFRESH_HISTORY_READ_UN_PIN,
	REFRESH_HISTORY_UNREAD_UN_PIN,
	REFRESH_ITEM_PIN_CHAT,
	REQUEST_DELETE_MESSAGE_READ,
	REQUEST_DELETE_MESSAGE_UNREAD,
	REQUEST_HISTORY,
	REQUEST_HISTORY_MERGE,
	REQUEST_HISTORY_PIN,
	REQUEST_HISTORY_READ,
	REQUEST_HISTORY_UNREAD,
	REQUEST_PIN_CHAT,
	REQUEST_UNPIN_CHAT,
	RESET_REQUEST_HISTORY,
} from '../constants/ActionTypes'
import { RESPONSE_CODE_SERVER, glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'
import axios from 'axios'
import { isPWA } from '../helper/helpFunction'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

export const requestListPin = () => {
	const tellerId = Fetch.tellerId()
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.get_list_message_pin + `${tellerId}/pin`
		let params = [
			'page=0',
			'size=24',
		]
		let makeParam = `?${params.join('&')}`

		axios
			.get(url + makeParam, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				if (response.data.code == RESPONSE_CODE_SERVER.TOKEN_EXPIRED || response.data.code == RESPONSE_CODE_SERVER.REVIEW_CODE) {
					Fetch.setLocalStorage()
					dispatch(resultSuccess(AUTH_LOGOUT))
					return 
				}
				dispatch(
					resultSuccess(REQUEST_HISTORY_PIN, response.data.data)
				)
			})
	}
}
export const pinChat = (req, callback) => {
	const tellerId = Fetch.tellerId()
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat + `${tellerId}/pin` + `?friendId=${req.friendID}`

		axios
			.put(url, {}, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (res) {
				if (res.data.code === 0) {
					dispatch(
						resultSuccess(REQUEST_PIN_CHAT, [req.message])
					)
					if (req.message.unreadMsgBadge > 0) {
						dispatch(
							resultSuccess(REQUEST_DELETE_MESSAGE_UNREAD, req.message)
						)
					} else {
						dispatch(
							resultSuccess(REQUEST_DELETE_MESSAGE_READ, req.message)
						)
					}
				} else {
					callback(res.data.code)
				}

			})
	}
}

export const addItemToPin = (req) => {
	return (dispatch) => {
		dispatch(
			resultSuccess(REQUEST_PIN_CHAT, [req.message])
		)
	}
}
export const refreshItemPin = (req) => {
	return (dispatch) => {
		dispatch(
			resultSuccess(REFRESH_ITEM_PIN_CHAT, req.message)
		)
	}
}

export const unpinChat = (req, callback) => {
	const tellerId = Fetch.tellerId()
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat + `${tellerId}/unpin` + `?friendId=${req.friendID}`

		axios
			.delete(url, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (res) {
				if (res.data.code === 0) {
					dispatch(
						resultSuccess(REQUEST_UNPIN_CHAT, req.message)
					)
					if (req.message.unreadMsgBadge > 0) {
						dispatch(
							resultSuccess(REFRESH_HISTORY_UNREAD_UN_PIN, [req.message])
						)
					} else {
						dispatch(
							resultSuccess(REFRESH_HISTORY_READ_UN_PIN, [req.message])
						)
					}
				} else {
					callback()
				}
			})
	}
}
export const requestHistoryUnRead = (req) => {
	return (dispatch) => {
		// const tellerId = Fetch.tellerId();
		const token = localStorage.getItem('token')
		const path = isPWA() ? glasConfig.path_last_chat_unread_v2 : glasConfig.get_list_message_unread_unpin
		const url = glasConfig.url_base + path
		let params = [
			'tab=all',
			`page=${req.page}`,
			`size=${req.size}`,
			`inConversation=${req.inConversation}`,
		]
		let makeParam = `?${params.join('&')}`

		axios
			.get(url + makeParam, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				if (response.data.code == RESPONSE_CODE_SERVER.TOKEN_EXPIRED || response.data.code == RESPONSE_CODE_SERVER.REVIEW_CODE) {
					Fetch.setLocalStorage()
					dispatch(resultSuccess(AUTH_LOGOUT))
					return 
				}
				dispatch(
					resultSuccess(REQUEST_HISTORY_UNREAD, { data: response.data.data, isLoadmore: req.isLoadmore })
				)
			})
	}
}

export const requestHistoryRead = (req) => {
	return (dispatch) => {
		// const tellerId = Fetch.tellerId();
		const token = localStorage.getItem('token')
		const path = isPWA() ? glasConfig.path_last_chat_read_v2 : glasConfig.get_list_message_read_unpin
		const url = glasConfig.url_base + path
		let params = [
			'tab=all',
			`page=${req.page}`,
			`size=${req.size}`,
			`inConversation=${req.inConversation}`,
		]
		let makeParam = `?${params.join('&')}`

		axios
			.get(url + makeParam, {
				headers: {
					Authorization: token,
					'Content-Type': 'application/json',
				},
			})
			.then(function (response) {
				if (response.data.code == RESPONSE_CODE_SERVER.TOKEN_EXPIRED || response.data.code == RESPONSE_CODE_SERVER.REVIEW_CODE) {
					Fetch.setLocalStorage()
					dispatch(resultSuccess(AUTH_LOGOUT))
					return 
				}
				// dispatch(resultSuccess(REQUEST_HISTORY_READ_DEFAULT, {}));
				dispatch(
					resultSuccess(REQUEST_HISTORY_READ, response.data.data)
				)
			})
	}
}

export const resetRequestHistoryPage = () => {
	return (dispatch) => {
		dispatch(resultSuccess(RESET_REQUEST_HISTORY))
	}
}

export const fetch = (req) => {
	return (dispatch, getState) => {
		const params = {
			page: req.page,
			size: req.size,
			inConversation: req.inConversation,
		}

		const tellerId = Fetch.tellerId()
		const token = localStorage.getItem('token')
		const url = glasConfig.url_base + glasConfig.path_last_chat_read_v2
		// path_last_chat_unread_v2
		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}

		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					let props = getState()
					let data = req.page !== 0 ? props.RequestHistory.data : []
					let result = {}

					if (response.data.data) {
						// let props = getState();
						for (let i in response.data.data) {
							const userId =
								response.data.data[i].toId === tellerId
									? response.data.data[i].fromId
									: response.data.data[i].toId
							data[userId] = response.data.data[i]
						}
						// if(req.page > props.RequestHistory.data.page){
						//   result.data = Object.values(props.RequestHistory.data).concat(Object.values(data));
						// }
						if (req.page === 0) {
							// result = Object.values(data)
							result.endHistory = false
						}
						if (Object.values(response.data.data).length === 0) {
							result.endHistory = true
						}

						result.page = req.page
						result.data = data
					}
					dispatch(resultSuccess(REQUEST_HISTORY, result))
				}
			})
			.catch((error) => {
				console.log('error on call ' + REQUEST_HISTORY, error)
				throw error
			})
	}
}

/**
 * stateに保存されているデータを一部更新する
 * 通知から左カラムの情報を更新したい場合など。
 */
export const merge = (req) => {
	return (dispatch) => dispatch(resultSuccess(REQUEST_HISTORY_MERGE, req))
}
