import React from 'react'
import { Dialog, Button, FormControlLabel, Checkbox } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import TutorialPushPinImage from '../../../assets/img_tutorial_review.png'
import {
	BottomContent,
	CenterBlock,
	ContainerContent,
	ImageTutorial,
	StyledCloseIcon,
	TopContent,
} from './StyledComponentsModalTutorialReviewPage'
import {
	colors,
	fonts,
	MOBILE_SCREEN,
	TABLET_SCREEN,
	LAPTOP_SCREEN,
} from '../../../util/StellaConstant'
import { Label } from 'react-bootstrap'

export const ModalTutorialReviewPage = (props) => {
	const classes = useStyles()
	const [show, setShow] = useState(false)

	useEffect(() => {
		setShow(props.show)
	}, [props.show])

	const closeDialog = () => setShow(false)

	const onCancel = () => {
		closeDialog()
		props.updateStatusOfReviewTutorial()
		props.resetToggle()
	}

	return (
		<Dialog
			classes={{
				paper: classes.dialogRoot,
			}}
			onClose={onCancel}
			open={show}
		>
			<ContainerContent>
				<StyledCloseIcon>
					<CloseIcon
						classes={{ root: classes.closeIcon }}
						onClick={onCancel}
					/>
				</StyledCloseIcon>

				<TopContent>
					<p>
						<span>直近2ヶ月以内についたレビューを最大２件まで</span>
						ピン留めできます。
						<br />
						ピン留めしたレビューはあなたのレビュー一覧上部に固定され、お客様の目に触れやすくなります。
						<br />
						お客様へ特にアピールしたいレビューをピン留めしてみましょう！
					</p>
				</TopContent>
				<ImageTutorial src={TutorialPushPinImage} />
				<BottomContent>
					<p>
						・ピン留めは2ヶ月間のみ有効で、期限が切れるとピン留めが外れます。
					</p>
					<p>
						・ピン留めしたレビューはいつでも変更することが可能です。
					</p>
					<p>
						・「どのように表示されているか」はStellaユーザー向けアプリから確認することができます。
					</p>
				</BottomContent>
				<Button
					classes={{ root: classes.buttonOKRoot }}
					onClick={onCancel}
				>
					OK
				</Button>
				<CenterBlock>
					<label class="container-answer_checkbox " id="popup-review">
						今後表示しない
						<input type="checkbox"/>
						<span class="checkmark"></span>
					</label>
				</CenterBlock>
			</ContainerContent>
		</Dialog>
	)
}

const useStyles = makeStyles({
	formControlLabelRoot: {
		marginLeft: 0,
		marginRight: 9,
		marginBottom: 0,
	},
	labelCheckbox: {
		[MOBILE_SCREEN]: {
			fontFamily: fonts.HiraginoKakuGothicPro,
			fontSize: 14,
			color: colors.taupeGray,
			marginLeft: -2,
			marginBottom: -2,
		},
		[TABLET_SCREEN]: {
			fontFamily: fonts.HiraginoKakuGothicPro,
			fontSize: 16,
			color: colors.black,
			marginLeft: -2,
			marginBottom: -2,
		},
		[LAPTOP_SCREEN]: {
			fontFamily: fonts.HiraginoKakuGothicPro,
			fontSize: 14,
			color: colors.taupeGray,
			marginLeft: -2,
			marginBottom: -2,
		},
	},
	checked: {
		color: `${colors.pearlAqua}!important`,
	},
	dialogRoot: {
		[MOBILE_SCREEN]: {
			width: '90%',
		},
		[TABLET_SCREEN]: {
			width: '58%',
		},
		[LAPTOP_SCREEN]: {
			width: '45.7%',
		},
	},
	buttonOKRoot: {
		[MOBILE_SCREEN]: {
			backgroundColor: colors.pearlAqua,
			borderRadius: 2,
			boxShadow:
				'0px 2px 2px 0px rgba(0, 0, 0, 0.24), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
			fontFamily: fonts.HiraginoKakuGothicPro,
			fontSize: 14,
			color: colors.white,
			margin: ' 13px auto 10px',
			display: 'flex',
			padding: '6px 73.5px 6px',

			'&:hover': {
				backgroundColor: colors.pearlAqua,
				color: colors.white,
			},
		},
		[TABLET_SCREEN]: {},
		[LAPTOP_SCREEN]: {
			backgroundColor: colors.pearlAqua,
			borderRadius: 2,
			boxShadow:
				'0px 2px 2px 0px rgba(0, 0, 0, 0.24), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)',
			fontFamily: fonts.HiraginoKakuGothicPro,
			fontSize: 15,
			color: colors.white,
			margin: ' 13px auto 10px',
			display: 'flex',
			padding: '6px 39.5px 4px',

			'&:hover': {
				backgroundColor: colors.pearlAqua,
				color: colors.white,
			},
		},
	},
	closeIcon: {
		color: colors.oldLavender,
		cursor: 'pointer',
	},
})
