/**
 * 左メニューの真ん中。お客様リスト（チャット・通話の履歴を表示）
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { glasConfig } from '../constants/Config'
import LeftFollowerList from './LeftFollowerList'
import '../assets/scss/leftFollowerArea.scss'
import * as FollowersActions from '../actions/FollowersActions'
import DetectRTC from 'detectrtc'

class LeftFollower extends Component {
	constructor(props) {
		super(props)
		this.state = {
			offset: 1,
			limit: 30,
			sortByValue: glasConfig.sortASC,
		}
	}
	componentDidMount() {
		const { dispatch } = this.props
		dispatch(
			FollowersActions.fetch({
				page: 0,
				size: this.state.limit,
				sortByCreatedAt: this.state.sortByValue,
			})
		)
	}
	componentWillReceiveProps(nextProps) {
		if (!this.props.show && nextProps.show) {
			this.setState({
				offset: 1,
			})
		}
	}

	loadData(page) {
		this.setState({
			offset: page,
			limit: page * glasConfig.leftmenu_maxlength_follower,
		})
		const { dispatch } = this.props
		dispatch(
			FollowersActions.fetch({
				page: 0,
				size: glasConfig.leftmenu_maxlength_follower * page,
				sortByCreatedAt: this.state.sortByValue,
			})
		)
	}

	sortByDataFollower = () => {
		const { dispatch } = this.props
		let sortByValue
		if (this.state.sortByValue === glasConfig.sortASC) {
			sortByValue = glasConfig.sortDESC
		} else {
			sortByValue = glasConfig.sortASC
		}

		this.setState({
			sortByValue: sortByValue,
		})
		dispatch(
			FollowersActions.fetch({
				page: 0,
				size: this.state.limit,
				sortByCreatedAt: sortByValue,
			})
		)
	}

	render() {
		const { data, chatUserId } = this.props
		const maxnum = Object.keys(data.data).length
		/*if ( !(show && data.loaded) ){
      return null
    }*/

		return (
			<div style={{ position: 'relative' }}>
				<div
					className="secondary-definition-wrap"
					style={
						!DetectRTC.isMobileDevice
							? style.listChatWrap
							: style.listChatWrapMobile
					}
				>
					<dl
						className="secondary-definition box-width-follower"
						style={style.boxWidthFollower}
					>
						<dt className="secondary-definition__title left-follower-area-row">
							<div>フォロワー</div>
							<div
								style={{
									fontWeight: 'bold',
									cursor: 'pointer',
								}}
								onClick={() => this.sortByDataFollower()}
							>
								新着順
								{this.state.sortByValue ===
								glasConfig.sortASC ? (
									<span
										style={{ marginLeft: 5 }}
										className={
											'glyphicon glyphicon-menu-down'
										}
									/>
								) : (
									<span
										style={{ marginLeft: 5 }}
										className={
											'glyphicon glyphicon-menu-up'
										}
									/>
								)}
							</div>
						</dt>

						<dd className="secondary-definition__data">
							<div className="list column_1">
								<LeftFollowerList
									listData={data.data}
									chatUserId={chatUserId}
								/>
							</div>
						</dd>
					</dl>
				</div>
				<div className={'box-btn-follower-click'}>
					{maxnum === this.state.limit ? (
						<div
							className={'btn-follower-area'}
							onClick={() => this.loadData(this.state.offset + 1)}
						>
							すべて見る
						</div>
					) : null}
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
		ChatRequest: state.ChatRequest,
		RequestOffline: state.RequestOffline,
		RequestHistory: state.RequestHistory,
		Follower: state.Follower,
	}
}

export default connect(mapStateToProps)(LeftFollower)

const style = {
	listChatWrap: {
		height: window.innerHeight - 372,
	},
	listChatWrapMobile: {
		height: window.innerHeight - 320,
	},
}
