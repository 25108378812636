import { MYINFO_NOTIFICATION } from '../constants/ActionTypes'

const initialState = {
	data: {},
}

export const MyNotification = (state = initialState, action) => {
	switch (action.type) {
		case MYINFO_NOTIFICATION: {
			return Object.assign({}, state, action.data)
		}
		default: {
			return state
		}
	}
}
