import {
	CHAT_REQUEST,
	CHAT_REQUEST_ANSWER,
	CHAT_REQUEST_MERGE,
	CHAT_REQUEST_DELETE,
	CHAT_REQUEST_DEFAULT,
	CHAT_REQUEST_NEW,
} from '../constants/ActionTypes'
import * as Fetch from '../util/Fetch'
import { getUserId } from '../assets/help/help'

const initialState = {
	loaded: false,
	data: {},
}

export const ChatRequest = (state = initialState, action) => {
	switch (action.type) {
		case CHAT_REQUEST_DEFAULT: {
			let newListSet = {}
			if (Object.keys(action.data).length > 0) {
				let mapObject = action.data
				Object.values(mapObject).map((item) => {
					Object.assign(newListSet, { [getUserId(item)]: item })
				})

				return {
					loaded: true,
					data: newListSet,
				}
			}
		}
		case CHAT_REQUEST_NEW: {
			return {
				loaded: true,
				data: action.data,
			}
		}
		/*case CHAT_REQUEST_ANSWER: {
      return Object.assign({}, state, {
        loaded: false,
        data: {}
      })
    }*/
		/*case CHAT_REQUEST_MERGE: {
      if (action.data.userId) {

        let orgData = {}
        if ( !state.data[action.data.userId] ) {
          if ( action.data.data.unreadMessageBadge === undefined ) {
            action.data.data.unreadMessageBadge = 1
          }
          orgData = action.data.data
        } else {
          orgData = state.data[action.data.userId]
          if ( action.data.data.unreadMessageBadge !== 0 ) {
            action.data.data.unreadMessageBadge = (orgData.unreadMessageBadge === undefined) ? 0 : orgData.unreadMessageBadge + 1
          }
          //一旦結合させる
          Object.assign(orgData, action.data.data)
          //先頭にもってくるために、一旦state.dataから削除する
          delete state.data[action.data.userId]
        }
        //先頭に追加

        return {...state, data : {...state.data, [action.data.userId] : orgData}, loaded : true};
      }

      return {...state, loaded : true};
    }*/
		/*case CHAT_REQUEST_DELETE: {
      const userId = action.data
      if ( state.data[userId] ) {
        delete state.data[userId]
      }
      return Object.assign({}, state, {
        loaded: true,
      })
    }*/
		default: {
			return state
		}
	}
}
