import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { POINT_REPORT, getRateOfPaidMessagesNotice } from '../../../../constants/PointReportConstants'
import {
	ActualPoint,
	ChartContainer,
	RateOfPaidMessages,
	RateOfReplyUserQuestions,
	RedirectDailyReward,
	TabTitle,
	TargetPoint,
	WorkingDayInAMonth,
	Title,
	Row,
	ButtonSetTargetPoint,
	ContentLayout,
	ChartBlock,
	StatisticBlock,
	Ruler,
	RateOfPaidMessagesNotice1,
	RateOfPaidMessagesNotice2,
	RedirectDailyRewardForMobile,
	TotalActualPoint,
	LastDayActualPoint,
	PointBonus
} from '../../StyledPointReport'
import ButtonRedirectDailyReward from '../Common/ButtonRedirectDailyReward'
import Statistic from '../Common/Statistic'
import PointChart from '../Main/PointChart'
import '../../StyledPointReport/Chart/PointChart.scss'
import { colors, fonts } from '../../../../util/StellaConstant'

const ReportChart = (props) => {
	const { openSetTargetPoint, targetPoint, PointReportData } = props

	const commonData = PointReportData.commonData.value
	return (
		<ContentLayout id='point-chart'>
			<TabTitle>今月のレポート</TabTitle>
			<ChartBlock>
				<TargetPoint>
					<Title type={POINT_REPORT.TYPES.TARGET_POINT}>
						{POINT_REPORT.TITLES.TARGET_POINT.label}
					</Title>
					<Row align='flex-end'>
						<Statistic type={POINT_REPORT.TYPES.TARGET_POINT} data={targetPoint} />
						<ButtonSetTargetPoint
							onClick={openSetTargetPoint}
						>
							目標設定
						</ButtonSetTargetPoint>
					</Row>
				</TargetPoint>
				<ActualPoint>
					<Title type={POINT_REPORT.TYPES.ACTUAL_POINT}>
						{POINT_REPORT.TITLES.ACTUAL_POINT.label}
					</Title>
					<Row align='space-between'>
						<TotalActualPoint>合計</TotalActualPoint>
						<Statistic type={POINT_REPORT.TYPES.ACTUAL_POINT} data={commonData.pointEarnedThisMonth} />
						<LastDayActualPoint>&nbsp;&nbsp;&nbsp;&nbsp;／前日&nbsp;&nbsp;</LastDayActualPoint>
						<Statistic type={POINT_REPORT.TYPES.ACTUAL_POINT_ADDITION} data={commonData.pointEarnedLastDay} />
					</Row>
				</ActualPoint>
				<PointBonus>
					<Title type={POINT_REPORT.TYPES.POINT_BONUS}>
						{POINT_REPORT.TITLES.POINT_BONUS.label}
					</Title>
					<Row align='right'>
						<Statistic type={POINT_REPORT.TYPES.POINT_BONUS} data={commonData.freePointThisMonth} />
					</Row>
				</PointBonus>
				<ChartContainer>
					<PointChart targetPoint={targetPoint} />
					<ButtonRedirectDailyReward />
				</ChartContainer>
				<RedirectDailyRewardForMobile>
					<Link
						to="/home/reward/daily"
						style={{
							display: 'block',
							color: colors.mediumBlue,
							textDecoration: 'none'
						}}
					>
						日別報酬を見る
					</Link>
				</RedirectDailyRewardForMobile>
			</ChartBlock>
			<StatisticBlock>
				<WorkingDayInAMonth>
					<Title type={POINT_REPORT.TYPES.WORKING_DAY}>
						{POINT_REPORT.TITLES.WORKING_DAY.label}
					</Title>
					<Statistic type={POINT_REPORT.TYPES.WORKING_DAY} data={commonData.totalDayWorkThisMonth} />
					<Ruler />
					<p>前月の稼働日数 <Statistic type={POINT_REPORT.TYPES.WORKING_DAY_ADDITION} data={commonData.totalDayWorkLastMonth} /></p>
				</WorkingDayInAMonth>
				<RateOfPaidMessages>
					<Title type={POINT_REPORT.TYPES.RATE_OF_PAID_MESSAGES}>
						{POINT_REPORT.TITLES.RATE_OF_PAID_MESSAGES.label}
					</Title>
					<Statistic type={POINT_REPORT.TYPES.RATE_OF_PAID_MESSAGES} data={commonData.ratioMessage} />
					<Ruler />
					<RateOfPaidMessagesNotice1>{getRateOfPaidMessagesNotice(commonData.ratioMessage)}
					</RateOfPaidMessagesNotice1>
					<RateOfPaidMessagesNotice2>
						※送信メッセージ文字数のうち何%が有料で
						<br />
						送信しているかを示す。
						<br />
						※60%程度が目安
					</RateOfPaidMessagesNotice2>
				</RateOfPaidMessages>
				<RateOfReplyUserQuestions>
					<Title type={POINT_REPORT.TYPES.RATE_OF_RESPONSING_USER_QUESTIONS}>
						{POINT_REPORT.TITLES.RATE_OF_RESPONSING_USER_QUESTIONS.label}
					</Title>
					<Statistic type={POINT_REPORT.TYPES.RATE_OF_RESPONSING_USER_QUESTIONS} data={commonData.totalAnswerThisMonth} />
					<Ruler />
					<p>前月の教えて!先生回答数<Statistic type={POINT_REPORT.TYPES.RATE_OF_RESPONSING_USER_QUESTIONS_ADDITION} data={commonData.totalAnswerLastMonth} /></p>
				</RateOfReplyUserQuestions>
			</StatisticBlock>
		</ContentLayout>
	)
}

export default ReportChart