import React, { Component } from 'react'
import CheckBoxCM from './CheckBoxCM'

class InputUserGenderCheckbox extends Component {
	constructor(props) {
		super(props)
		this.state = {
			userGender: [],
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (nextProps.defaultValue !== this.state.userGender) {
			this.setState({
				userGender: nextProps.defaultValue,
			})
		}
		return true
	}

	changeValue = (checked, value) => {
		let listData = this.state.userGender
		if (checked && listData.indexOf(value) < 0) {
			listData.push(value)
		} else {
			listData.splice(listData.indexOf(value), 1)
		}
		this.setState({
			userGender: listData,
		})
		this.props.onData(listData)
	}

	render() {
		let listUserGender = this.state.userGender
		return (
			<div className="form-inline-row-CM">
				<CheckBoxCM
					defaultValue={
						listUserGender !== undefined &&
						listUserGender.indexOf('2') > -1
					}
					onData={(value) => this.changeValue(value, '2')}
					titleText={'女'}
					locationText={true}
				/>
				<CheckBoxCM
					defaultValue={
						listUserGender !== undefined &&
						listUserGender.indexOf('1') > -1
					}
					onData={(value) => this.changeValue(value, '1')}
					titleText={'男'}
					locationText={true}
				/>
				<CheckBoxCM
					defaultValue={
						listUserGender !== undefined &&
						listUserGender.indexOf('3') > -1
					}
					onData={(value) => this.changeValue(value, '3')}
					titleText={'その他'}
					locationText={true}
				/>
			</div>
		)
	}
}

export default InputUserGenderCheckbox
