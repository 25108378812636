import React, { Component } from 'react'
import CheckBoxCM from './CheckBoxCM'
import $ from 'jquery'
import Popup from 'reactjs-popup'

const zodiacDefault = [
	{ title: 'おひつじ座', value: 'aries' },
	{ title: 'おうし座', value: 'taurus' },
	{ title: 'ふたご座', value: 'gemini' },
	{ title: 'かに座', value: 'cancer' },
	{ title: 'しし座', value: 'leo' },
	{ title: 'おとめ座', value: 'virgo' },
	{ title: 'てんびん座', value: 'libra' },
	{ title: 'さそり座', value: 'scorpio' },
	{ title: 'いて座', value: 'sagittarius' },
	{ title: 'やぎ座', value: 'capricorn' },
	{ title: 'みずがめ座', value: 'aquarius' },
	{ title: 'うお座', value: 'pisces' },
]

class PopupZodiac extends Component {
	constructor(props) {
		super(props)
		this.state = {
			zodiacs: [],
		}
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		if (
			nextProps.defaultValue !== undefined &&
			nextProps.defaultValue !== this.state.zodiacs
		) {
			this.setState({
				zodiacs: nextProps.defaultValue,
			})
		}
		return true
	}

	componentDidMount() {}

	changeValue = (value) => {
		let listData = this.state.zodiacs
		if (listData.indexOf(value) < 0) {
			listData.push(value)
		} else {
			listData.splice(listData.indexOf(value), 1)
		}
		this.setState({
			zodiacs: listData,
		})
		this.props.onData(listData)
	}

	dropDownShow = (name, value) => {
		this.setState({
			[name]: !value,
		})
	}

	render() {
		let listUserData = this.state.zodiacs
		return (
			<div className="form-inline-row-CM">
				<Popup
					className={'popup-list'}
					trigger={
						<span className="label-text-92797 point-click">
							{listUserData.length > 0 &&
							listUserData.length !== 12 ? (
								<span className={'text-color-dropdown-green'}>
									指定あり
								</span>
							) : (
								'すべて'
							)}
							<span className="caret" />
						</span>
					}
					modal={true}
					contentStyle={{ width: '357px', borderRadius: 5 }}
				>
					{(close) => (
						<div className={'box-row-select-popup'}>
							<div className={'row-back-popup'}>
								<div
									className={'arrow-back-popup'}
									onClick={() => close()}
								>
									<i className="fas fa-chevron-left" />
								</div>
								<div className={'box-title-popup'}>
									悩み選択
								</div>
							</div>
							<div className={'content-row-select'}>
								{zodiacDefault.map((item, key) => {
									return (
										<div
											key={key}
											className={'row-list-select'}
											onClick={() =>
												this.changeValue(item.value)
											}
										>
											<div
												className={'title-list-select'}
											>
												{item.title}
											</div>
											<div className={'icon-tick-select'}>
												{listUserData.indexOf(
													item.value
												) < 0 ? (
													<i className="fas fa-check icon-color-select-tick" />
												) : (
													<i className="fas fa-check active icon-color-select-tick" />
												)}
											</div>
										</div>
									)
								})}
							</div>
						</div>
					)}
				</Popup>
			</div>
		)
	}
}

export default PopupZodiac
