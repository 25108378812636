import React, { Component } from 'react'
import { PrivateRoute } from '../PrivateRoute'
import Introduction from './Introduction'
import RegistrationWithIntroduction from './RegistrationWithIntroduction'
import { glasConfig } from '../../constants/Config'
import request from 'axios'
import * as Fetch from '../../util/Fetch'
import { IntroductionCustomer } from './IntroductionCustomer'

class RouteIntroduction extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isIntroduced: false,
		}
	}

	componentDidMount() {
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()
		return request
			.get(glasConfig.url_base + glasConfig.path_teller_info + tellerId, {
				headers: {
					Authorization: token,
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					const { isIntroduced } = response.data.data
					this.setState({ isIntroduced })
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				throw error
			})
	}

	render() {
		const { isIntroduced } = this.state
		return (
			<span>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}`}
					component={(props) => (
						<Introduction {...props} isIntroduced={isIntroduced} />
					)}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/customer`}
					component={(props) => (
						<IntroductionCustomer {...props} isIntroduced={isIntroduced} />
					)}
				/>
				{!!isIntroduced && (
					<PrivateRoute
						exact
						strict
						path={`${this.props.match.path}/registration`}
						component={(props) => (
							<RegistrationWithIntroduction
								{...props}
								isIntroduced={isIntroduced}
							/>
						)}
					/>
				)}
			</span>
		)
	}
}

export default RouteIntroduction
