/**
 * チャット申し込みダイアログ
 *
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DetailAnswerInChat } from '../../../service/api/ApiService'
import { categoryAnswer } from '../../../helper/helpFunction'
import moment from 'moment'
import { DETAIL_ANSWER_CHAT_LOG_DEFAULT } from '../../../constants/ActionTypes'

class DetailAnswerPWA extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			detailAnswerUser: null,
			detailAnswerTeller: null,
		}
	}

	componentDidMount = async () => {
		let dataNewAnswerUser = null
		let dataNewAnswerTeller = null
		let dataQuestUser = this.props.DetailAnswerChatLog.data
		if (dataQuestUser !== undefined && dataQuestUser !== null) {
			dataNewAnswerUser = dataQuestUser
			let params = { questionId: dataNewAnswerUser.questionId }
			await DetailAnswerInChat(params).then(function (res) {
				if (res.code !== undefined && res.code === 0) {
					dataNewAnswerTeller = res.data
				}
			})
		}
		this.setState({
			detailAnswerUser: dataNewAnswerUser,
			detailAnswerTeller: dataNewAnswerTeller,
		})
	}

	closeDetailMenuContent = () => {
		let { dispatch } = this.props
		dispatch({ type: DETAIL_ANSWER_CHAT_LOG_DEFAULT, data: { data: null } })
	}

	render() {
		let { DetailAnswerChatLog } = this.props
		if (this.state.detailAnswerUser === null) {
			return null
		}

		return (
			<div
				className={
					DetailAnswerChatLog.data === null
						? 'content-detail-answer-click'
						: 'content-detail-answer-click active-show'
				}
				onClick={(event) => event.stopPropagation()}
			>
				<div
					className={'box-left-menu-content-detail'}
					onClick={() => this.closeDetailMenuContent()}
				>
					<i className={'fas fa-angle-left'} />
				</div>
				<div className={'box-right-menu-content-detail'}>
					<div className={'box-detail-answer-chat-mobile'}>
						<div
							className={'box-content-detail-answer'}
							style={{ border: 0 }}
						>
							<div className={'row-first-detail-answer'}>
								<div className={'category-detail-answer'}>
									<span
										className={
											'category-item-detail-answer'
										}
									>
										{categoryAnswer(
											this.state.detailAnswerUser.category
										)}
									</span>
								</div>
								<div className={'date-detail-answer'}>
									{moment(
										this.state.detailAnswerUser.createTime
									).format('YYYY/MM/DD hh:mm')}
								</div>
							</div>
							<div className={'row-body-detail-answer-user'}>
								<pre>{this.state.detailAnswerUser.content}</pre>
							</div>
							<div className={'title-answer-teller'}>
								あなたの回答
							</div>
							<div className={'row-body-detail-answer-teller'}>
								{this.state.detailAnswerTeller !== null &&
									this.state.detailAnswerTeller.answerContent}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		DetailAnswerChatLog: state.DetailAnswerChatLog,
	}
}

export default connect(mapStateToProps)(DetailAnswerPWA)
