/**
 * 次回ログイン設定は現在APIで取得できませんので更新のみです(10/3)
 */
import { NEXTLOGIN_PUT } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import * as MyProfileActions from './MyProfileActions'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

//更新系
export const put = (value) => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		const tellerId = Fetch.tellerId()

		const params = value

		const options = {
			method: 'PUT',
			url:
				glasConfig.url_base +
				glasConfig.path_teller_info +
				tellerId +
				'/relogintime',
			data: params,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}
		dispatch(showLoading())
		return request(options)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(hideLoading())
					dispatch(resultSuccess(NEXTLOGIN_PUT, params))
					dispatch(MyProfileActions.fetch())
				} else {
					throw 'システムエラー'
				}
			})
			.catch((error) => {
				dispatch(hideLoading())
				console.log('error on call ' + NEXTLOGIN_PUT, error)
				throw error
			})
	}
}
