import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { glasConfig } from '../constants/Config'
import request from 'axios'
import * as moment from 'moment'
import * as Fetch from '../util/Fetch'
import RewardMenu from './RewardMenu'
import { formatSeconds } from '../util/StellaConstant'

const keys = [
	'TOTAL',
	'DAILY_BONUS',
	'ACTION_CALL',
	'ACTION_CHAT_FREE_ONLINE',
	'ACTION_CHAT_FREE_OFFLINE',
	'ACTION_CHAT_ONLINE',
	'ACTION_CHAT_OFFLINE',
	'ACTION_RECEIVE_CHAT_ONLINE',
	'ACTION_RECEIVE_CHAT_OFFLINE',
	'ACTION_SEND_IMAGE_FREE_ONLINE',
	'ACTION_SEND_IMAGE_FREE_OFFLINE',
	'ACTION_SEND_IMAGE_ONLINE',
	'ACTION_SEND_IMAGE_OFFLINE',
	'ACTION_RECEIVE_IMAGE_ONLINE',
	'ACTION_RECEIVE_IMAGE_OFFLINE',
	'ACTION_COMMENT',
	'FREE_POINT',
	'RECEIVE_GIFT',
	'INTRODUCTION',
	'WAITING_TIME_CALL',
	'WAITING_TIME_CHAT',
	'WAITING_TIME_CALL_AND_CHAT',
]

class RewardDaily extends Component {
	constructor(props) {
		super(props)
		this.state = {
			month: moment().format('YYYYMM'),
			data: {},
			statusItems: [],
			dataMonth: [],
			dataSP: {},
		}
	}

	componentWillMount() {
		this.getReport(this.props.match.params.month)
	}

	componentDidMount() {
		this.checkOtherTeller()
	}

	checkOtherTeller = () => {
		const lastTellerId = localStorage.getItem('lastTellerId')
		const tellerId = localStorage.getItem('tellerId')
		if (lastTellerId !== tellerId) {
			window.location.pathname = `home/reward/daily/${moment().format(
				'YYYYMM'
			)}`
			localStorage.setItem('lastTellerId', tellerId)
		}
	}

	componentWillReceiveProps(nextProps) {
		this.getReport(nextProps.match.params.month)
	}

	/**
	 * Glasから取得したデータの成形。データがない日を0で埋める
	 */
	// getReportFormat = (month,data) => {

	//   if (!moment(month, "YYYYMM").isValid()) {
	//     return
	//   }

	//   let tmp = {}
	//   if ( data !== undefined && Object.values(data).length ) {
	//     Object.values(data).map((val)=>{
	//       const date = moment( val.date , "YYYYMMDD").date()
	//       tmp[date] = val.totalPoint
	//     })
	//   }
	//   const num = moment(month, "YYYYMM").daysInMonth();
	//   for ( let i=1; i<=num; i++){
	//     if (tmp[i] === undefined){
	//       tmp[i] = 0
	//     }
	//   }

	//   this.setState({
	//     month: month,
	//     data: tmp
	//   })

	// }

	/**
	 * 日別ログを取得
	 */
	getReport(yyyymm) {
		const month = yyyymm === undefined ? moment().format('YYYYMM') : yyyymm
		if (!moment(month, 'YYYYMM').isValid()) {
			return
		}
		let statusItems = []
		const amountDays = moment(month, 'YYYYMM').daysInMonth()
		for (let i = 0; i < amountDays + 1; i++) {
			statusItems.push(false)
		}
		const url = glasConfig.url_base + glasConfig.path_payment_reports
		const params = {
			monthTime: month,
			fortuneTellerId: Fetch.tellerId(),
		}
		const token = localStorage.getItem('token')
		const options = {
			method: 'GET',
			url: url,
			params,
			headers: {
				Authorization: token,
			},
			json: true,
		}

		request(options)
			.then((response) => {
				if (response.data.code === 0) {
					let dataMonth = []
					keys.forEach((item) => {
						let checkData = false
						response.data.data.paymentLogsInMonth.forEach(
							(element) => {
								if (item === element.actionType) {
									dataMonth.push(element)
									checkData = true
								}
							}
						)
						if (!checkData)
							dataMonth.push({
								actionType: item,
								point: 0,
								unit: 0,
							})
					})

					this.setState({
						month,
						data: response.data.data,
						dataMonth,
						statusItems,
					})
				}
			})
			.catch((error) => {
				//throw error
			})
	}

	showMore(index) {
		let newStatusItems = this.state.statusItems
		newStatusItems[index] = !this.state.statusItems[index]
		this.setState({ statusItems: newStatusItems }, () => { })
	}

	getItem(item, index) {
		let value = {}
		switch (item.actionType) {
			case 'ACTION_CALL':
				return
		}
	}

	getDay(dayTime) {
		return Number(moment(dayTime, 'YYYYMM').day())
	}

	checkExisted(day) {
		let result = null
		this.state.data.paymentLogsPerDay.forEach((item) => {
			if (item.dayTime === day) {
				result = item.data
			}
		})

		return result
	}

	checkKey(data) {
		let arrTmp = []
		keys.forEach((item) => {
			let checkData = false
			data.forEach((element) => {
				if (item === element.actionType) {
					arrTmp.push(element)
					checkData = true
				}
			})
			if (!checkData)
				arrTmp.push({
					actionType: item,
					point: 0,
					unit: 0,
				})
		})
		return arrTmp
	}

	renderRows = (type) => {
		const { statusItems } = this.state
		const amountDays = moment(this.state.month, 'YYYYMM').daysInMonth()
		let content = []
		if (this.state.data.paymentLogsPerDay) {
			for (let i = 0; i < amountDays; i++) {
				const day = i < 9 ? `0${i + 1}` : i + 1
				const month = moment(this.state.month, 'YYYYMM').month()
				const monthDisplay = month < 9 ? `0${month + 1}` : month + 1
				const dayDisplay = monthDisplay + '/' + day
				const dayDisplaySP = monthDisplay + '月' + day + '日'
				const dayFormat = this.state.month + day
				let data = this.checkExisted(dayFormat)
				if (data) {
					data = this.checkKey(data)
					if (type === 'pc') {
						content.push(
							<tr className="valueRow">
								<th scope="row">{dayDisplay}</th>
								{data.map((item, index) =>
									this.renderColumn(item, index)
								)}
							</tr>
						)
					} else {
						content.push(
							<div className="rowBody">
								<div className="rowReward total">
									<div className="rewardItemLeftCol">
										<span className="titleLeft">
											{dayDisplaySP}
										</span>
									</div>
									<div className="rewardItemRightCol">
										<span className="titleRight">
											{data.filter(
												(el) =>
													el.actionType === 'TOTAL'
											)[0]
												? Number.isInteger(
													data.filter(
														(el) =>
															el.actionType ===
															'TOTAL'
													)[0].point
												)
													? data.filter(
														(el) =>
															el.actionType ===
															'TOTAL'
													)[0].point +
													(data.filter(
														(el) =>
															el.actionType ===
															'TOTAL'
													)[0].point > 1
														? 'pts'
														: 'pt')
													: data
														.filter(
															(el) =>
																el.actionType ===
																'TOTAL'
														)[0]
														.point.toFixed(2) +
													'pts'
												: null}
										</span>
										<i
											className="material-icons btn__icons iconExpand"
											onClick={() => this.showMore(i + 1)}
										>
											expand_more
										</i>
									</div>
								</div>
								{statusItems[i + 1] ? (
									<div className="detailReward">
										{data.map((item, index) =>
											this.renderColumnSP(item, index)
										)}
										<div className="pluralRow lastRowItem">
											<div className="rowLeftCol">
												待機時間
											</div>
											<div className="rowRightCol">
												<div className="pluralRightCol topRightCol">
													<i className="material-icons btn__icons">
														call
													</i>
													<div className="valueRight">
														{data.filter(
															(el) =>
																el.actionType ===
																'WAITING_TIME_CALL'
														)[0] &&
															moment
																.utc(
																	data.filter(
																		(el) =>
																			el.actionType ===
																			'WAITING_TIME_CALL'
																	)[0].unit *
																	1000
																)
																.format(
																	'HH:mm:ss'
																)}
													</div>
												</div>
												<div className="pluralRightCol centerRightCol">
													<i className="material-icons btn__icons">
														sms
													</i>
													<div className="valueRight">
														{data.filter(
															(el) =>
																el.actionType ===
																'WAITING_TIME_CHAT'
														)[0] &&
															moment
																.utc(
																	data.filter(
																		(el) =>
																			el.actionType ===
																			'WAITING_TIME_CHAT'
																	)[0].unit *
																	1000
																)
																.format(
																	'HH:mm:ss'
																)}
													</div>
												</div>
												<div className="pluralRightColSpecial bottomRightCol">
													<i className="material-icons btn__icons">
														call
													</i>
													<span>+</span>
													<i className="material-icons btn__icons">
														sms
													</i>
													<div className="valueRight">
														{data.filter(
															(el) =>
																el.actionType ===
																'WAITING_TIME_CALL_AND_CHAT'
														)[0] &&
															moment
																.utc(
																	data.filter(
																		(el) =>
																			el.actionType ===
																			'WAITING_TIME_CALL_AND_CHAT'
																	)[0].unit *
																	1000
																)
																.format(
																	'HH:mm:ss'
																)}
													</div>
												</div>
											</div>
										</div>
									</div>
								) : null}
							</div>
						)
					}
				} else {
					if (type === 'pc') {
						content.push(
							<tr className="valueRow">
								<th scope="row">{dayDisplay}</th>
								{EmptyRow}
							</tr>
						)
					} else {
						content.push(
							<div className="rowBody">
								<div className="rowReward total">
									<div className="rewardItemLeftCol">
										<span className="titleLeft">
											{dayDisplaySP}
										</span>
									</div>
									<div className="rewardItemRightCol">
										<span className="titleRight">- pt</span>
										<i
											className="material-icons btn__icons iconExpand"
											onClick={() => this.showMore(i + 1)}
										>
											expand_more
										</i>
									</div>
								</div>
								{statusItems[i + 1] ? EmptyRowSP : null}
							</div>
						)
					}
				}
			}
		}

		return content
	}

	renderColumn(item, index) {
		if (
			item.actionType === 'TOTAL' ||
			item.actionType === 'DAILY_BONUS' ||
			item.actionType === 'FREE_POINT' ||
			item.actionType === 'RECEIVE_GIFT' ||
			item.actionType === 'INTRODUCTION'
		) {
			return (
				<Fragment>
					<td key={index}>
						{(Number.isInteger(item.point)
							? item.point
							: item.point.toFixed(2)) +
							(item.point > 1 ? 'pts' : 'pt')}
					</td>
				</Fragment>
			)
		} else if (item.actionType === 'ACTION_CALL') {
			return (
				<Fragment>
					<td key={index}>
						{(Number.isInteger(item.point)
							? item.point
							: item.point.toFixed(2)) +
							(item.point > 1 ? 'pts' : 'pt')}
					</td>
					<td key={index + 'new'}>
						{formatSeconds(item.unit)}
					</td>
				</Fragment>
			)
		} else if (
			item.actionType === 'WAITING_TIME_CALL' ||
			item.actionType === 'WAITING_TIME_CHAT' ||
			item.actionType === 'WAITING_TIME_CALL_AND_CHAT'
		) {
			return (
				<Fragment>
					<td
						key={index}
						className={
							item.actionType === 'WAITING_TIME_CALL_AND_CHAT'
								? 'lastCol'
								: ''
						}
					>
						{formatSeconds(item.unit)}
					</td>
				</Fragment>
			)
		} else if (
			item.actionType === 'ACTION_CHAT_FREE_ONLINE' ||
			item.actionType === 'ACTION_CHAT_FREE_OFFLINE' ||
			item.actionType === 'ACTION_CHAT_ONLINE' ||
			item.actionType === 'ACTION_CHAT_OFFLINE' ||
			item.actionType === 'ACTION_RECEIVE_CHAT_ONLINE' ||
			item.actionType === 'ACTION_RECEIVE_CHAT_OFFLINE' ||
			item.actionType === 'ACTION_COMMENT'
		) {
			return (
				<Fragment>
					<td key={index}>
						{(Number.isInteger(item.point)
							? item.point
							: item.point.toFixed(2)) +
							(item.point > 1 ? 'pts' : 'pt')}
					</td>
					<td key={index + 'new'}>{item.unit}字</td>
				</Fragment>
			)
		} else if (
			item.actionType === 'ACTION_SEND_IMAGE_FREE_ONLINE' ||
			item.actionType === 'ACTION_SEND_IMAGE_FREE_OFFLINE' ||
			item.actionType === 'ACTION_SEND_IMAGE_ONLINE' ||
			item.actionType === 'ACTION_SEND_IMAGE_OFFLINE' ||
			item.actionType === 'ACTION_RECEIVE_IMAGE_ONLINE' ||
			item.actionType === 'ACTION_RECEIVE_IMAGE_OFFLINE'
		) {
			return (
				<Fragment>
					<td key={index}>
						{(Number.isInteger(item.point)
							? item.point
							: item.point.toFixed(2)) +
							(item.point > 1 ? 'pts' : 'pt')}
					</td>
					<td key={index + 'new'}>{item.unit}枚</td>
				</Fragment>
			)
		}
	}

	getTitle(type) {
		let result = ''
		switch (type) {
			case 'ACTION_CHAT_FREE_ONLINE':
				result = 'オンラインメッセージ送信（無料）'
				break
			case 'ACTION_CHAT_FREE_OFFLINE':
				result = 'オフラインメッセージ送信（無料）'
				break
			case 'ACTION_CHAT_ONLINE':
				result = 'オンラインメッセージ送信（有料）'
				break
			case 'ACTION_CHAT_OFFLINE':
				result = 'オフラインメッセージ送信（有料）'
				break
			case 'ACTION_RECEIVE_CHAT_ONLINE':
				result = 'オンラインメッセージ受信'
				break
			case 'ACTION_RECEIVE_CHAT_OFFLINE':
				result = 'オフラインメッセージ受信'
				break
			case 'ACTION_COMMENT':
				result = 'タイムラインコメント'
				break
			case 'ACTION_SEND_IMAGE_FREE_ONLINE':
				result = 'オンライン画像送信（無料）'
				break
			case 'ACTION_SEND_IMAGE_FREE_OFFLINE':
				result = 'オフライン画像送信（無料）'
				break
			case 'ACTION_SEND_IMAGE_ONLINE':
				result = 'オンライン画像送信（有料）'
				break
			case 'ACTION_SEND_IMAGE_OFFLINE':
				result = 'オフライン画像送信（有料）'
				break
			case 'ACTION_RECEIVE_IMAGE_ONLINE':
				result = 'オンライン画像受信'
				break
			case 'ACTION_RECEIVE_IMAGE_OFFLINE':
				result = 'オフライン画像受信'
				break
			default:
				result = ''
		}

		return result
	}

	renderColumnSP(item, index) {
		if (
			item.actionType === 'DAILY_BONUS' ||
			item.actionType === 'FREE_POINT'
		) {
			return (
				<div className="singleRow rowItem">
					<div className="rowLeftCol">
						{item.actionType === 'DAILY_BONUS'
							? 'ログイン'
							: '無料PT'}
					</div>
					<div className="rowRightCol">
						<i className="fab fa-product-hunt"></i>
						<div className="valueRight">
							{(Number.isInteger(item.point)
								? item.point
								: item.point.toFixed(2)) +
								(item.point > 1 ? 'pts' : 'pt')}
						</div>
					</div>
				</div>
			)
		} else if (item.actionType === 'ACTION_CALL') {
			return (
				<div className="pluralRow rowItem">
					<div className="rowLeftCol">通話</div>
					<div className="rowRightCol">
						<div className="pluralRightCol topRightCol">
							<i className="fab fa-product-hunt"></i>
							<div className="valueRight">
								{(Number.isInteger(item.point)
									? item.point
									: item.point.toFixed(2)) +
									(item.point > 1 ? 'pts' : 'pt')}
							</div>
						</div>
						<div className="pluralRightCol bottomRightCol">
							<i className="material-icons btn__icons">
								access_time
							</i>
							<div className="valueRight">
								{moment
									.utc(item.unit * 1000)
									.format('HH:mm:ss')}
							</div>
						</div>
					</div>
				</div>
			)
		} else if (
			item.actionType === 'ACTION_CHAT_FREE_ONLINE' ||
			item.actionType === 'ACTION_CHAT_FREE_OFFLINE' ||
			item.actionType === 'ACTION_CHAT_ONLINE' ||
			item.actionType === 'ACTION_CHAT_OFFLINE' ||
			item.actionType === 'ACTION_RECEIVE_CHAT_ONLINE' ||
			item.actionType === 'ACTION_RECEIVE_CHAT_OFFLINE' ||
			item.actionType === 'ACTION_COMMENT'
		) {
			return (
				<div className="pluralRow rowItem">
					<div className="rowLeftCol">
						{this.getTitle(item.actionType)}
					</div>
					<div className="rowRightCol">
						<div className="pluralRightCol topRightCol">
							<i className="fab fa-product-hunt"></i>
							<div className="valueRight">
								{(Number.isInteger(item.point)
									? item.point
									: item.point.toFixed(2)) +
									(item.point > 1 ? 'pts' : 'pt')}
							</div>
						</div>
						<div className="pluralRightCol bottomRightCol">
							<i className="material-icons btn__icons">edit</i>
							<div className="valueRight">{item.unit}字</div>
						</div>
					</div>
				</div>
			)
		} else if (
			item.actionType === 'ACTION_SEND_IMAGE_FREE_ONLINE' ||
			item.actionType === 'ACTION_SEND_IMAGE_FREE_OFFLINE' ||
			item.actionType === 'ACTION_SEND_IMAGE_ONLINE' ||
			item.actionType === 'ACTION_SEND_IMAGE_OFFLINE' ||
			item.actionType === 'ACTION_RECEIVE_IMAGE_ONLINE' ||
			item.actionType === 'ACTION_RECEIVE_IMAGE_OFFLINE'
		) {
			return (
				<div className="pluralRow rowItem">
					<div className="rowLeftCol">
						{this.getTitle(item.actionType)}
					</div>
					<div className="rowRightCol">
						<div className="pluralRightCol topRightCol">
							<i className="fab fa-product-hunt"></i>
							<div className="valueRight">
								{(Number.isInteger(item.point)
									? item.point
									: item.point.toFixed(2)) +
									(item.point > 1 ? 'pts' : 'pt')}
							</div>
						</div>
						<div className="pluralRightCol bottomRightCol">
							<i className="material-icons btn__icons">
								insert_photo
							</i>
							<div className="valueRight">{item.unit}枚</div>
						</div>
					</div>
				</div>
			)
		} else if (item.actionType === 'RECEIVE_GIFT') {
			return (
				<div className="singleRow rowItem">
					<div className="rowLeftCol">ギフトPT</div>
					<div className="rowRightCol">
						<i className="fab fa-product-hunt"></i>
						<div className="valueRight">
							{(Number.isInteger(item.point)
								? item.point
								: item.point.toFixed(2)) +
								(item.point > 1 ? 'pts' : 'pt')}
						</div>
					</div>
				</div>
			)
		} else if (item.actionType === 'INTRODUCTION') {
			return (
				<div className="singleRow rowItem">
					<div className="rowLeftCol">紹介</div>
					<div className="rowRightCol">
						<i className="fab fa-product-hunt"></i>
						<div className="valueRight">
							{(Number.isInteger(item.point)
								? item.point
								: item.point.toFixed(2)) +
								(item.point > 1 ? 'pts' : 'pt')}
						</div>
					</div>
				</div>
			)
		}
	}

	renderDataMonth = (type) => {
		if (this.state.dataMonth.length > 0) {
			return this.state.dataMonth.map((item, index) =>
				type === 'pc'
					? this.renderColumn(item, index)
					: this.renderColumnSP(item, index)
			)
		} else {
			return EmptyRow
		}
	}

	render() {
		const { MyProfile, dispatch } = this.props
		const { statusItems, data, dataMonth } = this.state
		let momentObj = moment(
			MyProfile.data.registerTime,
			'YYYYMMDDHHmmss'
		).add(9, 'hours')
		let registerMonth = momentObj.month()
		let registerYear = momentObj.year()
		let currentDate = new Date()
		let currentMonth = currentDate.getMonth()
		let currentYear = currentDate.getFullYear()
		let month = moment(this.state.month, 'YYYYMM').month()
		let year = moment(this.state.month, 'YYYYMM').year()

		let canPrev = true
		let canNext = true

		if (
			year < registerYear ||
			(year == registerYear && month <= registerMonth)
		) {
			year = registerYear
			month = registerMonth
			canPrev = false
		}

		if (
			year > currentYear ||
			(year == currentYear && month >= currentMonth)
		) {
			year = currentYear
			month = currentMonth
			canNext = false
		}

		let prev = canPrev
			? moment(this.state.month, 'YYYYMM')
				.subtract(1, 'months')
				.format('YYYYMM')
			: this.state.month
		let next = canNext
			? moment(this.state.month, 'YYYYMM')
				.add(1, 'months')
				.format('YYYYMM')
			: this.state.month
		let prevClass = canPrev
			? 'rdh__navigation__prev is-active'
			: 'rdh__navigation__prev'
		let nextClass = canNext
			? 'rdh__navigation__next is-active'
			: 'rdh__navigation__next'
		const rows = this.renderRows('pc')
		const dataMonthPC = this.renderDataMonth('pc')
		const rowsSP = this.renderRows('sp')
		const dataMonthSP = this.renderDataMonth('sp')

		return (
			<div className="content reward reward-daily">
				<h1 className="content__title">精算</h1>
				<RewardMenu />
				<div className="primary primaryReward">
					<div className="primary-title titleDailyReward">
						<h1 className="primary-title__main">日別報酬</h1>
						<div className="primary-title lv_2 hidden-mobile dateTimeReward">
							<h1 className="primary-title__main">
								{year}年
								{month < 9 ? `0${month + 1}` : month + 1}月
							</h1>
							<div className="reward-daily__header">
								<div className="rdh__navigation">
									<Link
										to={`/home/reward/daily/${prev}`}
										className={prevClass}
									>
										<i className="fas fa-chevron-left"></i>
									</Link>
									<Link
										to={`/home/reward/daily/${next}`}
										className={nextClass}
									>
										<i className="fas fa-chevron-right"></i>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="primary__inner">
						<div className="primary-content rewardContent">
							<div className="primary-content__body">
								<div className="reward-daily__header display-mobile">
									<div className="rdh__date">
										{year}年
										{month < 9
											? `0${month + 1}`
											: month + 1}
										月
									</div>
									<div className="rdh__navigation">
										<Link
											to={`/home/reward/daily/${prev}`}
											className={prevClass}
										>
											<i class="fas fa-chevron-left"></i>
										</Link>
										<Link
											to={`/home/reward/daily/${next}`}
											className={nextClass}
										>
											<i class="fas fa-chevron-right"></i>
										</Link>
									</div>
									<div className="rewardItem firstRowMobileDown">
										<div className="firstRowTop">
											<div className="rewardItemLeftCol">
												<span
													style={{ marginLeft: 12 }}
												>
													日時
												</span>
											</div>
											<div className="rewardItemRightCol">
												<div
													style={{
														width: 100,
														textAlign: 'center',
														marginRight: 16,
													}}
												>
													合計ポイント
												</div>
											</div>
										</div>
										<div className="firstRowBody">
											<div className="rowReward total">
												<div className="rewardItemLeftCol">
													<span className="titleLeft">
														{month < 9
															? `0${month + 1}`
															: month + 1}
														月
													</span>
												</div>
												<div className="rewardItemRightCol">
													<span className="titleRight">
														{dataMonth.length > 0
															? Number.isInteger(
																dataMonth.filter(
																	(el) =>
																		el.actionType ===
																		'TOTAL'
																)[0].point
															)
																? dataMonth.filter(
																	(el) =>
																		el.actionType ===
																		'TOTAL'
																)[0].point
																: dataMonth
																	.filter(
																		(
																			el
																		) =>
																			el.actionType ===
																			'TOTAL'
																	)[0]
																	.point.toFixed(
																		2
																	) +
																(dataMonth.filter(
																	(el) =>
																		el.actionType ===
																		'TOTAL'
																)[0].point > 1
																	? 'pts'
																	: 'pt')
															: '- pt'}
													</span>
													<i
														className="material-icons btn__icons iconExpand"
														onClick={() =>
															this.showMore(0)
														}
													>
														expand_more
													</i>
												</div>
											</div>
											{statusItems[0] ? (
												dataMonth.length > 0 ? (
													<div className="detailReward">
														{dataMonthSP}
														<div className="pluralRow lastRowItem">
															<div className="rowLeftCol">
																待機時間
															</div>
															<div className="rowRightCol">
																<div className="pluralRightCol topRightCol">
																	<i className="material-icons btn__icons">
																		call
																	</i>
																	<div className="valueRight">
																		{dataMonth.length >
																			0 &&
																			moment
																				.utc(
																					dataMonth.filter(
																						(
																							el
																						) =>
																							el.actionType ===
																							'WAITING_TIME_CALL'
																					)[0]
																						.unit *
																					1000
																				)
																				.format(
																					'HH:mm:ss'
																				)}
																	</div>
																</div>
																<div className="pluralRightCol centerRightCol">
																	<i className="material-icons btn__icons">
																		sms
																	</i>
																	<div className="valueRight">
																		{dataMonth.length >
																			0 &&
																			moment
																				.utc(
																					dataMonth.filter(
																						(
																							el
																						) =>
																							el.actionType ===
																							'WAITING_TIME_CHAT'
																					)[0]
																						.unit *
																					1000
																				)
																				.format(
																					'HH:mm:ss'
																				)}
																	</div>
																</div>
																<div className="pluralRightColSpecial bottomRightCol">
																	<i className="material-icons btn__icons">
																		call
																	</i>
																	<span>
																		+
																	</span>
																	<i className="material-icons btn__icons">
																		sms
																	</i>
																	<div className="valueRight">
																		{dataMonth.length >
																			0 &&
																			moment
																				.utc(
																					dataMonth.filter(
																						(
																							el
																						) =>
																							el.actionType ===
																							'WAITING_TIME_CALL_AND_CHAT'
																					)[0]
																						.unit *
																					1000
																				)
																				.format(
																					'HH:mm:ss'
																				)}
																	</div>
																</div>
															</div>
														</div>
													</div>
												) : (
													EmptyRowSP
												)
											) : null}
										</div>
									</div>
									<div className="rowsSP">{rowsSP}</div>
								</div>
								<table className="tableReward hidden-mobile">
									<colgroup span="2"></colgroup>
									<colgroup span="2"></colgroup>
									<tr className="rowHeader">
										<td rowSpan="2">日付</td>
										<td rowSpan="2">合計</td>
										<td rowSpan="2">ログイン</td>
										<th colSpan="2" scope="colgroup">
											通話
										</th>
										<th colSpan="2" scope="colgroup">
											オンラインメッセージ送信（無料）
										</th>
										<th colSpan="2" scope="colgroup">
											オフラインメッセージ送信（無料）
										</th>
										<th colSpan="2" scope="colgroup">
											オンラインメッセージ送信（有料）
										</th>
										<th colSpan="2" scope="colgroup">
											オフラインメッセージ送信（有料）
										</th>
										<th colSpan="2" scope="colgroup">
											オンラインメッセージ受信
										</th>
										<th colSpan="2" scope="colgroup">
											オフラインメッセージ受信
										</th>
										<th colSpan="2" scope="colgroup">
											オンライン画像送信（無料）
										</th>
										<th colSpan="2" scope="colgroup">
											オフライン画像送信（無料）
										</th>
										<th colSpan="2" scope="colgroup">
											オンライン画像送信（有料）
										</th>
										<th colSpan="2" scope="colgroup">
											オフライン画像送信（有料）
										</th>
										<th colSpan="2" scope="colgroup">
											オンライン画像受信
										</th>
										<th colSpan="2" scope="colgroup">
											オフライン画像受信
										</th>
										<th colSpan="2" scope="colgroup">
											タイムラインコメント
										</th>
										<th rowSpan="2" scope="colgroup">
											無料PT
										</th>
										<th rowSpan="2" scope="colgroup">
											ギフトPT
										</th>
										<th rowSpan="2" scope="colgroup">
											紹介
										</th>
										<th
											colSpan="3"
											scope="colgroup"
											className="lastCol"
										>
											待機時間
										</th>
									</tr>
									<tr className="rowHeaderBot">
										<th scope="col">ポイント</th>
										<th scope="col">時間</th>
										<th scope="col">ポイント</th>
										<th scope="col">文字数</th>
										<th scope="col">ポイント</th>
										<th scope="col">文字数</th>
										<th scope="col">ポイント</th>
										<th scope="col">文字数</th>
										<th scope="col">ポイント</th>
										<th scope="col">文字数</th>
										<th scope="col">ポイント</th>
										<th scope="col">文字数</th>
										<th scope="col">ポイント</th>
										<th scope="col">文字数</th>
										<th scope="col">ポイント</th>
										<th scope="col">枚数</th>
										<th scope="col">ポイント</th>
										<th scope="col">枚数</th>
										<th scope="col">ポイント</th>
										<th scope="col">枚数</th>
										<th scope="col">ポイント</th>
										<th scope="col">枚数</th>
										<th scope="col">ポイント</th>
										<th scope="col">枚数</th>
										<th scope="col">ポイント</th>
										<th scope="col">枚数</th>
										<th scope="col">ポイント</th>
										<th scope="col">文字数</th>
										<th scope="col">通話</th>
										<th scope="col">チャット</th>
										<th scope="col" className="lastCol">
											通話＋チャット
										</th>
									</tr>
									<tr
										className="valueRow"
										style={{
											backgroundColor:
												'rgba(241, 255, 253, 1',
										}}
									>
										<td>
											{month < 9
												? `0${month + 1}`
												: month + 1}
											月
										</td>
										{data.paymentLogsInMonth &&
											data.paymentLogsInMonth.map(
												(item, index) => {
													this.getItem(item, index)
												}
											)}
										{dataMonthPC}
									</tr>
									{SpaceRow}
									{rows.map((item) => item)}
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const EmptyRow = (
	<Fragment>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td>-</td>
		<td className="lastCol">-</td>
	</Fragment>
)

const EmptyRowSP = (
	<div className="detailReward">
		<div className="singleRow rowItem">
			<div className="rowLeftCol">ログイン</div>
			<div className="rowRightCol">
				<i className="fab fa-product-hunt"></i>
				<div className="valueRight">-</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">通話</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">access_time</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オンラインメッセージ送信（無料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">edit</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オフラインメッセージ送信（無料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">edit</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オンラインメッセージ送信（有料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">edit</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オフラインメッセージ送信（有料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">edit</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オンラインメッセージ受信</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">edit</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オフラインメッセージ受信</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">edit</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オンライン画像送信（無料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">insert_photo</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オフライン画像送信（無料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">insert_photo</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オンライン画像送信（有料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">insert_photo</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オフライン画像送信（有料）</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">insert_photo</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オンライン画像受信</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">insert_photo</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">オフライン画像受信</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">insert_photo</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="pluralRow rowItem">
			<div className="rowLeftCol">タイムラインコメント</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="fab fa-product-hunt"></i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol bottomRightCol">
					<i className="material-icons btn__icons">edit</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
		<div className="singleRow rowItem">
			<div className="rowLeftCol">無料PT</div>
			<div className="rowRightCol">
				<i className="fab fa-product-hunt"></i>
				<div className="valueRight">-</div>
			</div>
		</div>
		<div className="singleRow rowItem">
			<div className="rowLeftCol">ギフトPT</div>
			<div className="rowRightCol">
				<i className="fab fa-product-hunt"></i>
				<div className="valueRight">-</div>
			</div>
		</div>
		<div className="singleRow rowItem">
			<div className="rowLeftCol">紹介</div>
			<div className="rowRightCol">
				<i className="fab fa-product-hunt"></i>
				<div className="valueRight">-</div>
			</div>
		</div>
		<div className="pluralRow lastRowItem">
			<div className="rowLeftCol">待機時間</div>
			<div className="rowRightCol">
				<div className="pluralRightCol topRightCol">
					<i className="material-icons btn__icons">call</i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightCol centerRightCol">
					<i className="material-icons btn__icons">sms</i>
					<div className="valueRight">-</div>
				</div>
				<div className="pluralRightColSpecial bottomRightCol">
					<i className="material-icons btn__icons">call</i>
					<span>+</span>
					<i className="material-icons btn__icons">sms</i>
					<div className="valueRight">-</div>
				</div>
			</div>
		</div>
	</div>
)

const SpaceRow = (
	<tr className="spaceRow">
		<th scope="row"></th>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td></td>
		<td className="lastCol"></td>
	</tr>
)

const mapStateToProps = (state) => {
	return {
		MyProfile: state.MyProfile,
	}
}

export default connect(mapStateToProps)(RewardDaily)
