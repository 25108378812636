import React, { Component } from 'react'
import { PrivateRoute } from './PrivateRoute'
import BankAccount from './BankAccount'
import SettingBank from './SettingBank'
import SettingBankInitials from './SettingBankInitials'
import SettingBankBranches from './SettingBankBranches'
import SettingBankBranchInitials from './SettingBankBranchInitials'
import SettingBankAccount from './SettingBankAccount'
import SettingBankAccountConfirm from './SettingBankAccountConfirm'

class RouteSettingBank extends Component {
	render() {
		return (
			<span>
				<PrivateRoute
					path={`${this.props.match.path}/top`}
					component={BankAccount}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/list`}
					component={SettingBank}
				/>
				<PrivateRoute
					path={`${this.props.match.path}/initials/:kana`}
					component={SettingBankInitials}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/branches/:code`}
					component={SettingBankBranches}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/branches/initials/:code/:kana`}
					component={SettingBankBranchInitials}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/account/:bankCode/:branchCode`}
					component={SettingBankAccount}
				/>
				<PrivateRoute
					exact
					strict
					path={`${this.props.match.path}/account/confirm`}
					component={SettingBankAccountConfirm}
				/>
			</span>
		)
	}
}

export default RouteSettingBank
