import { GET_ALL_HISTORY_RANKING_EVENT, GET_ALL_RANKING_EVENT, GET_ALL_RANKING_EVENT_ANNOUNCED, UPDATE_RANKING_EVENT, UPDATE_RANKING_EVENT_ANNOUNCED } from '../constants/ActionTypes'

const initialState = {
    data: [],
    dataAnnounced: [],
    lstHistoryEvent: { list: [], total: 0 },
}

export const RankingEvent = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_RANKING_EVENT: {
            return {
                ...state,
                data: action.data,
            }
        }
        case UPDATE_RANKING_EVENT: {
            let newArr = state.data.map(i => {
                if (i.rankingEventId === action.data) {
                    return { ...i, status: 'ENTRIED' }
                }
                return i
            })

            return {
                ...state,
                data: newArr,
            }
        }
        case GET_ALL_RANKING_EVENT_ANNOUNCED: {
            return {
                ...state,
                dataAnnounced: action.data,
            }
        }
        case UPDATE_RANKING_EVENT_ANNOUNCED: {
            let newArr = state.dataAnnounced.filter(i => i.rankingEventId !== action.data)
            return {
                ...state,
                dataAnnounced: newArr,
            }
        }
        case GET_ALL_HISTORY_RANKING_EVENT: {
            return {
                ...state,
                lstHistoryEvent: action.data,
            }
        }

        default: {
            return state
        }
    }
}
