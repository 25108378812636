import { Dialog } from "@material-ui/core";
import React from "react";
import { useEffect, useState } from 'react'

export const DialogAppealMessage = (props) => { 
    const [isShow, setShow] = useState(false);

    useEffect(() => {
		setShow(props.isShow)
	}, [props.isShow])

    const onCancel = () => {
        setShow(false)
        props.resetToggle()
    }

    return (
        <div>
			<Dialog
				modal={false}
				open={isShow}
				bodyClassName="modal-missing__profile"
				contentClassName="modal-missing__profile-paper"
			>
				<div className="cancel-dialog__right">
                    <span onClick={() => onCancel()}>X</span>
                </div>
				<div className="modal-missing__profile-content" id="content-appeal__message">
                    <img className="image-appeal__item" src="/img/appealMessage1.png" />
                    <img className="image-appeal__item" src="/img/appealMessage2.png" />
                    <div className="description-appeal__message">
                        <p className="title-description-appeal">★Point★</p>
                        <p className="text-description-appeal">
                            一番初めにお客さまの目に入る情報なので、
                        </p>
                        <p className="text-description-appeal">
                            <span>「お客様目線」</span>
                            で自分の特徴や実績・得意なことを
                        </p>
                        <p className="text-description-appeal">記載しましょう！</p>
                    </div>
				</div>
				<div className="modal-appeal__message-btn">
					<input
						type="button"
						value="OK"
						className="modal-appeal__message-btn-cancel"
						onClick={onCancel}
					/>
				</div>
			</Dialog>
		</div>
    )
}
