import {
	BANKS_GET,
	BANK_GET,
	// BANK_POST,
	// BANK_BACK,
	BANK_BRANCHS_GET,
	BANK_BRANCH_GET,
	// BANK_BRANCH_POST,
	// BANK_BRANCH_BACK,
	BANK_ACCOUNT_EDIT,
	BANK_ACCOUNT_CONFIRM_POST,
	// BANK_ACCOUNT_CONFIRM_BACK,
	BANK_ACCOUNT_POST,
	GET_LIST_BANK,
} from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import * as Fetch from '../util/Fetch'
import request from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

// 口座確認
export const accountEdit = (params) => {
	return (dispatch) => {
		dispatch(resultSuccess(BANK_ACCOUNT_EDIT, params))
	}
}

// 口座確認
export const accountConfirm = (params) => {
	return (dispatch) => {
		dispatch(resultSuccess(BANK_ACCOUNT_CONFIRM_POST, params))
	}
}

// 口座入力
export const accountInput = (params) => {
	return (dispatch) => {
		return request
			.put(
				glasConfig.url_outside + glasConfig.path_bank_account,
				params,
				{
					headers: {
						Authorization: localStorage.getItem('token'),
					},
				}
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(BANK_ACCOUNT_POST, response.data.data)
					)

					const tellerId = Fetch.tellerId()
					request.put(
						glasConfig.url_base +
							glasConfig.update_mission_introduce,
						{
							missionType: 'REGISTER_BANKING',
							tellerId,
						},
						{
							headers: {
								Authorization: localStorage.getItem('token'),
							},
						}
					)
				} else {
					dispatch(resultSuccess(BANK_ACCOUNT_POST, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANK_ACCOUNT_POST, error)
				throw error
			})
	}
}

// 銀行一覧
export const getBank = () => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_outside + glasConfig.path_bank)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(BANKS_GET, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(BANKS_GET, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANKS_GET, error)
				throw error
			})
	}
}
export const getListBank = () => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_outside + glasConfig.get_bank_webview)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(GET_LIST_BANK, response.data.data))
				} else {
					dispatch(resultSuccess(GET_LIST_BANK, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANKS_GET, error)
				throw error
			})
	}
}

// 頭文字銀行一覧
export const getInitialBank = (initial) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_outside + glasConfig.path_bank + '/' + initial)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(BANK_GET, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(BANK_GET, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANK_GET, error)
				throw error
			})
	}
}

// 銀行コード指定
export const getBankCode = (code) => {
	return (dispatch) => {
		return request
			.get(glasConfig.url_outside + glasConfig.path_bank + '/' + code)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(BANK_GET, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(BANK_GET, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANK_GET, error)
				throw error
			})
	}
}

// 支店一覧
export const getBranch = (bankCode) => {
	return (dispatch) => {
		return request
			.get(
				glasConfig.url_outside +
					glasConfig.path_bank +
					'/' +
					bankCode +
					glasConfig.path_bank_branch
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(BANK_BRANCHS_GET, response.data.data)
					)
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(BANK_BRANCHS_GET, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANK_BRANCHS_GET, error)
				throw error
			})
	}
}

// 頭文字支店一覧
export const getInitialBranch = (bankCode, initial) => {
	return (dispatch) => {
		return request
			.get(
				glasConfig.url_outside +
					glasConfig.path_bank +
					'/' +
					bankCode +
					glasConfig.path_bank_branch +
					'/' +
					initial
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(BANK_BRANCH_GET, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(BANK_BRANCH_GET, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANK_BRANCH_GET, error)
				throw error
			})
	}
}

// 支店コード指定
export const getBranchCode = (bankCode, branchCode) => {
	return (dispatch) => {
		return request
			.get(
				glasConfig.url_outside +
					glasConfig.path_bank +
					'/' +
					bankCode +
					glasConfig.path_bank_branch +
					'/' +
					branchCode
			)
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(resultSuccess(BANK_BRANCH_GET, response.data.data))
				} else {
					console.log(response.data.message)
					dispatch(resultSuccess(BANK_BRANCH_GET, {}))
				}
			})
			.catch((error) => {
				console.log('error on call ' + BANK_BRANCH_GET, error)
				throw error
			})
	}
}
