import React, { Component } from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import Checkbox from 'material-ui/Checkbox'
import { isAndroid,isMobile } from 'react-device-detect'

export default class DialogBlock extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			funcAccept: null,
			checked: false
		}
	}

	componentDidMount() {
		this.checkAcceptChat()
	}

	checkAcceptChat() {
		const { socket } = this.props
		socket.on('userRequest', (res) => {
			const message = JSON.parse(res)
			if (message.msgType === 'RESPONSE_REQUEST_CHAT') {
				this.closeDialog()
			}
		})
	}

	acceptReply = () => {
		const funcAccept = this.state.funcAccept
		this.setState({ show: false }, () => {
			funcAccept()
		})
	}

	openDialog = (funcAccept) => {
		this.setState({ show: true, funcAccept })
	}

	closeDialog = () => {
		this.setState({ show: false })
	}

	render() {
		return (
			<Dialog
				modal={false}
				open={this.state.show}
				// onRequestClose={this.closeDialog}
				className="dialogBlock"
				bodyStyle={{height: 800}}
				contentStyle={{width: '90%'}}
			>
				<i
					className="material-icons tab__icons closeBlock"
					onClick={this.closeDialog}
				>
					clear
				</i>
				<p className="txtDialogBlock1">ブロックするとお客様があなたにメッセージや{!isMobile?'\n':''}リクエストを送信できなくなります。</p>
				<p className="txtDialogBlock2">
					この機能は<span>ユーザーから「嫌がらせ」や「不適切な発言」を受けている</span>, あるいはユーザーが <span>「未成年など鑑定ができない」</span>場合などに利用することができます。
				</p>
				<p className="txtDialogBlock3" style={{ color: '#000000', fontWeight: 'bold' }}>以下のようなケースではブロックを利用しないでください。</p>
				<p className="txtDialogBlock3">・挨拶がない </p>
				<p className="txtDialogBlock3">・レビューが想定よりも低い</p>
				<p className="txtDialogBlock3">・なんとなく気に障った</p>
				<div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', marginBottom: isMobile? 7 : 15, }}>
					<Checkbox
						label={`上記を確認した上で${isMobile?'\n':''}このお客様をブロックします。`}
						onCheck={(e) =>
							this.setState({ checked: !this.state.checked })
						}
						checked={this.state.checked}
						// uncheckedIcon={style:{borerColor: 'red'}}
						iconStyle={{ marginLeft: 0, marginRight: 10}}
						inputStyle={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}
						labelStyle={{ fontSize: isMobile ? 14:15, color: 'rgba(0, 0, 0, 0.87)', opacity: 0.75,width:'auto',fontFamily:'Hiragino Kaku Gothic Pro',lineHeight:isMobile?'18px':'28px',marginTop:isMobile? 3 : 0}}
						style={{ width: 'auto', color: '#c7bfbf', marginTop: isMobile? 15: 40, display: "flex", justifyContent: 'center' }}
					/>
				</div>
				<div className="buttonsReplyBlock2" style={{ justifyContent: 'center', display: "flex",flexDirection: isMobile ? 'column' : 'row'}}>
					<button
						className={this.state.checked?"submitReplyBlock":"disableSubmitReplyBlock"}
						onClick={this.acceptReply}
						disabled={!this.state.checked}
					>
						ブロックする
					</button>
					<button
						className="cancelReplyBlock"
						onClick={this.closeDialog}
					>
						もどる
					</button>
				</div>
			</Dialog>
		)
	}
}