import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Dialog from 'material-ui/Dialog'
import Checkbox from 'material-ui/Checkbox'
import request from 'axios'
import { glasConfig } from './../constants/Config'
import * as MyProfileActions from '../actions/MyProfileActions'
import * as AnswerManagementActions from './../actions/AnswerManagementActions'
import Slider from 'react-slick'
class DialogWelcomeAnswer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			show: false,
			checked: false,
			index: 0,
		}
	}

	componentWillReceiveProps(nextProps) {
		if (
			this.state.show !== nextProps.AnswerManagement.isOpenWelComeAnswer
		) {
			this.setState({
				show: nextProps.AnswerManagement.isOpenWelComeAnswer,
			})
		}
	}

	handleSubmit = () => {
		const { MyProfile } = this.props
		if (MyProfile.data.isHiddenStellaAnswerPopup) {
			return
		}

		const token = localStorage.getItem('token')
		let options = {
			method: 'PUT',
			url:
				glasConfig.url_base +
				glasConfig.path_teller_info +
				`?isHiddenStellaAnswerPopup=true`,
			headers: {
				Authorization: token,
				'Content-Type': 'application/json',
			},
			json: true,
		}
		request(options)
			.then((response) => {
				this.props.dispatch(
					MyProfileActions.merge({
						isHiddenStellaAnswerPopup: true,
					})
				)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	handleClick = (e) => {
		this.setState({ checked: !this.state.checked })
	}

	next = () => {
		if (this.state.index === 2) {
			this.onClose()
			this.setState({
				index: 0,
			})
			return
		}
		this.slider.slickNext()
	}

	onClose = () => {
		this.handleSubmit()
		this.props.dispatch(AnswerManagementActions.closeDialogWelComeAnswer())
		this.setState({
			index: 0,
		})
	}

	render() {
		const settings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			beforeChange: (oldIndex, newIndex) =>
				this.setState({ index: newIndex }),
		}

		return (
			<Dialog
				title="「教えて！先生」とは？"
				titleStyle={{ color: '#11ad9d' }}
				modal={false}
				open={this.state.show}
				className="dialogWelcome"
			>
				<i
					class="material-icons tab__icons dialogWelcome__icon-clear hidden-sp"
					onClick={this.onClose}
				>
					clear
				</i>
				<div className="dialogWelcome__content">
					<hr className="dialogWelcome__line"></hr>
					<Slider ref={(c) => (this.slider = c)} {...settings}>
						<DiaLogStep1 />
						<DialogStep2 />
						<DialogStep3 onClose={this.onClose} />
					</Slider>
				</div>
				<div style={{ textAlign: 'center', marginLeft: 15 }}>
					<button
						className="btn-raised color_default dialogWelcome__btn-next"
						onClick={this.next}
					>
						{this.state.index === 2 ? 'OK' : '次へ'}
					</button>
				</div>
				{this.state.index === 2 && (
					<div>
						<Checkbox
							label="今後表示しない"
							onCheck={(e) => this.handleClick(e)}
							checked={this.state.checked}
							className="checkboxDialogWelcome"
							style={{ color: 'rgba(139, 132, 152, 1)' }}
						/>
					</div>
				)}
			</Dialog>
		)
	}
}

const DiaLogStep1 = () => {
	return (
		<div className="dialogWelcome__step">
			<p>
				{' '}
				「教えて！先生」は「ど
				<span style={{ color: 'rgba(17, 173, 157, 1)' }}>
					の先生に相談すればいいか悩んでしまうユーザー
				</span>
				」が占い師を選ぶためのコンテンツです。
			</p>
			<p style={{ marginTop: 20 }}>
				ユーザー様は匿名で悩みを投稿し、その投稿に対して、複数の占い師が回答できます。
			</p>
		</div>
	)
}

const DialogStep2 = () => {
	return (
		<div className="dialogWelcome__step">
			<p>
				回答がベストアンサーに選ばれると、
				<span style={{ color: 'rgba(17, 173, 157, 1)' }}>
					ポイント獲得と自動でユーザーへ営業メールが送信されます
				</span>
				。
			</p>
			<p style={{ marginTop: 20 }}>
				{' '}
				「教えて！先生」でユーザー様に心象の良い回答をすることが新規のお客様を獲得することに繋がります。
			</p>
		</div>
	)
}

const DialogStep3 = ({ onClose }) => {
	return (
		<div className="dialogWelcome__step">
			<p>
				回答する際はユーザー様目線で「どんな回答をすれば心象よく、自分に興味を持っていただけるか？」を回答していただくことがコツとなります！
			</p>
			<p style={{ marginTop: 20, marginBottom: 47 }}>
				もっと詳しくコツを見たい方は
				<Link
					onClick={onClose}
					to="/home/staffblog/detail/2588"
					style={{
						fontWeight: 'bold',
						color: 'rgba(17, 173, 157, 1)',
						textDecoration: 'underline',
						textUnderlinePosition: 'under',
					}}
				>
					【お仕事マニュアル】「教えて！先生」を有効活用しよう！
				</Link>
				を参考にしてください！
			</p>
		</div>
	)
}

const mapStateToProps = (store) => {
	return {
		AnswerManagement: store.AnswerManagement,
		MyProfile: store.MyProfile,
	}
}

export default connect(mapStateToProps)(DialogWelcomeAnswer)
