/**
 * チャットリクエスト関連
 */
import { CHAT_NUMBER_TOTAL } from '../constants/ActionTypes'
import { glasConfig } from '../constants/Config'
import axios from 'axios'

const resultSuccess = (type, data) => {
	return {
		type: type,
		data,
	}
}

export const fetch = () => {
	return (dispatch) => {
		const token = localStorage.getItem('token')
		return axios
			.get(glasConfig.url_base + glasConfig.path_number_unread_message, {
				headers: {
					Authorization: token,
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				if (response.data.code === 0) {
					dispatch(
						resultSuccess(CHAT_NUMBER_TOTAL, response.data.data)
					)
				} else {
					throw { response }
				}
			})
			.catch((error) => {
				console.log('error on call ' + CHAT_NUMBER_TOTAL, error)
				throw error
			})
	}
}
